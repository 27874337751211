// import constant
import {
    BRIDGENETWORK
} from '../constant';

const initialState = {
    fromnetwork: "",
    fromchainId: 0,
    frombridgeId: 0,
    tonetwork: "",
    tochainId: 0,
    tobridgeId: 0
};

const bridgeNetwork = (state = initialState, action) => {

    switch (action.type) {
        case BRIDGENETWORK:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default bridgeNetwork;