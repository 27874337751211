import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

import { getbalance } from "../ContractActions/bep20Actions";
import { setWallet } from "../reducers/Actions";
import config from "../config/config";
import { getCoinName, getTxName } from "../helper/networkdata";
import { useDisconnect, useSwitchNetwork, useAccount } from "wagmi";
import { useTranslation } from "react-i18next";

export default function WalletDetail(props) {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const { t } = useTranslation();

  const walletConnection = useSelector((state) => state.walletConnection);
  const [userdeatils, setuserdeatils] = useState({ balance: 0, address: "", txlink: "" });
  const [copytext, setcopytext] = React.useState(false);
  const [coinname, setcoinname] = React.useState("");

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("ConnectedConnected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
      localStorage.setItem("connect", "no");
      localStorage.setItem("iswallet", "");
      localStorage.setItem("network", parseInt(walletConnection.network));
      localStorage.removeItem("wagmi.injected.shimDisconnect");
    },
  });

  useEffect(() => {
    loadUserDetails();
    //eslint-disable-next-line
  }, [walletConnection]);

  async function loadUserDetails() {
    var cname = getCoinName(walletConnection.network);
    setcoinname(cname);
    if (walletConnection.address && walletConnection.address !== "") {
      var { balance } = await getbalance("", "coin");
      var link = (config && config.txLink[walletConnection.network]) ? config.txLink[walletConnection.network] : ""
      setuserdeatils({
        balance: balance,
        address: walletConnection.address,
        txlink: link + "address/" + walletConnection.address
      });
    }
  }

  const disconnectWeb3Wallet = async () => {
    console.log('disconnectWeb3Wallet')
    try {
      disconnect();
      window.$("#wallet_detail_modal").modal("hide");
    } catch (error) {
      console.error(error);
    }
  };

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  return (
    <div
      className="modal fade primary_modal"
      id="wallet_detail_modal"
      tabIndex={-1}
      aria-labelledby="connect_walletModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="connect_walletModalLabel">
              {t('YOUR_WALLET')}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="wallet_details">
              <h3>
                {t('YOUR_ADDRESS')}
              </h3>
              <div className="wallet_address_div">
                <h3>
                  <span>{userdeatils.address}</span>{" "}
                </h3>
                <CopyToClipboard
                  text={userdeatils.address}
                  onCopy={() => onCopy()}
                >
                  <img
                    src={require("../assets/images/copy_icon.png")}
                    alt="Copy"
                  />
                </CopyToClipboard>
                {copytext && <p className="copied_txt">{t('COPIED')}</p>}
              </div>
              <div className="wallet_balance_div">
                <label>{coinname} {t('BALANCE_ONE')}</label>
                <label>{userdeatils.balance}</label>
              </div>
              <div className="text-end mt-2">
                <a href={userdeatils.txlink} target="_blank">
                  {t('VIEW_ON')} {getTxName(walletConnection.network)}
                  <i className="bi bi-box-arrow-up-right ms-2"></i>
                </a>
              </div>
              <button
                className="connect_wallet_btn w-100 mt-4"
                onClick={() => disconnectWeb3Wallet()}
              >
                {t('DISCONNECT_WALLET')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
