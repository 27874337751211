export default {
  ABOUT_NEW_FEATURES: "despre noile caracteristici.",
  ACCEPT: "Accept",
  ACTIVE_ORDERS: "Comenzi active",
  ADD_TOKEN: "Adăugați un jeton",
  AGGRESSIVE: "Agresiv",
  AND: "și",
  APPROVE: "Aproba",
  ASSETS_ON_WAY: "Activele tale sunt pe drum și ar trebui să ajungă",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Vă rugăm să evitați transferul la o adresă de schimb centralizată (Binance, Huobi ...). Nu este o caracteristică acceptată",
  BALANCE: "Echilibru",
  BALANCE_ONE: "Echilibru:",
  BLOG: "OTC",
  BRIDGE: "Pod",
  CANCEL: "Anulare",
  CANCELLATION_TRANSACTION_FEE:
    "Anularea unei comenzi limită implică plata taxelor de tranzacție.",
  CANCEL_ALL: "Anulați toate",
  CANCEL_LIMIT_NOT_FILLED:
    "Puteți anula o comandă limită atât timp cât comanda nu a fost încă completată.",
  CANCEL_LIMIT_ORDER: "Anulați comanda limită",
  CANCEL_ORDER: "Anulați comanda",
  CANCEL_SELECTED: "Anulați selectat",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Alegeți rețea",
  CLASSIC_MODE: "Mod clasic",
  CLEAR: "clar",
  CONFIRM: "A confirma",
  CONFIRMATION_REJECTED: "Confirmarea respinsă.!",
  CONFIRM_SWAP: "Confirmați swap -ul",
  CONNECT_WALLET: "Conectați portofelul",
  COPIED: "Copiat",
  CURRENT: "Actual",
  CUSTOM: "Personalizat",
  DAYS: "Zile",
  DEFAULT: "Mod implicit",
  DETAILS: "Detalii",
  DISCLAIMER: "Renunțare",
  DISCONNECT_WALLET: "Deconectați portofelul",
  DISMISS: "Respinge",
  DUPLICATE_ORDER_SUBMIT: "Comandă duplicată. Acesta este deja trimis",
  ENTER_AMT: "Introduceți suma",
  EXCHANGE: "schimb valutar",
  EXPIRY: "EXPIRARE",
  EXPIRY_SM: "Expirare",
  FAILED_LOAD_CHART: "Graficul nu a reușit să încarce",
  FIND_BY_TOKEN_OR_STATUS: "Găsiți după jeton sau statut",
  INSUFFICIENT_BALANCE: "Echilibru insuficient",
  INSUFFICIENT: "Insuficient",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "sold pentru a acoperi soldul comenzilor existente de",
  RECEIVERS: "Receptor",
  WALETADDRESS: "Adresa portofelului",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Vă rugăm să acceptați termenii de serviciu și politica de confidențialitate",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker nu ar trebui să fie aceeași adresă de preluare",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Toleranța la alunecare ar trebui să fie cuprinsă între 0,1% și 15%",
  SEARCH_A_TOKEN: "Căutați un jeton",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Tranzacția dvs. va reveni dacă prețul se va schimba nefavorabil cu mai mult decât acest procent",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Reglați prețul gazului pentru tranzacția dvs.GWEI mai mare = viteză mai mare.",
  REVIEW_OTC_ORDER: "Examinați comanda OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Perioada de expirare stabilită de producătorul de comenzi.Pe lângă această perioadă, comanda va expira și va fi arhivată.",
  SEARCH_A_LIQUIDITY_SOURCES: "Căutați surse de lichiditate",

  FAST: "Rapid",
  FAST_AND_SECURE:
    "Modul sigur, rapid și cel mai sigur de a aduce active cu lanț transversal",
  FEATURE_TO_TRANSFER:
    "Vă rugăm să nu utilizați această caracteristică pentru a vă transfera jetoanele la o adresă a unui schimb centralizat.",
  FEES: "Taxe",
  FILL_ORDER: "Completați comanda",
  FILL_OTC_ORDER: "Completați comanda OTC",
  FREE: "Gratuit",
  FROM: "Din",
  GAS_COST: "Costul gazului",
  GAS_DESTINATION: "Gaz pe lanțul de destinație",
  GAS_ON_DESTINATION: "Gaz la destinație",
  GAS_PRICE: "Prețul gazului",
  GOT_IT: "Am înţeles",
  GOT_PROBLEM_JUST: "Avem o mică problemă? Pur și simplu luați legătura.",
  HIGH: "Înalt",
  HOURS: "Ore",
  IMPORT: "Import",
  IMPOSSIBLE_TO_RETRIEVE:
    "Nu este o caracteristică acceptată. De asemenea, vă rugăm să verificați dublu adresa pe care o completați, orice jetoane trimise la adresa greșită va fi imposibil de recuperat.",
  INSTANT: "Instant",
  INSUFFICIENT_APPROVED_AMT: "Sumă insuficientă aprobată",
  INVALID_TOKEN_ADDRESS: "Adresa de jeton nevalid",
  KEEP_TRACK_NOTIFY:
    "Puteți urmări pe acesta în notificarea panoului. Vă vom anunța odată ce va fi terminat.",
  KEEP_YOU_POSTED: ". Vă vom ține la curent.",
  LEARN_MORE: "Află mai multe",
  LEGACY: "Moştenire",
  LIMIT: "Limită",
  LIMIT_ORDER: "Comanda limită",
  LIMIT_PRICE: "Prețul limită",
  LIMIT_PRICE_SM: "Prețul limită",
  LIQUIDITY_SOURCES: "Surse de lichiditate",
  LOW: "Scăzut",
  MAKER: "Producător",
  MARKET: "Piaţă",
  MAX: "Max",
  MAX_BASE_FEE: "Taxa de bază maximă",
  MAX_S: "Max",
  MAX_TO_PAY: "Max să plătească",
  MINIMUM_RECEIVED: "Minim primit",
  MINUTES: "Minute",
  MONTHS: "Luni",
  NEED_APPROVAL_MOVE: "Avem nevoie de aprobarea dvs. pentru a vă muta",
  NEED_TO_WRAP: "Trebuie să înveți",
  NETWORK_FEES: "Taxe de rețea",
  NETWORK_SM: "reţea.",
  NEVER: "Nu",
  NONE: "Nici unul",
  NON_CUSTODIAL_LIMIT:
    "în ordinea limită non-custodială. Este o cerință tehnică pe",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Încă nu aveți comenzi",
  NO_RECORDS: "Fără înregistrări",
  ON_UR_BEHALF: "în numele dumneavoastră.",
  OOPS_WRONG: "Ups! Ceva a mers prost.!",
  OPEN: "Deschis",
  ORDER_DETAILS: "Comanda Detalii",
  ORDER_HISTORY: "Istoric comenzi",
  ORDER_PRICE: "Prețul comenzii",
  ORDER_ROUTING: "Rutarea comenzii",
  OTC: "OTC",
  PASTE: "Pastă",
  PAY: "A plati",
  PENDING_CONFIRM: "În așteptarea confirmării pe portofel.",
  PER: "pe",
  PLEASE_CHOOSE_NETWORK: "Vă rugăm să alegeți rețeaua",
  PLEASE_LOGIN: "Vă rugăm să vă conectați",
  PLEASE_TRY_LATER: "Vă rugăm să încercați din nou mai târziu",
  POPULAR_TOKENS: "Jetoane populare",
  PRICE: "Preț",
  PRICE_IMPACT: "Impactul prețurilor",
  PRIVACY_POLICY: "Politica de confidențialitate",
  RATE: "Rată",
  READ_ACCEPT: "Citesc și accept",
  RECEIVE: "A primi",
  RECEIVED_ORDERS: "Comenzi primite",
  REFUND: "RESTITUIRE",
  REMOVE: "Elimina",
  REVERSE_RATE: "Rata inversă",
  REVIEW_TRADE: "Revizuirea comerțului",
  SELECTED: "selectat",
  SELECT_ALL: "Selectează tot",
  SELECT_FROM_INFO: "Vă rugăm să selectați din informații",
  SELECT_NETWORK: "Selectați o rețea",
  SELECT_TOKEN: "Selectați un jeton",
  SELECT_TOKENN: "Selectați Token",
  SELECT_TO_INFO: "Vă rugăm să selectați informațiile",
  SETTINGS: "Setări",
  SIMPLE_MODE: "Modul simplu",
  SLIPPAGE: "Alunecare",
  SLIPPAGE_TOLERANCE: "Toleranță la alunecare",
  SRY_INSUFFICIENT: "Ne pare rău, insuficient",
  STATUS: "STARE",
  STATUS_SM: "stare",
  STEP: "Etapa",
  SUBMIT: "Trimite",
  SUBMITTED_ORDERS: "Comenzi trimise",
  SUBMIT_DATE: "Data depunerii",
  SUCCESS_FILLED: "Comanda cu succes completată",
  SUCCESS_RECEIVED: "Primit cu succes",
  SUCCESS_SUBMITTED_ORDER: "A trimis cu succes comanda dvs.",
  SWAP: "Swap",
  SWAP_TRANSFER: "Swap și transfer",
  SWITCH_NETWORK: "Rețea de comutați",
  TAKER: "Taker",
  TAKER_ADDRESS: "Adresa Taker",
  TERMS_OF_SERVICE: "Termenii serviciului",
  TO: "La",
  TOKEN_NOT_FOUND: "Token nu a fost găsit",
  TOKEN_RECEIVE_SENT: "Jetonul de primit va fi trimis la",
  TRADE: "Comerț",
  TRANSACTION_REJECTED_LATER:
    "Tranzacția respinsă vă rugăm să încercați din nou mai târziu",
  TRANSFER: "Transfer",
  TRANSFER_ALERT: "Alertă de transfer",
  TRANSFER_TO: "Transfer către",
  TX_COST: "Cost TX",
  UNLOCK: "Deblocare",
  USER_GUIDE: "Manualul utilizatorului",
  USE_ITAS: "și folosește -l ca w",
  VERIFY: "Verifica",
  VIEW_EXPLORER: "Vizualizați tranzacția în Explorer",
  VIEW_ON: "Vedere",
  VIEW_TRANSACTION: "Vizualizați tranzacția",
  WRAP: "Înveliș",
  YOUR_ADDRESS: "Adresa ta",
  YOUR_ORDER_CANCEL: "Comanda dvs. este anulată",
  YOUR_WALLET: "Portofelul tau",
  YOU_GET: "Primesti",
  YOU_PAY: "Plătiți",
  YOU_RECEIVE: "Primesti",
  YOU_WILL_RECEIVE: "Vei primi",
  ANYONE_CAN_CREATE:
    "Oricine poate crea orice jeton, inclusiv versiuni false ale jetoanelor existente. Ai grijă cuvenită. Unele jetoane și parametrii lor tehnici pot fi incompatibili cu serviciile noastre. Prin importul acestui simbol personalizat, recunoașteți și acceptați riscurile.",
  BY_SIGNING_MESSAGE:
    "Prin semnarea acestui mesaj, confirmați că nu accesați această aplicație sau sunteți un rezident al SUA sau în orice altă țară restrânsă. Pentru mai multe informații, vă rugăm să citiți",
  CONSTITUTION: "Constituțiedao",
  CURRENTLY_CONNECTED: "În prezent sunteți conectat la",
  IMPORT_TOKEN: "Importă un jeton",
  IN_YOUR_WALLET: "în portofelul tău",
  MAKE_SURE_CHECKED:
    "Asigurați -vă că ați verificat adresa de jeton și este corectă",
  ON: "pe",
  PEOPLE: "oameni",
  PLEASE_SWITCH: "Vă rugăm să treceți la",
  SELECTED_NOT_SUPPORTED:
    "Vă rugăm să schimbați rețeaua în portofel, rețeaua selectată nu este acceptată",
  SIGN_FOR_ACCESS: "Semn pentru acces",
  SIGN_MESSAGE: "Mesaj de semne",
  LAUNCH_APP: "Aplicație de lansare",
  GETTING_STARTED: "Începerea cu HODL: tutoriale și explicatori de produse",
  DEEP_DIVE: "Deepdive în HODL: Strategii și caracteristici ale produsului",
  BUILD_HODL: "Construirea pe HODL: documentație tehnică",
  BLOG_HEADING:
    "Demitifierea Defi - Tot ce trebuie să știți într -un singur loc. Pentru începători la utilizatori avansați și toată lumea.",
  BLOG_PLACEHOLDER: "Căutați produsul/subiectul despre care doriți să aflați",
  CHOOSE_TOPIC: "Escolha o tópico",
  TRY: "Tentar",
  SEARCH_RESULTS: "rezultatele cautarii",
  SEARCH_TOPIC: "Subiect de căutare",
  LOAD_MORE: "Incarca mai mult",
  SOCIAL_MEDIA: "socialMedia",
  NEW: "Cel mai nou prim",
  OLD: "Cei mai in varsta primii",
  Z_A: "Sortează titlul Z-A",
  A_Z: "Sortează titlul A-Z",
  RESULTS: "Rezultate",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "O taxă de gaz de bază este calculată automat de rețea și variază de la bloc la bloc. Setați suma maximă a taxei de bază pe care doriți să o plătiți. O diferență între taxa de bază maximă pe care o stabiliți și taxa de bază reală va fi rambursată odată ce blocul a fost extras.",
  CANCELLED_TRANSACTION: "V -a anulat tranzacția",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Crearea unei comenzi limită este o operație gratuită pentru producătorul de comenzi, cu excepția cazului în care acestea anulează. Comandatorul va fi obligat să plătească taxele de tranzacție în timp ce completează comanda.",
  Cancelled: "Anulat",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Diferența de valoare între cea mai bună rută și",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Activați această opțiune dacă aveți probleme care schimbați un jeton care are taxe încorporate pentru transfer.",
  Expired: "Expirat",
  Filled: "Umplut",
  INSUFFICIENT_FOR_GASFEE:
    "Ne pare rău, fonduri insuficiente pentru taxa de gaz",
  Open: "Deschis",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Umplerea parțială se aplică numai dacă o rută de schimb constă din mai multe piese principale ale traseului. Permite o execuție parțială, împiedicând inversarea tranzacției dacă prețul de piață se deplasează în favoarea.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Prețul stabilit de producătorul de comenzi pentru executarea comenzii lor (jeton pentru a primi = x token pentru a plăti)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Prețul stabilit de producătorul de comenzi pentru executarea comenzii lor (jeton to play = y token pentru a primi",
  REVIEW_LIMIT_ORDER: "Comanda limită de revizuire",
  SEARCH_BY_TOKEN: "Căutați după jeton sau adresă",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Stabilirea unei toleranțe mari de alunecare poate ajuta tranzacțiile să aibă succes, dar este posibil să nu obțineți un preț atât de bun. Utilizați cu prudență.",
  STATUS_OF_THE_LIMIT_ORDER: "Starea comenzii limită",
  Suspended: "Suspendat",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Aceste taxe sunt plătite minerilor de rețea. HODL nu controlează taxele.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Diferența dintre prețul pieței și prețul est datorită mărimii comerțului",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Suma minimă primită, altfel tranzacția va fi revenită",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Această taxă se adresează direct minerilor ca un stimulent pentru a acorda prioritate tranzacției.",
  TRANSACTION_REJECTED_TRY:
    "Tranzacția respinsă, vă rugăm să încercați din nou",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Transferul pentru jetonul selectat nu sunt disponibile în această rețea",
  YOU: "Tu",
};
