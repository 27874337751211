import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {getCms} from "../../Api/HomeActions.js";
import { setBridgeSettings } from "../../reducers/Actions";
import config from "../../config/config";
import { useTranslation } from "react-i18next";

export default function Swap(props) {
  var { clickBridge1 } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [slippageerr, setslippageerr] = useState("");
  const [gasError, setgasError] = useState("");
  const [gasDest, setgasDest] = useState(0);
  const [cms, setCms] = useState([]);
  const settings = useSelector((state) => state.bridgesettings);
  const bridgeToken = useSelector((state) => state.bridgeToken);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => { }, []);

  useEffect(() => {
    getGasDest();
  }, [settings]);

  function getGasDest() {

  }

  function showBridge() {
    clickBridge1();
  }

  const inputChange = async (event) => {
    setslippageerr("");
    dispatch(
      setBridgeSettings({
        bslippage: event.target.value,
        bgasmode: settings.gasmode,
        bvalue: settings.bvalue,
      })
    );

    if (event.target.value > 15 || event.target.value < 0.1) {
      setslippageerr(t("SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN"));
      return;
    }
    localStorage.setItem("bslippage", event.target.value);
  };

  function setMode(type) {
    try {
      localStorage.setItem("bgasmode", type);
      let defaultVal = config.bridge[bridgeToken.tochainId].default;
      let bvalue = type == "None" ? 0 : type == "Default" ? defaultVal : 0;
      dispatch(
        setBridgeSettings({
          bslippage: settings.bslippage,
          bgasmode: type,
          bvalue: bvalue,
        })
      );

      var nwk = bridgeToken.tochainId;
      localStorage.setItem("bvalue-" + nwk, bvalue);
    } catch (err) {

    }

  }

  const inputChange1 = async (event) => {
    setgasError("");
    var value = event.target.value;
    let maxfee = config.bridge[bridgeToken.tochainId].maxfee;
    if (parseFloat(value) > parseFloat(maxfee)) {
      setgasError("Requested gas on destination exceeds the max: " + maxfee);
      value = maxfee;
    }


    setslippageerr("");
    dispatch(
      setBridgeSettings({
        bslippage: settings.bslippage,
        bgasmode: settings.bgasmode,
        bvalue: value,
      })
    );
    var nwk = bridgeToken.tochainId;
    localStorage.setItem("bvalue-" + nwk, value);
  };

  function setSlippage(value) {
    localStorage.setItem("bslippage", value);
    dispatch(
      setBridgeSettings({
        bslippage: value,
        bgasmode: settings.bgasmode,
        bvalue: settings.bvalue,
      })
    );
  }

  function Maxvalue() {
    try {
      let maxfee = config.bridge[bridgeToken.tochainId].maxfee;
      var nwk = bridgeToken.tochainId;
      localStorage.setItem("bvalue-" + nwk, maxfee);
      dispatch(
        setBridgeSettings({
          bslippage: settings.bslippage,
          bgasmode: settings.bgasmode,
          bvalue: maxfee,
        })
      );
    } catch (err) { }
  }
  async function handleClick(subcategory) {
    console.log(cms, 'cmsdata', subcategory)
    const datas = cms && cms.length > 0 && cms.find((item) => item.identifier == subcategory)
    console.log(datas, 'datas')
    if (datas && datas.linkstatus == true) {
      window.location.href = datas.link
    }
    if (datas && datas.linkstatus == false) {

      window.location.href = datas.identifier;
    }
  }
  async function getcms() {

    var { result } = await getCms();
    setCms(result)


  }
  useEffect(() => {

    getcms()

  }, [])
  return (
    <div className="bridge_right">
      <div className="swap_box">
        <div className="heading_block">
          <span>{t('BRIDGE')}</span>
          <div>
            <i onClick={() => showBridge()}>
              <img
                src={require("../../assets/images/close_icon.png")}
                alt="Close"
              />
            </i>
          </div>
        </div>
        <div className="bridge_settings">
          <div className="modal_inner_head mt-3">
            <h2>{t('GAS_DESTINATION')}</h2>
            <img
              src={require("../../assets/images/info_icon.png")}
              alt="Info"
              id="gasprice_tooltip"
            />
            <ReactTooltip
              anchorId="gasprice_tooltip"
              place="top"
              content={t("ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION")}
            />
          </div>
          <div className="modal_grp_btns">
            <button
              className={
                settings && settings.bgasmode === "None"
                  ? "settings_btn settings_btn_selected"
                  : "settings_btn"
              }
              onClick={() => setMode("None")}
            >
              {t('NONE')}
            </button>
            <button
              className={
                settings && settings.bgasmode === "Default"
                  ? "settings_btn settings_btn_selected"
                  : "settings_btn"
              }
              onClick={() => setMode("Default")}
            >
              {t('DEFAULT')}
            </button>
          </div>
          <div className="bridge_inp mt-4">
            <div className="input-group slipage_inp_grp slipage_inp_grp_nobg mt-1">
              <input
                type="text"
                className="form-control"
                aria-label="slipage"
                aria-describedby="slipage"
                defaultValue={0}
                onChange={inputChange1}
                value={settings.bvalue}
              />
              <button
                className="input-group-text"
                id="bridge_max"
                onClick={() => Maxvalue()}
              >
                <span>{t('MAX_S')}</span>
              </button>
              <p className="errmsg">{gasError}</p>
            </div>
          </div>
          <hr className="custom_hr" />
          <div className="modal_inner_head">
            <h2>{t('SLIPPAGE_TOLERANCE')}</h2>
            <img
              src={require("../../assets/images/info_icon.png")}
              alt="Info"
              id="slippage_tooltip"
            />
            <ReactTooltip
              anchorId="slippage_tooltip"
              place="top"
              content={t("YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE")}
            />
          </div>
          <div className="modal_grp_btns">
            <button
              className={
                parseFloat(settings.bslippage) === 0.1
                  ? "settings_btn settings_btn_selected"
                  : "settings_btn"
              }
              onClick={() => setSlippage("0.1")}
            >
              0.1%
            </button>
            <button
              className={
                parseFloat(settings.bslippage) === 0.5
                  ? "settings_btn settings_btn_selected"
                  : "settings_btn"
              }
              onClick={() => setSlippage("0.5")}
            >
              0.5%
            </button>
            <button
              className={
                parseFloat(settings.bslippage) === 1
                  ? "settings_btn settings_btn_selected"
                  : "settings_btn"
              }
              onClick={() => setSlippage("1")}
            >
              1%
            </button>
          </div>
          <div className="input-group slipage_inp_grp slipage_inp_grp_nobg">
            <input
              type="number"
              className="form-control"
              aria-label="slipage"
              aria-describedby="slipage"
              onChange={inputChange}
              value={settings.bslippage}
            />
            <span className="input-group-text" id="slipage">
              %
            </span>
          </div>
          <div className="mt-4 d-flex align-items-center">
            <img
              src={require("../../assets/images/book_icon.png")}
              alt="Info" className="me-2"
            />
            <p className="mb-0"> <a  href="javascript:void(0)"onClick={() => handleClick('learn-more')} target="_blank"><b>{t('LEARN_MORE')}</b></a> {t('ABOUT_NEW_FEATURES')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
