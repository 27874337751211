import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getParameterByName } from "../helper/custommath";
import { useTranslation } from "react-i18next";

export default function SwapMenu(props) {
    const { t } = useTranslation();

    const walletConnection = useSelector((state) => state.walletConnection);

    return (

        <div className="d-flex align-center">
            <span>
                {location.pathname == "/" ?
                    <a
                        href="/swap-simple"
                        className={(getParameterByName("page") == "swap" || getParameterByName("page") == null) ? "active" : ""}
                    >
                        {t('SWAP')}{" "}
                    </a> :
                    <a
                        href="/swap-classic"
                        className={
                            (location.pathname === "/swap-classic" || location.pathname === "/swap-simple") ? "active" : ""
                        }
                    >
                        {t('SWAP')}{" "}
                    </a>
                }
            </span>
            <span className="ms-3">
                {" "}
                <a
                    href="/transfer"
                    className={
                        location.pathname === "/transfer" ? "active" : ""
                    }
                >
                    {t('TRANSFER')}{" "}
                </a>
            </span>
            {walletConnection && walletConnection.network != 42161
                && walletConnection && walletConnection.network != 8453
                && walletConnection && walletConnection.network != 10 &&
                <span className="ms-3">
                    {" "}
                    <a
                        href="/swap-limit"
                        className={
                            location.pathname === "/swap-limit" ? "active" : ""
                        }
                    >
                        {t('LIMIT')}
                    </a>
                </span>
            }
            {walletConnection && walletConnection.network != 42161
                && walletConnection && walletConnection.network != 8453
                && walletConnection && walletConnection.network != 10 &&
                <span className="ms-3">
                    {" "}
                    <a
                        href="/p2p"
                        className={location.pathname === "/p2p" ? "active" : ""}
                    >
                        {t('OTC')}
                    </a>
                </span>
            }
        </div>



    );
}
