import React, { useEffect, useState } from "react";

import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";

import Transfer from "./transfer.js";
import Settings from "./settings";
import WalletConfirm from "./walletConfirm";
import BridgeSuccess from "./bridge-success";
import { useTranslation } from "react-i18next";
import { getCms } from "../../Api/HomeActions.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Bridge(props) {
  const { t } = useTranslation();

  const [showSwap, setshowSwap] = useState(true);
  const [showSettings, setshowSettings] = useState(false);
  const [showconfirm, setshowconfirm] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [cms, setCms] = useState([]);

  function clickSettings() {
    setshowSwap(false);
    setshowSettings(true);
  }
  function clickBridge() {
    setshowSettings(false);
    setshowSwap(true);
  }

  function confirmSwap(status, ispay) {
    if (ispay) {
      setshowSettings(false);
      setshowSwap(false);
      setshowconfirm(false);
      setshowSuccess(true);
    } else if (status) {
      setshowSettings(false);
      setshowSwap(false);
      setshowconfirm(true);
      setshowSuccess(false);
    } else {
      setshowSwap(true);
      setshowSettings(false);
      setshowconfirm(false);
      setshowSuccess(true);
    }
  }

  async function handleClick(subcategory) {
    console.log(cms, 'cmsdata', subcategory)
    const datas = cms && cms.length > 0 && cms.find((item) => item.identifier == subcategory)
    console.log(datas, 'datas')
    if (datas && datas.linkstatus == true) {
      // window.location.href = datas.link
      window.open(datas.link, '_blank');
    }
    if (datas && datas.linkstatus == false) {

      // window.location.href = datas.identifier;
      window.open('/' + datas.identifier, '_blank');
    }
  }
  async function getcms() {

    var { result } = await getCms();
    setCms(result)


  }
  useEffect(() => {

    getcms()

  }, [])
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_page_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
              <div className="bridge_flex">
                <div className="bridge_left">
                  <div className="swap_box">
                    <div className="heading_block">
                      <span>HODL {t('BRIDGE')}</span>
                    </div>
                    <p>
                      {t('FAST_AND_SECURE')} HODL {t('CHAINS')}.
                    </p>
                    <a href="javascript:void(0)" onClick={() => handleClick('user-guide')} target="_blank">
                      {t('USER_GUIDE')}
                    </a>
                    <a href="javascript:void(0)" onClick={() => handleClick('get-in-touch')} target="_blank">
                      {t('GOT_PROBLEM_JUST')}
                    </a>
                  </div>
                </div>
                {showSwap ?
                  <Transfer
                    clickSettings1={clickSettings}
                    confirmSwap1={confirmSwap}
                  /> :
                  (showSettings) ?
                    <Settings
                      clickBridge1={clickBridge}
                    /> : (showconfirm) ?
                      <WalletConfirm /> :
                      (showSuccess) ? <BridgeSuccess /> :
                        <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}