import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

import Swapsection from "../../components/Swapsection";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SwapSimple(props) {

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_page_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-5 col-xl-4 m-auto">
              {/* Swap Div */}
              <Swapsection
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
