import { combineReducers } from "redux";

import web3Reucer from './webReducer';
import walletConnection from '../reducers/walletConnection';
import tokenList from '../reducers/tokenList';
import swapToken from './swapToken';
import settings from './settings';
import gasValue from './gasValue';
import { isMarket, isRoute } from './loadingState';
import network from './selectedNetwork';
import bridgeToken from './bridgeToken';
import bridgeNetwork from './bridgeNetwork';
import bridgesettings from './bridgesettings';
import transfer from './transferValue';
import transferstatus from './transferStatus';
import chainGasCost from './chainGasCost';
import sourceCount from './sourceCount';
import limitOrderToken from './limitOrderToken';
import pairToken from './pairToken';
import marketPrice from './marketPrice';
import loadHistory from './loadHistory';
import swapData from './swapData';
import signIn from './signIn';
import lang from './lang';
export default combineReducers({
    web3Reucer,
    walletConnection,
    tokenList,
    swapToken,
    settings,
    gasValue,
    isMarket,
    isRoute,
    network,
    bridgeToken,
    bridgeNetwork,
    bridgesettings,
    transfer,
    transferstatus,
    chainGasCost,
    sourceCount,
    limitOrderToken,
    pairToken,
    marketPrice,
    loadHistory,
    swapData,
    signIn,
    lang
});