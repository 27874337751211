// import constant
import {
    SETTINGS
} from '../constant';

const initialState = {
    slippage: (localStorage.getItem("slippage") &&
        parseFloat(localStorage.getItem("slippage")) > 0) ? parseFloat(localStorage.getItem("slippage")) : 0.5,
    gasmode: localStorage.getItem("gasmode") &&
        (localStorage.getItem("gasmode") !== "") ? localStorage.getItem("gasmode") : "Normal",
};

const settings = (state = initialState, action) => {

    switch (action.type) {
        case SETTINGS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default settings;