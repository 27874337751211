var limitorder = {
    1: "0xe92b586627ccA7a83dC919cc7127196d70f55a06",
    56: "0x8DcDfe88EF0351f27437284D0710cD65b20288bb",
    137: "0xF3CD476C3C4D3Ac5cA2724767f269070CA09A043",
    43114: "0x34302c4267d0dA0A8c65510282Cc22E9e39df51f",
    250: "0x2DF17455B96Dde3618FD6B1C3a9AA06D6aB89347",
    42161: "0x0927FD43a7a87E3E8b81Df2c44B03C4756849F6D",
    10: "0x0927FD43a7a87E3E8b81Df2c44B03C4756849F6D",
    8453: "0xa003dFBA51C9e1e56C67ae445b852bdEd7aC5EEd",
};
export default limitorder;