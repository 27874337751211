import { ParaSwap, NetworkID } from "paraswap";

import BigNumber from "bignumber.js";
import { OptimalRate, SwapSide } from "paraswap-core";

const USER_ADDRESS =
  /* process.env.USER_ADDRESS */ "0x737E1905b84B41BB664B94159799816c759E99f9";

const PARTNER = "0x23a1D5CFce967641Aca9DFc19D043d458b623761";
const SLIPPAGE = 1; // 1%

enum Networks {
  MAINNET = 1,
  POLYGON = 137,
  BSCAN = 56
}

interface MinTokenData {
  decimals: number;
  symbol: string;
  address: string;
}

const web3ProividersURLs: Partial<Record<number, string>> = {
  [Networks.MAINNET]: process.env.MAINNET_PROVIDER_URL,
  [Networks.POLYGON]: process.env.POLYGON_PROVIDER_URL
};

const tokens: Record<number, MinTokenData[]> = {
  [Networks.MAINNET]: [
    {
      decimals: 18,
      symbol: "ETH",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
    },
    {
      decimals: 6,
      symbol: "USDC",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    {
      decimals: 18,
      symbol: "DAI",
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F"
    }
  ],
  [Networks.POLYGON]: [
    {
      decimals: 18,
      symbol: "MATIC",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
    },
    {
      decimals: 8,
      symbol: "WBTC",
      address: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6"
    }
  ],
  [Networks.BSCAN]: [
    {
      decimals: 18,
      symbol: "BNB",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
    },
    {
      decimals: 18,
      symbol: "USDC",
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
    }
  ]
};

function getToken(address: Address, networkID = Networks.MAINNET,list:any): MinTokenData {
  const token = list?.find((t:any) => t.address.toLowerCase() === address.toLowerCase());

  if (!token)
    throw new Error(`Token ${address} not available on network ${networkID}`);
  return token;
}

/**
 * @type ethereum address
 */
type Address = string;
/**
 * @type Token symbol
 */
type Symbol = string;
/**
 * @type number as string
 */
type NumberAsString = string;

interface TransactionParams {
  to: Address;
  from: Address;
  value: NumberAsString;
  data: string;
  gasPrice: NumberAsString;
  gas?: NumberAsString;
  chainId: number;
}

interface Swapper {
  getRate(params: {
    srcToken: Pick<MinTokenData, "address" | "decimals">;
    destToken: Pick<MinTokenData, "address" | "decimals">;
    srcAmount: NumberAsString;
    userAddress: Address;
    partner?: string;
  }): Promise<OptimalRate>;
  buildSwap(params: {
    srcToken: Pick<MinTokenData, "address" | "decimals">;
    destToken: Pick<MinTokenData, "address" | "decimals">;
    srcAmount: NumberAsString;
    minAmount: NumberAsString;
    priceRoute: OptimalRate;
    userAddress: Address;
    receiver?: Address;
    partner?: string;
  }): Promise<TransactionParams>;
}

function createSwapper(networkID: number, apiURL?: string): Swapper {
  const paraswap = new ParaSwap(
    networkID as NetworkID,
    apiURL,
    web3ProividersURLs[networkID]
  );

  const getRate: Swapper["getRate"] = async ({
    srcToken,
    destToken,
    srcAmount,
    userAddress,
    partner = PARTNER
  }) => {
    const priceRouteOrError = await paraswap.getRate(
      srcToken.address,
      destToken.address,
      srcAmount,
      userAddress,
      SwapSide.SELL,
      { partner },
      srcToken.decimals,
      destToken.decimals
    );

    if ("message" in priceRouteOrError) {
      throw new Error(priceRouteOrError.message);
    }

    return priceRouteOrError;
  };

  const buildSwap: Swapper["buildSwap"] = async ({
    srcToken,
    destToken,
    srcAmount,
    minAmount,
    priceRoute,
    userAddress,
    receiver,
    partner
  }) => {
    
    const transactionRequestOrError = await paraswap.buildTx(
      srcToken.address,
      destToken.address,
      srcAmount,
      minAmount,
      priceRoute,
      userAddress,
      partner,
      undefined,
      undefined,
      receiver,
    );

    if ("message" in transactionRequestOrError) {
      throw new Error(transactionRequestOrError.message);
    }

    return transactionRequestOrError as TransactionParams;
  };

  return { getRate, buildSwap };
}

interface GetSwapTxInput {
  srcToken: string;
  srcSymbol: string;
  srcDecimals: number;
  destToken: string;
  destSymbol: string;
  destDecimals: number;
  srcAmount: NumberAsString; // in srcToken denomination
  networkID: number;
  slippage?: number;
  partner?: string;
  userAddress: Address;
  receiver?: Address;
}


export async function getSwapTransaction({
  srcToken: srcTokenAddress,
  srcSymbol: srcSymbol,
  srcDecimals: srcDecimals,
  destToken: destTokenAddress,
  destSymbol: destSymbol,
  destDecimals: destDecimals,
  srcAmount: _srcAmount,
  networkID,
  slippage : slippage,
  partner:partner,
  userAddress,
  receiver,
  ...rest
}: any): Promise<TransactionParams> {
  try {
    let test = {...rest}
    
    const srcToken = {
      decimals: (srcDecimals)?srcDecimals:0,
      symbol: (srcSymbol)?srcSymbol:"",
      address: (srcTokenAddress)?srcTokenAddress:""
    };
    const destToken = {
      decimals: (destDecimals)?destDecimals:0,
      symbol: (srcSymbol)?srcSymbol:"",
      address: (destTokenAddress)?destTokenAddress:""
    };
    var srcAmount = new BigNumber(_srcAmount)
      .times(10 ** srcToken.decimals)
      .toFixed(0);

    if(rest && rest.priceRoute && rest.priceRoute.side=="BUY"){
      srcAmount = new BigNumber(_srcAmount).times(10 ** srcToken.decimals).toFixed(0);
      srcAmount= new BigNumber(srcAmount)
      .times(1 + slippage / 100)
      .toFixed(0);
    }
    const ps = createSwapper(networkID);

    const priceRoute = await ps.getRate({
      srcToken,
      destToken,
      srcAmount,
      userAddress,
      partner
    });

    slippage = (slippage)?slippage:1;

    //console.log(priceRoute,'priceRoutepriceRoutepriceRoute')
    var minAmount = new BigNumber(priceRoute.destAmount)
      .times(1 - slippage / 100)
      .toFixed(0);

    // var minAmount = new BigNumber(priceRoute.destAmount).toFixed(0);

    if(rest && rest.priceRoute && rest.priceRoute.side=="BUY" && rest.priceRoute.destAmount){
      minAmount = new BigNumber(rest.priceRoute.destAmount).toFixed(0);
    }
    //console.log({...rest},'...rest')
    const transactionRequest = await ps.buildSwap({
      srcToken,
      destToken,
      srcAmount,
      minAmount,
      priceRoute,
      userAddress,
      receiver,
      partner,
      ...rest
    });

    console.log("TransactionRequest", transactionRequest);

    return transactionRequest;
  } catch (error) {
    console.log(error,'getSwapTransactiongetSwapTransaction');
    var err ={
        chainId: 56,
        data: "",
        from: "",
        gas: "",
        gasPrice: "",
        to: "",
        value: "",
        error:error
    }
    return  err;
  }
}

// export const getExampleSwapTransaction = () =>
//   getSwapTransaction({
//     srcAmount: "1",
//     srcToken: "MATIC",
//     destToken: "WBTC",
//     networkID: Networks.POLYGON,
//     userAddress: USER_ADDRESS
//   });
