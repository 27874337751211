import txLink from "./txLink";
import rpc from "./rpc";

var network = {
    1: [{
        chainId: '0x1',
        chainName: 'Ethereum Mainet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: [rpc[1]],
        blockExplorerUrls: [txLink[1]]
    }],
    10: [{
        chainId: '0xa',
        chainName: 'Optimism',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: [rpc[10]],
        blockExplorerUrls: [txLink[10]]
    }],
    56: [{
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: [rpc[56]],
        blockExplorerUrls: [txLink[56]]
    }],
    128: [{
        chainId: '0x80',
        chainName: 'Huobi ECO Chain Mainnet',
        nativeCurrency: {
            name: 'HT',
            symbol: 'HT',
            decimals: 18
        },
        rpcUrls: [rpc[128]],
        blockExplorerUrls: [txLink[128]]
    }],
    137: [{
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: [rpc[137]],
        blockExplorerUrls: [txLink[137]]
    }],
    250: [{
        chainId: '0xfa',
        chainName: 'Fantom',
        nativeCurrency: {
            name: 'FTM',
            symbol: 'FTM',
            decimals: 18
        },
        rpcUrls: [rpc[250]],
        blockExplorerUrls: [txLink[250]]
    }],
    42161: [{
        chainId: '0xa4b1',
        chainName: 'Arbitrum',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: [rpc[42161]],
        blockExplorerUrls: [txLink[42161]]
    }],
    43114: [{
        chainId: '0xa86a',
        chainName: 'Avalanche C-Chain',
        nativeCurrency: {
            name: 'AVAX',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: [rpc[43114]],
        blockExplorerUrls: [txLink[43114]]
    }],
    8453: [{
        chainId: '0x2105',
        chainName: 'Base',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: [rpc[8453]],
        blockExplorerUrls: [txLink[8453]]
    }],


}
export default network;