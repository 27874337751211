import { connection } from "../helper/connection";

import store from '../store';
import MiddlewareABI from "../ABI/Middleware.json";
import SwapRouterABI from "../ABI/SwapRouterABI.json";
import config from "../config/config";
import { convert } from "../helper/convert";


export const swapParaswapContract = async (tuple1, method) => {
    // console.log(data, 'data,contractAddress')
    //console.log(tuple1, 'tuple1tuple1tuple1tuple1')
    var currentProvider = store.getState();
    var gasData = currentProvider.gasValue;
    console.log("swapParaswapContract")
    //return;
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var network = get.network;

        var gasFee = gasData.selected;
        var gasPrice = await web3.eth.getGasPrice();
        console.log(gasFee, 'gasFeegasFee')
        gasFee = (parseFloat(gasFee) > 0) ? web3.utils.toWei(gasFee.toString(), 'gwei') : gasPrice;
        console.log(gasFee, 'gasFeegasFee1')
        console.log(gasPrice, 'gasFeegasFee2')
        var router = config.router[network];

        var Contract = new web3.eth.Contract(SwapRouterABI, router);

        var val = "0";
        if (method == "directUniV3Swap" && tuple1 && tuple1[0].toLowerCase() == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            val = convert(tuple1[3]);
        } else if (method == "simpleSwap" && tuple1 && tuple1[0].toLowerCase() == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            val = convert(tuple1[2]);
        } else if (method == "multiSwap" && tuple1 && tuple1[0].toLowerCase() == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            val = convert(tuple1[1]);
        }
        var result = {};
        var estimateGas = 30000;
        if (method == "directUniV3Swap") {
            estimateGas = await Contract.methods.directUniV3Swap(
                tuple1
            ).estimateGas({ from: address, value: val, gasPrice: gasFee });
            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            result = await Contract.methods.directUniV3Swap(
                tuple1
            ).send({ from: address, gasPrice: gasFee, gasLimit: estimateGas, value: val });
        } else if (method == "simpleSwap") {
            estimateGas = await Contract.methods.simpleSwap(
                tuple1
            ).estimateGas({ from: address, value: val, gasPrice: gasFee });

            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            result = await Contract.methods.simpleSwap(
                tuple1
            ).send({ from: address, gasPrice: gasFee, gasLimit: estimateGas, value: val });
        } else if (method == "multiSwap") {
            estimateGas = await Contract.methods.multiSwap(
                tuple1
            ).estimateGas({ from: address, value: val, gasPrice: gasFee });

            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            result = await Contract.methods.multiSwap(
                tuple1
            ).send({ from: address, gasPrice: gasFee, gasLimit: estimateGas, value: val });
        }


        return {
            txdata: result,
            error1: null
        }
    } catch (err) {
        console.log(err, "swapParaswapContract errr")
        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("gas required exceeds");
        var pos1 = errMsg.search("insufficient");
        var pos2 = errMsg.search("User denied transaction signature")
        var message = errMsg;
        if (pos >= 0 || pos1 >= 0) {
            message = "INSUFFICIENT_FOR_GASFEE"
        } else if (pos2 >= 0) {
            message = "CONFIRMATION_REJECTED"
        } else {
            message = "PLEASE_TRY_LATER";
        }
        return {
            txdata: {},
            error1: message
        }
    }

}

export const fillOrderMiddleware = async (tuple1, tokenTransferProxy) => {
    // console.log(data, 'data,contractAddress')
    //console.log(tuple1, 'tuple1tuple1tuple1tuple1')
    var currentProvider = store.getState();
    var gasData = currentProvider.gasValue;

    //return;
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var network = get.network;

        var gasFee = gasData.selected;
        gasFee = (parseFloat(gasFee) > 0) ? web3.utils.toWei(gasFee.toString(), 'gwei') : web3.utils.toWei("90", 'gwei');

        var middlreware = config.middlreware[network];

        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(MiddlewareABI, middlreware);

        var val = 0 * 10 ** 18;
        val = val.toString();
        var inputType = "1";
        if (tuple1 && tuple1[0].toLowerCase() == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            inputType = "2";
            val = tuple1[2];
        }

        var estimateGas = await Contract.methods.simpleBuy(
            tuple1,
            inputType,
            tokenTransferProxy
        ).estimateGas({ from: address, value: val });

        estimateGas = parseFloat(estimateGas) + parseFloat(30000);
        var result = await Contract.methods.simpleBuy(
            tuple1,
            inputType,
            tokenTransferProxy
        ).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas, value: val });

        return {
            txdata: result,
            error1: null
        }
    } catch (err) {
        console.log(err, 'swapMiddleware errerrerr')
        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("gas required exceeds");
        var pos1 = errMsg.search("Insufficient");
        var pos2 = errMsg.search("User denied transaction signature")
        var message = errMsg;
        if (pos >= 0 || pos1 >= 0) {
            message = "Sorry, Insufficient funds for gas fee"
        } else if (pos2 >= 0) {
            message = "Confirmation rejected"
        }
        return {
            txdata: {},
            error1: message
        }
    }

}

export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function isAddress(address) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var status = web3.utils.isAddress(address)
            return {
                value: status,
                status: true
            };
        } else {
            return {
                value: false,
                status: false
            };
        }
    } catch (err) {

        return {
            value: false,
            status: false
        };
    }
}


export async function getEstimateSwapFee(gasLimit) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var extra = 500000;
            console.log(gasLimit, 'gasLimitgasLimit')
            gasLimit = (parseFloat(gasLimit) > 0) ? parseFloat(gasLimit) + parseFloat(extra) : 100000;
            console.log(gasLimit, 'gasLimitgasLimit')
            var txFee = await web3.eth.getGasPrice() * gasLimit;
            return {
                txFee: txFee,
                status: true
            };
        } else {
            return {
                txFee: 0,
                status: false
            };
        }
    } catch (err) {

        return {
            txFee: 0,
            status: false
        };
    }
}