import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { GetTokenList } from "../../Api/TokenActions";
import { getTokenLogo } from "../../helper/networkdata";
import config from "../../config/config";
import { displayDays } from "../../helper/dateFormat";
import { formatNumber } from "../../helper/custommath";
import CancelLimitModal from "./cancelLimitModal";
import FillTradeModal from "./fillTradeModal";
import { shortText } from "../../helper/custommath";
import LimitOrderDetails from "./limitOrderDetails";
import { GetUserP2pOrders } from "../../Api/LimitOrderActions";
import { getOrderStatus } from "./swap-details";
import { useTranslation } from "react-i18next";

export default function P2POrderList(props) {
  const { t } = useTranslation();

  const walletConnection = useSelector((state) => state.walletConnection);
  const loadHistory = useSelector((state) => state.loadHistory);

  const [tokenList, settokenList] = useState([]);
  const [isloaded, setisloaded] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [cancelHash, setcancelHash] = useState([]);
  const [isCancel, setisCancel] = useState(false);
  const [fillOrderInfo, setfillOrderInfo] = useState({});
  const [tokenrate, settokenrate] = useState({ price: 0, price1: 0 });
  const [showExpire, setshowExpire] = useState({
    value: "Never",
    level: "Never",
  });
  const [activeOrders, setactiveOrders] = useState([]);
  const [allactiveOrders, setallactiveOrders] = useState([]);
  const [orderhistory, setorderhistory] = useState([]);
  const [allorderhistory, setallorderhistory] = useState([]);
  const [takerOrders, settakerOrders] = useState([]);
  const [alltakerOrders, setalltakerOrders] = useState([]);

  const [orderInfo, setorderInfo] = useState({});

  useEffect(() => { }, [cancelHash]);

  useEffect(() => {
    getOrderHistory();
  }, [walletConnection]);

  useEffect(() => {
    if (loadHistory.status) {
      getOrderHistory();
    }
  }, [loadHistory.status]);

  async function getTList() {
    var ntId = localStorage.getItem("network");
    ntId = parseInt(ntId) > 0 ? parseInt(ntId) : 56;
    let chainId =
      walletConnection && walletConnection.network
        ? walletConnection.network
        : ntId;
    var data = {
      chainId: chainId,
      useraddress: walletConnection.address,
    };
    var { result } = await GetTokenList(data);
    if (result) {
      var list = JSON.parse(result);
      settokenList(list);
      return list;
    }
  }

  function setcancelList(hash) {
    var index = cancelHash.findIndex((val) => val == hash);
    var NewList = cancelHash;
    if (index === -1) {
      var orderIndex = activeOrders.findIndex(val => val.orderHash == hash)

      var checkExpire = parseFloat(activeOrders[orderIndex].expiry) * 1000;
      checkExpire = new Date(checkExpire);
      if (checkExpire < new Date() && parseFloat(activeOrders[orderIndex].expiry) !== 0) {
        toastAlert("error", "Order is expired", "order");
        return;
      }
      cancelHash.push(hash);
      NewList = cancelHash;
    } else {
      cancelHash.splice(index, 1);
      NewList = cancelHash;
    }
    setcancelHash(NewList);
    setisCancel(NewList.length > 0 ? true : false);
  }

  async function cancelSelected() {
    setTimeout(function () {
      window.$("#cancel_limit_modal").modal("show");
    }, 500);
  }

  async function cancelAll() {
    const hashList = activeOrders.map((val) => val.orderHash);

    for (var o = 0; o < activeOrders.length; o++) {
      var checkExpire = parseFloat(activeOrders[o].expiry) * 1000;
      checkExpire = new Date(checkExpire);
      if (checkExpire < new Date() && parseFloat(activeOrders[o].expiry) !== 0) {
        toastAlert("error", "Order is expired", "order");
        break;
      }
    }

    setcancelHash(hashList);
    setTimeout(function () {
      window.$("#cancel_limit_modal").modal("show");
    }, 500);
  }

  async function getOrderHistory() {
    console.log("getOrderHistory");
    if (walletConnection && walletConnection.address) {
      setcancelHash([]);
      setisCancel(false);
      setisloaded(false);
      setisloading(true);

      var list = tokenList;
      if (list && list.length == 0) {
        let response = await getTList();
        list = response;
      }
      var userInfo = {
        address: walletConnection.address,
        chainId: walletConnection.network,
        assetType: "",
      };
      var { orders } = await GetUserP2pOrders(userInfo);
      userInfo.assetType = "maker";
      var makerList = await GetUserP2pOrders(userInfo);
      makerList = makerList && makerList.orders ? makerList.orders : [];

      var receiveList = await historyList(orders, 2);
      settakerOrders(receiveList);
      setalltakerOrders(receiveList);

      var submitList = await historyList(makerList, 2);
      setactiveOrders(submitList);
      setallactiveOrders(submitList);

      var orderList = orders.concat(makerList);

      //updatelimitorders({ list: JSON.stringify(orderList) });

      var histories = await historyList(orderList, 1);

      setorderhistory(histories);
      setallorderhistory(histories);

      setisloaded(true);
      setisloading(false);
    }
  }

  async function historyList(orderList, cond) {
    var list = tokenList;
    if (list && list.length == 0) {
      let response = await getTList();
      list = response;
    }
    // var cancelIds = localStorage.getItem("cancelIds");
    // var listIds = [];
    // if (cancelIds) {
    //     listIds = cancelIds.split(",");
    // }

    // var fillOrderIds = localStorage.getItem("fillOrderIds");
    // var flistIds = [];
    // if (fillOrderIds) {
    //     flistIds = fillOrderIds.split(",");
    // }

    var hList = [];
    for (var a = 0; a < orderList.length; a++) {
      // var alreadyCancel = listIds.findIndex(val => val == orderList[a].orderHash)
      //var alreadyFilled = flistIds.findIndex(val => val == orderList[a].orderHash)

      var obj = orderList[a];

      var makerLogo = config.defaultLogo;
      var makerSymbol = "";
      var makerDecimals = 18;
      var mAmount = 0;
      var mindex = list.findIndex(
        (val) =>
          val.address.toLowerCase() == orderList[a].makerAsset.toLowerCase()
      );

      if (mindex !== -1) {
        makerLogo = list[mindex].logoURI;
        makerSymbol = list[mindex].symbol;
        makerDecimals = list[mindex].decimals;
        mAmount = parseFloat(orderList[a].makerAmount);
        mAmount = orderList[a].makerAmount / 10 ** makerDecimals;
        mAmount = formatNumber(mAmount, 5);
      }

      var takerLogo = config.defaultLogo;
      var takerSymbol = "";
      var takerDecimals = 18;
      var tAmount = 0;
      var tindex = list.findIndex(
        (val) =>
          val.address.toLowerCase() == orderList[a].takerAsset.toLowerCase()
      );

      if (tindex !== -1) {
        takerLogo = list[tindex].logoURI;
        takerSymbol = list[tindex].symbol;
        takerDecimals = list[tindex].decimals;
        tAmount = parseFloat(orderList[a].takerAmount);
        tAmount = tAmount / 10 ** takerDecimals;
        tAmount = formatNumber(tAmount, 5);
      }

      Object.assign(obj, {
        makerLogo,
        makerSymbol,
        makerDecimals,
        mAmount,
        takerLogo,
        takerSymbol,
        takerDecimals,
        tAmount,
      });

      var checkExpire = parseFloat(orderList[a].expiry) * 1000;
      checkExpire = new Date(checkExpire);
      if (new Date() > checkExpire && orderList[a].expiry !== 0) {
        orderList[a].state = "EXPIRED";
      }

      if (cond == 1) {
        if (orderList && orderList[a] && orderList[a].state !== "PENDING") {
          hList.push(obj);
        }
      } else if (cond == 2) {
        if (orderList && orderList[a] && orderList[a].state == "PENDING") {
          //console.log(alreadyFilled, 'alreadyFilledalreadyFilled', obj.makerSymbol)
          // if (alreadyFilled !== -1) {
          //     obj.state = "Inprocess";
          // }
          hList.push(obj);
        }
      }
    }

    return hList;
  }

  function openFillOrder(items) {
    console.log(items, "itemsitemsitemsitems");
    // return;
    var spender = config.tokenProxy[items.chainId];
    var details = {
      fromlogo: items.makerLogo,
      fromsymbol: items.makerSymbol,
      pay: items.makerAmount,
      tologo: items.takerLogo,
      tosymbol: items.takerSymbol,
      receive: items.takerAmount,
      maker: items.maker,
      fromaddress: items.makerAsset,
      toaddress: items.takerAsset,
      fromdecimal: items.makerDecimals,
      todecimal: items.takerDecimals,
      chainId: items.chainId,
      spender: spender,
      expiry: items.expiry,
      nonceAndMeta: items.nonceAndMeta,
      signature: items.signature,
      orderHash: items.orderHash,
    };

    setfillOrderInfo(details);
    setTimeout(function () {
      window.$("#fill_trade_modal").modal("show");
    }, 500);
  }

  const filterActiveOrders = async (event) => {
    var value = event.target.value;
    if (
      value &&
      value !== "" &&
      allactiveOrders &&
      allactiveOrders.length > 0
    ) {
      var filteredData = allactiveOrders.filter(
        (data) =>
          data.makerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.takerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.state.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setactiveOrders([]);
      } else {
        setTimeout(setactiveOrders.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(setactiveOrders.bind(this, allactiveOrders), 200);
    }
  };

  const filterTakerOrders = async (event) => {
    var value = event.target.value;
    if (value && value !== "" && alltakerOrders && alltakerOrders.length > 0) {
      var filteredData = alltakerOrders.filter(
        (data) =>
          data.makerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.takerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.state.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        settakerOrders([]);
      } else {
        setTimeout(settakerOrders.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(settakerOrders.bind(this, alltakerOrders), 200);
    }
  };

  const filterOrdersHistory = async (event) => {
    var value = event.target.value;
    if (
      value &&
      value !== "" &&
      allorderhistory &&
      allorderhistory.length > 0
    ) {
      var filteredData = allorderhistory.filter(
        (data) =>
          data.makerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.takerSymbol.toLowerCase().includes(value.toLowerCase()) ||
          data.state.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setorderhistory([]);
      } else {
        setTimeout(setorderhistory.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(setorderhistory.bind(this, allorderhistory), 200);
    }
  };

  async function setInfo(items) {
    var address = walletConnection.address;
    var isMaker = items.maker;
    if (address.toLowerCase() == isMaker.toLowerCase()) {
      isMaker = "You";
    }
    Object.assign(items, {
      isMaker: isMaker,
    });
    setorderInfo(items);
    setTimeout(function () {
      window.$("#order_detail_info").modal("show");
    }, 1000);
  }

  return (
    <div className="">
      <ul
        className="nav nav-pills nav_pills_primary"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="submit-activeorder-tab"
            data-bs-toggle="pill"
            data-bs-target="#submit-activeorder"
            type="button"
            role="tab"
            aria-controls="submit-activeorder"
            aria-selected="true"
          >
            {t("SUBMITTED_ORDERS")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="received-order-tab"
            data-bs-toggle="pill"
            data-bs-target="#received-order"
            type="button"
            role="tab"
            aria-controls="received-order"
            aria-selected="false"
          >
            {t("RECEIVED_ORDERS")}
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="order-history-tab"
            data-bs-toggle="pill"
            data-bs-target="#order-history"
            type="button"
            role="tab"
            aria-controls="order-history"
            aria-selected="false"
          >
            {t("ORDER_HISTORY")}
          </button>
        </li>
      </ul>
      <div className="tab-content classic_tab" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="submit-activeorder"
          role="tabpanel"
          aria-labelledby="submit-activeorder-tab"
          tabIndex="0"
        >
          <div className="active_order_filter">
            <div className="search_grp">
              <input
                className="form-control"
                placeholder={t("FIND_BY_TOKEN_OR_STATUS")}
                type="text"
                onChange={filterActiveOrders}
              />
              <img
                src={require("../../assets/images/search_white.png")}
                alt="Search"
              />
            </div>
            {isCancel ? (
              <button className="cancel_btn" onClick={() => cancelSelected()}>
                {t("CANCEL_SELECTED")}
              </button>
            ) : (
              <button className="cancel_btn" onClick={() => cancelAll()}>
                {t("CANCEL_ALL")}
              </button>
            )}
          </div>
          <div className="table-responsive">
            <table className="table primary_table order_table">
              {isloading && <i className="fas fa-spinner fa-spin"></i>}
              {isloaded && activeOrders && activeOrders.length > 0 && (
                <thead>
                  <tr>
                    <th>{t("YOU_PAY")}</th>
                    <th>{t("YOU_GET")}</th>
                    <th>{t("LIMIT_PRICE")}</th>
                    <th>{t("EXPIRY")}</th>
                    <th>{t("STATUS")}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {activeOrders.length == 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="noorder_div">
                        <img
                          src={require("../../assets/images/no_orders_icon.png")}
                          alt="Search"
                        />
                        <p>{t("NO_ORDERS_YET")}</p>
                      </div>
                    </td>
                  </tr>
                )}

                {isloaded &&
                  activeOrders &&
                  activeOrders.length > 0 &&
                  activeOrders.map((items, i) => {
                    var expiry = items.expiry * 1000;
                    expiry = new Date(expiry);

                    var makerAmount =
                      items.makerAmount / 10 ** parseFloat(items.makerDecimals);
                    var takerAmount =
                      items.takerAmount / 10 ** parseFloat(items.takerDecimals);

                    var limitPrice = makerAmount / takerAmount;

                    return (
                      <tr>
                        <td>
                          <div className="box">
                            <input
                              type="checkbox"
                              className="form-check-input me-3"
                              onClick={() => setcancelList(items.orderHash)}
                            />
                            <img
                              src={getTokenLogo(items.makerLogo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">{items.makerSymbol}</span>
                              <p>{formatNumber(makerAmount, 6)}</p>
                            </div>
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </td>

                        <td>
                          <div className="box">
                            <img
                              src={getTokenLogo(items.takerLogo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">
                                {formatNumber(takerAmount, 6)}
                              </span>
                              <p>{items.takerSymbol}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="grey">
                            {formatNumber(limitPrice, 6)} {t("PER")}{" "}
                            {items.takerSymbol}
                          </span>
                        </td>
                        <td>
                          {/* {date} <span className="grey">{time}</span>{" "} */}
                          {items.expiry == 0
                            ? t('NEVER')
                            : displayDays(items.expiry * 1000)}
                        </td>
                        <td>
                          {" "}
                          <span className="order_open">
                            {/* {getOrderStatus(items.state).status} */}
                            {t(getOrderStatus(items.state).status)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="received-order"
          role="tabpanel"
          aria-labelledby="received-order-tab"
          tabIndex="0"
        >
          <div className="active_order_filter">
            <div className="search_grp">
              <input
                className="form-control"
                placeholder={t("FIND_BY_TOKEN_OR_STATUS")}
                type="text"
                onChange={filterTakerOrders}
              />
              <img
                src={require("../../assets/images/search_white.png")}
                alt="Search"
              />
            </div>
          </div>

          <div className="table-responsive adjust_height">
            <table className="table primary_table order_table">
              {isloaded && takerOrders && takerOrders.length > 0 && (
                <thead>
                  <tr>
                    <th>{t("YOU_PAY")}</th>
                    <th>{t("YOU_GET")}</th>
                    <th>{t("LIMIT_PRICE")}</th>
                    <th>{t("MAKER")}</th>
                    <th>{t("STATUS")}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {takerOrders.length == 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="noorder_div">
                        <img
                          src={require("../../assets/images/no_orders_icon.png")}
                          alt="Search"
                        />
                        <p>{t("NO_ORDERS_YET")}</p>
                      </div>
                    </td>
                  </tr>
                )}
                {isloaded &&
                  takerOrders &&
                  takerOrders.length > 0 &&
                  takerOrders.map((items, i) => {
                    var expiry = items.expiry * 1000;
                    expiry = new Date(expiry);

                    var makerAmount =
                      items.makerAmount / 10 ** parseFloat(items.makerDecimals);
                    var takerAmount =
                      items.takerAmount / 10 ** parseFloat(items.takerDecimals);

                    var limitPrice = makerAmount / takerAmount;
                    return (
                      <tr>
                        <td>
                          <div className="box">
                            <img
                              src={getTokenLogo(items.takerLogo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">{items.takerSymbol}</span>
                              <p>{formatNumber(takerAmount, 6)}</p>
                            </div>
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </td>

                        <td>
                          <div className="box">
                            <img
                              src={getTokenLogo(items.makerLogo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">
                                {formatNumber(makerAmount, 6)}
                              </span>
                              <p>{items.makerSymbol}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="grey">
                            {formatNumber(limitPrice, 6)} per{" "}
                            {items.takerSymbol}
                          </span>
                        </td>
                        <td>
                          <span className="grey">{shortText(items.maker)}</span>
                        </td>
                        {/* <td>
                          {date} <span className="grey">{time}</span>{" "}
                          {moment(expiry).format('D hh:mm')}
                        </td> */}
                        <td>
                          {" "}
                          <span
                            className={getOrderStatus(items.state).className}
                          >
                            {/* {getOrderStatus(items.state).status} */}
                            {t(getOrderStatus(items.state).status)}
                          </span>
                        </td>
                        <td>
                          {" "}
                          <button
                            className="filed_order_btn"
                            onClick={() => openFillOrder(items)}
                          >
                            {t("FILL_ORDER")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="order-history"
          role="tabpanel"
          aria-labelledby="order-history-tab"
          tabIndex="0"
        >
          <div className="active_order_filter">
            <div className="search_grp">
              <input
                className="form-control"
                placeholder={t("FIND_BY_TOKEN_OR_STATUS")}
                type="text"
                onChange={filterOrdersHistory}
              />
              <img
                src={require("../../assets/images/search_white.png")}
                alt="Search"
              />
            </div>
          </div>

          <div className="table-responsive adjust_height">
            <table className="table primary_table order_table">
              {isloaded && orderhistory && orderhistory.length > 0 && (
                <thead>
                  <tr>
                    <th>{t("YOU_PAY")}</th>
                    <th>{t("YOU_GET")}</th>
                    <th>{t("LIMIT_PRICE")}</th>
                    <th>{t("MAKER")}</th>
                    <th>{t("STATUS")}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {orderhistory.length == 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="noorder_div">
                        <img
                          src={require("../../assets/images/no_orders_icon.png")}
                          alt="Search"
                        />
                        <p>{t("NO_ORDERS_YET")}</p>
                      </div>
                    </td>
                  </tr>
                )}
                {isloaded &&
                  orderhistory &&
                  orderhistory.length > 0 &&
                  orderhistory.map((items, i) => {
                    var expiry = items.expiry * 1000;
                    expiry = new Date(expiry);
                    var makerVal =
                      items.maker.toLowerCase() ==
                        walletConnection.address.toLowerCase()
                        ? t('YOU')
                        : shortText(items.maker);

                    var fromsymbol = items.makerSymbol;
                    var fromlogo = items.makerLogo;
                    var fromAmt =
                      items.makerAmount / 10 ** parseFloat(items.makerDecimals);
                    var tosymbol = items.takerSymbol;
                    var tologo = items.takerLogo;
                    var toAmt =
                      items.takerAmount / 10 ** parseFloat(items.takerDecimals);
                    if (
                      items.takerFromMeta.toLowerCase() ==
                      walletConnection.address.toLowerCase()
                    ) {
                      fromsymbol = items.takerSymbol;
                      fromlogo = items.takerLogo;
                      fromAmt =
                        items.takerAmount /
                        10 ** parseFloat(items.takerDecimals);
                      tosymbol = items.makerSymbol;
                      tologo = items.makerLogo;
                      toAmt =
                        items.makerAmount /
                        10 ** parseFloat(items.makerDecimals);
                    }

                    var limitPrice = fromAmt / toAmt;

                    return (
                      <tr>
                        <td>
                          <div className="box">
                            <img
                              src={getTokenLogo(fromlogo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">{fromsymbol}</span>
                              <p>{formatNumber(fromAmt, 6)}</p>
                            </div>
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </td>

                        <td>
                          <div className="box">
                            <img
                              src={getTokenLogo(tologo, items.chainId)}
                              alt="Exchange"
                            />
                            <div>
                              <span className="grey">
                                {formatNumber(toAmt, 6)}
                              </span>
                              <p>{tosymbol}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="grey">
                            {formatNumber(limitPrice, 6)} {t("PER")} {tosymbol}
                          </span>
                        </td>
                        <td> {makerVal}</td>
                        <td>
                          <span
                            className={getOrderStatus(items.state).className}
                          >
                            {t(getOrderStatus(items.state).status)}
                            {/* {getOrderStatus(items.state).status} */}
                          </span>
                        </td>
                        <td>
                          {" "}
                          <button
                            className="filed_order_btn"
                            onClick={() => setInfo(items)}
                          >
                            {t("DETAILS")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CancelLimitModal cancelHash={cancelHash} />
      <FillTradeModal
        fillOrderInfo={fillOrderInfo}
        walletConnection={walletConnection}
        tokenrate={tokenrate}
        showExpire={showExpire}
      />
      <LimitOrderDetails
        orderInfo={orderInfo}
        walletConnection={walletConnection}
      />
    </div>
  );
}
