var feeClaimer = {
    1: "0xeF13101C5bbD737cFb2bF00Bbd38c626AD6952F7",
    56: "0x2DF17455B96Dde3618FD6B1C3a9AA06D6aB89347",
    137: "0x8b5cF413214CA9348F047D1aF402Db1b4E96c06",
    43114: "0xbFcd68FD74B4B458961495F3392Bf96f46A29E67",
    250: "0x4F14fE8c86A00D6DFB9e91239738499Fc0F587De",
    42161: "0xA7465CCD97899edcf11C56D2d26B49125674e45F",
    10: " 0xA7465CCD97899edcf11C56D2d26B49125674e45F",
    128: "0x9aaB4B24541af30fD72784ED98D8756ac0eFb3C7",
    8453: "0x9aaB4B24541af30fD72784ED98D8756ac0eFb3C7"
}
export default feeClaimer;