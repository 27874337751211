import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../assets/images/confirm_loader.svg";
import { setBridgeSettings } from "../../reducers/Actions";
import { dropdownLogo, getCoinName } from "../../helper/networkdata";

import config from "../../config/config";
import { useTranslation } from "react-i18next";


export default function WalletConfirm(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const settings = useSelector((state) => state.bridgesettings);
    const bridgeToken = useSelector((state) => state.bridgeToken);
    const transfer = useSelector((state) => state.transfer);

    useEffect(() => {
    }, []);

    useEffect(() => {
    }, []);

    function setMode(type) {
        localStorage.setItem("bgasmode", type);

        let bvalue = (type == "None") ? 0 : 1;
        dispatch(setBridgeSettings({
            bslippage: settings.bslippage,
            bgasmode: type,
            bvalue: bvalue,
        }));
    }



    return (
        <div className="bridge_right">
            {/* Wallet Confirmation */}
            <div className="swap_box">
                <div className="heading_block">
                    <span>{t('BRIDGE')}</span>
                    <div>
                        <i>
                            <img
                                src={require("../../assets/images/close_icon.png")}
                                alt="Close"
                            />
                        </i>
                    </div>
                </div>
                <div className="bridge_wallet_confirm">
                    <div className="text-center">
                        <img src={Loader} alt="Loader" />
                        <p>{t('PENDING_CONFIRM')}</p>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex justify-content-between">
                            <label>{t('FROM')}</label>
                            <div className="confirm_wallet_info">
                                <div>
                                    <img
                                        src={dropdownLogo(bridgeToken.fromchainId)}
                                        alt="Close"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = config.defaultLogo
                                        }}
                                    />
                                    <span>{getCoinName(bridgeToken.fromchainId)}</span>
                                </div>
                                <label>{t('TO')}</label>
                                <div>
                                    <img
                                        src={dropdownLogo(bridgeToken.tochainId)}
                                        alt="Close"
                                    />
                                    <span>{getCoinName(bridgeToken.tochainId)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm_wallet_inp">
                            <label>{t('YOU_PAY')}</label>
                            <label>{transfer.amount}</label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex justify-content-between">
                            <label>{t('YOU_WILL_RECEIVE')}</label>
                            <div className="confirm_wallet_info">
                                <div>
                                    <img
                                        src={bridgeToken.tologo}
                                        alt="Close"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = config.defaultLogo
                                        }}
                                    />
                                    <span>{bridgeToken.tosymbol}</span>
                                </div>
                            </div>
                        </div>
                        <div className="confirm_wallet_inp confirm_wallet_inp_nobg">
                            <label>{t('RECEIVE')}</label>
                            <label className="d-flex align-items-center">
                                {transfer.receiveAmount}
                                <img src={require("../../assets/images/info_icon.png")} alt="Icon" className="img-fluid ms-2" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}