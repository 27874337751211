var chaincode = {
    1: "eth",
    10: "optimism",
    56: "bsc",
    128: "heco",
    137: "polygon",
    250: "fantom",
    8453: "base",
    42161: "arbitrum",
    43114: "avax"
};
export default chaincode;