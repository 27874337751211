import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { GetTokenList } from "../../Api/TokenActions";
import { getTokenLogo } from "../../helper/networkdata";
import config from "../../config/config";
import { formatNumber } from "../../helper/custommath";
import CancelLimitModal from "./cancelLimitModal";
import LimitOrderDetails from "./limitOrderDetails"

import { displayDays } from "../../helper/dateFormat";

import { GetUserLimitOrders } from "../../Api/LimitOrderActions";
import { useTranslation } from "react-i18next";

import { toastAlert } from "../../helper/toastAlert";

export default function LimitOrderList(props) {
  const { t } = useTranslation();
  var { orderList } = props;
  const walletConnection = useSelector((state) => state.walletConnection);
  const loadHistory = useSelector((state) => state.loadHistory);
  const [isLoad, setisLoad] = useState(false);
  const [tokenList, settokenList] = useState([]);
  const [loading, setloading] = useState(false);

  const [activeOrders, setactiveOrders] = useState([]);
  const [orderhistory, setorderhistory] = useState([]);

  const [allactiveOrders, setallactiveOrders] = useState([]);
  const [allorderhistory, setallorderhistory] = useState([]);

  const [cancelHash, setcancelHash] = useState([]);
  const [isCancel, setisCancel] = useState(false);
  const [orderInfo, setorderInfo] = useState({});

  useEffect(() => {
    getLimitOrdersList(orderList);
    //eslint-disable-next-line
  }, [orderList]);

  useEffect(() => {
    getLimitOrdersList();
  }, [walletConnection]);

  useEffect(() => {
    getLimitOrdersList();
    //eslint-disable-next-line
  }, [loadHistory.status]);

  useEffect(() => {

  }, [cancelHash]);

  async function getLimitOrdersList() {


    try {
      if (walletConnection.address && walletConnection.address !== "") {

        var userInfo = {
          address: walletConnection.address,
          chainId: walletConnection.network
        }
        var { orders } = await GetUserLimitOrders(userInfo);

        // var { orders } = await getLimitOrders(walletConnection.address, walletConnection.network);

        var orderList = orders;
        setisLoad(false);
        setloading(true);

        setcancelHash([]);
        setactiveOrders([]);
        setisCancel(false)
        setallactiveOrders([]);

        setorderhistory([]);
        setallorderhistory([]);

        var ntId = localStorage.getItem("network");
        ntId = (parseInt(ntId) > 0) ? parseInt(ntId) : 56;
        let chainId = (walletConnection && walletConnection.network) ? walletConnection.network : ntId;
        var data = {
          chainId: chainId,
          useraddress: walletConnection.address
        }

        var { result } = await GetTokenList(data);
        var list = JSON.parse(result);
        settokenList(list);
        if (orderList && orderList.length > 0) {

          var aList = [];
          var oList = [];

          for (var a = 0; a < orderList.length; a++) {

            var obj = orderList[a];

            var makerLogo = config.defaultLogo;
            var makerSymbol = "";
            var makerDecimals = 18;
            var makerAmount = 0;
            var mindex = list.findIndex(val => val.address.toLowerCase() == orderList[a].makerAsset.toLowerCase())

            if (mindex !== -1) {
              makerLogo = list[mindex].logoURI;
              makerSymbol = list[mindex].symbol;
              makerDecimals = list[mindex].decimals;
              makerAmount = parseFloat(orderList[a].makerAmount);
              //makerAmount = orderList[a].makerAmount / 10 ** makerDecimals;
              //makerAmount = formatNumber(makerAmount, 5);
            }

            var takerLogo = config.defaultLogo;
            var takerSymbol = "";
            var takerDecimals = 18;
            var takerAmount = 0;
            var tindex = list.findIndex(val => val.address.toLowerCase() == orderList[a].takerAsset.toLowerCase());

            if (tindex !== -1) {
              takerLogo = list[tindex].logoURI;
              takerSymbol = list[tindex].symbol;
              takerDecimals = list[tindex].decimals;
              takerAmount = parseFloat(orderList[a].takerAmount);
              //takerAmount = takerAmount / 10 ** takerDecimals;
              //takerAmount = formatNumber(takerAmount, 5);
            }

            Object.assign(obj, {
              makerLogo,
              makerSymbol,
              makerDecimals,
              makerAmount,
              takerLogo,
              takerSymbol,
              takerDecimals,
              takerAmount
            });

            var checkExpire = parseFloat(obj.expiry) * 1000;
            checkExpire = new Date(checkExpire);
            if (new Date() > checkExpire && obj.expiry !== 0) {
              obj.state = "EXPIRED";
            }
            if (obj.state == "PENDING") {
              aList.push(obj);
            }
            if (obj.state !== "PENDING") {
              oList.push(obj);
            }

          }

          setactiveOrders(aList);
          setallactiveOrders(aList);

          setorderhistory(oList);
          setallorderhistory(oList);
        }

        setisLoad(true);
        setloading(false);
      }
    } catch (err) {
      setloading(false);
    }
  }


  function setcancelList(hash) {
    var index = cancelHash.findIndex(val => val == hash);
    var NewList = cancelHash;
    if (index === -1) {
      var orderIndex = activeOrders.findIndex(val => val.orderHash == hash)

      var checkExpire = parseFloat(activeOrders[orderIndex].expiry) * 1000;
      checkExpire = new Date(checkExpire);
      if (checkExpire < new Date() && parseFloat(activeOrders[orderIndex].expiry) !== 0) {
        toastAlert("error", "Order is expired", "order");
        return;
      }

      cancelHash.push(hash)
      NewList = cancelHash;
    } else {
      cancelHash.splice(index, 1);
      NewList = cancelHash;

    }
    setcancelHash(NewList);
    setisCancel((NewList.length > 0) ? true : false);
  }


  async function cancelSelected() {
    setTimeout(function () {
      window.$("#cancel_limit_modal").modal("show");
    }, 500);
  }

  async function cancelAll() {
    const hashList = activeOrders.map(val => val.orderHash);

    for (var o = 0; o < activeOrders.length; o++) {
      var checkExpire = parseFloat(activeOrders[o].expiry) * 1000;
      checkExpire = new Date(checkExpire);
      if (checkExpire < new Date() && parseFloat(activeOrders[o].expiry) !== 0) {
        toastAlert("error", "Order is expired", "order");
        break;
      }
    }


    setcancelHash(hashList);
    setTimeout(function () {
      window.$("#cancel_limit_modal").modal("show");
    }, 500)
  }

  const filterActiveOrders = async (event) => {
    var value = event.target.value;
    if (value && value !== "" && allactiveOrders && allactiveOrders.length > 0) {
      var filteredData = allactiveOrders.filter(data =>
        data.makerSymbol.toLowerCase().includes(value.toLowerCase())
        || data.takerSymbol.toLowerCase().includes(value.toLowerCase())
        || data.state.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setactiveOrders([]);
      } else {
        setTimeout(setactiveOrders.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(setactiveOrders.bind(this, allactiveOrders), 200);
    }
  }

  const filterOrdersHistory = async (event) => {
    var value = event.target.value;
    if (value && value !== "" && allorderhistory && allorderhistory.length > 0) {
      var filteredData = allorderhistory.filter(data =>
        data.makerSymbol.toLowerCase().includes(value.toLowerCase())
        || data.takerSymbol.toLowerCase().includes(value.toLowerCase())
        || data.state.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setorderhistory([]);
      } else {
        setTimeout(setorderhistory.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(setorderhistory.bind(this, allorderhistory), 200);
    }
  }

  async function setInfo(items) {
    setorderInfo(items);
    setTimeout(function () {
      window.$("#order_detail_info").modal("show");
    }, 1000)
  }

  return (
    <div className="">
      <ul
        className="nav nav-pills nav_pills_primary"
        id="pills-tab"
        role="tablist"
      >
        <li
          className="nav-item"
          role="presentation"
        >
          <button
            className="nav-link active"
            id="pills-activeorder-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-activeorder"
            type="button"
            role="tab"
            aria-controls="pills-activeorder"
            aria-selected="true"
          >
            {t('ACTIVE_ORDERS')}{loading && (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </li>
        <li
          className="nav-item"
          role="presentation"
        >
          <button
            className="nav-link"
            id="pills-history-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-history"
            type="button"
            role="tab"
            aria-controls="pills-history"
            aria-selected="false"
          >
            {t('ORDER_HISTORY')}{loading && (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </li>
      </ul>
      <div className="tab-content classic_tab" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-activeorder"
          role="tabpanel"
          aria-labelledby="pills-activeorder-tab"
          tabIndex="0"
        >
          {/* <h2> Active orders</h2> */}
          <div className="active_order_filter">
            <div className="search_grp">
              <input
                className="form-control"
                placeholder={t("FIND_BY_TOKEN_OR_STATUS")}
                type="text"
                onChange={filterActiveOrders}
              />
              <img
                src={require("../../assets/images/search_white.png")}
                alt="Search"
              />
            </div>
            {isCancel ?
              <button className="cancel_btn" onClick={() => cancelSelected()}>{t('CANCEL_SELECTED')}</button>
              :
              (isLoad &&
                activeOrders &&
                activeOrders.length > 0) ? <button className="cancel_btn" onClick={() => cancelAll()}>{t('CANCEL_ALL')}</button>
                : ""
            }
          </div>
          <div className="table-responsive adjust_height">
            <table className="table primary_table order_table">
              {isLoad &&
                activeOrders &&
                activeOrders.length > 0 &&
                <thead>
                  <tr>
                    <th>{t('YOU_PAY')}</th>
                    <th>{t('YOU_GET')}</th>
                    <th>{t('LIMIT_PRICE')}</th>
                    <th>{t('EXPIRY')}</th>
                    <th>{t('STATUS')}</th>
                  </tr>
                </thead>
              }
              <tbody>
                {activeOrders.length == 0 &&
                  <tr>
                    <td colSpan={6}>
                      <div className="noorder_div">
                        <img
                          src={require("../../assets/images/no_orders_icon.png")}
                          alt="Search"
                        />
                        <p>{t('NO_ORDERS_YET')}</p>
                      </div>
                    </td>
                  </tr>
                }
                {isLoad &&
                  activeOrders &&
                  activeOrders.length > 0 &&
                  activeOrders.map((items, i) => {

                    var expiry = items.expiry * 1000;
                    expiry = new Date(expiry);

                    var cancelIds = localStorage.getItem("cancelIds");
                    var listIds = [];
                    if (cancelIds) {
                      listIds = cancelIds.split(",");
                    }

                    var index = listIds.findIndex(val => val == items.orderHash);

                    var makerDecimals = items.makerDecimals;
                    var makerAmount = items.makerAmount / 10 ** makerDecimals;

                    var takerDecimals = items.takerDecimals;
                    var takerAmount = items.takerAmount / 10 ** takerDecimals;

                    var limitPrice = makerAmount / takerAmount;

                    var checkExpire = parseFloat(items.expiry) * 1000;
                    checkExpire = new Date(checkExpire);


                    if (checkExpire >= new Date() || items.expiry == 0) {
                      return (
                        <tr>
                          <td>
                            <div className="box">
                              <input type="checkbox" className="form-check-input me-3" onClick={() => setcancelList(items.orderHash)} />
                              <img src={getTokenLogo(items.makerLogo, items.chainId)} alt="Exchange" />
                              <div>
                                <span className="grey">{items.makerSymbol}</span>
                                <p>{formatNumber(makerAmount, 6)}</p>
                              </div>
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </td>

                          <td>
                            <div className="box">
                              <img src={getTokenLogo(items.takerLogo, items.chainId)} alt="Exchange" />
                              <div>
                                <span className="grey">
                                  {formatNumber(takerAmount, 6)}
                                </span>
                                <p>{items.takerSymbol}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="grey">
                              {formatNumber(limitPrice, 6)} per {items.takerSymbol}
                            </span>
                          </td>
                          <td>
                            {/* {date} <span className="grey">{time}</span>{" "} */}
                            {(items.expiry == 0) ? "Never" : displayDays(items.expiry * 1000)}
                          </td>
                          <td> <span className="order_open">{t('OPEN')}</span></td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-history"
          role="tabpanel"
          aria-labelledby="pills-history-tab"
          tabIndex="0"
        >
          <div className="active_order_filter">
            <div className="search_grp">
              <input
                className="form-control"
                placeholder={t("FIND_BY_TOKEN_OR_STATUS")}
                type="text"
                onChange={filterOrdersHistory}
              />
              <img
                src={require("../../assets/images/search_white.png")}
                alt="Search"
              />
            </div>
          </div>
          {/* <h2>Order history</h2> */}
          <div className="table-responsive adjust_height">
            <table className="table primary_table order_table">

              {isLoad &&
                orderhistory &&
                orderhistory.length > 0 &&
                <thead>
                  <tr>
                    <th>{t('YOU_PAY')}</th>
                    <th>{t('YOU_GET')}</th>
                    <th>{t('LIMIT_PRICE')}</th>
                    <th>{t('STATUS')}</th>
                  </tr>
                </thead>
              }
              <tbody>
                {orderhistory.length == 0 &&
                  <tr>
                    <td colSpan={6}>
                      <div className="noorder_div">
                        <img
                          src={require("../../assets/images/no_orders_icon.png")}
                          alt="Search"
                        />
                        <p>{t('NO_ORDERS_YET')}</p>
                      </div>
                    </td>
                  </tr>
                }
                {isLoad &&
                  orderhistory &&
                  orderhistory.length > 0 &&
                  orderhistory.map((items, i) => {

                    var expiry = items.expiry * 1000;
                    expiry = new Date(expiry);

                    var status = (items.state == "CANCELLED") ? "CANCELED" : (items.state == "FULFILLED") ? "FILLED" : (items.state == "SUSPENDED") ? "SUSPENDED" : items.state;
                    var classN = (items.state == "CANCELLED") ? "order_cancel" : (items.state == "FULFILLED") ? "order_filled" : (items.state == "SUSPENDED") ? "order_cancel" : "order_open";

                    var makerDecimals = items.makerDecimals;
                    var makerAmount = items.makerAmount / 10 ** makerDecimals;

                    var takerDecimals = items.takerDecimals;
                    var takerAmount = items.takerAmount / 10 ** takerDecimals;

                    var limitPrice = makerAmount / takerAmount;

                    return (
                      <tr>
                        <td>
                          <div className="box">
                            <img src={getTokenLogo(items.makerLogo, items.chainId)} alt="Exchange" />
                            <div>
                              <span className="grey">{items.makerSymbol}</span>
                              <p>{formatNumber(makerAmount, 6)}</p>
                            </div>
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </td>

                        <td>
                          <div className="box">
                            <img src={getTokenLogo(items.takerLogo, items.chainId)} alt="Exchange" />
                            <div>
                              <span className="grey">
                                {formatNumber(takerAmount, 6)}
                              </span>
                              <p>{items.takerSymbol}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="grey">
                            {formatNumber(limitPrice, 6)} {t('PER')} {items.takerSymbol}
                          </span>
                        </td>
                        {/* <td>
                          {date} <span className="grey">{time}</span>{" "}
                          {moment(expiry).format('D hh:mm')}
                        </td> */}
                        <td><span className={classN}>{status}</span></td>
                        <td> <button className="filed_order_btn" onClick={() => setInfo(items)}>{t('DETAILS')}</button></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CancelLimitModal
        cancelHash={cancelHash}
        getLimitOrdersList1={getLimitOrdersList}
      />
      <LimitOrderDetails
        orderInfo={orderInfo}
        walletConnection={walletConnection}
      />

    </div>
  );
}
