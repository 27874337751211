// import constant
import {
    SWAPTOKEN
} from '../constant';

const initialState = {
    fromaddress: "",
    fromdecimal: 0,
    fromsymbol: "",
    fromlogo: "",
    fromname: "",
    fromchainId: 0,
    toaddress: "",
    todecimal: 0,
    tosymbol: "",
    tologo: "",
    toname: "",
    tochainId: 0,
};

const swapToken = (state = initialState, action) => {

    switch (action.type) {
        case SWAPTOKEN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default swapToken;