// import constant
import {
    SWAPDATA
} from '../constant';

const initialState = {
    fromaddress: "",
    fromdecimal: 0,
    fromsymbol: "",
    fromlogo: "",
    fromamount: "",
    fromchainId: 0,
    fromdisplay: "",
    toaddress: "",
    todecimal: 0,
    tosymbol: "",
    tologo: "",
    toamount: "",
    todisplay: "",
    tochainId: 0,
    minimumrecevied: 0,
    priceImpact: 0,
    srcUSD: "",
    destUSD: "",
    priceRoute: "",
    gasCostUSD: "",
    fromRate: "",
    toRate: "",
    gasCost: "",
};

const swapData = (state = initialState, action) => {

    switch (action.type) {
        case SWAPDATA:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default swapData;