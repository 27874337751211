export default {
  ABOUT_NEW_FEATURES: "حول الميزات الجديدة.",
  ACCEPT: "يقبل",
  ACTIVE_ORDERS: "أوامر نشطة",
  ADD_TOKEN: "أضف رمزًا",
  AGGRESSIVE: "عنيف",
  AND: "و",
  APPROVE: "يعتمد",
  ASSETS_ON_WAY: "أصولك في الطريق ويجب أن تصل",
  AUTO: "آلي",
  AVOID_TRANSFER:
    "يرجى تجنب الانتقال إلى عنوان تبادل مركزي (Binance ، Huobi ...). إنها ليست ميزة مدعومة",
  BALANCE: "توازن",
  BALANCE_ONE: "توازن:",
  BLOG: "مدونة",
  BRIDGE: "كوبري",
  CANCEL: "يلغي",
  CANCELLATION_TRANSACTION_FEE: "يتضمن إلغاء أمر الحد دفع رسوم المعاملة.",
  CANCEL_ALL: "ألغ الكل",
  CANCEL_LIMIT_NOT_FILLED: "يمكنك إلغاء أمر الحد طالما لم يتم ملء الطلب بعد.",
  CANCEL_LIMIT_ORDER: "إلغاء أمر الحد",
  CANCEL_ORDER: "الغاء الطلب",
  CANCEL_SELECTED: "إلغاء المحدد",
  CHAINS: "مدونة",
  CHOOSE_NETWORK: "اختر الشبكة",
  CLASSIC_MODE: "الوضع الكلاسيكي",
  CLEAR: "واضح",
  CONFIRM: "يتأكد",
  CONFIRMATION_REJECTED: "تأكيد رفض.!",
  CONFIRM_SWAP: "تأكيد المبادلة",
  CONNECT_WALLET: "ربط المحفظة",
  COPIED: "نسخ",
  CURRENT: "حاضِر",
  CUSTOM: "مخصص",
  DAYS: "أيام",
  DEFAULT: "تقصير",
  DETAILS: "تفاصيل",
  DISCLAIMER: "تنصل",
  DISCONNECT_WALLET: "افصل المحفظة",
  DISMISS: "رفض",
  DUPLICATE_ORDER_SUBMIT: "كرر الطلب. تم تقديم هذا بالفعل",
  ENTER_AMT: "أدخل المبلغ",
  EXCHANGE: "تبادل",
  EXPIRY: "انقضاء",
  EXPIRY_SM: "انقضاء",
  FAILED_LOAD_CHART: "فشل تحميل الرسم البياني",
  FIND_BY_TOKEN_OR_STATUS: "ابحث عن طريق الرمز أو الحالة",
  INSUFFICIENT_BALANCE: "توازن غير كاف",
  INSUFFICIENT: "غير كافٍ",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "التوازن لتغطية توازن الطلبات الحالية",
  RECEIVERS: "المتلقي",
  WALETADDRESS: "عنوان محفظة",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "يرجى قبول شروط الخدمة وسياسة الخصوصية",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "لا ينبغي أن يكون صانع Taker هو نفس عنوان Taker",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "يجب أن يكون التسامح الانزلاق بين 0.1 ٪ و 15 ٪",
  SEARCH_A_TOKEN: "ابحث في رمز الرمز المميز",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "ستعود معاملتك إذا تغير السعر بشكل غير موات أكثر من ذلك أن هذه النسبة المئوية",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "يعدل سعر الغاز لمعاملتك.أعلى GWEI = سرعة أعلى.",
  REVIEW_OTC_ORDER: "مراجعة ترتيب OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "فترة انتهاء الصلاحية التي يحددها صانع الطلبات.بعد هذه الفترة ، سوف ينتهي الأمر ويتم أرشفته.",
  SEARCH_A_LIQUIDITY_SOURCES: "ابحث في مصادر السيولة",

  FAST: "سريع",
  FAST_AND_SECURE:
    "الطريقة الآمنة والسريعة والأكثر أمانًا لجلب الأصول عبر السلسلة إلى",
  FEATURE_TO_TRANSFER:
    "يرجى عدم استخدام هذه الميزة لنقل الرموز الخاصة بك إلى عنوان تبادل مركزي.",
  FEES: "مصاريف",
  FILL_ORDER: "عبئ الطلب",
  FILL_OTC_ORDER: "املأ ترتيب OTC",
  FREE: "حر",
  FROM: "من",
  GAS_COST: "تكلفة الغاز",
  GAS_DESTINATION: "الغاز على سلسلة الوجهة",
  GAS_ON_DESTINATION: "الغاز على الوجهة",
  GAS_PRICE: "سعر الغاز",
  GOT_IT: "فهمتها",
  GOT_PROBLEM_JUST: "لدي مشكلة؟ فقط تواصل.",
  HIGH: "عالي",
  HOURS: "ساعات",
  IMPORT: "يستورد",
  IMPOSSIBLE_TO_RETRIEVE:
    "إنها ليست ميزة مدعومة. أيضًا ، يرجى التحقق من العنوان الذي تملأه ، سيكون من المستحيل استرداد أي الرموز المميزة المرسلة إلى العنوان الخطأ.",
  INSTANT: "فوري",
  INSUFFICIENT_APPROVED_AMT: "المبلغ المعتمد غير الكافي",
  INVALID_TOKEN_ADDRESS: "عنوان رمز غير صالح",
  KEEP_TRACK_NOTIFY:
    "يمكنك تتبع ذلك في إخطار اللوحة الخاص بك. سوف نعلمك بمجرد الانتهاء.",
  KEEP_YOU_POSTED: ". سنبقيك على اطلاع.",
  LEARN_MORE: "يتعلم أكثر",
  LEGACY: "إرث",
  LIMIT: "حد",
  LIMIT_ORDER: "طلب محدود",
  LIMIT_PRICE: "الحد من السعر",
  LIMIT_PRICE_SM: "الحد من السعر",
  LIQUIDITY_SOURCES: "مصادر السيولة",
  LOW: "قليل",
  MAKER: "صانع",
  MARKET: "سوق",
  MAX: "الأعلى",
  MAX_BASE_FEE: "رسوم قاعدة الحد الأقصى",
  MAX_S: "الأعلى",
  MAX_TO_PAY: "ماكس للدفع",
  MINIMUM_RECEIVED: "الحد الأدنى المستلم",
  MINUTES: "دقائق",
  MONTHS: "شهور",
  NEED_APPROVAL_MOVE: "نحن بحاجة إلى موافقتك على التحرك",
  NEED_TO_WRAP: "تحتاج إلى التفاف",
  NETWORK_FEES: "رسوم الشبكة",
  NETWORK_SM: "شبكة.",
  NEVER: "أبداً",
  NONE: "لا أحد",
  NON_CUSTODIAL_LIMIT: "في ترتيب الحد غير الوصي. إنه شرط فني على",
  NORMAL: "طبيعي",
  NO_ORDERS_YET: "ليس لديك أوامر حتى الآن",
  NO_RECORDS: "لا تسجيلات",
  ON_UR_BEHALF: "نيابة عنك.",
  OOPS_WRONG: "تبا شيء ما حدث بشكل خاطئ.!",
  OPEN: "يفتح",
  ORDER_DETAILS: "تفاصيل الطلب",
  ORDER_HISTORY: "تاريخ الطلب",
  ORDER_PRICE: "سعر الطلب",
  ORDER_ROUTING: "توجيه الطلب",
  OTC: "OTC",
  PASTE: "معجون",
  PAY: "يدفع",
  PENDING_CONFIRM: "تأكيد معلق على المحفظة.",
  PER: "لكل",
  PLEASE_CHOOSE_NETWORK: "الرجاء اختيار الشبكة",
  PLEASE_LOGIN: "الرجاء تسجيل الدخول إلى",
  PLEASE_TRY_LATER: "الرجاء معاودة المحاولة في وقت لاحق",
  POPULAR_TOKENS: "الرموز الشعبية",
  PRICE: "سعر",
  PRICE_IMPACT: "تأثير السعر",
  PRIVACY_POLICY: "سياسة الخصوصية",
  RATE: "معدل",
  READ_ACCEPT: "قرأت وأقبل",
  RECEIVE: "يستلم",
  RECEIVED_ORDERS: "تلقى أوامر",
  REFUND: "استرداد",
  REMOVE: "يزيل",
  REVERSE_RATE: "معدل عكسي",
  REVIEW_TRADE: "مراجعة التجارة",
  SELECTED: "المحدد",
  SELECT_ALL: "اختر الكل",
  SELECT_FROM_INFO: "الرجاء الاختيار من المعلومات",
  SELECT_NETWORK: "حدد شبكة",
  SELECT_TOKEN: "حدد رمز الرمز",
  SELECT_TOKENN: "حدد الرمز المميز",
  SELECT_TO_INFO: "الرجاء تحديد معلومات",
  SETTINGS: "إعدادات",
  SIMPLE_MODE: "الوضع البسيط",
  SLIPPAGE: "انزلاق",
  SLIPPAGE_TOLERANCE: "تسامح الانزلاق",
  SRY_INSUFFICIENT: "آسف ، غير كافية",
  STATUS: "حالة",
  STATUS_SM: "حالة",
  STEP: "خطوة",
  SUBMIT: "يُقدِّم",
  SUBMITTED_ORDERS: "أوامر مقدم",
  SUBMIT_DATE: "تاريخ النشر",
  SUCCESS_FILLED: "طلب ناجح",
  SUCCESS_RECEIVED: "تلقى بنجاح",
  SUCCESS_SUBMITTED_ORDER: "قدمت طلبك بنجاح",
  SWAP: "تبديل",
  SWAP_TRANSFER: "مبادلة ونقل",
  SWITCH_NETWORK: "التبديل الشبكة",
  TAKER: "Taker",
  TAKER_ADDRESS: "عنوان Taker",
  TERMS_OF_SERVICE: "شروط الخدمة",
  TO: "ل",
  TOKEN_NOT_FOUND: "رمز لم يتم العثور عليه",
  TOKEN_RECEIVE_SENT: "سيتم إرسال الرمز المميز لاستلامه إلى",
  TRADE: "تجارة",
  TRANSACTION_REJECTED_LATER: "تم رفض المعاملة ، يرجى المحاولة مرة أخرى لاحقًا",
  TRANSFER: "تحويل",
  TRANSFER_ALERT: "تنبيه نقل",
  TRANSFER_TO: "حول إلى",
  TX_COST: "تكلفة تكساس",
  UNLOCK: "الغاء القفل",
  USER_GUIDE: "دليل المستخدم",
  USE_ITAS: "واستخدامه كما",
  VERIFY: "يؤكد",
  VIEW_EXPLORER: "عرض المعاملة في المستكشف",
  VIEW_ON: "نظرة على",
  VIEW_TRANSACTION: "عرض المعاملة",
  WRAP: "طَوّق",
  YOUR_ADDRESS: "عنوانك",
  YOUR_ORDER_CANCEL: "تم إلغاء طلبك",
  YOUR_WALLET: "محفظتك",
  YOU_GET: "لقد حصلت",
  YOU_PAY: "انت تدفع",
  YOU_RECEIVE: "انت تستقبل",
  YOU_WILL_RECEIVE: "ستستلم",
  ANYONE_CAN_CREATE:
    "يمكن لأي شخص إنشاء أي رمز ، بما في ذلك الإصدارات المزيفة من الرموز الموجودة. اعتني بالاهتمام. قد تكون بعض الرموز والمعلمات التقنية غير متوافقة مع خدماتنا. من خلال استيراد هذا الرمز المميز ، فإنك تقر بالمخاطر وقبولها.",
  BY_SIGNING_MESSAGE:
    "من خلال التوقيع على هذه الرسالة ، فإنك تؤكد أنك لا تصل إلى هذا التطبيق من هذا التطبيق ، أو أنك مقيم في الولايات المتحدة الأمريكية أو أي بلد آخر مقيد. لمزيد من المعلومات ، يرجى قراءة لدينا",
  CONSTITUTION: "دستوردو",
  CURRENTLY_CONNECTED: "أنت متصل حاليًا بـ",
  IMPORT_TOKEN: "استيراد رمز الرمز",
  IN_YOUR_WALLET: "في محفظتك",
  MAKE_SURE_CHECKED: "تأكد من قيامك بفحص عنوان الرمز المميز وهو صحيح",
  ON: "على",
  PEOPLE: "الناس",
  PLEASE_SWITCH: "الرجاء التبديل إلى",
  SELECTED_NOT_SUPPORTED:
    "يرجى تغيير الشبكة في محفظتك ، لا يتم دعم الشبكة المحددة",
  SIGN_FOR_ACCESS: "علامة للوصول",
  SIGN_MESSAGE: "رسالة التوقيع",
  LAUNCH_APP: "إطلاق التطبيق",
  BLOG_HEADING:
    "إزالة الغموض عن Defi - كل ما تحتاج إلى معرفته في مكان واحد. للمبتدئين للمستخدمين المتقدمين ، والجميع بينهما.",
  GETTING_STARTED: "البدء مع HODL: البرامج التعليمية والمفحسين للمنتجات",
  DEEP_DIVE: "عميق في HODL: الاستراتيجيات وميزات المنتج",
  BUILD_HODL: "بناء على HODL: الوثائق الفنية",
  BLOG_PLACEHOLDER: "ابحث عن المنتج/الموضوع الذي تريد التعرف عليه",
  CHOOSE_TOPIC: "اختر الشبكة",
  TRY: "يحاول",
  SEARCH_RESULTS: "نتائج البحث",
  SEARCH_TOPIC: "موضوع البحث",
  LOAD_MORE: "تحميل المزيد",
  SOCIAL_MEDIA: "وسائل التواصل الاجتماعي",
  NEW: "أحدث أولا",
  OLD: "الأقدم أولا",
  Z_A: " فرز العنوان Z-A",
  A_Z: " فرز العنوان A-Z",
  RESULTS: "نتائج",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "يتم حساب رسوم الغاز الأساسية تلقائيًا بواسطة الشبكة وتتراوح من كتلة إلى أخرى. قم بتعيين أقصى مبلغ من الرسوم الأساسية التي ترغب في دفعها. سيتم استرداد الفرق بين رسوم القاعدة الأقصى التي تحددها وسيتم استرداد الرسوم الأساسية الفعلية بمجرد استخراج الكتلة.",
  CANCELLED_TRANSACTION: "ألغت معاملتك",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "يعد إنشاء أمر الحد عبارة عن عملية مجانية لصانع الطلبات ما لم يتم إلغاؤها. سيُطلب من Taker Order دفع رسوم المعاملة أثناء ملء الطلب.",
  Cancelled: "ألغيت",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "الفرق في القيمة بين أفضل مسار و",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "قم بتمكين هذا الخيار إذا كنت تواجه مشكلات في تبديل رمز مميز يحتوي على رسوم مدمجة للنقل.",
  Expired: "منتهي الصلاحية",
  Filled: "مملوء",
  INSUFFICIENT_FOR_GASFEE: "آسف ، أموال غير كافية مقابل رسوم الغاز",
  Open: "يفتح",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "يتم تطبيق التعبئة الجزئية فقط إذا كان مسار المبادلة يتكون من أجزاء مسار رئيسية متعددة. يسمح بالتنفيذ الجزئي ، ومنع المعاملة من عكسها إذا كان سعر السوق لا يفضل.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "السعر الذي يحدده صانع الطلبات لتنفيذ طلبهم (الرمز المميز لتلقي = x الرمز المميز للدفع)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "السعر المحدد من قبل صانع الطلبات لتنفيذ طلبهم (الرمز المميز للدفع = y الرمز المميز لتلقيه",
  REVIEW_LIMIT_ORDER: "مراجعة أمر الحد",
  SEARCH_BY_TOKEN: "ابحث عن طريق الرمز أو العنوان",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "يمكن أن يساعد تحديد التسامح في الانزلاق العالي على النجاح ، ولكن قد لا تحصل على مثل هذا السعر الجيد. استخدم بحذر.",
  STATUS_OF_THE_LIMIT_ORDER: "حالة ترتيب الحد",
  Suspended: "معلق",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "يتم دفع هذه الرسوم إلى عمال مناجم الشبكة. HODL لا تتحكم في الرسوم.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE: "الفرق بين سعر السوق وسعر الإست",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "الحد الأدنى للمبلغ المستلم ، وإلا سيتم إعادة المعاملة",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "تنتقل هذه الرسوم مباشرة إلى عمال المناجم كحافز لتحديد أولويات معاملتك.",
  TRANSACTION_REJECTED_TRY: "رفضت المعاملة ، يرجى المحاولة مرة أخرى",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "النقل للرمز المميز المحدد غير متوفر على هذه الشبكة",
  YOU: "أنت",
};
