// import constant
import {
    LIMITORDERTOKEN
} from '../constant';

const initialState = {
    fromaddress: "",
    fromdecimal: 0,
    fromsymbol: "",
    fromlogo: "",
    fromname: "",
    toaddress: "",
    todecimal: 0,
    tosymbol: "",
    tologo: "",
    toname: "",
};

const limitOrderToken = (state = initialState, action) => {

    switch (action.type) {
        case LIMITORDERTOKEN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default limitOrderToken;