import Web3 from 'web3';
import { bignumber, multiply, divide, add, subtract } from 'mathjs'

import config from '../config/config';
import { convert } from './convert';
import { readWeb3 } from '../helper/connection';

export async function percentage(amount, percentage, type) {

    var result = 0;
    var initialamt = 0;
    if (type === "minus") {
        initialamt = parseFloat(amount) * parseFloat(percentage) / 100;
        let amt = parseFloat(amount) - parseFloat(initialamt);
        let amt1 = await convert(amt);
        result = amt1;
    } else if (type === "add") {
        initialamt = parseFloat(amount) * parseFloat(percentage) / 100;
        let amt = parseFloat(amount) + parseFloat(initialamt);
        let amt1 = await convert(amt);
        result = amt1;
    } else if (type === "percentage") {
        initialamt = parseFloat(amount) * parseFloat(percentage) / 100;
        let amt1 = await convert(initialamt);
        result = amt1;
    }

    result = await isNumberCheck(result);
    return result;
}

export async function convertToWei(amount, decimals) {

    try {
        var result = "";
        var web3 = new Web3(window.ethereum);
        if (decimals === 18) {
            result = web3.utils.toWei(amount.toString());
        } else {
            result = parseFloat(amount) * parseFloat(10 ** decimals);
        }
        return result.toString();
    } catch (err) {
        return "0";
    }


}

export async function getdeadline(minutes) {

    if (minutes === "" || minutes <= 0) {
        minutes = 2;
    }
    var deadline = parseInt(Date.now() / 1000) + minutes * 60;
    return deadline;
}

export async function toFixedFormat(amount) {

    var result = 0;

    try {
        var number = amount.toString();
        var number1 = number.split(".");

        if (number1[1] && number1[1].length && number1[1].length > 0) {
            var length = number1[1].length;
            if (length > 5) {
                length = config.toFixed;
            }
            result = amount.toFixed(length);
        } else {
            result = amount;
        }

        result = await isNumberCheck(result);

        return result;

    } catch (err) {
        return result;
    }

}

export async function divideDecimal(amount, decimals) {
    var amt = amount / 10 ** decimals;
    var convertamt = await convert(amt);
    var cAmt = await toFixedFormat(convertamt);
    cAmt = await isNumberCheck(cAmt);
    return cAmt;
}

export async function numberFloatOnly(value) {
    //eslint-disable-next-line
    const regxFormat = /^[]?\d*(?:[.]\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}

export function numberOnly(value) {
    const regxFormat = /^[0-9-+()]*$/;
    var result = regxFormat.test(value)
    return result;
}

export function formatAddress(address) {
    try {
        var addr = address.substring(0, 16);
        var addr1 = address.substring(30, 42);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}

export function shortText(address) {
    try {
        var addr = address.substring(0, 4);
        var addr1 = address.substring(36, 42);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}

export async function division(amountA, amountB) {
    var amt = amountA / amountB;
    var convertamt = await convert(amt);
    convertamt = await isNumberCheck(convertamt);
    return convertamt;
}

export async function multiply1(amountA, amountB) {
    var amt = amountA * amountB;
    var convertamt = await convert(amt);
    convertamt = await isNumberCheck(convertamt);
    return convertamt;
}


export function toFixedWithoutRound(number, decimalPlaces = 2) {
    try {
        let numberString = number.toString();
        let decimalIndex = numberString.indexOf('.');
        if (decimalIndex === -1) {
            return number;
        }
        numberString = numberString.slice(0, decimalIndex + decimalPlaces + 1);
        let truncatedNumber = parseFloat(numberString);
        return truncatedNumber;
    } catch (err) {
        return 0;
    }

}

export function isNumberCheck(amount) {
    var numberVal = amount;
    var convertamt = (isFinite(numberVal) && numberVal !== "Infinity") ? numberVal : 0;
    return convertamt;
}

export async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
        decimalsLength = decimals;
    }
    try {
        var number = amount.toString();
        var number1 = number.split(".");

        if (number1[1] && number1[1].length && number1[1].length > 0) {
            var length = number1[1].length;
            if (length > decimalsLength) {
                length = decimals;
            }
            result = amount.toFixed(length);
        } else {
            result = amount;
        }

        result = await isNumberCheck(result);

        return result;

    } catch (err) {
        return result;
    }

}

export function formatData(data) {
    try {
        var field = data.field;
        var fstart = parseInt(data.fstart);
        var fend = parseInt(data.fend);
        var lstart = parseInt(data.lstart);
        var lend = parseInt(data.lend);
        var addr = field.substring(fstart, fend);
        var addr1 = field.substring(lstart, lend);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}

export async function toFixedGreater(amount) {

    var deciChk = amount.toString();
    deciChk = deciChk.split('.');

    if (deciChk && deciChk.length === 2) {

        const getNumber = deciChk[1].split("");
        for (let n = 0; n < getNumber.length; n++) {

            var num = parseInt(getNumber);
            if (num > 0) {

            }

        }


    }

}

export function getNetworkName(id) {
    id = parseInt(id);
    try {
        var text = (id === 56) ? "BNB Chain" : (id === 1) ? "Ethereum" : (id === 137) ? "Polygon" : "BNB Chain";
        return text;
    } catch (err) {
        return "";
    }
}

export function formatNumber(num, defaultFixed) {

    try {
        if (defaultFixed && parseInt(defaultFixed) > 0) {
            defaultFixed = parseInt(defaultFixed);
        } else {
            defaultFixed = 5;
        }
        var numval = num.toString();
        numval = convert(numval);

        var chkDeci = numval.split(".");
        var returnNum = num;
        if (chkDeci.length == 2) {
            if (defaultFixed < chkDeci[1].length) {
                returnNum = toFixedWithoutRound(numval, defaultFixed);
            } else {
                var fix = chkDeci[1].length;
                returnNum = toFixedWithoutRound(numval, fix);
            }
        }

        return (!isNaN(returnNum)) ? returnNum : 0;
    } catch (err) {
        console.log(err, 'errerrerrerr')
        return 0;
    }

}

export function CheckDecimal(amount) {

    var amt = amount.toString();
    amt = convert(amt)
    amt = amt.split('.');
    if (amt.length == 2) {
        amount = amt[0];
    }
    return amount;

}

export function getParameterByName(name, url = window.location.href) {
    /* eslint-disable-next-line */
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&;]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export function checkIsAddress(id, address) {
    var web3 = readWeb3(id);
    var status = web3.utils.isAddress(address);
    return status;
}

export function removePointValue(amount) {

    amount = amount.toString();
    amount = convert(amount);
    var chkDeci = amount.split(".");
    if (chkDeci && chkDeci.length == 2) {
        amount = chkDeci[0];
    }

    return amount;

}

export function divideValue(amount1, amount2) {
    amount1 = bignumber(amount1);
    amount2 = bignumber(amount2);
    var calVal = divide(amount1, amount2);
    return calVal.toString();
}

export function multiplyValue(amount1, amount2) {
    try {
        amount1 = bignumber(amount1)
        amount2 = bignumber(amount2)
        var calVal = multiply(amount1, amount2);
        return calVal.toString();
    } catch (err) {
        return "0";
    }

}

export function addValue(amount1, amount2) {
    try {
        amount1 = bignumber(amount1)
        amount2 = bignumber(amount2)
        var calVal = add(amount1, amount2);
        return calVal.toString();
    } catch (err) {
        return "0";
    }
}

export function subtractValue(amount1, amount2) {
    try {
        amount1 = bignumber(amount1)
        amount2 = bignumber(amount2)
        var calVal = subtract(amount1, amount2);
        return calVal.toString();
    } catch (err) {
        return "0";
    }
}

export function percentageValue(amount1, amount2) {
    try {

    } catch (err) {
        return "0";
    }

}

export function showMenu() {
    try {
        var pathname = location.pathname;
        var pos = pathname.search("blog");
        var path = "inner";
        if (pathname == "/" || pos >= 0) {
            path = "home";
        }
        return path;
    } catch (err) {
        return "inner";
    }

}