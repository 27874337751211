import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { getHomepageText, getCms } from "../Api/HomeActions.js";
import { motion } from "framer-motion";
import ReactLoading from "react-loading";
import config from "../config/config";
import { useTranslation } from "react-i18next";
import bgImage01 from "./../assets/images/rotate_icon_04.png";
import Countdown, { zeroPad } from "react-countdown";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const homeText = {
  Banner_text_one: "",
  Banner_text_two: [],
  Banner_description: "",
  sectiononeText: "",
  sectiononedesciption: "",
  sectiontwoTitle: "",
  sectiontwodesciption: "",
  sectiontwobutton: "",
  sectionthreeTitle: "",
  sectionthreedesciption: "",
  sectionthreebutton: "",
  sectionfourHeading: "",
  sectionfourHighlightText: "",
  sectionfourTitle: "",
  sectionfourdecription: "",
  sectionfourbutton: "",
  futureheading: "",
  futureheadingHighlightText: "",
  cardoneheading: "",
  cardonedescription: "",
  cardonebutton: "",
  cardonelink: "",
  cardtwoheading: "",
  cardtwodescription: "",
  cardtwobutton: "",
  cardtwolink: "",
  helpsectionheading: "",
  helpsectiondescription: "",
  supportheading: "",
  supportdescription: "",
  supporttextone: "",
  supportlinkone: "",
  supporttexttwo: "",
  supportlinktwo: "",
  supporttextthree: "",
  supportlinkthree: "",
  supporttextfour: "",
  supportlinkfour: "",
  sidebartext: "",
  sidebardescription: "",
  sidebarbutton: "",
  sidebarlink: "",
  discordURI: "",
  twitterURI: "",
  githubURI: "",
  youtubeURI: "",
  Block_chanins: "",
  Total_trades: "",
  Total_active_users: "",
  Aggregated_sources: "",
  copyrighttext: "",
  homecontentURI: "",
  token_name: "",
  token_text: "",
  listing_countdown: "",
  startTimeStamp: ""
};
var animates = [];

export default function Home(props) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const [isAnimate, setisAnimate] = useState(false);
  const [cms, setCms] = useState([]);
  const [isLoad, setisLoad] = useState(false);

  const walletConnection = useSelector((state) => state.walletConnection);
  var code =
    localStorage && localStorage.getItem("code")
      ? localStorage.getItem("code")
      : "en";
  const langcode = useSelector((state) => state.lang);
  console.log(langcode, "langcode");
  var code =
    localStorage && localStorage.getItem("code")
      ? localStorage.getItem("code")
      : "en";
  console.log(code, "codecode");
  useEffect(() => {
    AOS.init();

    const gettingValues = async () => {
      setisLoad(false)
      var data = {
        lancode: code,
      };
      const result = await getHomepageText(data);
      setTextData(result.result);
      setisAnimate(true);
      if (result && result.result && result.result.Banner_text_two) {
        var resarr = [];
        var arr = result.result.Banner_text_two.flat();

        for (var i in arr) {
          var obj = {};
          obj["id"] = i;
          obj["content"] = arr[i];
          resarr.push(obj);
        }

        //setanimates(resarr);
        animates = resarr;
      }
      setisLoad(true)
      setisAnimate(false);
    };
    gettingValues();
  }, [code]);


  const [isActive, setActive] = useState(false);
  const [textData, setTextData] = useState(homeText);
  //const [animates, setanimates] = useState(items);
  if (textData && textData.sectionfourHeading) {
    const sectionfourHeadingTextVal = textData.sectionfourHeading.split(" ");
    const sectionfourHighlightTextVal = textData.sectionfourHighlightText;
    const mappedArray = sectionfourHeadingTextVal.map((val) =>
      sectionfourHighlightTextVal.includes(val) ? val : "no"
    );
  }

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= animates.length - 1) return 0;
        return state + 1;
      });
    }, 2300);
    return () => clearInterval(id);
  }, [isAnimate]);

  const toggleClass = () => {
    setActive(!isActive);
  };
  function selectToken(val) {
    window.location.href = `/swap-simple?address=${val.address}`;
  }
  async function handleClick(subcategory) {
    console.log(cms, 'cmsdata', subcategory)
    const datas = cms && cms.length > 0 && cms.find((item) => item.identifier == subcategory)
    console.log(datas, 'datas')
    if (datas && datas.linkstatus == true) {
      window.location.href = datas.link
    }
    if (datas && datas.linkstatus == false) {

      window.location.href = datas.identifier;
    }
  }
  async function getcms() {

    var { result } = await getCms();
    setCms(result)


  }
  useEffect(() => {

    getcms()

  }, [])


  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">D</span>
        </span>
        <span className="timer_dots px-2"></span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">H</span>
        </span>
        <span className="timer_dots px-2"></span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">M</span>
        </span>
        <span className="timer_dots px-2"></span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label sec">S</span>
        </span>
      </div>
    );
  };
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />

      <div className="page_header" name="home">
        <div className="container">
          <div className="row">
            {isLoad ? <div className="col-lg-6">
              <h1>{textData && textData.Banner_text_one}</h1>
              <h1 className="txt_yellow">
                {animates && animates[index] && animates[index].id && (
                  <motion.div
                    key={animates[index].id}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0 }}
                    transition={{ ease: "easeInOut" }}
                  >
                    {animates[index].content}
                  </motion.div>
                )}
              </h1>
              <div className="banbox">
                <p> <span className="txt_yellow"> {textData && textData.token_name}</span>  {textData && textData.listing_countdown}</p>
                {textData.startTimeStamp && <Countdown date={new Date(textData.startTimeStamp)} renderer={renderer} />}
              </div>
              <h5>
                {textData && textData.token_text}
              </h5>
            </div>
              :
              <div className="col-lg-6">
                <div className="text-center">
                  <ReactLoading
                    type={"bars"}
                    color="#fbd534"
                    className="loading"
                  />
                </div>
              </div>
            }
            <div
              className="col-lg-6"
              data-aos-duration="3000"
              data-aos-delay="500"
            >
              <div className="home_banner">
                <img
                  src={require("../assets/images/hodlcard_img.png")}
                  alt="Banner"
                  className="img-fluid banner_cardimg"
                />
                <img
                  src={require("../assets/images/ban2.png")}
                  alt="Banner"
                  className="img-fluid ban_img"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="banner_token_info">
                <div>
                  <h2>
                    {textData && textData.Block_chanins_count}<span>+</span>
                  </h2>
                  <p>{textData && textData.Block_chanins}</p>
                </div>
                <div>
                  <h2>
                    {textData && textData.Total_trades_count}<span>+</span>
                  </h2>
                  <p>{textData && textData.Total_trades}</p>
                </div>
                <div>
                  <h2>
                    {textData && textData.Total_active_users_count}<span>+</span>
                  </h2>
                  <p>{textData && textData.Total_active_users}</p>
                </div>
                <div>
                  <h2>
                    {textData && textData.Aggregated_sources_count} <span>+</span>
                  </h2>
                  <p>{textData && textData.Aggregated_sources}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="home_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-8 m-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >

                <h2 className="main_title text-center">
                  {textData && textData.sectiononeText}
                </h2>

                <p className="inner_para text-center">
                  {textData && textData.sectiononedesciption}.{" "}
                </p>
              </div>
            </div>
            <div className="row landing_networks_div">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_01.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Ethereum</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_02.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Binance Smart Chain</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_03.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Polygon</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_04.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Avalanche</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_08.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Fantom</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_05.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Optimism</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_06.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Base</p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <img
                  src={require("../assets/images/network_07.png")}
                  alt="Network"
                  className="img-fluid"
                />
                <p>Arbitrum</p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="rotating_circle_keys home_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="rotating_circle d_m_none">
                  <div className="center_img" style={{ backgroundImage: `url(${textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''})` }}></div>
                 
                  <div class="middle dots">
                    <div class="dot icon-buch"></div>
                    <div class="dot icon-buch arbitrum" style={{ top: "21px" }}></div>
                    <div class="dot icon-buch optimism" style={{ top: "-12px", left: "0" }}></div>
                    <div class="dot icon-buch eth" style={{ top: "237px", left: "72px" }}></div>
                  </div>
                  <div className="outer dots">
                    <div className="dot icon-kompass"></div>
                    <div className="dot icon-kompass avalanche" style={{ left: "148px", top: "-40px" }}></div>
                    <div className="dot icon-screen fantom" style={{ left: "0", top: "70%" }}></div>
                    <div className="dot icon-screen base" style={{ left: "80%", top: "65%" }}></div>
                  </div>
                </div>
                <div className="d_d_none mobilelogo_center">
                  <img
                    src={require("../assets/images/mobile_img_011.png")}
                    alt="Image"
                    className="img-fluid"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                  <img
                    src={textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''}
                    className="img-fluid own_crypto_img"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
               
                <h2 className="sub_title">
                  {textData && textData.sectiontwoTitle}
                </h2>
                
                <p className="inner_para">
                  {textData && textData.sectiontwodesciption}
                </p>
                
                <a
                  href="/your-keys"
                  className="primary_btn mt-3"
                  style={{ textDecoration: "none" }}
                >
                  {textData && textData.sectiontwobutton}
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="home_section assets_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-md-6 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
               
                <h2 className="sub_title">
                  {textData && textData.sectionthreeTitle}
                </h2>
               
                <p className="inner_para">
                  {textData && textData.sectionthreedesciption}
                </p>
               
                <Link className="primary_btn mt-3" to="/swap-classic">
                  {textData && textData.sectionthreebutton}
                </Link>
              </div>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="rotating_circle d_m_none">
                  <div className="center_img" style={{ backgroundImage: `url(${textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''})` }}></div>
                  <div class="middle dots">
                    <div class="dot icon-buch"></div>
                    <div class="dot icon-buch arbitrum" style={{ top: "21px" }}></div>
                    <div class="dot icon-buch optimism" style={{ top: "-12px", left: "0" }}></div>
                    <div class="dot icon-buch eth" style={{ top: "237px", left: "72px" }}></div>
                  </div>
                  <div className="outer dots">
                    <div className="dot icon-kompass"></div>
                    <div className="dot icon-kompass avalanche" style={{ left: "148px", top: "-40px" }}></div>
                    <div className="dot icon-screen fantom" style={{ left: "0", top: "70%" }}></div>
                    <div className="dot icon-screen base" style={{ left: "80%", top: "65%" }}></div>
                  </div>
                </div>
                <div className="d_d_none mobilelogo_center">
                  <img
                    src={require("../assets/images/mobile_img_022.png")}
                    alt="Image"
                    className="img-fluid"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                  <img
                    src={textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''}
                    className="img-fluid asset_sec_img"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="home_section wealth_section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 m-auto text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
               
                <h2 className="main_title">
                  {textData &&
                    textData.sectionfourHeading &&
                    textData.sectionfourHeading
                    ? textData.sectionfourHeading
                      .split(" ")
                      .map((word, index) => {
                        let match = false;
                        for (let colored of textData.sectionfourHighlightText) {
                          if (word.includes(colored)) {
                            match = true;
                          }
                        }
                        const style = {
                          color: match ? "yellow" : "white",
                        };

                        return (
                          <span key={index} style={style}>
                            {word}{" "}
                          </span>
                        );
                      })
                    : ""}
                </h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="rotating_circle d_m_none">
                  <div className="center_img" style={{ backgroundImage: `url(${textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''})` }}></div>
                  <div class="middle dots">
                    <div class="dot icon-buch"></div>
                    <div class="dot icon-buch arbitrum" style={{ top: "21px" }}></div>
                    <div class="dot icon-buch optimism" style={{ top: "-12px", left: "0" }}></div>
                    <div class="dot icon-buch eth" style={{ top: "237px", left: "72px" }}></div>
                  </div>
                  <div className="outer dots">
                    <div className="dot icon-kompass"></div>
                    <div className="dot icon-kompass avalanche" style={{ left: "148px", top: "-40px" }}></div>
                    <div className="dot icon-screen fantom" style={{ left: "0", top: "70%" }}></div>
                    <div className="dot icon-screen base" style={{ left: "80%", top: "65%" }}></div>
                  </div>
                </div>
                <div className="d_d_none mobilelogo_center">
                  <img
                    src={require("../assets/images/mobile_img_033.png")}
                    alt="Image"
                    className="d_d_none img-fluid"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                  <img
                    src={textData && textData ? config.imageUrl + "/logo/" + textData.homecontentURI : ''}
                    className="img-fluid wealth_sec_img"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  />
                </div>
              </div>
              <div
                className="col-md-6 col-lg-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
               
                <h2 className="sub_title">
                  {textData && textData.sectionfourTitle}
                </h2>
               
                <p className="inner_para">
                  {textData && textData.sectionfourdecription}
                </p>
               
                <a
                  href="/passive-income"
                  className="primary_btn mt-3"
                  style={{ textDecoration: "none" }}
                >
                  {textData && textData.sectionfourbutton}
                </a>
              </div>
            </div>
          </div>
        </section> */}

        <section className="home_section">
          <div className="container">
            <div className="row text-center">
              <div
                className="col-md-11 col-lg-7 m-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                {/* <h2 className="main_title">
                  Help build the future with{" "}
                  <span className="txt_yellow">HODL.</span> We invite all
                  developers to explore <span className="txt_yellow">HODL</span>{" "}
                  frameworks.
                </h2> */}
                <h2 className="main_title">
                  {textData && textData.futureheading && textData.futureheading
                    ? textData.futureheading.split(" ").map((word, index) => {
                      let match = false;
                      for (let colored of textData.futureheadingHighlightText) {
                        if (word.includes(colored)) {
                          match = true;
                        }
                      }
                      const style = {
                        color: match ? "#fbd334" : "white",
                      };

                      return (
                        <span key={index} style={style}>
                          {word}{" "}
                        </span>
                      );
                    })
                    : ""}
                </h2>
              </div>
            </div>
            <div className="row mt-5">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="frame_box">
                  <div>
                    <img
                      src={require("../assets/images/icon_01.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    {/* <h3>HODL Token Vault</h3> */}
                    <h3>{textData && textData.cardoneheading}</h3>
                  </div>
                  {/* <h2>The interest-bearing DeFi base layer</h2> */}
                  <h2>{textData && textData.cardonedescription}</h2>
                  {/* <a href="#">View more</a> */}
                  {/* <a href={textData && textData.cardonelink}> */}
                  <a href="javascript:void(0)" onClick={() => handleClick('interest-bearing')}>
                    {textData && textData.cardonebutton}
                  </a>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="frame_box">
                  <div>
                    <img
                      src={require("../assets/images/icon_02.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    {/* <h3>Trident AMM</h3> */}
                    <h3>{textData && textData.cardtwoheading}</h3>
                  </div>
                  {/* <h2>A future-proof framework for building AMMs</h2> */}
                  <h2>{textData && textData.cardtwodescription}</h2>
                  {/* <a href="#">View more</a> */}
                  {/* <a href={textData && textData.cardtwolink}>  */}
                  <a href="javascript:void(0)" onClick={() => handleClick('future-proof')}>
                    {textData && textData.cardtwobutton}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home_section help_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="home_section_div">
                  {/* <h2 className="main_title">Need Help?</h2> */}
                  <h2 className="main_title">
                    {textData && textData.helpsectionheading}
                  </h2>
                  {/* <p className="inner_para">
                    If you need help or have any questions, contact us on one of
                    our social channels
                  </p> */}
                  <p className="inner_para">
                    {textData && textData.helpsectiondescription}
                  </p>
                  <div className="home_section_div_flex">
                    <div className="home_section_div_left">
                      {/* <h2 className="sub_title">Contact Support</h2> */}
                      <h2 className="sub_title">
                        {textData && textData.supportheading}
                      </h2>
                      {/* <p className="inner_para">
                        Join the HODL Discord community and ask away!
                      </p> */}
                      <p className="inner_para">
                        {textData && textData.supportdescription}
                      </p>
                      <ul>
                        <li>
                          <a
                            href={textData && textData.supportlinkone}
                            target="_blank"
                          >
                            {textData && textData.discordURI && (
                              <img
                                src={
                                  config.imageUrl +
                                  "/logo/" +
                                  textData.discordURI
                                }
                                alt="logo"
                                className="img-fluid"
                              />
                            )}
                            <span>{textData && textData.supporttextone}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={textData && textData.supportlinktwo}
                            target="_blank"
                          >
                            {textData && textData.twitterURI && (
                              <img
                                src={
                                  config.imageUrl +
                                  "/logo/" +
                                  textData.twitterURI
                                }
                                alt="logo"
                                className="img-fluid"
                              />
                            )}
                            <span>{textData && textData.supporttexttwo}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={textData && textData.supportlinkthree}
                            target="_blank"
                          >
                            {textData && textData.githubURI && (
                              <img
                                src={
                                  config.imageUrl +
                                  "/logo/" +
                                  textData.githubURI
                                }
                                alt="logo"
                                className="img-fluid"
                              />
                            )}
                            <span>{textData && textData.supporttextthree}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={textData && textData.supportlinkfour}
                            target="_blank"
                          >
                            {textData && textData.youtubeURI && (
                              <img
                                src={
                                  config.imageUrl +
                                  "/logo/" +
                                  textData.youtubeURI
                                }
                                alt="logo"
                                className="img-fluid"
                              />
                            )}
                            <span>{textData && textData.supporttextfour}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="home_section_div_right">
                      <div className="frame_box mb-0">
                        {/* <h2 className="sub_title">HODL Academy</h2> */}
                        <h2 className="sub_title">
                          {textData && textData.sidebartext}
                        </h2>
                        {/* <p className="inner_para">
                          Demystifying DeFi - everything you need to know in one
                          place. For beginners to advanced users, and everyone
                          in between.
                        </p> */}
                        <p className="inner_para">
                          {textData && textData.sidebardescription}
                        </p>
                        {/* <Link to="/blog">Visit Academy</Link> */}
                        <Link to={textData && textData.sidebarlink}>
                          {textData && textData.sidebarbutton}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
