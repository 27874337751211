// import constant
import {
    GASCOST
} from '../constant';

const initialState = {
    gas: 0
};

const chainGasCost = (state = initialState, action) => {

    switch (action.type) {
        case GASCOST:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default chainGasCost;