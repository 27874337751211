var gastrakerkey = {
    1: "RH4QVBZ88AX3RGS9TBHG8FE48BSB1C4W23",
    56: "TVJCBD2X7BNSWM77QYWR4K4GCVQPXGGQFU",
    137: "5YGEWQFN9Y2V5GE4BNFDTNTJEQBXK2XP4H",
    43114: "key",
    250: "PJ2CJJMXRZAR6AIQ936FVG5PQ81F5X17AR",
    42161: "W7AU3WIH83JV5S1X6CVSXI1S7Z5PPE2Z67",
    10: "MPTX7A4BXE9QPNTTX6W2V4S1DNH9MS8DN9",
    128: "MPTX7A4BXE9QPNTTX6W2V4S1DNH9MS8DN9",
    8453: "MK5RWZTPTNRKDWD7MF19QBBKX1ES9DF8VS"
}
export default gastrakerkey;