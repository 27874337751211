export default {
  ABOUT_NEW_FEATURES: "о новых функциях.",
  ACCEPT: "Принимать",
  ACTIVE_ORDERS: "Активные заказы",
  ADD_TOKEN: "Добавить токен",
  AGGRESSIVE: "Агрессивный",
  AND: "и",
  APPROVE: "Утвердить",
  ASSETS_ON_WAY: "Ваши активы уже в пути и должны прибыть",
  AUTO: "Авто",
  AVOID_TRANSFER:
    "Пожалуйста, избегайте перевода в централизованный адрес обмена (Binance, Huobi ...). Это не поддерживаемая функция",
  BALANCE: "Баланс",
  BALANCE_ONE: "Баланс:",
  BLOG: "Отк",
  BRIDGE: "Мост",
  CANCEL: "Отмена",
  CANCELLATION_TRANSACTION_FEE:
    "Отмена предельного порядка включает в себя оплату сборов за транзакцию.",
  CANCEL_ALL: "Отменить все",
  CANCEL_LIMIT_NOT_FILLED:
    "Вы можете отменить ограниченный заказ, пока заказ еще не был заполнен.",
  CANCEL_LIMIT_ORDER: "Отменить лимитный заказ",
  CANCEL_ORDER: "Отменить заказ",
  CANCEL_SELECTED: "Отменить выбранный",
  CHAINS: "Блог",
  CHOOSE_NETWORK: "Выберите сеть",
  CLASSIC_MODE: "Классический режим",
  CLEAR: "Прозрачный",
  CONFIRM: "Подтверждать",
  CONFIRMATION_REJECTED: "Подтверждение отвергнуто.!",
  CONFIRM_SWAP: "Подтвердите обмен",
  CONNECT_WALLET: "Соединить кошелек",
  COPIED: "Скопированный",
  CURRENT: "Текущий",
  CUSTOM: "Обычай",
  DAYS: "Дни",
  DEFAULT: "По умолчанию",
  DETAILS: "Подробности",
  DISCLAIMER: "Отказ от ответственности",
  DISCONNECT_WALLET: "Отключить кошелек",
  DISMISS: "Увольнять",
  DUPLICATE_ORDER_SUBMIT: "Дубликат порядка. Этот уже представлен",
  ENTER_AMT: "Введите сумму",
  EXCHANGE: "Обмен",
  EXPIRY: "Истечение",
  EXPIRY_SM: "Истечение",
  FAILED_LOAD_CHART: "Не удалось загрузить график",
  FIND_BY_TOKEN_OR_STATUS: "Найти токен или статус",
  INSUFFICIENT_BALANCE: "Недостаточный баланс",
  INSUFFICIENT: "Недостаточный",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "баланс для покрытия существующих заказов баланса",
  FAST: "Быстрый",
  RECEIVERS: "Приемник",
  WALETADDRESS: "Адрес кошелька",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Vă rugăm să acceptați termenii de serviciu și politica de confidențialitate",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker nu ar trebui să fie aceeași adresă de preluare",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Toleranța la alunecare ar trebui să fie cuprinsă între 0,1% și 15%",
  SEARCH_A_TOKEN: "Căutați un jeton",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Tranzacția dvs. va reveni dacă prețul se va schimba nefavorabil cu mai mult decât acest procent",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Reglați prețul gazului pentru tranzacția dvs.GWEI mai mare = viteză mai mare.",
  REVIEW_OTC_ORDER: "Examinați comanda OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Perioada de expirare stabilită de producătorul de comenzi.Pe lângă această perioadă, comanda va expira și va fi arhivată.",
  SEARCH_A_LIQUIDITY_SOURCES: "Căutați surse de lichiditate",

  FAST_AND_SECURE:
    "Безопасный, быстрый и самый надежный способ привлечь активы по перекрестной цепи в",
  FEATURE_TO_TRANSFER:
    "Пожалуйста, не используйте эту функцию для передачи ваших жетонов на адрес централизованного обмена.",
  FEES: "Сборы",
  FILL_ORDER: "Заполнить заказ",
  FILL_OTC_ORDER: "Заполните заказ OTC",
  FREE: "Бесплатно",
  FROM: "От",
  GAS_COST: "Стоимость газа",
  GAS_DESTINATION: "Газ в цепочке назначения",
  GAS_ON_DESTINATION: "Газ в пункте назначения",
  GAS_PRICE: "Цена газа",
  GOT_IT: "Понятно",
  GOT_PROBLEM_JUST: "Есть проблема? Просто свяжитесь с нами.",
  HIGH: "Высокий",
  HOURS: "Часы",
  IMPORT: "Импортировать",
  IMPOSSIBLE_TO_RETRIEVE:
    "Это не поддерживаемая функция. Кроме того, пожалуйста, дважды проверьте адрес, который вы заполняете, любые токены, отправленные по неправильному адресу, невозможно получить.",
  INSTANT: "Мгновенный",
  INSUFFICIENT_APPROVED_AMT: "Недостаточная утвержденная сумма",
  INVALID_TOKEN_ADDRESS: "Неверный токен адрес",
  KEEP_TRACK_NOTIFY:
    "Вы можете отслеживать его в уведомлении о своей панели. Мы уведомим вас, как только это будет сделано.",
  KEEP_YOU_POSTED: "Полем Мы будем держать вас в курсе.",
  LEARN_MORE: "Узнать больше",
  LEGACY: "Наследие",
  LIMIT: "Предел",
  LIMIT_ORDER: "Ограниченный порядок",
  LIMIT_PRICE: "Предельная цена",
  LIMIT_PRICE_SM: "Предельная цена",
  LIQUIDITY_SOURCES: "Источники ликвидности",
  LOW: "Низкий",
  MAKER: "Производитель",
  MARKET: "Рынок",
  MAX: "Макс",
  MAX_BASE_FEE: "Максимальный базовый плата",
  MAX_S: "Макс",
  MAX_TO_PAY: "Макс, чтобы заплатить",
  MINIMUM_RECEIVED: "Минимальный получен",
  MINUTES: "Минуты",
  MONTHS: "Месяцы",
  NEED_APPROVAL_MOVE: "Нам нужно ваше разрешение на переезд",
  NEED_TO_WRAP: "Тебе нужно обернуть",
  NETWORK_FEES: "Сетевые сборы",
  NETWORK_SM: "сеть.",
  NEVER: "Никогда",
  NONE: "Никто",
  NON_CUSTODIAL_LIMIT:
    "в не внесении порядок ограниченного порядка. Это техническое требование на",
  NORMAL: "Нормальный",
  NO_ORDERS_YET: "У тебя еще нет заказов",
  NO_RECORDS: "Нет записей",
  ON_UR_BEHALF: "от вашего имени.",
  OOPS_WRONG: "Упс! Что-то пошло не так.!",
  OPEN: "Открыть",
  ORDER_DETAILS: "Информация для заказа",
  ORDER_HISTORY: "История заказов",
  ORDER_PRICE: "Стоимость заказа",
  ORDER_ROUTING: "Заказать маршрутизацию",
  OTC: "Отк",
  PASTE: "Вставить",
  PAY: "Платить",
  PENDING_CONFIRM: "В ожидании подтверждения на кошельке.",
  PER: "перемещение",
  PLEASE_CHOOSE_NETWORK: "Пожалуйста, выберите сеть",
  PLEASE_LOGIN: "Пожалуйста, войдите в",
  PLEASE_TRY_LATER: "Пожалуйста, повторите попытку позже",
  POPULAR_TOKENS: "Популярные жетоны",
  PRICE: "Цена",
  PRICE_IMPACT: "Ценовое воздействие",
  PRIVACY_POLICY: "политика конфиденциальности",
  RATE: "Ставка",
  READ_ACCEPT: "Я читаю и принимаю",
  RECEIVE: "Получать",
  RECEIVED_ORDERS: "Получили заказы",
  REFUND: "ВОЗВРАЩАТЬ ДЕНЬГИ",
  REMOVE: "Удалять",
  REVERSE_RATE: "Обратная скорость",
  REVIEW_TRADE: "Обзор торговли",
  SELECTED: "выбран",
  SELECT_ALL: "Выбрать все",
  SELECT_FROM_INFO: "Пожалуйста, выберите из информации",
  SELECT_NETWORK: "Выберите сеть",
  SELECT_TOKEN: "Выберите токен",
  SELECT_TOKENN: "Выберите токен",
  SELECT_TO_INFO: "Пожалуйста, выберите для информации",
  SETTINGS: "Настройки",
  SIMPLE_MODE: "Простой режим",
  SLIPPAGE: "Проскальзывание",
  SLIPPAGE_TOLERANCE: "Толерантность к проскальзыванию",
  SRY_INSUFFICIENT: "Извините, недостаточно",
  STATUS: "ПОЛОЖЕНИЕ ДЕЛ",
  STATUS_SM: "Положение дел",
  STEP: "Шаг",
  SUBMIT: "Представлять на рассмотрение",
  SUBMITTED_ORDERS: "Отправленные заказы",
  SUBMIT_DATE: "Отправить дату",
  SUCCESS_FILLED: "Успешно заполнен",
  SUCCESS_RECEIVED: "Успешно получил",
  SUCCESS_SUBMITTED_ORDER: "Успешно отправил ваш заказ",
  SWAP: "Менять",
  SWAP_TRANSFER: "Swap & Transfer",
  SWITCH_NETWORK: "Переключить сеть",
  TAKER: "Поступок",
  TAKER_ADDRESS: "Адрес",
  TERMS_OF_SERVICE: "Условия использования",
  TO: "К",
  TOKEN_NOT_FOUND: "Токен не найден",
  TOKEN_RECEIVE_SENT: "Токен для получения будет отправлен",
  TRADE: "Торговля",
  TRANSACTION_REJECTED_LATER: "Транзакция отвергнута, попробуйте еще раз позже",
  TRANSFER: "Передача",
  TRANSFER_ALERT: "Перевод предупреждения",
  TRANSFER_TO: "Перевести",
  TX_COST: "TX стоимость",
  UNLOCK: "Разблокировать",
  USER_GUIDE: "Гид пользователя",
  USE_ITAS: "и используйте его как w",
  VERIFY: "Проверять",
  VIEW_EXPLORER: "Просмотреть транзакцию в исследователе",
  VIEW_ON: "Смотреть на",
  VIEW_TRANSACTION: "Просмотреть транзакцию",
  WRAP: "Сворачивать",
  YOUR_ADDRESS: "Ваш адрес",
  YOUR_ORDER_CANCEL: "Ваш заказ отменен",
  YOUR_WALLET: "Ваш кошелек",
  YOU_GET: "Вы получаете",
  YOU_PAY: "Ты платишь",
  YOU_RECEIVE: "Вы получаете",
  YOU_WILL_RECEIVE: "Вы получите",
  ANYONE_CAN_CREATE:
    "Любой может создать любой токен, включая фальшивые версии существующих токенов. Позаботьтесь о помощи. Некоторые токены и их технические параметры могут быть несовместимы с нашими услугами. Импортируя этот пользовательский токен, вы признаете и принимаете риски.",
  BY_SIGNING_MESSAGE:
    "Подписав это сообщение, вы подтверждаете, что не обращаетесь к этому приложению или являетесь жителем США или любой другой ограниченной страны. Для получения дополнительной информации, пожалуйста, прочтите наш",
  CONSTITUTION: "Конституциядао",
  CURRENTLY_CONNECTED: "В настоящее время вы подключены к",
  IMPORT_TOKEN: "Импортировать токен",
  IN_YOUR_WALLET: "в вашем кошельке",
  MAKE_SURE_CHECKED:
    "Убедитесь, что вы проверили адрес токена, и это правильно",
  ON: "на",
  PEOPLE: "Люди",
  PLEASE_SWITCH: "Пожалуйста, переключитесь на",
  SELECTED_NOT_SUPPORTED:
    "Пожалуйста, измените сеть в вашем кошельке, выбранная сеть не поддерживается",
  SIGN_FOR_ACCESS: "Знак для доступа",
  SIGN_MESSAGE: "Сообщение о знаке",
  LAUNCH_APP: "Запуск приложения",
  GETTING_STARTED:
    "Начало работы с HODL: учебные пособия и объяснения продуктов",
  DEEP_DIVE: "DeepDive Into HODL: стратегии и функции продукта",
  BUILD_HODL: "Строительство HODL: техническая документация",
  BLOG_HEADING:
    "Демистификация DEFI - все, что вам нужно знать в одном месте. Для начинающих для продвинутых пользователей и всех между ними.",
  BLOG_PLACEHOLDER: "Поиск продукта/темы, о которой вы хотите узнать",
  CHOOSE_TOPIC: "Выберите тему",
  TRY: "Пытаться",
  SEARCH_RESULTS: "результаты поиска",
  SEARCH_TOPIC: "Поиск темы",
  LOAD_MORE: "Загрузи больше",
  SOCIAL_MEDIA: "socialMedia",
  NEW: "Новички вперед",
  OLD: "Oldest First",
  Z_A: "Сортировать название Z-A",
  A_Z: "Сортировать заголовок A-Z",
  RESULTS: "Полученные результаты",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Бейс -плата за газ автоматически рассчитывается сетью и варьируется от блока к блоку. Установите максимальную сумму базовой платы, которую вы хотите заплатить. Разница между максимальной базовой платой, которую вы установили, и фактической базовой платой будет возвращена после того, как блок будет добыт.",
  CANCELLED_TRANSACTION: "Отменил вашу транзакцию",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Создание ограниченного заказа - это бесплатная операция для производителя заказа, если они не отменили. Заказ должен будет оплатить плату за транзакцию, когда они заполняют заказ.",
  Cancelled: "Отменен",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Разница в стоимости между лучшим маршрутом и",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Включите этот вариант, если у вас есть проблемы, заменяющие токен, который имеет встроенные сборы за передачу.",
  Expired: "Истекший",
  Filled: "Заполненный",
  INSUFFICIENT_FOR_GASFEE: "Извините, недостаточно средств за газ",
  Open: "Открыть",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Частичное заполнение применяется только в том случае, если маршрут обмена состоит из нескольких частей основного маршрута. Это обеспечивает частичное выполнение, предотвращая обращение транзакции, если рыночная цена выходит из предположения.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Цена, установленная производителем заказов на выполнение их заказа (токен для получения = x токен для оплаты)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Цена, установленная производителем заказов на выполнение их заказа (токен для оплаты = y токен для получения",
  REVIEW_LIMIT_ORDER: "Просмотр предельного порядка",
  SEARCH_BY_TOKEN: "Поиск по токену или адресу",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Установка высокой толерантности к проскальзыванию может помочь транзакциям добиться успеха, но вы можете не получить такую ​​хорошую цену. Используйте с осторожностью.",
  STATUS_OF_THE_LIMIT_ORDER: "Статус ограниченного порядка",
  Suspended: "Приостановленный",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Эти сборы выплачиваются сетевым шахтерам. Ходл не контролирует сборы.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Разница между рыночной ценой и ценой EST. Из -за размера торговли",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Минимальная полученная сумма, в противном случае транзакция будет возвращена",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Эта плата идет непосредственно для шахтеров в качестве стимула для расстановки приоритетов вашей транзакции.",
  TRANSACTION_REJECTED_TRY: "Транзакция отвергнута, попробуйте еще раз",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Передача для выбранного токена не доступна в этой сети",
  YOU: "Ты",
};
