import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  updateChartDetail,
  updatePriceChart,
  updateDurationChart,
} from "./chartDetails";

import { setMarketPrice } from "../../reducers/Actions";
import config from "../../config/config";
import { getTokenRate } from "../../Api/SwapActions";

var chartInfo = {
  options: {
    chart: {
      id: "hodl-defi-chart",
      toolbar: {
        show: false,
      },
      labels: {
        show: false,
      },
      events: {
        mouseMove: function (event, chartContext, config) {
          var index = config.dataPointIndex;
          if (
            config.config &&
            config.config.series &&
            config.config.series[config.seriesIndex]
          ) {
            var data = config.config.series[config.seriesIndex].data;
            var price = data[index];
            var div = document.getElementById("currentPrice");
            div.innerHTML = price;
          }
        },
        mouseLeave: function (event, chartContext, config) {
          if (
            config.config &&
            config.config.series &&
            config.config.series[config.seriesIndex]
          ) {
            var data = config.config.series[config.seriesIndex].data;
            var price = data[data.length - 1];
            var div = document.getElementById("currentPrice");
            div.innerHTML = price;
          }
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
      },
      lines: {
        show: false, //or just here to disable only x axis grids
      },
      crosshairs: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      lines: {
        show: false, //or just here to disable only x axis grids
      },
    },
    dataLabels: {
      enabled: false, // <--- HERE
    },

    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
        labels: {
          show: false,
        },
      },
    },
    colors: ["green"],
    fill: {
      show: false,
      colors: "#a7e0c6",
      opacity: 4,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 1,
      dashArray: 0,
    },

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let dateTime =
          w &&
            w.globals &&
            w.globals.categoryLabels &&
            w.globals.categoryLabels[dataPointIndex]
            ? w.globals.categoryLabels[dataPointIndex]
            : "";
        let price =
          series && series[seriesIndex] && series[seriesIndex][dataPointIndex]
            ? series[seriesIndex][dataPointIndex]
            : "";
        return (
          '<div class="arrow_box">' +
          "<span>" +
          dateTime +
          ": " +
          price +
          "</span>" +
          "</div>"
        );
      },
    },
  },
  series: [
    {
      name: "Price",
      data: [],
    },
  ],
};

export default function ChartPage(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fromChart, setfromChart] = useState({
    address: "",
    interval: 0,
    list: [],
  });
  const [toChart, settoChart] = useState({
    address: "",
    interval: 0,
    list: [],
  });
  const [chartData, setchartData] = useState(chartInfo);
  const [isLoadChart, setisLoadChart] = useState("");
  const [isLoad, setisLoad] = useState(false);
  const [chartdetails, setchartdetails] = useState({
    amount: 0,
    percentage: "",
    time: "",
    color: "",
  });
  const [toChange, settoChange] = useState(false);
  const [duration, setduration] = useState("3D");
  const [currentPrice, setcurrentPrice] = useState("");

  const swapToken = useSelector((state) => state.swapToken);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    priceChart("3D");
    // eslint-disable-next-line
  }, [swapToken]);

  useEffect(() => {
    limitOrderPrice();
  }, [currentPrice]);

  async function limitOrderPrice() {
    console.log(currentPrice, 'currentPricecurrentPricecurrentPrice')
    dispatch(setMarketPrice({ price: currentPrice }));
  }

  async function priceChart(dur) {
    setisLoadChart("");
    setisLoad(true);
    setduration(dur);
    if (
      swapToken.fromaddress &&
      swapToken.toaddress &&
      swapToken.fromaddress !== "" &&
      swapToken.toaddress !== "" &&
      parseInt(walletConnection.network) > 0
    ) {
      let { tChart, fChart } = await updatePriceChart(
        walletConnection,
        fromChart,
        toChart,
        swapToken,
        settoChart,
        setfromChart
      );

      if (tChart && tChart.length > 0 && fChart && fChart.length > 0) {
        updateChart(tChart, fChart);
      } else {

        setisLoadChart("no");
        var chainId = (swapToken && swapToken.fromchainId) ? swapToken.fromchainId : 56;
        var id = config.coingeckoId[chainId];
        var fromAddr = swapToken.toaddress;
        if (fromAddr == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
          fromAddr = config.coin[chainId];
        }
        var rate1 = await getTokenRate(id, fromAddr);

        setcurrentPrice(rate1);
      }

      setisLoad(false);
      settoChange(true);
    }
  }

  async function durationChart(dur) {
    setisLoadChart("");
    setisLoad(true);
    setduration(dur);
    if (
      swapToken.fromaddress &&
      swapToken.toaddress &&
      swapToken.fromaddress !== "" &&
      swapToken.toaddress !== "" &&
      parseInt(walletConnection.network) > 0
    ) {
      try {
        let { tChart, fChart } = await updateDurationChart(
          setfromChart,
          settoChart,
          swapToken,
          walletConnection,
          dur
        );
        if (tChart && tChart.length > 0 && fChart && fChart.length > 0) {
          updateChart(tChart, fChart);
        } else {
          setisLoadChart("no");
          var chainId = (swapToken && swapToken.fromchainId) ? swapToken.fromchainId : 56;
          var id = config.coingeckoId[chainId];
          var fromAddr = swapToken.toaddress;
          if (fromAddr == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            fromAddr = config.coin[chainId];
          }
          var rate1 = await getTokenRate(id, fromAddr);
          console.log(rate1, "rate1");
          console.log(id, "rate1", fromAddr);
          setcurrentPrice(rate1);
        }
        setisLoad(false);
        settoChange(true);
        // }, 2000)
      } catch (err) {
        setisLoadChart("no");
        var chainId = (swapToken && swapToken.fromchainId) ? swapToken.fromchainId : 56;
        var id = config.coingeckoId[chainId];
        var fromAddr = swapToken.toaddress;
        if (fromAddr == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
          fromAddr = config.coin[chainId];
        }
        var rate1 = await getTokenRate(id, fromAddr);
        console.log(rate1, "rate1");
        console.log(id, "rate1", fromAddr);
        setcurrentPrice(rate1);
        setisLoad(false);
        settoChange(true);
      }
    }
  }

  async function interChange() {
    settoChange(false);

    setisLoad(true);
    if (isLoadChart === "no") {
      settoChange(true);
      setisLoad(false);
      return;
    }
    setisLoadChart("");
    var fChart = toChart.list;
    var tChart = fromChart.list;

    setfromChart(toChart);
    settoChart(fromChart);

    if (tChart && tChart.length > 0 && fChart && fChart.length > 0) {
      updateChart(tChart, fChart);
    } else {
      setisLoadChart("no");
      var chainId = (swapToken && swapToken.fromchainId) ? swapToken.fromchainId : 56;
      var id = config.coingeckoId[chainId];
      var fromAddr = swapToken.toaddress;
      if (fromAddr == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
        fromAddr = config.coin[chainId];
      }
      var rate1 = await getTokenRate(id, fromAddr);
      console.log(rate1, "rate1");
      console.log(id, "rate1", fromAddr);
      setcurrentPrice(rate1);
    }
    setTimeout(function () {
      settoChange(true);
    }, 2000);
  }

  function updateChart(tChart, fChart) {
    let fromAddr = swapToken.fromaddress;
    let chainId = walletConnection.network;
    updateChartDetail(
      tChart,
      fChart,
      duration,
      setchartData,
      setchartdetails,
      setisLoadChart,
      setcurrentPrice,
      setisLoad,
      chartInfo,
      fromAddr,
      chainId
    );
  }

  return (
    <div>
      <div className="swap_box">
        {!isLoad && (
          <div className="heading_block mb-4">
            <div>
              {swapToken.fromsymbol && (
                <span>
                  {swapToken.fromsymbol} / {swapToken.tosymbol}
                </span>
              )}
              {toChange ? (
                <i className="ms-3" onClick={() => interChange()}>
                  <img
                    src={require("../../assets/images/chart_exchange_icon.png")}
                    alt="Exchange"
                  />
                </i>
              ) : (
                <i className="ms-3">
                  <img
                    src={require("../../assets/images/chart_exchange_icon.png")}
                    alt="Exchange"
                  />
                </i>
              )}
            </div>
            <div className="days">
              <ul>
                <li
                  onClick={() => durationChart("12H")}
                  className={duration === "12H" ? "active" : ""}
                >
                  12H
                </li>
                <li
                  onClick={() => durationChart("1D")}
                  className={duration === "1D" ? "active" : ""}
                >
                  1D
                </li>
                <li
                  onClick={() => durationChart("3D")}
                  className={duration === "3D" ? "active" : ""}
                >
                  3D
                </li>
                <li
                  onClick={() => durationChart("1W")}
                  className={duration === "1W" ? "active" : ""}
                >
                  1W
                </li>
                <li
                  onClick={() => durationChart("1M")}
                  className={duration === "1M" ? "active" : ""}
                >
                  1M
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="chart_box">
          {isLoadChart === "yes" && (
            <div className="flx">
              <h5 id="currentPrice">{currentPrice}</h5>
              <span style={{ color: chartdetails.color }}>
                {chartdetails.percentage}% ({chartdetails.time})
              </span>
            </div>
          )}

          {isLoadChart === "no" ? (
            <div className="chart_failed_msg">
              <div>{t("FAILED_LOAD_CHART")}</div>
            </div>
          ) : isLoadChart === "yes" ? (
            <div>
              <div className="hide_height">
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="area"
                  width={"100%"}
                  height={300}
                />
              </div>
            </div>
          ) : isLoad ? (
            <ReactLoading type={"bars"} color="#fbd534" className="loading" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
