import moment from "moment";
import config from "../../config/config";
import { getChart1 } from "../../Api/SwapActions";
import { getTokenRate } from "../../Api/SwapActions";

var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export async function updatePriceChart(
    walletConnection,
    fromChart,
    toChart,
    tokenInfo,
    settoChart,
    setfromChart
) {

    if (tokenInfo.fromaddress && tokenInfo.toaddress && tokenInfo.fromaddress !== "" &&
        tokenInfo.toaddress !== "" && parseInt(walletConnection.network) > 0) {

        var d = new Date();
        d.setDate(d.getDate() - 3);
        var minutes = d.getMinutes();
        var fiveMinutes = minutes % 5;
        fiveMinutes = minutes - fiveMinutes;
        d.setMinutes(fiveMinutes);
        d.setSeconds(0);

        var to = new Date(d);
        to.setDate(to.getDate() + 3);

        var from = Math.floor(new Date(d).getTime() / 1000);
        var to = Math.floor(new Date(to).getTime() / 1000);

        var nw = parseInt(walletConnection.network);
        var wrappedAdd = config.coin[nw];
        var fChart = (fromChart && fromChart.list) ? fromChart.list : [];
        if (tokenInfo.fromaddress && toChart.address && tokenInfo.fromaddress.toLowerCase() === toChart.address.toLowerCase()) {
            fChart = (toChart && toChart.list) ? toChart.list : [];
        } else if (tokenInfo.fromaddress !== fromChart.address) {

            var details = {
                contract: (tokenInfo.fromaddress.toLowerCase()
                    === coinAddress) ? wrappedAdd : tokenInfo.fromaddress,
                from,
                to,
                network: parseInt(walletConnection.network)
            }

            let { result } = await getChart1(details);
            fChart = (result && result.prices) ? result.prices : 0;
            setfromChart({ list: fChart, address: details.contract, interval: '3D' });
        }

        var details1 = {
            contract: (tokenInfo.toaddress.toLowerCase()
                === coinAddress) ? wrappedAdd : tokenInfo.toaddress,
            from,
            to,
            network: parseInt(walletConnection.network)
        }
        //setTimeout(async function () {
        var tChart = (toChart && toChart.list) ? toChart.list : [];

        if (tokenInfo.toaddress && fromChart.address && tokenInfo.toaddress.toLowerCase() === fromChart.address.toLowerCase()) {
            tChart = (fromChart && fromChart.list) ? fromChart.list : [];
        } else if (tokenInfo.toaddress !== toChart.address) {
            let { result } = await getChart1(details1);
            tChart = (result && result.prices) ? result.prices : 0;
            settoChart({ list: tChart, address: details1.contract, interval: '3D' });
        }

        return {
            tChart,
            fChart
        }

    } else {
        return {
            tChart: [],
            fChart: []
        }

    }
}

export async function updateChartDetail(
    tChart,
    fChart,
    duration,
    setchartData,
    setchartdetails,
    setisLoadChart,
    setcurrentPrice,
    setisLoad,
    chartInfo,
    fromAddr,
    chainId
) {

    var timeData = [];
    var pairData = [];

    if (tChart && tChart.length > 0 && fChart && fChart.length > 0) {
        for (var c = 0; c < fChart.length; c++) {

            if (tChart[c] && tChart[c] && tChart[c][1] && fChart[c] && fChart[c] && fChart[c][1]) {
                var fPrice = parseFloat(fChart[c][1]) / parseFloat(tChart[c][1]);

                fPrice = (fPrice > 0) ? fPrice.toFixed(7) : 0;
                timeData.push(moment(fChart[c][0]).format('MMMM DD hh:mm A'));
                pairData.push(fPrice);
            }

        }
        var v1 = (pairData && pairData[0]) ?
            parseFloat(pairData[0]) : 0;
        var v2 = pairData[pairData.length - 1];
        v2 = parseFloat(v2);
        var percDiff = 100 * Math.abs((v1 - v2) / ((v1 + v2) / 2));
        v2 = (parseFloat(v2) > 0) ? parseFloat(v2).toFixed(7) : 0;
        var color = (v1 < v2) ? "green" : "red"
        var displayDays = (duration === "3D") ? "Past 3 days" : (duration === "1W") ? "Past 1 week" : (duration === "1M") ? "Past 1 month" : (duration === "12H") ? "Past 12 hours" : ""

        try {

            var newData1 = chartInfo;
            setTimeout(function () {
                newData1.options.xaxis.categories = timeData;
                newData1.options.colors[0] = color;
                newData1.options.fill.colors = color;
                newData1.series[0].data = pairData;
                setchartData(newData1);
                setchartdetails({
                    amount: v2,
                    percentage: percDiff.toFixed(3),
                    time: displayDays,
                    color: color
                });
                setisLoadChart("yes");
            }, 400);
        } catch (err) {
            console.log(err, 'errerrerr')
        }


        setcurrentPrice(v2);

        setisLoad(false);
    } else {
        var id = config.coingeckoId[chainId];
        if (fromAddr == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
            fromAddr = config.coin[chainId];
        }
        var rate1 = await getTokenRate(id, fromAddr);
        setcurrentPrice(rate1);
    }

}


export async function updateDurationChart(
    setfromChart,
    settoChart,
    tokenInfo,
    walletConnection,
    dur
) {

    if (tokenInfo.fromaddress && tokenInfo.toaddress && tokenInfo.fromaddress !== "" && tokenInfo.toaddress !== "" && parseInt(walletConnection.network) > 0) {
        try {

            var d = new Date();
            d.setDate(d.getDate() - 3);
            var from = Math.floor(new Date(d).getTime() / 1000);
            if (dur === "12H") {
                let d = new Date();
                d.setHours(d.getHours() - 12);
                from = Math.floor(new Date(d).getTime() / 1000);
            } else if (dur === "1D") {
                let d = new Date();
                d.setDate(d.getDate() - 1);
                from = Math.floor(new Date(d).getTime() / 1000);
            } else if (dur === "1W") {
                let d = new Date();
                d.setDate(d.getDate() - 7);
                from = Math.floor(new Date(d).getTime() / 1000);
            } else if (dur === "1M") {
                let d = new Date();
                d.setDate(d.getDate() - 28);
                from = Math.floor(new Date(d).getTime() / 1000);
            }

            var to = Math.floor(new Date().getTime() / 1000);

            var nw = parseInt(walletConnection.network);
            var wrappedAdd = config.coin[nw];

            var details = {
                contract: (tokenInfo.fromaddress.toLowerCase()
                    === coinAddress) ? wrappedAdd : tokenInfo.fromaddress,
                from,
                to,
                network: parseInt(walletConnection.network)
            }
            var resp = await getChart1(details);
            var fChart = (resp && resp.result && resp.result.prices) ? resp.result.prices : 0;
            setfromChart({ list: fChart, address: details.contract, interval: dur });


            var details1 = {
                contract: (tokenInfo.toaddress.toLowerCase()
                    === coinAddress) ? wrappedAdd : tokenInfo.toaddress,
                from,
                to,
                network: parseInt(walletConnection.network)
            }

            var { result } = await getChart1(details1);
            var tChart = (result && result.prices) ? result.prices : 0;
            settoChart({ list: tChart, address: details1.contract, interval: dur });

            return {
                tChart,
                fChart
            }
        } catch (err) {
            console.log(err, 'errerrerr')
            return {
                tChart: [],
                fChart: []
            }
        }

    }
}
