import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useParams } from 'react-router-dom'
import { getblogbylevel, loadbycat, getblogbycat } from "../Api/BlogActions"
import config from "../config/config";
import moment from 'moment';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function BlogCategory(props) {
  const { t, i18n } = useTranslation();
  const params = useParams()
  console.log(params, 'paramszz')

  const imageUrl = config.imageUrl;
  const [leveltype, setleveltype] = useState('Beginners');
  const [cattype, setcattype] = useState('');
  const [leveldata, setleveldata] = useState({})
  const [sort, setsort] = useState('new');
  const [count, setCount] = useState()
  const [category, setcategory] = useState({})
  const [catid, setcatid] = useState('')
  const [search, setSearch] = useState('');
  const [skip, setskip] = useState(0);
  const langcode = useSelector((state) => state.lang);
  async function getLevel() {
    var data = {
      leveltype: leveltype,
      categorytype: params.categorytype,
      sort: sort,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    var { result, totalrecords, catresult } = await getblogbycat(data)
    setleveldata(result)
    setCount(totalrecords)
    setcategory(catresult)
    if (params && params.categorytype) {
    console.log(catresult,'catresult46',params.categorytype)
    let foundObject = catresult.find(obj => obj.categoryname === params.categorytype);
console.log(foundObject,'foundObject')
if(foundObject){
  setcatid(foundObject._id)
}

    }
    else{
      setcatid(catresult[0]._id)
    }
   
  }
  const handleChange = (event) => {

    setleveltype(event.target.value)
    newfunc(event.target.value)
  }
  async function newfunc(value) {
    var data = {
      leveltype: value,
      sort: sort,
      catid: catid,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    setcatid(catid)
    var { result, totalrecords } = await loadbycat(data)
    setleveldata(result)
    setCount(totalrecords)

  }
  async function getLevelchange(value) {
    var data = {
      leveltype: value,
      sort: sort
    }
    var { result, totalrecords } = await getblogbylevel(data)
    setleveldata(result)
    setCount(totalrecords)

  }
  const handlesort = async(event) => {

     
      setsort(event.target.value)
     
    

  }
  async function fetchbyCategory(value, name) {
    setskip(0)
    var data = {
      leveltype: leveltype,
      sort: sort,
      catid: value,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    setcattype(name)
    setcatid(value)
    var { result, totalrecords } = await loadbycat(data)
    setleveldata(result)
    setCount(totalrecords)
  }

  async function load() {
    var data = {
      leveltype: leveltype,
      catid: catid,
      sort: sort,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    setcatid(catid)
    var { result, totalrecords } = await loadbycat(data)
    setleveldata(result)
    setCount(totalrecords)
  }
  const handlesearch = (event) => {
    console.log(event.target.value, 'event')
    setSearch(event.target.value)
  }

  async function fetchbysearch() {



    var data = {
      leveltype: leveltype,
      sort: sort,
      catid: catid,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }

    console.log(data, 'datat102')
    var { result, totalrecords } = await loadbycat(data)
    setleveldata(result)
    setCount(totalrecords)
  }

  async function handlepage(value) {
    var skiporder = skip + 4


    var data = {
      leveltype: leveltype,
      sort: sort,
      catid: catid,
      search: search,
      skip: skiporder,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    var { result, totalrecords } = await loadbycat(data)
    var arr = leveldata.concat(result)
    setleveldata(arr)
    setCount(totalrecords)
    setskip(skiporder)
  }
  async function loadsort() {
    var data = {
      leveltype: leveltype,
      sort: sort,
      catid: catid,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
      limit:leveldata && leveldata.length<=count ?leveldata.length:count
    }
    setcatid(catid)
    var { result, totalrecords } = await loadbycat(data)
    setleveldata(result)
    setCount(totalrecords)
  }
  useEffect(() => {
    if (params && params.categorytype) {
      setcattype(params.categorytype)

    }
    
  }, []);
  useEffect(() => {
   
    getLevel()
  }, [langcode.lancode]);
  useEffect(() => {
    if (catid && catid != '') {
      load()
    }

  }, [])
  useEffect(() => {
    if (catid && catid != '') {
      loadsort()
    }

  }, [sort])
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="blog_header blog_search_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto text-center">
            <h1>{t("BLOG")}</h1>
              <h2>{t("SEARCH_RESULTS")}</h2>
              <div className="blog_difficulty_select">

                <select className="form-select" aria-label="Default select example" value={leveltype} onChange={handleChange}>
                  {/* <option>Select Difficulty</option> */}
                  <option value="Beginners" >Beginner</option>
                  <option value="AdvancedUsers">Advanced</option>
                  <option value="TechnicalUsers">Technical</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper blog_search_container">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="blog_search_results_left">
                  <div className="blog_search">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("SEARCH_TOPIC")}
                        aria-label="blog"
                        aria-describedby="basic-addon2"
                        id="search"
                        onChange={handlesearch}

                      />
                      <button className="input-group-text" id="basic-addon2" onClick={() => { fetchbysearch() }}>
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                  <ul>

                    {category &&
                      category.length > 0 &&
                      category.map((item, key) => {

                        return (

                          <li>

                            <a className={item.categoryname == cattype ? "active" : ''} onClick={() => { fetchbyCategory(item._id, item.categoryname) }} >{item.categoryname}</a>
                          </li>
                        );
                      })}
                    {/* <li>
                      <a href="#" className="active">
                        Trident - AMM Framework
                      </a>
                    </li>
                    <li>
                      <a href="#">Furo - Streaming & Vesting</a>
                    </li>
                    <li>
                      <a href="#">Onsen - Yield Farming</a>
                    </li>
                    <li>
                      <a href="#">MISO - Token Launchpad</a>
                    </li>
                    <li>
                      <a href="#">DeFi</a>
                    </li>
                    <li>
                      <a href="#">Margin Trading</a>
                    </li>
                    <li>
                      <a href="#">Lending</a>
                    </li>
                    <li>
                      <a href="#">Crosschain</a>
                    </li>
                    <li>
                      <a href="#">Launchpad</a>
                    </li>
                    <li>
                      <a href="#">AMM</a>
                    </li>
                    <li>
                      <a href="#">Vesting</a>
                    </li>
                    <li>
                      <a href="#">Walkthrough</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="search_results_top">
                  <p className="mb-0">{count && count ? count + t("RESULTS") :t("NO_RECORDS")} </p>
                  <div className="blog_difficulty_select">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={sort} onChange={handlesort}
                    >
                      {/* <option >Sort by</option> */}
                      <option value="new">{t("NEW")}</option>
                      <option value="old">{t("OLD")}</option>
                      <option value="asc">{t("Z_A")}</option>
                      <option value="desc">{t("A_Z")}</option>
                    </select>
                  </div>
                </div>
                <div className="blog_wrap">

                  {leveldata &&
                    leveldata.length > 0 &&
                    leveldata.map((item, key) => {


                      return (
                        <a href={'/blog-detail/' + item.link}>
                          <div className="blog_single">
                            <div className="blog_img">
                              <img
                                src={`${imageUrl}/blog/${item.bannerlogo}`}
                                alt="Blog"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blog_content">
                              <div className={`${item.leveltype == 'AdvancedUsers' ? "blog_type blog_type_advance" : item.leveltype == 'TechnicalUsers' ? "blog_type blog_type_tech" : "blog_type blog_type_begin"}`}>
                                <i className="bi bi-circle-fill"></i>
                                <span>{"For " + item.leveltype}</span>
                              </div>
                              <ul className="blog_topic_list">
                                <li>
                                  <a href="#">{item.categoryname}</a>
                                </li>
                              </ul>
                              <h2>{item.title}</h2>
                              <p>{item.description} </p>
                              <p className="blog_date">{moment(item.createdAt).format('D MMM,YYYY')}</p>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  {/* <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_02.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_tech">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Technical Users</span>
                        </div>
                        <h2>Basics Of Cryptocurrency Trading Strategies</h2>
                        <p>
                          Learn about various trading strategies when trading
                          cryptocurrency{" "}
                        </p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_03.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_advance">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Advanced Users</span>
                        </div>
                        <ul className="blog_topic_list">
                          <li>
                            <a href="#">DeFi</a>
                          </li>
                        </ul>
                        <h2>What Does Yield Farming Mean In DeFi?</h2>
                        <p>Learn more about yield farming</p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_04.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_advance">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Advanced Users</span>
                        </div>
                        <ul className="blog_topic_list">
                          <li>
                            <a href="#">Trident</a>
                          </li>
                        </ul>
                        <h2>
                          HODL's Concentrated Liquidity (v3) Pool Walkthrough
                        </h2>
                        <p>
                          Learn how to open, manage & close a concentrated
                          liquidity position
                        </p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_01.png")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_begin">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Beginners</span>
                        </div>
                        <ul className="blog_topic_list">
                          <li>
                            <a href="#">Trident</a>
                          </li>
                        </ul>
                        <h2>Basics Of Risk Management</h2>
                        <p>Learn about risk management and how to mitigate </p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_02.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_tech">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Technical Users</span>
                        </div>
                        <h2>Basics Of Cryptocurrency Trading Strategies</h2>
                        <p>
                          Learn about various trading strategies when trading
                          cryptocurrency{" "}
                        </p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_03.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_advance">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Advanced Users</span>
                        </div>
                        <ul className="blog_topic_list">
                          <li>
                            <a href="#">DeFi</a>
                          </li>
                        </ul>
                        <h2>What Does Yield Farming Mean In DeFi?</h2>
                        <p>Learn more about yield farming</p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a>

                  <a href="#">
                    <div className="blog_single">
                      <div className="blog_img">
                        <img
                          src={require("../assets/images/blog_04.jpg")}
                          alt="Blog"
                          className="img-fluid"
                        />
                      </div>
                      <div className="blog_content">
                        <div className="blog_type blog_type_advance">
                          <i className="bi bi-circle-fill"></i>
                          <span>For Advanced Users</span>
                        </div>
                        <ul className="blog_topic_list">
                          <li>
                            <a href="#">Trident</a>
                          </li>
                        </ul>
                        <h2>
                          HODL's Concentrated Liquidity (v3) Pool Walkthrough
                        </h2>
                        <p>
                          Learn how to open, manage & close a concentrated
                          liquidity position
                        </p>
                        <p className="blog_date">11 May, 2023</p>
                      </div>
                    </div>
                  </a> */}
                </div>
                <div className="mt-5">

                  {/* <li className={disabled}>
                      <a className="page-link" onClick={()=>{handlepage('prev')}}>Previous</a>
                    </li> */}
                  {/* <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item " aria-current="page">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li> */}
                  {count && leveldata && (count > leveldata.length) &&

                    <button className="primary_btn" onClick={() => { handlepage() }}>
                    {t("LOAD_MORE")}
                    </button>

                  }

                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
