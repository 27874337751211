export default {
  ABOUT_NEW_FEATURES: "sur les nouvelles fonctionnalités.",
  ACCEPT: "Accepter",
  ACTIVE_ORDERS: "Commandes actives",
  ADD_TOKEN: "Ajouter un jeton",
  AGGRESSIVE: "Agressif",
  AND: "et",
  APPROVE: "Approuver",
  ASSETS_ON_WAY: "Vos actifs sont en route et devraient arriver",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Veuillez éviter de transférer vers une adresse d'échange centralisée (Binance, Huobi ...). Ce n'est pas une fonctionnalité prise en charge",
  BALANCE: "Équilibre",
  BALANCE_ONE: "Équilibre:",
  BLOG: "OTC",
  BRIDGE: "Pont",
  CANCEL: "Annuler",
  CANCELLATION_TRANSACTION_FEE:
    "L'annulation d'une ordonnance de limite consiste à payer les frais de transaction.",
  CANCEL_ALL: "Annuler tout",
  CANCEL_LIMIT_NOT_FILLED:
    "Vous pouvez annuler une commande limite tant que la commande n'a pas encore été remplie.",
  CANCEL_LIMIT_ORDER: "Annuler la commande de limite",
  CANCEL_ORDER: "Annuler la commande",
  CANCEL_SELECTED: "Annuler sélectionné",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Choisissez le réseau",
  CLASSIC_MODE: "Mode classique",
  CLEAR: "Clair",
  CONFIRM: "Confirmer",
  CONFIRMATION_REJECTED: "Confirmation rejetée.!",
  CONFIRM_SWAP: "Confirmer l'échange",
  CONNECT_WALLET: "Portefeuille de connexion",
  COPIED: "Copié",
  CURRENT: "Actuel",
  CUSTOM: "Coutume",
  DAYS: "Jours",
  DEFAULT: "Défaut",
  DETAILS: "Détails",
  DISCLAIMER: "Clause de non-responsabilité",
  DISCONNECT_WALLET: "Débrancher le portefeuille",
  DISMISS: "Rejeter",
  DUPLICATE_ORDER_SUBMIT: "Commande en double. Celui-ci est déjà soumis",
  ENTER_AMT: "Entrer le montant",
  EXCHANGE: "Échange",
  EXPIRY: "EXPIRATION",
  EXPIRY_SM: "Expiration",
  FAILED_LOAD_CHART: "Échec de chargement",
  FIND_BY_TOKEN_OR_STATUS: "Trouver par jeton ou statut",
  INSUFFICIENT_BALANCE: "Solde insuffisant",
  INSUFFICIENT: "Insuffisant",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "équilibre pour couvrir les commandes existantes Balance de",
  RECEIVERS: "Récepteur",
  WALETADDRESS: "Adresse du portefeuille",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Veuillez accepter les conditions d'utilisation et la politique de confidentialité",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker ne devrait pas être la même adresse de preneur",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "La tolérance au glissement doit être comprise entre 0,1% et 15%",
  SEARCH_A_TOKEN: "Rechercher un jeton",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Votre transaction reviendra si le prix change défavorablement par ce pourcentage",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Ajuste le prix du gaz pour votre transaction.GWEI plus élevé = vitesse plus élevée.",
  REVIEW_OTC_ORDER: "Examiner la commande OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Période d'expiration définie par le fabricant de commandes.Au-delà de cette période, la commande expirera et sera archivée.",
  SEARCH_A_LIQUIDITY_SOURCES: "Rechercher des sources de liquidité",

  FAST: "Rapide",
  FAST_AND_SECURE:
    "Le moyen sûr, rapide et le plus sécurisé d'apporter des actifs transversaux à",
  FEATURE_TO_TRANSFER:
    "Veuillez ne pas utiliser cette fonctionnalité pour transférer vos jetons à une adresse d'un échange centralisé.",
  FEES: "Frais",
  FILL_ORDER: "Commande de remplissage",
  FILL_OTC_ORDER: "Remplissez la commande OTC",
  FREE: "Gratuit",
  FROM: "Depuis",
  GAS_COST: "Coût du gaz",
  GAS_DESTINATION: "Gas sur la chaîne de destination",
  GAS_ON_DESTINATION: "Gas sur destination",
  GAS_PRICE: "Prix ​​du gaz",
  GOT_IT: "J'ai compris",
  GOT_PROBLEM_JUST: "J'ai un problème? Contactez-nous.",
  HIGH: "Haut",
  HOURS: "Heures",
  IMPORT: "Importer",
  IMPOSSIBLE_TO_RETRIEVE:
    "Ce n'est pas une fonctionnalité prise en charge. Veuillez également revérifier l'adresse que vous remplissez, tous les jetons envoyés à la mauvaise adresse seront impossibles à récupérer.",
  INSTANT: "Instantané",
  INSUFFICIENT_APPROVED_AMT: "Montant approuvé insuffisant",
  INVALID_TOKEN_ADDRESS: "Adresse de jeton non valide",
  KEEP_TRACK_NOTIFY:
    "Vous pouvez garder une trace dessus dans votre notification de panneau. Nous vous informerons une fois que cela sera fait.",
  KEEP_YOU_POSTED: ". Nous vous tiendrons au courant.",
  LEARN_MORE: "Apprendre encore plus",
  LEGACY: "Héritage",
  LIMIT: "Limite",
  LIMIT_ORDER: "limiter la commande",
  LIMIT_PRICE: "Limiter les prix",
  LIMIT_PRICE_SM: "Limiter les prix",
  LIQUIDITY_SOURCES: "Sources de liquidité",
  LOW: "Faible",
  MAKER: "Fabricant",
  MARKET: "Marché",
  MAX: "Max",
  MAX_BASE_FEE: "Frais de base maximaux",
  MAX_S: "max",
  MAX_TO_PAY: "Max à payer",
  MINIMUM_RECEIVED: "Minimum reçu",
  MINUTES: "Minutes",
  MONTHS: "Mois",
  NEED_APPROVAL_MOVE: "Nous avons besoin de votre approbation pour bouger",
  NEED_TO_WRAP: "Tu dois envelopper",
  NETWORK_FEES: "Frais de réseau",
  NETWORK_SM: "réseau.",
  NEVER: "Jamais",
  NONE: "Aucun",
  NON_CUSTODIAL_LIMIT:
    "dans l'ordre de limite non gardienne. C'est une exigence technique sur le",
  NORMAL: "Normale",
  NO_ORDERS_YET: "Vous n'avez pas encore de commandes",
  NO_RECORDS: "Pas d'enregistrements",
  ON_UR_BEHALF: "en votre nom.",
  OOPS_WRONG: "oups quelque chose s'est mal passé.!",
  OPEN: "Ouvrir",
  ORDER_DETAILS: "détails de la commande",
  ORDER_HISTORY: "Historique des commandes",
  ORDER_PRICE: "Prix ​​de commande",
  ORDER_ROUTING: "Routage des commandes",
  OTC: "OTC",
  PASTE: "Pâte",
  PAY: "Payer",
  PENDING_CONFIRM: "Confirmation en attente sur le portefeuille.",
  PER: "par",
  PLEASE_CHOOSE_NETWORK: "Veuillez choisir le réseau",
  PLEASE_LOGIN: "Veuillez vous connecter dans",
  PLEASE_TRY_LATER: "Veuillez réessayer plus tard",
  POPULAR_TOKENS: "Jetons populaires",
  PRICE: "Prix",
  PRICE_IMPACT: "Impact sur les prix",
  PRIVACY_POLICY: "politique de confidentialité",
  RATE: "Taux",
  READ_ACCEPT: "Je lis et accepte",
  RECEIVE: "Recevoir",
  RECEIVED_ORDERS: "Commandes reçues",
  REFUND: "REMBOURSEMENT",
  REMOVE: "Retirer",
  REVERSE_RATE: "Taux inversé",
  REVIEW_TRADE: "Examiner le commerce",
  SELECTED: "choisi",
  SELECT_ALL: "Tout sélectionner",
  SELECT_FROM_INFO: "Veuillez sélectionner parmi les informations",
  SELECT_NETWORK: "Sélectionnez un réseau",
  SELECT_TOKEN: "Sélectionnez un jeton",
  SELECT_TOKENN: "Sélectionner le jeton",
  SELECT_TO_INFO: "Veuillez sélectionner les informations",
  SETTINGS: "Paramètres",
  SIMPLE_MODE: "Mode simple",
  SLIPPAGE: "Glissement",
  SLIPPAGE_TOLERANCE: "Tolérance de glissement",
  SRY_INSUFFICIENT: "Désolé, insuffisant",
  STATUS: "STATUT",
  STATUS_SM: "Statut",
  STEP: "Étape",
  SUBMIT: "Soumettre",
  SUBMITTED_ORDERS: "Commandes soumises",
  SUBMIT_DATE: "Date butoire",
  SUCCESS_FILLED: "Ordre avec succès rempli",
  SUCCESS_RECEIVED: "Reçu avec succès",
  SUCCESS_SUBMITTED_ORDER: "Soumis avec succès votre commande",
  SWAP: "Échanger",
  SWAP_TRANSFER: "Échanger et transférer",
  SWITCH_NETWORK: "Réseau de commutation",
  TAKER: "Preneur",
  TAKER_ADDRESS: "Adresse",
  TERMS_OF_SERVICE: "Conditions d'utilisation",
  TO: "À",
  TOKEN_NOT_FOUND: "Jeton introuvable",
  TOKEN_RECEIVE_SENT: "Le jeton à recevoir sera envoyé à",
  TRADE: "Commerce",
  TRANSACTION_REJECTED_LATER:
    "Transaction rejetée s'il vous plaît réessayer plus tard",
  TRANSFER: "Transfert",
  TRANSFER_ALERT: "Alerte de transfert",
  TRANSFER_TO: "Transférer à",
  TX_COST: "Coût TX",
  UNLOCK: "Ouvrir",
  USER_GUIDE: "Mode d'emploi",
  USE_ITAS: "et l'utiliser comme w",
  VERIFY: "Vérifier",
  VIEW_EXPLORER: "Afficher la transaction dans Explorer",
  VIEW_ON: "Voir",
  VIEW_TRANSACTION: "Afficher la transaction",
  WRAP: "Envelopper",
  YOUR_ADDRESS: "Votre adresse",
  YOUR_ORDER_CANCEL: "Votre commande est annulée",
  YOUR_WALLET: "Ton portefeuille",
  YOU_GET: "Vous obtenez",
  YOU_PAY: "Tu payes",
  YOU_RECEIVE: "Vous recevez",
  YOU_WILL_RECEIVE: "Vous allez recevoir",
  ANYONE_CAN_CREATE:
    "Tout le monde peut créer n'importe quel jeton, y compris les fausses versions des jetons existants. Prendre soin. Certains jetons et leurs paramètres techniques peuvent être incompatibles avec nos services. En important ce jeton personnalisé, vous reconnaissez et acceptez les risques.",
  BY_SIGNING_MESSAGE:
    "En signant ce message, vous confirmez que vous n'accédez pas à cette application ou êtes un résident des États-Unis ou de tout autre pays restreint. Pour plus d'informations, veuillez lire notre",
  CONSTITUTION: "Constitutiondao",
  CURRENTLY_CONNECTED: "Vous êtes actuellement connecté à",
  IMPORT_TOKEN: "Importer un jeton",
  IN_YOUR_WALLET: "Dans votre portefeuille",
  MAKE_SURE_CHECKED:
    "Assurez-vous d'avoir vérifié l'adresse de jeton et c'est correct",
  ON: "sur",
  PEOPLE: "Personnes",
  PLEASE_SWITCH: "Veuillez passer à",
  SELECTED_NOT_SUPPORTED:
    "Veuillez modifier le réseau dans votre portefeuille, le réseau sélectionné n'est pas pris en charge",
  SIGN_FOR_ACCESS: "Signer l'accès",
  SIGN_MESSAGE: "Signer le message",
  LAUNCH_APP: "Démarrer l'application",
  GETTING_STARTED: "Début avec HODL: Tutoriels et explicateurs de produits",
  DEEP_DIVE: "Deepdive dans Hodl: stratégies et caractéristiques du produit",
  BUILD_HODL: "Construire sur Hodl: documentation technique",
  BLOG_HEADING:
    "Démystifier Defi - Tout ce que vous devez savoir en un seul endroit. Pour les débutants aux utilisateurs avancés et tous les deux.",
  BLOG_PLACEHOLDER:
    "Recherchez le produit / le sujet que vous souhaitez apprendre",
  CHOOSE_TOPIC: "Choisir le réseau",
  TRY: "ESSAYER",
  SEARCH_RESULTS: "Résultats de recherche",
  SEARCH_TOPIC: "Sujet de recherche",
  LOAD_MORE: "Charger plus",
  SOCIAL_MEDIA: "Réseaux sociaux",
  NEW: "Le plus récent d'abord",
  OLD: "Le plus ancien premier",
  Z_A: "Trier le titre Z-A",
  A_Z: "Trier le titre A-Z",
  RESULTS: "Résultats",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Des frais de gaz de base sont automatiquement calculés par le réseau et varie d'un bloc à l'autre. Définissez le montant maximal des frais de base que vous souhaitez payer. Une différence entre les frais de base maximaux que vous fixez et les frais de base réels sera remboursé une fois le bloc exploité.",
  CANCELLED_TRANSACTION: "Annulé votre transaction",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "La création d'une commande limite est une opération gratuite pour le créateur de commandes à moins qu'ils n'annulent. Le preneur de commande devra payer les frais de transaction pendant qu'ils remplissent la commande.",
  Cancelled: "Annulé",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Différence de valeur entre le meilleur itinéraire et",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Activez cette option si vous rencontrez des problèmes d'échange d'un jeton qui a des frais intégrés pour le transfert.",
  Expired: "Expiré",
  Filled: "Rempli",
  INSUFFICIENT_FOR_GASFEE:
    "Désolé, des fonds insuffisants pour les frais de gaz",
  Open: "Ouvrir",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Le remplissage partiel ne s'applique que si une route d'échange se compose de plusieurs pièces de route principale. Il permet une exécution partielle, empêchant la transaction d'être inversée si le prix du marché se déplace en disgrâce.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Prix ​​fixé par le fabricant de commandes d'exécution de sa commande (jeton pour recevoir = x jeton à payer)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Prix ​​fixé par le fabricant de commandes d'exécution de leur commande (jeton à payer = y token pour recevoir",
  REVIEW_LIMIT_ORDER: "Examiner la commande de limite",
  SEARCH_BY_TOKEN: "Recherche par jeton ou adresse",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "La définition d'une tolérance à forte glissement peut aider les transactions à réussir, mais vous ne pouvez pas obtenir un si bon prix. Utiliser avec précaution.",
  STATUS_OF_THE_LIMIT_ORDER: "Statut de l'ordre de limite",
  Suspended: "Suspendu",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Ces frais sont versés aux mineurs de réseau. Hodl ne contrôle pas les frais.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "La différence entre le prix du marché et le prix du marché dû à la taille du commerce",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Le montant minimum reçu, sinon la transaction sera retenue",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Ces frais vont directement aux mineurs comme une incitation à hiérarchiser votre transaction.",
  TRANSACTION_REJECTED_TRY: "Transaction rejetée, veuillez réessayer",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Le transfert pour le jeton sélectionné n'est pas disponible sur ce réseau",
  YOU: "Toi",
};
