import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const GetTokenList = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/token-list?chainId=${data.chainId}&useraddress=${data.useraddress}`
        });
        return {
            loading: false,
            result: respData.data.list
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const AddCustomToken = async (data) => {

    try {
        await axios({
            'method': 'post',
            'url': `${baseUrl}/add-custom-token`,
            'data': data
        });
        return {
            loading: false
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const SaveUsers = async (data) => {

    try {
        var resp = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-users`,
            'data': data
        });
        return {
            loading: false,
            isSignature: (resp && resp.data && resp.data.isSignature) ? resp.data.isSignature : "no"
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err),
            isSignature: "no"
        }
    }
}

export const GetDexSites = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-dex-site`,
            data: data
        });
        return {
            loading: false,
            result: respData.data.list
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const getLoginInfo = async (data) => {
    try {
        let countrydetails = await axios({
            'method': 'get',
            'url': config.locationApi,
            "timeout": 10000
        });
        return {
            status: true,
            ip: countrydetails && countrydetails.data && countrydetails.data.ipAddress ? countrydetails.data.ipAddress : '',
            country: countrydetails && countrydetails.data && countrydetails.data.countryName ? countrydetails.data.countryName : '',
        }
    } catch (err) {
        return {
            status: false,
            error: returnErr(err),
            ip: "",
            country: ""
        }
    }
}


export const saveVisitors = async (data) => {

    try {
        await axios({
            'method': 'post',
            'url': `${baseUrl}/save-visitors`,
            'data': data
        });
        return {
            loading: false
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const getFeeInfo = async (data) => {
    try {
        let resp = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-fee`,
            "data": data
        });
        return {
            status: true,
            fee: (resp && resp.data && resp.data.fee) ? resp.data.fee : 0,
            adminAddress: (resp && resp.data && resp.data.address) ? resp.data.address : "",
        }
    }
    catch (err) {
        return {
            status: false,
            error: returnErr(err),
            fee: 0,
            adminAddress: "",
        }
    }
}

export const GetUser = async (data) => {

    try {
        var resp = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-user`,
            'data': data
        });
        return {
            loading: false,
            isSignature: (resp && resp.data && resp.data.isSignature) ? resp.data.isSignature : "no"
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err),
            isSignature: "no"
        }
    }
}

export const SaveSignature = async (data) => {

    try {
        var resp = await axios({
            'method': 'post',
            'url': `${baseUrl}/update-signature`,
            'data': data
        });
        return {
            loading: false
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const GetMeta = async (data) => {

    try {
        var resp = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-meta`
        });

        return {
            loading: false,
            result: (resp && resp.data && resp.data.result) ? resp.data.result : null
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err),
            result: null
        }
    }
}

function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


