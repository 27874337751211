// import constant
import {
    LANG
} from '../constant';

const initialState = {
    lancode: 'en'
};

const lang = (state = initialState, action) => {

    switch (action.type) {
        case LANG:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default lang;