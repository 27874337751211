export default {
  YOU_PAY: "You Pay",
  MAX: "Max",
  YOU_RECEIVE: "You Receive",
  TRANSFER_TO: "Transfer to",
  VERIFY: "Verify",
  PASTE: "Paste",
  REMOVE: "Remove",
  DISCLAIMER: "Disclaimer",
  FEATURE_TO_TRANSFER:
    "Please do not use this feature to transfer your tokens to an address of a centralized exchange.",
  IMPOSSIBLE_TO_RETRIEVE:
    "It is not a supported feature. Also, please double-check the address you fill, any tokens sent to the wrong address will be impossible to retrieve.",
  CONNECT_WALLET: "Connect Wallet",
  SRY_INSUFFICIENT: "Sorry, Insufficient",
  BALANCE: "Balance",
  APPROVE: "Approve",
  ENTER_AMT: "Enter Amount",
  SLIPPAGE_TOLERANCE: "Slippage Tolerance",
  PRICE_IMPACT: "Price Impact",
  NETWORK_FEES: "Network Fees",
  REFUND: "REFUND",
  SWAP_TRANSFER: "Swap & Transfer",
  SWAP: "Swap",
  TRANSFER: "Transfer",
  LIMIT: "Limit",
  OTC: "OTC",
  MAX_TO_PAY: "Max to Pay",
  SELECT_TOKEN: "Select a token",
  POPULAR_TOKENS: "Popular tokens",
  NO_RECORDS: "No Records",
  ADD_TOKEN: "Add a token",
  IMPORT: "Import",
  TOKEN_NOT_FOUND: "Token not found",
  INVALID_TOKEN_ADDRESS: "Invalid token address",
  CONFIRM_SWAP: "Confirm Swap",
  GAS_PRICE: "Gas Price",
  PRICE: "Price",
  MINIMUM_RECEIVED: "Minimum Received",
  TRANSFER_ALERT: "Transfer Alert",
  TOKEN_RECEIVE_SENT: "The token to receive will be sent to",
  AVOID_TRANSFER:
    "Please avoid transferring to a centralized exchange address (Binance, Huobi...). It is not a supported feature",
  SUCCESS_RECEIVED: "Successfully Received",
  VIEW_ON: "View on",
  CONFIRM: "Confirm",
  SETTINGS: "Settings",
  NORMAL: "Normal",
  LIQUIDITY_SOURCES: "Liquidity sources",
  FAST: "Fast",
  INSTANT: "Instant",
  SELECTED: "selected",
  CLEAR: "Clear",
  SELECT_ALL: "Select all",
  ORDER_ROUTING: "Order Routing",
  EXCHANGE: "Exchange",
  YOU_GET: "You Get",
  FEES: "Fees",
  FAILED_LOAD_CHART: "Failed to load chart",
  FIND_BY_TOKEN_OR_STATUS: "Find by token or status",
  INSUFFICIENT_BALANCE: "Insufficient balance",
  INSUFFICIENT: "Insufficient",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "balance to cover existing orders balance of",
  RECEIVERS: "Receiver's",
  WALETADDRESS: "Wallet Address",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Please Accept Terms of Service and Privacy Policy",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker taker should not be same taker address",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Slippage Tolerance should be between 0.1% and 15%",
  SEARCH_A_TOKEN: "Search a token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Your transaction will revert if the price changes unfavorably by more that this percentage",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Adjusts the gas price for your transaction. Higher Gwei = higher speed.",
  REVIEW_OTC_ORDER: "Review OTC Order",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Expiry period set by the order maker. Past this period, the order will expire and be archived.",
  SEARCH_A_LIQUIDITY_SOURCES: "Search a liquidity sources",

  BRIDGE: "Bridge",
  BALANCE_ONE: "Balance:",
  MAX_S: "max",
  YOU_WILL_RECEIVE: "You will receive",
  SLIPPAGE: "Slippage",
  GAS_ON_DESTINATION: "Gas on destination",
  GAS_COST: "Gas cost",
  SELECT_FROM_INFO: "Please select from info",
  SELECT_TO_INFO: "Please select to info",
  SWITCH_NETWORK: "Switch network",
  SELECT_NETWORK: "Select a network",
  STEP: "Step",
  ACCEPT: "Accept",
  TERMS_OF_SERVICE: "Terms of Service",
  AND: "and",
  PRIVACY_POLICY: "Privacy Policy",
  READ_ACCEPT: "I read and accept",
  CHOOSE_NETWORK: "Choose Network",
  ACTIVE_ORDERS: "Active orders",
  ORDER_HISTORY: "Order history",
  CANCEL_SELECTED: "Cancel Selected",
  CANCEL_ALL: "Cancel All",
  LIMIT_PRICE: "LIMIT PRICE",
  EXPIRY: "EXPIRY",
  STATUS: "STATUS",
  NO_ORDERS_YET: "You have no orders yet",
  OPEN: "Open",
  PER: "per",
  DETAILS: "Details",
  CANCEL_LIMIT_ORDER: "Cancel Limit Order",
  CANCEL_LIMIT_NOT_FILLED:
    "You can cancel a limit order as long as the order has not been filled yet.",
  CANCELLATION_TRANSACTION_FEE:
    "Cancellation of a limit order involves paying the transaction fees.",
  DISMISS: "Dismiss",
  CANCEL_ORDER: "Cancel order",
  ORDER_DETAILS: "Order Details",
  PAY: "Pay",
  RECEIVE: "Receive",
  SUBMIT_DATE: "Submit Date",
  MAKER: "Maker",
  LIMIT_PRICE_SM: "Limit Price",
  VIEW_EXPLORER: "View Transaction in Explorer",
  STATUS_SM: "Status",
  SELECT_TOKENN: "Select token",
  RATE: "Rate",
  EXPIRY_SM: "Expiry",
  NEVER: "Never",
  MINUTES: "Minutes",
  HOURS: "Hours",
  DAYS: "Days",
  MONTHS: "Months",
  TAKER_ADDRESS: "Taker address",
  WRAP: "Wrap",
  REVIEW_TRADE: "Review Trade",
  UNLOCK: "Unlock",
  FREE: "Free",
  SUBMITTED_ORDERS: "Submitted Orders",
  RECEIVED_ORDERS: "Received Orders",
  FILL_ORDER: "Fill Order",
  FILL_OTC_ORDER: "Fill OTC Order",
  ORDER_PRICE: "Order Price",
  SUBMIT: "Submit",
  CANCEL: "Cancel",
  SUCCESS_FILLED: "Successfully Order Filled",
  NEED_APPROVAL_MOVE: "We need your approval to move",
  ON_UR_BEHALF: "on your behalf.",
  FAST_AND_SECURE:
    "The safe, fast and most secure way to bring cross-chain assets to",
  USER_GUIDE: "User Guide",
  GOT_PROBLEM_JUST: "Got a problem? Just get in touch.",
  ASSETS_ON_WAY: "Your assets are on the way and should arrive in",
  KEEP_YOU_POSTED: ". We'll keep you posted.",
  KEEP_TRACK_NOTIFY:
    "You can keep track on it in your panel notification. We will notify you once it's done.",
  VIEW_TRANSACTION: "View Transaction",
  GOT_IT: "Got It",
  PENDING_CONFIRM: "Pending confirmation on wallet.",
  TO: "To",
  FROM: "From",
  AUTO: "Auto",
  TX_COST: "Tx cost",
  MARKET: "Market",
  LEGACY: "Legacy",
  HIGH: "High",
  LOW: "Low",
  CURRENT: "Current",
  MAX_BASE_FEE: "Max base fee",
  CUSTOM: "Custom",
  AGGRESSIVE: "Aggressive",
  GAS_DESTINATION: "Gas on destination chain",
  NONE: "None",
  DEFAULT: "Default",
  LEARN_MORE: "Learn more",
  ABOUT_NEW_FEATURES: "about the new features.",
  YOUR_WALLET: "Your Wallet",
  YOUR_ADDRESS: "Your Address",
  COPIED: "Copied",
  DISCONNECT_WALLET: "Disconnect Wallet",
  REVERSE_RATE: "Reverse Rate",
  TAKER: "Taker",
  SUCCESS_SUBMITTED_ORDER: "Successfully submitted your order",
  NEED_TO_WRAP: "You need to wrap",
  USE_ITAS: "and use it as W",
  NON_CUSTODIAL_LIMIT:
    "in the non-custodial Limit Order. It's a technical requirement on the",
  NETWORK_SM: "network.",
  PLEASE_LOGIN: "Please login into",
  OOPS_WRONG: "oops something went wrong.!",
  PLEASE_CHOOSE_NETWORK: "Please Choose Network",
  PLEASE_TRY_LATER: "Please try again later",
  TRANSACTION_REJECTED_LATER: "Transaction rejected Please try again later",
  CONFIRMATION_REJECTED: "Confirmation rejected.!",
  YOUR_ORDER_CANCEL: "Your order is cancelled",
  DUPLICATE_ORDER_SUBMIT: "Duplicate Order. This one is already submitted",
  INSUFFICIENT_APPROVED_AMT: "Insufficient approved amount",
  TRADE: "Trade",
  SIMPLE_MODE: "Simple Mode",
  CLASSIC_MODE: "Classic Mode",
  LIMIT_ORDER: "limit order",
  OTC: "OTC",
  BLOG: "Blog",
  CHAINS: "chains",
  SELECTED_NOT_SUPPORTED:
    "Please change network in your wallet, selected network is not supported",
  CURRENTLY_CONNECTED: "You are currently connected to",
  ON: "on",
  PLEASE_SWITCH: "please switch to",
  IN_YOUR_WALLET: " in your wallet",
  IMPORT_TOKEN: "Import a token",
  CONSTITUTION: "ConstitutionDAO",
  PEOPLE: "People",
  MAKE_SURE_CHECKED:
    "Make sure you have checked the token address and it's correct",
  ANYONE_CAN_CREATE:
    "Anyone can create any token, including fake versions of the existing tokens. Take due care. Some tokens and their technical parameters may be incompatible with our services. By importing this custom token you acknowledge and accept the risks.",
  SIGN_FOR_ACCESS: "Sign for Access",
  BY_SIGNING_MESSAGE:
    "By signing this message, you confirm that you are not accessing this app from, or are a resident of the USA or any other restricted country. For more information, please read our",
  SIGN_MESSAGE: "Sign message",
  LAUNCH_APP: "Launch App",
  BLOG_HEADING:
    "Demystifying DeFi - everything you need to know in one place. For beginners to advanced users, and everyone in between.",
  GETTING_STARTED: "Getting started with HODL: Tutorials & Product Explainers",
  DEEP_DIVE: "Deepdive into HODL: Strategies & Product Features",
  BUILD_HODL: "Building on HODL: Technical Documentation",
  BLOG_PLACEHOLDER: "Search for the product/topic you want to learn about",
  CHOOSE_TOPIC: "Choose Topic",
  TRY: "Try",
  BLOG_TITLE: "Blog",
  SEARCH_RESULTS: "Search Results",
  SEARCH_TOPIC: "Search Topic",
  LOAD_MORE: "Load More",
  SOCIAL_MEDIA: "Social Media",
  NEW: "Newest First",
  OLD: "Oldest First",
  Z_A: " Sort Title Z-A",
  A_Z: " Sort Title A-Z",
  RESULTS: "Results",
  SEARCH_BY_TOKEN: "Search by token or address",
  REVIEW_LIMIT_ORDER: "Review Limit order",
  YOU: "You",
  Suspended: "Suspended",
  Cancelled: "Cancelled",
  Expired: "Expired",
  Filled: "Filled",
  Open: "Open",
  INSUFFICIENT_FOR_GASFEE: "Sorry ,insufficient funds for gas fee",
  CANCELLED_TRANSACTION: "Cancelled your transaction",
  TRANSACTION_REJECTED_TRY: "Transaction rejected, please try again",

  
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "The minimum received amount, otherwise the transaction will be reverted",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "A base gas fee is automatically calculated by the network and varies from block to block. Set the max amount of the base fee you wish to pay. A difference between the max base fee you set and the actual base fee will be refunded once the block has been mined.",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "This fee goes directly to miners as an incentive to prioritize your transaction.",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Enable this option if you are having issues swapping a token that has built-in fees for transferring.",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Partial fill only applies if a swap route consists of multiple main route parts. It allows for partial execution, preventing the transaction from being reversed if the market price moves out of favor.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "The difference between market price and est. price due to trade size",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Transfer for the selected token are not available on this network",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "These fees are paid to the network miners. HODL don't control the fees.",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Difference in value between the best route and",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Price set by the order maker for executing their order (token to receive = X token to pay)",
  STATUS_OF_THE_LIMIT_ORDER: "Status of the limit order",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Price set by the order maker for executing their order (token to pay = Y token to receive",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Creating a limit order is a free operation for the order maker unless they cancel. The order taker will be required to pay for the transaction fees while they fill the order.",
};
