import React, { useEffect } from "react";

import ChartPage from "../chartLimitClassic/chartPageLimit";
import LimitOrderList from "./limitOrderList";
import P2POrderList from "./P2POrderList";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

import Limitotc from "./limit-otc";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Swaplimit(props) {

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_page_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 order-lg-1 order-2">
              <div className="row">
                <div className="col-12">
                  <ChartPage />
                  <div id="chart"></div>
                  {location.pathname === "/swap-limit" && <LimitOrderList />}
                  {location.pathname === "/p2p" && <P2POrderList />}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 order-lg-2 order-1">
              <Limitotc />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
