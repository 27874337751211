import { connection, readWeb3 } from "../helper/connection";
import BridgeABI from "../ABI/Bridge.json";
import BridgeETHABI from "../ABI/RouterETHABI.json";
import feeLibraryABI from "../ABI/bridgefeeLibrary.json";
import LPStakingABI from "../ABI/LPStaking.json";
import BEP20ABI from "../ABI/BEP20.json";
import MiddlewareABI from "../ABI/Middleware.json";
import store from '../store';

import {
    Multicall
} from "ethereum-multicall";

import Web3 from "web3";

import {
    formatNumber
} from "../helper/custommath";

import { convert } from "../helper/convert";
import config from "../config/config"

export const swap = async (
    tobridgeId,
    frompoolId,
    topoolId,
    amount,
    Router,
    gasCost,
    tochainId,
    symbol,
    minReceivedAmt,
    fromaddress
) => {

    try {

        var currentProvider = store.getState();
        var settings = (currentProvider && currentProvider.bridgesettings) ? currentProvider.bridgesettings : {};


        var get = await connection();
        var web3 = get.web3;
        var network = get.network;
        var ABI = BridgeABI;
        if (symbol == "ETH") {
            ABI = BridgeETHABI;
        }

        // var Router = config.bridge[network].middleware;

        var Contract = new web3.eth.Contract(MiddlewareABI, Router);
        var address = get.address;
        gasCost = parseFloat(gasCost) + 200000000000000
        gasCost = gasCost.toString();
        gasCost = convert(gasCost);

        amount = amount.toString();
        amount = convert(amount);

        var gasPrice = await web3.eth.getGasPrice();

        console.log(tobridgeId.toString(),
            frompoolId.toString(),
            topoolId.toString(),
            address,
            amount.toString(),
            "0",
            { dstGasForCall: 0, dstNativeAmount: gasCost, dstNativeAddr: "0x" },
            address,
            "0x", '????????????????', gasCost)
        if (symbol == "ETH") {

            var estimateGas = await Contract.methods.swapBridgeETH(
                tobridgeId.toString(),
                address,
                address,
                amount.toString(),
                "0",
            ).estimateGas({ from: address, value: gasCost });

            estimateGas = parseFloat(estimateGas) + parseFloat(30000);

            await Contract.methods.swapBridgeETH(
                tobridgeId.toString(),
                address,
                address,
                amount.toString(),
                "0",
            ).send({ from: address, value: gasCost, gasLimit: estimateGas, gasPrice: gasPrice });

        } else {
            await Contract.methods.swapBridge(
                tobridgeId.toString(),
                frompoolId.toString(),
                topoolId.toString(),
                address,
                amount.toString(),
                "0",
                { dstGasForCall: 0, dstNativeAmount: gasCost, dstNativeAddr: "0x" },
                address,
                "0x",
                fromaddress
            ).estimateGas({ from: address, value: gasCost });

            if (Router === "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd") {
                estimateGas = parseFloat(estimateGas) + parseFloat(30000000);
            } else {
                estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            }

            var tx = await Contract.methods.swapBridge(
                tobridgeId.toString(),
                frompoolId.toString(),
                topoolId.toString(),
                address,
                amount.toString(),
                "0",
                { dstGasForCall: 0, dstNativeAmount: gasCost, dstNativeAddr: "0x" },
                address,
                "0x",
                fromaddress
            ).send({ from: address, value: gasCost, gasLimit: estimateGas, gasPrice: gasPrice });
        }
        var status = false;
        var hash = null;
        if (tx && tx.status) {
            status = true;
            hash = tx.transactionHash;
        }

        var adminFee = (tx && tx.events && tx.events.swapBridgeEvent
            && tx.events.swapBridgeEvent.returnValues && tx.events.swapBridgeEvent.returnValues[2]) ?
            tx.events.swapBridgeEvent.returnValues[2] : 0;

        return {
            status,
            txHash: hash,
            error: null,
            adminFee
        }
    } catch (err) {
        console.log(err, 'swap errerrerrerr')
        var errorMsg = (err && err.message) ? err.message : err;
        var pos = errorMsg.search("insufficient funds");
        var pos1 = errorMsg.search("User denied transaction signature")
        var error = "TRANSACTION_REJECTED_TRY";
        if (pos >= 0) {
            error = "INSUFFICIENT_FOR_GASFEE";
        } else if (pos1 >= 0) {
            error = "CANCELLED_TRANSACTION";
        }
        return {
            status: false,
            txHash: null,
            error: error,
            adminFee: 0
        }
    }
}

export const getFees = async (tobridgeId, frompoolId, topoolId, amount, Router) => {

    try {
        var get = await connection();
        var web3 = get.web3;
        var Contract = new web3.eth.Contract(feeLibraryABI, "0xCA6522116e8611A346D53Cc2005AC4192e3fc2BC");
        var address = get.address;

        var getFees = await Contract.methods.getFees(
            frompoolId.toString(),
            topoolId.toString(),
            tobridgeId.toString(),
            address,
            amount.toString()
        ).call();

        return getFees;
    } catch (err) {
        // console.log(err, 'getFees errerrerrerr')
        return 0;

    }
}

export const getLayerZeroFee = async (router, tobridgeId, decimals, chainId) => {

    //return;
    try {

        var currentProvider = store.getState();
        var settings = (currentProvider && currentProvider.bridgesettings) ? currentProvider.bridgesettings : 0;
        var bridgeToken = (currentProvider && currentProvider.bridgeToken) ? currentProvider.bridgeToken : {};
        var decimal = (bridgeToken && bridgeToken.todecimals) ? bridgeToken.todecimals : 0
        var dstNativeAmount = (settings && settings.bvalue) ? parseFloat(settings.bvalue) : 0;

        if (dstNativeAmount > 0) {
            dstNativeAmount = dstNativeAmount * 10 ** parseFloat(18);
        }

        dstNativeAmount = dstNativeAmount.toString();
        var get = await connection();
        let rpcUrl = config.rpc[chainId];
        var web3 = new Web3(rpcUrl);
        var address = get.address;

        var Contract = new web3.eth.Contract(BridgeABI, router);

        var result1 = await Contract.methods.quoteLayerZeroFee(
            tobridgeId.toString(), "1", address, "0x", ({
                dstGasForCall: "0",
                dstNativeAmount: dstNativeAmount,
                dstNativeAddr: address
            })
        ).call();

        result1 = (result1 && result1[0]) ? result1[0] : 0;
        result1 = result1 / 10 ** parseFloat(18);
        // result1 = formatNumber(result1, 5);

        return {
            networkFee: result1
        }

    } catch (err) {
        console.log(err, 'getBuyData errerrerr')
        return {
            networkFee: 0
        }
    }

}

export async function getGasFee() {

    var currentProvider = store.getState();
    var bridgeToken = (currentProvider && currentProvider.bridgeToken) ? currentProvider.bridgeToken : {};

    var get = await connection();
    try {
        if (get && get.web3) {

            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(BEP20ABI, bridgeToken.fromaddress);
            var approveAmt = 1 * (10 ** 18);
            //approveAmt = await convert(approveAmt);

            var result1 = await Contract.methods.approve(
                "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8",
                approveAmt.toString()
            ).estimateGas({ from: address });


            var tobridgeId = bridgeToken.tobridgeId;
            var frompoolId = bridgeToken.frompoolId;
            var topoolId = bridgeToken.topoolId;
            var amount = 1 * 10 ** parseFloat(bridgeToken.fromdecimals)

            var Contract1 = new web3.eth.Contract(BridgeABI, "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8");

            var gasfee = await Contract1.methods.swap(
                tobridgeId.toString(),
                frompoolId.toString(),
                topoolId.toString(),
                address,
                amount.toString(),
                "0",
                { dstGasForCall: 0, dstNativeAmount: 0, dstNativeAddr: "0x" },
                address,
                "0x"
            ).estimateGas({ from: address });


            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        return {
            value: {},
            status: false
        };
    }
}


export const getProtocolAndLpFeeInfo = async (data) => {

    try {
        var get = await connection();
        let chainId = parseFloat(data.chainId);
        let rpcUrl = config.rpc[chainId];
        var web3 = new Web3(rpcUrl);

        const multicall = new Multicall({
            web3Instance: web3,
        });

        const contractCallContext = [
            {
                reference: "info",
                contractAddress: data.feeLibrary,
                abi: feeLibraryABI,
                calls: [
                    {
                        reference: "PROTOCOL_FEE_FOR_SAME_TOKEN",
                        methodName: "PROTOCOL_FEE_FOR_SAME_TOKEN",
                        methodParameters: [],
                    },
                    {
                        reference: "PROTOCOL_FEE",
                        methodName: "PROTOCOL_FEE",
                        methodParameters: [],
                    },
                    {
                        reference: "DENOMINATOR",
                        methodName: "DENOMINATOR",
                        methodParameters: [],
                    },
                    {
                        reference: "LP_FEE",
                        methodName: "LP_FEE",
                        methodParameters: [],
                    },
                    {
                        reference: "stargatePoolIdToLPStaking",
                        methodName: "stargatePoolIdToLPStaking",
                        methodParameters: [data._srcPoolId],
                    },
                    {
                        reference: "poolIdToLpId",
                        methodName: "poolIdToLpId",
                        methodParameters: [data._srcPoolId],
                    },
                ],
            }
        ];

        const results = await multicall.call(contractCallContext);

        var PROTOCOL_FEE_FOR_SAME_TOKEN = await getFormatMulticall(results, "info", 0);

        PROTOCOL_FEE_FOR_SAME_TOKEN = (PROTOCOL_FEE_FOR_SAME_TOKEN
            && PROTOCOL_FEE_FOR_SAME_TOKEN.hex) ?
            parseInt(PROTOCOL_FEE_FOR_SAME_TOKEN.hex) : 0;

        var PROTOCOL_FEE = await getFormatMulticall(results, "info", 1);
        PROTOCOL_FEE = (PROTOCOL_FEE && PROTOCOL_FEE.hex) ? parseInt(PROTOCOL_FEE.hex) : 0;

        var DENOMINATOR = await getFormatMulticall(results, "info", 2);
        DENOMINATOR = (DENOMINATOR && DENOMINATOR.hex) ? parseInt(DENOMINATOR.hex) : 0;

        var LP_FEE = await getFormatMulticall(results, "info", 3);
        LP_FEE = (LP_FEE && LP_FEE && LP_FEE.hex) ? parseInt(LP_FEE.hex) : 0;

        var stakingAddr = await getFormatMulticall(results, "info", 4);
        stakingAddr = (stakingAddr) ? stakingAddr : "0";

        var poolIdToLpId = await getFormatMulticall(results, "info", 5);
        poolIdToLpId = (poolIdToLpId && poolIdToLpId && poolIdToLpId.hex) ? parseInt(poolIdToLpId.hex) : 0;
        var allocPoint = 0;
        if (stakingAddr !== "0x0000000000000000000000000000000000000000") {
            var Contract = new web3.eth.Contract(LPStakingABI, stakingAddr);
            let lpdata = await Contract.methods.poolInfo(poolIdToLpId.toString()).call();
            allocPoint = (lpdata && lpdata.allocPoint) ? lpdata.allocPoint : 0;
        }



        return {
            PROTOCOL_FEE_FOR_SAME_TOKEN,
            PROTOCOL_FEE,
            DENOMINATOR,
            LP_FEE,
            stakingAddr,
            allocPoint
        };

    } catch (err) {
        console.log(err, 'getProtocolAndLpFeeInfo errerr')
        return {
            PROTOCOL_FEE_FOR_SAME_TOKEN: 0,
            PROTOCOL_FEE: 0,
            DENOMINATOR: 0,
            LP_FEE: 0,
            stakingAddr: "",
            allocPoint: 0
        };
    }
}

export const getCalculation = (data) => {

    try {
        let protocolFeeBps = data._srcPoolId == data._dstPoolId ? parseFloat(data.PROTOCOL_FEE_FOR_SAME_TOKEN) : parseFloat(data.PROTOCOL_FEE);

        let DENOMINATOR = parseFloat(data.DENOMINATOR);
        let LP_FEE = parseFloat(data.LP_FEE);
        let _protocolSubsidy = 0;
        let amountSD = parseFloat(data._amountSD); // Stack too deep
        var protocolFee = amountSD * (protocolFeeBps) / (DENOMINATOR) - (_protocolSubsidy);
        var lpFee = amountSD * (LP_FEE) / (DENOMINATOR);
        let allocPoint = parseFloat(data.allocPoint);
        if (allocPoint > 0) {
            protocolFee = protocolFee + (lpFee);
            lpFee = 0;
        }

        var amount = amountSD - protocolFee;
        amount = amount / 10 ** parseFloat(data.decimals);
        amount = formatNumber(amount, 2);
        amount = (parseFloat(amount) > 0) ? amount : 0;
        return {
            protocolFee,
            lpFee,
            amount

        };
    } catch (err) {
        console.log(err, 'getCalculation errerrerr')
        return {
            protocolFee: 0,
            lpFee: 0,
            amount: 0
        };
    }

}

export const getFromDetails = (item, tokenList, bridgeToken) => {

    var selectedChainId = item.chainId;

    var ftoDetails = tokenList.filter(data => data.address.toLowerCase() == bridgeToken.toaddress.toLowerCase());
    var toRemotepath = ftoDetails[0].remotepath;
    var details = bridgeToken;

    //check same symbol available on oppsite network
    if (bridgeToken.tosymbol && bridgeToken.tosymbol != "") {
        var sameSymbol = tokenList.filter(data =>
            data.symbol == bridgeToken.tosymbol &&
            parseInt(data.chainId) == parseInt(selectedChainId) && toRemotepath.includes(data.localpath)
        );
    } else {
        var sameSymbol = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(selectedChainId) && toRemotepath.includes(data.localpath)
        );
    }

    //check same symbol available on oppsite network
    if (sameSymbol && sameSymbol[0] && sameSymbol[0].symbol) {
        details = {
            fromaddress: sameSymbol[0].address,
            fromsymbol: sameSymbol[0].symbol,
            fromname: sameSymbol[0].name,
            fromlogo: config.imageUrl + "/bridge/" + sameSymbol[0].logoURI,
            frombridgeId: sameSymbol[0].bridgeId,
            fromchainId: sameSymbol[0].chainId,
            frompoolId: sameSymbol[0].poolId,
            fromdecimals: sameSymbol[0].decimals,
            frompool: sameSymbol[0].pool,
            toaddress: bridgeToken.toaddress,
            tosymbol: bridgeToken.tosymbol,
            tologo: bridgeToken.tologo,
            toname: bridgeToken.toname,
            tobridgeId: bridgeToken.tobridgeId,
            tochainId: bridgeToken.tochainId,
            topoolId: bridgeToken.topoolId,
            todecimals: bridgeToken.todecimals,
            topool: bridgeToken.topool
        }
    } else {

        //check equivalent pair symbol available on oppsite network ex:USDT--USDC,USD,DAI

        var samePair = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(selectedChainId) && toRemotepath.includes(data.localpath)
        );
        if (samePair && samePair[0] && samePair[0].symbol) {
            details = {
                fromaddress: samePair[0].address,
                fromsymbol: samePair[0].symbol,
                fromname: samePair[0].name,
                fromlogo: config.imageUrl + "/bridge/" + samePair[0].logoURI,
                frombridgeId: samePair[0].bridgeId,
                fromchainId: samePair[0].chainId,
                frompoolId: samePair[0].poolId,
                fromdecimals: samePair[0].decimals,
                frompool: samePair[0].pool,
                toaddress: bridgeToken.toaddress,
                tosymbol: bridgeToken.tosymbol,
                tologo: bridgeToken.tologo,
                toname: bridgeToken.toname,
                tobridgeId: bridgeToken.tobridgeId,
                tochainId: bridgeToken.tochainId,
                topoolId: bridgeToken.topoolId,
                todecimals: bridgeToken.todecimals,
                topool: bridgeToken.topool
            }
        } else {
            details = {
                fromaddress: "",
                fromdecimal: 0,
                fromsymbol: "",
                fromname: "",
                fromlogo: "",
                frombridgeId: 0,
                fromchainId: 0,
                frompoolId: 0,
                fromdecimals: 0,
                frompool: "",
                toaddress: bridgeToken.toaddress,
                tosymbol: bridgeToken.tosymbol,
                tologo: bridgeToken.tologo,
                toname: bridgeToken.toname,
                tobridgeId: bridgeToken.tobridgeId,
                tochainId: bridgeToken.tochainId,
                topoolId: bridgeToken.topoolId,
                todecimals: bridgeToken.todecimals,
                topool: bridgeToken.topool
            }
        }

    }
    return details;

}

export const getToDetails = (item, tokenList, bridgeToken) => {

    var selectedChainId = item.chainId;

    var fromDetails = tokenList.filter(data => data.address.toLowerCase() == bridgeToken.fromaddress.toLowerCase());
    var fromRemotepath = fromDetails[0].remotepath;

    var details = bridgeToken;

    //check same symbol available on oppsite network
    if (bridgeToken.fromsymbol && bridgeToken.fromsymbol != "") {
        var sameSymbol = tokenList.filter(data =>
            data.symbol == bridgeToken.fromsymbol &&
            parseInt(data.chainId) == parseInt(selectedChainId) && fromRemotepath.includes(data.localpath)
        );
    } else {
        var sameSymbol = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(selectedChainId) && fromRemotepath.includes(data.localpath)
        );
    }


    //check same symbol available on oppsite network
    if (sameSymbol && sameSymbol[0] && sameSymbol[0].symbol) {
        details = {
            fromaddress: bridgeToken.fromaddress,
            fromdecimal: bridgeToken.fromdecimal,
            fromsymbol: bridgeToken.fromsymbol,
            fromname: bridgeToken.fromname,
            fromlogo: bridgeToken.fromlogo,
            frombridgeId: bridgeToken.frombridgeId,
            fromchainId: bridgeToken.fromchainId,
            frompoolId: bridgeToken.frompoolId,
            fromdecimals: bridgeToken.fromdecimals,
            frompool: bridgeToken.frompool,
            toaddress: sameSymbol[0].address,
            todecimal: sameSymbol[0].decimals,
            tosymbol: sameSymbol[0].symbol,
            tologo: config.imageUrl + "/bridge/" + sameSymbol[0].logoURI,
            toname: sameSymbol[0].name,
            tobridgeId: sameSymbol[0].bridgeId,
            tochainId: sameSymbol[0].chainId,
            topoolId: sameSymbol[0].poolId,
            todecimals: sameSymbol[0].decimals,
            topool: sameSymbol[0].pool,
        }
    } else {

        //check equivalent pair symbol available on oppsite network ex:USDT--USDC,USD,DAI

        var samePair = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(selectedChainId) && fromRemotepath.includes(data.localpath)
        );

        if (samePair && samePair[0] && samePair[0].symbol) {
            details = {
                fromaddress: bridgeToken.fromaddress,
                fromdecimal: bridgeToken.fromdecimal,
                fromsymbol: bridgeToken.fromsymbol,
                fromname: bridgeToken.fromname,
                fromlogo: bridgeToken.fromlogo,
                frombridgeId: bridgeToken.frombridgeId,
                fromchainId: bridgeToken.fromchainId,
                frompoolId: bridgeToken.frompoolId,
                fromdecimals: bridgeToken.fromdecimals,
                frompool: bridgeToken.frompool,
                toaddress: samePair[0].address,
                todecimal: samePair[0].decimals,
                tosymbol: samePair[0].symbol,
                tologo: config.imageUrl + "/bridge/" + samePair[0].logoURI,
                toname: samePair[0].name,
                tobridgeId: samePair[0].bridgeId,
                tochainId: samePair[0].chainId,
                topoolId: samePair[0].poolId,
                todecimals: samePair[0].decimals,
                topool: samePair[0].pool,
            }
        } else {
            details = {
                fromaddress: bridgeToken.fromaddress,
                fromdecimal: bridgeToken.fromdecimal,
                fromsymbol: bridgeToken.fromsymbol,
                fromname: bridgeToken.fromname,
                fromlogo: bridgeToken.fromlogo,
                frombridgeId: bridgeToken.frombridgeId,
                fromchainId: bridgeToken.fromchainId,
                frompoolId: bridgeToken.frompoolId,
                fromdecimals: bridgeToken.fromdecimals,
                frompool: bridgeToken.frompool,
                toaddress: "",
                todecimal: 0,
                tosymbol: "",
                tologo: "",
                toname: "",
                tobridgeId: 0,
                tochainId: 0,
                topoolId: 0,
                todecimals: 0,
                topool: ""
            }
        }

    }
    return details;

}

export const getFromToken = (item, tokenList, bridgeToken) => {

    var tochainId = bridgeToken.tochainId;

    var fDetails = tokenList.filter(data => data.address.toLowerCase() == bridgeToken.fromaddress.toLowerCase());
    var fromlocalpath = fDetails[0].localpath;
    var details = bridgeToken;

    //check same symbol available on oppsite network
    var sameSymbol = tokenList.filter(data =>
        data.symbol == bridgeToken.fromsymbol &&
        parseInt(data.chainId) == parseInt(tochainId) && data.remotepath.includes(fromlocalpath)
    );

    //check same symbol available on oppsite network
    if (sameSymbol && sameSymbol[0] && sameSymbol[0].symbol) {
        details = {
            fromaddress: bridgeToken.fromaddress,
            fromsymbol: bridgeToken.fromsymbol,
            fromname: bridgeToken.fromname,
            fromlogo: bridgeToken.fromlogo,
            frombridgeId: bridgeToken.frombridgeId,
            fromchainId: bridgeToken.fromchainId,
            frompoolId: bridgeToken.frompoolId,
            fromdecimals: bridgeToken.fromdecimals,
            frompool: sameSymbol[0].pool,
            toaddress: sameSymbol[0].address,
            tosymbol: sameSymbol[0].symbol,
            tologo: config.imageUrl + "/bridge/" + sameSymbol[0].logoURI,
            toname: sameSymbol[0].name,
            tobridgeId: sameSymbol[0].bridgeId,
            tochainId: sameSymbol[0].chainId,
            topoolId: sameSymbol[0].poolId,
            todecimals: sameSymbol[0].decimals,
            topool: sameSymbol[0].pool
        }
    } else {
        //check equivalent pair symbol available on oppsite network ex:USDT--USDC,USD,DAI
        var samePair = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(tochainId) && data.remotepath.includes(fromlocalpath)
        );
        if (samePair && samePair[0] && samePair[0].symbol) {
            details = {
                fromaddress: bridgeToken.fromaddress,
                fromsymbol: bridgeToken.fromsymbol,
                fromname: bridgeToken.fromname,
                fromlogo: bridgeToken.fromlogo,
                frombridgeId: bridgeToken.frombridgeId,
                fromchainId: bridgeToken.fromchainId,
                frompoolId: bridgeToken.frompoolId,
                fromdecimals: bridgeToken.fromdecimals,
                frompool: bridgeToken.frompool,
                toaddress: samePair[0].address,
                tosymbol: samePair[0].symbol,
                tologo: config.imageUrl + "/bridge/" + samePair[0].logoURI,
                toname: samePair[0].name,
                tobridgeId: samePair[0].bridgeId,
                tochainId: samePair[0].chainId,
                topoolId: samePair[0].poolId,
                todecimals: samePair[0].decimals,
                topool: samePair[0].pool
            }
        } else {

            //check same symbol available on any network
            var samePairOther = tokenList.filter(data =>
                data.symbol == bridgeToken.fromsymbol && data.remotepath.includes(fromlocalpath)
            );
            if (samePairOther && samePairOther[0] && samePairOther[0].symbol) {
                details = {
                    fromaddress: bridgeToken.fromaddress,
                    fromsymbol: bridgeToken.fromsymbol,
                    fromname: bridgeToken.fromname,
                    fromlogo: bridgeToken.fromlogo,
                    frombridgeId: bridgeToken.frombridgeId,
                    fromchainId: bridgeToken.fromchainId,
                    frompoolId: bridgeToken.frompoolId,
                    fromdecimals: bridgeToken.fromdecimals,
                    frompool: bridgeToken.frompool,
                    toaddress: samePairOther[0].address,
                    tosymbol: samePairOther[0].symbol,
                    tologo: config.imageUrl + "/bridge/" + samePairOther[0].logoURI,
                    toname: samePairOther[0].name,
                    tobridgeId: samePairOther[0].bridgeId,
                    tochainId: samePairOther[0].chainId,
                    topoolId: samePairOther[0].poolId,
                    todecimals: samePairOther[0].decimals,
                    topool: samePairOther[0].pool
                }
            } else {
                details = {
                    fromaddress: bridgeToken.fromaddress,
                    fromsymbol: bridgeToken.fromsymbol,
                    fromname: bridgeToken.fromname,
                    fromlogo: bridgeToken.fromlogo,
                    frombridgeId: bridgeToken.frombridgeId,
                    fromchainId: bridgeToken.fromchainId,
                    frompoolId: bridgeToken.frompoolId,
                    fromdecimals: bridgeToken.fromdecimals,
                    frompool: bridgeToken.frompool,
                    toaddress: "",
                    tosymbol: "",
                    tologo: "",
                    toname: "",
                    tobridgeId: 0,
                    tochainId: 0,
                    topoolId: 0,
                    todecimals: 0,
                    topool: ""
                }
            }


        }

    }

    return details;

}

export const getToToken = (item, tokenList, bridgeToken) => {

    var fromchainId = bridgeToken.fromchainId;

    var fDetails = tokenList.filter(data => data.address.toLowerCase() == bridgeToken.toaddress.toLowerCase());
    var tolocalpath = fDetails[0].localpath;
    var details = bridgeToken;

    //check same symbol available on oppsite network
    var sameSymbol = tokenList.filter(data =>
        data.symbol == bridgeToken.tosymbol &&
        parseInt(data.chainId) == parseInt(fromchainId) && data.remotepath.includes(tolocalpath)
    );

    //check same symbol available on oppsite network
    if (sameSymbol && sameSymbol[0] && sameSymbol[0].symbol) {
        details = {
            fromaddress: sameSymbol[0].address,
            fromsymbol: sameSymbol[0].symbol,
            fromlogo: config.imageUrl + "/bridge/" + sameSymbol[0].logoURI,
            fromname: sameSymbol[0].name,
            frombridgeId: sameSymbol[0].bridgeId,
            fromchainId: sameSymbol[0].chainId,
            frompoolId: sameSymbol[0].poolId,
            fromdecimals: sameSymbol[0].decimals,
            frompool: sameSymbol[0].pool,
            toaddress: bridgeToken.toaddress,
            tosymbol: bridgeToken.tosymbol,
            toname: bridgeToken.toname,
            tologo: bridgeToken.tologo,
            tobridgeId: bridgeToken.tobridgeId,
            tochainId: bridgeToken.tochainId,
            topoolId: bridgeToken.topoolId,
            todecimals: bridgeToken.todecimals,
            topool: bridgeToken.topool,
        }
    } else {
        //check equivalent pair symbol available on oppsite network ex:USDT--USDC,USD,DAI
        var samePair = tokenList.filter(data =>
            parseInt(data.chainId) == parseInt(fromchainId) && data.remotepath.includes(tolocalpath)
        );
        if (samePair && samePair[0] && samePair[0].symbol) {
            details = {
                fromaddress: samePair[0].address,
                fromsymbol: samePair[0].symbol,
                fromname: samePair[0].name,
                fromlogo: config.imageUrl + "/bridge/" + samePair[0].logoURI,
                frombridgeId: samePair[0].bridgeId,
                fromchainId: samePair[0].chainId,
                frompoolId: samePair[0].poolId,
                fromdecimals: samePair[0].decimals,
                frompool: samePair[0].pool,
                toaddress: bridgeToken.toaddress,
                tosymbol: bridgeToken.tosymbol,
                tologo: bridgeToken.tologo,
                toname: bridgeToken.toname,
                tobridgeId: bridgeToken.tobridgeId,
                tochainId: bridgeToken.tochainId,
                topoolId: bridgeToken.topoolId,
                todecimals: bridgeToken.todecimals,
                topool: bridgeToken.topool,
            }
        } else {
            details = {
                fromaddress: "",
                fromsymbol: "",
                fromname: "",
                fromlogo: "",
                frombridgeId: 0,
                fromchainId: 0,
                frompoolId: 0,
                fromdecimals: 0,
                frompool: "",
                toaddress: bridgeToken.toaddress,
                tosymbol: bridgeToken.tosymbol,
                toname: bridgeToken.toname,
                tologo: bridgeToken.tologo,
                tobridgeId: bridgeToken.tobridgeId,
                tochainId: bridgeToken.tochainId,
                topoolId: bridgeToken.topoolId,
                todecimals: bridgeToken.todecimals,
                topool: bridgeToken.topool,
            }
        }

    }

    return details;

}

export const getTokenBalance = async (token, spender, symbol, chainId) => {

    var initial = {
        allowance: 0,
        tokenbalanceOf: 0,
        tokenbalance: 0,
        ethbalanceOf: 0,
        balance: 0,
        isLoad: false
    }

    try {
        var get = await connection();
        let rpcUrl = config.rpc[chainId];
        var web3 = new Web3(rpcUrl);
        var address = get.address;
        const multicall = new Multicall({
            web3Instance: web3,
        });
        if (symbol !== "ETH") {
            const contractCallContext = [
                {
                    reference: "info",
                    contractAddress: token,
                    abi: BEP20ABI,
                    calls: [
                        {
                            reference: "allowance",
                            methodName: "allowance",
                            methodParameters: [address, spender],
                        },
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [address],
                        },
                        {
                            reference: "decimals",
                            methodName: "decimals",
                            methodParameters: [],
                        },
                    ],
                }
            ];

            const results = await multicall.call(contractCallContext);

            var allowance = await getFormatMulticall(results, "info", 0);
            allowance = (allowance && allowance.hex) ? parseInt(allowance.hex) : 0;

            var tokenbalanceOf = await getFormatMulticall(results, "info", 1);
            tokenbalanceOf = (tokenbalanceOf && tokenbalanceOf.hex) ? parseInt(tokenbalanceOf.hex) : 0;

            var decimals = await getFormatMulticall(results, "info", 2);
            // decimals = (decimals && decimals.hex) ? parseInt(decimals.hex) : 0;

            var tokenbalance = tokenbalanceOf / 10 ** decimals;
            tokenbalance = formatNumber(tokenbalance, 5);
            var getBalance = await web3.eth.getBalance(address);
            getBalance = parseFloat(getBalance);
            initial.ethbalanceOf = getBalance;
            // getBalance = getBalance / 10 ** 18;;
            initial.tokenbalanceOf = tokenbalanceOf;
            initial.balance = tokenbalance;
            initial.allowance = allowance;
            initial.isLoad = true;

            return initial;
        } else {
            var getBalance = await web3.eth.getBalance(address);
            getBalance = parseFloat(getBalance);
            initial.ethbalanceOf = getBalance;
            initial.balance = getBalance / 10 ** 18;
            initial.isLoad = true;
            return initial;
        }
    } catch (err) {

        return initial;

    }
}

export async function getBridgeFee(chainId) {

    try {
        var web3 = readWeb3(chainId);
        var middlreware = config.middlreware[chainId];
        var Contract = new web3.eth.Contract(MiddlewareABI, middlreware);
        var adminfee = await Contract.methods.adminfee().call();
        return {
            fee: adminfee / 100,
            status: true
        };

    } catch (err) {
        console.log(err, 'getBridgeFee errerrerr')
        return {
            fee: 0,
            status: false
        };
    }
}

export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function isAddress(address) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var status = web3.utils.isAddress(address)
            return {
                value: status,
                status: true
            };
        } else {
            return {
                value: false,
                status: false
            };
        }
    } catch (err) {

        return {
            value: false,
            status: false
        };
    }
}
