export default {
  ABOUT_NEW_FEATURES: "sobre las nuevas características.",
  ACCEPT: "Aceptar",
  ACTIVE_ORDERS: "Órdenes activas",
  ADD_TOKEN: "Agregar una token",
  AGGRESSIVE: "Agresivo",
  AND: "y",
  APPROVE: "Aprobar",
  ASSETS_ON_WAY: "Sus activos están en camino y deben llegar a",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Evite transferir a una dirección de intercambio centralizada (Binance, Huobi ...). No es una característica compatible",
  BALANCE: "Balance",
  BALANCE_ONE: "Balance:",
  BLOG: "Blog",
  BRIDGE: "Puente",
  CANCEL: "Cancelar",
  CANCELLATION_TRANSACTION_FEE:
    "La cancelación de una orden límite implica pagar las tarifas de transacción.",
  CANCEL_ALL: "Cancelalo todo",
  CANCEL_LIMIT_NOT_FILLED:
    "Puede cancelar un pedido límite siempre que el pedido aún no se haya completado.",
  CANCEL_LIMIT_ORDER: "Orden de límite de cancelación",
  CANCEL_ORDER: "Cancelar orden",
  CANCEL_SELECTED: "Cancelar seleccionado",
  CHAINS: "cadenas",
  CHOOSE_NETWORK: "Elegir red",
  CLASSIC_MODE: "Modo clásico",
  CLEAR: "Claro",
  CONFIRM: "Confirmar",
  CONFIRMATION_REJECTED: "Confirmación rechazada!",
  CONFIRM_SWAP: "Confirmar intercambio",
  CONNECT_WALLET: "Conectar billetera",
  COPIED: "Copiado",
  CURRENT: "Actual",
  CUSTOM: "Costumbre",
  DAYS: "Días",
  DEFAULT: "Por defecto",
  DETAILS: "Detalles",
  DISCLAIMER: "Descargo de responsabilidad",
  DISCONNECT_WALLET: "Desconectar la billetera",
  DISMISS: "Despedir",
  DUPLICATE_ORDER_SUBMIT: "Duplicar la orden. Este ya está enviado",
  ENTER_AMT: "Ingresar la cantidad",
  EXCHANGE: "Intercambio",
  EXPIRY: "EXPIRACIÓN",
  EXPIRY_SM: "Expiración",
  FAILED_LOAD_CHART: "No se pudo cargar la tabla",
  FIND_BY_TOKEN_OR_STATUS: "Encontrar por token o estado",
  INSUFFICIENT_BALANCE: "Saldo insuficiente",
  INSUFFICIENT: "Insuficiente",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "Saldo para cubrir el saldo de pedidos existentes de",
  RECEIVERS: "Receptor",
  WALETADDRESS: "Dirección de la billetera",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Acepte términos de servicio y política de privacidad",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Fabricante de tomadores no debe ser la misma dirección de tomador",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "La tolerancia del deslizamiento debe estar entre 0.1% y 15%",
  SEARCH_A_TOKEN: "Buscar un token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Su transacción se revertirá si el precio cambia desfavorablemente por más que este porcentaje",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Ajusta el precio del gas para su transacción.GWEI más alto = mayor velocidad.",
  REVIEW_OTC_ORDER: "Revisar el pedido OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Período de vencimiento establecido por el fabricante de pedidos.Después de este período, la orden expirará y se archivará.",
  SEARCH_A_LIQUIDITY_SOURCES: "Buscar fuentes de liquidez",

  FAST: "Rápido",
  FAST_AND_SECURE:
    "La forma segura, rápida y más segura de traer activos de cadena cruzada a",
  FEATURE_TO_TRANSFER:
    "No use esta función para transferir sus tokens a una dirección de un intercambio centralizado.",
  FEES: "Honorarios",
  FILL_ORDER: "Orden de llenado",
  FILL_OTC_ORDER: "Llenar el pedido de OTC",
  FREE: "Gratis",
  FROM: "De",
  GAS_COST: "Costo de gas",
  GAS_DESTINATION: "Gas en la cadena de destino",
  GAS_ON_DESTINATION: "Gas en el destino",
  GAS_PRICE: "Precio de gas",
  GOT_IT: "Entiendo",
  GOT_PROBLEM_JUST: "¿Tiene un problema? Solo ponte en contacto.",
  HIGH: "Alto",
  HOURS: "Horas",
  IMPORT: "Importar",
  IMPOSSIBLE_TO_RETRIEVE:
    "No es una característica compatible. Además, verifique dos veces la dirección que llene, cualquier tokens enviado a la dirección incorrecta será imposible de recuperar.",
  INSTANT: "Instante",
  INSUFFICIENT_APPROVED_AMT: "Monto aprobado insuficiente",
  INVALID_TOKEN_ADDRESS: "Dirección de token no válida",
  KEEP_TRACK_NOTIFY:
    "Puede realizar un seguimiento de él en la notificación de su panel. Te notificaremos una vez que esté listo.",
  KEEP_YOU_POSTED: ". Nos mantendremos informados.",
  LEARN_MORE: "Aprende más",
  LEGACY: "Legado",
  LIMIT: "Límite",
  LIMIT_ORDER: "orden de límite",
  LIMIT_PRICE: "Precio límite",
  LIMIT_PRICE_SM: "Precio límite",
  LIQUIDITY_SOURCES: "Fuentes de liquidez",
  LOW: "Bajo",
  MAKER: "Fabricante",
  MARKET: "Mercado",
  MAX: "Máximo",
  MAX_BASE_FEE: "Tarifa base máxima",
  MAX_S: "máximo",
  MAX_TO_PAY: "Max para pagar",
  MINIMUM_RECEIVED: "Mínimo recibido",
  MINUTES: "Minutos",
  MONTHS: "Meses",
  NEED_APPROVAL_MOVE: "Necesitamos su aprobación para mudarse",
  NEED_TO_WRAP: "Necesitas envolver",
  NETWORK_FEES: "Tarifas de red",
  NETWORK_SM: "red.",
  NEVER: "Nunca",
  NONE: "Ninguno",
  NON_CUSTODIAL_LIMIT:
    "En el orden de límite no custodial. Es un requisito técnico en el",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Todavía no tienes pedidos",
  NO_RECORDS: "No hay registros",
  ON_UR_BEHALF: "en su nombre.",
  OOPS_WRONG: "Huy! Algo salió mal.!",
  OPEN: "Abierto",
  ORDER_DETAILS: "Detalles del pedido",
  ORDER_HISTORY: "Historial de pedidos",
  ORDER_PRICE: "Precio del pedido",
  ORDER_ROUTING: "Enrutamiento de pedidos",
  OTC: "cuerpos de cadetes militares",
  PASTE: "Pegar",
  PAY: "Pagar",
  PENDING_CONFIRM: "Confirmación pendiente en la billetera.",
  PER: "por",
  PLEASE_CHOOSE_NETWORK: "Elija la red",
  PLEASE_LOGIN: "Inicie sesión en",
  PLEASE_TRY_LATER: "Por favor, inténtelo de nuevo más tarde",
  POPULAR_TOKENS: "Tokens populares",
  PRICE: "Precio",
  PRICE_IMPACT: "Impacto del precio",
  PRIVACY_POLICY: "política de privacidad",
  RATE: "Tasa",
  READ_ACCEPT: "Leo y acepto",
  RECEIVE: "Recibir",
  RECEIVED_ORDERS: "Recibidos órdenes",
  REFUND: "REEMBOLSO",
  REMOVE: "Eliminar",
  REVERSE_RATE: "Tasa inversa",
  REVIEW_TRADE: "Comercio de revisión",
  SELECTED: "seleccionado",
  SELECT_ALL: "Seleccionar todo",
  SELECT_FROM_INFO: "Seleccione de la información",
  SELECT_NETWORK: "Seleccione una red",
  SELECT_TOKEN: "Seleccione un token",
  SELECT_TOKENN: "Seleccionar token",
  SELECT_TO_INFO: "Seleccione Info Info",
  SETTINGS: "Ajustes",
  SIMPLE_MODE: "Modo simple",
  SLIPPAGE: "Deslizamiento",
  SLIPPAGE_TOLERANCE: "Tolerancia de deslizamiento",
  SRY_INSUFFICIENT: "Lo siento, insuficiente",
  STATUS: "ESTADO",
  STATUS_SM: "Estado",
  STEP: "Paso",
  SUBMIT: "Entregar",
  SUBMITTED_ORDERS: "Pedidos enviados",
  SUBMIT_DATE: "Fecha de envío",
  SUCCESS_FILLED: "Orden exitoso lleno",
  SUCCESS_RECEIVED: "Recibido con éxito",
  SUCCESS_SUBMITTED_ORDER: "Envió con éxito su pedido",
  SWAP: "Intercambio",
  SWAP_TRANSFER: "Intercambio y transferencia",
  SWITCH_NETWORK: "Cambiar de red",
  TAKER: "Tomador",
  TAKER_ADDRESS: "Dirección de tomador",
  TERMS_OF_SERVICE: "Términos de servicio",
  TO: "A",
  TOKEN_NOT_FOUND: "Token no encontrado",
  TOKEN_RECEIVE_SENT: "El token para recibir será enviado a",
  TRADE: "Comercio",
  TRANSACTION_REJECTED_LATER:
    "Transacción rechazada Por favor intente nuevamente más tarde",
  TRANSFER: "Transferir",
  TRANSFER_ALERT: "Alerta de transferencia",
  TRANSFER_TO: "Transferir a",
  TX_COST: "Costo de TX",
  UNLOCK: "desbloquear",
  USER_GUIDE: "Guía del usuario",
  USE_ITAS: "y úsalo como w",
  VERIFY: "Verificar",
  VIEW_EXPLORER: "Ver transacción en Explorer",
  VIEW_ON: "Ver en",
  VIEW_TRANSACTION: "Ver transacción",
  WRAP: "Envoltura",
  YOUR_ADDRESS: "Su dirección",
  YOUR_ORDER_CANCEL: "Su pedido está cancelado",
  YOUR_WALLET: "Tu billetera",
  YOU_GET: "Usted obtiene",
  YOU_PAY: "Tu pagas",
  YOU_RECEIVE: "Recibes",
  YOU_WILL_RECEIVE: "Usted recibirá",
  ANYONE_CAN_CREATE:
    "Cualquiera puede crear cualquier token, incluidas versiones falsas de los tokens existentes. Tenga el debido cuidado. Algunos tokens y sus parámetros técnicos pueden ser incompatibles con nuestros servicios. Al importar esta token personalizada, usted reconoce y acepta los riesgos.",
  BY_SIGNING_MESSAGE:
    "Al firmar este mensaje, confirma que no está accediendo a esta aplicación, o es residente de los Estados Unidos o cualquier otro país restringido. Para obtener más información, lea nuestro",
  CONSTITUTION: "Constitución",
  CURRENTLY_CONNECTED: "Actualmente estás conectado a",
  IMPORT_TOKEN: "Importar un token",
  IN_YOUR_WALLET: "En tu billetera",
  MAKE_SURE_CHECKED:
    "Asegúrese de haber marcado la dirección del token y es correcto",
  ON: "en",
  PEOPLE: "Gente",
  PLEASE_SWITCH: "Por favor cambie a",
  SELECTED_NOT_SUPPORTED:
    "Cambie la red en su billetera, la red seleccionada no es compatible",
  SIGN_FOR_ACCESS: "Firmar para acceder",
  SIGN_MESSAGE: "Mensaje de firma",
  LAUNCH_APP: "Ejecutar aplicación",
  GETTING_STARTED:
    "Comenzando con HODL: tutoriales y explicaciones de producto",
  DEEP_DIVE: "DeepDive Into Hodl: estrategias y características del producto",
  BUILD_HODL: "Construcción en HODL: documentación técnica",
  BLOG_HEADING:
    "Desmitificante Defi: todo lo que necesitas saber en un solo lugar. Para principiantes a usuarios avanzados, y todos los intermedios.",
  BLOG_PLACEHOLDER: "Busque el producto/tema sobre el que desea aprender",
  CHOOSE_TOPIC: "Elegir red",
  TRY: "INTENTAR",
  SEARCH_RESULTS: "Resultados de la búsqueda",
  SEARCH_TOPIC: "Tema de búsqueda",
  LOAD_MORE: "Carga más",
  SOCIAL_MEDIA: "Medios de comunicación social",
  NEW: "El más reciente primero",
  OLD: "Los más viejos primero",
  Z_A: "Ordenar el título Z-A",
  A_Z: "Ordenar el título A-Z",
  RESULTS: "Resultados",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "La red calcula automáticamente una tarifa de gas base y varía de bloque a bloque. Establezca el monto máximo de la tarifa base que desea pagar. Una diferencia entre la tarifa base máxima que establece y la tarifa base real se reembolsará una vez que se haya extraído el bloque.",
  CANCELLED_TRANSACTION: "Canceló su transacción",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Crear un pedido límite es una operación gratuita para el fabricante de pedidos a menos que se cancelen. Se requerirá que el tomador de pedidos pague las tarifas de transacción mientras llenan el pedido.",
  Cancelled: "Cancelado",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Diferencia de valor entre la mejor ruta y",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Habilite esta opción si tiene problemas para intercambiar un token que tiene tarifas incorporadas para transferir.",
  Expired: "Venció",
  Filled: "Completado",
  INSUFFICIENT_FOR_GASFEE:
    "Lo siento, fondos insuficientes para la tarifa de gasolina",
  Open: "Abierto",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "El relleno parcial solo se aplica si una ruta de intercambio consta de múltiples piezas de ruta principal. Permite una ejecución parcial, evitando que la transacción se invierta si el precio del mercado se desplace.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Precios establecidos por el fabricante de pedidos para ejecutar su pedido (token para recibir = x token para pagar)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Precios establecidos por el fabricante de pedidos para ejecutar su pedido (token to paga = y token para recibir",
  REVIEW_LIMIT_ORDER: "Revisión de la orden de límite",
  SEARCH_BY_TOKEN: "Buscar por token o dirección",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Establecer una alta tolerancia a los deslizamientos puede ayudar a las transacciones a tener éxito, pero es posible que no obtenga un precio tan bueno. Úselo con precaución.",
  STATUS_OF_THE_LIMIT_ORDER: "Estado de la orden límite",
  Suspended: "Suspendido",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Estas tarifas se pagan a los mineros de la red. Hodl no controle las tarifas.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "La diferencia entre el precio de mercado y el precio de Est. Debido al tamaño comercial",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "El monto mínimo recibido, de lo contrario, la transacción se volverá",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Esta tarifa va directamente a los mineros como un incentivo para priorizar su transacción.",
  TRANSACTION_REJECTED_TRY: "Transacción rechazada, intente nuevamente",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "La transferencia para el token seleccionado no está disponible en esta red",
  YOU: "Tú",
};
