export default {
  ABOUT_NEW_FEATURES: "про нові функції.",
  ACCEPT: "Приймати",
  ACTIVE_ORDERS: "Активні замовлення",
  ADD_TOKEN: "Додайте маркер",
  AGGRESSIVE: "Агресивний",
  AND: "і",
  APPROVE: "Затверджувати",
  ASSETS_ON_WAY: "Ваші активи в дорозі і повинні надходити",
  AUTO: "Автоматичний",
  AVOID_TRANSFER:
    "Будь ласка, уникайте переходу на централізовану адресу обміну (Binance, Huobi ...). Це не підтримувана функція",
  BALANCE: "Балансувати",
  BALANCE_ONE: "Баланс:",
  BLOG: "Позабіржовий",
  BRIDGE: "Мост",
  CANCEL: "Скасувати",
  CANCELLATION_TRANSACTION_FEE:
    "Скасування обмеженого замовлення передбачає сплату плати за транзакцію.",
  CANCEL_ALL: "Скасувати все",
  CANCEL_LIMIT_NOT_FILLED:
    "Ви можете скасувати лімітне замовлення до тих пір, поки замовлення ще не заповнене.",
  CANCEL_LIMIT_ORDER: "Скасувати обмеження замовлення",
  CANCEL_ORDER: "Відмінити замовлення",
  CANCEL_SELECTED: "Скасувати вибраний",
  CHAINS: "Блог",
  CHOOSE_NETWORK: "Виберіть мережу",
  CLASSIC_MODE: "Класичний режим",
  CLEAR: "Чіткий",
  CONFIRM: "Підтверджувати",
  CONFIRMATION_REJECTED: "Підтвердження відхилено.!",
  CONFIRM_SWAP: "Підтвердити своп",
  CONNECT_WALLET: "Підключіть гаманець",
  COPIED: "Скопійований",
  CURRENT: "Поточний",
  CUSTOM: "Звичайний",
  DAYS: "Дні",
  DEFAULT: "За замовчуванням",
  DETAILS: "Деталі",
  DISCLAIMER: "Відмова",
  DISCONNECT_WALLET: "Від'єднайте гаманець",
  DISMISS: "Звільнити",
  DUPLICATE_ORDER_SUBMIT: "Дублікат замовлення. Цей вже подано",
  ENTER_AMT: "Введіть суму",
  EXCHANGE: "Обмін",
  EXPIRY: "Термін дії",
  EXPIRY_SM: "Термін дії",
  FAILED_LOAD_CHART: "Не вдалося завантажити діаграму",
  FIND_BY_TOKEN_OR_STATUS: "Знайдіть затоком або статусом",
  INSUFFICIENT_BALANCE: "Недостатній баланс",
  INSUFFICIENT: "Недостатній",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "баланс для покриття існуючих замовлень балансу",
  RECEIVERS: "Приймач",
  WALETADDRESS: "Адреса гаманця",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Будь ласка, прийміть умови послуги та політику конфіденційності",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Виробник Taker не повинен бути такою ж адресою Taker",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Толерантність до ковзання повинна становити від 0,1% до 15%",
  SEARCH_A_TOKEN: "Шукайте маркер",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Ваша транзакція повернеться, якщо ціна не несприятливо зміниться на цей відсоток",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Коригує ціну газу для вашої транзакції.Більш висока швидкість Gwei = більша швидкість.",
  REVIEW_OTC_ORDER: "Огляд OLDC ORDER",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Термін придатності, встановлений виробником замовлення.Минулого цього періоду порядок закінчується і буде архівовано.",
  SEARCH_A_LIQUIDITY_SOURCES: "Шукайте джерела ліквідності",

  FAST: "Швидкий",
  FAST_AND_SECURE:
    "Безпечний, швидкий та найбезпечніший спосіб привести активів між ланцюжками",
  FEATURE_TO_TRANSFER:
    "Будь ласка, не використовуйте цю функцію для передачі ваших жетонів на адресу централізованого обміну.",
  FEES: "Збори",
  FILL_ORDER: "Замовити",
  FILL_OTC_ORDER: "Заповніть OTC ORDER",
  FREE: "Безкоштовний",
  FROM: "З",
  GAS_COST: "Вартість газу",
  GAS_DESTINATION: "Газ на ланцюзі призначення",
  GAS_ON_DESTINATION: "Газ за призначенням",
  GAS_PRICE: "Ціна газу",
  GOT_IT: "Зрозумів",
  GOT_PROBLEM_JUST: "У вас проблема? Просто зв’яжіться.",
  HIGH: "Високий",
  HOURS: "Годинник",
  IMPORT: "Імпорт",
  IMPOSSIBLE_TO_RETRIEVE:
    "Це не підтримувана функція. Також, будь ласка, перевірте, що ви заповніть адресу, будь-які жетони, надіслані на неправильну адресу, неможливо отримати.",
  INSTANT: "Мить",
  INSUFFICIENT_APPROVED_AMT: "Недостатня затверджена сума",
  INVALID_TOKEN_ADDRESS: "Недійсна адреса маркера",
  KEEP_TRACK_NOTIFY:
    "Ви можете відстежувати на ньому у своєму сповіщенні на панелі. Ми повідомимо вас, як тільки це буде зроблено.",
  KEEP_YOU_POSTED: ". Ми будемо тримати вас у курсі.",
  LEARN_MORE: "Вивчайте більше",
  LEGACY: "Спадщина",
  LIMIT: "Обмежувати",
  LIMIT_ORDER: "обмежувати порядок",
  LIMIT_PRICE: "Обмежувати ціну",
  LIMIT_PRICE_SM: "Обмежувати ціну",
  LIQUIDITY_SOURCES: "Джерела ліквідності",
  LOW: "Низький",
  MAKER: "Виробник",
  MARKET: "Ринок",
  MAX: "Максимум",
  MAX_BASE_FEE: "Максимальна базова плата",
  MAX_S: "максимум",
  MAX_TO_PAY: "Макс заплатити",
  MINIMUM_RECEIVED: "Мінімум отриманий",
  MINUTES: "Хвилини",
  MONTHS: "Місяці",
  NEED_APPROVAL_MOVE: "Нам потрібно ваше схвалення для переїзду",
  NEED_TO_WRAP: "Вам потрібно обернути",
  NETWORK_FEES: "Мережеві збори",
  NETWORK_SM: "мережа.",
  NEVER: "Ніколи",
  NONE: "Ні",
  NON_CUSTODIAL_LIMIT:
    "У порядку, що не має догляду, обмеження. Це технічна вимога щодо",
  NORMAL: "Нормальний",
  NO_ORDERS_YET: "У вас ще немає замовлень",
  NO_RECORDS: "Немає записів",
  ON_UR_BEHALF: "від вашого імені.",
  OOPS_WRONG: "Щось пішло не так.!",
  OPEN: "ВІДЧИНЕНО",
  ORDER_DETAILS: "деталі замовлення",
  ORDER_HISTORY: "Історія Замовлень",
  ORDER_PRICE: "Замовити ціну",
  ORDER_ROUTING: "Маршрутизація замовлень",
  OTC: "Позабіржовий",
  PASTE: "Пасти",
  PAY: "Оплачувати",
  PENDING_CONFIRM: "До підтвердження на гаманці.",
  PER: "за",
  PLEASE_CHOOSE_NETWORK: "Будь ласка, виберіть мережу",
  PLEASE_LOGIN: "Будь ласка, увійдіть у",
  PLEASE_TRY_LATER: "Будь-ласка спробуйте пізніше",
  POPULAR_TOKENS: "Популярні жетони",
  PRICE: "Ціна",
  PRICE_IMPACT: "Вплив ціни",
  PRIVACY_POLICY: "Політика конфіденційності",
  RATE: "Оцінювати",
  READ_ACCEPT: "Я читаю і приймаю",
  RECEIVE: "Отримувати",
  RECEIVED_ORDERS: "Отримані замовлення",
  REFUND: "Повернути",
  REMOVE: "Видалити",
  REVERSE_RATE: "Зворотна швидкість",
  REVIEW_TRADE: "Перегляньте торгівлю",
  SELECTED: "обраний",
  SELECT_ALL: "Вибрати все",
  SELECT_FROM_INFO: "Будь ласка, виберіть з інформації",
  SELECT_NETWORK: "Виберіть мережу",
  SELECT_TOKEN: "Виберіть маркер",
  SELECT_TOKENN: "Виберіть маркер",
  SELECT_TO_INFO: "Будь ласка, виберіть до інформації",
  SETTINGS: "Налаштування",
  SIMPLE_MODE: "Простий режим",
  SLIPPAGE: "Ковзання",
  SLIPPAGE_TOLERANCE: "Толерантність до ковзання",
  SRY_INSUFFICIENT: "Вибачте, недостатньо",
  STATUS: "Статус",
  STATUS_SM: "Статус",
  STEP: "Крок",
  SUBMIT: "Подавати",
  SUBMITTED_ORDERS: "Подані замовлення",
  SUBMIT_DATE: "Дата подання",
  SUCCESS_FILLED: "Успішно замовлення заповнене",
  SUCCESS_RECEIVED: "Успішно отриманий",
  SUCCESS_SUBMITTED_ORDER: "Успішно подав своє замовлення",
  SWAP: "Обміняти",
  SWAP_TRANSFER: "Swap & Transfer",
  SWITCH_NETWORK: "Мережа перемикання",
  TAKER: "Джейкер",
  TAKER_ADDRESS: "Адреса Taker",
  TERMS_OF_SERVICE: "Умови використання",
  TO: "До",
  TOKEN_NOT_FOUND: "Маркер не знайдено",
  TOKEN_RECEIVE_SENT: "Маркер для отримання буде надіслано до",
  TRADE: "Торгувати",
  TRANSACTION_REJECTED_LATER:
    "Відхилена транзакція, будь ласка, спробуйте пізніше",
  TRANSFER: "Перенесення",
  TRANSFER_ALERT: "Передача попередження",
  TRANSFER_TO: "Передати",
  TX_COST: "Вартість TX",
  UNLOCK: "Розблокувати",
  USER_GUIDE: "Керівництво користувача",
  USE_ITAS: "і використовуйте його як w",
  VERIFY: "Перевірити",
  VIEW_EXPLORER: "Переглянути транзакцію в Explorer",
  VIEW_ON: "Переглянути",
  VIEW_TRANSACTION: "Переглянути транзакцію",
  WRAP: "Обгортати",
  YOUR_ADDRESS: "Вашу адресу",
  YOUR_ORDER_CANCEL: "Ваше замовлення скасовано",
  YOUR_WALLET: "Ваш гаманець",
  YOU_GET: "Ви отримуєте",
  YOU_PAY: "Ти платиш",
  YOU_RECEIVE: "Ви отримуєте",
  YOU_WILL_RECEIVE: "Ви отримаєте",
  ANYONE_CAN_CREATE:
    "Будь -хто може створити будь -який маркер, включаючи підроблені версії існуючих жетонів. Покладіть належну обережність. Деякі жетони та їх технічні параметри можуть бути несумісними з нашими послугами. Імпортуючи цей звичайний маркер, який ви підтверджуєте та приймаєте ризики.",
  BY_SIGNING_MESSAGE:
    "Підписуючи це повідомлення, ви підтверджуєте, що не отримуєте доступ до цього додатка з або є мешканцем США чи будь -якої іншої країни з обмеженим доступом. Для отримання додаткової інформації, будь ласка, прочитайте наші",
  CONSTITUTION: "Конституція",
  CURRENTLY_CONNECTED: "Ви зараз підключені до",
  IMPORT_TOKEN: "Імпортувати маркер",
  IN_YOUR_WALLET: "у вашому гаманці",
  MAKE_SURE_CHECKED:
    "Переконайтеся, що ви перевірили адресу маркера, і це правильно",
  ON: "на",
  PEOPLE: "Люди",
  PLEASE_SWITCH: "Будь ласка, перейдіть на",
  SELECTED_NOT_SUPPORTED:
    "Будь ласка, змініть мережу у своєму гаманці, вибрана мережа не підтримується",
  SIGN_FOR_ACCESS: "Підпишіть для доступу",
  SIGN_MESSAGE: "Підпишіть повідомлення",
  LAUNCH_APP: "Запуск програми",
  GETTING_STARTED: "Початок роботи з Hodl: Підручники та пояснювачі продуктів",
  DEEP_DIVE: "DeepDive в Hodl: стратегії та функції продукту",
  BUILD_HODL: "Будівництво на Hodl: Технічна документація",
  BLOG_HEADING:
    "Demystified Defi - все, що потрібно знати в одному місці. Для початківців передових користувачів та всіх між ними.",
  BLOG_PLACEHOLDER: "Шукайте продукт/тему, про яку ви хочете дізнатися",
  CHOOSE_TOPIC: "Виберіть тему",
  TRY: "Намагатися",
  SEARCH_RESULTS: "Результати пошуку",
  SEARCH_TOPIC: "Пошук теми",
  LOAD_MORE: "Завантажити ще",
  SOCIAL_MEDIA: "Соц.медіа",
  NEW: "Найновіший перший",
  OLD: "Найстаріший перший",
  Z_A: "Сортування заголовка Z-A",
  A_Z: "Сортування заголовка A-Z",
  RESULTS: "Результат",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Базова плата за газ автоматично обчислюється мережею і залежить від блоку до блоку. Встановіть максимальну суму базової плати, яку ви хочете заплатити. Різниця між встановленою вами базовою плати за максимум та фактичною базовою плату буде повернена після видобутку блоку.",
  CANCELLED_TRANSACTION: "Скасував свою транзакцію",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Створення лімітного замовлення - це безкоштовна операція для виробника замовлення, якщо вони не скасують. Замовлення буде потрібно платити за плату за транзакцію, поки вони заповнюють замовлення.",
  Cancelled: "Скасований",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Різниця у цінності між найкращим маршрутом і",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Увімкніть цю опцію, якщо у вас виникають проблеми, що замінюють маркер, який має вбудовані збори за передачу.",
  Expired: "Закінчився",
  Filled: "Наповнений",
  INSUFFICIENT_FOR_GASFEE: "Вибачте, недостатньо коштів за плату за газ",
  Open: "ВІДЧИНЕНО",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Часткова заливка застосовується лише в тому випадку, якщо маршрут заміни складається з декількох основних деталей маршруту. Це дозволяє частково виконувати, що запобігає поверненню транзакції, якщо ринкова ціна вийде з корисності.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Ціна, встановлена ​​виробником замовлення для виконання свого замовлення (Token для отримання = X Token для оплати)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Ціна, встановлена ​​виробником замовлення для виконання свого замовлення (маркер для оплати = Y токен для отримання",
  REVIEW_LIMIT_ORDER: "Порядок обмеження перегляду",
  SEARCH_BY_TOKEN: "Пошук за жетоном або адресою",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Встановлення високої толерантності до ковзання може допомогти транзакціям досягти успіху, але ви не можете отримати таку хорошу ціну. Використовуйте обережно.",
  STATUS_OF_THE_LIMIT_ORDER: "Статус обмеження",
  Suspended: "Підвішений",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Ці збори сплачуються шахтарям мережі. Hodl не контролює збори.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Різниця між ринковою ціною та ціною внаслідок розміру торгівлі",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Мінімальна отримана сума, інакше транзакція буде повернена",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Ця плата йде безпосередньо до шахтарів як стимул для визначення пріоритетності вашої транзакції.",
  TRANSACTION_REJECTED_TRY: "Транзакція відхилена, спробуйте ще раз",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Передача для вибраного маркера недоступна в цій мережі",
  YOU: "ви",
};
