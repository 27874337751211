export default {
  ABOUT_NEW_FEATURES: "über die neuen Funktionen.",
  ACCEPT: "Akzeptieren",
  ACTIVE_ORDERS: "Aktive Bestellungen",
  ADD_TOKEN: "Fügen Sie ein Token hinzu",
  AGGRESSIVE: "Aggressiv",
  AND: "Und",
  APPROVE: "Genehmigen",
  ASSETS_ON_WAY: "Ihre Vermögenswerte sind unterwegs und sollten ankommen",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Bitte vermeiden Sie es, in eine zentrale Austauschadresse zu wechseln (Binance, Huobi ...). Es ist keine unterstützte Funktion",
  BALANCE: "Gleichgewicht",
  BALANCE_ONE: "Gleichgewicht:",
  BLOG: "BLog",
  BRIDGE: "Brücke",
  CANCEL: "Stornieren",
  CANCELLATION_TRANSACTION_FEE:
    "Die Stornierung einer Grenzbestellung umfasst die Zahlung der Transaktionsgebühren.",
  CANCEL_ALL: "Alle Absagen",
  CANCEL_LIMIT_NOT_FILLED:
    "Sie können eine Grenzbestellung stornieren, solange die Bestellung noch nicht ausgefüllt wurde.",
  CANCEL_LIMIT_ORDER: "Limitbestellung abbrechen",
  CANCEL_ORDER: "Bestellung stornieren",
  CANCEL_SELECTED: "Ausgewählt abbrechen",
  CHAINS: "Ketten",
  CHOOSE_NETWORK: "Wählen Sie Netzwerk",
  CLASSIC_MODE: "Klassischer Modus",
  CLEAR: "Klar",
  CONFIRM: "Bestätigen",
  CONFIRMATION_REJECTED: "Bestätigung abgelehnt.!",
  CONFIRM_SWAP: "Swap bestätigen",
  CONNECT_WALLET: "Brieftasche anschließen",
  COPIED: "Kopiert",
  CURRENT: "Aktuell",
  CUSTOM: "Brauch",
  DAYS: "Tage",
  DEFAULT: "Standard",
  DETAILS: "Einzelheiten",
  DISCLAIMER: "Haftungsausschluss",
  DISCONNECT_WALLET: "Brieftasche trennen",
  DISMISS: "Zurückweisen",
  DUPLICATE_ORDER_SUBMIT: "Doppelte Bestellung. Dieser ist bereits eingereicht",
  ENTER_AMT: "Menge eingeben",
  EXCHANGE: "Austausch",
  EXPIRY: "ABLAUF",
  EXPIRY_SM: "Ablauf",
  FAILED_LOAD_CHART: "Die Tabelle nicht laden",
  FIND_BY_TOKEN_OR_STATUS: "Finden Sie nach Token oder Status",
  INSUFFICIENT_BALANCE: "Mangelhaftes Gleichgewicht",
  INSUFFICIENT: "Unzureichend",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "Saldo zur Deckung des bestehenden Bestellbilanzs von",
  RECEIVERS: "Empfänger",
  WALETADDRESS: "Brieftaschenadresse",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Bitte akzeptieren Sie Nutzungsbedingungen und Datenschutzbestimmungen",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker sollte nicht gleiche Taker -Adresse sein",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Die Schlupftoleranz sollte zwischen 0,1% und 15% liegen",
  SEARCH_A_TOKEN: "Suche nach einem Token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Ihre Transaktion wird zurückkehren, wenn sich der Preis um mehr als diesen Prozentsatz ändert",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Passt den Gaspreis für Ihre Transaktion an.Höhere Gwei = höhere Geschwindigkeit.",
  REVIEW_OTC_ORDER: "Überprüfen Sie die OTC -Bestellung",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Ablaufzeit des Order Makers.In diesem Zeitraum läuft die Bestellung aus und wird archiviert.",
  SEARCH_A_LIQUIDITY_SOURCES: "Suchen Sie nach Liquiditätsquellen",

  FAST: "Schnell",
  FAST_AND_SECURE:
    "Die sichere, schnelle und sicherste Methode, um Cross-Chain-Vermögenswerte an zu bringen",
  FEATURE_TO_TRANSFER:
    "Bitte verwenden Sie diese Funktion nicht, um Ihre Token an eine Adresse eines zentralen Austauschs zu übertragen.",
  FEES: "Gebühren",
  FILL_ORDER: "Bestellung füllen",
  FILL_OTC_ORDER: "Füllen Sie die OTC -Bestellung",
  FREE: "Frei",
  FROM: "Aus",
  GAS_COST: "Gaskosten",
  GAS_DESTINATION: "Gas in der Zielkette",
  GAS_ON_DESTINATION: "Gas am Ziel",
  GAS_PRICE: "Gaspreis",
  GOT_IT: "Habe es",
  GOT_PROBLEM_JUST: "Hab ein Problem? Machen Sie sich einfach in Verbindung.",
  HIGH: "Hoch",
  HOURS: "Std",
  IMPORT: "Importieren",
  IMPOSSIBLE_TO_RETRIEVE:
    "Es ist keine unterstützte Funktion. Bitte überprüfen Sie auch die von Ihnen füllende Adresse. Alle an die falschen Adresse gesendete Token sind nicht abzurufen.",
  INSTANT: "Sofortig",
  INSUFFICIENT_APPROVED_AMT: "Unzureichender zugelassener Betrag",
  INVALID_TOKEN_ADDRESS: "Ungültige Token -Adresse",
  KEEP_TRACK_NOTIFY:
    "Sie können es in Ihrer Panel -Benachrichtigung verfolgen. Wir werden Sie benachrichtigen, sobald es fertig ist.",
  KEEP_YOU_POSTED: ". Wir halten Sie auf dem Laufenden.",
  LEARN_MORE: "Erfahren Sie mehr",
  LEGACY: "Vermächtnis",
  LIMIT: "Grenze",
  LIMIT_ORDER: "Bestellung begrenzen",
  LIMIT_PRICE: "Preis begrenzen",
  LIMIT_PRICE_SM: "Preis begrenzen",
  LIQUIDITY_SOURCES: "Liquiditätsquellen",
  LOW: "Niedrig",
  MAKER: "Hersteller",
  MARKET: "Markt",
  MAX: "Max",
  MAX_BASE_FEE: "Maximale Basisgebühr",
  MAX_S: "Max",
  MAX_TO_PAY: "Max zu zahlen",
  MINIMUM_RECEIVED: "Minimum erhalten",
  MINUTES: "Protokoll",
  MONTHS: "Monate",
  NEED_APPROVAL_MOVE: "Wir brauchen Ihre Genehmigung, um sich zu bewegen",
  NEED_TO_WRAP: "Sie müssen wickeln",
  NETWORK_FEES: "Netzwerkgebühren",
  NETWORK_SM: "Netzwerk.",
  NEVER: "Niemals",
  NONE: "Keiner",
  NON_CUSTODIAL_LIMIT:
    "in der nicht kundenmäßigen Grenzreihenfolge. Es ist eine technische Anforderung an der",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Sie haben noch keine Bestellungen",
  NO_RECORDS: "Keine Aufzeichnungen",
  ON_UR_BEHALF: "in Ihrem Namen.",
  OOPS_WRONG: "Hoppla! Etwas ist schiefgelaufen.!",
  OPEN: "Offen",
  ORDER_DETAILS: "Bestelldetails",
  ORDER_HISTORY: "Bestellverlauf",
  ORDER_PRICE: "Bestellpreis",
  ORDER_ROUTING: "Routing bestellen",
  OTC: "OTC",
  PASTE: "Paste",
  PAY: "Zahlen",
  PENDING_CONFIRM: "Ausstehende Bestätigung auf Brieftasche.",
  PER: "pro",
  PLEASE_CHOOSE_NETWORK: "Bitte wählen Sie Netzwerk",
  PLEASE_LOGIN: "Bitte melden Sie sich in",
  PLEASE_TRY_LATER: "Bitte versuchen Sie es später erneut",
  POPULAR_TOKENS: "Beliebte Token",
  PRICE: "Preis",
  PRICE_IMPACT: "Preisauswirkungen",
  PRIVACY_POLICY: "Datenschutzrichtlinie",
  RATE: "Rate",
  READ_ACCEPT: "Ich lese und akzeptiere",
  RECEIVE: "Erhalten",
  RECEIVED_ORDERS: "Bestellungen erhalten",
  REFUND: "ERSTATTUNG",
  REMOVE: "Entfernen",
  REVERSE_RATE: "Reverse Rate",
  REVIEW_TRADE: "Überprüfungshandel",
  SELECTED: "ausgewählt",
  SELECT_ALL: "Wählen Sie Alle",
  SELECT_FROM_INFO: "Bitte wählen Sie aus Informationen",
  SELECT_NETWORK: "Wählen Sie ein Netzwerk aus",
  SELECT_TOKEN: "Wählen Sie ein Token",
  SELECT_TOKENN: "Wählen Sie Token",
  SELECT_TO_INFO: "Bitte wählen Sie Informationen zu Info",
  SETTINGS: "Einstellungen",
  SIMPLE_MODE: "Einfacher Modus",
  SLIPPAGE: "Schlupf",
  SLIPPAGE_TOLERANCE: "Schlupftoleranz",
  SRY_INSUFFICIENT: "Entschuldigung, unzureichend",
  STATUS: "STATUS",
  STATUS_SM: "Status",
  STEP: "Schritt",
  SUBMIT: "Einreichen",
  SUBMITTED_ORDERS: "Eingereichte Bestellungen",
  SUBMIT_DATE: "Abgabetermin",
  SUCCESS_FILLED: "Erfolgreich bestellen gefüllt",
  SUCCESS_RECEIVED: "Erfolgreich aufgenommen",
  SUCCESS_SUBMITTED_ORDER: "Erfolgreich Ihre Bestellung eingereicht",
  SWAP: "Tauschen",
  SWAP_TRANSFER: "Tausch & Übertragung",
  SWITCH_NETWORK: "Switch -Netzwerk",
  TAKER: "Taker",
  TAKER_ADDRESS: "Takeradresse",
  TERMS_OF_SERVICE: "Nutzungsbedingungen",
  TO: "Zu",
  TOKEN_NOT_FOUND: "Token nicht gefunden",
  TOKEN_RECEIVE_SENT: "Das zu erhalten",
  TRADE: "Handel",
  TRANSACTION_REJECTED_LATER: "Transaktion abgelehnt bitte erneut versuchen",
  TRANSFER: "Überweisen",
  TRANSFER_ALERT: "Alarm übertragen",
  TRANSFER_TO: "Übertragen zu",
  TX_COST: "TX Kosten",
  UNLOCK: "Freischalten",
  USER_GUIDE: "Benutzerhandbuch",
  USE_ITAS: "und benutze es als w",
  VERIFY: "Verifizieren",
  VIEW_EXPLORER: "Transaktion im Entdecker anzeigen",
  VIEW_ON: "Blick auf",
  VIEW_TRANSACTION: "Transaktion anzeigen",
  WRAP: "Wickeln",
  YOUR_ADDRESS: "Deine Adresse",
  YOUR_ORDER_CANCEL: "Ihre Bestellung wird storniert",
  YOUR_WALLET: "Deine Geldbörse",
  YOU_GET: "Du erhältst",
  YOU_PAY: "Sie bezahlen",
  YOU_RECEIVE: "Du erhältst",
  YOU_WILL_RECEIVE: "Du wirst erhalten",
  ANYONE_CAN_CREATE:
    "Jeder kann ein beliebiges Token erstellen, einschließlich gefälschter Versionen der vorhandenen Token. Achten Sie auf die gebührende Vorsicht. Einige Token und ihre technischen Parameter können mit unseren Diensten unvereinbar sein. Durch das Import dieses benutzerdefinierten Tokens bestätigen Sie die Risiken und akzeptieren Sie die Risiken.",
  BY_SIGNING_MESSAGE:
    "Durch die Unterzeichnung dieser Nachricht bestätigen Sie, dass Sie nicht auf diese App zugreifen oder in den USA oder einem anderen eingeschränkten Land ansässig sind. Für weitere Informationen lesen Sie bitte unsere",
  CONSTITUTION: "ConstitutionDao",
  CURRENTLY_CONNECTED: "Sie sind derzeit miteinander verbunden mit",
  IMPORT_TOKEN: "Ein Token importieren",
  IN_YOUR_WALLET: "in deiner Brieftasche",
  MAKE_SURE_CHECKED:
    "Stellen Sie sicher, dass Sie die Token -Adresse überprüft haben und sie korrekt ist",
  ON: "An",
  PEOPLE: "Menschen",
  PLEASE_SWITCH: "Bitte wechseln Sie zu",
  SELECTED_NOT_SUPPORTED:
    "Bitte ändern Sie das Netzwerk in Ihrer Brieftasche, ausgewähltes Netzwerk wird nicht unterstützt",
  SIGN_FOR_ACCESS: "Zeichen für Zugang",
  SIGN_MESSAGE: "Meldung unterschreiben",
  LAUNCH_APP: "App starten",
  GETTING_STARTED: "Erste Schritte mit HODL: Tutorials & Produkterklärer",
  DEEP_DIVE: "Deepdive in HODL: Strategien und Produktfunktionen",
  BUILD_HODL: "Aufbau auf HODL: Technische Dokumentation",
  BLOG_HEADING:
    "Entmystifizierung von Defi - Alles, was Sie an einem Ort wissen müssen. Für Anfänger zu fortgeschrittenen Benutzern und alle dazwischen.",
  BLOG_PLACEHOLDER:
    "Suchen Sie nach dem Produkt/dem Thema, das Sie kennenlernen möchten",
  CHOOSE_TOPIC: "Wählen Sie Netzwerk",
  TRY: "VERSUCHEN",
  SEARCH_RESULTS: "Suchergebnisse",
  SEARCH_TOPIC: "Suchthema",
  LOAD_MORE: "Mehr laden",
  SOCIAL_MEDIA: "Sozialen Medien",
  NEW: "Das neuste zuerst",
  OLD: "Die ältesten zu erst",
  Z_A: " Sortieren Sie den Titel Z-A",
  A_Z: " Sortieren Sie den Titel A-Z",
  RESULTS: "Ergebnisse",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Eine Basisgasgebühr wird automatisch vom Netzwerk berechnet und variiert von Block zu Block. Legen Sie den maximalen Betrag der Grundgebühr fest, die Sie zahlen möchten. Ein Unterschied zwischen der von Ihnen festgelegten maximalen Basisgebühr und der tatsächlichen Grundgebühr wird zurückerstattet, sobald der Block abgebaut wurde.",
  CANCELLED_TRANSACTION: "Stornierte Ihre Transaktion",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Das Erstellen einer Limit -Bestellung ist ein kostenloser Betrieb für den Bestellhersteller, es sei denn, sie stornieren. Der Bestell -Taker muss die Transaktionsgebühren zahlen, während sie die Bestellung füllen.",
  Cancelled: "Abgesagt",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Wertunterschied zwischen der besten Route und",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Aktivieren Sie diese Option, wenn Sie Probleme beim Austausch eines Tokens mit integrierten Gebühren für die Übertragung haben.",
  Expired: "Abgelaufen",
  Filled: "Gefüllt",
  INSUFFICIENT_FOR_GASFEE:
    "Entschuldigung, unzureichende Mittel für die Gasgebühr",
  Open: "Offen",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Die teilweise Füllung gilt nur, wenn eine Swap -Route aus mehreren Hauptroutensteilen besteht. Es ermöglicht eine teilweise Ausführung und verhindern, dass die Transaktion umgekehrt wird, wenn sich der Marktpreis in Ungnade bewegt.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Preis vom Bestellhersteller für die Ausführung ihrer Bestellung festgelegt (Token zu erhalten = x Token, um zu zahlen)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Preis festgelegt vom Bestellhersteller für die Ausführung ihrer Bestellung (Token zu zahlen = y Token zum Empfang",
  REVIEW_LIMIT_ORDER: "Überprüfung der Grenze",
  SEARCH_BY_TOKEN: "Suche nach Token oder Adresse",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION: "। استخدم بحذر।",
  STATUS_OF_THE_LIMIT_ORDER: "Status der Grenzbestellung",
  Suspended: "Ausgesetzt",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Diese Gebühren werden an die Netzwerkminenarbeiter gezahlt. Hodl kontrolliert die Gebühren nicht.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Der Unterschied zwischen Marktpreis und Schusspreis aufgrund der Handelsgröße",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Der minimal empfangene Betrag, andernfalls wird die Transaktion zurückversetzt",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Diese Gebühr geht direkt an Bergleute als Anreiz, Ihre Transaktion zu priorisieren.",
  TRANSACTION_REJECTED_TRY:
    "Transaktion abgelehnt, bitte versuchen Sie es erneut",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Die Übertragung für das ausgewählte Token ist in diesem Netzwerk nicht verfügbar",
  YOU: "Du",
};
