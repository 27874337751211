import React, { useState, useEffect } from "react";
import config from "../../config/config";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getTokenLogo, getTxLink } from "../../helper/networkdata";
import { formatAddress, formatNumber } from "../../helper/custommath";
import { dateFormat } from "../../helper/dateFormat";
import { useTranslation } from "react-i18next";

export default function LimitOrderDetails(props) {
  var { walletConnection } = props;
  const { t } = useTranslation();

  const [swapDetails, setswapDetails] = useState(false);

  useEffect(() => {
    setswapDetails(props.orderInfo);
    //eslint-disable-next-line
  }, [props.orderInfo]);

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="order_detail_info"
        tabIndex="-1"
        aria-labelledby="order_detail_infoLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="order_detail_infoLabel">
                {t("ORDER_DETAILS")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <div className="swap_select_token_wrap">
                  <div className="swap_select_token_inner">
                    <label>{t("PAY")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.makerLogo,
                              swapDetails.chainId
                            )}
                            alt={swapDetails.makerSymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.makerSymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={formatNumber(
                          swapDetails.makerAmount /
                            10 ** swapDetails.makerDecimals,
                          5
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="swap_arrow_container">
                  <img
                    src={require("../../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
                <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
                  <div className="swap_select_token_inner">
                    <label>{t("RECEIVE")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.takerLogo,
                              swapDetails.chainId
                            )}
                            alt={swapDetails.takerSymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.takerSymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={formatNumber(
                          swapDetails.takerAmount /
                            10 ** swapDetails.takerDecimals,
                          5
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="swap_info">
                <div>
                  <span>{t("SUBMIT_DATE")}</span>
                  <span>
                    {dateFormat(
                      new Date(swapDetails.createdAt * 1000),
                      "DD-MM-YYYY hh:mm"
                    )}
                  </span>
                </div>
                <div>
                  <span>{t("MAKER")}</span>
                  <span>
                    {walletConnection &&
                    walletConnection.address &&
                    swapDetails &&
                    swapDetails.maker &&
                    swapDetails.maker.toLowerCase() ==
                      walletConnection.address.toLowerCase()
                      ? "You"
                      : formatAddress(swapDetails.maker)}
                  </span>
                </div>
                <div>
                  <span>
                    {t("LIMIT_PRICE_SM")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="orderPrice"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="orderPrice"
                    place="right"
                    content={t("PRICE_SET_BY_THE_ORDER_MAKER_X")}
                  />
                  {walletConnection &&
                  walletConnection.address &&
                  swapDetails &&
                  swapDetails.maker &&
                  swapDetails.maker.toLowerCase() ==
                    walletConnection.address.toLowerCase() ? (
                    <span>
                      1 {swapDetails.takerSymbol} ={" "}
                      {formatNumber(
                        parseFloat(swapDetails.makerAmount) /
                          10 ** parseFloat(swapDetails.makerDecimals) /
                          (parseFloat(swapDetails.takerAmount) /
                            10 ** parseFloat(swapDetails.takerDecimals))
                      )}
                      {swapDetails.makerSymbol}
                    </span>
                  ) : (
                    <span>
                      1 {swapDetails.makerSymbol} ={" "}
                      {formatNumber(
                        swapDetails.takerAmount / swapDetails.makerAmount
                      )}
                      {swapDetails.takerSymbol}
                    </span>
                  )}
                </div>
                <div>
                  <span>
                    {t("STATUS_SM")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="orderstatus"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="orderstatus"
                    place="right"
                    content={t("STATUS_OF_THE_LIMIT_ORDER")}
                  />
                  <span
                    className={
                      swapDetails.state == "CANCELLED"
                        ? "order_cancel"
                        : swapDetails.state == "FULFILLED"
                        ? "order_filled"
                        : ""
                    }
                  >
                    {/* {swapDetails.state} */}
                    {swapDetails &&
                      swapDetails.state &&
                      t(
                        swapDetails.state.charAt(0) +
                          swapDetails.state.slice(1).toLowerCase()
                      )}
                  </span>
                </div>
                {swapDetails.transactionHash &&
                  swapDetails.transactionHash !== "" && (
                    <div className="text-center">
                      <a
                        className="connect_wallet_btn w-100"
                        href={
                          getTxLink(swapDetails.chainId) +
                          "tx/" +
                          swapDetails.transactionHash
                        }
                        target="_blank"
                      >
                        {t("VIEW_EXPLORER")}
                      </a>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
