import { bignumber, multiply, divide, subtract } from "mathjs";

import BEP20ABI from "../ABI/BEP20.json";
import MiddlewareABI from "../ABI/Middleware.json";
import Web3 from "web3";
import { Multicall } from "ethereum-multicall";

import {
  getFormatMulticall,
  checkIsAddress,
  divideValue,
  multiplyValue,
  addValue,
  subtractValue
} from "../helper/custommath";
import { connection, readWeb3 } from "../helper/connection";
import config from "../config/config";
import { convert } from "../helper/convert";

import { SaveSignature } from "../Api/TokenActions";

var topicArray = [
  {
    "indexed": false,
    "internalType": "bytes16",
    "name": "uuid",
    "type": "bytes16"
  },
  {
    "indexed": false,
    "internalType": "address",
    "name": "partner",
    "type": "address"
  },
  {
    "indexed": false,
    "internalType": "uint256",
    "name": "feePercent",
    "type": "uint256"
  },
  {
    "indexed": true,
    "internalType": "address",
    "name": "initiator",
    "type": "address"
  },
  {
    "indexed": false,
    "internalType": "uint256",
    "name": "srcAmount",
    "type": "uint256"
  },
  {
    "indexed": false,
    "internalType": "uint256",
    "name": "receivedAmount",
    "type": "uint256"
  },
  {
    "indexed": false,
    "internalType": "uint256",
    "name": "expectedAmount",
    "type": "uint256"
  }
]

export async function validateAllowanceBalance(
  swapToken,
  walletConnection,
  router
) {
  var initial = {
    fromBalanceOf: 0,
    fromAllowance: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toAllowance: 0,
    toBalance: 0,
  };
  var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0)
    ? parseInt(localStorage.getItem("network")) : (walletConnection.network > 0) ? walletConnection.network : 56;
  var web3 = readWeb3(chainVal);
  if (web3 == "") {
    web3 = new Web3(config.rpc[walletConnection.network]);
  }
  var address = walletConnection.address;
  try {
    if (address && address !== "" && router) {
      const multicall = new Multicall({
        web3Instance: web3,
      });

      if (
        swapToken.fromaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "fromDetails",
            contractAddress: swapToken.fromaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "allowance",
                methodName: "allowance",
                methodParameters: [address, router],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var fromBalanceOf = await getFormatMulticall(results, "fromDetails", 0);
        fromBalanceOf =
          fromBalanceOf && fromBalanceOf.hex ? parseInt(fromBalanceOf.hex) : 0;
        var fromAllowance = await getFormatMulticall(results, "fromDetails", 1);
        fromAllowance =
          fromAllowance && fromAllowance.hex ? parseInt(fromAllowance.hex) : 0;
        var fromDecimals = await getFormatMulticall(results, "fromDetails", 2);

        return {
          fromBalanceOf: fromBalanceOf,
          fromAllowance: fromAllowance / 10 ** fromDecimals,
          fromBalance: fromBalanceOf / 10 ** fromDecimals,
        };
      } else if (
        swapToken.fromaddress.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        var getBalance = await web3.eth.getBalance(walletConnection.address);
        var bal = getBalance / 10 ** 18;

        return {
          fromBalanceOf: getBalance,
          fromAllowance: 0,
          fromBalance: bal,
        };
      } else {
        return initial;
      }
    } else {
      return initial;
    }
  } catch (err) {
    console.log(err, "errerrerrerrerr11111");
    return initial;
  }
}

export async function approveToken(token, spender, amount) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var Contract = new web3.eth.Contract(BEP20ABI, token);
      var result1 = await Contract.methods
        .approve(spender, amount)
        .send({ from: address });

      return {
        value: result1,
        status: true,
      };
    } else {
      return {
        value: {},
        status: false,
      };
    }
  } catch (err) {
    return {
      value: {},
      status: false,
    };
  }
}

export async function getUserBalances(
  swapToken,
  walletConnection,
  setusertokenBal
) {
  var initial = {
    fromBalanceOf: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toBalance: 0,
  };
  var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0) ? parseInt(localStorage.getItem("network")) : (walletConnection.network > 0) ? walletConnection.network : 56;

  var web3 = readWeb3(chainVal);
  if (web3 == "") {
    web3 = new Web3(config.rpc[walletConnection.network]);
  }

  var address = walletConnection.address;
  try {
    if (
      address &&
      address !== "" &&
      swapToken.fromaddress &&
      swapToken.toaddress
    ) {
      const multicall = new Multicall({
        web3Instance: web3,
      });

      if (
        swapToken.fromaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "fromDetails",
            contractAddress: swapToken.fromaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "toDetails",
            contractAddress: swapToken.toaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var fromBalanceOf = await getFormatMulticall(results, "fromDetails", 0);
        fromBalanceOf =
          fromBalanceOf && fromBalanceOf.hex ? parseInt(fromBalanceOf.hex) : 0;
        var fromDecimals = await getFormatMulticall(results, "fromDetails", 1);

        var toBalanceOf = await getFormatMulticall(results, "toDetails", 0);
        toBalanceOf =
          toBalanceOf && toBalanceOf.hex ? parseInt(toBalanceOf.hex) : 0;
        var toDecimals = await getFormatMulticall(results, "toDetails", 1);

        let balData = {
          fromBalanceOf: fromBalanceOf,
          fromBalance:
            fromBalanceOf > 0
              ? (fromBalanceOf / 10 ** fromDecimals).toFixed(4)
              : 0.0,
          toBalanceOf: toBalanceOf,
          toBalance:
            toBalanceOf > 0 ? (toBalanceOf / 10 ** toDecimals).toFixed(4) : 0.0,
        };
        setusertokenBal(balData);
        return;
      } else if (
        swapToken.fromaddress.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "toDetails",
            contractAddress: swapToken.toaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var toBalanceOf1 = await getFormatMulticall(results, "toDetails", 0);
        toBalanceOf1 =
          toBalanceOf1 && toBalanceOf1.hex ? parseInt(toBalanceOf1.hex) : 0;
        var toDecimals1 = await getFormatMulticall(results, "toDetails", 1);

        var getBalance = await web3.eth.getBalance(walletConnection.address);
        var bal = getBalance / 10 ** 18;
        let balData = {
          fromBalanceOf: getBalance,
          fromBalance: bal > 0 ? bal.toFixed(4) : 0.0,
          toBalanceOf: toBalanceOf1,
          toBalance:
            toBalanceOf1 > 0
              ? (toBalanceOf1 / 10 ** toDecimals1).toFixed(4)
              : 0.0,
        };

        setusertokenBal(balData);
      } else if (
        swapToken.fromaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "fromDetails",
            contractAddress: swapToken.fromaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var fromBalanceOf1 = await getFormatMulticall(
          results,
          "fromDetails",
          0
        );
        fromBalanceOf1 =
          fromBalanceOf1 && fromBalanceOf1.hex
            ? parseInt(fromBalanceOf1.hex)
            : 0;
        var fromDecimals1 = await getFormatMulticall(results, "fromDetails", 1);

        var getBalance = await web3.eth.getBalance(walletConnection.address);
        var bal = getBalance / 10 ** 18;

        let balData = {
          fromBalanceOf: fromBalanceOf1,
          fromBalance:
            fromBalanceOf1 > 0
              ? (fromBalanceOf1 / 10 ** fromDecimals1).toFixed(4)
              : 0.0,
          toBalanceOf: getBalance,
          toBalance: getBalance > 0 ? (getBalance / 10 ** 18).toFixed(4) : 0,
        };
        setusertokenBal(balData);
      }
    } else {
      setusertokenBal(initial);
      return initial;
    }
  } catch (err) {
    console.log(err, "getUserBalances1");
    setusertokenBal(initial);
    return initial;
  }
}

export async function getFee() {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var network = get.network;
      var middlreware = config.middlreware[network];
      var Contract = new web3.eth.Contract(MiddlewareABI, middlreware);
      var adminfee = await Contract.methods.adminfee().call();

      return {
        fee: adminfee / 100,
        status: true,
      };
    } else {
      return {
        fee: 0,
        status: false,
      };
    }
  } catch (err) {
    return {
      fee: 0,
      status: false,
    };
  }
}
export function getFeeCal(data) {
  var price = bignumber(data.price);
  var fee = bignumber(data.fee);
  var mul = multiply(price, fee);
  var per = bignumber(100);
  var servicefeeper = divide(mul, per);
  var servicecal = subtract(price, servicefeeper);

  return servicecal.toString();
}

export function getRecievedFeeCal(price, fee) {
  price = parseFloat(price);
  fee = parseFloat(fee);
  var cal = (price * fee) / 100;
  var commision = (cal * 85) / 100; //85%
  var receivedAmt = price - cal;
  return {
    commision: commision,
    receivedAmt: receivedAmt,
  };
}

export function getmultiSwap(result, partnerFee, partner, minReceived) {
  try {
    var data1 = result[0]; //fromToken
    var data2 = parseInt(result[1]._hex);
    data2 = convert(data2.toString()); //fromAmount

    var data3 = parseInt(result[2]._hex); //toAmount
    data3 = convert(data3.toString());

    var data4 = parseInt(result[3]._hex);
    data4 = convert(minReceived.toString()); //expectedAmount

    var data5 = result[4]; //beneficiary

    // var data6 = [];
    // for (var h = 0; h < result[5].length; h++) {
    //     data6.push(result[5][h]);//path
    // }
    var path11111 = result[5];
    var path = result[5];
    console.log(path11111, "data6data6data6");
    // for (var m = 0; m < path.length; m++) {
    //     if (Array.isArray(path[m])) {
    //         console.log(path[m], '+++++++++++++++++++++++')
    //         var second = path[m][0];
    //         for (var n = 0; n < second.length; n++) {
    //             if (Array.isArray(path[m][n])) {
    //                 var thirdArr = path[m][n][0];
    //                 for (var o = 0; o < thirdArr.length; o++) {
    //                     console.log(path[m][n], '###################')
    //                     path[m][n][0][o] = (thirdArr && thirdArr[o] && thirdArr[o]._hex) ? parseInt(thirdArr[o]._hex).toString() : thirdArr[o];
    //                     console.log(path[m][n][o], '###################1')
    //                 }
    //                 //path[m][n] = thirdArr;
    //             } else {
    //                 path[m][0][n] = (path[m] && path[m][n] && path[m][n]._hex) ? parseInt(path[m][n]._hex).toString() : path[m][n];
    //             }

    //         }
    //     } else {
    //         path[m] = (path[m] && path[m]._hex) ? parseInt(path[m]._hex).toString() : path[m];
    //     }

    // }

    // for (var o = 0; o < secondLoop.length - 1; o++) {
    //     thirdLoop[o] = (thirdLoop && thirdLoop[n] && thirdLoop[n]._hex) ? parseInt(thirdLoop[n]._hex).toString() : thirdLoop[n]
    // }

    // console.log(path, 'pathpathpathpath')
    // console.log(secondLoop, 'secondLoopsecondLoop1')
    // console.log(thirdLoop, 'thirdLoopthirdLoop')

    console.log(path, "++++++++++++++++");
    console.log(path[0], "++++++++++++++++");
    for (var m = 0; m < path.length; m++) {
      // struct Path {
      //     address to;
      //     uint256 totalNetworkFee;
      //     Adapter[] adapters;
      // }
      console.log(path[m], "aaaaaaaaaaaaaaaaaaaaaaa1");
      if (Array.isArray(path[m]) && path[m] && path[m][0]) {
      } else {
        path[m] =
          path[m] && path[m]._hex ? parseInt(path[m]._hex).toString() : path[m];
      }

      path[m][0] = path[m][0];
      path[m][1] =
        path[m] && path[m][1]
          ? convert(parseInt(path[m][1]).toString())
          : path[m][1];

      for (var secondLoop = 0; secondLoop < path[m][2].length; secondLoop++) {
        // struct Adapter {
        //     address payable adapter;
        //     uint256 percent;
        //     uint256 networkFee;
        //     Route[] route;
        // }

        console.log(path[m][2][secondLoop], "aaaaaaaaaaaaaaaaaaaaaaa2");

        path[m][2][secondLoop][0] = path[m][2][secondLoop][0];
        path[m][2][secondLoop][1] = convert(
          parseInt(path[m][2][secondLoop][1]._hex).toString()
        );
        path[m][2][secondLoop][2] = convert(
          parseInt(path[m][2][secondLoop][2]._hex).toString()
        );

        console.log(path[m][2][secondLoop][3], "aaaaaaaaaaaaaaaaaaaaaaa3");

        for (
          var thirdLoop = 0;
          thirdLoop < path[m][2][secondLoop][3].length;
          thirdLoop++
        ) {
          // struct Route {
          //     uint256 index;
          //     address targetExchange;
          //     uint256 percent;
          //     bytes payload;
          //     uint256 networkFee;
          // }
          path[m][2][secondLoop][3][thirdLoop][0] = convert(
            parseInt(path[m][2][secondLoop][3][thirdLoop][0]._hex).toString()
          );
          path[m][2][secondLoop][3][thirdLoop][1] =
            path[m][2][secondLoop][3][thirdLoop][1];
          path[m][2][secondLoop][3][thirdLoop][2] = convert(
            parseInt(path[m][2][secondLoop][3][thirdLoop][2]._hex).toString()
          );
          path[m][2][secondLoop][3][thirdLoop][3] =
            path[m][2][secondLoop][3][thirdLoop][3];
          path[m][2][secondLoop][3][thirdLoop][4] = convert(
            parseInt(path[m][2][secondLoop][3][thirdLoop][4]._hex).toString()
          );
        }
      }
    }

    console.log(path, "pathpathpath");
    var data6 = path;

    var data7 = partner; //partner
    var data8 = partnerFee; //feePercent
    var data9 = result[8]; //permit

    var data10 = parseInt(result[9]._hex); //deadline
    data10 = convert(data10.toString());
    var data11 = result[10]; //uuid

    var tuple1 = [
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
    ];
    return tuple1;
  } catch (errr) {
    console.log(errr, "errrerrrerrrerrr");
    return [];
  }
}

export function getdirectBalancerV2GivenInSwap(
  result,
  partnerFee,
  partner,
  minReceived
) {
  try {
    var data1 = result[0];
    var data2 = result[1];
    var data3 = parseInt(result[2]._hex);
    var data4 = parseInt(result[3]._hex);
    var data5 = parseInt(result[4]._hex);
    console.log(data5, "data5data5");

    data5 = minReceived;
    console.log(data5, "data5data5A");
    var data6 = [];
    for (var h = 0; h < result[5].length; h++) {
      data6.push(result[5][h]);
    }
    var data7 = result[6];

    var data8 = [];
    for (var i = 0; i < result[7].length; i++) {
      var val1 = parseInt(result[7][i]._hex).toString();
      data8.push(val1);
    }

    var data9 = [];
    for (var j = 0; j < result[8].length; j++) {
      var val2 = parseInt(result[8][j]._hex).toString();
      data9.push(val2);
    }

    var data10 = result[9];
    var data11 = result[10];
    var data12 = partnerFee;
    var data13 = result[12];
    var data14 = parseInt(result[13]._hex);
    var data15 = result[14];

    var tuple1 = [
      data1,
      data2,
      data3.toString(),
      data4.toString(),
      data5.toString(),
      data6,
      data7,
      data8,
      data9,
      data10,
      partner,
      data12.toString(),
      data13.toString(),
      data14.toString(),
      data15.toString(),
    ];
    return tuple1;
  } catch (errr) {
    console.log(errr, "errrerrrerrrerrr");
    return [];
  }
}

export function getSwapSimple(result, partnerFee, partner, minReceived) {
  try {
    var data1 = result[0];
    var data2 = result[1];
    var data3 = parseInt(result[2]._hex);
    var data4 = parseInt(result[3]._hex);
    var data5 = parseInt(result[4]._hex);
    console.log(data5, "data5data5");

    data5 = minReceived;
    console.log(data5, "data5data5A");
    var data6 = [];
    for (var h = 0; h < result[5].length; h++) {
      data6.push(result[5][h]);
    }
    var data7 = result[6];

    var data8 = [];
    for (var i = 0; i < result[7].length; i++) {
      var val1 = parseInt(result[7][i]._hex).toString();
      data8.push(val1);
    }

    var data9 = [];
    for (var j = 0; j < result[8].length; j++) {
      var val2 = parseInt(result[8][j]._hex).toString();
      data9.push(val2);
    }

    var data10 = result[9];
    var data11 = result[10];
    var data12 = partnerFee;
    var data13 = result[12];
    var data14 = parseInt(result[13]._hex);
    var data15 = result[14];

    var tuple1 = [
      data1,
      data2,
      convert(data3.toString()),
      convert(data4.toString()),
      convert(data5.toString()),
      data6,
      data7,
      data8,
      data9,
      data10,
      partner,
      convert(data12.toString()),
      data13.toString(),
      convert(data14.toString()),
      data15.toString(),
    ];
    return tuple1;
  } catch (errr) {
    console.log(errr, "errrerrrerrrerrr");
    return [];
  }
}

export function getdirectUniV3Swap(result, partnerFee, partner, minReceived) {
  try {
    var data1 = result[0];
    var data2 = result[1];
    var data3 = result[2];
    var data4 = parseInt(result[3]._hex);
    data4 = convert(data4.toString());
    //data4 = parseFloat(enterData.amount) * 10 ** decimals;

    var data5 = parseInt(result[4]._hex);
    data5 = convert(data5.toString());

    var data6 = parseInt(result[5]._hex);
    data6 = convert(data6.toString());
    data6 = minReceived;

    var data7 = parseInt(result[6]._hex);
    // data7 = convert(data7.toString());
    data7 = convert(partnerFee);

    var data8 = parseInt(result[7]._hex);
    data8 = convert(data8.toString());

    var data9 = result[8];
    data9 = partner;
    var data10 = result[9];
    var data11 = result[10];
    var data12 = result[11];
    var data13 = result[12];
    var data14 = result[13];

    var tuple1 = [
      data1,
      data2,
      data3,
      data4,
      data5,
      data6,
      data7,
      data8,
      data9,
      data10,
      data11,
      data12,
      data13,
      data14,
    ];
    return tuple1;
  } catch (err) {
    console.log(err, "getdirectUniV3Swap errerrerrerr");
    return [];
  }
}

export function getPoplularList(list) {
  try {
    var getPopular = list.filter((data) => data.isPopular == "yes");
    var popularList = [];
    for (var l = 0; l < getPopular.length; l++) {
      popularList.push(getPopular[l]);
    }

    var isPopular = localStorage.getItem("isloadPopular");

    if (isPopular !== "yes") {
      var pList = "";
      if (popularList && popularList.length > 0) {
        for (var p = 0; p < popularList.length; p++) {
          pList +=
            p == 0 ? popularList[p].address : "," + popularList[p].address;
        }
        console.log(pList, "pListpListpList");
        localStorage.setItem("isloadPopular", "yes");
        localStorage.setItem("popularlist", pList);
      }
    } else if (isPopular == "yes") {
      var getList = localStorage.getItem("popularlist");
      var splitAddr = [];
      if (getList) {
        var splitAddr = getList.split(",");
      }
      var gList = [];
      if (splitAddr && splitAddr.length > 0) {
        for (var k = 0; k < splitAddr.length; k++) {
          var chkIndex = list.findIndex(
            (val) => val.address.toLowerCase() == splitAddr[k].toLowerCase()
          );
          if (chkIndex !== -1) {
            gList.push(list[chkIndex]);
          }
        }
        if (gList && gList.length > 0) {
          popularList = gList;
        }
      }
    }
    return gList;
  } catch (err) {
    console.log(err, "getPoplularListgetPoplularList");
    return [];
  }
}

export async function createSignature({ message4 }) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var network = get.network;
      console.log(network, 'networknetworknetwork')
      var msg = message4;
      console.log(msg, 'msgmsgmsgmsg')
      var signature = await web3.eth.personal.sign(msg, address, 'HODL');
      await SaveSignature({ useraddress: address, signature: signature });
      return {
        signature: signature,
        status: true,
      };
    } else {
      console.log('elseeeeeeee')
      return {
        signature: "",
        status: false,
      };
    }
  } catch (err) {
    console.log(err, "createSignature errerrerr");
    return {
      value: {},
      status: false,
    };
  }
}

export function getImportedToken(newTkn) {
  var imptToken = [newTkn];
  var getImported = localStorage.getItem("Imported");
  if (getImported) {
    try {
      getImported = JSON.parse(getImported);
      if (Array.isArray(getImported)) {
        var index = getImported.findIndex(
          (val) => val.address == newTkn.address.toLowerCase()
        );
        if (index == -1) {
          var newImported = getImported.concat([newTkn]);
          imptToken = newImported;
        }
      }
    } catch (err) {
      console.log(err, "errerrerr");
    }
  }
  return imptToken;
}

var topicArray1 = [
  {
    "indexed": false,
    "internalType": "address",
    "name": "from",
    "type": "address"
  },
]
//getInfo()
export async function getInfo() {
  console.log('getInfogetInfogetInfogetInfossss')
  var transferAmount = 0;
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      //var result = await web3.eth.getTransactionReceipt("0x3c50686652fc468347b12eecf3864f84f2b5e3e7424911a3630e862593697e8d")

      var { expectedAmount,
        receivedAmount,
        adminFee } = await getreceivedAmount("0x073d518e26b6cdea344ecec8882e74673263b044aea35985cb2cbb3323ee3a45", "0xc2132D05D31c914a87C6611C10748AEb04B58e8F");
      console.log(expectedAmount,
        receivedAmount,
        adminFee, '??????????????????????')
    }


  } catch (err) {
    console.log(err, "createSignature errerrerr");
    return {
      amount: 0
    };
  }

}

export async function getreceivedAmount(transactionHash) {

  var expectedAmount = 0;
  var receivedAmount = 0;
  var adminFee = 0;
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var network = get.network;
      var feeClaimer = config.feeClaimer[network];
      feeClaimer = feeClaimer.toLowerCase();
      console.log(feeClaimer, 'feeClaimer')
      var result = await web3.eth.getTransactionReceipt(transactionHash)
      var events = result.logs;

      //var getTransfer = events.filter(val => val.address.toLowerCase() == tokenAddress.toLowerCase());
      var getTransfer = events;

      for (var e = 0; e < getTransfer.length; e++) {
        if (getTransfer[e] && getTransfer[e]) {
          var hashData = (getTransfer && getTransfer[e] && getTransfer[e].data
          ) ? getTransfer[e].data : "";
          var topics = (getTransfer && getTransfer[e] && getTransfer[e].topics
          ) ? getTransfer[e].topics : "";
          var index = topics.findIndex(val => val.toLowerCase() == "0xe00361d207b252a464323eb23d45d42583e391f2031acdd2e9fa36efddd43cb0")
          if (index != -1) {
            var decodeData = web3.eth.abi.decodeParameters(topicArray, String(hashData));
            receivedAmount = (decodeData && decodeData.receivedAmount) ? parseFloat(decodeData.receivedAmount) : 0;
            var feePercent = (decodeData && decodeData.feePercent) ? parseFloat(decodeData.feePercent) : 0;
            var mul = multiplyValue(receivedAmount, feePercent);
            var adminFee = divideValue(mul, 10000);
            adminFee = parseFloat(adminFee);
            //adminFee = receivedAmount * feePercent / 10000;
            expectedAmount = subtractValue(receivedAmount, adminFee);
            expectedAmount = parseFloat(expectedAmount);
          }

        }
      }

      return {
        expectedAmount,
        receivedAmount,
        adminFee
      };
    } else {
      return {
        expectedAmount: 0,
        receivedAmount: 0,
        adminFee: 0
      };
    }
  } catch (err) {
    console.log(err, 'errerrerrerrerrerr')
    return {
      expectedAmount: 0,
      receivedAmount: 0,
      adminFee: 0
    };
  }

}
