// import constant
import {
    GASFEE
} from '../constant';

const initialState = {
    low: 0,
    market: 0,
    aggressive: 0,
    custom: { base: "", miner: "", value: "" },
    base: "",
    gastype: "market",
    selected: 0,
    network: 0
};

const gasValue = (state = initialState, action) => {
    switch (action.type) {
        case GASFEE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default gasValue;