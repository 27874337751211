import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;
export const getblogCategory = async (data) => {



    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-blog-category`,
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {}
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getblog = async (data) => {

    console.log(data, 'daattat')

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-blog?skip=` + data.skip + `&lancode=` + data.lancode,
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            category: (respData && respData.data && respData.data.category) ? respData.data.category : {},
            totalrecords: (respData && respData.data && respData.data.totalrecords) ? respData.data.totalrecords : 0
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const loadblog = async (data) => {

    console.log(data, 'loadblog')

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/load-blog?skip=` + data.skip + `&category=` + data.category + `&search=` + data.search + `&lancode=` + data.lancode,
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            category: (respData && respData.data && respData.data.category) ? respData.data.category : {},
            totalrecords: (respData && respData.data && respData.data.totalrecords) ? respData.data.totalrecords : 0
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}


export const getblogbylink = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-single-blog`,
            data: data
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            similararticles: (respData && respData.data && respData.data.similararticles) ? respData.data.similararticles : {},
            recentarticles: (respData && respData.data && respData.data.recentarticles) ? respData.data.recentarticles : {},
            blogcategory: (respData && respData.data && respData.data.blogcategory) ? respData.data.blogcategory : {}
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getblogbylevel = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-blog-level`,
            data: data
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            totalrecords: (respData && respData.data && respData.data.totalrecords) ? respData.data.totalrecords : '',
            catresult: (respData && respData.data && respData.data.catresult) ? respData.data.catresult : {}
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const loadbycat = async (data) => {
    console.log(data, 'data134')
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/load-bycat`,
            data: data
        });
        console.log(respData, 'respData1')
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            totalrecords: (respData && respData.data && respData.data.totalrecords) ? respData.data.totalrecords : '',

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getblogbycat = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-blog-cat`,
            data: data
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            totalrecords: (respData && respData.data && respData.data.totalrecords) ? respData.data.totalrecords : '',
            catresult: (respData && respData.data && respData.data.catresult) ? respData.data.catresult : {}
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getfooterCms = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-footer-cms?lancode=` + data.lancode,

        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            footerresult: (respData && respData.data && respData.data.footerresult) ? respData.data.footerresult : {},

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getCmsbylink = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-cms`,
            data: data
        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            status: respData && respData.data && respData.data.status,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getfooterLink = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-footer-link`,

        });

        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            footerresult: (respData && respData.data && respData.data.footerresult) ? respData.data.footerresult : {},

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}


function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}
