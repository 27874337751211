import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";

import {
    getCoinName
} from "../../helper/networkdata";
import { approve } from "../../ContractActions/bep20Actions";
import { toastAlert } from "../../helper/toastAlert"

import config from "../../config/config";
import { useTranslation } from "react-i18next";
import { checkNetwork } from "../../helper/connection";

import {
    formatNumber
} from "../../helper/custommath";

var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

var initial = {
    pay: 0,
    fromsymbol: "",
    fromaddress: "",
    fromdecimal: 0
}

export default function UnlockTokenModal1(props) {
    let { wrappedDetails, getLimitPriceData1, spender } = props;
    const { t } = useTranslation();
    const walletConnection = useSelector((state) => state.walletConnection);
    const [loading, setloading] = useState(false);
    const [tokenName, settokenName] = useState("");
    const [orderInfos, setorderInfos] = useState(initial);

    useEffect(() => {
        getTokenName(wrappedDetails);
    }, [wrappedDetails]);

    function getTokenName(wrappedDetails) {
        if (wrappedDetails) {
            setorderInfos(wrappedDetails)
            if (wrappedDetails && wrappedDetails.fromaddress) {
                var cName = getCoinName(walletConnection.network);
                var coinSym =
                    wrappedDetails.fromaddress.toLowerCase() === coinAddress
                        ? "W" + cName
                        : wrappedDetails.fromsymbol;
                settokenName(coinSym);
            }
        }

    }

    async function triggerApprove() {
        setloading(true);

        //getLimitPriceData1(orderInfos.pay);
        try {

            var { isNetwork, networkname } = await checkNetwork();
            if (!isNetwork) {
                setloading(false);
                toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
                return;
            }

            var nwrk = walletConnection.network;
            //var spender = config.middlreware[nwrk];
            var coinAddr =
                orderInfos.fromaddress.toLowerCase() === coinAddress &&
                    config &&
                    config.coin &&
                    config.coin[nwrk]
                    ? config.coin[nwrk]
                    : orderInfos.fromaddress;

            var { status, approvalAmt, error } = await approve(coinAddr, spender);
            var deci = parseFloat(orderInfos.fromdecimals);
            var chkAmt = parseFloat(approvalAmt) / 10 ** deci;

            if (error) {
                toastAlert("error", t(error), "approval");
                setloading(false);
                return;
            } else if (orderInfos.pay > chkAmt) {
                toastAlert("error", t('INSUFFICIENT_APPROVED_AMT'), "approval");
                setloading(false);
                return;
            }

            if (status) {
                getLimitPriceData1(orderInfos.pay, orderInfos.rate);
                window.$("#confirm_approve_modal1").modal("hide");
            }
            setloading(false);
        } catch (err) {
            var errMsg = (err && err.message) ? err.message : err;
            var pos = errMsg.search("User denied");
            var msg = "Please try again later";
            if (pos >= 0) {
                msg = "Confirmation rejected";
            }
            toastAlert("error", t(msg), "approval");
            console.log(err, "errerrerrerr");
            setloading(false);
        }
    }


    return (
        <>
            <div
                className="modal fade primary_modal"
                id="confirm_approve_modal1"
                tabIndex="-1"
                aria-labelledby="confirm_approve_modal1Label"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header pb-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body text-center">
                            <img
                                src={require(`../../assets/images/unlocked.png`)}
                                alt="Unlock" width="80" className="mb-3"
                            />
                            <h5>{t('UNLOCK')} {tokenName}</h5>
                            <p>
                                {t('NEED_APPROVAL_MOVE')} {(orderInfos && orderInfos.pay) ? formatNumber(orderInfos.pay, 8) : 0} {tokenName}{" "}
                                {t('ON_UR_BEHALF')}
                            </p>
                            <div className="text-center">
                                <button
                                    className="connect_wallet_btn w-100"
                                    onClick={() => triggerApprove()}
                                    disabled={loading}
                                >
                                    {t('UNLOCK')} {tokenName}
                                    {loading && <i className="fas fa-spinner fa-spin"></i>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}