import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import TokenList from "../components/TokenList";
import AddToken from "../components/AddToken";
import Settings from "../components/Settings";
import Gasfee from "../components/Gasfee";
import ConfirmSwap from "../components/ConfirmSwap";
import SwapMenu from "../components/swapMenu";

import config from "../config/config";
import { getRoutes } from "../Api/SwapActions";
import { getFeeInfo } from "../Api/TokenActions";
import {
  setswapToken,
  setIsMarket,
  setIsRoute,
  setSwapData,
} from "../reducers/Actions";
import { approve } from "../ContractActions/bep20Actions";
import {
  getMinumumReceived,
  getPriceImpact
} from "./Calculation";
import {
  validateAllowanceBalance,
  getUserBalances,
  getRecievedFeeCal,
} from "./swap-details";
import {
  formatNumber,
  checkIsAddress,
  getParameterByName,
  multiplyValue,
  numberFloatOnly,
} from "../helper/custommath";
import {
  getTokenLogo,
  getTxLink
} from "../helper/networkdata";

import { checkNetwork } from "../helper/connection";
import { toastAlert } from "../helper/toastAlert";
import { getEstimateSwapFee } from "../ContractActions/swapActions";


const initialState = {
  fromaddress: "",
  fromdecimal: 0,
  fromsymbol: "",
  fromlogo: "",
  fromamount: "",
  fromchainId: 0,
  fromdisplay: "",
  toaddress: "",
  todecimal: 0,
  tosymbol: "",
  tologo: "",
  toamount: "",
  todisplay: "",
  tochainId: 0,
  minimumrecevied: 0,
  priceImpact: 0,
  srcUSD: "",
  destUSD: "",
  priceRoute: "",
  gasCostUSD: "",
  fromRate: "",
  toRate: "",
  gasCost: "",
};

var setintervaltime = new Date();
var enterAmt = 1;
var enterId = "from";

export default function Swapsection(props) {
  var { loadMarkets } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const swapToken = useSelector((state) => state.swapToken);
  const walletConnection = useSelector((state) => state.walletConnection);
  const settings = useSelector((state) => state.settings);
  const gasValue = useSelector((state) => state.gasValue);


  const [swapDetails, setswapDetails] = useState(initialState);
  const [isLoad, setisLoad] = useState(false);
  const [enterData, setenterData] = useState({ amount: 1, id: "from" });
  const [currentId, setcurrentId] = useState("from");
  const [isallowanceerr, setisallowanceerr] = useState(false);
  const [isbalanceerr, setisbalanceerr] = useState(false);
  const [usertokenBal, setusertokenBal] = useState({
    fromBalanceOf: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toBalance: 0,
  });
  const [approveLoad, setapproveLoad] = useState(false);
  const [swaperror, setswaperror] = useState("");
  const [tokenTransferProxy, settokenTransferProxy] = useState("");
  const [contractAddress, setcontractAddress] = useState("");

  const [tokenrate, settokenrate] = useState({ srcUSD: 0, destUSD: 0 });
  const [priceImpact, setpriceImpact] = useState(0);
  const [takeraddress, settakeraddress] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [adminfee, setadminfee] = useState(0);
  const [partner, setpartner] = useState("");
  const [adminCommision, setadminCommision] = useState(0);
  const [minReceived, setminReceived] = useState(0);
  const [feeLoaed, setfeeLoaed] = useState(false);

  useEffect(() => {
    loadInitial();
    // eslint-disable-next-line
  }, [swapToken, walletConnection.address, walletConnection.network]);

  useEffect(() => {
    getFee();
    // eslint-disable-next-line
  }, [walletConnection.network]);

  useEffect(() => {
    loaduserBal();
    // eslint-disable-next-line
  }, [swapToken, walletConnection.address]);

  useEffect(() => {
    const timer = window.setInterval(async () => {
      var t = new Date(setintervaltime);
      t.setSeconds(t.getSeconds() + 10);
      if (t < new Date() && setintervaltime && parseFloat(enterAmt) > 0) {
        loadInitial();
      }
    }, 15000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [swapToken, walletConnection, enterAmt]);

  async function getFee() {
    if (walletConnection.network) {
      var { fee, adminAddress } = await getFeeInfo({
        chainId: walletConnection.network,
      });
      setadminfee(fee);
      setpartner(adminAddress);
      setfeeLoaed(true);
    }
  }

  async function loaduserBal() {
    //if (walletConnection && walletConnection.connect == "yes" && walletConnection.address && walletConnection.address != "") {

    getUserBalances(swapToken, walletConnection, setusertokenBal);
    // }
  }

  async function loadInitial() {
    setswapDetails(swapToken);

    if (
      swapToken &&
      swapToken.fromaddress &&
      swapToken.fromaddress !== "" &&
      walletConnection &&
      parseInt(walletConnection.network) > 0
    ) {

      var newObj = swapToken;
      var currId = enterId && enterId !== "" ? enterId : "from";
      var decimals = parseFloat(swapToken.fromdecimal);
      var ntId = localStorage.getItem("network");
      ntId = parseInt(ntId) > 0 ? parseInt(ntId) : 56;
      var connecId = walletConnection.network ? walletConnection.network : ntId;
      var network = (swapToken && swapToken.fromchainId) ? swapToken.fromchainId : connecId;

      var amt = enterAmt && parseFloat(enterAmt) > 0 ? parseFloat(enterAmt) : 1;

      var side = currId === "from" ? "SELL" : "BUY";

      Object.assign(newObj, {
        network: network,
        amount: multiplyValue(amt, 10 ** decimals),
        side: side,
        id: currId,
      });
      setisLoad(true);

      await getSwapData(newObj);
      enterAmt = amt;
      enterId = currId;
      setenterData({ amount: amt, id: currId });
      try {
        document.getElementById(currId).value = amt;
      } catch (err) { }
    }
  }

  async function getSwapData(newObj) {

    setintervaltime = new Date();
    dispatch(setIsMarket({ isList: false }));
    dispatch(setIsRoute({ status: false }));

    var errMsg = "";
    if (newObj && newObj.network && parseFloat(newObj.network) > 0) {

      var { result, error } = await getRoutes(newObj);

      setisbalanceerr(false);
      setisallowanceerr(false);
      setswaperror("");
      errMsg = "";
      if (error && error !== "") {
        var errMsg = "...";
        if (error === "ESTIMATED_LOSS_GREATER_THAN_MAX_IMPACT") {
          errMsg = "Price impact to high";
        } else if (error === "No routes found with enough liquidity") {
          errMsg = "Insufficient liquidity";
        } else if (error === "Invalid Amount") {
          console.log(document.getElementById("from").value, '*****************')
          errMsg = document.getElementById("from").value
            ? "Invalid Amount"
            : t("ENTER_AMT");
        }
      }

      setswaperror(errMsg);
      if (result && result.srcAmount) {
        var fromdisplay =
          newObj.id === "from"
            ? parseFloat(result.srcAmount) / 10 ** result.srcDecimals
            : (parseFloat(result.srcAmount) / 10 ** result.srcDecimals).toFixed(
              6
            );

        var todisplay =
          newObj.id === "to"
            ? parseFloat(result.destAmount) / 10 ** result.destDecimals
            : parseFloat(result.destAmount) / 10 ** result.destDecimals;

        let rate = fromdisplay / todisplay;
        let rate1 = todisplay / fromdisplay;

        var feeVal = adminfee;
        if (feeVal == 0) {
          var { fee, adminAddress } = await getFeeInfo({
            chainId: walletConnection.network,
          });
          setadminfee(fee);
          setpartner(adminAddress);
          feeVal = fee;
        }

        var { commision, receivedAmt } = getRecievedFeeCal(todisplay, feeVal);

        setadminCommision(commision);
        setminReceived(receivedAmt);
        receivedAmt = formatNumber(receivedAmt, 8);
        todisplay = receivedAmt;

        //var srcusd = formatNumber(result.destAmount / result.srcAmount, 5);
        //var dstusd = formatNumber(result.srcAmount / result.destAmount, 5);
        //settokenrate({ srcUSD: srcusd, destUSD: dstusd });

        let data = {
          fromamount: result.srcAmount,
          fromdisplay,
          toamount: result.destAmount,
          todisplay,
          id: enterData.id === "" ? "from" : enterData.id,
          slippageValue:
            settings && settings.slippage ? settings.slippage : 0.5,
        };

        var minimumrecevied = getMinumumReceived(data);

        data.minimumrecevied =
          minimumrecevied > 0 ? formatNumber(minimumrecevied, 5) : 0;
        var priceImpact = getPriceImpact(result);
        data.priceImpact = priceImpact > 0 ? priceImpact.toFixed(2) : 0;
        data.srcUSD =
          result && parseFloat(result.srcUSD) > 0
            ? parseFloat(result.srcUSD).toFixed(2)
            : 0;
        data.destUSD =
          result && parseFloat(result.destUSD) > 0
            ? parseFloat(result.destUSD).toFixed(2)
            : 0;
        settokenrate({ srcUSD: data.srcUSD, destUSD: data.destUSD });
        data.priceRoute = result;
        data.gasCostUSD =
          result && parseFloat(result.gasCostUSD) > 0
            ? parseFloat(result.gasCostUSD).toFixed(2)
            : 0;
        data.fromRate = rate.toFixed(4);
        data.toRate = rate1.toFixed(4);
        data.gasCost =
          result && parseFloat(result.gasCost) > 0
            ? parseFloat(result.gasCost)
            : 0;
        try {
          if (newObj.id === "from") {
            document.getElementById("to").value = receivedAmt;
          } else if (newObj.id === "to") {
            document.getElementById("from").value = fromdisplay;
          }

        } catch (err) {

        }

        var nw = parseInt(walletConnection.network);
        var router = result.tokenTransferProxy;
        var middlreware = config.middlreware[nw];
        var alloAddr = router;
        var { fromAllowance, fromBalance } = await validateAllowanceBalance(
          swapToken,
          walletConnection,
          alloAddr
        );

        if (fromBalance < fromdisplay) {
          setisbalanceerr(true);
        } else if (
          fromAllowance < fromdisplay &&
          result &&
          swapToken.fromaddress.toLowerCase() !==
          "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          setisallowanceerr(true);
        }
        var swapTx = { ...swapToken, ...data };
        setswapDetails(swapTx);
        dispatch(setSwapData(swapTx));
        var swapInfo = {
          fromdecimal: swapToken.fromdecimal,
          fromsymbol: swapToken.fromsymbol,
          fromaddress: swapToken.fromaddress,
          fromlogo: swapToken.fromlogo,
          fromchainId: swapToken.fromchainId,
          todecimal: swapToken.todecimal,
          tosymbol: swapToken.tosymbol,
          tologo: swapToken.tologo,
          toaddress: swapToken.toaddress,
          tochainId: swapToken.tochainId,
          srcAmount: result.srcAmount,
          destAmount: result.destAmount,
          gasUSD: result.gasCostUSD,
          contractMethod: result.contractMethod,
        };

        settokenTransferProxy(result.tokenTransferProxy);
        setcontractAddress(result.contractAddress);

        var allMarkets = result && result.others ? result.others : [];
        var bestRoute = result && result.bestRoute ? result.bestRoute : [];
        try {
          loadMarkets(allMarkets, swapInfo, bestRoute);
        } catch (err) { }
      } else {
        try {
          loadMarkets([], {}, []);
        } catch (err) {
          setisbalanceerr(false);
          setisallowanceerr(false);
        }
        dispatch(setIsMarket({ isList: true }));
        dispatch(setIsRoute({ status: true }));
      }

      setTimeout(function () {
        setisLoad(false);
      }, 1200);
    }
  }

  const debouncedSearch = debounce(function (e) {
    setswaperror("");
    const { id, value } = e.target;
    swapCalculation(id, value);
  }, 500);

  const inputChange = async (event) => {
    setswaperror("");
    var id = event.target.id;
    var value = event.target.value;
    var status = await numberFloatOnly(value);
    //console.log(status, "statusstatusstatus");

    if (status && parseFloat(value) > 0) {
      debouncedSearch(event);
    } else if (status) {
      document.getElementById(id).value = value;
      if (id == "from") {
        document.getElementById("to").value = "";
      } else {
        document.getElementById("from").value = "";
      }
      setisbalanceerr(false);
      setisallowanceerr(false);
      setswaperror("");
      var resetInfo = {
        swapDetails,
      };
      var resetInfo = { ...swapDetails, ...{ fromdisplay: "", todisplay: "" } };
      setswapDetails(resetInfo);

    } else {
      setisbalanceerr(false);
      setisallowanceerr(false);
      setswaperror("");
      var resetInfo = {
        swapDetails,
      };
      var resetInfo = { ...swapDetails, ...{ fromdisplay: "" } };
      setswapDetails(resetInfo);
      document.getElementById("to").value = "";
      document.getElementById("from").value = "";

    }
  };

  function swapCalculation(id, value) {
    enterAmt = value;
    enterId = id;

    setenterData({ amount: value, id: id });

    if (id === "from") {
      document.getElementById("to").value = "";
    } else if (id === "to") {
      document.getElementById("from").value = "";
    }



    setisLoad(true);
    var data = { fromdisplay: value };
    if (id === "to") {
      data = { todisplay: value };
    }
    setswapDetails({ ...swapDetails, ...data });
    var newObj = swapToken;
    var decimals =
      id === "from"
        ? parseFloat(swapToken.fromdecimal)
        : parseFloat(swapToken.todecimal);
    var network = walletConnection.network ? walletConnection.network : 56;
    var side = id === "from" ? "SELL" : "BUY";
    Object.assign(newObj, {
      network: network,
      amount: multiplyValue(value, 10 ** decimals),
      side: side,
      id: id,
    });

    getSwapData(newObj);
  }

  function interChange() {
    console.log("interChange");
    var swapData = {
      fromaddress: swapToken.toaddress,
      fromdecimal: swapToken.todecimal,
      fromsymbol: swapToken.tosymbol,
      fromname: swapToken.toname,
      fromlogo: swapToken.tologo,
      toaddress: swapToken.fromaddress,
      todecimal: swapToken.fromdecimal,
      tosymbol: swapToken.fromsymbol,
      tologo: swapToken.fromlogo,
      toname: swapToken.fromname,
    };

    var id = enterData.id === "from" ? "to" : "from";

    var value = enterData.amount;
    setenterData({ amount: value, id: id });
    dispatch(setswapToken(swapData));

    if (id === "from") {
      document.getElementById("to").value = "";
    } else if (id === "to") {
      document.getElementById("from").value = "";
    }
  }

  function selectedType(id) {
    setcurrentId(id);
  }

  async function approveToken() {

    var { isNetwork, networkname } = await checkNetwork();
    if (!isNetwork) {
      toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
      return;
    }

    if (
      location.pathname === "/transfer" ||
      getParameterByName("page") == "transfer"
    ) {
      var network = walletConnection.network;
      var isAddr = checkIsAddress(network, takeraddress);
      seterrorMsg("");
      if (takeraddress == "" || !isAddr) {
        seterrorMsg("Enter valid transfer to address");
        return;
      }
    }

    var nw = parseInt(walletConnection.network);
    var router = config.router[nw];
    var middlreware = config.middlreware[nw];
    var alloAddr = tokenTransferProxy;
    console.log(middlreware, "middlrewaremiddlreware");
    setapproveLoad(true);
    var { approvalAmt } = await approve(swapToken.fromaddress, alloAddr);
    setapproveLoad(false);
    if (parseFloat(approvalAmt) >= parseFloat(swapDetails.fromamount)) {
      setisallowanceerr(false);
    }
  }

  function swapPageClick() {
    enterAmt = 0;
    try {
      document.getElementById("to").value = "";
      document.getElementById("from").value = "";
    } catch (err) {

    }
    let data = {
      fromamount: 0,
      fromdisplay: 0,
      toamount: 0,
      todisplay: 0,
      id: "from",
      slippageValue:
        settings && settings.slippage ? settings.slippage : 0.5,
      minimumrecevied: 0,
      priceImpact: 0,
      srcUSD: 0,
      destUSD: 0
    };
    setswapDetails({ ...swapDetails, ...data });
    loaduserBal();
  }

  function reCheckLoad() {
    console.log("reCheckLoad")
    loadInitial();
    loaduserBal();
  }

  function refresh() {
    loadInitial();
    loaduserBal();
  }

  const handlePaste = async (event) => {
    seterrorMsg("");
    try {
      const text = await navigator.clipboard.readText();

      var network = walletConnection.network;
      var isAddr = checkIsAddress(network, text);
      settakeraddress("");
      if (isAddr) {
        settakeraddress(text);
      }
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  const inputChange1 = async (event) => {
    var value = event.target.value;
    var network = walletConnection.network;
    var isAddr = checkIsAddress(network, value);
    settakeraddress("");
    if (isAddr) {
      settakeraddress(value);
    }
  };

  function removeAddress() {
    settakeraddress("");
  }

  function openSwap() {
    if (
      location.pathname === "/transfer" ||
      getParameterByName("page") == "transfer"
    ) {
      var network = walletConnection.network;
      var isAddr = checkIsAddress(network, takeraddress);
      seterrorMsg("");
      if (takeraddress == "" || !isAddr) {
        seterrorMsg("Enter valid transfer to address");
        return;
      }
    }
    window.$("#confirm_swap_modal").modal("show");
  }

  async function selectMax() {


    let fromBalanceOf =
      usertokenBal && usertokenBal.fromBalanceOf
        ? parseFloat(usertokenBal.fromBalanceOf)
        : 0;
    let tokenDeci = parseFloat(swapToken.fromdecimal);
    let value = fromBalanceOf / 10 ** tokenDeci;
    if (parseFloat(value) > 0) {
      setisbalanceerr(false);
      if (
        swapDetails.fromaddress.toLowerCase() ==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        var gasCost = (swapDetails && swapDetails.gasCost) ? parseFloat(swapDetails.gasCost) : 0;

        console.log(gasCost, 'gasValuegasValue3', value)
        var { txFee } = await getEstimateSwapFee(gasCost);
        txFee = txFee / 1e18;
        console.log(txFee, 'gasValuegasValue3aaa', value)
        if (txFee > value) {
          value = 0;
          setisbalanceerr(true);
        } else {
          value = value - txFee;
        }
      }
      console.log('gasValuegasValue4', value)
      if (value && value > 0) {
        value = formatNumber(value, 8);
        enterAmt = value;
        document.getElementById("from").value = value;
        swapCalculation("from", value.toString());
      } else if (
        swapDetails.fromaddress.toLowerCase() ==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        toastAlert("error", t("INSUFFICIENT_FOR_GASFEE"), "insufficient");
      }
    }

  }

  return (
    <>
      <div className="sticky">
        <div>
          <div className="swap_box">
            <div className="heading_block heading_block_chg">
              <SwapMenu />
              <div>
                {/* <i>
                  <img
                    src={require("../assets/images/refresh_icon.png")}
                    alt="Refresh"
                    onClick={() => refresh()}
                  />
                </i> */}
                {(location.pathname === "/transfer" ||
                  location.pathname === "/swap-classic" ||
                  location.pathname === "/swap-simple" ||
                  location.pathname === "/p2p") && (
                    <i data-bs-toggle="modal" data-bs-target="#settings_modal">
                      <img
                        src={require("../assets/images/settings_icon.png")}
                        alt="Settings"
                      />
                    </i>
                  )}
              </div>
            </div>
            <div className="swap_container swap_container_chg">
              <div className="swap_select_token_wrap">
                <div className="swap_select_token_inner">
                  <div>
                    <label>{t("YOU_PAY")}</label>
                    <label className="d-flex align-items-center">
                      <img
                        src={require("../assets/images/wallet_icon.png")}
                        alt="wallet"
                        className="me-2"
                      />
                      <span>{usertokenBal.fromBalance}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      className="swap_token_input text-start"
                      onChange={inputChange}
                      //onKeyUp={onKeyPressed}
                      id="from"
                      pattern="[0-9]*"
                      type="text"
                      min="0"
                      disabled={feeLoaed ? false : true}
                      autoComplete={"off"}
                    />
                    {enterData && enterData.id === "to" && isLoad && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    <div className="maxtoken_wrap">
                      <label onClick={() => selectMax()}>{t("MAX")}</label>
                      <div
                        className="swap_select_token"
                        data-bs-toggle="modal"
                        data-bs-target="#select_token_modal"
                        onClick={() => selectedType("from")}
                      >
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.fromlogo,
                              swapDetails.fromchainId
                            )}
                            alt={swapDetails.fromsymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.fromsymbol}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="swap_token_bal">{swapDetails.fromname}</div>
                    <div className="swap_token_bal">~${swapDetails.srcUSD}</div>
                  </div>
                </div>
              </div>

              {isLoad ? (
                <div className="swap_arrow_container">
                  <img
                    src={require("../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
              ) : (
                <div
                  className="swap_arrow_container"
                  onClick={() => interChange()}
                >
                  <img
                    src={require("../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
              )}

              <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
                <div className="swap_select_token_inner">
                  <div>
                    <label>{t("YOU_RECEIVE")}</label>
                    <label className="d-flex align-items-center">
                      <img
                        src={require("../assets/images/wallet_icon.png")}
                        alt="wallet"
                        className="me-2"
                      />
                      <span>{usertokenBal.toBalance}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      className="swap_token_input text-start"
                      //onChange={inputChange}
                      id="to"
                      pattern="[0-9]*"
                      type="number"
                      min="0"
                      disabled={true}
                    />
                    {enterData && enterData.id === "from" && isLoad && (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                    <div
                      className="swap_select_token swap_select_token_nobg"
                      data-bs-toggle="modal"
                      data-bs-target="#select_token_modal"
                      onClick={() => selectedType("to")}
                    >
                      <div className="swap_select_token_option">
                        <img
                          src={getTokenLogo(
                            swapDetails.tologo,
                            swapDetails.tochainId
                          )}
                          alt={swapDetails.tosymbol}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = config.defaultLogo;
                          }}
                        />
                        <span className="swap_token_title">
                          {swapDetails.tosymbol}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="swap_token_bal">{swapDetails.toname}</div>
                    <div className="swap_token_bal">
                      ~${swapDetails.destUSD}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(location.pathname === "/transfer" ||
              getParameterByName("page") == "transfer") && (
                <>
                  <div className="mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <label className="text-white">{t("TRANSFER_TO")}</label>
                      {takeraddress && takeraddress !== "" && (
                        <label className="txt_yellow">
                          <a
                            href={
                              getTxLink(swapDetails.fromchainId) +
                              "address/" +
                              takeraddress
                            }
                            target="_blank"
                          >
                            {t("VERIFY")}
                          </a>
                        </label>
                      )}
                    </div>
                    <div className="taker_address_inpgrp">
                      <input
                        className="swap_token_input text-start"
                        id="transferaddress"
                        type="text"
                        placeholder={`${t("RECEIVERS")}  BSC ${t(
                          "WALETADDRESS"
                        )}`}
                        onChange={inputChange1}
                        value={takeraddress}
                      />
                      {!takeraddress || takeraddress == "" ? (
                        <button onClick={() => handlePaste()}>
                          {t("PASTE")}
                        </button>
                      ) : (
                        <button onClick={() => removeAddress()}>
                          {t("REMOVE")}
                        </button>
                      )}
                    </div>
                    {errorMsg && errorMsg !== "" && (
                      <p className="text-danger mb-1 mt-2 text-center">
                        {errorMsg}
                      </p>
                    )}
                  </div>

                  <div className="otc_disclaimer">
                    <div>
                      <h5>{t("DISCLAIMER")}</h5>
                      <img
                        src={require("../assets/images/info_icon.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <p className="yellow_txt">{t("FEATURE_TO_TRANSFER")}</p>
                    <p>{t("IMPOSSIBLE_TO_RETRIEVE")}</p>
                  </div>
                </>
              )}

            {walletConnection && walletConnection.connect === "no" ? (
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  {t("CONNECT_WALLET")}
                </button>
              </div>
            ) : swaperror && swaperror != "" ? (
              <div className="text-center">
                <button className="connect_wallet_btn w-100">
                  {swaperror}
                </button>
              </div>
            ) : isbalanceerr ? (
              <div className="text-center">
                <button className="connect_wallet_btn w-100">
                  {t("SRY_INSUFFICIENT")} {swapDetails.fromsymbol}{" "}
                  {t("BALANCE")}
                </button>
              </div>
            ) : isallowanceerr ? (
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => approveToken()}
                >
                  {t("APPROVE")}
                  {approveLoad && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
            ) : swapDetails.fromdisplay === "" ||
              swapDetails.fromdisplay <= 0 ? (
              <div className="text-center">
                <button className="connect_wallet_btn w-100">
                  {t("ENTER_AMT")}
                </button>
              </div>
            ) : parseFloat(swapDetails.fromdisplay) > 0 &&
              parseFloat(swapDetails.todisplay) > 0 ? (
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  disabled={isLoad}
                  onClick={() => openSwap()}
                >
                  {isLoad ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : location.pathname === "/transfer" ? (
                    t("SWAP_TRANSFER")
                  ) : (
                    t("SWAP")
                  )}
                </button>
              </div>
            ) : (
              <div className="text-center">
                <button className="connect_wallet_btn w-100">
                  <i className="fas fa-spinner fa-spin"></i>
                </button>
              </div>
            )}
            <div className="swap_info">
              <div>
                <span>
                  {swapDetails && swapDetails.side == "SELL"
                    ? t("MINIMUM_RECEIVED")
                    : t("MAX_TO_PAY")}
                </span>
                <span>
                  {swapDetails.minimumrecevied} {swapDetails.tosymbol}
                </span>
              </div>
              <div>
                <span>{t("SLIPPAGE_TOLERANCE")}</span>
                <span>{settings.slippage}%</span>
              </div>
              <div>
                <span>
                  {t("PRICE_IMPACT")}
                  <i className="far fa-question-circle ms-1" id="maxbase"></i>
                </span>
                <ReactTooltip
                  anchorId="maxbase"
                  place="right"
                  content={t("THE_DIFFERENT_BETWEEN_MARKET_PRICE")}
                />
                {swapDetails &&
                  swapDetails.priceImpact &&
                  parseFloat(swapDetails.priceImpact) < 0 ? (
                  <span style={{ color: "red" }}>
                    {swapDetails.priceImpact}%
                  </span>
                ) : (
                  <span style={{ color: "green" }}>
                    {swapDetails.priceImpact}%
                  </span>
                )}
              </div>
              <div>
                <span>{t("NETWORK_FEES")}</span>
                <span>
                  <span className="refund_txt">0% {t("REFUND")}</span>~ $0.33
                </span>
              </div>
            </div>
            {(location.pathname === "/transfer" ||
              location.pathname === "/swap-classic" ||
              location.pathname === "/swap-simple" ||
              location.pathname === "/p2p") && (
                <Gasfee
                  fromRate={swapDetails.fromRate}
                  toRate={swapDetails.toRate}
                  gasCostUSD={swapDetails.gasCostUSD}
                  srcSymbol={swapDetails.fromsymbol}
                  destSymbol={swapDetails.tosymbol}
                />
              )}
          </div>
        </div>
      </div>

      <TokenList id={currentId} />
      <AddToken />

      <ConfirmSwap
        swapDetails={swapDetails}
        walletConnection={walletConnection}
        enterData={enterData}
        settings={settings}
        TokenModalClick={swapPageClick}
        reCheck={reCheckLoad}
        setisLoad={setisLoad}
        isLoad={isLoad}
        priceImpact={priceImpact}
        tokenrate={tokenrate}
        contractAddress={contractAddress}
        takeraddress={takeraddress}
        tokenTransferProxy={tokenTransferProxy}
        adminfee={adminfee}
        partner={partner}
        adminCommision={adminCommision}
        minReceived={minReceived}
        feeLoaed={feeLoaed}
      />

      <Settings reCheck={reCheckLoad} />
    </>
  );
}
