var network = {
    binance: 56,
    ethereum: 1,
    polygon: 137,
    avalanche: 43114,
    fantom: 250,
    arbitrum: 42161,
    optimism: 10,
    huobi: 128,
    base: 8453
}
export default network;