var router = {
    1: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    56: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    137: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    43114: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    250: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    42161: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    10: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    128: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    8453: "0x59C7C832e96D2568bea6db468C1aAdcbbDa08A52"
}
export default router;