export default {
  ABOUT_NEW_FEATURES: "om de nya funktionerna.",
  ACCEPT: "Acceptera",
  ACTIVE_ORDERS: "Aktor",
  ADD_TOKEN: "Lägg till ett symbol",
  AGGRESSIVE: "Aggressiv",
  AND: "och",
  APPROVE: "Godkänna",
  ASSETS_ON_WAY: "Dina tillgångar är på väg och bör komma in",
  AUTO: "Bil",
  AVOID_TRANSFER:
    "Undvik att överföra till en centraliserad utbytesadress (Binance, Huobi ...). Det är inte en stödd funktion",
  BALANCE: "Balans",
  BALANCE_ONE: "Balans:",
  BLOG: "Otc",
  BRIDGE: "Bro",
  CANCEL: "Annullera",
  CANCELLATION_TRANSACTION_FEE:
    "Avbokning av en begränsningsorder innebär att betala transaktionsavgifterna.",
  CANCEL_ALL: "Avbryta alla",
  CANCEL_LIMIT_NOT_FILLED:
    "Du kan avbryta en gränsorder så länge beställningen inte har fyllts ännu.",
  CANCEL_LIMIT_ORDER: "Avbryt gränsordern",
  CANCEL_ORDER: "Avbryt beställning",
  CANCEL_SELECTED: "Avbryt vald",
  CHAINS: "Blogga",
  CHOOSE_NETWORK: "Välj nätverk",
  CLASSIC_MODE: "Klassiskt läge",
  CLEAR: "Klar",
  CONFIRM: "Bekräfta",
  CONFIRMATION_REJECTED: "Bekräftelse avvisad.!",
  CONFIRM_SWAP: "Bekräfta byte",
  CONNECT_WALLET: "Anslut plånbok",
  COPIED: "Kopierad",
  CURRENT: "Nuvarande",
  CUSTOM: "Beställnings",
  DAYS: "Dagar",
  DEFAULT: "Standard",
  DETAILS: "Information",
  DISCLAIMER: "varning",
  DISCONNECT_WALLET: "Koppla bort plånboken",
  DISMISS: "Avfärda",
  DUPLICATE_ORDER_SUBMIT: "Duplicerad ordning. Den här är redan inlämnat",
  ENTER_AMT: "Ange belopp",
  EXCHANGE: "Utbyta",
  EXPIRY: "UPPHÖRANDE",
  EXPIRY_SM: "Upphörande",
  FAILED_LOAD_CHART: "Det gick inte att ladda diagrammet",
  FIND_BY_TOKEN_OR_STATUS: "Hitta med token eller status",
  INSUFFICIENT_BALANCE: "Otillräcklig balans",
  INSUFFICIENT: "Otillräcklig",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "balans för att täcka befintliga beställningar balans mellan",
  RECEIVERS: "Mottagarens",
  WALETADDRESS: "Plånbokadress",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Vänligen acceptera användarvillkor och integritetspolicy",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker ska inte vara samma Taker -adress",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Slippage -tolerans bör vara mellan 0,1% och 15%",
  SEARCH_A_TOKEN: "Sök på ett symbol",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Din transaktion kommer att återgå om priset ändras ogynnsamt av mer än denna procentandel",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Justerar gaspriset för din transaktion.Högre GWEI = högre hastighet.",
  REVIEW_OTC_ORDER: "Granska OTC -beställning",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Utgångsperiod som fastställts av ordertillverkaren.Tidigare denna period kommer beställningen att löpa ut och arkiveras.",
  SEARCH_A_LIQUIDITY_SOURCES: "Sök på en likviditetskällor",

  FAST: "Snabb",
  FAST_AND_SECURE:
    "Det säkra, snabba och säkraste sättet att ta med tvärkedjor till",
  FEATURE_TO_TRANSFER:
    "Använd inte den här funktionen för att överföra dina tokens till en adress till ett centraliserat utbyte.",
  FEES: "Avgifter",
  FILL_ORDER: "Påfyllningsorder",
  FILL_OTC_ORDER: "Fyll OTC -beställning",
  FREE: "Fri",
  FROM: "Från",
  GAS_COST: "Gaskostnad",
  GAS_DESTINATION: "Gas på destinationskedjan",
  GAS_ON_DESTINATION: "Gas på destination",
  GAS_PRICE: "Gaspris",
  GOT_IT: "Jag fattar",
  GOT_PROBLEM_JUST: "Har ett problem? Bara komma i kontakt.",
  HIGH: "Hög",
  HOURS: "Timme",
  IMPORT: "Importera",
  IMPOSSIBLE_TO_RETRIEVE:
    "Det är inte en stödd funktion. Kontrollera också adressen du fyller, alla tokens som skickas till fel adress kommer att vara omöjliga att hämta.",
  INSTANT: "Omedelbar",
  INSUFFICIENT_APPROVED_AMT: "Otillräckligt godkänt belopp",
  INVALID_TOKEN_ADDRESS: "Ogiltig tokenadress",
  KEEP_TRACK_NOTIFY:
    "Du kan hålla reda på det i din panelmeddelande. Vi kommer att meddela dig när det är klart.",
  KEEP_YOU_POSTED: ". Vi håller dig uppdaterad.",
  LEARN_MORE: "Lär dig mer",
  LEGACY: "Arv",
  LIMIT: "Begränsa",
  LIMIT_ORDER: "gränsöverskridande",
  LIMIT_PRICE: "Begränsa priset",
  LIMIT_PRICE_SM: "Begränsa priset",
  LIQUIDITY_SOURCES: "Likviditetskällor",
  LOW: "Låg",
  MAKER: "Tillverkare",
  MARKET: "Marknadsföra",
  MAX: "Max",
  MAX_BASE_FEE: "Max basavgift",
  MAX_S: "max",
  MAX_TO_PAY: "Max att betala",
  MINIMUM_RECEIVED: "Minimum mottaget",
  MINUTES: "Minuter",
  MONTHS: "Månad",
  NEED_APPROVAL_MOVE: "Vi behöver ditt godkännande för att flytta",
  NEED_TO_WRAP: "Du måste linda in",
  NETWORK_FEES: "Nätverksavgifter",
  NETWORK_SM: "nätverk.",
  NEVER: "Aldrig",
  NONE: "Ingen",
  NON_CUSTODIAL_LIMIT:
    "I den icke-vårdnadshavande gränsordningen. Det är ett tekniskt krav på",
  NORMAL: "Vanligt",
  NO_ORDERS_YET: "Du har inga beställningar än",
  NO_RECORDS: "Inga uppgifter",
  ON_UR_BEHALF: "å dina vägnar.",
  OOPS_WRONG: "Hoppsan! Något gick fel.!",
  OPEN: "Öppen",
  ORDER_DETAILS: "Orderdetaljer",
  ORDER_HISTORY: "Beställningshistorik",
  ORDER_PRICE: "Beställningspris",
  ORDER_ROUTING: "Beställning av routing",
  OTC: "Otc",
  PASTE: "Klistra",
  PAY: "Betala",
  PENDING_CONFIRM: "Väntande bekräftelse på plånbok.",
  PER: "per",
  PLEASE_CHOOSE_NETWORK: "Välj nätverk",
  PLEASE_LOGIN: "Vänligen logga in på",
  PLEASE_TRY_LATER: "Vänligen försök igen senare",
  POPULAR_TOKENS: "Populära tokens",
  PRICE: "Pris",
  PRICE_IMPACT: "Prispåverkan",
  PRIVACY_POLICY: "Integritetspolicy",
  RATE: "Betygsätta",
  READ_ACCEPT: "Jag läste och accepterar",
  RECEIVE: "Motta",
  RECEIVED_ORDERS: "Mottagna beställningar",
  REFUND: "ÅTERBETALNING",
  REMOVE: "Avlägsna",
  REVERSE_RATE: "Omvänd hastighet",
  REVIEW_TRADE: "Granskning",
  SELECTED: "vald",
  SELECT_ALL: "Välj alla",
  SELECT_FROM_INFO: "Välj från info",
  SELECT_NETWORK: "Välj ett nätverk",
  SELECT_TOKEN: "Välj ett symbol",
  SELECT_TOKENN: "Välj Token",
  SELECT_TO_INFO: "Välj att info",
  SETTINGS: "inställningar",
  SIMPLE_MODE: "Enkelt läge",
  SLIPPAGE: "Glidning",
  SLIPPAGE_TOLERANCE: "Glidningstolerans",
  SRY_INSUFFICIENT: "Tyvärr, otillräcklig",
  STATUS: "STATUS",
  STATUS_SM: "Status",
  STEP: "Steg",
  SUBMIT: "Skicka in",
  SUBMITTED_ORDERS: "Inlämnade beställningar",
  SUBMIT_DATE: "Skicka datum",
  SUCCESS_FILLED: "Framgångsrikt beställer fylld",
  SUCCESS_RECEIVED: "Framgångsrikt mottagen",
  SUCCESS_SUBMITTED_ORDER: "Framgångsrikt skickat in din beställning",
  SWAP: "Byta",
  SWAP_TRANSFER: "Swap & Transfer",
  SWITCH_NETWORK: "Switchnätverk",
  TAKER: "Tagare",
  TAKER_ADDRESS: "Takeradress",
  TERMS_OF_SERVICE: "Användarvillkor",
  TO: "Till",
  TOKEN_NOT_FOUND: "Token hittades inte",
  TOKEN_RECEIVE_SENT: "Token att ta emot kommer att skickas till",
  TRADE: "Handel",
  TRANSACTION_REJECTED_LATER: "Transaktion avvisas, försök igen senare",
  TRANSFER: "Överföra",
  TRANSFER_ALERT: "Överföring",
  TRANSFER_TO: "Överföra till",
  TX_COST: "TX -kostnad",
  UNLOCK: "Låsa upp",
  USER_GUIDE: "Användarguide",
  USE_ITAS: "och använd det som w",
  VERIFY: "Kontrollera",
  VIEW_EXPLORER: "Visa transaktion i Explorer",
  VIEW_ON: "Syn på",
  VIEW_TRANSACTION: "Visa transaktion",
  WRAP: "Slå in",
  YOUR_ADDRESS: "Din adress",
  YOUR_ORDER_CANCEL: "Din beställning avbryts",
  YOUR_WALLET: "Din plånbok",
  YOU_GET: "Du får",
  YOU_PAY: "Du betalar",
  YOU_RECEIVE: "Du får",
  YOU_WILL_RECEIVE: "Du kommer att få",
  ANYONE_CAN_CREATE:
    "Vem som helst kan skapa valfritt token, inklusive falska versioner av de befintliga tokens. Ta hand om. Vissa symboler och deras tekniska parametrar kan vara oförenliga med våra tjänster. Genom att importera denna anpassade token erkänner du och accepterar riskerna.",
  BY_SIGNING_MESSAGE:
    "Genom att underteckna detta meddelande bekräftar du att du inte kommer åt den här appen från eller är bosatt i USA eller något annat begränsat land. För mer information, läs vår",
  CONSTITUTION: "Konstitution",
  CURRENTLY_CONNECTED: "Du är för närvarande ansluten till",
  IMPORT_TOKEN: "Importera ett symbol",
  IN_YOUR_WALLET: "I din plånbok",
  MAKE_SURE_CHECKED:
    "Se till att du har kontrollerat tokenadressen och den är korrekt",
  ON: "på",
  PEOPLE: "människor",
  PLEASE_SWITCH: "Vänligen växla till",
  SELECTED_NOT_SUPPORTED:
    "Ändra nätverk i din plånbok, utvalda nätverk stöds inte",
  SIGN_FOR_ACCESS: "Skylt för åtkomst",
  SIGN_MESSAGE: "Underteckningsmeddelande",
  LAUNCH_APP: "Starta app",
  GETTING_STARTED: "Komma igång med HODL: Tutorials & Product förklarare",
  DEEP_DIVE: "Deepdive in i Hodl: Strategier och produktfunktioner",
  BUILD_HODL: "Byggande på HODL: Teknisk dokumentation",
  BLOG_HEADING:
    "Demystifying defi - allt du behöver veta på ett ställe. För nybörjare till avancerade användare och alla däremellan.",
  BLOG_PLACEHOLDER: "Sök efter produkten/ämnet du vill lära dig om",
  CHOOSE_TOPIC: "Välj ämne",
  TRY: "Prova",
  SEARCH_RESULTS: "sökresultat",
  SEARCH_TOPIC: "Sökämne",
  LOAD_MORE: "Ladda mer",
  SOCIAL_MEDIA: "Social Media",
  NEW: "Новички вперед",
  OLD: "Сначала старые",
  Z_A: "Сортировать название Z-A",
  A_Z: "Сортировать заголовок A-Z",
  RESULTS: "Полученные результаты",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "En basgasavgift beräknas automatiskt av nätverket och varierar från block till block. Ställ in det maximala beloppet för basavgiften du vill betala. En skillnad mellan den maximala basavgiften du ställer in och den faktiska basavgiften återbetalas när blocket har bryts.",
  CANCELLED_TRANSACTION: "Avbröt din transaktion",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Att skapa en gränsorder är en gratis operation för ordertillverkaren såvida de inte avbryter. Beställaren kommer att behöva betala för transaktionsavgifterna medan de fyller beställningen.",
  Cancelled: "Inställt",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Skillnad i värde mellan den bästa rutten och",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Aktivera det här alternativet om du har problem som byter ett symbol som har inbyggda avgifter för överföring.",
  Expired: "Utgånget",
  Filled: "Fylld",
  INSUFFICIENT_FOR_GASFEE: "Tyvärr, otillräckliga medel för gasavgift",
  Open: "Öppen",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Partiell fyllning gäller endast om en swap -rutt består av flera huvudvägsdelar. Det möjliggör delvis genomförande, vilket förhindrar transaktionen från att vändas om marknadspriset rör sig ur fördel.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Pris som fastställts av ordertillverkaren för att utföra sin beställning (token för att ta emot = x token för att betala)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Pris som beställs av ordertillverkaren för att utföra sin beställning (token att betala = y token för att ta emot",
  REVIEW_LIMIT_ORDER: "Granskningsgränsorder",
  SEARCH_BY_TOKEN: "Sök efter token eller adress",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Att ställa in en hög glidtolerans kan hjälpa transaktioner att lyckas, men du kanske inte får ett så bra pris. Använd med försiktighet.",
  STATUS_OF_THE_LIMIT_ORDER: "Status för gränsbeställningen",
  Suspended: "Upphängd",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Dessa avgifter betalas till nätverksgruvarna. Hodl kontrollerar inte avgifterna.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Skillnaden mellan marknadspriset och det est. Priset på grund av handelsstorlek",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Det minsta mottagna beloppet, annars återgår transaktionen",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Denna avgift går direkt till gruvarbetare som ett incitament att prioritera din transaktion.",
  TRANSACTION_REJECTED_TRY: "Transaktion avvisas, försök igen",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Överföring för det valda tokenet är inte tillgängligt i det här nätverket",
  YOU: "Du",
};
