export default {
  ABOUT_NEW_FEATURES: "tentang fitur baru.",
  ACCEPT: "Menerima",
  ACTIVE_ORDERS: "Pesanan aktif",
  ADD_TOKEN: "Tambahkan token",
  AGGRESSIVE: "Agresif",
  AND: "Dan",
  APPROVE: "Menyetujui",
  ASSETS_ON_WAY: "Aset Anda sedang dalam perjalanan dan harus tiba",
  AUTO: "Mobil",
  AVOID_TRANSFER:
    "Harap hindari transfer ke alamat pertukaran terpusat (Binance, Huobi ...). Ini bukan fitur yang didukung",
  BALANCE: "Keseimbangan",
  BALANCE_ONE: "Keseimbangan:",
  BLOG: "OTC",
  BRIDGE: "Menjembatani",
  CANCEL: "Membatalkan",
  CANCELLATION_TRANSACTION_FEE:
    "Pembatalan pesanan batas melibatkan pembayaran biaya transaksi.",
  CANCEL_ALL: "Batalkan semua",
  CANCEL_LIMIT_NOT_FILLED:
    "Anda dapat membatalkan pesanan batas selama pesanan belum diisi.",
  CANCEL_LIMIT_ORDER: "Batalkan Pesanan Batas",
  CANCEL_ORDER: "Batalkan pesanan",
  CANCEL_SELECTED: "Batalkan yang dipilih",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Pilih Jaringan",
  CLASSIC_MODE: "Mode klasik",
  CLEAR: "Jernih",
  CONFIRM: "Mengonfirmasi",
  CONFIRMATION_REJECTED: "Konfirmasi ditolak.!",
  CONFIRM_SWAP: "Konfirmasi pertukaran",
  CONNECT_WALLET: "Hubungkan Dompet",
  COPIED: "Disalin",
  CURRENT: "Saat ini",
  CUSTOM: "Kebiasaan",
  DAYS: "Hari -hari",
  DEFAULT: "Bawaan",
  DETAILS: "Detail",
  DISCLAIMER: "Penafian",
  DISCONNECT_WALLET: "Lepaskan dompet",
  DISMISS: "Membubarkan",
  DUPLICATE_ORDER_SUBMIT: "Urutan duplikat. Yang ini sudah dikirimkan",
  ENTER_AMT: "Masukan jumlah",
  EXCHANGE: "Menukarkan",
  EXPIRY: "Kedaluwarsa",
  EXPIRY_SM: "Kedaluwarsa",
  FAILED_LOAD_CHART: "Gagal memuat grafik",
  FIND_BY_TOKEN_OR_STATUS: "Temukan dengan token atau status",
  INSUFFICIENT_BALANCE: "Keseimbangan yang tidak mencukupi",
  INSUFFICIENT: "Tidak memadai",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "saldo untuk mencakup keseimbangan pesanan yang ada",
  RECEIVERS: "Penerima",
  WALETADDRESS: "Alamat dompet",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Harap terima Ketentuan Layanan dan Kebijakan Privasi",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Pembuat Taker seharusnya tidak menjadi alamat pengambil yang sama",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Toleransi selip harus antara 0,1% dan 15%",
  SEARCH_A_TOKEN: "Cari token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Transaksi Anda akan kembali jika harga berubah secara tidak menguntungkan dengan persentase ini",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Menyesuaikan harga gas untuk transaksi Anda.GWEI lebih tinggi = kecepatan yang lebih tinggi.",
  REVIEW_OTC_ORDER: "Tinjau pesanan OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Periode kedaluwarsa yang ditetapkan oleh pembuat pesanan.Melewati periode ini, pesanan akan kedaluwarsa dan diarsipkan.",
  SEARCH_A_LIQUIDITY_SOURCES: "Cari sumber likuiditas",

  FAST: "Cepat",
  FAST_AND_SECURE:
    "Cara yang aman, cepat dan paling aman untuk membawa aset silang",
  FEATURE_TO_TRANSFER:
    "Harap jangan gunakan fitur ini untuk mentransfer token Anda ke alamat pertukaran terpusat.",
  FEES: "Biaya",
  FILL_ORDER: "Mengisi pesanan",
  FILL_OTC_ORDER: "Isi pesanan OTC",
  FREE: "Bebas",
  FROM: "Dari",
  GAS_COST: "Biaya gas",
  GAS_DESTINATION: "Gas di rantai tujuan",
  GAS_ON_DESTINATION: "Gas di tujuan",
  GAS_PRICE: "Harga gas",
  GOT_IT: "Mengerti",
  GOT_PROBLEM_JUST: "Punya masalah? Hubungi saja.",
  HIGH: "Tinggi",
  HOURS: "Jam",
  IMPORT: "Impor",
  IMPOSSIBLE_TO_RETRIEVE:
    "Ini bukan fitur yang didukung. Juga, harap periksa ulang alamat yang Anda isi, token apa pun yang dikirim ke alamat yang salah tidak mungkin untuk diambil.",
  INSTANT: "Instan",
  INSUFFICIENT_APPROVED_AMT: "Jumlah yang disetujui tidak cukup",
  INVALID_TOKEN_ADDRESS: "Alamat token tidak valid",
  KEEP_TRACK_NOTIFY:
    "Anda dapat melacaknya di pemberitahuan panel Anda. Kami akan memberi tahu Anda setelah selesai.",
  KEEP_YOU_POSTED: ". Kami akan terus mengabari Anda.",
  LEARN_MORE: "Belajarlah lagi",
  LEGACY: "Warisan",
  LIMIT: "Membatasi",
  LIMIT_ORDER: "Batasi pesanan",
  LIMIT_PRICE: "Batas harga",
  LIMIT_PRICE_SM: "Batas Harga",
  LIQUIDITY_SOURCES: "Sumber Likuiditas",
  LOW: "Rendah",
  MAKER: "Pembuat",
  MARKET: "Pasar",
  MAX: "Max",
  MAX_BASE_FEE: "Biaya Basis Max",
  MAX_S: "Max",
  MAX_TO_PAY: "Maks untuk membayar",
  MINIMUM_RECEIVED: "Minimum diterima",
  MINUTES: "Menit",
  MONTHS: "Bulan",
  NEED_APPROVAL_MOVE: "Kami membutuhkan persetujuan Anda untuk pindah",
  NEED_TO_WRAP: "Anda perlu membungkus",
  NETWORK_FEES: "Biaya jaringan",
  NETWORK_SM: "jaringan.",
  NEVER: "Tidak pernah",
  NONE: "Tidak ada",
  NON_CUSTODIAL_LIMIT:
    "dalam urutan batas non-penahan. Ini adalah persyaratan teknis pada",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Anda belum memiliki pesanan",
  NO_RECORDS: "Tidak ada catatan",
  ON_UR_BEHALF: "atas nama Anda.",
  OOPS_WRONG: "Ups! Ada yang tidak beres.!",
  OPEN: "Membuka",
  ORDER_DETAILS: "Detail pesanan",
  ORDER_HISTORY: "Sejarah pesanan",
  ORDER_PRICE: "Harga pesanan",
  ORDER_ROUTING: "Routing pesanan",
  OTC: "OTC",
  PASTE: "Tempel",
  PAY: "Membayar",
  PENDING_CONFIRM: "Konfirmasi yang tertunda di dompet.",
  PER: "per",
  PLEASE_CHOOSE_NETWORK: "Pilih Jaringan",
  PLEASE_LOGIN: "Harap masuk ke",
  PLEASE_TRY_LATER: "Silakan coba lagi nanti",
  POPULAR_TOKENS: "Token Populer",
  PRICE: "Harga",
  PRICE_IMPACT: "Dampak Harga",
  PRIVACY_POLICY: "Kebijakan pribadi",
  RATE: "Kecepatan",
  READ_ACCEPT: "Saya membaca dan menerima",
  RECEIVE: "Menerima",
  RECEIVED_ORDERS: "Menerima pesanan",
  REFUND: "PENGEMBALIAN DANA",
  REMOVE: "Menghapus",
  REVERSE_RATE: "Tingkat terbalik",
  REVIEW_TRADE: "Tinjau perdagangan",
  SELECTED: "terpilih",
  SELECT_ALL: "Pilih Semua",
  SELECT_FROM_INFO: "Pilih dari info",
  SELECT_NETWORK: "Pilih jaringan",
  SELECT_TOKEN: "Pilih token",
  SELECT_TOKENN: "Pilih token",
  SELECT_TO_INFO: "Pilih Info",
  SETTINGS: "Pengaturan",
  SIMPLE_MODE: "Mode sederhana",
  SLIPPAGE: "Kelicinan",
  SLIPPAGE_TOLERANCE: "Toleransi Slippage",
  SRY_INSUFFICIENT: "Maaf, tidak mencukupi",
  STATUS: "STATUS",
  STATUS_SM: "Status",
  STEP: "Melangkah",
  SUBMIT: "Kirim",
  SUBMITTED_ORDERS: "Pesanan yang dikirimkan",
  SUBMIT_DATE: "Tanggal Kirim",
  SUCCESS_FILLED: "Berhasil memesan",
  SUCCESS_RECEIVED: "Berhasil diterima",
  SUCCESS_SUBMITTED_ORDER: "Berhasil mengirimkan pesanan Anda",
  SWAP: "Menukar",
  SWAP_TRANSFER: "Tukar & Transfer",
  SWITCH_NETWORK: "Switch Network",
  TAKER: "Pengambil",
  TAKER_ADDRESS: "Alamat pengambil",
  TERMS_OF_SERVICE: "Ketentuan Layanan",
  TO: "Ke",
  TOKEN_NOT_FOUND: "Token tidak ditemukan",
  TOKEN_RECEIVE_SENT: "Token yang akan diterima akan dikirim ke",
  TRADE: "Berdagang",
  TRANSACTION_REJECTED_LATER: "Transaksi ditolak tolong coba lagi nanti",
  TRANSFER: "Transfer",
  TRANSFER_ALERT: "Peringatan transfer",
  TRANSFER_TO: "Transfer ke",
  TX_COST: "Biaya tx",
  UNLOCK: "Membuka kunci",
  USER_GUIDE: "Panduan pengguna",
  USE_ITAS: "dan menggunakannya sebagai w",
  VERIFY: "Memeriksa",
  VIEW_EXPLORER: "Lihat Transaksi di Explorer",
  VIEW_ON: "Lihat",
  VIEW_TRANSACTION: "Lihat transaksi",
  WRAP: "Membungkus",
  YOUR_ADDRESS: "Alamat Anda",
  YOUR_ORDER_CANCEL: "Pesanan Anda dibatalkan",
  YOUR_WALLET: "Dompetmu",
  YOU_GET: "Anda mendapatkan",
  YOU_PAY: "Anda membayar",
  YOU_RECEIVE: "Anda menerima",
  YOU_WILL_RECEIVE: "Anda akan menerima",
  ANYONE_CAN_CREATE:
    "Siapa pun dapat membuat token apa pun, termasuk versi palsu dari token yang ada. Berhati -hatilah. Beberapa token dan parameter teknis mereka mungkin tidak sesuai dengan layanan kami. Dengan mengimpor token khusus ini, Anda mengakui dan menerima risikonya.",
  BY_SIGNING_MESSAGE:
    "Dengan menandatangani pesan ini, Anda mengonfirmasi bahwa Anda tidak mengakses aplikasi ini dari, atau merupakan penduduk AS atau negara terbatas lainnya. Untuk informasi lebih lanjut, silakan baca kami",
  CONSTITUTION: "Constitutiondao",
  CURRENTLY_CONNECTED: "Anda saat ini terhubung ke",
  IMPORT_TOKEN: "Impor token",
  IN_YOUR_WALLET: "di dompet Anda",
  MAKE_SURE_CHECKED: "Pastikan Anda telah memeriksa alamat token dan itu benar",
  ON: "pada",
  PEOPLE: "Rakyat",
  PLEASE_SWITCH: "Harap beralih ke",
  SELECTED_NOT_SUPPORTED:
    "Harap ubah jaringan di dompet Anda, jaringan yang dipilih tidak didukung",
  SIGN_FOR_ACCESS: "Tanda untuk akses",
  SIGN_MESSAGE: "Pesan tanda",
  LAUNCH_APP: "Luncurkan aplikasi",
  GETTING_STARTED: "Memulai dengan HODL: Tutorial & Penjelasan Produk",
  DEEP_DIVE: "DeepDive Into Hodl: Fitur Strategi & Produk",
  BUILD_HODL: "Building on Hodl: Dokumentasi Teknis",
  BLOG_HEADING:
    "Demystifying Defi - Semua yang perlu Anda ketahui di satu tempat. Untuk pemula kepada pengguna tingkat lanjut, dan semua orang di antaranya.",
  BLOG_PLACEHOLDER: "Cari produk/topik yang ingin Anda pelajari",
  CHOOSE_TOPIC: "Pilih Jaringan",
  TRY: "MENCOBA",
  SEARCH_RESULTS: "Hasil Pencarian",
  SEARCH_TOPIC: "Topik pencarian",
  LOAD_MORE: "Muat lebih banyak",
  SOCIAL_MEDIA: "Media sosial",
  NEW: "Pertama Terbaru",
  OLD: "Yang tertua dulu",
  Z_A: "Urutkan judul Z-A",
  A_Z: "Urutkan judul A-Z",
  RESULTS: "Hasil",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Biaya gas dasar secara otomatis dihitung oleh jaringan dan bervariasi dari blok ke blok. Tetapkan jumlah maksimal dari biaya dasar yang ingin Anda bayar. Perbedaan antara biaya basis maks yang Anda tetapkan dan biaya dasar aktual akan dikembalikan setelah blok telah ditambang.",
  CANCELLED_TRANSACTION: "Membatalkan transaksi Anda",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Membuat pesanan batas adalah operasi gratis untuk pembuat pesanan kecuali mereka membatalkan. Pesanan pengambil akan diminta untuk membayar biaya transaksi saat mereka mengisi pesanan.",
  Cancelled: "Dibatalkan",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Perbedaan nilai antara rute terbaik dan",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Aktifkan opsi ini jika Anda mengalami masalah bertukar token yang memiliki biaya bawaan untuk ditransfer.",
  Expired: "Kedaluwarsa",
  Filled: "Dipenuhi",
  INSUFFICIENT_FOR_GASFEE: "Maaf, dana yang tidak mencukupi untuk biaya gas",
  Open: "Membuka",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Isi sebagian hanya berlaku jika rute swap terdiri dari beberapa bagian rute utama. Ini memungkinkan untuk eksekusi parsial, mencegah transaksi terbalik jika harga pasar tidak disukai.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Harga ditetapkan oleh pembuat pesanan untuk melaksanakan pesanan mereka (token untuk menerima = x token untuk membayar)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Harga ditetapkan oleh pembuat pesanan untuk melaksanakan pesanan mereka (token untuk membayar = y token untuk menerima",
  REVIEW_LIMIT_ORDER: "Ulasan Batas Pesanan",
  SEARCH_BY_TOKEN: "Cari dengan token atau alamat",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Menetapkan toleransi slippage yang tinggi dapat membantu transaksi berhasil, tetapi Anda mungkin tidak mendapatkan harga yang bagus. Gunakan dengan hati -hati.",
  STATUS_OF_THE_LIMIT_ORDER: "Status urutan batas",
  Suspended: "Tergantung",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Biaya ini dibayarkan kepada penambang jaringan. Hodl tidak mengontrol biaya.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Perbedaan antara harga pasar dan harga est karena ukuran perdagangan",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Jumlah minimum yang diterima, jika tidak transaksi akan dikembalikan",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Biaya ini langsung ke penambang sebagai insentif untuk memprioritaskan transaksi Anda.",
  TRANSACTION_REJECTED_TRY: "Transaksi Ditolak, Coba Lagi",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Transfer untuk token yang dipilih tidak tersedia di jaringan ini",
  YOU: "Anda",
};
