// import constant
import {
    BRIDGESETTINGS
} from '../constant';

var network = (localStorage.getItem("network") && parseFloat(localStorage.getItem("network"))) ? parseFloat(localStorage.getItem("network")) : 56;
const initialState = {
    bslippage: (localStorage.getItem("bslippage") &&
        parseFloat(localStorage.getItem("bslippage")) > 0) ? parseFloat(localStorage.getItem("bslippage")) : 0.5,
    bgasmode: localStorage.getItem("bgasmode") &&
        (localStorage.getItem("bgasmode") !== "") ? localStorage.getItem("bgasmode") : "None",
    bvalue: localStorage.getItem("bvalue-" + network) &&
        (localStorage.getItem("bvalue-" + network) !== "") ? localStorage.getItem("bvalue-" + network) : 0,
};

const settings = (state = initialState, action) => {

    switch (action.type) {
        case BRIDGESETTINGS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default settings;