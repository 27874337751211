export default {
  ABOUT_NEW_FEATURES: "over de nieuwe functies.",
  ACCEPT: "Aanvaarden",
  ACTIVE_ORDERS: "Actieve bestellingen",
  ADD_TOKEN: "Voeg een token toe",
  AGGRESSIVE: "Agressief",
  AND: "En",
  APPROVE: "Goedkeuren",
  ASSETS_ON_WAY: "Uw activa zijn onderweg en moeten binnenkomen",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Stap niet over naar een gecentraliseerd uitwisselingsadres (Binance, Huobi ...). Het is geen ondersteunde functie",
  BALANCE: "Evenwicht",
  BALANCE_ONE: "Evenwicht:",
  BLOG: "OTC",
  BRIDGE: "Brug",
  CANCEL: "Annuleren",
  CANCELLATION_TRANSACTION_FEE:
    "Annulering van een limietorder omvat het betalen van de transactiekosten.",
  CANCEL_ALL: "Verwijder alles",
  CANCEL_LIMIT_NOT_FILLED:
    "U kunt een limietorder annuleren zolang de bestelling nog niet is ingevuld.",
  CANCEL_LIMIT_ORDER: "Annuleer de limietorder",
  CANCEL_ORDER: "Annuleer bestelling",
  CANCEL_SELECTED: "Annuleer geselecteerd",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Kies netwerk",
  CLASSIC_MODE: "Klassieke modus",
  CLEAR: "Duidelijk",
  CONFIRM: "Bevestigen",
  CONFIRMATION_REJECTED: "Bevestiging afgewezen.!",
  CONFIRM_SWAP: "Bevestig swap",
  CONNECT_WALLET: "Verbind de portemonnee",
  COPIED: "Gekopieerd",
  CURRENT: "Huidig",
  CUSTOM: "Aangepast",
  DAYS: "Dagen",
  DEFAULT: "Standaard",
  DETAILS: "Details",
  DISCLAIMER: "Vrijwaring",
  DISCONNECT_WALLET: "Koppel de portemonnee los",
  DISMISS: "Afwijzen",
  DUPLICATE_ORDER_SUBMIT: "Dubbele bestelling. Deze is al ingediend",
  ENTER_AMT: "Voer een bedrag in",
  EXCHANGE: "Aandelenbeurs",
  EXPIRY: "Aflopen",
  EXPIRY_SM: "Aflopen",
  FAILED_LOAD_CHART: "Kan de grafiek niet laden",
  FIND_BY_TOKEN_OR_STATUS: "Zoek door token of status",
  INSUFFICIENT_BALANCE: "Onvoldoende balans",
  INSUFFICIENT: "Onvoldoende",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "evenwicht om bestaande bestellingen te dekken",
  RECEIVERS: "Ontvangers",
  WALETADDRESS: "Portemonnee",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Accepteer alstublieft voorwaarden voor servicevoorwaarden en privacybeleid",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker zou niet hetzelfde Taker -adres moeten zijn",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Slippentolerantie moet tussen 0,1% en 15% liggen",
  SEARCH_A_TOKEN: "Zoek een token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Uw transactie keert terug als de prijs ongunstig verandert door meer dan dit percentage",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Past de gasprijs aan voor uw transactie.Hogere Gwei = hogere snelheid.",
  REVIEW_OTC_ORDER: "Bekijk OTC -bestelling",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Vervalperiode ingesteld door de ordermaker.Na deze periode zal de bestelling vervallen en worden gearchiveerd.",
  SEARCH_A_LIQUIDITY_SOURCES: "Zoek een liquiditeitsbronnen",

  FAST: "Snel",
  FAST_AND_SECURE:
    "De veilige, snelle en meest veilige manier om cross-chain activa naar toe te brengen",
  FEATURE_TO_TRANSFER:
    "Gebruik deze functie niet om uw tokens over te dragen naar een adres van een gecentraliseerde uitwisseling.",
  FEES: "Vergoedingen",
  FILL_ORDER: "Volgorde",
  FILL_OTC_ORDER: "Vul OTC -bestelling",
  FREE: "Vrij",
  FROM: "Van",
  GAS_COST: "Gaskosten",
  GAS_DESTINATION: "Gas op bestemmingsketen",
  GAS_ON_DESTINATION: "Gas op bestemming",
  GAS_PRICE: "Gasprijs",
  GOT_IT: "Begrepen",
  GOT_PROBLEM_JUST: "Heb een probleem? Neem gewoon contact op.",
  HIGH: "Hoog",
  HOURS: "Uren",
  IMPORT: "Importeren",
  IMPOSSIBLE_TO_RETRIEVE:
    "Het is geen ondersteunde functie. Controleer ook het adres dat u vult, eventuele tokens die naar het verkeerde adres zijn verzonden, zijn onmogelijk op te halen.",
  INSTANT: "Ogenblik",
  INSUFFICIENT_APPROVED_AMT: "Onvoldoende goedgekeurd bedrag",
  INVALID_TOKEN_ADDRESS: "Ongeldig tokenadres",
  KEEP_TRACK_NOTIFY:
    "U kunt het bijhouden in uw paneelmelding. We zullen u op de hoogte stellen zodra het klaar is.",
  KEEP_YOU_POSTED: ". We zullen je op de hoogte houden.",
  LEARN_MORE: "Kom meer te weten",
  LEGACY: "Nalatenschap",
  LIMIT: "Begrenzing",
  LIMIT_ORDER: "beperkende volgorde",
  LIMIT_PRICE: "Beperk de prijs",
  LIMIT_PRICE_SM: "Beperk de prijs",
  LIQUIDITY_SOURCES: "Liquiditeitsbronnen",
  LOW: "Laag",
  MAKER: "Maker",
  MARKET: "Markt",
  MAX: "Maximaal",
  MAX_BASE_FEE: "Max Base Fee",
  MAX_S: "maximaal",
  MAX_TO_PAY: "Max om te betalen",
  MINIMUM_RECEIVED: "Minimaal ontvangen",
  MINUTES: "Minuten",
  MONTHS: "Maanden",
  NEED_APPROVAL_MOVE: "We hebben uw goedkeuring nodig om te verhuizen",
  NEED_TO_WRAP: "Je moet inpakken",
  NETWORK_FEES: "Netwerkkosten",
  NETWORK_SM: "netwerk.",
  NEVER: "Nooit",
  NONE: "Geen",
  NON_CUSTODIAL_LIMIT:
    "in de niet-aanpassingslimietvolgorde. Het is een technische vereiste voor de",
  NORMAL: "Normaal",
  NO_ORDERS_YET: "Je hebt nog geen bestellingen",
  NO_RECORDS: "Geen records",
  ON_UR_BEHALF: "namens u.",
  OOPS_WRONG: "Oeps! Er is iets misgegaan.!",
  OPEN: "Open",
  ORDER_DETAILS: "Bestel Details",
  ORDER_HISTORY: "Bestelgeschiedenis",
  ORDER_PRICE: "Bestelprijs",
  ORDER_ROUTING: "Bestelroutering",
  OTC: "OTC",
  PASTE: "Plakken",
  PAY: "Betalen",
  PENDING_CONFIRM: "In afwachting van bevestiging op portemonnee.",
  PER: "per",
  PLEASE_CHOOSE_NETWORK: "Kies netwerk",
  PLEASE_LOGIN: "Log in op",
  PLEASE_TRY_LATER: "Probeer het later opnieuw",
  POPULAR_TOKENS: "Populaire tokens",
  PRICE: "Prijs",
  PRICE_IMPACT: "Prijseffect",
  PRIVACY_POLICY: "Privacybeleid",
  RATE: "Tarief",
  READ_ACCEPT: "Ik lees en accepteer",
  RECEIVE: "Ontvangen",
  RECEIVED_ORDERS: "Ontvangen bestellingen",
  REFUND: "TERUGBETALING",
  REMOVE: "Verwijderen",
  REVERSE_RATE: "Omgekeerde tarief",
  REVIEW_TRADE: "Beoordelingshandel",
  SELECTED: "geselecteerd",
  SELECT_ALL: "Selecteer alles",
  SELECT_FROM_INFO: "Kies alstublieft uit info",
  SELECT_NETWORK: "Selecteer een netwerk",
  SELECT_TOKEN: "Selecteer een token",
  SELECT_TOKENN: "Selecteer token",
  SELECT_TO_INFO: "Selecteer voor info",
  SETTINGS: "Instellingen",
  SIMPLE_MODE: "Simpele modus",
  SLIPPAGE: "Slippen",
  SLIPPAGE_TOLERANCE: "Slippentolerantie",
  SRY_INSUFFICIENT: "Sorry, onvoldoende",
  STATUS: "TOESTAND",
  STATUS_SM: "Toestand",
  STEP: "Stap",
  SUBMIT: "Indienen",
  SUBMITTED_ORDERS: "Ingediende bestellingen",
  SUBMIT_DATE: "Dien datum",
  SUCCESS_FILLED: "Met succes bestellen vol",
  SUCCESS_RECEIVED: "Succesvol ontvangen",
  SUCCESS_SUBMITTED_ORDER: "Heeft uw bestelling met succes ingediend",
  SWAP: "Ruil",
  SWAP_TRANSFER: "Swap & Transfer",
  SWITCH_NETWORK: "Wisselnetwerk",
  TAKER: "Nemer",
  TAKER_ADDRESS: "Temer -adres",
  TERMS_OF_SERVICE: "Servicevoorwaarden",
  TO: "Naar",
  TOKEN_NOT_FOUND: "Token niet gevonden",
  TOKEN_RECEIVE_SENT: "Het te ontvangen token wordt verzonden naar",
  TRADE: "Handel",
  TRANSACTION_REJECTED_LATER: "Transactie afgewezen, probeer het later opnieuw",
  TRANSFER: "Overdracht",
  TRANSFER_ALERT: "Overdracht alert",
  TRANSFER_TO: "Overzetten naar",
  TX_COST: "TX -kosten",
  UNLOCK: "Ontgrendelen",
  USER_GUIDE: "Gebruikershandleiding",
  USE_ITAS: "en gebruik het als W",
  VERIFY: "Verifiëren",
  VIEW_EXPLORER: "Bekijk transactie in Explorer",
  VIEW_ON: "Uitzicht op",
  VIEW_TRANSACTION: "Bekijk transactie",
  WRAP: "Wrap",
  YOUR_ADDRESS: "Jouw adres",
  YOUR_ORDER_CANCEL: "Uw bestelling is geannuleerd",
  YOUR_WALLET: "Uw portemonnee",
  YOU_GET: "Jij krijgt",
  YOU_PAY: "Je betaalt",
  YOU_RECEIVE: "Jij ontvangt",
  YOU_WILL_RECEIVE: "Je ontvangt",
  ANYONE_CAN_CREATE:
    "Iedereen kan elk token maken, inclusief nepversies van de bestaande tokens. Zorg ervoor. Sommige tokens en hun technische parameters kunnen onverenigbaar zijn met onze diensten. Door dit aangepaste token te importeren, erkent en accepteert u de risico's.",
  BY_SIGNING_MESSAGE:
    "Door dit bericht te ondertekenen, bevestigt u dat u geen toegang hebt tot deze app van of een inwoner van de VS of een ander beperkt land bent. Lees voor meer informatie onze",
  CONSTITUTION: "Grondwetdao",
  CURRENTLY_CONNECTED: "U bent momenteel verbonden met",
  IMPORT_TOKEN: "Importeer een token",
  IN_YOUR_WALLET: "in je portemonnee",
  MAKE_SURE_CHECKED:
    "Zorg ervoor dat u het tokenadres hebt gecontroleerd en het is correct",
  ON: "op",
  PEOPLE: "Mensen",
  PLEASE_SWITCH: "Schakel over naar",
  SELECTED_NOT_SUPPORTED:
    "Wijzig het netwerk in uw portemonnee, het geselecteerde netwerk wordt niet ondersteund",
  SIGN_FOR_ACCESS: "Teken voor toegang",
  SIGN_MESSAGE: "Aanmeldingsbericht",
  LAUNCH_APP: "Lanceer app",
  GETTING_STARTED: "Aan de slag met HODL: Tutorials & Product Exputers",
  DEEP_DIVE: "Deepdive in HODL: strategieën en productkenmerken",
  BUILD_HODL: "Voortbouw op HODL: technische documentatie",
  BLOG_HEADING:
    "Demystifying defi - alles wat je moet weten op één plek. Voor beginners voor geavanceerde gebruikers, en iedereen daartussenin.",
  BLOG_PLACEHOLDER: "Zoek naar het product/onderwerp waarover u wilt leren",
  CHOOSE_TOPIC: "Kies onderwerp",
  TRY: "Poging",
  SEARCH_RESULTS: "Zoekresultaten",
  SEARCH_TOPIC: "Zoek onderwerp",
  LOAD_MORE: "Meer laden",
  SOCIAL_MEDIA: "Sociale media",
  NEW: "Nieuwste eerst",
  OLD: "Oudste eerst",
  Z_A: "Sorteer titel Z-A",
  A_Z: "Sorteer titel A-Z",
  RESULTS: "Resultaat",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Een basisgaskosten worden automatisch berekend door het netwerk en varieert van blok tot blok. Stel het maximale bedrag van de basiskosten in die u wilt betalen. Een verschil tussen de maximale basiskosten die u instelt en de werkelijke basiskosten worden terugbetaald zodra het blok is gedolven.",
  CANCELLED_TRANSACTION: "Uw transactie geannuleerd",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Het maken van een limietorder is een gratis bewerking voor de bestelmaker, tenzij ze annuleren. De bestelpersoon moet de transactiekosten betalen terwijl ze de bestelling invullen.",
  Cancelled: "Geannuleerd",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Waarde verschil tussen de beste route en",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Schakel deze optie in als u problemen heeft met het ruilen van een token met ingebouwde kosten voor overdracht.",
  Expired: "Verlopen",
  Filled: "Gevuld",
  INSUFFICIENT_FOR_GASFEE: "Sorry, onvoldoende fondsen voor gaskosten",
  Open: "Open",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Gedeeltelijke vulling is alleen van toepassing als een swap -route bestaat uit meerdere hoofdroute -onderdelen. Het maakt gedeeltelijke uitvoering mogelijk, waardoor de transactie wordt weergegeven om terug te keren als de marktprijs uit de gratie komt.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Prijs ingesteld door de bestelmaker voor het uitvoeren van hun bestelling (token om te ontvangen = x token om te betalen)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Prijs ingesteld door de bestelmaker voor het uitvoeren van hun bestelling (token om te betalen = y token om te ontvangen",
  REVIEW_LIMIT_ORDER: "Bekijk limietorder",
  SEARCH_BY_TOKEN: "Zoek op token of adres",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Het instellen van een hoge slippentolerantie kan transacties helpen slagen, maar u krijgt misschien niet zo'n goede prijs. Voorzichtig gebruiken.",
  STATUS_OF_THE_LIMIT_ORDER: "Status van de limietorder",
  Suspended: "Opgeschort",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Deze kosten worden betaald aan de netwerkmijnwerkers. HODL heeft geen controle over de kosten.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Het verschil tussen marktprijs en est. Prijs als gevolg van handelsgrootte",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Het minimale ontvangen bedrag, anders wordt de transactie teruggekeerd",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Deze vergoeding gaat rechtstreeks naar mijnwerkers als een stimulans om prioriteit te geven aan uw transactie.",
  TRANSACTION_REJECTED_TRY: "Transactie afgewezen, probeer het opnieuw",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Overdracht voor het geselecteerde token is niet beschikbaar op dit netwerk",
  YOU: "Jij",
};
