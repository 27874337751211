export default {
  ABOUT_NEW_FEATURES: "關於新功能。",
  ACCEPT: "接受",
  ACTIVE_ORDERS: "主動訂單",
  ADD_TOKEN: "添加一個令牌",
  AGGRESSIVE: "挑釁的",
  AND: "和",
  APPROVE: "批准",
  ASSETS_ON_WAY: "您的資產正在途中，應該到達",
  AUTO: "汽車",
  AVOID_TRANSFER:
    "請避免轉移到集中的交換地址（Binance，Huobi ...）。這不是一個支持的功能",
  BALANCE: "平衡",
  BALANCE_ONE: "平衡：",
  BLOG: "OTC",
  BRIDGE: "橋",
  CANCEL: "取消",
  CANCELLATION_TRANSACTION_FEE: "取消限額訂單涉及支付交易費用。",
  CANCEL_ALL: "取消全部",
  CANCEL_LIMIT_NOT_FILLED: "只要訂單尚未填寫，您就可以取消限額訂單。",
  CANCEL_LIMIT_ORDER: "取消限制訂單",
  CANCEL_ORDER: "取消訂單",
  CANCEL_SELECTED: "取消選擇",
  CHAINS: "部落格",
  CHOOSE_NETWORK: "選擇網絡",
  CLASSIC_MODE: "經典模式",
  CLEAR: "清除",
  CONFIRM: "確認",
  CONFIRMATION_REJECTED: "確認拒絕了。",
  CONFIRM_SWAP: "確認交換",
  CONNECT_WALLET: "連接錢包",
  COPIED: "複製",
  CURRENT: "當前的",
  CUSTOM: "風俗",
  DAYS: "天",
  DEFAULT: "預設",
  DETAILS: "細節",
  DISCLAIMER: "免責聲明",
  DISCONNECT_WALLET: "斷開錢包",
  DISMISS: "解僱",
  DUPLICATE_ORDER_SUBMIT: "重複訂單。這個已經提交了",
  ENTER_AMT: "輸入金額",
  EXCHANGE: "交換",
  EXPIRY: "到期",
  EXPIRY_SM: "到期",
  FAILED_LOAD_CHART: "無法加載圖表",
  FIND_BY_TOKEN_OR_STATUS: "通過令牌或狀態查找",
  INSUFFICIENT_BALANCE: "平衡不足",
  INSUFFICIENT: "不足的",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF: "平衡以彌補現有訂單的餘額",
  RECEIVERS: "接收器的",
  WALETADDRESS: "錢包地址",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY: "请接受服务条款和隐私政策",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "制造商接受者不应是同一个接收者地址",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN: "滑板公差应在0.1％至15％之间",
  SEARCH_A_TOKEN: "搜索令牌",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "如果价格通过比这个百分比的更多，您的交易将恢复",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "调整交易的气价。较高的GWEI =更高的速度。",
  REVIEW_OTC_ORDER: "查看OTC订单",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "订单制造商设定的到期期。在此期间，该订单将过期并存档。",
  SEARCH_A_LIQUIDITY_SOURCES: "搜索流动性来源",

  FAST: "快速地",
  FAST_AND_SECURE: "安全，快速和最安全的方式將跨鏈資產帶到",
  FEATURE_TO_TRANSFER: "請不要使用此功能將您的令牌轉移到集中交易所的地址。",
  FEES: "費用",
  FILL_ORDER: "填寫訂單",
  FILL_OTC_ORDER: "填寫OTC訂單",
  FREE: "自由的",
  FROM: "從",
  GAS_COST: "汽油成本",
  GAS_DESTINATION: "目的地鏈上的氣體",
  GAS_ON_DESTINATION: "目的地的汽油",
  GAS_PRICE: "汽油價格",
  GOT_IT: "知道了",
  GOT_PROBLEM_JUST: "有問題嗎？只是取得聯繫。",
  HIGH: "高的",
  HOURS: "小時",
  IMPORT: "進口",
  IMPOSSIBLE_TO_RETRIEVE:
    "這不是支持的功能。另外，請仔細檢查您填充的地址，任何發送到錯誤地址的令牌將無法檢索。",
  INSTANT: "立即的",
  INSUFFICIENT_APPROVED_AMT: "批准金額不足",
  INVALID_TOKEN_ADDRESS: "無效的令牌地址",
  KEEP_TRACK_NOTIFY: "您可以在面板通知中跟踪它。完成後，我們將通知您。",
  KEEP_YOU_POSTED: "。我們會及時通知您。",
  LEARN_MORE: "了解更多",
  LEGACY: "遺產",
  LIMIT: "限制",
  LIMIT_ORDER: "限制順序",
  LIMIT_PRICE: "限制價格",
  LIMIT_PRICE_SM: "限制價格",
  LIQUIDITY_SOURCES: "流動性來源",
  LOW: "低的",
  MAKER: "製作者",
  MARKET: "市場",
  MAX: "最大限度",
  MAX_BASE_FEE: "最大基本費用",
  MAX_S: "最大限度",
  MAX_TO_PAY: "最大付款",
  MINIMUM_RECEIVED: "最低收到",
  MINUTES: "分鐘",
  MONTHS: "月份",
  NEED_APPROVAL_MOVE: "我們需要您的認可才能移動",
  NEED_TO_WRAP: "你需要包裝",
  NETWORK_FEES: "網絡費",
  NETWORK_SM: "網絡。",
  NEVER: "絕不",
  NONE: "沒有任何",
  NON_CUSTODIAL_LIMIT: "在非監測限制順序中。這是關於",
  NORMAL: "普通的",
  NO_ORDERS_YET: "你還沒有訂單",
  NO_RECORDS: "沒有記錄",
  ON_UR_BEHALF: "代表你。",
  OOPS_WRONG: "哎呀！出事了。！",
  OPEN: "打開",
  ORDER_DETAILS: "訂單詳細信息",
  ORDER_HISTORY: "訂單歷史",
  ORDER_PRICE: "訂單價格",
  ORDER_ROUTING: "訂單路由",
  OTC: "OTC",
  PASTE: "粘貼",
  PAY: "支付",
  PENDING_CONFIRM: "等待錢包的確認。",
  PER: "每",
  PLEASE_CHOOSE_NETWORK: "請選擇網絡",
  PLEASE_LOGIN: "請登錄",
  PLEASE_TRY_LATER: "請稍後再試",
  POPULAR_TOKENS: "受歡迎的令牌",
  PRICE: "價格",
  PRICE_IMPACT: "價格影響",
  PRIVACY_POLICY: "隱私政策",
  RATE: "速度",
  READ_ACCEPT: "我閱讀並接受",
  RECEIVE: "收到",
  RECEIVED_ORDERS: "收到訂單",
  REFUND: "退款",
  REMOVE: "消除",
  REVERSE_RATE: "反向速率",
  REVIEW_TRADE: "審查貿易",
  SELECTED: "選定",
  SELECT_ALL: "全選",
  SELECT_FROM_INFO: "請從信息中選擇",
  SELECT_NETWORK: "選擇一個網絡",
  SELECT_TOKEN: "選擇一個令牌",
  SELECT_TOKENN: "選擇令牌",
  SELECT_TO_INFO: "請選擇信息",
  SETTINGS: "設定",
  SIMPLE_MODE: "簡單模式",
  SLIPPAGE: "滑移",
  SLIPPAGE_TOLERANCE: "滑板公差",
  SRY_INSUFFICIENT: "抱歉，不足",
  STATUS: "地位",
  STATUS_SM: "地位",
  STEP: "步",
  SUBMIT: "提交",
  SUBMITTED_ORDERS: "提交訂單",
  SUBMIT_DATE: "提交日期",
  SUCCESS_FILLED: "成功訂購",
  SUCCESS_RECEIVED: "成功收到",
  SUCCESS_SUBMITTED_ORDER: "成功提交了您的訂單",
  SWAP: "交換",
  SWAP_TRANSFER: "交換和轉移",
  SWITCH_NETWORK: "開關網絡",
  TAKER: "接受者",
  TAKER_ADDRESS: "接管者地址",
  TERMS_OF_SERVICE: "服務條款",
  TO: "到",
  TOKEN_NOT_FOUND: "找不到令牌",
  TOKEN_RECEIVE_SENT: "要接收的令牌將發送到",
  TRADE: "貿易",
  TRANSACTION_REJECTED_LATER: "交易被拒絕，請稍後再試",
  TRANSFER: "轉移",
  TRANSFER_ALERT: "傳輸警報",
  TRANSFER_TO: "傳送到",
  TX_COST: "TX成本",
  UNLOCK: "開鎖",
  USER_GUIDE: "用戶指南",
  USE_ITAS: "並將其用作W",
  VERIFY: "核實",
  VIEW_EXPLORER: "在資源管理器中查看交易",
  VIEW_ON: "查看",
  VIEW_TRANSACTION: "查看交易",
  WRAP: "裹",
  YOUR_ADDRESS: "你的地址",
  YOUR_ORDER_CANCEL: "您的訂單已取消",
  YOUR_WALLET: "你的錢包",
  YOU_GET: "你得到",
  YOU_PAY: "你付錢",
  YOU_RECEIVE: "你收到",
  YOU_WILL_RECEIVE: "您將收到",
  ANYONE_CAN_CREATE:
    "任何人都可以創建任何令牌，包括現有令牌的偽造版本。要注意。某些令牌及其技術參數可能與我們的服務不相容。通過導入此自定義令牌，您可以承認並接受風險。",
  BY_SIGNING_MESSAGE:
    "通過簽署此消息，您確認您未從中訪問此應用，或者是美國或任何其他受限制國家的居民。有關更多信息，請閱讀我們的",
  CONSTITUTION: "憲法",
  CURRENTLY_CONNECTED: "您目前已連接到",
  IMPORT_TOKEN: "導入令牌",
  IN_YOUR_WALLET: "在你的錢包裡",
  MAKE_SURE_CHECKED: "確保您已經檢查了令牌地址，這是正確的",
  ON: "在",
  PEOPLE: "人們",
  PLEASE_SWITCH: "請切換",
  SELECTED_NOT_SUPPORTED: "請更改錢包中的網絡，不支持選定的網絡",
  SIGN_FOR_ACCESS: "登錄訪問權限",
  SIGN_MESSAGE: "標誌消息",
  LAUNCH_APP: "啟動應用程序",
  GETTING_STARTED: "HODL的開始：教程和產品解釋器",
  DEEP_DIVE: "深入研究HODL：策略和產品功能",
  BUILD_HODL: "建立HODL：技術文檔",
  BLOG_HEADING:
    "揭开defi的神秘面纱 - 您需要在一个地方知道的一切。对于初学者，高级用户以及介于两者之间的每个人。",
  BLOG_PLACEHOLDER: "搜索您要學習的產品/主題",
  CHOOSE_TOPIC: "选择主题",
  TRY: "尝试",
  SEARCH_RESULTS: "搜索结果",
  SEARCH_TOPIC: "搜索主题",
  LOAD_MORE: "装载更多",
  SOCIAL_MEDIA: "社交媒体",
  NEW: "新的先来",
  OLD: "最古老的第一",
  Z_A: "排序标题z-a",
  A_Z: "排序标题A-Z",
  RESULTS: "结果",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "基本氣費自動由網絡計算，並且因塊而異。設置您要支付的最高基本費用。一旦塊開採，您設定的最高基本費用與實際基本費用之間的差額將退還。",
  CANCELLED_TRANSACTION: "取消了您的交易",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "除非取消，否則創建限制訂單是訂單製造商的免費操作。接收訂單的人在填寫訂單時必須支付交易費用。",
  Cancelled: "取消",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "最佳路線之間的價值差異和",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "如果您遇到交換具有內置費用的代幣，請啟用此選項。",
  Expired: "已到期",
  Filled: "填充",
  INSUFFICIENT_FOR_GASFEE: "抱歉，費用不足的資金",
  Open: "打開",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "部分填充僅當交換路線由多個主要路由零件組成時，才適用。它允許部分執行，如果市場價格不受歡迎，則可以防止交易逆轉。",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "訂單製造商設定的價格用於執行訂單（代幣接收= x代幣以付款）",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "訂單製造商設定的代價用於執行訂單（代幣付費= y代幣以接收",
  REVIEW_LIMIT_ORDER: "審查限制順序",
  SEARCH_BY_TOKEN: "通過令牌或地址搜索",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "設定高滑倒公差可以幫助交易成功，但您可能不會獲得如此好的價格。謹慎使用。",
  STATUS_OF_THE_LIMIT_ORDER: "限制順序的狀態",
  Suspended: "暫停",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "這些費用是向網絡礦工支付的。 Hodl不能控制費用。",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "由於貿易規模而導致的市場價格和估計價格之間的差額",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "收到的最低金額，否則交易將被恢復",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "這筆費用直接捐給礦工，以便將您的交易確定優先級。",
  TRANSACTION_REJECTED_TRY: "交易被拒絕，請重試",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE: "該網絡上沒有選定令牌的傳輸",
  YOU: "你",
};
