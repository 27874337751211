import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useConnect } from 'wagmi';
import { useDispatch, useSelector } from "react-redux";
import config from "../config/config";
import { getWalletImages } from "../helper/networkdata";

import { selectNetwork } from "../reducers/Actions";
import { toastAlert } from "../helper/toastAlert";
import { useTranslation } from "react-i18next";
import { getPrivacyterms } from "../Api/HomeActions"
export default function BridgeConnectWallet(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { connect, connectors } = useConnect();

    const [isChecked, setIsChecked] = useState(true);
    const [network, setnetwork] = useState("");
    const [error1, seterror1] = useState("");
    const [cmsdata, setCmsdata] = useState([]);
    const langcode = useSelector((state) => state.lang);
    var fromchainId = (props && props.fromchainId) ? parseInt(props.fromchainId) : 0

    useEffect(() => {
        console.log('hhhhhhhhhhhhA')
        setnetwork(fromchainId);
        // eslint-disable-next-line
    }, [fromchainId]);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        seterror1("");
        if (!event.target.checked) {
            seterror1(t("PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY"));
        }
    };

    async function chooseNetwork(type) {
        console.log('hhhhhhhhhhhh', type)
        var prevNwt = network;
        try {
            if (!isChecked) {
                seterror1(t("PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY"));
                return;
            }
            var rpcUrl = config.rpc[type];
            var web3 = new Web3(rpcUrl);
            if (window && window.ethereum) {
                web3 = new Web3(window.ethereum);
            }
            console.log('hhhhhhhhhhhhB', type)
            setnetwork(type);
            var currnetwork = await web3.eth.net.getId();

            if (parseInt(currnetwork) !== parseInt(type) && window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: Web3.utils.toHex(type) }],
                    });
                    //await switchNetworkAsync?.(type);
                    currnetwork = network;
                } catch (err) {

                }

            }


        } catch (err) {
            console.log(err, 'hhhhhhhhhhhh')
            var error = err.toString();
            var pos = error.search("Provider not set or invalid");
            if (pos >= 0) {
                toastAlert("error", `${t('PLEASE_LOGIN')} metamask`, "errormetamask");
            } else {
                toastAlert("error", t('OOPS_WRONG'), "errormetamask");
            }
            setnetwork(prevNwt);
            dispatch(selectNetwork({ network: parseInt(prevNwt) }));
        }
    }

    async function ConnectAccount(connector) {

        try {
            var chains = (connector && connector.connector && connector.connector.chains) ? connector.connector.chains : [];
            var index = chains.findIndex(val => parseInt(val.id) === parseInt(network));
            var selectedChain = [chains[index]];

            var chainList = [];
            chainList.push(selectedChain[0]);
            var sNetwork = (selectedChain && selectedChain[0] && selectedChain[0].id) ? selectedChain[0].id : 0
            for (var c = 0; c < chains.length; c++) {
                var index = chainList.findIndex(val => val.id === chains[c].id);
                if (index === -1) {
                    chainList.push(chains[c]);
                }
            }

            connector.connector.chains = chainList;

            if (!isChecked) {
                seterror1(t("PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY"));
                return;
            }
            if (!network) {
                toastAlert("error", t('PLEASE_CHOOSE_NETWORK'), "errormetamask");
                return;
            }

            let check = isMobile();
            var isType = (connector && connector.connector && connector.connector.id)
                ? connector.connector.id : "";

            if (check && !window.ethereum && isType == "metaMask") {
                connectMetamaskMobile();
                return;
            } else {

                var web3 = null;
                if (isType !== "walletConnect") {
                    web3 = new Web3(window.ethereum);
                } else {
                    var rpcUrl = config.rpc[network];
                    web3 = new Web3(rpcUrl);
                }
                var currnetwork = await web3.eth.net.getId();


                if (parseInt(currnetwork) !== parseInt(sNetwork) && isType !== "walletConnect") {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: Web3.utils.toHex(sNetwork) }],
                    });
                    setnetwork(sNetwork);
                    dispatch(selectNetwork({ network: parseInt(sNetwork) }))
                }

                connect(connector);
                var isWallet = (connector && connector.connector && connector.connector.id) ? connector.connector.id : ""
                localStorage.setItem("connect", "yes");
                localStorage.setItem("iswallet", isWallet);
                localStorage.setItem("network", parseInt(sNetwork));
                window.$("#bconnect_wallet_modal").modal("hide");
                // }, 200)
                setTimeout(function () {
                }, 200)
                setIsChecked(false);
            }
        } catch (err) {
            console.log(err, 'errerrerrerraaa')
            var error = err.toString();
            var pos = error.search("Provider not set or invalid");
            if (pos >= 0) {
                toastAlert("error", `${t('PLEASE_LOGIN')} metamask`, "errormetamask");
            } else {
                toastAlert("error", t('OOPS_WRONG'), "errormetamask");
            }

        }
    }

    const connectMetamaskMobile = () => {
        const dappUrl = window.location.href.split("//")[1].split("/")[0];
        console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        // console.log(metamaskAppDeepLink, "dapppppp...")
        window.open(metamaskAppDeepLink, "_self");
    };

    function isMobile() {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    async function handleClick(subcategory) {
        console.log(cmsdata, 'cmsdata', subcategory)
        const datas = cmsdata && cmsdata.length > 0 && cmsdata.find((item) => item.subcategoryname == subcategory)
        console.log(datas, 'datas')
        if (datas && datas.linkstatus == true) {
            window.open(datas.link, '_blank');
        }
        if (datas && datas.linkstatus == false) {
            window.open('/' + datas.identifier, '_blank');
        }
    }
    async function getdata() {
        var data = {
            lancode: langcode && langcode.lancode ? langcode.lancode : "en"
        }
        var { result } = await getPrivacyterms(data);
        setCmsdata(result)


    }
    useEffect(() => {
        getdata()

    }, [langcode.lancode]);
    return (
        <div
            className="modal fade primary_modal"
            id="bconnect_wallet_modal"
            tabIndex={-1}
            aria-labelledby="connect_walletModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="connect_walletModalLabel">
                            {t('CONNECT_WALLET')}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="connect_wallet_row">
                            <h2>{t('STEP')} 1</h2>
                            <h3>
                                {t('ACCEPT')}{" "}
                                <a className="link-yellow" onClick={() => handleClick('Terms of service')}>
                                    {t('TERMS_OF_SERVICE')}
                                </a>{" "}
                                {t('AND')}{" "}
                                <a className="link-yellow" onClick={() => handleClick('Privacy policy')}>
                                    {t('PRIVACY_POLICY')}
                                </a>
                            </h3>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">
                                    {t('READ_ACCEPT')}
                                </label>
                            </div>
                            {error1 && error1 !== "" && (
                                <p className="errmsg mb-0">
                                    {error1}
                                </p>
                            )}
                        </div>

                        <div
                            className={`${isChecked ? "connect_wallet_row_active" : ""
                                } connect_wallet_row`}
                        >
                            <h2>{t('STEP')} 2</h2>
                            <h3>{t('CHOOSE_NETWORK')}</h3>
                            <div className="network_row">
                                <div
                                    className={
                                        network === config.network.binance ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.binance)}
                                    disabled={(network === config.network.binance) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/bsc.png")}
                                        alt="BNB Chain"
                                    />
                                    <p>BNB Chain</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.ethereum
                                            ? "network_selected"
                                            : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.ethereum)}
                                    disabled={(network === config.network.ethereum) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/eth.png")}
                                        alt="Ethereum"
                                    />
                                    <p>Ethereum</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.polygon ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.polygon)}
                                    disabled={(network === config.network.polygon) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/polygon.png")}
                                        alt="Polygon"
                                    />
                                    <p>Polygon</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.avalanche ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.avalanche)}
                                    disabled={(network === config.network.avalanche) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/avalanche.png")}
                                        alt="Avalanche"
                                    />
                                    <p>Avalanche</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.fantom ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.fantom)}
                                    disabled={(network === config.network.fantom) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/fantom.png")}
                                        alt="Avalanche"
                                    />
                                    <p>Fantom</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.arbitrum ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.arbitrum)}
                                    disabled={(network === config.network.arbitrum) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/arbitrum.png")}
                                        alt="Avalanche"
                                    />
                                    <p>Arbitrum</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.optimism ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.optimism)}
                                    disabled={(network === config.network.optimism) ? false : true}
                                >
                                    <img
                                        src={require("../assets/images/crypto/optimism.png")}
                                        alt="Avalanche"
                                    />
                                    <p>Optimism</p>
                                </div>
                                <div
                                    className={
                                        network === config.network.base ? "network_selected" : ""
                                    }
                                    onClick={() => chooseNetwork(config.network.base)}
                                >
                                    <img
                                        src={require("../assets/images/crypto/base.png")}
                                        alt="Base"
                                    />
                                    <p>Base</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`${isChecked ? "connect_wallet_row_active" : ""
                                } connect_wallet_row`}
                        >
                            <h2>{t('STEP')} 3</h2>

                            <div className="network_row">

                                {connectors && connectors.length > 0 && connectors.map((connector, i) => {

                                    return (
                                        <div
                                            className={
                                                parseInt(network) !== 56 && connector.name === "Binance Wallet" ? "disabledbinance" : ""
                                            }
                                            onClick={() => ConnectAccount({ connector })}
                                        >
                                            <img
                                                src={require(`../assets/images/${getWalletImages(connector.name)}`)}
                                                alt={connector.name}
                                            />
                                            <p>{connector.name}</p>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}