var bridge = {
    1: {
        bridgeId: 101,
        router: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
        RouterETH: "0x150f94B44927F078737562f0fcF3C95c01Cc2376",
        bridge: "0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97",
        factory: "0x06D538690AF257Da524f25D0CD52fD85b1c2173E",
        feeLibrary: "0x8C3085D9a554884124C998CDB7f6d7219E9C1e6F",
        widgetswap: "0x76d4d68966728894961AA3DDC1d5B0e45668a5A6",
        maxfee: 0.24,
        default: 0.000456
    },
    56: {
        bridgeId: 102,
        router: "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8",
        bridge: "0x6694340fc020c5E6B96567843da2df01b2CE1eb6",
        factory: "0xe7Ec689f432f29383f217e36e680B5C855051f25",
        feeLibrary: "0xCA6522116e8611A346D53Cc2005AC4192e3fc2BC",
        widgetswap: "0x2Eb9ea9dF49BeBB97e7750f231A32129a89b82ee",
        maxfee: 1.32,
        default: 0.002
    },
    137: {
        bridgeId: 109,
        router: "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd",
        bridge: "0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944",
        factory: "0x808d7c71ad2ba3FA531b068a2417C63106BC0949",
        feeLibrary: "0xb279b324Ea5648bE6402ABc727173A225383494C",
        widgetswap: "0xdc2716B92480225533aBC3328C2Ab961f2A9247d",
        middleware: "0x40C73021b600B17a1a29ce3A4b1ee9eDAEc6642F",
        maxfee: 681,
        default: 10,
    },
    250: {//Fantom
        bridgeId: 112,
        router: "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6",
        bridge: "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd",
        factory: "0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944",
        feeLibrary: "0x616a68BD6DAd19e066661C7278611487d4072839",
        widgetswap: "0xC8e5157EC44E00ff85Bf15D4f50974d3A8166427",
        maxfee: 631,
        default: 0.0135
    },
    10: {//Optimism
        bridgeId: 10,
        RouterETH: "0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b",
        router: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
        bridge: "0x701a95707A0290AC8B90b3719e8EE5b210360883",
        factory: "0xE3B53AF74a4BF62Ae5511055290838050bf764Df",
        feeLibrary: "0x505eCDF2f14Cd4f1f413d04624b009A449D38D7E",
        widgetswap: "0x46Bc16F76B0aE14Abb820D3410843Ba54D8ef6f0",
        maxfee: 0.24,
        default: 0.00005676,
    },
    42161: {//Arbitrum
        bridgeId: 110,
        RouterETH: "0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40",
        router: "0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614",
        bridge: "0x352d8275AAE3e0c2404d9f68f6cEE084B5bEB3DD",
        factory: "0x55bDb4164D28FBaF0898e0eF14a589ac09Ac9970",
        feeLibrary: "0x1cF31666c06ac3401ed0C1c6346C4A9425dd7De4",
        widgetswap: "0x6c33A7b29C8B012D060F3a5046f3ee5aC48f4780",
        maxfee: 0.24,
        default: 0.000358
    },
    43114: {//avalanche
        bridgeId: 106,
        router: "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd",
        bridge: "0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944",
        factory: "0x808d7c71ad2ba3FA531b068a2417C63106BC0949",
        feeLibrary: "0x5E8eC15ACB5Aa94D5f0589E54441b31c5e0B992d",
        widgetswap: "0x20293eDD4f52F81234b3997B9AE4742c48005858",
        maxfee: 18.47,
        default: 0.00709
    },
    8453: {//base
        bridgeId: 184,
        router: "0x45f1A95A4D3f3836523F5c83673c797f4d4d263B",
        bridge: "0xAF54BE5B6eEc24d6BFACf1cce4eaF680A8239398",
        factory: "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6",
        feeLibrary: "0x9d1b1669c73b033dfe47ae5a0164ab96df25b944",
        widgetswap: "0x10d16248bED1E0D0c7cF94fFD99A50c336c7Bcdc",
        maxfee: 18.47,
        default: 0.00709
    }
}
export default bridge;