// import constant
import {
    SOURCECOUNT
} from '../constant';

const initialState = {
    count: 0
};

const sourceCount = (state = initialState, action) => {

    switch (action.type) {
        case SOURCECOUNT:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default sourceCount;