import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";
import WalletDetail from "./WalletDetail";
import { setWallet, selectNetwork, setLanguage } from "../reducers/Actions";
import { shortText } from "../helper/custommath";
import config from "../config/config";
import { useTranslation } from "react-i18next";
import { getfooterLink } from "../Api/BlogActions"
import { toastAlert } from "../helper/toastAlert";
import { dropdownLogo, getNetworkName } from "../helper/networkdata";
import { useWalletClient, useNetwork } from "wagmi";
import { switchNetwork } from '@wagmi/core';
import { walletClientToSigner } from "../components/ethersconnect";
import {
  SaveUsers,
  saveVisitors,
  getLoginInfo,
  GetMeta
} from "../Api/TokenActions";
import SignIn from "../components/SignIn";

import { showMenu } from "../helper/custommath"

var arr = [
  { name: "العربية", value: "ar" },//arabic
  { name: "বাংলা", value: "bn" },//bengali
  { name: "English", value: "en" },//english
  { name: "Deutsch", value: "de" },//german
  { name: "Ελληνικά", value: "el" },//greek
  { name: "Español", value: "es" },//spanish
  { name: "Suomalainen", value: "fi" },//Finnish
  { name: "Filipino", value: "fil" },//Portuguese
  { name: "Français", value: "fr" },//French
  { name: "हिंदी", value: "hi" },//hindi
  { name: "Magyar", value: "hu" },//Hungarian
  { name: "Bahasa Indonesia", value: "id" },//Indonesian
  { name: "Italiano", value: "it" },//Italian
  { name: "日本語", value: "ja" },//Japanese
  { name: "한국어", value: "ko" },//korean
  { name: "Nederlands", value: "nl" },//Dutch
  { name: "Polski", value: "pl" },//Polish
  { name: "Português (Brazil)", value: "pt-br" },//Português (Brazil)
  { name: "Português", value: "pt" },//Portuguese
  { name: "Română", value: "ro" },//romanian
  { name: "Русский", value: "ru" },//	Russian
  { name: "Svenska", value: "sv" },//	swedish
  { name: "தமிழ்", value: "ta" },//tamil
  { name: "Türkçe", value: "tr" },//Turkish
  { name: "Українська", value: "uk" },//ukrainian
  { name: "Tiếng Việt", value: "vi" },//Vietnamese
  { name: "简体中文", value: "zh" },//Chinese
  // { name: "繁體中文", value: "zh-TW" },//Chinese
];

export default function Navbar() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { chain } = useNetwork();
  const [file, setfile] = useState(null)
  const walletConnection = useSelector((state) => state.walletConnection);
  const selectednetwork = useSelector((state) => state.network);

  var chainId =
    selectednetwork &&
      selectednetwork.network &&
      parseInt(selectednetwork.network)
      ? parseInt(selectednetwork.network)
      : 1;

  const { data: walletClient } = useWalletClient({ chainId });

  //console.log(walletClient, 'walletClientwalletClient', chain)
  const [lang, setLang] = useState("en");
  const [logodata, setLogodata] = useState("");
  const [useraddress, setuseraddress] = useState("");
  const [currentNetwork, setcurrentNetwork] = useState(
    localStorage.getItem("network") &&
      parseInt(localStorage.getItem("network")) > 0
      ? parseInt(localStorage.getItem("network"))
      : 56
  );

  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  var fired = false;

  useEffect(() => {
    GetMetaData();
    changeLanguageFunction();
  }, []);

  async function GetMetaData() {

    var { result } = await GetMeta();

    var title = "Welcome";
    if (result) {
      var home = (result && result.home) ? result.home : "Welcome";
      var swap = (result && result.swap) ? result.swap : "Welcome";
      var limit = (result && result.limit) ? result.limit : "Welcome";
      var otc = (result && result.otc) ? result.otc : "Welcome";
      var bridge = (result && result.bridge) ? result.bridge : "Welcome";
      var blog = (result && result.blog) ? result.blog : "Welcome";

      var pathname = (location && location.pathname) ? location.pathname : "";
      var blogPos = pathname.search("blog")

      if (pathname === "/swap-simple"
        || pathname === "/swap-classic"
        || pathname === "/transfer") {
        title = swap;
      } else if (pathname === "/swap-limit") {
        title = limit;
      } else if (pathname === "/p2p") {
        title = otc;
      } else if (pathname === "/bridge") {
        title = bridge;
      } else if (blogPos >= 0) {
        title = blog;
      } else {
        title = home;
      }
    }
    document.title = title;
  }



  const changeLanguageFunction = () => {
    let getLang = localStorage.getItem("code");
    if (!getLang) {
      localStorage.setItem("code", "en");

      setLang(localStorage.getItem("code"));
      i18n.changeLanguage(lang);
    } else {
      dispatch(setLanguage({ lancode: getLang }));
      setLang(getLang);
      i18n.changeLanguage(getLang);
    }
  };
  async function selectlanguage(event, value) {
    let language = value;
    localStorage.setItem("code", language);
    dispatch(setLanguage({ lancode: language }));
    i18n.changeLanguage(language);
  }
  useEffect(() => {
    loadJquery();
    setConnection();
    // eslint-disable-next-line
  }, [walletClient, chain?.id]);

  useEffect(() => {
    if (localStorage.getItem("connect") == "yes") {
      setConnection();
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("connect")]);

  useEffect(() => {
    saveVisitUsers();
    // eslint-disable-next-line
  }, [walletClient, chain?.id]);

  async function saveVisitUsers() {
    var { ip, country } = await getLoginInfo();
    saveVisitors({ ip, country });
  }

  async function setConnection() {

    var currChain = chain && chain.id ? chain.id : 0;

    var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0) ? parseInt(localStorage.getItem("network")) : (currChain > 0) ? currChain : 56;

    if (
      walletClient &&
      config.rpc &&
      config.rpc[chainVal] &&
      config.rpc[chainVal] != "" && localStorage.getItem("connect") == "yes"
    ) {
      var { transport } = await walletClientToSigner(walletClient);

      var web3 = new Web3(transport);
      var userAddr =
        walletClient && walletClient.account && walletClient.account.address
          ? walletClient.account.address
          : "";
      //var chainVal = chain && chain.id ? chain.id : 56;

      var { isSignature } = await SaveUsers({ useraddress: userAddr });

      //if (isSignature == "yes" && localStorage.getItem("iswallet") !== "walletConnect") {
      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: transport,
          connect: isSignature,
        })
      );
      //}

      if (isSignature == "no" && localStorage.getItem("iswallet") == "walletConnect") {
        window.$("#sign_approve_modal").modal("show");
      }

      setcurrentNetwork(chainVal);
      setuseraddress(userAddr);
      dispatch(selectNetwork({ network: parseInt(chainVal) }));

    } else {

      //var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0) ? parseInt(localStorage.getItem("network")) : (currChain > 0) ? currChain : 56;
      var chainVal = localStorage.getItem("network") &&
        parseInt(localStorage.getItem("network")) > 0
        ? parseInt(localStorage.getItem("network"))
        : 56;
      var rpcurl = config.rpc[chainVal];
      var web3 = new Web3(rpcurl);
      dispatch(
        setWallet({
          network: chainVal,
          web3: web3,
          address: "",
          provider: "",
          connect: "no",
        })
      );
      dispatch(selectNetwork({ network: parseInt(chainVal) }));
      setcurrentNetwork(chainVal);
    }

  }

  async function changeNetwork(id) {

    try {
      setActive(!isActive);

      if (
        window.ethereum &&
        parseInt(window.ethereum.networkVersion) !== parseInt(id) &&
        walletConnection &&
        walletConnection.address &&
        walletConnection.address != ""
      ) {
        try {

          await switchNetwork({
            chainId: id,
          });
        } catch (err) {
          console.log(err, "erroooooooo");
          var errMsg = err && err.message ? err.message : err;
          var pos = errMsg.search("wallet_addEthereumChain");
          var pos1 = errMsg.search("User rejected the request");
          var pos2 = errMsg.search("An error occurred when attempting to switch chain");

          if (pos1 >= 0) {
            toastAlert("error", t('CONFIRMATION_REJECTED'), "errormetamask");
            return;
            return;
          } else if (pos2 >= 0 && localStorage.getItem("iswallet") === "walletConnect") {
            var nwName = getNetworkName(currentNetwork);
            var nwName1 = getNetworkName(id);
            toastAlert(
              "error",
              `${t('CURRENTLY_CONNECTED')} ${t('ON')} ` +
              nwName +
              `", ${t('PLEASE_SWITCH')} "` +
              nwName1 +
              `${t('IN_YOUR_WALLET')}`,
              "walletconnect"
            );
            return;
          }

          if (pos >= 0) {
            var addNwk = config.addnetwork[id];
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: addNwk,
              });
            } catch (err) {
              console.log("Switch network err", err);
              return;
            }
            console.log("Switch network");
          }
        }
      }
      var wallettype = localStorage.getItem("iswallet");
      localStorage.setItem("connect", "yes");
      localStorage.setItem("iswallet", wallettype);
      localStorage.setItem("network", parseInt(id));

      var provider = null;
      var userAddr = "";
      var chainVal = "";
      var chainVal = id && id > 0 ? id : 56;
      if (walletClient) {
        var { transport } = await walletClientToSigner(walletClient);
        provider = transport;
        userAddr =
          walletClient && walletClient.account && walletClient.account.address
            ? walletClient.account.address
            : "";
        //chainVal = (chain && chain.id) ? chain.id : chainVal;
        var web3 = new Web3(provider);
      } else {
        var rpcurl = config.rpc[chainVal];
        web3 = new Web3(rpcurl);
        provider = web3;
      }

      //  return;
      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: provider,
          connect: "yes",
          wallettype: wallettype,
        })
      );

      setcurrentNetwork(parseInt(id));

      setTimeout(function () {
        window.location.reload(false); //translate lang issu
      }, 1500);
    } catch (err) {
      console.log(err, "****************?");
    }
  }

  function loadJquery() {
    $("body").off("click");
    $(document).on("click", "body", function (e) {
      try {
        var id = e.target.id;
        if (id !== "navbarclose") {
          var className = $(".network_btn").attr("class");
          var position = className.search("network_btn_open");

          $(".navbarclose").removeClass("network_btn_open");
          $(".navbarclose").removeClass("choose_network_container_open");
          if (position >= 0) {
            setActive(false);
          }
        }
      } catch (err) { }
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 500) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    $("#languageBtn").off("click");
    $("#languageBtn").click(function () {
      $(".language-change-popup").toggleClass("popup-show");
    });
    $(".popupClose").click(function () {
      $(".language-change-popup").removeClass("popup-show");
    });
  }

  useEffect(() => {

  }, []);

  function checkSignature1(status) {

  }

  async function getfooterlink() {
    var { result } = await getfooterLink();
    setLogodata(result)
  }
  useEffect(() => {
    getfooterlink()

  }, []);
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            {logodata && logodata.logoURI && <img src={`${config.imageUrl + '/logo/' + logodata.logoURI
              }`} alt="Logo" width="110" />}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                HODL
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 main_navbar_list">

                <li className="nav-item dropdown dropdown_menu_custom">
                  <a
                    className={
                      location.pathname === "/swap-simple" ||
                        location.pathname === "/swap-classic" ||
                        location.pathname === "/swap-limit" ||
                        location.pathname === "/p2p" ||
                        location.pathname === "/transfer"
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }
                    href="/#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t('TRADE')}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="/swap-simple" className="dropdown-item">
                        <img
                          src={require("../assets/images/simple_icon.png")}
                          className="img-fluid"
                          alt="Simple"
                        />
                        <span>{t('SIMPLE_MODE')}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/swap-classic" className="dropdown-item">
                        <img
                          src={require("../assets/images/multiple_icon.png")}
                          className="img-fluid"
                          alt="Classic"
                        />
                        <span>{t('CLASSIC_MODE')}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/transfer" className="dropdown-item">
                        <img
                          src={require("../assets/images/transfer.png")}
                          className="img-fluid"
                          alt="Classic"
                        />
                        <span>{t('TRANSFER')}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/swap-limit" className="dropdown-item">
                        <img
                          src={require("../assets/images/limit.png")}
                          className="img-fluid"
                          alt="Classic"
                        />
                        <span>{t('LIMIT_ORDER')}</span>
                      </a>
                    </li>
                    <li>
                      <a href="/p2p" className="dropdown-item">
                        <img
                          src={require("../assets/images/p2p.png")}
                          className="img-fluid"
                          alt="Classic"
                        />
                        <span>{t('OTC')}</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="/bridge" className={
                    location.pathname === "/bridge"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                    {t('BRIDGE')}
                  </a>
                </li>

                <li className="nav-item">
                  <a href="/blog" className={
                    location.pathname === "/blog"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                    {t('BLOG')}
                  </a>
                </li>
              </ul>

              <div className="right_menu">
                <div id="languageSwitchWrap" className="language-switch">
                  <div id="languageBtn" className="language-button">
                    <i className="bi bi-globe"></i>
                    <span>{(localStorage.getItem("code")) ? localStorage.getItem("code").toUpperCase() : ""}</span>
                  </div>

                  <div
                    id="languageChangePopup"
                    className="language-change-popup"
                  >
                    <div className="popup-header">
                      <p className="popup-title">Change language</p>
                      <i className="bi bi-x popup-close popupClose" id="popupClose"></i>
                    </div>
                    <div className="popup-content">
                      {
                        arr && arr.length > 0 && arr.map((item, key) => {
                          return (
                            <div key={key}>
                              <a href="#/" className="popup-lang-item popupClose" data-lang="ar" onClick={(event) => selectlanguage(event, item.value)}>
                                <span>{item.name}</span>
                                <span>&nbsp;—&nbsp;</span>
                                <span className="popup-lang-abbr">{item.value}</span>
                              </a>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                {showMenu() == "inner" &&
                  <div className="network_div">
                    <button
                      className={`network_btn navbarclose ${isActive ? "network_btn_open" : ""
                        }`}
                      onClick={toggleClass}
                      id="navbarclose"
                    >
                      <div>
                        <img
                          onClick={toggleClass}
                          id="navbarclose"
                          src={dropdownLogo(currentNetwork)}
                          alt="Crypto Coin"
                        />

                      </div>
                    </button>
                    <div
                      className={
                        isActive
                          ? "choose_network_container  choose_network_container_open navbarclose"
                          : "choose_network_container"
                      }
                    >
                      <ul>
                        <li onClick={() => changeNetwork(1)}>
                          <img
                            src={dropdownLogo(
                              1
                            )}
                            alt="Crypto Coin"
                          />
                          Ethereum
                        </li>
                        <li onClick={() => changeNetwork(56)}>
                          <img
                            src={dropdownLogo(56)}
                            alt="Crypto Coin"
                          />
                          BNB Chain
                        </li>
                        <li onClick={() => changeNetwork(137)}>
                          <img
                            src={dropdownLogo(137)}
                            alt="Crypto Coin"
                          />
                          Polygon
                        </li>
                        <li onClick={() => changeNetwork(43114)}>
                          <img
                            src={dropdownLogo(43114)}
                            alt="Crypto Coin"
                          />
                          Avalanche
                        </li>
                        <li onClick={() => changeNetwork(250)}>
                          <img
                            src={dropdownLogo(250)}
                            alt="Crypto Coin"
                          />
                          Fantom
                        </li>
                        <li onClick={() => changeNetwork(42161)}>
                          <img
                            src={dropdownLogo(42161)}
                            alt="Crypto Coin"
                          />
                          Arbitrum
                        </li>
                        <li onClick={() => changeNetwork(10)}>
                          <img
                            src={dropdownLogo(10)}
                            alt="Crypto Coin"
                          />
                          Optimism
                        </li>
                        <li onClick={() => changeNetwork(8453)}>
                          <img
                            src={dropdownLogo(8453)}
                            alt="Crypto Coin"
                          />
                          Base
                        </li>
                      </ul>
                    </div>
                  </div>
                }
                {showMenu() == "home" ?
                  <a
                    className="primary_btn"
                    href="/swap-classic"
                    target="_blank"
                  >
                    {t('LAUNCH_APP')}
                  </a>
                  : walletConnection &&
                    walletConnection.address &&
                    walletConnection.address !== "" && walletConnection.connect === "yes" ?
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#wallet_detail_modal"
                    >
                      {shortText(useraddress)}
                    </button> :
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      {t('CONNECT_WALLET')}
                    </button>
                }

              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* Connect Wallet Modal */}
      <ConnectWallet
        setConnection1={setConnection}
      />
      <WalletDetail />
      <SignIn
        checkSignature={checkSignature1}
      />
    </>
  );
}
