import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import CopyToClipboard from "react-copy-to-clipboard";
import { GetTokenList } from "../Api/TokenActions";
import config from "../config/config";
import { setswapToken } from "../reducers/Actions";
import { getParameterByName, checkIsAddress, shortText } from "../helper/custommath";
import { getTokenLogo } from "../helper/networkdata";

import { getAllTokenBalances, getTokenInfo } from "../ContractActions/bep20Actions";
import { getPoplularList, getImportedToken } from "./swap-details";
import { useTranslation } from "react-i18next";
import { getTxName, getTxLink } from "../helper/networkdata";
import { toastAlert } from "../helper/toastAlert";

var isFire = false;
export default function TokenList(props) {
  const dispatch = useDispatch();
  const swapToken = useSelector((state) => state.swapToken);
  const walletConnection = useSelector((state) => state.walletConnection);
  const { t, i18n } = useTranslation();

  const [tokenList, settokenList] = useState([]);
  const [alltokenList, setalltokenList] = useState([]);
  const [populartokens, setpopulartokens] = useState([]);
  const [pagenumber, setpagenumber] = useState(1);
  const [currId, setcurrId] = useState("");
  const [loadBalances, setloadBalances] = useState(false);
  const [searchTxt, setsearchTxt] = useState("");
  const [importToken, setimportToken] = useState({
    address: "",
    chainId: 0,
    decimals: 0,
    isPopular: "no",
    logoURI: "",
    name: "",
    symbol: "",
    isNew: ""
  });
  const [copytext, setcopytext] = React.useState(false);
  const [isAccept, setisAccept] = useState(true);
  const [error, seterror] = useState(false);
  const [status, setstatus] = useState(true);

  useEffect(() => {
    setInitial();
    //eslint-disable-next-line
  }, [walletConnection.network, walletConnection.address]);

  useEffect(() => {
    selectedToken();
    //eslint-disable-next-line
  }, [props.id]);

  function selectedToken() {
    setcurrId(props.id);
  }

  function paginate(allTokens, skip) {
    let limit = 50;
    return allTokens.slice((skip - 1) * limit, skip * limit);
  }

  async function setInitial() {

    try {
      var ntId = localStorage.getItem("network");
      ntId = parseInt(ntId) > 0 ? parseInt(ntId) : 97;
      let chainId =
        walletConnection && walletConnection.network
          ? walletConnection.network
          : ntId;
      var data = {
        chainId: chainId,
        useraddress: walletConnection.address,
      };

      var { result } = await GetTokenList(data);
      var list = JSON.parse(result);

      var imptToken = localStorage.getItem("Imported");
      try {
        imptToken = JSON.parse(imptToken);
      } catch (err) {

      }
      if (imptToken && imptToken.length > 0 && Array.isArray(imptToken)) {
        imptToken = imptToken.filter(val => val.chainId == chainId);
        if (imptToken && imptToken.length > 0) {
          list = list.concat(imptToken);
        }
      }

      var popList = getPoplularList(list);
      setpopulartokens(popList);
      var index = list.findIndex(
        (val) => val.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      );
      index = index === -1 ? 0 : index;

      var index1 = list.findIndex((val) => val.symbol === "USDT");
      if (index1 == -1) {
        index1 = list.findIndex((val) => val.symbol === "DAI");
      }
      index1 = index1 === -1 ? 1 : index1;

      var getAddr = getParameterByName("address");
      if (getAddr && getAddr !== "") {
        var selectindex = list.findIndex(
          (val) => val.address.toLowerCase() === getAddr.toLowerCase()
        );
        if (selectindex !== -1) {
          index1 = selectindex;
        }
      }

      dispatch(
        setswapToken({
          fromaddress: list[index].address,
          fromdecimal: list[index].decimals,
          fromsymbol: list[index].symbol,
          fromname: list[index].name,
          fromlogo: list[index].logoURI,
          fromchainId: list[index].chainId,
          toaddress: list[index1].address,
          todecimal: list[index1].decimals,
          tosymbol: list[index1].symbol,
          tologo: list[index1].logoURI,
          toname: list[index1].name,
          tochainId: list[index1].chainId,
        })
      );
      //console.log(list, 'resp1resp1resp1resp2')
      var resp = paginate(list, pagenumber);
      var resp1 = await getAllTokenBalances(resp);
      if (resp1 && resp1.length == 0) {
        resp1 = resp;
      }
      settokenList(resp1);

      if (resp1 && resp1.length > 0) {
        for (var t = 0; t < resp1.length; t++) {
          var index = list.findIndex(
            (val) => val.address.toLowerCase() == resp1[t].address.toLowerCase()
          );
          if (index != -1) {
            var newObj = list[index];
            Object.assign(newObj, {
              balance: list[index].balance,
            });
            list[index] = newObj;
          }
        }
      }
      setalltokenList(list);
      setpagenumber(parseInt(pagenumber) + 1);
    } catch (err) { }
  }

  const filterValue = async (event) => {
    var value = event.target.value;
    if (value && value !== "") {
      setsearchTxt(value)
      var filteredData = alltokenList.filter(data => data.symbol.toLowerCase().includes(value.toLowerCase()) || data.address.toLowerCase().includes(value.toLowerCase()));
      var currChainId = (alltokenList && alltokenList[0] && alltokenList[0].chainId) ?
        alltokenList[0].chainId : 56;
      var isToken = checkIsAddress(currChainId, value);
      if (filteredData.length === 0 && isToken) {

        var {
          tokenName,
          symbol,
          decimals,
          status
        } = await getTokenInfo(currChainId, value);
        if (status) {

          var data = {
            address: value,
            chainId: currChainId,
            decimals,
            isPopular: "no",
            logoURI: "",
            name: tokenName,
            symbol,
            isNew: "yes"
          }
          console.log(data, 'datadatadatadata')
          settokenList([data]);
        } else {
          settokenList([]);
        }

      } else {
        setpagenumber(1);
        var resp = paginate(filteredData, 1);
        var resp1 = await getAllTokenBalances(resp);
        if (resp1 && resp1.length == 0) {
          resp1 = resp;
        }
        setTimeout(settokenList.bind(this, resp1), 200);
      }
    } else {
      setsearchTxt("")
      setpagenumber(1);
      let resp = paginate(alltokenList, 1)
      setTimeout(settokenList.bind(this, resp), 200);
    }

  }

  const handleScroll = async (e) => {
    setloadBalances(true);
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      var list = alltokenList;
      var pageList = list;
      if (searchTxt && searchTxt !== "") {
        var filteredData = alltokenList.filter((data) =>
          data.symbol.toLowerCase().includes(searchTxt.toLowerCase())
        );
        pageList = filteredData;
      }
      setpagenumber(parseInt(pagenumber) + 1);
      var arr2 = paginate(pageList, parseInt(pagenumber) + 1);
      if (arr2 && arr2.length > 0) {
        var resp1 = await getAllTokenBalances(arr2);
        const combineList = tokenList.concat(resp1);

        if (resp1 && resp1.length > 0) {
          for (var t = 0; t < resp1.length; t++) {
            var index = list.findIndex(
              (val) => val.address.toLowerCase() == resp1[t].address
            );
            if (index != -1) {
              var newObj = list[index];
              Object.assign(newObj, {
                balance: list[index].balance,
              });
              list[index] = newObj;
            }
          }
        }
        setalltokenList(list);
        settokenList(combineList);
      }
    }
    setloadBalances(false);
  };

  function setToken(items) {
    console.log("setTokensetTokensetTokensetToken")
    dispatch(
      setswapToken({
        fromaddress:
          props.id === "from" ? items.address : swapToken.fromaddress,
        fromdecimal:
          props.id === "from" ? items.decimals : swapToken.fromdecimal,
        fromsymbol: props.id === "from" ? items.symbol : swapToken.fromsymbol,
        fromname: props.id === "from" ? items.name : swapToken.fromname,
        fromlogo: props.id === "from" ? items.logoURI : swapToken.fromlogo,
        fromchainId:
          props.id === "from" ? items.chainId : swapToken.fromchainId,
        toaddress: props.id === "from" ? swapToken.toaddress : items.address,
        todecimal: props.id === "from" ? swapToken.todecimal : items.decimals,
        tosymbol: props.id === "from" ? swapToken.tosymbol : items.symbol,
        tologo: props.id === "from" ? swapToken.tologo : items.logoURI,
        toname: props.id === "from" ? swapToken.toname : items.name,
        tochainId: props.id === "from" ? swapToken.tochainId : items.chainId,
      })
    );
    window.$("#select_token_modal").modal("hide");
  }

  function setFavourite(addr) {
    if (walletConnection && walletConnection.address !== "") {
      setstatus(false)
      var isExits = populartokens.findIndex(
        (val) => val.address.toLowerCase() == addr.toLowerCase()
      );

      if (isExits == -1) {
        if (populartokens && populartokens.length <= 12) {
          var getList = localStorage.getItem("popularlist");
          if (getList) {
            getList += "," + addr;
          } else {
            getList += addr;
          }
          var chkIndex = alltokenList.findIndex(
            (val) => val.address.toLowerCase() == addr.toLowerCase()
          );
          if (chkIndex !== -1) {
            populartokens.push(alltokenList[chkIndex]);
          }
          setpopulartokens([])
          setTimeout(function () {
            setpopulartokens(populartokens);
          }, 1)

          localStorage.setItem("popularlist", getList);
        }
      } else {
        populartokens.splice(isExits, 1);
        setpopulartokens([])
        setTimeout(function () {
          setpopulartokens(populartokens);
        }, 1)
        var pList = "";
        for (var p = 0; p < populartokens.length; p++) {
          pList +=
            p == 0 ? populartokens[p].address : "," + populartokens[p].address;
        }
        localStorage.setItem("popularlist", pList);
      }
      setstatus(true);
    }
  }

  function setNewToken(obj) {
    setimportToken(obj)
  }

  async function importTokenNew() {

    if (!isAccept) {
      seterror(true);
      setTimeout(function () {
        seterror(false);
      }, 1000)
      return;

    }

    if (importToken && importToken.address) {

      if (importToken && importToken.isNew) {
        importToken.isNew = "no";
      }

      var imptToken = getImportedToken(importToken);
      localStorage.setItem("Imported", JSON.stringify(imptToken));

      var newTkn = alltokenList.concat([importToken]);
      console.log(newTkn, 'newTknnewTkn1')
      setalltokenList(newTkn);
      setpagenumber(1)



      var resp = paginate(newTkn, 1);
      var resp1 = await getAllTokenBalances(resp);
      if (resp1 && resp1.length == 0) {
        resp1 = resp;
      }
      var chainId = walletConnection.network;
      var imptToken = localStorage.getItem("Imported");
      try {
        imptToken = JSON.parse(imptToken);
      } catch (err) {

      }
      if (imptToken && imptToken.length > 0 && Array.isArray(imptToken)) {
        imptToken = imptToken.filter(val => val.chainId == chainId);
        if (imptToken && imptToken.length > 0) {
          resp1 = resp1.concat(imptToken);
        }
      }

      settokenList(resp1);

      dispatch(
        setswapToken({
          fromaddress:
            props.id === "from" ? importToken.address : swapToken.fromaddress,
          fromdecimal:
            props.id === "from" ? importToken.decimals : swapToken.fromdecimal,
          fromsymbol: props.id === "from" ? importToken.symbol : swapToken.fromsymbol,
          fromname: props.id === "from" ? importToken.name : swapToken.fromname,
          fromlogo: props.id === "from" ? importToken.logoURI : swapToken.fromlogo,
          fromchainId:
            props.id === "from" ? importToken.chainId : swapToken.fromchainId,
          toaddress: props.id === "from" ? swapToken.toaddress : importToken.address,
          todecimal: props.id === "from" ? swapToken.todecimal : importToken.decimals,
          tosymbol: props.id === "from" ? swapToken.tosymbol : importToken.symbol,
          tologo: props.id === "from" ? swapToken.tologo : importToken.logoURI,
          toname: props.id === "from" ? swapToken.toname : importToken.name,
          tochainId: props.id === "from" ? swapToken.tochainId : importToken.chainId,
        })
      );
      window.$("#select_token_modal").modal("hide");
      window.$("#ImportTokenModal").modal("hide");
      setsearchTxt("");

    } else {
      toastAlert("error", "Invalid token", "imtTkn")
    }

  }

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }


  const acceptToken = async (event) => {
    setisAccept(event.target.checked);
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="select_token_modal"
        tabIndex="-1"
        aria-labelledby="select_token_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="select_token_modalLabel">
                {t('SELECT_TOKEN')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                className="token_search_inp"
                placeholder={t("SEARCH_A_TOKEN")}
                onChange={filterValue}
                value={searchTxt}
              />
              <p className="my-3">{t('POPULAR_TOKENS')}</p>

              <div className="whole_popularlist">
                {status && populartokens &&
                  populartokens.length > 0 &&
                  populartokens.map((item, i) => {
                    var Saddress =
                      currId == "from"
                        ? swapToken.toaddress
                        : swapToken.fromaddress;
                    if (Saddress !== item.address) {
                      return (
                        <div
                          className="popularlist"
                          id={"fav-" + i}
                        >
                          <img
                            src={getTokenLogo(item.logoURI, item.chainId)}
                            alt={item.symbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                            onClick={() => setToken(item)}
                          />
                          <div onClick={() => setToken(item)}>
                            <span className="token_search_list_cur_name">
                              {item.symbol}
                            </span>
                          </div>
                          <i
                            className="fa fa-times-circle popularlist_close"
                            aria-hidden="true"
                            onClick={() => setFavourite(item.address)}
                          ></i>
                        </div>
                      );
                    }
                  })}
              </div>
              {loadBalances && (
                <i className="fas fa-spinner fa-spin text-center"></i>
              )}
              {tokenList && tokenList.length > 0 && (
                <div className="token_search_list" onScroll={handleScroll}>
                  {tokenList &&
                    tokenList.length > 0 &&
                    tokenList.map((item, i) => {
                      var Saddress =
                        currId == "from"
                          ? swapToken.toaddress
                          : swapToken.fromaddress;
                      var tknAddr = item.address.toLowerCase();

                      var pIndex = -1;
                      if (populartokens && populartokens.length > 0) {
                        pIndex = populartokens.findIndex(
                          (val) =>
                            val.address.toLowerCase() ==
                            item.address.toLowerCase()
                        );
                      }

                      if (Saddress !== item.address) {
                        return (
                          <div className="token_search_list_row" key={i}>
                            <div onClick={() => setToken(item)}>
                              <img
                                src={getTokenLogo(item.logoURI, item.chainId)}
                                alt={item.symbol}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = config.defaultLogo;
                                }}
                              />
                              <div>
                                <span className="token_search_list_cur_name">
                                  {item.symbol}
                                </span>
                                <span className="token_search_list_cur_value">
                                  {item.name}
                                </span>
                              </div>
                            </div>

                            {item && item.isNew && item.isNew == "yes" ?
                              <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#ImportTokenModal"
                                onClick={() => setNewToken(item)}
                              >
                                {t('IMPORT')}
                              </button>
                              :
                              <div className="token_fav">
                                <span
                                  id={tknAddr}
                                  className="token_search_list_coin_dollar"
                                >
                                  {item.balance}{" "}
                                </span>
                                <i
                                  onClick={() => setFavourite(item.address)}
                                  className={
                                    (pIndex !== -1 && walletConnection && walletConnection.address != "")
                                      ? "fas fa-thumbtack ispopular"
                                      : "fas fa-thumbtack"
                                  }
                                ></i>
                              </div>
                            }
                          </div>
                        );
                      }
                    })}
                </div>
              )}
              {tokenList && tokenList.length === 0 && (
                <div className="token_search_list emptytokens">
                  <div>
                    <span className="token_search_list_cur_name">
                      {t('NO_RECORDS')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Import Modal */}
      <div
        className="modal fade primary_modal"
        id="ImportTokenModal"
        tabindex="-1"
        aria-labelledby="ImportTokenModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="ImportTokenModalLabel">
                {t('IMPORT_TOKEN')}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="import_token_details">
                <div className="import_token_details_left">
                  <img
                    src={require("../assets/images/crypto/cream.png")}
                    alt="Icon"
                    className="img-fluid"
                  />
                  <div>
                    <div>
                      <h4>{importToken.name}</h4>
                      <h5>{importToken.symbol}</h5>
                    </div>
                    <div>
                      <p>{shortText(importToken.address)}</p>
                      <CopyToClipboard
                        text={importToken.address}
                        onCopy={() => onCopy()}
                      >
                        <i className="far fa-copy"></i>
                      </CopyToClipboard>
                      {copytext && <p className="copied_txt">{t('COPIED')}</p>}
                    </div>
                  </div>
                </div>
                <a
                  href={getTxLink(importToken.chainId) + "address/" + importToken.address}
                  target="_blank"
                  className="import_token_details_right"
                >
                  <p>{getTxName(importToken.chainId)}</p>
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </div>

              <div className="yellow_box">
                <p className="mb-0">
                  {t('MAKE_SURE_CHECKED')}
                </p>
              </div>

              <div className="alert alert-danger" role="alert" style={{ color: error ? 'red' : '' }}>
                {t('ANYONE_CAN_CREATE')}
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={isAccept}
                    onClick={acceptToken}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {t('ACCEPT')}
                  </label>
                </div>
              </div>
              <button className="primary_btn w-100" onClick={() => importTokenNew()}>{t('IMPORT')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
