import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";

import TokenList from "../../components/TokenList";
import Settings from "../../components/Settings";
import SwapMenu from "../../components/swapMenu";

import {
  validateAllowanceBalance,
  getUserBalances,
  limitOrderExpireOptions,
} from "./swap-details";
import {
  formatNumber,
  checkIsAddress,
  getParameterByName,
  numberOnly,
} from "../../helper/custommath";
import config from "../../config/config";
import { getTokenLogo, getNetworkName } from "../../helper/networkdata";
import { getCurrentNetwork } from "../../helper/connection";
import { getLimitOrders, getP2POrders } from "./limitParaswapSdk";
import {
  multiplyValue,
  numberFloatOnly,
  divideValue,
  addValue,
  subtractValue
} from "../../helper/custommath";
import { toastAlert } from "../../helper/toastAlert";
import WrappedTokenModal from "./wrappedTokenModal";
import UnlockTokenModal from "./unlockTokenModal";
import ReviewTradeModal from "./reviewTradeModal";
import { useTranslation } from "react-i18next";
import { GetTokenList } from "../../Api/TokenActions";
import { getEstimateFee } from "../../ContractActions/limitorderActions";

import { checkNetwork } from "../../helper/connection"

const initialState = {
  fromaddress: "",
  fromdecimal: 0,
  fromsymbol: "",
  fromlogo: "",
  toaddress: "",
  todecimal: 0,
  tosymbol: "",
  tologo: "",
  limitPrice: 0,
  amount: 0,
  priceValue: 0,
  expire: "10M",
  pay: 0,
  rate: 0,
  receive: 0,
};
var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export default function Limitotc(props) {
  const { t } = useTranslation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const swapToken = useSelector((state) => state.swapToken);
  const marketPrice = useSelector((state) => state.marketPrice);
  const loadHistory = useSelector((state) => state.loadHistory);

  const [OrderDetails, setOrderDetails] = useState(initialState);
  const [isLoad, setisLoad] = useState(false);

  const [usertokenBal, setusertokenBal] = useState({
    fromBalanceOf: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toBalance: 0,
  });
  const [approveLoad, setapproveLoad] = useState(false);
  const [wrppedLoad, setwrppedLoad] = useState(false);
  const [swaperror, setswaperror] = useState("");
  const [tokenrate, settokenrate] = useState({ price: 0, price1: 0 });

  const [expireList, setexpireList] = useState([]);
  const [showExpire, setshowExpire] = useState({
    value: "Never",
    label: "Never",
  });

  const [switchorder, setswitchorder] = useState(false);

  const [wrapped, setwrapped] = useState(false);
  const [unlock, setunlock] = useState(false);
  const [insufficientBal, setinsufficientBal] = useState(false);
  const [reviewtrade, setreviewtrade] = useState(false);
  const [disablePrice, setdisablePrice] = useState(false);
  const [reversePrice, setreversePrice] = useState(false);

  const [currentId, setcurrentId] = useState("from");
  const [orderList, setorderList] = useState([]);
  const [orderLoaded, setorderLoaded] = useState(false);
  const [takeraddress, settakeraddress] = useState("");
  const [ratePercentage, setratePercentage] = useState(0);
  const [showpercentage, setshowpercentage] = useState(false); //display issues
  const [spender, setspender] = useState("");
  const [dollar, setdollar] = useState(0);
  const [errmsg, seterrmsg] = useState("");
  const [errmsg1, seterrmsg1] = useState("");
  const [isreload, setisreload] = useState(false);
  const [alreadyApproved, setalreadyApproved] = useState(0);
  const [totalApproved, settotalApproved] = useState(0);

  useEffect(() => {
    let amt =
      OrderDetails && OrderDetails.pay && parseFloat(OrderDetails.pay) > 0
        ? OrderDetails.pay
        : 1;
    loadInitialPriceData(amt, marketPrice.price);
    // eslint-disable-next-line
  }, [
    swapToken,
    marketPrice,
    walletConnection.address,
    walletConnection.network,
    loadHistory.status,
  ]);

  useEffect(() => {
    loaduserBal();
    // eslint-disable-next-line
  }, [swapToken, walletConnection.address]);

  useEffect(() => {
    reloadPrice();
    settakeraddress("");
    getAllOrder();
    // eslint-disable-next-line
  }, [loadHistory.status]);

  async function getAllOrder() {
    console.log("getAllOrder")
    if (walletConnection && walletConnection.address) {
      setOrderDetails({ ...OrderDetails, ...{ pay: "", rate: "", receive: "" } });
      settokenrate({ price: 0, price1: 0 });
      seterrmsg("");
      seterrmsg1("");
      setorderLoaded(false);
      var { orders } = await getLimitOrders(
        walletConnection.address,
        walletConnection.network
      );
      var lOrders = orders;
      var { orders } = await getP2POrders(
        walletConnection.address,
        walletConnection.network
      );
      var newList = lOrders.concat(orders);
      setorderList(newList);
      setorderLoaded(true);
      console.log("getAllOrder1")
    }

  }

  async function loaduserBal() {
    getUserBalances(swapToken, walletConnection, setusertokenBal);
  }

  const inputChange1 = async (event) => {
    var value = event.target.value;
    var network = walletConnection.network;
    var address = walletConnection.address;
    var isAddr = checkIsAddress(network, value);
    settakeraddress("");
    seterrmsg("");
    if (isAddr) {
      if (address.toLowerCase() == value.toLowerCase()) {
        seterrmsg(t("MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS"));
      } else {
        settakeraddress(value);
      }
    } else {
      seterrmsg("Enter valid transfer address");
    }
  };

  async function loadInitialPriceData(amount, mPrice) {

    let expire = limitOrderExpireOptions();
    setexpireList(expire);
    settokenrate({
      price: mPrice,
      price1: formatNumber(1 / parseFloat(mPrice), 5),
    });
    if (walletConnection.network) {
      var nwrk = walletConnection.network;
      setspender(config.limitorder[nwrk]);
    }
    // var orderInfo = OrderDetails;
    //if (OrderDetails && OrderDetails.fromaddress == "") {
    var orderInfo = { ...OrderDetails, ...swapToken };
    //}
    setOrderDetails(orderInfo);
    if (mPrice && parseFloat(mPrice) > 0) {
      let id = "pay";
      calculatePrice(id, amount, orderInfo, mPrice, orderLoaded);
    }
  }

  async function changeWrppedDetails(amount, mPrice) {
    settokenrate({
      price: mPrice,
      price1: formatNumber(1 / parseFloat(mPrice), 5),
    });
    if (walletConnection.network) {
      var nwrk = walletConnection.network;
      setspender(config.limitorder[nwrk]);
    }
    var data = {
      chainId: walletConnection.network,
      useraddress: walletConnection.address,
    };
    var coinAddr = config.coin[nwrk];
    var { result } = await GetTokenList(data);
    var list = JSON.parse(result);
    var index = list.findIndex(val => val.address.toLowerCase() == coinAddr.toLowerCase());
    var orderInfo = OrderDetails;
    if (index != -1) {
      var fInfo = {
        fromaddress: list[index].address,
        fromsymbol: list[index].symbol,
        fromdecimal: list[index].decimals,
        fromchainId: list[index].chainId
      }
      orderInfo = { ...OrderDetails, ...fInfo };
    }

    getUserBalances(orderInfo, walletConnection, setusertokenBal);

    if (mPrice && parseFloat(mPrice) > 0) {
      let id = "pay";
      calculatePrice(id, amount, orderInfo, mPrice, false);
    }

  }


  async function getLimitPriceData(amount, mPrice) {

    settokenrate({
      price: mPrice,
      price1: formatNumber(1 / parseFloat(mPrice), 5),
    });
    if (walletConnection.network) {
      var nwrk = walletConnection.network;
      setspender(config.limitorder[nwrk]);
    }
    // var orderInfo = { ...OrderDetails, ...swapToken };
    // setOrderDetails(orderInfo);
    if (mPrice && parseFloat(mPrice) > 0) {
      let id = "pay";
      calculatePrice(id, amount, OrderDetails, mPrice, false);
    }
  }

  async function getReceivedUsd(amt, toaddress) {
    if (toaddress && amt) {
      var id = config.coingeckoId[OrderDetails.fromchainId];
      //var val = await getTokenRate(id, toaddress);
      // var cal = parseFloat(val) * parseFloat(amt);
      setdollar(0);
    }
  }

  function reloadPrice() {
    setisreload(true);

    if (marketPrice && marketPrice.price && parseFloat(marketPrice.price) > 0) {
      var youPay = OrderDetails.pay;
      var youReceive = 0;
      let mPrice = switchorder
        ? 1 / parseFloat(marketPrice.price)
        : marketPrice.price;
      var cal = 1 / parseFloat(mPrice);
      cal = youPay / cal;
      youReceive = cal;

      let data = {
        pay: youPay,
        rate: mPrice,
        receive: youReceive,
      };
      setOrderDetails({ ...OrderDetails, ...data });
    }
    setTimeout(function () {
      setisreload(false);
    }, 500);
  }

  const inputChange = async (event) => {
    var value = event.target.value;
    var id = event.target.id;
    var status = await numberFloatOnly(value);
    if (status) {
      var mPrice = (OrderDetails && OrderDetails.rate) ? OrderDetails.rate : marketPrice.price;
      var isOrderLoad = orderLoaded;
      calculatePrice(id, value, OrderDetails, mPrice, isOrderLoad);
    } else {
      seterrmsg1("");
      var newObj = {
        pay: "",
        rate: "",
        receive: "",
      }
      setOrderDetails({ ...OrderDetails, ...newObj });
      setwrapped(false);
      setunlock(false);
      setreviewtrade(false);
      setinsufficientBal(false);
      setswaperror("");
    }

  };

  useEffect(() => {
    if (swaperror != "") {
      setswaperror(t('INSUFFICIENT_BALANCE'));
    } else {
      setswaperror("");
    }
  }, [t, setswaperror]);


  const calculatePrice = async (id, value, orderInfo, mPrice, isOrderLoad) => {

    try {
      // var id = event.target.id;
      // var value = event.target.value;

      var rate = mPrice;
      rate = id == "rate" ? value : rate;

      if (id == "rate") {
        var checkDeci = value.toString();
        checkDeci = checkDeci.split(".");
        if (value.length > 12 && checkDeci.length == 1) {
          return;
        }
      }

      var pay =
        orderInfo && orderInfo.pay && parseFloat(orderInfo.pay) > 0
          ? parseFloat(orderInfo.pay)
          : 0;
      pay = id == "pay" ? value : pay;

      var receive =
        orderInfo && orderInfo.receive && parseFloat(orderInfo.receive) > 0
          ? parseFloat(orderInfo.receive)
          : 0;
      receive = id == "receive" ? value : receive;

      var newObj = {
        pay,
        rate,
        receive,
      };

      if (id == "pay") {
        var rcal1 = pay * rate;
        if (switchorder) {
          rcal1 = pay / rate;
        }
        newObj.pay = pay;
        newObj.rate = rate;
        newObj.receive = rcal1;
      } else if (id == "rate") {
        var rcal2 = pay * rate;
        if (switchorder) {
          rcal2 = pay / rate;
        }
        newObj.pay = pay;
        newObj.rate = rate;
        newObj.receive = rcal2;

        settokenrate({
          price: rate,
          price1: formatNumber(1 / parseFloat(rate), 5),
        });
      } else if (id == "receive") {
        var rcal3 = receive / pay;
        if (switchorder) {
          rcal3 = rate;
        }
        newObj.pay = pay;
        newObj.rate = rcal3;
        newObj.receive = receive;
      }

      var v1 = parseFloat(rate);
      if (id == "receive") {
        v1 = parseFloat(receive);
      }
      let v2 = parseFloat(mPrice);
      var percentage = (v1 - v2) / v2;
      percentage = percentage * 100;
      percentage = percentage.toFixed(2);
      setratePercentage(percentage);
      var status = percentage == 0 ? false : true;
      setshowpercentage(status);
      setOrderDetails({ ...orderInfo, ...newObj });

      var details = {
        fromaddress: orderInfo.fromaddress,
        fromdecimal: orderInfo.fromdecimal,
        fromsymbol: orderInfo.fromsymbol,
        toaddress: orderInfo.toaddress,
        receive: newObj.receive,
        pay: newObj.pay,
      };
      validateOrder(details, isOrderLoad);
    } catch (err) {
      console.log(err, "errerrerr");
    }
  };

  async function validateOrder(details, isOrderLoad) {

    let toaddress = details.toaddress;
    getReceivedUsd(details.receive, toaddress);

    var nwrk = walletConnection.network;
    var coinAddr =
      details.fromaddress.toLowerCase() === coinAddress &&
        config &&
        config.coin &&
        config.coin[nwrk]
        ? config.coin[nwrk]
        : details.fromaddress;

    let allowanceAddr = {
      fromaddress: coinAddr,
    };
    var limitContract = config.limitorder[nwrk];

    var alreadyUsedAmt = 0; //already submitted limit,otc order amount
    var tokenDeci = parseFloat(details.fromdecimal);
    var allorders = [];
    if (walletConnection && walletConnection.address) {
      if (!isOrderLoad) {
        var { orders } = await getLimitOrders(
          walletConnection.address,
          walletConnection.network
        );
        var lOrders = orders;

        var { orders } = await getP2POrders(
          walletConnection.address,
          walletConnection.network
        );

        //lOrders.concat(orders);
        var newList = lOrders.concat(orders);
        setorderList(newList);
        setorderLoaded(true);

        allorders = newList;
      } else {
        allorders = orderList;
      }

      var getAlready = allorders.filter(
        (data) =>
          data.makerAsset.toLowerCase() == coinAddr.toLowerCase() &&
          data.state == "PENDING"
      );

      if (getAlready && getAlready.length > 0) {
        for (var o = 0; o < getAlready.length; o++) {
          var fillableBalance = getAlready && getAlready[o] && getAlready[o].fillableBalance
            ? (getAlready[o].fillableBalance)
            : 0;
          var usedAmt = divideValue(fillableBalance, 10 ** tokenDeci)
          alreadyUsedAmt = addValue(alreadyUsedAmt, usedAmt);
        }
      }
    }

    setalreadyApproved(alreadyUsedAmt);
    var { fromAllowance, fromBalance, coinBalance } =
      await validateAllowanceBalance(
        allowanceAddr,
        walletConnection,
        limitContract
      );

    fromAllowance = fromAllowance / 10 ** tokenDeci;
    if (fromAllowance > 0) {
      fromAllowance = (alreadyUsedAmt >= fromAllowance) ?
        subtractValue(alreadyUsedAmt, fromAllowance) : subtractValue(fromAllowance, alreadyUsedAmt);
    }

    var totalAllowance = addValue(alreadyUsedAmt, details.pay);

    settotalApproved(totalAllowance)

    seterrmsg1("");

    setwrapped(false);
    setunlock(false);
    setinsufficientBal(false);
    setreviewtrade(false);
    setswaperror("");
    var youPay = details.pay;

    if (details.fromaddress.toLowerCase() === coinAddress) {

      let totalAvlBal = parseFloat(fromBalance) + coinBalance;
      let remainingBal = subtractValue(totalAvlBal, alreadyUsedAmt);
      let availableWrapped = subtractValue(fromBalance, alreadyUsedAmt);

      if (parseFloat(youPay) > remainingBal && alreadyUsedAmt > 0) {
        seterrmsg1(
          `${t("INSUFFICIENT")} ${details.fromsymbol} ${t(
            "BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF"
          )} ${formatNumber(parseFloat(alreadyUsedAmt), 6)}`
        );
      }
      console.log(alreadyUsedAmt, 'alreadyUsedAmtalreadyUsedAmt', availableWrapped, fromBalance)
      if (parseFloat(youPay) <= parseFloat(coinBalance) || parseFloat(youPay) <= parseFloat(fromBalance)) {
        if (parseFloat(availableWrapped) < parseFloat(youPay)) {
          setwrapped(true);
        } else if (parseFloat(youPay) > parseFloat(fromAllowance)) {
          setunlock(true);
        } else {
          setreviewtrade(true);
        }
      } else {
        setinsufficientBal(true);
        setswaperror(t('INSUFFICIENT_BALANCE'));
      }
    } else {
      let totalAvlBal = parseFloat(fromBalance);
      let remainingBal = totalAvlBal - alreadyUsedAmt;

      if (parseFloat(youPay) > remainingBal && alreadyUsedAmt > 0) {
        seterrmsg1(
          `${t("INSUFFICIENT")} ${details.fromsymbol} ${t(
            "BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF"
          )} ${formatNumber(parseFloat(alreadyUsedAmt), 6)}`
        );
      }

      if (youPay > fromBalance) {
        setinsufficientBal(true);
        setswaperror(t('INSUFFICIENT_BALANCE'));
      } else if (youPay > fromAllowance) {
        setunlock(true);
      } else {
        setreviewtrade(true);
      }
    }
    setwrppedLoad(false)
  }

  function interChange() {
    setisreload(true);

    var details = {
      fromaddress: OrderDetails.toaddress,
      fromchainId: OrderDetails.tochainId,
      fromdecimal: OrderDetails.todecimal,
      pay: OrderDetails.receive,
      fromlogo: OrderDetails.tologo,
      fromname: OrderDetails.toname,
      fromsymbol: OrderDetails.tosymbol,
      toaddress: OrderDetails.fromaddress,
      tochainId: OrderDetails.fromchainId,
      todecimal: OrderDetails.fromdecimal,
      receive: OrderDetails.pay,
      tologo: OrderDetails.fromlogo,
      toname: OrderDetails.fromname,
      tosymbol: OrderDetails.fromsymbol,
      rate: 1 / parseFloat(OrderDetails.rate),
    };
    setOrderDetails({ ...OrderDetails, ...details });

    validateOrder(details);

    getUserBalances(details, walletConnection, setusertokenBal);

    setTimeout(function () {
      setisreload(false);
    }, 2000);
  }

  async function setExpire(item) {
    var label = item.target.value;
    let val = label == "Minutes" || label == "Hours" ? "10" : "1";
    setshowExpire({ value: val, label: item.target.value });
  }

  function changeSwitch() {
    setswitchorder(!switchorder);
    let newObj = {
      rate: 1 / OrderDetails.rate,
    };
    setOrderDetails({ ...OrderDetails, ...newObj });
  }

  async function WrappedToken() {
    setwrppedLoad(true)
    window.$("#confirm_wrapped_modal").modal("show");
  }

  async function ApproveToken() {
    window.$("#confirm_approve_modal").modal("show");
  }

  function lockPrice() {
    setdisablePrice(!disablePrice);
  }

  function reverseData() {
    setreversePrice(!reversePrice);
  }

  const changeexpireTime = async (event) => {
    var value = event.target.value;
    var status = numberOnly(value);
    if (status && parseFloat(value) > 0) {
      setshowExpire({ ...showExpire, ...{ value: value } });
    } else {
      setshowExpire({ ...showExpire, ...{ value: "" } });
    }
  };
  const handleExpire = async (event) => {
    var value = event.target.value;
    if (value == "") {
      let val =
        showExpire.label == "Minutes" || showExpire.label == "Hours"
          ? "10"
          : "1";
      setshowExpire({ ...showExpire, ...{ value: val } });
    }
  };

  function selectedType(id) {
    setcurrentId(id);
  }

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      var network = walletConnection.network;
      var isAddr = checkIsAddress(network, text);
      settakeraddress("");
      if (isAddr) {
        settakeraddress(text);
      }
      seterrmsg("");

      if (text == walletConnection.address) {
        seterrmsg(t("MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS"));
      }
    } catch (err) { }
  };

  function removeTakerAddress() {
    settakeraddress("");
    seterrmsg("");
  }

  async function selectMax() {
    var txFee = 0;
    if (
      OrderDetails.fromaddress.toLowerCase() == coinAddress
    ) {
      var { txFee } = await getEstimateFee();
    }

    let fromBalanceOf =
      usertokenBal && usertokenBal.fromBalanceOf
        ? parseFloat(usertokenBal.fromBalanceOf)
        : 0;
    if (fromBalanceOf > 0) {

      if (fromBalanceOf > txFee) {

        fromBalanceOf = fromBalanceOf - txFee;

        let tokenDeci = parseFloat(swapToken.fromdecimal);
        let value = fromBalanceOf / 10 ** tokenDeci;
        value = formatNumber(value, 8);

        var mPrice = (OrderDetails && OrderDetails.rate) ? OrderDetails.rate : marketPrice.price;
        getLimitPriceData(value, mPrice);
      } else if (OrderDetails.fromaddress.toLowerCase() == coinAddress) {
        toastAlert("error", t("INSUFFICIENT_FOR_GASFEE"), "insufficient");
      }

    }
  }

  async function openTrade() {

    var { isNetwork, networkname } = await checkNetwork();
    if (!isNetwork) {
      toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
      return;
    }

    if (showExpire.label == "Minutes" && parseFloat(showExpire.value) < 10) {
      seterrmsg("Expiry must be at least ten minute");
      return;
    }

    if (location.pathname === "/p2p" || getParameterByName("page") == "p2p") {
      var network = walletConnection.network;
      var address = walletConnection.address;
      var isAddr = checkIsAddress(network, takeraddress);
      seterrmsg("");
      if (takeraddress == "" || !isAddr) {
        seterrmsg("Enter valid transfer address");
        return;
      } else if (takeraddress.toLowerCase() == address.toLowerCase()) {
        seterrmsg(t("MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS"));
        return;
      }
    }

    var nwrk = walletConnection.network;
    var fromaddress =
      OrderDetails.fromaddress.toLowerCase() === coinAddress &&
        config &&
        config.coin &&
        config.coin[nwrk]
        ? config.coin[nwrk]
        : OrderDetails.fromaddress;

    var toaddress =
      OrderDetails.toaddress.toLowerCase() === coinAddress &&
        config &&
        config.coin &&
        config.coin[nwrk]
        ? config.coin[nwrk]
        : OrderDetails.toaddress;

    var mAmount = parseFloat(OrderDetails.pay);
    var mDeci = parseFloat(OrderDetails.fromdecimal);
    mAmount = multiplyValue(mAmount, 10 ** parseFloat(mDeci));
    var tAmount = parseFloat(OrderDetails.receive);
    var tDeci = parseFloat(OrderDetails.todecimal);
    tAmount = multiplyValue(tAmount, 10 ** parseFloat(tDeci));

    var orderType = location.pathname === "/p2p" ? "P2P" : "LIMIT";

    var pendingOrders = orderList.filter(
      (data) => data.state == "PENDING" && data.type == orderType
    );

    var index = pendingOrders.findIndex(
      (val) =>
        val.makerAsset.toLowerCase() == fromaddress.toLowerCase() &&
        val.takerAsset.toLowerCase() == toaddress.toLowerCase() &&
        val.makerAmount.toLowerCase() == mAmount.toString() &&
        val.takerAmount.toLowerCase() == tAmount.toString()
    );

    if (index == -1) {
      window.$("#confirm_trade_modal").modal("show");
    } else {
      toastAlert("error", t("DUPLICATE_ORDER_SUBMIT"), "order");
    }
  }

  function ModalClose() {
    setwrppedLoad(false);
  }

  return (
    <div className="swap_box">
      <div className="heading_block heading_block_chg">
        <SwapMenu />
        <div>
          <i>
            <img
              src={require("../../assets/images/refresh_icon.png")}
              alt="Refresh"
              onClick={() => reloadPrice()}
            />
          </i>
          {/* <i data-bs-toggle="modal" data-bs-target="#settings_modal">
                      <img
                        src={require("../../assets/images/settings_icon.png")}
                        alt="Settings"
                      />
                    </i> */}
          <i data-bs-toggle="modal" data-bs-target="#settings_modal">
            <img
              src={require("../../assets/images/settings_icon.png")}
              alt="Settings"
            />
          </i>
        </div>
      </div>

      <div className="swap_container swap_container_chg swap_container_limit">
        <div className="swap_select_token_wrap">
          <div className="swap_select_token_inner">
            <div>
              <label>{t("YOU_PAY")}</label>
              <label className="d-flex align-items-center">
                <img
                  src={require("../../assets/images/wallet_icon.png")}
                  alt="wallet"
                  className="me-2"
                />
                <span>{usertokenBal.fromBalance}</span>
              </label>
            </div>
            <div>
              <input
                className="swap_token_input text-start"
                id="pay"
                pattern="[0-9]*"
                type="text"
                min="0"
                onChange={inputChange}
                value={OrderDetails.pay}
              />

              <div className="maxtoken_wrap">
                <label onClick={() => selectMax()}>{t("MAX")}</label>
                <div
                  className="swap_select_token"
                  data-bs-toggle="modal"
                  data-bs-target="#select_token_modal"
                  onClick={() => selectedType("from")}
                >
                  <div className="swap_select_token_option">
                    <img
                      src={getTokenLogo(
                        OrderDetails.fromlogo,
                        OrderDetails.fromchainId
                      )}
                      alt={OrderDetails.fromsymbol}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                    <span className="swap_token_title">
                      {OrderDetails.fromsymbol}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="swap_token_bal">{OrderDetails.fromname}</div>
            </div>
          </div>
        </div>
        <div className="swap_arrow_container">
          {isreload ? (
            <img
              src={require("../../assets/images/swap_icon.png")}
              alt="Crypto Coin"
            />
          ) : (
            <img
              src={require("../../assets/images/swap_icon.png")}
              alt="Crypto Coin"
              onClick={() => interChange()}
            />
          )}
        </div>
        <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
          <div className="swap_select_token_inner">
            <div>
              <label>{t("YOU_RECEIVE")}</label>
              <label className="d-flex align-items-center">
                <img
                  src={require("../../assets/images/wallet_icon.png")}
                  alt="wallet"
                  className="me-2"
                />
                <span>{usertokenBal.toBalance}</span>
              </label>
            </div>
            <div>
              <input
                className="swap_token_input text-start"
                id="receive"
                pattern="[0-9]*"
                type="number"
                min="0"
                value={OrderDetails.receive}
                onChange={inputChange}
              />

              <div
                className="swap_select_token swap_select_token_nobg"
                data-bs-toggle="modal"
                data-bs-target="#select_token_modal"
                onClick={() => selectedType("to")}
              >
                <div className="swap_select_token_option">
                  {OrderDetails.tosymbol && OrderDetails.tosymbol != "" && (
                    <img
                      src={getTokenLogo(
                        OrderDetails.tologo,
                        OrderDetails.tochainId
                      )}
                      alt={OrderDetails.tosymbol}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                  )}
                  <span className="swap_token_title">
                    {OrderDetails.tosymbol
                      ? OrderDetails.tosymbol
                      : "Select token"}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="swap_token_bal">{OrderDetails.toname}</div>
              <div className="swap_token_bal">~${formatNumber(dollar, 5)}</div>
            </div>
          </div>
        </div>
        <div className="otc_last">
          <div className="otc_last_left">
            <div className="d-flex align-items-center justify-content-between">
              <label className="mb-0">
                {t("RATE")}{" "}
                {showpercentage && (
                  <span
                    className={
                      parseFloat(ratePercentage) > 0
                        ? "positiveper"
                        : "negativeper"
                    }
                  >
                    {parseFloat(ratePercentage) > 0 ? "+" : ""}
                    {ratePercentage && !isNaN(ratePercentage)
                      ? ratePercentage
                      : 0}
                    %
                  </span>
                )}
              </label>
              <div className="rate_lock">
                <img
                  src={require("../../assets/images/switch_icon.png")}
                  alt="Icon"
                  className="img-fluid"
                  onClick={() => changeSwitch()}
                />
                <img
                  src={require("../../assets/images/lock_icon.png")}
                  alt="Icon"
                  className={
                    disablePrice
                      ? "img-fluid lock_icon active"
                      : "img-fluid lock_icon"
                  }
                  onClick={() => lockPrice()}
                />
              </div>
            </div>
            <div className="input-group slipage_inp_grp">
              <input
                type="number"
                className={
                  disablePrice ? "form-control disabled_input" : "form-control"
                }
                aria-label="slipage"
                aria-describedby="slipage"
                placeholder="0.00"
                onChange={inputChange}
                id="rate"
                maxLength={5}
                value={formatNumber(OrderDetails.rate, 5)}
                disabled={disablePrice}
              />
              <span className="input-group-text" id="slipage">
                {OrderDetails.tosymbol}{" "}
                {isreload && <i className="fas fa-spinner fa-spin"></i>}
              </span>
            </div>
          </div>
          <div className="otc_last_right">
            <label className="mb-0">{t("EXPIRY")}</label>
            <div className="input-group slipage_inp_grp">
              {showExpire.label !== "Never" && (
                <input
                  type="number"
                  className="form-control"
                  aria-label="slipage"
                  aria-describedby="slipage"
                  value={showExpire.value}
                  onChange={changeexpireTime}
                  onBlur={handleExpire}
                  placeholder={
                    showExpire.label == "Minutes" || showExpire.label == "Hours"
                      ? "10"
                      : "1"
                  }
                />
              )}
              {showExpire.label == "Never" && (
                <input
                  type="number"
                  className="form-control disabled_input"
                  aria-label="slipage"
                  aria-describedby="slipage"
                  disabled
                />
              )}
              <span className="input-group-text ps-0">
                <select
                  value={showExpire.label}
                  onChange={setExpire}
                  id="options"
                >
                  <option value="Never">{t("NEVER")}</option>
                  <option value="Minutes">{t("MINUTES")}</option>
                  <option value="Hours">{t("HOURS")}</option>
                  <option value="Days">{t("DAYS")}</option>
                  <option value="Months">{t("MONTHS")}</option>
                </select>
              </span>
            </div>
          </div>
        </div>

        {location.pathname === "/p2p" || getParameterByName("page") == "p2p" ? (
          <>
            <div>
              <label className="text-white">{t("TAKER_ADDRESS")}</label>
              <div className="taker_address_inpgrp">
                <input
                  className="swap_token_input text-start"
                  id="transferaddress"
                  type="text"
                  placeholder={`${t("RECEIVERS")} ${getNetworkName(
                    walletConnection.network
                  )} ${t("WALETADDRESS")}`}
                  onChange={inputChange1}
                  value={takeraddress}
                />
                {!takeraddress || takeraddress == "" ? (
                  <button onClick={() => handlePaste()}>{t("PASTE")}</button>
                ) : (
                  <button onClick={() => removeTakerAddress()}>
                    {t("REMOVE")}
                  </button>
                )}
              </div>
            </div>
            {errmsg && errmsg != "" ? (
              <p className="text-danger mb-1 mt-2 text-center">{errmsg}</p>
            ) : errmsg1 && errmsg1 != "" ? (
              <p className="text-danger mb-1 mt-2 text-center">{errmsg1}</p>
            ) : (
              ""
            )}
            <div className="otc_disclaimer">
              <div>
                <h5>{t("DISCLAIMER")}</h5>
                <img
                  src={require("../../assets/images/info_icon.png")}
                  alt="Icon"
                  className="img-fluid"
                />
              </div>
              <p className="yellow_txt">{t("FEATURE_TO_TRANSFER")}</p>
              <p>{t("IMPOSSIBLE_TO_RETRIEVE")}</p>
            </div>
          </>
        ) : errmsg && errmsg != "" ? (
          <p className="text-danger mb-1 mt-2 text-center">{errmsg}</p>
        ) : errmsg1 && errmsg1 != "" ? (
          <p className="text-danger mb-1 mt-2 text-center">{errmsg1}</p>
        ) : (
          ""
        )}
        {walletConnection && walletConnection.connect === "no" ? (
          <div className="text-center">
            <button
              className="connect_wallet_btn w-100"
              data-bs-toggle="modal"
              data-bs-target="#connect_wallet_modal"
            >
              {t("CONNECT_WALLET")}
            </button>
          </div>
        ) : swaperror && swaperror != "" ? (
          <div className="text-center">
            <button className="connect_wallet_btn w-100">{swaperror}</button>
          </div>
        ) : wrapped && parseFloat(OrderDetails.rate) > 0 &&
          parseFloat(OrderDetails.receive) > 0 &&
          parseFloat(OrderDetails.receive) > 0 ? (
          <div className="modal_grp_btns">
            <button
              className="connect_wallet_btn w-100"
              onClick={() => WrappedToken()}
              disabled={
                (errmsg && errmsg != "") || (errmsg1 && errmsg1 != "") || (wrppedLoad)
                  ? true
                  : false
              }
            //disabled={((takeraddress == "" || takeraddress == walletConnection.address)) ? true : false}
            >
              {
                wrppedLoad ? <i className="fas fa-spinner fa-spin"></i> : t("WRAP") + " " + OrderDetails.fromsymbol
              }

            </button>
            <button className="connect_wallet_btn w-100 disabledbinance" disabled={true}>
              {t("REVIEW_TRADE")}
            </button>
          </div>
        ) : unlock && parseFloat(OrderDetails.rate) > 0 &&
          parseFloat(OrderDetails.receive) > 0 &&
          parseFloat(OrderDetails.receive) > 0 ? (
          <div className="modal_grp_btns">
            <button
              className="connect_wallet_btn w-100"
              onClick={() => ApproveToken()}
              disabled={
                (errmsg && errmsg != "") || (errmsg1 && errmsg1 != "")
                  ? true
                  : false
              }
            // disabled={((takeraddress == "" || takeraddress == walletConnection.address)) ? true : false}
            >
              {(approveLoad) ? <i className="fas fa-spinner fa-spin"></i> : t("UNLOCK") + " " + OrderDetails.fromsymbol}

            </button>
            <button className="connect_wallet_btn w-100 disabledbinance" disabled={true}>
              {t("REVIEW_TRADE")}
            </button>
          </div>
        ) : OrderDetails.rate === "" ||
          OrderDetails.rate <= 0 ||
          OrderDetails.receive === "" ||
          OrderDetails.pay === "" ||
          OrderDetails.receive <= 0 ||
          OrderDetails.pay <= 0 ? (
          <div className="text-center">
            <button className="connect_wallet_btn w-100">
              {t("ENTER_AMT")}
            </button>
          </div>
        ) : reviewtrade &&
          parseFloat(OrderDetails.rate) > 0 &&
          parseFloat(OrderDetails.receive) > 0 &&
          parseFloat(OrderDetails.receive) > 0 ? (
          <div className="text-center">
            <button
              className={(errmsg1) ? "connect_wallet_btn w-100 disabledbinance" : "connect_wallet_btn w-100"}
              onClick={() => openTrade()}
              disabled={errmsg1 ? true : false}
            >
              {t("REVIEW_TRADE")}
            </button>
          </div>
        ) : (
          <div className="text-center">
            <button className="connect_wallet_btn w-100">
              <i className="fas fa-spinner fa-spin"></i>
            </button>
          </div>
        )}

        <div className="swap_info">
          {reversePrice ? (
            <div onClick={() => reverseData()} className="order_rate">
              <span>1 {OrderDetails.tosymbol} </span>
              <span>
                {tokenrate.price1} {OrderDetails.fromsymbol}
              </span>
            </div>
          ) : (
            <div onClick={() => reverseData()} className="order_rate">
              <span>1 {OrderDetails.fromsymbol} </span>
              <span>
                {tokenrate.price} {OrderDetails.tosymbol}
              </span>
            </div>
          )}
          <div>
            <span>{t("NETWORK_FEES")}</span>
            <span className="d-flex align-items-center">
              <img
                src={require("../../assets/images/gift.png")}
                alt="img"
                className="me-2"
                width={"30px"}
                height={"30px"}
              />
              {t("FREE")}
            </span>
          </div>
        </div>
      </div>

      {/* Select Token Modal */}
      <TokenList id={currentId} />
      <WrappedTokenModal
        wrappedDetails={OrderDetails}
        changeWrppedDetails1={changeWrppedDetails}
        ModalClose1={ModalClose}
      />
      <UnlockTokenModal
        OrderDetails={OrderDetails}
        getLimitPriceData1={getLimitPriceData}
        spender={spender}
        alreadyApproved={alreadyApproved}
        totalApproved={totalApproved}
      />
      <ReviewTradeModal
        swapDetails={OrderDetails}
        getLimitPriceData1={getLimitPriceData}
        walletConnection={walletConnection}
        tokenrate={tokenrate}
        showExpire={showExpire}
        takeraddress={takeraddress}
        getAllOrder1={getAllOrder}
      />

      {/* Settings Modal */}
      <Settings reCheck={reloadPrice} />
    </div>
  );
}
