var cIds = {
    1: "ethereum",
    56: "binance-smart-chain",
    137: "polygon-pos",
    43114: "avalanche",
    250: "fantom",
    42161: "arbitrum-one",
    10: "optimistic-ethereum",
    128: "optimistic-ethereum",
    8453: "base",
};
export default cIds;