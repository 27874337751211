var txLink = {
    1: "https://etherscan.io/",
    56: "https://bscscan.com/",
    137: "https://polygonscan.com/",
    43114: "https://snowtrace.io/",
    250: "https://ftmscan.com/",
    42161: "https://arbiscan.io/",
    10: "https://optimistic.etherscan.io/",
    128: "https://www.hecoinfo.com/en-us/",
    8453: "https://basescan.org/"
}
export default txLink;