import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const getHomepageText = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${baseUrl}/getHomePage-text?lancode=` + data.lancode,
    });

    return {
      loading: true,
      result:
        respData && respData.data && respData.data.result
          ? respData.data.result
          : {},
    };
  } catch (error) {
    console.log("ERRORrouttes:  ", error);
    return {
      loading: true,
      error: returnErr(error),
      result: null,
    };
  }
};

export const getsignmessages = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${baseUrl}/get-sign-messages?lancode=` + data.lancode,
    });

    return {
      loading: true,
      result:
        respData && respData.data && respData.data.result
          ? respData.data.result
          : {},
    };
  } catch (error) {
    console.log("ERRORrouttes:  ", error);
    return {
      loading: true,
      error: returnErr(error),
      result: null,
    };
  }
};
export const getCms = async () => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `${baseUrl}/get-bridge-cms`,

    });

    return {
      loading: true,
      result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
      footerresult: (respData && respData.data && respData.data.footerresult) ? respData.data.footerresult : {},

    }
  }
  catch (err) {
    console.log(err, 'errerrerr getRoutes')
    return {
      loading: true,
      error: returnErr(err),
      result: null
    }
  }
}
export const getPrivacyterms = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${baseUrl}/get-privacy-terms?lancode=` + data.lancode,
    });

    return {
      loading: true,
      result:
        respData && respData.data && respData.data.result
          ? respData.data.result
          : {},
    };
  } catch (error) {
    console.log("ERRORrouttes:  ", error);
    return {
      loading: true,
      error: returnErr(error),
      result: null,
    };
  }
};
function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}
