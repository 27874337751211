import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";

import {
  dropdownLogo,
  getCoinName,
  getNetworkName,
} from "../../helper/networkdata";
import { convertWrapped } from "../../ContractActions/limitorderActions";
import { useTranslation } from "react-i18next";
import { toastAlert } from "../../helper/toastAlert";
import config from "../../config/config";
import { checkNetwork } from "../../helper/connection";

import {
  formatNumber
} from "../../helper/custommath";

export default function WrappedTokenModal(props) {
  let { wrappedDetails, changeWrppedDetails1, ModalClose1 } = props;
  const { t } = useTranslation();

  const walletConnection = useSelector((state) => state.walletConnection);
  const [swapLoad, setswapLoad] = useState(false);

  useEffect(() => {
  }, []);

  async function triggerWrapped() {
    setswapLoad(true);
    try {
      var { isNetwork, networkname } = await checkNetwork();
      if (!isNetwork) {
        setswapLoad(false);
        toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
        return;
      }

      if (!wrappedDetails.pay || parseFloat(wrappedDetails.pay) == 0) {
        setswapLoad(false);
        window.$("#confirm_wrapped_modal").modal("hide");
        return;
      }
      var nwrk = walletConnection.network;
      var coinAddr = config.coin[nwrk];

      let details = {
        contract: coinAddr,
        amount: wrappedDetails.pay
      }
      var { status, error } = await convertWrapped(details);
      if (status) {
        setTimeout(function () {
          changeWrppedDetails1(wrappedDetails.pay, wrappedDetails.rate);
          window.$("#confirm_wrapped_modal").modal("hide");
          setswapLoad(false);
        }, 6000);

      } else if (error) {
        setswapLoad(false);
        toastAlert("error", t(error), "swaperror");
      }
    } catch (err) {
      setswapLoad(false);
    }

  }

  function CloseToken() {
    ModalClose1();
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="confirm_wrapped_modal"
        tabIndex="-1"
        aria-labelledby="confirm_wrapped_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="confirm_wrapped_modalLabel">
                {t('WRAP')} {getCoinName(walletConnection.network)}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => CloseToken()}
              ></button>
            </div>
            <div className="modal-body text-center">
              <img
                src={dropdownLogo(walletConnection.network)}
                alt="Crypto Coin" width="80" className="mb-3"
              />
              <p>
                {t('NEED_TO_WRAP')} {formatNumber(wrappedDetails.pay, 8)}{" "}
                {getCoinName(walletConnection.network)} {t('USE_ITAS')}
                {getCoinName(walletConnection.network)} {t('NON_CUSTODIAL_LIMIT')}{" "}
                {getNetworkName(walletConnection.network)} {t('NETWORK_SM')}
              </p>

              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => triggerWrapped()}
                  disabled={swapLoad}
                >
                  {t('WRAP')} {wrappedDetails.pay} {wrappedDetails.fromsymbol}
                  {swapLoad && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
