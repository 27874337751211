import Web3 from "web3";
import axios from "axios";

import config from "../config/config";
import { convert } from "../helper/convert";

import BscIcon from "../assets/images/crypto/bsc.svg";
import ArbIcon from "../assets/images/crypto/arbitrum.svg";
import AVAXIcon from "../assets/images/crypto/avalanche.svg";
import BaseIcon from "../assets/images/crypto/base.svg";
import EthIcon from "../assets/images/crypto/eth.svg";
import FtmIcon from "../assets/images/crypto/fantom.svg";
import OptIcon from "../assets/images/crypto/optimism.svg";
import PolygonIcon from "../assets/images/crypto/polygon.svg";

export function dropdownLogo(id) {

    var logo = BscIcon;
    id = parseInt(id);
    if (id === 1 || id === 5) {
        logo = EthIcon;
    } else if (id === 56 || id === 97) {
        logo = BscIcon;
    } else if (id === 137 || id === 80001) {
        logo = PolygonIcon;
    } else if (id === 250 || id === 250) {
        logo = FtmIcon;
    } else if (id === 43114 || id === 43113) {
        logo = AVAXIcon;
    } else if (id === 42161 || id === 421613) {
        logo = ArbIcon;
    } else if (id === 10 || id === 420) {
        logo = OptIcon;
    } else if (id === 128) {
        logo = "heco.png";
    } else if (id === 8453) {
        logo = BaseIcon;
    }
    return logo;

}

export function getNetworkName(id) {
    id = parseInt(id);
    var text = "BNB Chain";
    try {
        if (id === 1 || id === 5) {
            text = "Ethereum";
        } else if (id === 56 || id === 97) {
            text = "BNB Chain";
        } else if (id === 137 || id === 80001) {
            text = "Polygon";
        } else if (id === 250 || id === 250) {
            text = "Fantom";
        } else if (id === 43114 || id === 43113) {
            text = "Avalanche";
        } else if (id === 42161 || id === 421613) {
            text = "Arbitrum";
        } else if (id === 10 || id === 420) {
            text = "Optimism";
        } else if (id === 128) {
            text = "Heco";
        } else if (id === 8453) {
            text = "Base";
        }
        return text;
    } catch (err) {
        return text;
    }
}

export async function getGasFee(id) {

    var suggestBaseFee, lowFee, marketFee, aggressiveFee = 0;

    var { gwei } = await getCurrentGas(id);

    if (id === 1) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(1);
        marketFee = suggestBaseFee + parseFloat(1.5);
        aggressiveFee = suggestBaseFee + parseFloat(12);
    } else if (id === 56) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(0);
        marketFee = suggestBaseFee + parseFloat(1);
        aggressiveFee = suggestBaseFee + parseFloat(3);
    } else if (id === 137) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(40);
        marketFee = suggestBaseFee + parseFloat(60);
        aggressiveFee = suggestBaseFee + parseFloat(300);
    } else if (id === 43114) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(5);
        marketFee = suggestBaseFee + parseFloat(27);
        aggressiveFee = suggestBaseFee + parseFloat(45);
    } else if (id === 250) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(5);
        marketFee = suggestBaseFee + parseFloat(27);
        aggressiveFee = suggestBaseFee + parseFloat(45);
    } else if (id === 42161) {
        let PRIORITY_FEE = 0.1;
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(0);
        marketFee = suggestBaseFee + parseFloat(PRIORITY_FEE) + 0.01;
        aggressiveFee = suggestBaseFee + parseFloat(PRIORITY_FEE) + 0.02;
    } else if (id === 10) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(0);
        marketFee = suggestBaseFee * 2;
        aggressiveFee = suggestBaseFee * 6;
    } else if (id === 128) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(0);
        marketFee = suggestBaseFee * 2;
        aggressiveFee = suggestBaseFee * 6;
    } else if (id === 8453) {
        suggestBaseFee = gwei;
        lowFee = suggestBaseFee + parseFloat(0);
        marketFee = suggestBaseFee * 2;
        aggressiveFee = suggestBaseFee * 6;
    }

    suggestBaseFee = await convert(suggestBaseFee);
    lowFee = await convert(lowFee);
    marketFee = await convert(marketFee);
    aggressiveFee = await convert(aggressiveFee);

    return {
        suggestBaseFee,
        lowFee,
        marketFee,
        aggressiveFee
    };

}

export function getCoinName(id) {

    id = parseInt(id);
    var text = "";
    try {
        if (id === 1) {
            text = "ETH";
        } else if (id === 56) {
            text = "BNB";
        } else if (id === 137) {
            text = "MATIC";
        } else if (id === 250) {
            text = "FTM";
        } else if (id === 43114) {
            text = "AVAX";
        } else if (id === 42161) {
            text = "ARB";
        } else if (id === 420) {
            text = "OP";
        } else if (id === 128) {
            text = "HT";
        } else if (id === 8453) {
            text = "ETH";
        }
        return text;
    } catch (err) {
        return text;
    }
}

export function getTxName(id) {
    id = parseInt(id);
    var text = "";
    try {
        if (id === 1) {
            text = "Etherscan";
        } else if (id === 56) {
            text = "BscScan";
        } else if (id === 137) {
            text = "Polyscan";
        } else if (id === 250) {
            text = "Ftmscan";
        } else if (id === 43114) {
            text = "Snowtrace";
        } else if (id === 42161) {
            text = "Arbitrum";
        } else if (id === 10) {
            text = "Optimism";
        } else if (id === 128) {
            text = "Heco";
        } else if (id === 8453) {
            text = "Basescan";
        }
        return text;
    } catch (err) {
        return text;
    }
}

export function getChartId(id) {
    id = parseInt(id);
    var text = "";
    try {
        if (id === 1) {
            text = "ethereum";
        } else if (id === 56) {
            text = "binance-smart-chain";
        } else if (id === 137) {
            text = "polygon-pos";
        } else if (id === 250) {
            text = "fantom";
        } else if (id === 43114) {
            text = "avalanche";
        } else if (id === 42161) {
            text = "arbitrum-one";
        } else if (id === 10) {
            text = "optimistic-ethereum";
        } else if (id === 128) {
            text = "huobi-token";
        } else if (id === 8453) {
            text = "base";
        }
        return text;
    } catch (err) {
        return text;
    }
}

export function getWalletImages(name) {

    var image = "metamask.png";
    try {
        if (name === "MetaMask") {
            image = "metamask.png";
        } else if (name === "WalletConnect") {
            image = "wallet_connect.png";
        } else if (name === "Binance Wallet") {
            image = "bsc_wallet.png";
        }
        return image;
    } catch (err) {
        return image;
    }
}

export function getTxLink(id) {

    var link = "";
    id = parseInt(id);
    if (id === 1) {
        link = config.txLink[1];
    } else if (id === 56) {
        link = config.txLink[56];
    } else if (id === 137) {
        link = config.txLink[137];
    } else if (id === 250) {
        link = config.txLink[250];
    } else if (id === 43114) {
        link = config.txLink[43114];
    } else if (id === 42161) {
        link = config.txLink[42161];
    } else if (id === 10) {
        link = config.txLink[10];
    } else if (id === 128) {
        link = config.txLink[128];
    } else if (id === 8453) {
        link = config.txLink[8453];
    }
    return link;

}

export async function getCurrentGas(id) {
    try {
        var web3 = new Web3(config.rpc[id]);
        var GasPrice = await web3.eth.getGasPrice();
        GasPrice = (parseFloat(GasPrice) > 0) ? GasPrice : 5;
        return {
            GasPrice,
            gwei: parseFloat(GasPrice / 10 ** 9)
        }
    } catch (err) {
        return {
            GasPrice: 5 * 10 ** 9,
            gwei: 5
        }
    }

}

export function getTokenLogo(logoURI, id) {
    id = parseInt(id);
    var logo = config.defaultLogo;
    try {
        if (id === 1) {
            logo = config.imageUrl + "/tokens/eth/" + logoURI;
        } else if (id === 56) {
            logo = config.imageUrl + "/tokens/bsc/" + logoURI;
        } else if (id === 137) {
            logo = config.imageUrl + "/tokens/polygon/" + logoURI;
        } else if (id === 250) {
            logo = config.imageUrl + "/tokens/ftm/" + logoURI;
        } else if (id === 43114) {
            logo = config.imageUrl + "/tokens/avax/" + logoURI;
        } else if (id === 42161) {
            logo = config.imageUrl + "/tokens/arb/" + logoURI;
        } else if (id === 10) {
            logo = config.imageUrl + "/tokens/opt/" + logoURI;
        } else if (id === 8453) {
            logo = config.imageUrl + "/tokens/base/" + logoURI;
        }
        return logo;
    } catch (err) {
        return logo;
    }
}