export function getMinumumReceived(details) {
    var toAmt = (details.id == "from") ? parseFloat(details.todisplay) : parseFloat(details.fromdisplay);
    var slippageValue = parseFloat(details.slippageValue);

    let A = toAmt * slippageValue / 100;
    var minimumrecevied = toAmt - A;

    if (details.id === "to") {
        minimumrecevied = toAmt + A;
    }

    return minimumrecevied;
}

export function getPriceImpact(details) {
    var srcUSD = (details && details.srcUSD) ? parseFloat(details.srcUSD) : 0;
    var destUSD = (details && details.destUSD) ? parseFloat(details.destUSD) : 0;
    var priceImpact = (srcUSD - destUSD) / srcUSD * 100;
    priceImpact = (priceImpact > 0) ? priceImpact : 0;
    return priceImpact;
}
