// import constant
import {
    SIGNIN
} from '../constant';

const initialState = {
    isSign: false
};

const signIn = (state = initialState, action) => {

    switch (action.type) {
        case SIGNIN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default signIn;