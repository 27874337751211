export default {
  ABOUT_NEW_FEATURES: "az új funkciókról.",
  ACCEPT: "Elfogad",
  ACTIVE_ORDERS: "Aktív megrendelések",
  ADD_TOKEN: "Adjon hozzá egy tokent",
  AGGRESSIVE: "Agresszív",
  AND: "és",
  APPROVE: "Jóváhagy",
  ASSETS_ON_WAY: "A vagyona úton van, és be kell érkeznie",
  AUTO: "Autó",
  AVOID_TRANSFER:
    "Kérjük, kerülje az átutalást egy központosított csere -címre (Binance, Huobi ...). Ez nem támogatott szolgáltatás",
  BALANCE: "Egyensúly",
  BALANCE_ONE: "Egyensúly:",
  BLOG: "Elhelyezkedő",
  BRIDGE: "Híd",
  CANCEL: "Megszünteti",
  CANCELLATION_TRANSACTION_FEE:
    "A limitrendelés lemondása magában foglalja a tranzakciós díjak megfizetését.",
  CANCEL_ALL: "Az összes törlése",
  CANCEL_LIMIT_NOT_FILLED:
    "A limit megrendelést lemondhatja, amíg a megrendelést még nem töltötték meg.",
  CANCEL_LIMIT_ORDER: "Törölje a Limit ORDS -t",
  CANCEL_ORDER: "Megrendelés törlése",
  CANCEL_SELECTED: "Kiválasztott törlése",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Válassza a Hálózat lehetőséget",
  CLASSIC_MODE: "Klasszikus mód",
  CLEAR: "Egyértelmű",
  CONFIRM: "megerősít",
  CONFIRMATION_REJECTED: "A megerősítés elutasította.!",
  CONFIRM_SWAP: "Erősítse meg a cserét",
  CONNECT_WALLET: "Csatlakoztassa a pénztárcát",
  COPIED: "Másolt",
  CURRENT: "Jelenlegi",
  CUSTOM: "Egyedi",
  DAYS: "Napok",
  DEFAULT: "Mulasztás",
  DETAILS: "Részletek",
  DISCLAIMER: "Nyilatkozat",
  DISCONNECT_WALLET: "Csatlakoztassa le a pénztárcáját",
  DISMISS: "Elbocsát",
  DUPLICATE_ORDER_SUBMIT: "Másolatú megrendelés. Ezt már benyújtották",
  ENTER_AMT: "Adja meg az összeget",
  EXCHANGE: "Csere",
  EXPIRY: "LEJÁRAT",
  EXPIRY_SM: "Lejárat",
  FAILED_LOAD_CHART: "Nem sikerült betölteni a diagramot",
  FIND_BY_TOKEN_OR_STATUS: "Keresse meg a token vagy az állapot",
  INSUFFICIENT_BALANCE: "Fedezethiány",
  INSUFFICIENT: "Elégtelen",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "egyenleg a meglévő megrendelések egyensúlyának fedezésére",
  RECEIVERS: "Vevőkészülékek",
  WALETADDRESS: "Pénztárca címe",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Kérjük, fogadja el a szolgáltatási feltételeket és az adatvédelmi irányelveket",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "A készítő Takernek nem szabad ugyanaz a címe",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "A csúszási toleranciának 0,1% és 15% között kell lennie",
  SEARCH_A_TOKEN: "Keressen egy tokent",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Tranzakciója visszatér, ha az ár kedvezőtlenül változik, mint ez a százalék",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Beállítja a tranzakció gázárát.Magasabb GWEI = nagyobb sebesség.",
  REVIEW_OTC_ORDER: "Tekintse át az OTC megrendelését",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "A megrendeléskészítő által beállított lejárati időszak.Ebben az időszakban a megrendelés lejár és archiválódik.",
  SEARCH_A_LIQUIDITY_SOURCES: "Keressen likviditási forrásokat",

  FAST: "Gyors",
  FAST_AND_SECURE:
    "A láncok közötti eszközök biztonságos, gyors és legbiztonságosabb módja",
  FEATURE_TO_TRANSFER:
    "Kérjük, ne használja ezt a funkciót a tokenek átadására egy központosított csere címére.",
  FEES: "Díj",
  FILL_ORDER: "Kitöltési parancs",
  FILL_OTC_ORDER: "Töltse ki az OTC megrendelését",
  FREE: "Ingyenes",
  FROM: "Tól től",
  GAS_COST: "Gázköltség",
  GAS_DESTINATION: "Gáz a rendeltetési láncon",
  GAS_ON_DESTINATION: "Földgáz a rendeltetési helyen",
  GAS_PRICE: "Gázár",
  GOT_IT: "Megvan",
  GOT_PROBLEM_JUST: "Van egy probléma? Csak vegye fel a kapcsolatot.",
  HIGH: "Magas",
  HOURS: "Órák",
  IMPORT: "Behozatal",
  IMPOSSIBLE_TO_RETRIEVE:
    "Ez nem támogatott szolgáltatás. Ezenkívül kérjük, ellenőrizze duplán a kitöltött címet, a rossz címre küldött tokeneket lehetetlen lekérni.",
  INSTANT: "Azonnali",
  INSUFFICIENT_APPROVED_AMT: "Nem elegendő jóváhagyott összeg",
  INVALID_TOKEN_ADDRESS: "Érvénytelen token cím",
  KEEP_TRACK_NOTIFY:
    "A panel értesítésben nyomon követheti. Miután elkészült, értesítjük Önt.",
  KEEP_YOU_POSTED: "- Folytatjuk Önt.",
  LEARN_MORE: "Tudj meg többet",
  LEGACY: "Örökség",
  LIMIT: "Határ",
  LIMIT_ORDER: "határrend",
  LIMIT_PRICE: "Határár",
  LIMIT_PRICE_SM: "Határár",
  LIQUIDITY_SOURCES: "Likviditási források",
  LOW: "Alacsony",
  MAKER: "Készítő",
  MARKET: "Piac",
  MAX: "Maximum",
  MAX_BASE_FEE: "Max alapdíj",
  MAX_S: "maximum",
  MAX_TO_PAY: "Max.",
  MINIMUM_RECEIVED: "A kapott minimum",
  MINUTES: "Percek",
  MONTHS: "Hónapok",
  NEED_APPROVAL_MOVE: "Szükségünk van a jóváhagyásra a költözéshez",
  NEED_TO_WRAP: "Be kell csomagolnod",
  NETWORK_FEES: "Hálózati díjak",
  NETWORK_SM: "hálózat.",
  NEVER: "Soha",
  NONE: "Egyik sem",
  NON_CUSTODIAL_LIMIT:
    "A nem-testi limit sorrendben. Ez egy technikai követelmény a",
  NORMAL: "Normál",
  NO_ORDERS_YET: "Még nincs megrendelése",
  NO_RECORDS: "Nincs rekord",
  ON_UR_BEHALF: "az Ön nevében.",
  OOPS_WRONG: "Hoppá! Valami rosszul sült el.!",
  OPEN: "Nyisd ki",
  ORDER_DETAILS: "Rendelési részletek",
  ORDER_HISTORY: "Megrendelés előzményei",
  ORDER_PRICE: "Megrendelési ár",
  ORDER_ROUTING: "Megrendelés útválasztás",
  OTC: "Elhelyezkedő",
  PASTE: "Paszta",
  PAY: "Fizetés",
  PENDING_CONFIRM: "A pénztárca megerősítésének függőben.",
  PER: "egy",
  PLEASE_CHOOSE_NETWORK: "Kérjük, válassza a Hálózat lehetőséget",
  PLEASE_LOGIN: "Kérjük, jelentkezzen be",
  PLEASE_TRY_LATER: "Kérlek, próbáld újra később",
  POPULAR_TOKENS: "Népszerű tokenek",
  PRICE: "Ár",
  PRICE_IMPACT: "Árhatás",
  PRIVACY_POLICY: "Adatvédelmi irányelvek",
  RATE: "Mérték",
  READ_ACCEPT: "Olvastam és elfogadom",
  RECEIVE: "Kap",
  RECEIVED_ORDERS: "Megrendeléseket kapott",
  REFUND: "VISSZATÉRÍTÉS",
  REMOVE: "Eltávolítás",
  REVERSE_RATE: "Fordított sebesség",
  REVIEW_TRADE: "Felülvizsgálja a kereskedelmet",
  SELECTED: "kiválasztott",
  SELECT_ALL: "Mindet kiválaszt",
  SELECT_FROM_INFO: "Kérjük, válassza az Info lehetőséget",
  SELECT_NETWORK: "Válasszon egy hálózatot",
  SELECT_TOKEN: "Válasszon egy tokent",
  SELECT_TOKENN: "Válassza ki a tokent",
  SELECT_TO_INFO: "Kérjük, válassza az Info lehetőséget",
  SETTINGS: "Beállítások",
  SIMPLE_MODE: "Egyszerű mód",
  SLIPPAGE: "Csúszás",
  SLIPPAGE_TOLERANCE: "Csúszási tolerancia",
  SRY_INSUFFICIENT: "Sajnálom, elégtelen",
  STATUS: "ÁLLAPOT",
  STATUS_SM: "Állapot",
  STEP: "Lépés",
  SUBMIT: "Beküldés",
  SUBMITTED_ORDERS: "Benyújtott megrendelések",
  SUBMIT_DATE: "Benyújtás dátuma",
  SUCCESS_FILLED: "Sikeresen megtelt megrendelés",
  SUCCESS_RECEIVED: "Sikeresen fogadott",
  SUCCESS_SUBMITTED_ORDER: "Sikeresen benyújtotta megrendelését",
  SWAP: "Csere",
  SWAP_TRANSFER: "Cserélő és átutalás",
  SWITCH_NETWORK: "Kapcsolóhálózat",
  TAKER: "Vevő",
  TAKER_ADDRESS: "Felvételi cím",
  TERMS_OF_SERVICE: "Szolgáltatás feltételei",
  TO: "Nak nek",
  TOKEN_NOT_FOUND: "Token nem található",
  TOKEN_RECEIVE_SENT: "A fogadandó tokent elküldik",
  TRADE: "Kereskedelmi",
  TRANSACTION_REJECTED_LATER:
    "A tranzakció elutasította, kérjük, próbálkozzon újra később",
  TRANSFER: "Átruházás",
  TRANSFER_ALERT: "Átviteli riasztás",
  TRANSFER_TO: "Átutal",
  TX_COST: "TX költség",
  UNLOCK: "Kinyit",
  USER_GUIDE: "Használati útmutató",
  USE_ITAS: "és használja W -ként",
  VERIFY: "Igazol",
  VIEW_EXPLORER: "Tekintse meg a tranzakciót az Explorer -ben",
  VIEW_ON: "Rávilágít",
  VIEW_TRANSACTION: "Tranzakció megtekintése",
  WRAP: "Betakar",
  YOUR_ADDRESS: "Címed",
  YOUR_ORDER_CANCEL: "Megrendelését lemondják",
  YOUR_WALLET: "A pénztárcád",
  YOU_GET: "Kapsz",
  YOU_PAY: "Te fizetsz",
  YOU_RECEIVE: "Megkapod",
  YOU_WILL_RECEIVE: "Fogsz kapni",
  ANYONE_CAN_CREATE:
    "Bárki létrehozhat bármilyen tokent, beleértve a meglévő tokenek hamis verzióit is. Vigyázzon kellő vigyázni. Egyes tokenek és műszaki paramétereik összeegyeztethetetlenek lehetnek szolgáltatásainkkal. Az egyéni token importálásával elismeri és elfogadja a kockázatokat.",
  BY_SIGNING_MESSAGE:
    "Az üzenet aláírásával megerősíti, hogy nem fér hozzá ehhez az alkalmazáshoz, vagy az Egyesült Államok vagy bármely más korlátozott ország lakosa. További információkért kérjük, olvassa el a mi",
  CONSTITUTION: "Alkotmánya",
  CURRENTLY_CONNECTED: "Jelenleg csatlakozik",
  IMPORT_TOKEN: "Importáljon egy tokent",
  IN_YOUR_WALLET: "A pénztárcádban",
  MAKE_SURE_CHECKED:
    "Ügyeljen arra, hogy ellenőrizze a token címet, és ez helyes",
  ON: "tovább",
  PEOPLE: "Emberek",
  PLEASE_SWITCH: "Kérjük, váltson a",
  SELECTED_NOT_SUPPORTED:
    "Kérjük, változtassa meg a hálózatot a pénztárcájában, a kiválasztott hálózat nem támogatott",
  SIGN_FOR_ACCESS: "Jelentkezzen be a hozzáféréshez",
  SIGN_MESSAGE: "Aláírás",
  LAUNCH_APP: "Indító alkalmazás indítása",
  GETTING_STARTED: "Először a HODL -vel: Oktatóanyagok és termékmagyarázók",
  DEEP_DIVE: "Mélység a HODL -ba: Stratégiák és termékjellemzők",
  BUILD_HODL: "Építés a HODL -re: Műszaki dokumentáció",
  BLOG_HEADING:
    "Demystify defi - mindent, amit tudnod kell egy helyen. Kezdőknek a haladó felhasználók és mindenki között.",
  BLOG_PLACEHOLDER:
    "Keresse meg azt a terméket/témát, amelyről meg szeretne tanulni",
  CHOOSE_TOPIC: "Válassza a Hálózat lehetőséget",
  TRY: "PRÓBÁLD KI",
  SEARCH_RESULTS: "Keresési eredmények",
  SEARCH_TOPIC: "Keresési téma",
  LOAD_MORE: "Töltsön fel többet",
  SOCIAL_MEDIA: "Közösségi média",
  NEW: "Legújabb első",
  OLD: "Legrégebbi első",
  Z_A: "Rendje a Z-A címet",
  A_Z: "Rendezze az A-Z címet",
  RESULTS: "Eredmények",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Az alapgázdíjat a hálózat automatikusan kiszámítja, és blokkonként változik. Állítsa be a fizetni kívánt alapdíj maximális összegét. A különbség a beállított maximális alapdíj és a tényleges bázisdíj között visszatérítésre kerül, miután a blokkot bányászták.",
  CANCELLED_TRANSACTION: "Törölte a tranzakciót",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "A limit megrendelés létrehozása ingyenes művelet a megrendeléskészítő számára, hacsak nem törölnek. A megrendelésnek a megrendelés kitöltésekor fizetnie kell a tranzakciós díjakért.",
  Cancelled: "Törölve",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "Az érték különbsége a legjobb út és a",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Engedélyezze ezt az opciót, ha problémái vannak egy olyan token cseréjével, amely beépített díjakkal rendelkezik az átutaláshoz.",
  Expired: "Lejárt",
  Filled: "Megtöltött",
  INSUFFICIENT_FOR_GASFEE: "Sajnálom, nem elegendő pénzeszköz a gázdíjért",
  Open: "Nyisd ki",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "A részleges kitöltés csak akkor érvényes, ha egy csere útvonal több fő útvonalból áll. Ez lehetővé teszi a részleges végrehajtást, megakadályozva a tranzakció megfordítását, ha a piaci ár kedvezőtlenül mozog.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Ár a megrendeléskészítő által a megrendelés végrehajtásáért (token fogadáshoz = x token fizetés)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Az árat, amelyet a megrendeléskészítő végez a megrendelés végrehajtásáért (token fizetni = y token a fogadáshoz",
  REVIEW_LIMIT_ORDER: "Felülvizsgálati korlátozás",
  SEARCH_BY_TOKEN: "Token vagy cím keresése",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "A magas csúszási tolerancia beállítása elősegítheti a tranzakciók sikerét, de lehet, hogy nem kap ilyen jó árat. Óvatosan használja.",
  STATUS_OF_THE_LIMIT_ORDER: "A határrendelés állapota",
  Suspended: "Felfüggesztett",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Ezeket a díjakat a hálózati bányászoknak fizetik. A HODL nem ellenőrzi a díjakat.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "A különbség a piaci ár és az EST. Ár között a kereskedelem méretéből",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "A minimális vett összeg, különben a tranzakciót vissza kell állítani",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Ez a díj közvetlenül a bányászokhoz fordul, mint ösztönzés a tranzakció prioritásainak prioritásának.",
  TRANSACTION_REJECTED_TRY:
    "A tranzakció elutasította, kérjük, próbálkozzon újra",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "A kiválasztott token átutalása nem érhető el ezen a hálózaton",
  YOU: "te",
};
