export default {
  ABOUT_NEW_FEATURES: "tungkol sa mga bagong tampok.",
  ACCEPT: "Tanggapin",
  ACTIVE_ORDERS: "Aktibong mga order",
  ADD_TOKEN: "Magdagdag ng isang token",
  AGGRESSIVE: "Agresibo",
  AND: "at",
  APPROVE: "Aprubahan",
  ASSETS_ON_WAY: "Ang iyong mga pag -aari ay nasa daan at dapat dumating sa",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Mangyaring iwasan ang paglilipat sa isang sentralisadong address ng palitan (Binance, Huobi ...). Hindi ito isang suportadong tampok",
  BALANCE: "Balansehin",
  BALANCE_ONE: "Balanse:",
  BLOG: "OTC",
  BRIDGE: "Tulay",
  CANCEL: "Kanselahin",
  CANCELLATION_TRANSACTION_FEE:
    "Ang pagkansela ng isang order ng limitasyon ay nagsasangkot ng pagbabayad ng mga bayarin sa transaksyon.",
  CANCEL_ALL: "Kanselahin ang lahat",
  CANCEL_LIMIT_NOT_FILLED:
    "Maaari mong kanselahin ang isang order order hangga't hindi pa napuno ang order.",
  CANCEL_LIMIT_ORDER: "Kanselahin ang limitasyon ng limitasyon",
  CANCEL_ORDER: "Kanselahin ang order",
  CANCEL_SELECTED: "Kanselahin ang napiling",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Piliin ang Network",
  CLASSIC_MODE: "Klasikong mode",
  CLEAR: "Malinaw",
  CONFIRM: "Kumpirmahin",
  CONFIRMATION_REJECTED: "Tumanggi ang kumpirmasyon.!",
  CONFIRM_SWAP: "Kumpirmahin ang pagpapalit",
  CONNECT_WALLET: "Ikonekta ang pitaka",
  COPIED: "Kinopya",
  CURRENT: "Kasalukuyan",
  CUSTOM: "Pasadya",
  DAYS: "Araw",
  DEFAULT: "Default",
  DETAILS: "Mga detalye",
  DISCLAIMER: "Pagtatanggi",
  DISCONNECT_WALLET: "Idiskonekta ang pitaka",
  DISMISS: "Tanggalin",
  DUPLICATE_ORDER_SUBMIT:
    "Dobleng pagkakasunud -sunod. Ang isang ito ay isinumite na",
  ENTER_AMT: "Ipasok ang halaga",
  EXCHANGE: "Palitan",
  EXPIRY: "Mag -expire",
  EXPIRY_SM: "Mag -expire",
  FAILED_LOAD_CHART: "Nabigong mag -load ng tsart",
  FIND_BY_TOKEN_OR_STATUS: "Hanapin sa pamamagitan ng token o katayuan",
  INSUFFICIENT_BALANCE: "Hindi sapat na balanse",
  INSUFFICIENT: "Hindi sapat",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "Balanse upang masakop ang umiiral na balanse ng mga order ng",
  RECEIVERS: "Tatanggap",
  WALETADDRESS: "Address ng Wallet",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Aceite a política de termos de serviço e privacidade",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Taker do fabricante não deve ser o mesmo endereço de tomador",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "A tolerância a derrapagem deve estar entre 0,1% e 15%",
  SEARCH_A_TOKEN: "Procure um token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Sua transação será revertida se o preço mudar desfavorável por mais do que essa porcentagem",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Ajusta o preço do gás para sua transação.GWEI mais alto = velocidade mais alta.",
  REVIEW_OTC_ORDER: "Revise o OTC Order",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Período de validade definido pelo fabricante de pedidos.Após esse período, a ordem expirará e será arquivada.",
  SEARCH_A_LIQUIDITY_SOURCES: "Pesquise as fontes de liquidez",

  FAST: "Mabilis",
  FAST_AND_SECURE:
    "Ang ligtas, mabilis at pinaka ligtas na paraan upang magdala ng mga assets ng cross-chain",
  FEATURE_TO_TRANSFER:
    "Mangyaring huwag gamitin ang tampok na ito upang ilipat ang iyong mga token sa isang address ng isang sentralisadong palitan.",
  FEES: "Bayarin",
  FILL_ORDER: "Punan ang order",
  FILL_OTC_ORDER: "Punan ang order ng OTC",
  FREE: "Libre",
  FROM: "Mula sa",
  GAS_COST: "Gastos sa gas",
  GAS_DESTINATION: "Gas sa chain ng patutunguhan",
  GAS_ON_DESTINATION: "Gas sa patutunguhan",
  GAS_PRICE: "Presyo ng Gas",
  GOT_IT: "Nakuha ko",
  GOT_PROBLEM_JUST: "May problema ba? Makipag -ugnay lang.",
  HIGH: "Mataas",
  HOURS: "Oras",
  IMPORT: "Angkat",
  IMPOSSIBLE_TO_RETRIEVE:
    "Hindi ito isang suportadong tampok. Gayundin, mangyaring i-double-check ang address na punan mo, ang anumang mga token na ipinadala sa maling address ay imposibleng makuha.",
  INSTANT: "Instant",
  INSUFFICIENT_APPROVED_AMT: "Hindi sapat na naaprubahan na halaga",
  INVALID_TOKEN_ADDRESS: "Hindi wastong token address",
  KEEP_TRACK_NOTIFY:
    "Maaari mong subaybayan ito sa iyong abiso sa panel. Sasabihan ka namin sa sandaling tapos na ito.",
  KEEP_YOU_POSTED: ". Panatilihin kang nai -post.",
  LEARN_MORE: "Matuto nang higit pa",
  LEGACY: "Pamana",
  LIMIT: "Limitahan",
  LIMIT_ORDER: "Limitahan ang order",
  LIMIT_PRICE: "Limitahan ang presyo",
  LIMIT_PRICE_SM: "Limitahan ang presyo",
  LIQUIDITY_SOURCES: "Mga mapagkukunan ng pagkatubig",
  LOW: "Mababa",
  MAKER: "Tagagawa",
  MARKET: "Merkado",
  MAX: "Max",
  MAX_BASE_FEE: "MAX BASE FEE",
  MAX_S: "Max",
  MAX_TO_PAY: "Max upang magbayad",
  MINIMUM_RECEIVED: "Minimum na natanggap",
  MINUTES: "Minuto",
  MONTHS: "Buwan",
  NEED_APPROVAL_MOVE: "Kailangan namin ang iyong pag -apruba upang ilipat",
  NEED_TO_WRAP: "Kailangan mong balutin",
  NETWORK_FEES: "Mga Bayad sa Network",
  NETWORK_SM: "Network.",
  NEVER: "Hindi kailanman",
  NONE: "Wala",
  NON_CUSTODIAL_LIMIT:
    "Sa pagkakasunud-sunod ng limitasyon ng hindi custodial. Ito ay isang kinakailangan sa teknikal sa",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Wala ka pang mga order",
  NO_RECORDS: "Walang mga tala",
  ON_UR_BEHALF: "sa ngalan mo.",
  OOPS_WRONG: "Oops! May nangyaring mali.!",
  OPEN: "Buksan",
  ORDER_DETAILS: "Mga Detalye ng Order",
  ORDER_HISTORY: "Kasaysayan ng Order",
  ORDER_PRICE: "Order Presyo",
  ORDER_ROUTING: "Order ruta",
  OTC: "OTC",
  PASTE: "I -paste",
  PAY: "Magbayad",
  PENDING_CONFIRM: "Nakabinbin na kumpirmasyon sa pitaka.",
  PER: "bawat",
  PLEASE_CHOOSE_NETWORK: "Mangyaring piliin ang Network",
  PLEASE_LOGIN: "Mangyaring mag -login sa",
  PLEASE_TRY_LATER: "Subukang muli mamaya",
  POPULAR_TOKENS: "Mga sikat na token",
  PRICE: "Presyo",
  PRICE_IMPACT: "Epekto ng presyo",
  PRIVACY_POLICY: "Patakaran sa Pagkapribado",
  RATE: "Rate",
  READ_ACCEPT: "Nabasa ko at tinanggap",
  RECEIVE: "Tumanggap",
  RECEIVED_ORDERS: "Nakatanggap ng mga order",
  REFUND: "Mag -refund",
  REMOVE: "Alisin",
  REVERSE_RATE: "Baligtad na rate",
  REVIEW_TRADE: "Suriin ang kalakalan",
  SELECTED: "napili",
  SELECT_ALL: "Piliin lahat",
  SELECT_FROM_INFO: "Mangyaring pumili mula sa impormasyon",
  SELECT_NETWORK: "Pumili ng isang network",
  SELECT_TOKEN: "Pumili ng isang token",
  SELECT_TOKENN: "Piliin ang Token",
  SELECT_TO_INFO: "Mangyaring piliin sa impormasyon",
  SETTINGS: "Mga setting",
  SIMPLE_MODE: "Simpleng mode",
  SLIPPAGE: "Slippage",
  SLIPPAGE_TOLERANCE: "Tolerance ng Slippage",
  SRY_INSUFFICIENT: "Paumanhin, hindi sapat",
  STATUS: "Katayuan",
  STATUS_SM: "Katayuan",
  STEP: "Hakbang",
  SUBMIT: "Ipasa",
  SUBMITTED_ORDERS: "Isinumite na mga order",
  SUBMIT_DATE: "Petsa ng pagsusumite",
  SUCCESS_FILLED: "Matagumpay na napuno ng order",
  SUCCESS_RECEIVED: "Matagumpay na natanggap",
  SUCCESS_SUBMITTED_ORDER: "Matagumpay na isinumite ang iyong order",
  SWAP: "Palitan",
  SWAP_TRANSFER: "Palitan at paglipat",
  SWITCH_NETWORK: "Lumipat ng network",
  TAKER: "Taker",
  TAKER_ADDRESS: "Address ng Taker",
  TERMS_OF_SERVICE: "Mga Tuntunin ng Serbisyo",
  TO: "Sa",
  TOKEN_NOT_FOUND: "Hindi natagpuan ang token",
  TOKEN_RECEIVE_SENT: "Ang token upang matanggap ay ipapadala sa",
  TRADE: "Kalakal",
  TRANSACTION_REJECTED_LATER:
    "Tinanggihan ng transaksyon mangyaring subukang muli sa ibang pagkakataon",
  TRANSFER: "Ilipat",
  TRANSFER_ALERT: "Ilipat ang alerto",
  TRANSFER_TO: "Ilipat sa",
  TX_COST: "Tx gastos",
  UNLOCK: "I -unlock",
  USER_GUIDE: "Gabay sa gumagamit",
  USE_ITAS: "at gamitin ito bilang w",
  VERIFY: "I -verify",
  VIEW_EXPLORER: "Tingnan ang transaksyon sa Explorer",
  VIEW_ON: "Tingnan sa",
  VIEW_TRANSACTION: "Tingnan ang transaksyon",
  WRAP: "Balutin",
  YOUR_ADDRESS: "Ang iyong address",
  YOUR_ORDER_CANCEL: "Nakansela ang iyong order",
  YOUR_WALLET: "Iyong pitaka",
  YOU_GET: "Nakuha mo",
  YOU_PAY: "Magbabayad ka",
  YOU_RECEIVE: "Natanggap mo",
  YOU_WILL_RECEIVE: "Tatanggap ka",
  ANYONE_CAN_CREATE:
    "Kahit sino ay maaaring lumikha ng anumang token, kabilang ang mga pekeng bersyon ng umiiral na mga token. Mag -ingat. Ang ilang mga token at ang kanilang mga teknikal na parameter ay maaaring hindi katugma sa aming mga serbisyo. Sa pamamagitan ng pag -import ng pasadyang token na ito ay kinikilala at tinatanggap mo ang mga panganib.",
  BY_SIGNING_MESSAGE:
    "Sa pamamagitan ng pag -sign ng mensaheng ito, kinukumpirma mo na hindi mo na -access ang app na ito, o residente ng USA o anumang iba pang paghihigpit na bansa. Para sa karagdagang impormasyon, mangyaring basahin ang aming",
  CONSTITUTION: "KonstitusyonDao",
  CURRENTLY_CONNECTED: "Kasalukuyan kang konektado sa",
  IMPORT_TOKEN: "Mag -import ng isang token",
  IN_YOUR_WALLET: "sa iyong pitaka",
  MAKE_SURE_CHECKED: "Siguraduhing sinuri mo ang token address at tama ito",
  ON: "sa",
  PEOPLE: "Mga tao",
  PLEASE_SWITCH: "Mangyaring lumipat sa",
  SELECTED_NOT_SUPPORTED:
    "Mangyaring baguhin ang network sa iyong pitaka, ang napiling network ay hindi suportado",
  SIGN_FOR_ACCESS: "Mag -sign para sa pag -access",
  SIGN_MESSAGE: "Mag -sign Message",
  LAUNCH_APP: "Ilunsad ang app",
  GETTING_STARTED: "Pagsisimula sa HODL: Mga Tutorial at Product Expresser",
  DEEP_DIVE: "Malalim sa HODL: Mga Istratehiya at Mga Tampok ng Produkto",
  BUILD_HODL: "Pagbuo sa HODL: Teknikal na Dokumentasyon",
  BLOG_HEADING:
    "Demystifying Defi - Lahat ng kailangan mong malaman sa isang lugar. Para sa mga nagsisimula sa mga advanced na gumagamit, at lahat ng nasa pagitan.",
  BLOG_PLACEHOLDER:
    "Maghanap para sa produkto/paksa na nais mong malaman tungkol sa",
  CHOOSE_TOPIC: "Piliin ang Network",
  TRY: "Subukan",
  SEARCH_RESULTS: "Procurar Resultados",
  SEARCH_TOPIC: "Tópico de pesquisa",
  LOAD_MORE: "Carregue mais",
  SOCIAL_MEDIA: "Redes sociais",
  NEW: "Os mais novos primeiro",
  OLD: "Mais velhos primeiro",
  Z_A: "Classifique o título Z-A",
  A_Z: "Classificar o título A-Z",
  RESULTS: "Resultados",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Ang isang base gas fee ay awtomatikong kinakalkula ng network at nag -iiba mula sa block upang mai -block. Itakda ang pinakamataas na halaga ng base fee na nais mong bayaran. Ang isang pagkakaiba sa pagitan ng max base fee na itinakda mo at ang aktwal na bayad sa base ay ibabalik sa sandaling ang block ay mined.",
  CANCELLED_TRANSACTION: "Kinansela ang iyong transaksyon",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Ang paglikha ng isang order order ay isang libreng operasyon para sa tagagawa ng order maliban kung kanselahin nila. Ang order taker ay kinakailangan na magbayad para sa mga bayarin sa transaksyon habang pinupuno nila ang order.",
  Cancelled: "Kanselahin",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Pagkakaiba sa halaga sa pagitan ng pinakamahusay na ruta at",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Paganahin ang pagpipiliang ito kung nagkakaroon ka ng mga isyu sa pagpapalit ng isang token na may built-in na bayad para sa paglilipat.",
  Expired: "Nag -expire",
  Filled: "Napuno",
  INSUFFICIENT_FOR_GASFEE:
    "Paumanhin, hindi sapat na pondo para sa bayad sa gas",
  Open: "Buksan",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Ang bahagyang punan ay nalalapat lamang kung ang isang ruta ng pagpapalit ay binubuo ng maraming pangunahing mga bahagi ng ruta. Pinapayagan nito para sa bahagyang pagpapatupad, na pumipigil sa transaksyon mula sa pagbabalik kung ang presyo ng merkado ay lumilipat sa pabor.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Presyo na itinakda ng tagagawa ng order para sa pagpapatupad ng kanilang order (token upang makatanggap = x token na magbayad)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Presyo na itinakda ng tagagawa ng order para sa pagpapatupad ng kanilang order (token na magbayad = y token upang matanggap",
  REVIEW_LIMIT_ORDER: "Suriin ang limitasyon ng limitasyon",
  SEARCH_BY_TOKEN: "Maghanap sa pamamagitan ng token o address",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Ang pagtatakda ng isang mataas na tolerance ng slippage ay maaaring makatulong sa mga transaksyon na magtagumpay, ngunit maaaring hindi ka makakuha ng ganoong magandang presyo. Gamitin nang may pag -iingat.",
  STATUS_OF_THE_LIMIT_ORDER: "Katayuan ng order order",
  Suspended: "Nasuspinde",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Ang mga bayarin na ito ay binabayaran sa mga minero ng network. HODL Huwag kontrolin ang mga bayarin.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Ang pagkakaiba sa pagitan ng presyo ng merkado at Est. Presyo dahil sa laki ng kalakalan",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Ang minimum na natanggap na halaga, kung hindi man ay ibabalik ang transaksyon",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Ang bayad na ito ay diretso sa mga minero bilang isang insentibo upang unahin ang iyong transaksyon.",
  TRANSACTION_REJECTED_TRY:
    "Tinanggihan ng transaksyon, mangyaring subukang muli",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Ang paglipat para sa napiling token ay hindi magagamit sa network na ito",
  YOU: "Ikaw",
};
