import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;

export const GetTokenList = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-limit-ordertokens?chainId=${data.chainId}`
        });
        return {
            loading: false,
            result: respData.data.list
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err)
        }
    }
}

export const savelimitorders = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-limitorders`,
            data: data
        });

        return {
            loading: true,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const updatelimitorders = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/update-limitorders`,
            data: data
        });

        return {
            loading: true,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const cancelOrders = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/cancel-limitorders`,
            data: data
        });

        return {
            loading: true,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const GetUserLimitOrders = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-limit-orders`,
            'data': data
        });
        return {
            loading: true,
            orders: (respData && respData.data && respData.data.orders) ? respData.data.orders : []
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err),
            orders: []
        }
    }
}

export const GetUserP2pOrders = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-p2p-orders`,
            'data': data
        });
        return {
            loading: true,
            orders: (respData && respData.data && respData.data.orders) ? respData.data.orders : []
        }
    } catch (err) {
        return {
            loading: false,
            error: returnErr(err),
            orders: []
        }
    }
}

export const fillP2POrders = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/fill-limit-orders`,
            data: data
        });

        return {
            loading: true,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}


function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


