export default {
  ABOUT_NEW_FEATURES: "புதிய அம்சங்களைப் பற்றி.",
  ACCEPT: "ஏற்றுக்கொள்",
  ACTIVE_ORDERS: "செயலில் ஆர்டர்கள்",
  ADD_TOKEN: "ஒரு டோக்கனைச் சேர்க்கவும்",
  AGGRESSIVE: "முரட்டுத்தனமான",
  AND: "மற்றும்",
  APPROVE: "ஒப்புதல்",
  ASSETS_ON_WAY: "உங்கள் சொத்துக்கள் வழியில் உள்ளன, உள்ளே வர வேண்டும்",
  AUTO: "ஆட்டோ",
  AVOID_TRANSFER:
    "மையப்படுத்தப்பட்ட பரிமாற்ற முகவரிக்கு மாற்றுவதைத் தவிர்க்கவும் (பைனன்ஸ், ஹூபி ...). இது ஒரு ஆதரவு அம்சம் அல்ல",
  BALANCE: "இருப்பு",
  BALANCE_ONE: "இருப்பு:",
  BLOG: "வலைப்பதிவு",
  BRIDGE: "பாலம்",
  CANCEL: "ரத்துசெய்",
  CANCELLATION_TRANSACTION_FEE:
    "ஒரு வரம்பு வரிசையை ரத்து செய்வது பரிவர்த்தனைக் கட்டணத்தை செலுத்துவதை உள்ளடக்குகிறது.",
  CANCEL_ALL: "அனைத்தையும் ரத்துசெய்",
  CANCEL_LIMIT_NOT_FILLED:
    "ஆர்டர் இன்னும் நிரப்பப்படாத வரை நீங்கள் ஒரு வரம்பு வரிசையை ரத்து செய்யலாம்.",
  CANCEL_LIMIT_ORDER: "வரம்பு ஆர்டரை ரத்துசெய்",
  CANCEL_ORDER: "ஆர்டரை ரத்துசெய்",
  CANCEL_SELECTED: "தேர்ந்தெடுக்கப்பட்டதை ரத்துசெய்",
  CHAINS: "சங்கிலிகள்",
  CHOOSE_NETWORK: "பிணையத்தைத் தேர்வுசெய்க",
  CLASSIC_MODE: "கிளாசிக் பயன்முறை",
  CLEAR: "தெளிவான",
  CONFIRM: "உறுதிப்படுத்தவும்",
  CONFIRMATION_REJECTED: "உறுதிப்படுத்தல் நிராகரிக்கப்பட்டது.!",
  CONFIRM_SWAP: "இடமாற்றம் உறுதிப்படுத்தவும்",
  CONNECT_WALLET: "பணப்பையை இணைக்கவும்",
  COPIED: "நகலெடுக்கப்பட்டது",
  CURRENT: "நடப்பு",
  CUSTOM: "வழக்கம்",
  DAYS: "நாட்களில்",
  DEFAULT: "இயல்புநிலை",
  DETAILS: "விவரங்கள்",
  DISCLAIMER: "மறுப்பு",
  DISCONNECT_WALLET: "பணப்பையை துண்டிக்கவும்",
  DISMISS: "தள்ளுபடி",
  DUPLICATE_ORDER_SUBMIT: "நகல் ஒழுங்கு. இது ஏற்கனவே சமர்ப்பிக்கப்பட்டுள்ளது",
  ENTER_AMT: "தொகையை உள்ளிடவும்",
  EXCHANGE: "பரிமாற்றம்",
  EXPIRY: "காலாவதியாகும்",
  EXPIRY_SM: "காலாவதியாகும்",
  FAILED_LOAD_CHART: "விளக்கப்படத்தை ஏற்றுவதில் தோல்வி",
  FIND_BY_TOKEN_OR_STATUS: "டோக்கன் அல்லது அந்தஸ்தால் கண்டறியவும்",
  INSUFFICIENT_BALANCE: "போதுமான சமநிலை",
  INSUFFICIENT: "போதுமானதாக இல்லை",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "தற்போதுள்ள ஆர்டர்கள் சமநிலையை ஈடுகட்ட இருப்பு",
  RECEIVERS: "பெறுபவரின்",
  WALETADDRESS: "பணப்பையை முகவரி",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "சேவை விதிமுறைகள் மற்றும் தனியுரிமைக் கொள்கையை ஏற்றுக்கொள்ளுங்கள்",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "மேக்கர் டேக்கர் ஒரே டேக்கர் முகவரியாக இருக்கக்கூடாது",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "வழுக்கும் சகிப்புத்தன்மை 0.1% முதல் 15% வரை இருக்க வேண்டும்",
  SEARCH_A_TOKEN: "ஒரு டோக்கனைத் தேடுங்கள்",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "இந்த சதவீதத்தை விட விலை சாதகமாக மாறினால் உங்கள் பரிவர்த்தனை மாற்றப்படும்",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "உங்கள் பரிவர்த்தனைக்கான வாயு விலையை சரிசெய்கிறது.அதிக GWEI = அதிக வேகம்.",
  REVIEW_OTC_ORDER: "OTC ஆர்டரை மதிப்பாய்வு செய்யவும்",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "ஆர்டர் தயாரிப்பாளரால் நிர்ணயிக்கப்பட்ட காலாவதி காலம்.இந்த காலகட்டத்தில், உத்தரவு காலாவதியாகி காப்பகப்படுத்தப்படும்.",
  SEARCH_A_LIQUIDITY_SOURCES: "பணப்புழக்க ஆதாரங்களைத் தேடுங்கள்",

  FAST: "வேகமாக",
  FAST_AND_SECURE:
    "குறுக்கு சங்கிலி சொத்துக்களைக் கொண்டுவருவதற்கான பாதுகாப்பான, வேகமான மற்றும் மிகவும் பாதுகாப்பான வழி",
  FEATURE_TO_TRANSFER:
    "உங்கள் டோக்கன்களை மையப்படுத்தப்பட்ட பரிமாற்றத்தின் முகவரிக்கு மாற்ற இந்த அம்சத்தைப் பயன்படுத்த வேண்டாம்.",
  FEES: "கட்டணம்",
  FILL_ORDER: "ஆர்டர் நிரப்பவும்",
  FILL_OTC_ORDER: "OTC ஆர்டரை நிரப்பவும்",
  FREE: "இலவசம்",
  FROM: "இருந்து",
  GAS_COST: "வாயு செலவு",
  GAS_DESTINATION: "இலக்கு சங்கிலியில் வாயு",
  GAS_ON_DESTINATION: "இலக்கு மீதான வாயு",
  GAS_PRICE: "எரிவாயு விலை",
  GOT_IT: "அறிந்துகொண்டேன்",
  GOT_PROBLEM_JUST: "ஒரு சிக்கல் கிடைத்ததா? தொடர்பு கொள்ளுங்கள்.",
  HIGH: "உயர்ந்த",
  HOURS: "மணி",
  IMPORT: "இறக்குமதி",
  IMPOSSIBLE_TO_RETRIEVE:
    "இது ஒரு ஆதரவு அம்சம் அல்ல. மேலும், நீங்கள் நிரப்பும் முகவரியை இருமுறை சரிபார்க்கவும், தவறான முகவரிக்கு அனுப்பப்படும் எந்த டோக்கன்களையும் மீட்டெடுக்க இயலாது.",
  INSTANT: "உடனடி",
  INSUFFICIENT_APPROVED_AMT: "போதுமான அங்கீகரிக்கப்பட்ட தொகை",
  INVALID_TOKEN_ADDRESS: "தவறான டோக்கன் முகவரி",
  KEEP_TRACK_NOTIFY:
    "உங்கள் குழு அறிவிப்பில் அதைக் கண்காணிக்கலாம். அது முடிந்ததும் நாங்கள் உங்களுக்கு அறிவிப்போம்.",
  KEEP_YOU_POSTED: ". நாங்கள் உங்களை இடுகையிடுவோம்.",
  LEARN_MORE: "மேலும் அறிக",
  LEGACY: "மரபு",
  LIMIT: "அளவு",
  LIMIT_ORDER: "வரம்பு ஆர்டர்",
  LIMIT_PRICE: "விலையை வரம்பு",
  LIMIT_PRICE_SM: "விலையை வரம்பு",
  LIQUIDITY_SOURCES: "திரவ ஆதாரங்கள்",
  LOW: "குறைந்த",
  MAKER: "தயாரிப்பாளர்",
  MARKET: "சந்தை",
  MAX: "அதிகபட்சம்",
  MAX_BASE_FEE: "அதிகபட்ச அடிப்படை கட்டணம்",
  MAX_S: "அதிகபட்சம்",
  MAX_TO_PAY: "செலுத்த அதிகபட்சம்",
  MINIMUM_RECEIVED: "பெறப்பட்டது",
  MINUTES: "நிமிடங்கள்",
  MONTHS: "மாதங்கள்",
  NEED_APPROVAL_MOVE: "நகர்த்த எங்களுக்கு உங்கள் ஒப்புதல் தேவை",
  NEED_TO_WRAP: "நீங்கள் போர்த்த வேண்டும்",
  NETWORK_FEES: "பிணைய கட்டணம்",
  NETWORK_SM: "வலைப்பின்னல்.",
  NEVER: "ஒருபோதும்",
  NONE: "எதுவுமில்லை",
  NON_CUSTODIAL_LIMIT:
    "காவலில் இல்லாத வரம்பு வரிசையில். இது ஒரு தொழில்நுட்ப தேவை",
  NORMAL: "சாதாரண",
  NO_ORDERS_YET: "உங்களிடம் இதுவரை ஆர்டர்கள் இல்லை",
  NO_RECORDS: "பதிவுகள் இல்லை",
  ON_UR_BEHALF: "உங்கள் சார்பாக.",
  OOPS_WRONG: "அச்சச்சோ! ஏதோ தவறு நடந்துவிட்டது.!",
  OPEN: "திறந்த",
  ORDER_DETAILS: "ஆணை விவரங்கள்",
  ORDER_HISTORY: "ஒழுங்கு வரலாறு",
  ORDER_PRICE: "ஆர்டர் விலை",
  ORDER_ROUTING: "ஆர்டர் ரூட்டிங்",
  OTC: "OTC",
  PASTE: "ஒட்டவும்",
  PAY: "செலுத்துங்கள்",
  PENDING_CONFIRM: "பணப்பையில் உறுதிப்படுத்தல் நிலுவையில் உள்ளது.",
  PER: "ஒன்றுக்கு",
  PLEASE_CHOOSE_NETWORK: "நெட்வொர்க்கைத் தேர்வுசெய்க",
  PLEASE_LOGIN: "தயவுசெய்து உள்நுழைக",
  PLEASE_TRY_LATER: "தயவுசெய்து பின்னர் மீண்டும் முயற்சிக்கவும்",
  POPULAR_TOKENS: "பிரபலமான டோக்கன்கள்",
  PRICE: "விலை",
  PRICE_IMPACT: "விலை தாக்கம்",
  PRIVACY_POLICY: "தனியுரிமைக் கொள்கை",
  RATE: "விகிதம்",
  READ_ACCEPT: "நான் படித்து ஏற்றுக்கொள்கிறேன்",
  RECEIVE: "பெறுங்கள்",
  RECEIVED_ORDERS: "ஆர்டர்களைப் பெற்றது",
  REFUND: "பணத்தைத் திரும்பப் பெறுதல்",
  REMOVE: "அகற்று",
  REVERSE_RATE: "தலைகீழ் வீதம்",
  REVIEW_TRADE: "மறுஆய்வு வர்த்தகம்",
  SELECTED: "தேர்ந்தெடுக்கப்பட்டது",
  SELECT_ALL: "அனைத்தையும் தெரிவுசெய்",
  SELECT_FROM_INFO: "தகவலிலிருந்து தேர்ந்தெடுக்கவும்",
  SELECT_NETWORK: "பிணையத்தைத் தேர்ந்தெடுக்கவும்",
  SELECT_TOKEN: "ஒரு டோக்கனைத் தேர்ந்தெடுக்கவும்",
  SELECT_TOKENN: "டோக்கனைத் தேர்ந்தெடுக்கவும்",
  SELECT_TO_INFO: "தகவலுக்கு தேர்ந்தெடுக்கவும்",
  SETTINGS: "அமைப்புகள்",
  SIMPLE_MODE: "எளிய பயன்முறை",
  SLIPPAGE: "நழுவுதல்",
  SLIPPAGE_TOLERANCE: "வழுக்கும் சகிப்புத்தன்மை",
  SRY_INSUFFICIENT: "போதுமானதாக இல்லை",
  STATUS: "நிலை",
  STATUS_SM: "நிலை",
  STEP: "படி",
  SUBMIT: "சமர்ப்பிக்கவும்",
  SUBMITTED_ORDERS: "சமர்ப்பிக்கப்பட்ட ஆர்டர்கள்",
  SUBMIT_DATE: "தேதி சமர்ப்பிக்கவும்",
  SUCCESS_FILLED: "வெற்றிகரமாக ஆர்டர் நிரப்பப்பட்டது",
  SUCCESS_RECEIVED: "வெற்றிகரமாக பெறப்பட்டது",
  SUCCESS_SUBMITTED_ORDER: "உங்கள் ஆர்டரை வெற்றிகரமாக சமர்ப்பித்தது",
  SWAP: "இடமாற்றம்",
  SWAP_TRANSFER: "இடமாற்று மற்றும் பரிமாற்றம்",
  SWITCH_NETWORK: "நெட்வொர்க்கை மாற்றவும்",
  TAKER: "பெறுபவர்",
  TAKER_ADDRESS: "பெறுபவர் முகவரி",
  TERMS_OF_SERVICE: "சேவை விதிமுறைகள்",
  TO: "To",
  TOKEN_NOT_FOUND: "டோக்கன் கண்டுபிடிக்கப்படவில்லை",
  TOKEN_RECEIVE_SENT: "பெற வேண்டிய டோக்கன் அனுப்பப்படும்",
  TRADE: "வர்த்தகம்",
  TRANSACTION_REJECTED_LATER:
    "நிராகரிக்கப்பட்ட பரிவர்த்தனை தயவுசெய்து பின்னர் முயற்சிக்கவும்",
  TRANSFER: "இடமாற்றம்",
  TRANSFER_ALERT: "எச்சரிக்கை இடமாற்றம்",
  TRANSFER_TO: "மாற்றவும்",
  TX_COST: "TX செலவு",
  UNLOCK: "திறக்க",
  USER_GUIDE: "பயனர் வழிகாட்டி",
  USE_ITAS: "அதை w ஆக பயன்படுத்தவும்",
  VERIFY: "சரிபார்க்கவும்",
  VIEW_EXPLORER: "எக்ஸ்ப்ளோரரில் பரிவர்த்தனையைக் காண்க",
  VIEW_ON: "காண்க",
  VIEW_TRANSACTION: "பரிவர்த்தனையைக் காண்க",
  WRAP: "மடக்கு",
  YOUR_ADDRESS: "உங்கள் முகவரி",
  YOUR_ORDER_CANCEL: "உங்கள் ஆர்டர் ரத்து செய்யப்பட்டது",
  YOUR_WALLET: "உங்கள் பணப்பையை",
  YOU_GET: "நீங்கள் பெறுவீர்கள்",
  YOU_PAY: "நீ பணம் செலுத்து",
  YOU_RECEIVE: "நீங்கள் பெறுகிறீர்கள்",
  YOU_WILL_RECEIVE: "உங்களுக்கு கிடைக்கும்",
  ANYONE_CAN_CREATE:
    "தற்போதுள்ள டோக்கன்களின் போலி பதிப்புகள் உட்பட எந்த டோக்கனையும் எவரும் உருவாக்கலாம். சரியான கவனிப்பு. சில டோக்கன்கள் மற்றும் அவற்றின் தொழில்நுட்ப அளவுருக்கள் எங்கள் சேவைகளுடன் பொருந்தாது. இந்த தனிப்பயன் டோக்கனை இறக்குமதி செய்வதன் மூலம் நீங்கள் ஆபத்துக்களை ஒப்புக்கொள்கிறீர்கள் மற்றும் ஏற்றுக்கொள்கிறீர்கள்.",
  BY_SIGNING_MESSAGE:
    "இந்த செய்தியில் கையொப்பமிடுவதன் மூலம், நீங்கள் இந்த பயன்பாட்டை அணுகவில்லை, அல்லது அமெரிக்கா அல்லது வேறு எந்த தடைசெய்யப்பட்ட நாட்டிலிருந்தும் வசிப்பவர் என்பதை உறுதிப்படுத்துகிறீர்கள். மேலும் தகவலுக்கு, எங்கள் படிக்கவும்",
  CONSTITUTION: "ConsititionationDao",
  CURRENTLY_CONNECTED: "நீங்கள் தற்போது இணைக்கப்பட்டுள்ளீர்கள்",
  IMPORT_TOKEN: "ஒரு டோக்கனை இறக்குமதி செய்யுங்கள்",
  IN_YOUR_WALLET: "உங்கள் பணப்பையில்",
  MAKE_SURE_CHECKED:
    "டோக்கன் முகவரியை நீங்கள் சரிபார்த்துள்ளீர்கள் என்பதை உறுதிப்படுத்திக் கொள்ளுங்கள், அது சரியானது",
  ON: "ஆன்",
  PEOPLE: "மக்கள்",
  PLEASE_SWITCH: "தயவுசெய்து மாறவும்",
  SELECTED_NOT_SUPPORTED:
    "உங்கள் பணப்பையில் நெட்வொர்க்கை மாற்றவும், தேர்ந்தெடுக்கப்பட்ட நெட்வொர்க் ஆதரிக்கப்படவில்லை",
  SIGN_FOR_ACCESS: "அணுகலுக்கான கையொப்பம்",
  SIGN_MESSAGE: "செய்தி கையொப்பம்",
  LAUNCH_APP: "பயன்பாட்டைத் தொடங்கவும்",
  GETTING_STARTED:
    "HODL உடன் தொடங்குதல்: பயிற்சிகள் மற்றும் தயாரிப்பு விளக்கக்காரர்கள்",
  DEEP_DIVE: "HODL இல் ஆழமானதாக: உத்திகள் மற்றும் தயாரிப்பு அம்சங்கள்",
  BUILD_HODL: "HODL இல் கட்டிடம்: தொழில்நுட்ப ஆவணங்கள்",
  BLOG_HEADING:
    "டெஃபி -ஐ நீக்குதல் - நீங்கள் தெரிந்து கொள்ள வேண்டிய அனைத்தும் ஒரே இடத்தில். மேம்பட்ட பயனர்களுக்கும், இடையில் உள்ள அனைவருக்கும்.",
  BLOG_PLACEHOLDER: "நீங்கள் அறிய விரும்பும் தயாரிப்பு/தலைப்பைத் தேடுங்கள்",
  CHOOSE_TOPIC: "தலைப்பைத் தேர்வுசெய்க",
  TRY: "முயற்சி",
  SEARCH_RESULTS: "தேடல் முடிவுகள்",
  SEARCH_TOPIC: "தேடல் தலைப்பு",
  LOAD_MORE: "அதிகமாக ஏற்று",
  SOCIAL_MEDIA: "சமூக ஊடகம்",
  NEW: "முதல் முதல்",
  OLD: "முதல்முதல்",
  Z_A: "வரிசை தலைப்பு Z-A.",
  A_Z: "வரிசை தலைப்பு A-Z",
  RESULTS: "Results",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "ஒரு அடிப்படை எரிவாயு கட்டணம் தானாகவே பிணையத்தால் கணக்கிடப்படுகிறது மற்றும் தொகுதி முதல் தொகுதி வரை மாறுபடும். நீங்கள் செலுத்த விரும்பும் அடிப்படைக் கட்டணத்தின் அதிகபட்ச தொகையை அமைக்கவும். தொகுதி வெட்டப்பட்டவுடன் நீங்கள் நிர்ணயித்த அதிகபட்ச அடிப்படை கட்டணத்திற்கும் உண்மையான அடிப்படைக் கட்டணத்திற்கும் இடையிலான வேறுபாடு திருப்பித் தரப்படும்.",
  CANCELLED_TRANSACTION: "உங்கள் பரிவர்த்தனையை ரத்து செய்தது",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "ஒரு வரம்பு வரிசையை உருவாக்குவது ஆர்டர் தயாரிப்பாளருக்கு ரத்து செய்யாவிட்டால் ஒரு இலவச செயல்பாடாகும். ஆர்டர் எடுப்பவர் ஆர்டரை நிரப்பும்போது பரிவர்த்தனை கட்டணத்தை செலுத்த வேண்டும்.",
  Cancelled: "ரத்து செய்யப்பட்டது",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "சிறந்த பாதைக்கு இடையிலான மதிப்பில் வேறுபாடு மற்றும்",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "இடமாற்றத்திற்கான உள்ளமைக்கப்பட்ட கட்டணங்களைக் கொண்ட ஒரு டோக்கனை மாற்றுவதில் உங்களுக்கு சிக்கல்கள் இருந்தால் இந்த விருப்பத்தை இயக்கவும்.",
  Expired: "காலாவதியான",
  Filled: "பூர்த்தி",
  INSUFFICIENT_FOR_GASFEE:
    "மன்னிக்கவும், எரிவாயு கட்டணத்திற்கு போதுமான நிதி இல்லை",
  Open: "திறந்த",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "இடமாற்று பாதை பல முக்கிய பாதை பகுதிகளைக் கொண்டிருந்தால் மட்டுமே பகுதி நிரப்பு பொருந்தும். இது பகுதி மரணதண்டனை அனுமதிக்கிறது, சந்தை விலை சாதகமாக நகர்ந்தால் பரிவர்த்தனை தலைகீழாக மாறுவதைத் தடுக்கிறது.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "அவர்களின் ஆர்டரை செயல்படுத்த ஆர்டர் தயாரிப்பாளரால் நிர்ணயிக்கப்பட்ட விலை (செலுத்த டோக்கன் = x டோக்கன் செலுத்த)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "அவர்களின் ஆர்டரை நிறைவேற்றுவதற்காக ஆர்டர் தயாரிப்பாளரால் நிர்ணயிக்கப்பட்ட விலை (பெற டோக்கன் = y டோக்கன் பெற",
  REVIEW_LIMIT_ORDER: "வரம்பு வரிசையை மதிப்பாய்வு செய்யவும்",
  SEARCH_BY_TOKEN: "டோக்கன் அல்லது முகவரி மூலம் தேடுங்கள்",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "அதிக வழுக்கும் சகிப்புத்தன்மையை அமைப்பது பரிவர்த்தனைகள் வெற்றிபெற உதவும், ஆனால் நீங்கள் அவ்வளவு நல்ல விலையைப் பெறாமல் இருக்கலாம். எச்சரிக்கையுடன் பயன்படுத்தவும்.",
  STATUS_OF_THE_LIMIT_ORDER: "வரம்பு வரிசையின் நிலை",
  Suspended: "இடைநீக்கம்",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "இந்த கட்டணங்கள் பிணைய சுரங்கத் தொழிலாளர்களுக்கு செலுத்தப்படுகின்றன. HODL கட்டணத்தை கட்டுப்படுத்தாது.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "வர்த்தக அளவு காரணமாக சந்தை விலை மற்றும் மதிப்புக்கு இடையிலான வேறுபாடு",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "பெறப்பட்ட குறைந்தபட்ச தொகை, இல்லையெனில் பரிவர்த்தனை மாற்றப்படும்",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "உங்கள் பரிவர்த்தனைக்கு முன்னுரிமை அளிப்பதற்கான ஊக்கமாக இந்த கட்டணம் நேரடியாக சுரங்கத் தொழிலாளர்களுக்கு செல்கிறது.",
  TRANSACTION_REJECTED_TRY:
    "பரிவர்த்தனை நிராகரிக்கப்பட்டது, தயவுசெய்து மீண்டும் முயற்சிக்கவும்",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "தேர்ந்தெடுக்கப்பட்ட டோக்கனுக்கான பரிமாற்றம் இந்த நெட்வொர்க்கில் கிடைக்காது",
  YOU: "நீங்கள்",
};
