import BEP20ABI from "../ABI/BEP20.json";
import RouterABI from "../ABI/SwapRouterABI.json";
import config from "../config/config";
import Web3 from "web3";

import { connection, readWeb3 } from "../helper/connection";
import { convert } from "../helper/convert";
import {
    Multicall
} from "ethereum-multicall";

import {
    divideDecimal,
    formatNumber,
    checkIsAddress
} from "../helper/custommath";

export async function getbalance(from, symbol) {

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var balanceOf = 0;
            var bal = 0;
            if (symbol === "coin") {
                var getBalance = await web3.eth.getBalance(address);
                balanceOf = parseFloat(getBalance);
                bal = await divideDecimal(balanceOf, 18);
            } else {
                var Contract = new web3.eth.Contract(BEP20ABI, from);
                balanceOf = await Contract.methods.balanceOf(address).call();
                var decimals = await Contract.methods.decimals().call();
                bal = await divideDecimal(balanceOf, decimals);
                bal = await convert(bal);

            }

            return {
                balance: bal,
                balanceOf: balanceOf,
                error: ""
            };
        } else {
            return {
                balance: 0,
                balanceOf: 0,
                error: ""
            };
        }
    } catch (err) {
        console.log(err, 'errerrerrerrerrerrerr')
        return {
            balance: 0,
            balanceOf: 0,
            error: err
        };
    }
}

export async function approve(contractAddr, spender) {
    console.log(contractAddr, spender, 'contractAddr, spender')

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(BEP20ABI, contractAddr);
            var approveAmt = 100000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);


            var gasPrice = await web3.eth.getGasPrice();
            var estimateGas = await Contract.methods.approve(
                spender,
                approveAmt.toString()
            ).estimateGas({ from: address });
            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            var result1 = await Contract.methods.approve(
                spender,
                approveAmt.toString()
            ).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });

            console.log(result1, 'result1result1')
            var approvalAmt = (result1 && result1.events && result1.events.Approval
                && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value) ?
                parseFloat(result1.events.Approval.returnValues.value) : 0;

            return {
                value: result1,
                status: true,
                approvalAmt,
                error: null
            };
        } else {
            return {
                value: {},
                status: false,
                approvalAmt: 0,
                error: null
            };
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')

        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("User denied");
        var msg = "Please try again later";
        if (pos >= 0) {
            msg = "Confirmation rejected";
        }

        return {
            value: {},
            status: false,
            approvalAmt: 0,
            error: msg
        };
    }
}

export async function increaseAllowance(contractAddr, spender) {
    console.log(contractAddr, spender, 'contractAddr, spender')

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(BEP20ABI, contractAddr);
            var approveAmt = 100000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var gasPrice = await web3.eth.getGasPrice();
            var estimateGas = await Contract.methods.increaseAllowance(
                spender,
                approveAmt.toString()
            ).estimateGas({ from: address });
            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            var result1 = await Contract.methods.increaseAllowance(
                spender,
                approveAmt.toString()
            ).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas });

            return {
                value: result1,
                status: true,
                approvalAmt: approveAmt,
                error: null
            };
        } else {
            return {
                value: {},
                status: false,
                approvalAmt: 0,
                error: null
            };
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')

        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("User denied");
        var msg = "Please try again later";
        if (pos >= 0) {
            msg = "Confirmation rejected";
        }

        return {
            value: {},
            status: false,
            approvalAmt: 0,
            error: msg
        };
    }
}

export async function mint(contractAddr) {

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(BEP20ABI, contractAddr);
            var Amt = 100000 * (10 ** 18);
            Amt = await convert(Amt);
            console.log(contractAddr,
                Amt.toString(), '>>>>>>>>>>>>>>>>>>>')
            var result1 = await Contract.methods.mint(
                address,
                Amt.toString()
            ).send({ from: address });

            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        return {
            value: {},
            status: false
        };
    }
}

export async function allowance(contractAddr, spender) {

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var owner = get.address;

            var Contract = new web3.eth.Contract(BEP20ABI, contractAddr);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();

            return {
                value: result1,
                status: true
            };

        } else {
            return {
                value: 0,
                status: false
            };
        }
    } catch (err) {
        console.log(err, 'allowance errerr')
        return {
            value: 0,
            status: false
        };
    }
}


export async function getTokenDetail(address) {
    try {
        var get = await connection();
        console.log(get, 'getgetget')

        var web3 = get.web3;
        if (web3 === "") {
            web3 = new Web3(config.rpc[get.network]);
        }

        const multicall = new Multicall({
            web3Instance: web3,
        });

        const contractCallContext = [
            {
                reference: "symbol",
                contractAddress: address,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "symbol",
                        methodName: "symbol",
                        methodParameters: [],
                    },
                ],
            },
            {
                reference: "name",
                contractAddress: address,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "name",
                        methodName: "name",
                        methodParameters: [],
                    },
                ],
            },
            {
                reference: "decimals",
                contractAddress: address,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "decimals",
                        methodName: "decimals",
                        methodParameters: [],
                    },
                ],
            }
        ];

        const results = await multicall.call(contractCallContext);

        var tokenName = await getFormatMulticall(results, "name", 0);
        var symbol = await getFormatMulticall(results, "symbol", 0);
        var decimals = await getFormatMulticall(results, "decimals", 0);

        return {
            tokenName: tokenName,
            symbol: symbol,
            decimals: decimals,
            status: true,
            network: get.network,
            useraddress: get.address,
        };

    } catch (err) {
        console.log(err, 'getTokenDetail errerr')
        return {
            tokenName: "",
            symbol: "",
            decimals: "",
            status: false,
            useraddress: "",
            network: 0
        };
    }

}



export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function isAddress(address) {
    var get = await connection();
    try {
        var web3 = get.web3;
        if (web3 === "") {
            web3 = new Web3(config.rpc[get.network]);
        }
        var status = web3.utils.isAddress(address)
        return {
            value: status,
            status: status
        };

    } catch (err) {
        console.log(err, 'isAddress errerr')
        return {
            value: false,
            status: false
        };
    }
}


export async function simpleSwap(data) {
    console.log(data, 'simpleSwap')

    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(RouterABI, "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57");
            var val = 0 * 10 ** 18;
            val = val.toString();
            var gasPrice = await web3.eth.getGasPrice();
            var estimateGas = await Contract.methods.simpleSwap(
                data
            ).estimateGas({ from: address, value: val });
            console.log(estimateGas, 'estimateGasestimateGas')
            estimateGas = parseFloat(estimateGas) + parseFloat(30000);
            var result1 = await Contract.methods.simpleSwap(
                data
            ).send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas, value: val });

            console.log(result1, 'result1result1')
            var approvalAmt = 0

            return {
                value: result1,
                status: true,
                approvalAmt,
                error: null
            };
        } else {
            return {
                value: {},
                status: false,
                approvalAmt: 0,
                error: null
            };
        }
    } catch (err) {
        console.log(err, 'simpleSwap errerrerrerr')

        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("User denied");
        var msg = "Please try again later";
        if (pos >= 0) {
            msg = "Confirmation rejected";
        }

        return {
            value: {},
            status: false,
            approvalAmt: 0,
            error: msg
        };
    }
}

export async function getAllTokenBalances(tokens) {

    try {
        var get = await connection();
        var chainId = (tokens && tokens[0] && tokens[0].chainId) ?
            tokens[0].chainId : get.network;
        var web3 = readWeb3(chainId);
        var network = get.network;
        var address = get.address;
        var isWallet = checkIsAddress(network, address)
        if (address && address != "" && tokens.length > 0 && isWallet) {
            const multicall = new Multicall({
                web3Instance: web3,
            });

            var Contract1 = [];
            for (var u = 0; u < tokens.length; u++) {
                var tAddr = tokens[u].address;
                var isToken = checkIsAddress(tokens[u].chainId, tAddr);
                if (isToken) {
                    Contract1.push({
                        reference: "tokenBalances-" + u,
                        contractAddress: tAddr,
                        abi: BEP20ABI,
                        calls: [
                            {
                                reference: "balanceOf",
                                methodName: "balanceOf",
                                methodParameters: [address],
                            }
                        ]
                    });
                }
            }

            const results1 = await multicall.call(Contract1);
            for (var r1 = 0; r1 < tokens.length; r1++) {
                var decimals = tokens[r1].decimals;
                var bal = await getFormatMulticall(results1, "tokenBalances-" + r1, 0);
                bal = (bal && bal.hex) ? formatNumber(parseInt(bal.hex) / 10 ** decimals, 6) : 0;
                var newObj = tokens[r1];
                Object.assign(newObj, {
                    balance: bal
                });
                tokens[r1] = newObj;
            }

            return tokens;

        } else {
            return [];
        }
    } catch (err) {
        console.log(err, 'getAllTokenBalances errerrerrerrerr')
        return [];
    }

}

export async function getTokenInfo(chainId, tokenaddress) {
    try {
        var get = await connection();
        console.log(get, 'getgetget')

        var web3 = new Web3(config.rpc[chainId]);

        const multicall = new Multicall({
            web3Instance: web3,
        });

        const contractCallContext = [
            {
                reference: "info",
                contractAddress: tokenaddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "symbol",
                        methodName: "symbol",
                        methodParameters: [],
                    },
                    {
                        reference: "name",
                        methodName: "name",
                        methodParameters: [],
                    },
                    {
                        reference: "decimals",
                        methodName: "decimals",
                        methodParameters: [],
                    },
                ],
            }
        ];

        const results = await multicall.call(contractCallContext);

        var tokenName = await getFormatMulticall(results, "info", 0);
        var symbol = await getFormatMulticall(results, "info", 1);
        var decimals = await getFormatMulticall(results, "info", 2);

        return {
            tokenName: tokenName,
            symbol: symbol,
            decimals: decimals,
            status: true,
            network: get.network,
            useraddress: get.address,
        };

    } catch (err) {
        console.log(err, 'getTokenInfo errerr')
        return {
            tokenName: "",
            symbol: "",
            decimals: "",
            status: false,
            useraddress: "",
            network: 0
        };
    }

}

export async function getAllCoinBalances(tokens) {

    try {
        var get = await connection();
        var chainId = (tokens && tokens[0] && tokens[0].chainId) ?
            tokens[0].chainId : get.network;
        var web3 = readWeb3(chainId);
        var network = get.network;
        var address = get.address;
        var isWallet = checkIsAddress(network, address)
        if (address && address != "" && tokens.length > 0 && isWallet) {

            for (var u = 0; u < tokens.length; u++) {

                var getBalance = await web3.eth.getBalance(address);
                var balanceOf = parseFloat(getBalance);
                var bal = await divideDecimal(balanceOf, 18);

                var newObj = tokens[u];
                Object.assign(newObj, {
                    balance: bal
                });
                tokens[u] = newObj;

            }


            return tokens;

        } else {
            return [];
        }
    } catch (err) {
        console.log(err, 'getAllCoinBalances errerrerrerrerr')
        return [];
    }

}
