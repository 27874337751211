import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import axios from "axios";
import config from "../../config/config";

import { toastAlert } from "../../helper/toastAlert";
import { getTxName } from "../../helper/networkdata";

import { getLoginInfo } from "../../Api/TokenActions";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { getTokenLogo } from "../../helper/networkdata";
import { triggerLimitOrder } from "../../ContractActions/limitorderActions";
import { submitLimitOrder, submitP2pOrder, getLimitOrders, getP2POrders } from "./limitParaswapSdk";
import {
  shortText,
  formatNumber,
  divideValue,
  addValue,
  subtractValue
} from "../../helper/custommath";
import { setLoadHistory } from "../../reducers/Actions";

import { savelimitorders } from "../../Api/LimitOrderActions";
import { useTranslation } from "react-i18next";
import {
  validateAllowanceBalance
} from "./swap-details";

var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export default function ReviewTrade(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [txId, settxId] = useState("");

  var {
    getAllOrder1,
    swapDetails,
    walletConnection,
    tokenrate,
    showExpire,
    takeraddress } = props;

  function CloseModal() {
    setloading(false);
  }

  function ReverseRate(amount) {
    let rateCal = 1 / amount;
    return rateCal;
  }

  async function triggerOrder() {
    try {
      setloading(true);
      let details = { ...swapDetails, ...showExpire };
      var tokenDeci = parseFloat(details.fromdecimal);
      var nwrk = walletConnection.network;
      var coinAddr =
        details.fromaddress.toLowerCase() === coinAddress &&
          config &&
          config.coin &&
          config.coin[nwrk]
          ? config.coin[nwrk]
          : swapDetails.fromaddress;

      let allowanceAddr = {
        fromaddress: coinAddr,
      };
      var limitContract = config.limitorder[nwrk];

      var { orders } = await getLimitOrders(
        walletConnection.address,
        walletConnection.network
      );
      var lOrders = orders;

      var { orders } = await getP2POrders(
        walletConnection.address,
        walletConnection.network
      );

      var allorders = lOrders.concat(orders);
      var getAlready = allorders.filter(
        (data) =>
          data.makerAsset.toLowerCase() == coinAddr.toLowerCase() &&
          data.state == "PENDING"
      );
      console.log(getAlready, 'getAlreadygetAlready', coinAddr)
      var alreadyUsedAmt = 0
      if (getAlready && getAlready.length > 0) {
        for (var o = 0; o < getAlready.length; o++) {
          var fillableBalance = getAlready && getAlready[o] && getAlready[o].fillableBalance
            ? (getAlready[o].fillableBalance)
            : 0;
          var usedAmt = divideValue(fillableBalance, 10 ** tokenDeci)
          alreadyUsedAmt = addValue(alreadyUsedAmt, usedAmt);
        }
      }
      console.log(alreadyUsedAmt, 'getAlreadygetAlready')
      var youPay = details.pay;
      var { fromAllowance, fromBalance, coinBalance } =
        await validateAllowanceBalance(
          allowanceAddr,
          walletConnection,
          limitContract
        );
      console.log(fromAllowance, 'getAlreadygetAlready2')
      fromAllowance = fromAllowance / 10 ** tokenDeci;
      if (fromAllowance > 0) {
        fromAllowance = (alreadyUsedAmt >= fromAllowance) ?
          subtractValue(alreadyUsedAmt, fromAllowance) : subtractValue(fromAllowance, alreadyUsedAmt);
      }

      console.log(fromAllowance, youPay, 'getAlreadygetAlready3')
      var totalAllowance = addValue(alreadyUsedAmt, details.pay);

      if (details.fromaddress.toLowerCase() === coinAddress) {

        let totalAvlBal = parseFloat(fromBalance) + coinBalance;
        let remainingBal = subtractValue(totalAvlBal, alreadyUsedAmt);
        let availableWrapped = subtractValue(fromBalance, alreadyUsedAmt);

        if (parseFloat(youPay) > remainingBal && alreadyUsedAmt > 0) {
          window.$("#confirm_trade_modal").modal("hide");
          toastAlert("error", `${t("INSUFFICIENT")} ${details.fromsymbol} ${t(
            "BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF"
          )} ${formatNumber(parseFloat(alreadyUsedAmt), 6)}`, "limitorder");
          setloading(false);
          return;
        }

        if (parseFloat(youPay) <= parseFloat(coinBalance) || parseFloat(youPay) <= parseFloat(fromBalance)) {
          if (parseFloat(availableWrapped) < parseFloat(youPay)) {
            window.$("#confirm_trade_modal").modal("hide");
            toastAlert("error", "Please wrapped your token", "limitorder");
            setloading(false);
            return;
          } else if (parseFloat(youPay) > parseFloat(fromAllowance)) {
            window.$("#confirm_trade_modal").modal("hide");
            toastAlert("error", "Please unlock your token", "limitorder");
            setloading(false);
            return;
          }
        } else {
          window.$("#confirm_trade_modal").modal("hide");
          toastAlert("error", t("INSUFFICIENT_BALANCE"), "limitorder");
          setloading(false);
          return;
        }
      } else {
        let totalAvlBal = parseFloat(fromBalance);
        let remainingBal = totalAvlBal - alreadyUsedAmt;

        if (parseFloat(youPay) > remainingBal && alreadyUsedAmt > 0) {
          window.$("#confirm_trade_modal").modal("hide");
          toastAlert("error", `${t("INSUFFICIENT")} ${details.fromsymbol} ${t(
            "BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF"
          )} ${formatNumber(parseFloat(alreadyUsedAmt), 6)}`, "limitorder");
          setloading(false);
          return;
        }

        if (youPay > fromBalance) {
          window.$("#confirm_trade_modal").modal("hide");
          toastAlert("error", t("INSUFFICIENT_BALANCE"), "limitorder");
          setloading(false);
          return;
        } else if (youPay > fromAllowance) {
          window.$("#confirm_trade_modal").modal("hide");
          toastAlert("error", "Please unlock your token", "limitorder");
          setloading(false);
          return;
        }
      }

      var nwrk = walletConnection.network;
      var makerAsset =
        details.fromaddress.toLowerCase() === coinAddress &&
          config &&
          config.coin &&
          config.coin[nwrk]
          ? config.coin[nwrk]
          : details.fromaddress;
      var takerAsset =
        details.toaddress.toLowerCase() === coinAddress &&
          config &&
          config.coin &&
          config.coin[nwrk]
          ? config.coin[nwrk]
          : details.toaddress;

      details.fromaddress = makerAsset;
      details.toaddress = takerAsset;

      if (location.pathname === "/p2p") {
        var { newOrder, status, error } = await submitP2pOrder(
          details,
          takeraddress
        );
      } else {
        var { newOrder, status, error } = await submitLimitOrder(details);
      }

      if (newOrder && newOrder.orderHash) {
        var { ip, country } = await getLoginInfo();

        Object.assign(newOrder, {
          fromSymbol: swapDetails.fromsymbol,
          fromDecimal: swapDetails.fromdecimal,
          toSymbol: swapDetails.tosymbol,
          toDecimal: swapDetails.todecimal,
          ip,
          country,
        });

        savelimitorders(newOrder);
      }

      if (error) {
        toastAlert("error", t(error), "limitorder");
        setloading(false);
      } else if (status) {

        setTimeout(function () {
          window.$("#confirm_trade_modal").modal("hide");
          window.$("#success_order_modal").modal("show");

          toastAlert("success", t("SUCCESS_SUBMITTED_ORDER"), "limitorder");
          dispatch(
            setLoadHistory({
              status: true,
            })
          );
          setloading(false);
        }, 9000);

        setTimeout(function () {
          dispatch(
            setLoadHistory({
              status: false,
            })
          );
        }, 11000);

      }
    } catch (err) {
      console.log(err, 'errerrerrerrerr')
      setloading(false);
    }
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="confirm_trade_modal"
        tabIndex="-1"
        aria-labelledby="confirm_trade_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="confirm_trade_modalLabel">
                {location.pathname === "/swap-limit" && t("REVIEW_LIMIT_ORDER")}
                {location.pathname === "/p2p" && t("REVIEW_OTC_ORDER")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => CloseModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <div className="swap_select_token_wrap">
                  <div className="swap_select_token_inner">
                    <label>{t("PAY")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.fromlogo,
                              walletConnection.network
                            )}
                            alt={swapDetails.fromsymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.fromsymbol}
                          </span>
                        </div>
                      </div>

                      <input
                        className="swap_token_input"
                        type="text"
                        value={(swapDetails && swapDetails.pay) ? formatNumber(swapDetails.pay, 5) : 0}
                        readOnly
                      />
                      {/* <div className="swap_token_bal">~$0.001</div> */}
                    </div>
                  </div>
                </div>
                <div className="swap_arrow_container">
                  <img
                    src={require("../../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
                <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
                  <div className="swap_select_token_inner">
                    <label>{t("RECEIVE")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.tologo,
                              walletConnection.network
                            )}
                            alt={swapDetails.tosymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.tosymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={(swapDetails && swapDetails.receive) ? formatNumber(swapDetails.receive, 5) : 0}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="swap_info">
                <div>
                  <span>
                    {t("ORDER_PRICE")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="RevieworderPrice"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="RevieworderPrice"
                    place="right"
                    content={t("PRICE_SET_BY_THE_ORDER_MAKER_X")}
                  />
                  <span>
                    1 {swapDetails.fromsymbol} ={" "}
                    {(tokenrate && tokenrate.price1) ? formatNumber(tokenrate.price, 5) : "0"} {swapDetails.tosymbol}
                  </span>
                </div>
                <div>
                  <span>
                    {t("REVERSE_RATE")}{" "}
                    <i
                      className="far fa-question-circle ms-1"
                      id="reverseValue"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="reverseValue"
                    place="right"
                    content={t("PRICE_SET_BY_THE_ORDER_MAKER_Y")}
                  />
                  <span>
                    1 {swapDetails.tosymbol} ={" "}
                    {(tokenrate && tokenrate.price1) ? formatNumber(ReverseRate(tokenrate.price1), 5) : "0"}{" "}
                    {swapDetails.fromsymbol}
                  </span>
                </div>

                <div>
                  <span>
                    {t("EXPIRY_SM")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="Reviewexpiry"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="Reviewexpiry"
                    place="right"
                    content={t("EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER")}
                  />
                  {showExpire.value === "Never" ? (
                    <span>{showExpire.value}</span>
                  ) : (
                    <span>
                      {showExpire.value} {showExpire.label}
                    </span>
                  )}
                </div>

                {location.pathname === "/p2p" && (
                  <div>
                    <span>
                      {t("TAKER")}
                      <i
                        className="far fa-question-circle ms-1"
                        id="Reviewtakeraddr"
                      ></i>
                    </span>
                    <ReactTooltip
                      anchorId="Reviewtakeraddr"
                      place="right"
                      content={takeraddress}
                      className="address_tooltip"
                    />
                    <span>{shortText(takeraddress)}</span>
                  </div>
                )}

                <div>
                  <span>
                    {t("NETWORK_FEES")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="Reviewnetworkfee"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="Reviewnetworkfee"
                    place="right"
                    content={t("CREATING_A_LIMIT_ORDER_IS_A_FREE")}
                  />
                  <span className="d-flex align-items-center">
                    <img
                      src={require("../../assets/images/gift.png")}
                      alt="img"
                      className="me-2"
                      width={"30px"}
                      height={"30px"}
                    />
                    {t("FREE")}
                  </span>
                </div>
              </div>
              <div className="flex_btn_grp">
                <button
                  className="connect_wallet_btn w-100"
                  data-bs-dismiss="modal"
                  disabled={loading}
                >
                  {t("CANCEL")}{" "}
                </button>

                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => triggerOrder()}
                  disabled={loading}
                >
                  {t("SUBMIT")}{" "}
                  {loading && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
              {loading && (
                <div className="text-center">
                  <ReactLoading
                    type={"bars"}
                    color="#fbd534"
                    className="loading"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade primary_modal"
        id="success_order_modal"
        tabIndex="-1"
        aria-labelledby="success_order_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="success_order_modalLabel">
                {t("SUCCESS_SUBMITTED_ORDER")}{" "}
              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <svg
                  className="txcomplete"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                {/* <a
                  href={txId}
                  target="_blank"
                  className="d-block mt-3 text-center"
                >
                  {" "}
                  <i className="fa fa-eye" aria-hidden="true"></i> View on{" "}
                  {getTxName(walletConnection.network)}{" "}
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
