import mainetNetwork from "./mainet/network";
import mainetRpc from "./mainet/rpc";
import mainetTxLink from "./mainet/txLink";
import mainetRouter from "./mainet/swaprouter";
import mainetGastraker from "./mainet/gastrakerkey";
import mainetCoin from "./mainet/coin";
import mainetBridge from "./mainet/bridge";
import apiurl from "./mainet/apiurl";
import chaincode from "./mainet/chaincode";
import addnetworks from "./mainet/addnetwork";
import limitorder from "./mainet/limitorder";
import tokenProxy from "./mainet/tokenProxy";
import coingeckoId from "./mainet/coingeckoId";
import middlreware from "./mainet/middlreware";
import feeClaimer from "./mainet/feeClaimer";

var txLink = mainetTxLink;
var network = mainetNetwork;
var router = mainetRouter;
var rpc = mainetRpc;
var gastrakerkey = mainetGastraker;
var coin = mainetCoin;
var bridge = mainetBridge;
var addnetwork = addnetworks

var key = {
  coin: coin
};

if (process.env.NODE_ENV === "production") {
  key = {
    baseUrl: "https://hodlapi.wearedev.team/api",
    imageUrl: "https://hodlapi.wearedev.team",
    defaultLogo: "https://hodlapi.wearedev.team/tokens/default.png",
    network: network,
    txLink: txLink,
    router: router,
    rpc: rpc,
    coin: coin,//wrapped tokens
    gastrakerkey: gastrakerkey,//bridge to watch transaction
    bridge: bridge,
    apiurl: apiurl,
    reactLoader: "#fbd334",
    toFixed: 6,
    infuraId: "d5b89bd22ca242798dd9feb0ab031ccf",//ethereum
    projectId: "cb89ebb21cdccb2e1b591e189e27706a",//wallet connect,
    zerolayer: "0xe93685f3bba03016f02bd1828badd6195988d950",//bridge section
    chaincode: chaincode,
    addnetwork: addnetwork,
    limitorder: limitorder,
    tokenProxy: tokenProxy,//bridge section
    coingeckoId: coingeckoId,//price chart
    middlreware: middlreware,//to control bridge fee
    locationApi: "https://freeipapi.com/api/json",
    frontUrl: "https://hodl-frontend.pages.dev",
    feeClaimer: feeClaimer
  };
} else {
  key = {
    //baseUrl: "http://localhost:4013/api",
    // imageUrl: "http://localhost:4013",
    baseUrl: "https://hodlapi.wearedev.team/api",
    imageUrl: "https://hodlapi.wearedev.team",
    defaultLogo: "https://hodlapi.wearedev.team/tokens/default.png",
    network: network,
    txLink: txLink,
    router: router,
    rpc: rpc,
    coin: coin,
    gastrakerkey: gastrakerkey,
    bridge: bridge,
    apiurl: apiurl,
    reactLoader: "#fbd334",
    toFixed: 6,
    infuraId: "d5b89bd22ca242798dd9feb0ab031ccf",//ethereum
    projectId: "cb89ebb21cdccb2e1b591e189e27706a",//wallet connect,
    zerolayer: "0xe93685f3bba03016f02bd1828badd6195988d950",
    chaincode: chaincode,
    addnetwork: addnetwork,
    limitorder: limitorder,
    tokenProxy: tokenProxy,
    coingeckoId: coingeckoId,
    middlreware: middlreware,
    locationApi: "https://freeipapi.com/api/json",
    frontUrl: "https://hodl-frontend.pages.dev",
    feeClaimer: feeClaimer
  };
}
export default key;