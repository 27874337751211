export default {
  ABOUT_NEW_FEATURES: "নতুন বৈশিষ্ট্য সম্পর্কে।",
  ACCEPT: "গ্রহণ",
  ACTIVE_ORDERS: "সক্রিয় আদেশ",
  ADD_TOKEN: "একটি টোকেন যোগ করুন",
  AGGRESSIVE: "আক্রমণাত্মক",
  AND: "এবং",
  APPROVE: "অনুমোদন",
  ASSETS_ON_WAY: "আপনার সম্পদগুলি পথে রয়েছে এবং এতে উপস্থিত হওয়া উচিত",
  AUTO: "অটো",
  AVOID_TRANSFER:
    "দয়া করে একটি কেন্দ্রীভূত এক্সচেঞ্জ ঠিকানায় স্থানান্তর করা এড়িয়ে চলুন (বিনেন্স, হুওবি ...)। এটি কোনও সমর্থিত বৈশিষ্ট্য নয়",
  BALANCE: "ভারসাম্য",
  BALANCE_ONE: "ভারসাম্য:",
  BLOG: "ওটিসি",
  BRIDGE: "সেতু",
  CANCEL: "বাতিল",
  CANCELLATION_TRANSACTION_FEE:
    "সীমা অর্ডার বাতিলকরণ লেনদেনের ফি প্রদান জড়িত।",
  CANCEL_ALL: "সব বাতিল",
  CANCEL_LIMIT_NOT_FILLED:
    "যতক্ষণ না অর্ডারটি এখনও পূরণ না হয় ততক্ষণ আপনি একটি সীমা অর্ডার বাতিল করতে পারেন।",
  CANCEL_LIMIT_ORDER: "সীমা অর্ডার বাতিল করুন",
  CANCEL_ORDER: "আদেশ বাতিল",
  CANCEL_SELECTED: "নির্বাচিত বাতিল",
  CHAINS: "ব্লগ",
  CHOOSE_NETWORK: "নেটওয়ার্ক চয়ন করুন",
  CLASSIC_MODE: "ক্লাসিক মোড",
  CLEAR: "পরিষ্কার",
  CONFIRM: "নিশ্চিত করুন",
  CONFIRMATION_REJECTED: "নিশ্চিতকরণ প্রত্যাখ্যান!",
  CONFIRM_SWAP: "অদলবদল নিশ্চিত করুন",
  CONNECT_WALLET: "ওয়ালেট সংযুক্ত করুন",
  COPIED: "অনুলিপি",
  CURRENT: "কারেন্ট",
  CUSTOM: "কাস্টম",
  DAYS: "দিন",
  DEFAULT: "ডিফল্ট",
  DETAILS: "বিশদ",
  DISCLAIMER: "দাবি অস্বীকার",
  DISCONNECT_WALLET: "ওয়ালেট সংযোগ বিচ্ছিন্ন করুন",
  DISMISS: "বরখাস্ত",
  DUPLICATE_ORDER_SUBMIT: "সদৃশ আদেশ। এটি ইতিমধ্যে জমা দেওয়া হয়েছে",
  ENTER_AMT: "পরিমান লিখুন",
  EXCHANGE: "বিনিময়",
  EXPIRY: "মেয়াদোত্তীর্ণ",
  EXPIRY_SM: "মেয়াদোত্তীর্ণ",
  FAILED_LOAD_CHART: "চার্ট লোড করতে ব্যর্থ",
  FIND_BY_TOKEN_OR_STATUS: "টোকেন বা স্থিতি দ্বারা সন্ধান করুন",
  INSUFFICIENT_BALANCE: "অপর্যাপ্ত টাকা",
  INSUFFICIENT: "অপর্যাপ্ত",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "বিদ্যমান অর্ডারগুলি ভারসাম্য কভার করতে ভারসাম্য",
  RECEIVERS: "রিসিভার",
  WALETADDRESS: "মানিব্যাগের ঠিকানা",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "পরিষেবা এবং গোপনীয়তা নীতি শর্তান",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "নির্মাতা গ্রহণকারী একই টেকারের ঠিকানা হওয়া উচিত নয়",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "পিচ্ছিল সহনশীলতা 0.1% থেকে 15% এর মধ্যে হওয়া উচিত",
  SEARCH_A_TOKEN: "একটি টোকেন অনুসন্ধান করুন",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "আপনার লেনদেনটি আবারও যদি এই শতাংশের চেয়ে বেশি দামের পরিবর্তিত হয় তবে তা ফিরে আসবে",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "আপনার লেনদেনের জন্য গ্যাসের দাম সামঞ্জস্য করে।উচ্চতর GWEI = উচ্চ গতি।",
  REVIEW_OTC_ORDER: "ওটিসি অর্ডার পর্যালোচনা করুন",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "অর্ডার প্রস্তুতকারক দ্বারা নির্ধারিত মেয়াদ শেষ।এই সময়ের অতীত, আদেশটি শেষ হয়ে যাবে এবং সংরক্ষণাগারভুক্ত হবে।",
  SEARCH_A_LIQUIDITY_SOURCES: "একটি তরলতা উত্স অনুসন্ধান করুন",

  FAST: "দ্রুত",
  FAST_AND_SECURE:
    "ক্রস-চেইন সম্পদ আনার নিরাপদ, দ্রুত এবং সবচেয়ে সুরক্ষিত উপায়",
  FEATURE_TO_TRANSFER:
    "আপনার টোকেনগুলিকে কেন্দ্রীয় এক্সচেঞ্জের ঠিকানায় স্থানান্তর করতে দয়া করে এই বৈশিষ্ট্যটি ব্যবহার করবেন না।",
  FEES: "ফি",
  FILL_ORDER: "অর্ডার পূরণ করুন",
  FILL_OTC_ORDER: "ওটিসি অর্ডার পূরণ করুন",
  FREE: "বিনামূল্যে",
  FROM: "থেকে",
  GAS_COST: "গ্যাস ব্যয়",
  GAS_DESTINATION: "গন্তব্য শৃঙ্খলে গ্যাস",
  GAS_ON_DESTINATION: "গন্তব্য উপর গ্যাস",
  GAS_PRICE: "গ্যাসের দাম",
  GOT_IT: "বুঝেছি",
  GOT_PROBLEM_JUST: "একটি সমস্যা? শুধু যোগাযোগ করুন।",
  HIGH: "উচ্চ",
  HOURS: "ঘন্টার",
  IMPORT: "আমদানি",
  IMPOSSIBLE_TO_RETRIEVE:
    "এটি কোনও সমর্থিত বৈশিষ্ট্য নয়। এছাড়াও, দয়া করে আপনি যে ঠিকানাটি পূরণ করেছেন তা ডাবল-চেক করুন, ভুল ঠিকানায় প্রেরিত যে কোনও টোকেন পুনরুদ্ধার করা অসম্ভব।",
  INSTANT: "তাত্ক্ষণিক",
  INSUFFICIENT_APPROVED_AMT: "অপর্যাপ্ত অনুমোদিত পরিমাণ",
  INVALID_TOKEN_ADDRESS: "অবৈধ টোকেন ঠিকানা",
  KEEP_TRACK_NOTIFY:
    "আপনি আপনার প্যানেল বিজ্ঞপ্তিতে এটি ট্র্যাক রাখতে পারেন। এটি হয়ে গেলে আমরা আপনাকে অবহিত করব।",
  KEEP_YOU_POSTED: ".হ আমরা আপনাকে পোস্ট রাখব।",
  LEARN_MORE: "আরও শিখুন",
  LEGACY: "উত্তরাধিকার",
  LIMIT: "সীমা",
  LIMIT_ORDER: "সীমাবদ্ধ আদেশ",
  LIMIT_PRICE: "সীমাবদ্ধ দাম",
  LIMIT_PRICE_SM: "সীমাবদ্ধ দাম",
  LIQUIDITY_SOURCES: "তরলতা উত্স",
  LOW: "কম",
  MAKER: "নির্মাতা",
  MARKET: "বাজার",
  MAX: "সর্বোচ্চ",
  MAX_BASE_FEE: "সর্বোচ্চ বেস ফি",
  MAX_S: "সর্বোচ্চ",
  MAX_TO_PAY: "সর্বোচ্চ বেতন",
  MINIMUM_RECEIVED: "ন্যূনতম প্রাপ্ত",
  MINUTES: "মিনিট",
  MONTHS: "মাস",
  NEED_APPROVAL_MOVE: "আমাদের সরানোর জন্য আপনার অনুমোদনের প্রয়োজন",
  NEED_TO_WRAP: "আপনার মোড়ানো দরকার",
  NETWORK_FEES: "নেটওয়ার্ক ফি",
  NETWORK_SM: "অন্তর্জাল.",
  NEVER: "কখনও না",
  NONE: "কিছুই না",
  NON_CUSTODIAL_LIMIT:
    "অ-কাস্টমিয়াল সীমা ক্রমে। এটি একটি প্রযুক্তিগত প্রয়োজন",
  NORMAL: "সাধারণ",
  NO_ORDERS_YET: "আপনার এখনও কোন আদেশ নেই",
  NO_RECORDS: "কোন রেকর্ড নেই",
  ON_UR_BEHALF: "আপনার পক্ষ থেকে.",
  OOPS_WRONG: "কিছু ভুল হয়েছে.!",
  OPEN: "খোলা",
  ORDER_DETAILS: "আদেশ বিবরণী",
  ORDER_HISTORY: "অর্ডার ইতিহাস",
  ORDER_PRICE: "অর্ডার মূল্য",
  ORDER_ROUTING: "অর্ডার রাউটিং",
  OTC: "ওটিসি",
  PASTE: "আটকান",
  PAY: "পে",
  PENDING_CONFIRM: "ওয়ালেটে নিশ্চিতকরণ মুলতুবি।",
  PER: "প্রতি",
  PLEASE_CHOOSE_NETWORK: "নেটওয়ার্ক চয়ন করুন",
  PLEASE_LOGIN: "দয়া করে লগইন করুন",
  PLEASE_TRY_LATER: "অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন",
  POPULAR_TOKENS: "জনপ্রিয় টোকেন",
  PRICE: "দাম",
  PRICE_IMPACT: "দাম প্রভাব",
  PRIVACY_POLICY: "গোপনীয়তা নীতি",
  RATE: "রেট",
  READ_ACCEPT: "আমি পড়েছি এবং গ্রহণ করি",
  RECEIVE: "গ্রহণ",
  RECEIVED_ORDERS: "আদেশ প্রাপ্ত",
  REFUND: "ফেরত",
  REMOVE: "অপসারণ",
  REVERSE_RATE: "বিপরীত হার",
  REVIEW_TRADE: "বাণিজ্য পর্যালোচনা",
  SELECTED: "নির্বাচিত",
  SELECT_ALL: "সমস্ত নির্বাচন করুন",
  SELECT_FROM_INFO: "তথ্য থেকে নির্বাচন করুন",
  SELECT_NETWORK: "একটি নেটওয়ার্ক নির্বাচন করুন",
  SELECT_TOKEN: "একটি টোকেন নির্বাচন করুন",
  SELECT_TOKENN: "টোকেন নির্বাচন করুন",
  SELECT_TO_INFO: "তথ্য নির্বাচন করুন",
  SETTINGS: "সেটিংস",
  SIMPLE_MODE: "সাধারণ ভাব",
  SLIPPAGE: "পিচ্ছিল",
  SLIPPAGE_TOLERANCE: "পিচ্ছিল সহনশীলতা",
  SRY_INSUFFICIENT: "দুঃখিত, অপর্যাপ্ত",
  STATUS: "স্থিতি",
  STATUS_SM: "স্থিতি",
  STEP: "পদক্ষেপ",
  SUBMIT: "জমা দিন",
  SUBMITTED_ORDERS: "জমা দেওয়া আদেশ",
  SUBMIT_DATE: "তারিখ দাখিল করা",
  SUCCESS_FILLED: "সফলভাবে ভরা অর্ডার",
  SUCCESS_RECEIVED: "সফলভাবে প্রাপ্ত",
  SUCCESS_SUBMITTED_ORDER: "সফলভাবে আপনার অর্ডার জমা দিয়েছে",
  SWAP: "অদলবদল",
  SWAP_TRANSFER: "অদলবদল এবং স্থানান্তর",
  SWITCH_NETWORK: "স্যুইচ নেটওয়ার্ক",
  TAKER: "গ্রহণকারী",
  TAKER_ADDRESS: "গ্রহণকারী ঠিকানা",
  TERMS_OF_SERVICE: "সেবা পাবার শর্ত",
  TO: "প্রতি",
  TOKEN_NOT_FOUND: "টোকেন পাওয়া যায় নি",
  TOKEN_RECEIVE_SENT: "টোকেন গ্রহণের জন্য প্রেরণ করা হবে",
  TRADE: "বাণিজ্য",
  TRANSACTION_REJECTED_LATER:
    "লেনদেন প্রত্যাখ্যান করুন দয়া করে পরে আবার চেষ্টা করুন",
  TRANSFER: "স্থানান্তর",
  TRANSFER_ALERT: "সতর্কতা স্থানান্তর",
  TRANSFER_TO: "স্থানান্তর করা",
  TX_COST: "টিএক্স ব্যয়",
  UNLOCK: "আনলক করুন",
  USER_GUIDE: "ব্যবহারকারী গাইড",
  USE_ITAS: "এবং এটি ডাব্লু হিসাবে ব্যবহার করুন",
  VERIFY: "যাচাই করুন",
  VIEW_EXPLORER: "এক্সপ্লোরারে লেনদেন দেখুন",
  VIEW_ON: "দৃষ্টি রাখা",
  VIEW_TRANSACTION: "লেনদেন দেখুন",
  WRAP: "মোড়ানো",
  YOUR_ADDRESS: "আপনার ঠিকানা",
  YOUR_ORDER_CANCEL: "আপনার অর্ডার বাতিল করা হয়েছে",
  YOUR_WALLET: "আপনার মানিব্যাগ",
  YOU_GET: "তুমি পাও",
  YOU_PAY: "আপনি বেতন",
  YOU_RECEIVE: "আপনি পাবেন",
  YOU_WILL_RECEIVE: "আপনি পাবেন",
  ANYONE_CAN_CREATE:
    "যে কেউ বিদ্যমান টোকেনের নকল সংস্করণ সহ যে কোনও টোকেন তৈরি করতে পারেন। যথাযথ যত্ন নিন। কিছু টোকেন এবং তাদের প্রযুক্তিগত পরামিতিগুলি আমাদের পরিষেবাগুলির সাথে বেমানান হতে পারে। এই কাস্টম টোকেন আমদানি করে আপনি ঝুঁকিগুলি স্বীকার করেন এবং গ্রহণ করেন।",
  BY_SIGNING_MESSAGE:
    "এই বার্তাটি স্বাক্ষর করে, আপনি নিশ্চিত করেছেন যে আপনি এই অ্যাপ্লিকেশনটি থেকে অ্যাক্সেস করছেন না, বা মার্কিন যুক্তরাষ্ট্র বা অন্য কোনও সীমাবদ্ধ দেশের বাসিন্দা। আরও তথ্যের জন্য, আমাদের পড়ুন",
  CONSTITUTION: "সংবিধানকাও",
  CURRENTLY_CONNECTED: "আপনি বর্তমানে সংযুক্ত",
  IMPORT_TOKEN: "একটি টোকেন আমদানি করুন",
  IN_YOUR_WALLET: "আপনার মানিব্যাগে",
  MAKE_SURE_CHECKED:
    "আপনি টোকেন ঠিকানাটি পরীক্ষা করেছেন তা নিশ্চিত করুন এবং এটি সঠিক",
  ON: "চালু",
  PEOPLE: "মানুষ",
  PLEASE_SWITCH: "দয়া করে স্যুইচ করুন",
  SELECTED_NOT_SUPPORTED:
    "আপনার ওয়ালেটে নেটওয়ার্ক পরিবর্তন করুন, নির্বাচিত নেটওয়ার্ক সমর্থিত নয়",
  SIGN_FOR_ACCESS: "অ্যাক্সেসের জন্য সাইন",
  SIGN_MESSAGE: "সাইন বার্তা",
  LAUNCH_APP: "এপ্লিকেশন চালু করুন",
  BLOG_HEADING:
    "ডিফাই ডিএফআই - আপনার এক জায়গায় যা জানা দরকার তা সমস্ত কিছু। উন্নত ব্যবহারকারীদের এবং এর মধ্যে প্রত্যেকের জন্য নতুনদের জন্য।",
  GETTING_STARTED: "এইচওডিএল দিয়ে শুরু করা: টিউটোরিয়াল এবং পণ্য ব্যাখ্যাকারী",
  DEEP_DIVE: "হডল মধ্যে গভীরতা: কৌশল এবং পণ্য বৈশিষ্ট্য",
  BUILD_HODL: "এইচওডিএল উপর বিল্ডিং: প্রযুক্তিগত ডকুমেন্টেশন",
  BLOG_PLACEHOLDER:
    "আপনি যে পণ্য/বিষয় সম্পর্কে শিখতে চান তার জন্য অনুসন্ধান করুন",
  CHOOSE_TOPIC: "নেটওয়ার্ক চয়ন করুন",
  TRY: "চেষ্টা করুন",
  SEARCH_RESULTS: "অনুসন্ধান ফলাফল",
  SEARCH_TOPIC: "অনুসন্ধান বিষয়",
  LOAD_MORE: "আর ঢুকাও",
  SOCIAL_MEDIA: "সামাজিক মাধ্যম",
  NEW: "নতুন প্রথম",
  OLD: "প্রাচীনতম প্রথম",
  Z_A: " শিরোনাম জেড-এ",
  A_Z: " শিরোনাম এ-জেড",
  RESULTS: "ফলাফল",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "একটি বেস গ্যাস ফি স্বয়ংক্রিয়ভাবে নেটওয়ার্ক দ্বারা গণনা করা হয় এবং ব্লক থেকে ব্লক পর্যন্ত পরিবর্তিত হয়। আপনি যে বেস ফি দিতে চান তার সর্বোচ্চ পরিমাণ সেট করুন। আপনি যে সর্বোচ্চ বেস ফি সেট করেছেন তার মধ্যে একটি পার্থক্য এবং ব্লকটি খনন করার পরে আসল বেস ফি ফেরত দেওয়া হবে।",
  CANCELLED_TRANSACTION: "আপনার লেনদেন বাতিল",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "সীমাবদ্ধ অর্ডার তৈরি করা অর্ডার প্রস্তুতকারকের জন্য একটি নিখরচায় অপারেশন যদি না তারা বাতিল করে দেয়। অর্ডার গ্রহণকারীকে অর্ডারটি পূরণ করার সময় লেনদেনের ফিগুলির জন্য অর্থ প্রদান করতে হবে।",
  Cancelled: "বাতিল",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "সেরা রুট এবং মধ্যে মানের মধ্যে পার্থক্য",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "আপনার যদি স্থানান্তর করার জন্য অন্তর্নির্মিত ফি রয়েছে এমন একটি টোকেন অদলবদল করার সমস্যাগুলি থাকে তবে এই বিকল্পটি সক্ষম করুন।",
  Expired: "মেয়াদোত্তীর্ণ",
  Filled: "ভরাট",
  INSUFFICIENT_FOR_GASFEE: "দুঃখিত, গ্যাস ফি জন্য অপর্যাপ্ত তহবিল",
  Open: "খোলা",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "আংশিক ভরাট কেবল তখনই প্রযোজ্য যদি কোনও অদলবদল রুট একাধিক প্রধান রুটের অংশ থাকে। এটি আংশিক সম্পাদনের জন্য অনুমতি দেয়, যদি বাজারের দামের পক্ষে চলে যায় তবে লেনদেনকে বিপরীত হতে বাধা দেয়।",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "অর্ডার মেকার দ্বারা তাদের অর্ডার কার্যকর করার জন্য মূল্য নির্ধারণ করা হয়েছে (টোকেন পাওয়ার জন্য টোকেন = এক্স টোকেন প্রদান করতে)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "অর্ডার প্রস্তুতকারক দ্বারা তাদের অর্ডার কার্যকর করার জন্য মূল্য নির্ধারণ (টোকেন টোকেন = y টোকেন গ্রহণের জন্য",
  REVIEW_LIMIT_ORDER: "পর্যালোচনা সীমা আদেশ",
  SEARCH_BY_TOKEN: "টোকেন বা ঠিকানা দ্বারা অনুসন্ধান করুন",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "একটি উচ্চ স্লিপেজ সহনশীলতা সেট করা লেনদেনগুলিকে সফল হতে সহায়তা করতে পারে তবে আপনি এত ভাল দাম নাও পেতে পারেন। সতর্কতার সাথে ব্যবহার করুন.",
  STATUS_OF_THE_LIMIT_ORDER: "সীমা আদেশের স্থিতি",
  Suspended: "স্থগিত",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "এই ফিগুলি নেটওয়ার্ক মাইনারদের দেওয়া হয়। হডল ফি নিয়ন্ত্রণ করে না।",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "বাণিজ্য আকারের কারণে বাজার মূল্য এবং এস্ট। দামের মধ্যে পার্থক্য",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "সর্বনিম্ন প্রাপ্ত পরিমাণ, অন্যথায় লেনদেনটি ফিরিয়ে দেওয়া হবে",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "এই ফিটি আপনার লেনদেনকে অগ্রাধিকার দেওয়ার জন্য উত্সাহ হিসাবে সরাসরি খনিজদের কাছে যায়।",
  TRANSACTION_REJECTED_TRY: "লেনদেন প্রত্যাখ্যান, দয়া করে আবার চেষ্টা করুন",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "নির্বাচিত টোকেনের জন্য স্থানান্তর এই নেটওয়ার্কে উপলভ্য নয়",
  YOU: "আপনি",
};
