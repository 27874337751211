var tokenProxy = {
    1: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    56: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    137: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    43114: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    250: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    42161: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    10: "0x216b4b4ba9f3e719726886d34a177484278bfcae",
    8453: "0x93aAAe79a53759cD164340E4C8766E4Db5331cD7"
};
export default tokenProxy;