
import axios from 'axios';
import { ethers } from 'ethers1';
import BigNumber from 'bignumber.js';

import {connection} from "../../helper/connection";
import {removePointValue} from "../../helper/custommath";

import RouterABI from "../../ABI/SwapRouterABI.json";

import {
  // swap methods
  constructPartialSDK,
  constructEthersContractCaller,
  constructAxiosFetcher,
  // limitOrders methods
  constructBuildLimitOrder,
  constructSignLimitOrder,
  constructPostLimitOrder,
  // extra types
  SignableOrderData,
  LimitOrderToSend,
  constructGetLimitOrders,
  constructCancelLimitOrder,
  constructBuildLimitOrderTx,
  SwappableOrder,
} from '@paraswap/sdk';

const fetcher = constructAxiosFetcher(axios);

export async function submitLimitOrder(resp:any) {
   
    var get = await connection();
    var provider1:any = get.provider;
    let network = get.network;
    var web3:any = get.web3;
    const provider = new ethers.providers.Web3Provider(provider1);

    const contractCaller = constructEthersContractCaller(
    {
        ethersProviderOrSigner: provider,
        EthersContract: ethers.Contract,
    },
    get.address
    );

    const paraSwapLimitOrderSDK = constructPartialSDK(
    {
        chainId: network,
        fetcher,
        contractCaller,
    },
    constructBuildLimitOrder,
    constructSignLimitOrder,
    constructPostLimitOrder
    );

    
    let fDecimals = parseFloat(resp.fromdecimal);
    var makerAmount = parseFloat(resp.pay)*10**fDecimals;
    makerAmount = removePointValue(makerAmount);

    let tDecimals = parseFloat(resp.todecimal);
    var takerAmount = parseFloat(resp.receive)*10**tDecimals;
    takerAmount = removePointValue(takerAmount);

    var expire = 0;
    var timeVal = parseFloat(resp.value);
    if(resp.label=="Minutes"){
        var now = new Date();
        now.setMinutes(now.getMinutes() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Hours"){
        var now = new Date();
        now.setHours(now.getHours() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Days"){
        var now = new Date();
        now.setDate(now.getDate() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Months"){
        var now = new Date();
        now.setMonth(now.getMonth() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }
console.log(resp,'respresprespresp>>>>>>>')
var txCount = await web3.eth.getTransactionCount(get.address, 'pending')
    const orderInput = {
        nonce: txCount,
        expiry: expire,
        makerAsset: resp.fromaddress,
        takerAsset: resp.toaddress,
        makerAmount: makerAmount.toString(),
        takerAmount: takerAmount.toString(),
        maker: get.address
    };

  const signableOrderData: SignableOrderData = await paraSwapLimitOrderSDK.buildLimitOrder(orderInput);
  
  try{
  const signature: string = await paraSwapLimitOrderSDK.signLimitOrder(
    signableOrderData
  );

  const orderToPostToApi: LimitOrderToSend = {
    ...signableOrderData.data,
    signature,
  };

    const newOrder = await paraSwapLimitOrderSDK.postLimitOrder(orderToPostToApi);
    console.log(newOrder,'newOrdernewOrder');
    return {
        newOrder,
        status:true,
        error:null
    }
}catch(err:any){
    var errorMsg = err.toString();
    
    //console.log(test,'testtesttesttest')
    console.log(errorMsg,'errorMsgerrorMsg')
    var pos = errorMsg.search("rejected");
    var pos1 = errorMsg.search("already used in augustusRFQ storage");
    var pos2 = errorMsg.search("'maker' already submitted");
    console.log(pos2,'pos2pos2pos2')
    var errorVal = errorMsg.replace("'maker'","")
    var pos3 = errorMsg.search("FetcherError");

    console.log(errorVal,'errorMsgerrorMsg')
    var eMsg = "Please try again later";
    if(pos>=0){
        eMsg = "Confirmation Rejected"
    }else if(pos1>=0){
        eMsg = "Please try different price";
    }else if(pos2>=0){
        
            var getError = errorMsg.split(" ");
            var usedBal  =getError[4];
            var availableBal  =getError[10].replace(")","");
            if(usedBal && parseFloat(usedBal)>=0 && availableBal && parseFloat(availableBal)>=0){
                eMsg = `Already submitted ${usedBal/10**fDecimals} to limit order service. your current balance ${availableBal/10**fDecimals}`
            }else if(errorVal){
                eMsg = errorVal;
            }
       
       
        
    }else if(pos3>=0){

        var errorVal1 = errorMsg.replace("FetcherError:","");
        eMsg = errorVal1;

    }
    console.log(err,'errerrerrerr')
    return {
        newOrder:{},
        error:eMsg,
        status:false
    }
}
  
}

export async function getLimitOrders(address:string,network:number) {
    
    try{
        const paraSwapLimitOrderSDK = constructPartialSDK(
            {
            chainId: network,
            fetcher,
            },
            constructGetLimitOrders
        );
        const response = await paraSwapLimitOrderSDK.getLimitOrders({
            maker: address,
            type: 'LIMIT',
        });
        console.log('orders', response);
        return {
            orders:(response && response.orders)?response.orders:[],
            limit:(response && response.limit)?response.limit:20,
            offset:(response && response.limit)?response.offset:20,
        }
    }catch(err){
        return {
            orders:[],
            limit:20,
            offset:0,
        }
    }
}

export async function cancelLimitOrders(hash:any) {
    
    try{
    var get = await connection();
    var provider1:any = get.provider;
    let network = get.network;
    const provider = new ethers.providers.Web3Provider(provider1);

    const contractCaller = constructEthersContractCaller(
        {
            ethersProviderOrSigner: provider,
            EthersContract: ethers.Contract,
        },
        get.address
    );
    const paraSwapLimitOrderSDK = constructPartialSDK(
    {
        chainId: network,
        fetcher,
        contractCaller,
    },
    constructGetLimitOrders,
    constructCancelLimitOrder
    );
    

    if(hash && hash.length==1){
        var deleteTx: ethers.ContractTransaction =
        await paraSwapLimitOrderSDK.cancelLimitOrder(hash[0]);
    }else{
        var deleteTx: ethers.ContractTransaction =
        await paraSwapLimitOrderSDK.cancelLimitOrderBulk(hash);
    }

    return {
        err:null,
        hash:(deleteTx && deleteTx.hash)?deleteTx.hash:""
    }
    
    }catch(err){
        console.log(err,'errerrerr')
        return {
            err,
            hash:null
        }
    }
}


export async function submitP2pOrder(resp:any,takeraddress:any) {
   
    var get = await connection();
    var provider1:any = get.provider;
    var web3:any = get.web3;
    let network = get.network;
    const provider = new ethers.providers.Web3Provider(provider1);

    const contractCaller = constructEthersContractCaller(
    {
        ethersProviderOrSigner: provider,
        EthersContract: ethers.Contract,
    },
    get.address
    );

    const paraSwapLimitOrderSDK = constructPartialSDK(
    {
        chainId: network,
        fetcher,
        contractCaller,
    },
    constructBuildLimitOrder,
    constructSignLimitOrder,
    constructPostLimitOrder
    );

    
    let fDecimals = parseFloat(resp.fromdecimal);
    var makerAmount = parseFloat(resp.pay)*10**fDecimals;
    makerAmount = removePointValue(makerAmount);

    let tDecimals = parseFloat(resp.todecimal);
    var takerAmount = parseFloat(resp.receive)*10**tDecimals;
    takerAmount = removePointValue(takerAmount);

    var expire = 0;
    var timeVal = parseFloat(resp.value);
    if(resp.label=="Minutes"){
        var now = new Date();
        now.setMinutes(now.getMinutes() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Hours"){
        var now = new Date();
        now.setHours(now.getHours() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Days"){
        var now = new Date();
        now.setDate(now.getDate() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }else if(resp.label=="Months"){
        var now = new Date();
        now.setMonth(now.getMonth() + timeVal);
        now = new Date(now);
        expire = Math.floor(now.getTime()/1000);
    }
    console.log(resp,'respresprespresp')

    var txCount = await web3.eth.getTransactionCount(get.address, 'pending')

    const orderInput = {
        nonce: txCount,
        expiry: expire,
        makerAsset: resp.fromaddress,
        takerAsset: resp.toaddress,
        makerAmount: makerAmount.toString(),
        takerAmount: takerAmount.toString(),
        maker: get.address,
        taker:takeraddress
    };
    console.log(orderInput,'orderInputorderInput')

  const signableOrderData: SignableOrderData = await paraSwapLimitOrderSDK.buildLimitOrder(orderInput);
  
  try{
  const signature: string = await paraSwapLimitOrderSDK.signLimitOrder(
    signableOrderData
  );

  const orderToPostToApi: LimitOrderToSend = {
    ...signableOrderData.data,
    signature,
  };

    const newOrder = await paraSwapLimitOrderSDK.postP2POrder(orderToPostToApi);
    console.log(newOrder,'newOrdernewOrder');
    return {
        newOrder,
        status:true,
        error:null
    }
}catch(err:any){
    var errorMsg = err.toString();
    
    //console.log(test,'testtesttesttest')
    console.log(errorMsg,'errorMsgerrorMsg')
    var pos = errorMsg.search("rejected");
    var pos1 = errorMsg.search("already used in augustusRFQ storage");
    var pos2 = errorMsg.search("'maker' already submitted");
    console.log(pos2,'pos2pos2pos2')
    var errorVal = errorMsg.replace("'maker'","")
    var pos3 = errorMsg.search("FetcherError");

    console.log(errorVal,'errorMsgerrorMsg')
    var eMsg = "Please try again later";
    if(pos>=0){
        eMsg = "Confirmation Rejected"
    }else if(pos1>=0){
        eMsg = "Please try different price";
    }else if(pos2>=0){
        
            var getError = errorMsg.split(" ");
            var usedBal  =getError[4];
            var availableBal  =getError[10].replace(")","");
            if(usedBal && parseFloat(usedBal)>=0 && availableBal && parseFloat(availableBal)>=0){
                eMsg = `Already submitted ${usedBal/10**fDecimals} to limit order service. your current balance ${availableBal/10**fDecimals}`
            }else if(errorVal){
                eMsg = errorVal;
            }
       
       
        
    }else if(pos3>=0){

        var errorVal1 = errorMsg.replace("FetcherError:","");
        eMsg = errorVal1;

    }
    console.log(err,'errerrerrerr')
    return {
        newOrder:{},
        error:eMsg,
        status:false
    }
}
  
}

export async function getP2POrders(address:string,network:number) {
    
    try{
        const paraSwapLimitOrderSDK = constructPartialSDK(
            {
            chainId: network,
            fetcher,
            },
            constructGetLimitOrders
        );
        var makerOrders = await paraSwapLimitOrderSDK.getLimitOrders({
            maker: address,
            type: 'P2P',
        });
       var mOrders = (makerOrders && makerOrders.orders)?makerOrders.orders:[];

        return {
            orders:mOrders,
        }
    }catch(err){
        return {
            orders:[]
        }
    }
}

export async function getP2POrdersByTaker(address:string,network:number) {
    
    try{
        const paraSwapLimitOrderSDK = constructPartialSDK(
            {
            chainId: network,
            fetcher,
            },
            constructGetLimitOrders
        );
        const response = await paraSwapLimitOrderSDK.getLimitOrders({
            taker: address,
            type: 'P2P',
        });
       
        return {
            orders:(response && response.orders)?response.orders:[],
            limit:(response && response.limit)?response.limit:20,
            offset:(response && response.limit)?response.offset:20
        }
    }catch(err){
        return {
            orders:[],
            limit:20,
            offset:0
        }
    }
}

export async function submitFillOrder(resp:any) {
   console.log(resp,'resprespresp')
    var get = await connection();
    var provider1:any = get.provider;
    let network = get.network;
    var web3:any= get.web3;
    const provider = new ethers.providers.Web3Provider(provider1);

    const contractCaller = constructEthersContractCaller(
    {
        ethersProviderOrSigner: provider,
        EthersContract: ethers.Contract,
    },
    get.address
    );

    const takerSDK = constructPartialSDK(
    {
        chainId: network,
        fetcher,
        contractCaller,
    },
    constructBuildLimitOrder,
    constructSignLimitOrder,
    constructBuildLimitOrderTx
    );

    
    let fDecimals = parseFloat(resp.fromdecimal);
    var makerAmount = parseFloat(resp.pay);
    makerAmount = removePointValue(makerAmount);

    let tDecimals = parseFloat(resp.todecimal);
    var takerAmount = parseFloat(resp.receive);
    takerAmount = removePointValue(takerAmount);
    
    const orderWithSignature: SwappableOrder = {
        nonceAndMeta: resp.nonceAndMeta,
        expiry: resp.expiry,
        makerAsset: resp.fromaddress,
        takerAsset: resp.toaddress,
        maker: resp.maker,
        taker: "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
        makerAmount: makerAmount.toString(),
        takerAmount: takerAmount.toString(),
        signature:resp.signature
      };

console.log(orderWithSignature,'orderWithSignatureorderWithSignature')
  try{
    const txData = await takerSDK.buildLimitOrderTx(
        {
          srcDecimals: resp.fromdecimal,
          destDecimals: resp.todecimal,
          userAddress: get.address,
          orders: [orderWithSignature],
        }
      );
    
      const { gas: payloadGas, ...LOPayloadTxParams } = txData;
    console.log(LOPayloadTxParams,'LOPayloadTxParamsLOPayloadTxParams')

      // compose ethers transaction out of provided params
      const transaction: ethers.providers.TransactionRequest = {
        ...LOPayloadTxParams,
        gasPrice: '0x' + new BigNumber(LOPayloadTxParams.gasPrice).toString(16),
        gasLimit: '0x' + new BigNumber(payloadGas || 5000000).toString(16),
        value: '0x' + new BigNumber(LOPayloadTxParams.value).toString(16),
      };
    
      // send
      const takerFillsOrderTx =await web3.eth.sendTransaction(transaction);
       // await taker.sendTransaction(transaction);
       //await web3.eth.estimateGas(transaction);
        
    
      console.log('takerFillsOrderTx', takerFillsOrderTx);

      return {
        hash:(takerFillsOrderTx && takerFillsOrderTx.transactionHash)?takerFillsOrderTx.transactionHash:"",
        status:(takerFillsOrderTx && takerFillsOrderTx.status)?takerFillsOrderTx.status:false,
        error:null
      }
}catch(err:any){
    console.log(err,'submitFillOrder errerrerrerr')
    var errMsg = (err && err.message) ? err.message : err;
    var pos = errMsg.search("User denied");
    var pos1=errMsg.search("This transaction has some errors and may fail. Please contact support for more details")
    var msg = "Please try again later";
    if (pos >= 0) {
        msg = "Confirmation rejected";
    }else if(pos1>=0){
        msg = "This transaction has some errors and may fail. Please contact support for more details"; 
    }
    return {
        hash:"",
        status:false,
        error:msg
    }
}
  
}

export async function estimateGasFillOrder(resp:any) {
    console.log(resp,'resprespresp')
     var get = await connection();
     var provider1:any = get.provider;
     let network = get.network;
     var web3:any= get.web3;
     const provider = new ethers.providers.Web3Provider(provider1);
 
     const contractCaller = constructEthersContractCaller(
     {
         ethersProviderOrSigner: provider,
         EthersContract: ethers.Contract,
     },
     get.address
     );
 
     const takerSDK = constructPartialSDK(
     {
         chainId: network,
         fetcher,
         contractCaller,
     },
     constructBuildLimitOrder,
     constructSignLimitOrder,
     constructBuildLimitOrderTx
     );
 
     
     let fDecimals = parseFloat(resp.fromdecimal);
     var makerAmount = parseFloat(resp.pay);
     makerAmount = removePointValue(makerAmount);
 
     let tDecimals = parseFloat(resp.todecimal);
     var takerAmount = parseFloat(resp.receive);
     takerAmount = removePointValue(takerAmount);
     
     const orderWithSignature: SwappableOrder = {
         nonceAndMeta: resp.nonceAndMeta,
         expiry: resp.expiry,
         makerAsset: resp.fromaddress,
         takerAsset: resp.toaddress,
         maker: resp.maker,
         taker: "0xdef171fe48cf0115b1d80b88dc8eab59176fee57",
         makerAmount: makerAmount.toString(),
         takerAmount: takerAmount.toString(),
         signature:resp.signature
       };
 
 console.log(orderWithSignature,'orderWithSignatureorderWithSignature')
   try{
     const txData:any = await takerSDK.buildLimitOrderTx(
         {
           srcDecimals: resp.fromdecimal,
           destDecimals: resp.todecimal,
           userAddress: get.address,
           orders: [orderWithSignature],
         }
       );
     
//        let gasPrice = await web3.eth.getGasPrice();
// console.log(txData,'estimatedGasestimatedGasestimatedGas')
//        var estimatedGas = await web3.eth.estimateGas({
//         from: get.address,
//          to: txData.to,
//          data: txData.data,
//          gasPrice:gasPrice
//        })
         
      
    var estimatedGas = (txData && txData.gas)?txData.gas/10**8:320462/10**8;
    console.log(estimatedGas,'estimatedGasestimatedGas')
    return {
        estimatedGas:estimatedGas
    }
 }catch(err){
     console.log(err,'errerrerrerr')
     return {
         estimatedGas:320462/10**8
     }
 }
   
 }