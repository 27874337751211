export default {
  ABOUT_NEW_FEATURES: "sulle nuove funzionalità.",
  ACCEPT: "Accettare",
  ACTIVE_ORDERS: "Ordini attivi",
  ADD_TOKEN: "Aggiungi un token",
  AGGRESSIVE: "Aggressivo",
  AND: "E",
  APPROVE: "Approvare",
  ASSETS_ON_WAY: "I tuoi beni sono in arrivo e dovrebbero arrivare",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Si prega di evitare di trasferirsi a un indirizzo di scambio centralizzato (Binance, Huobi ...). Non è una funzionalità supportata",
  BALANCE: "Bilancia",
  BALANCE_ONE: "Bilancia:",
  BLOG: "OTC",
  BRIDGE: "Ponte",
  CANCEL: "Annulla",
  CANCELLATION_TRANSACTION_FEE:
    "La cancellazione di un ordine limite prevede il pagamento delle commissioni di transazione.",
  CANCEL_ALL: "Cancella tutto",
  CANCEL_LIMIT_NOT_FILLED:
    "È possibile annullare un ordine limite fintanto che l'ordine non è stato ancora riempito.",
  CANCEL_LIMIT_ORDER: "Annullare l'ordine limite",
  CANCEL_ORDER: "Annulla Ordine",
  CANCEL_SELECTED: "Annulla selezionata",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Scegli la rete",
  CLASSIC_MODE: "Modalità classica",
  CLEAR: "Chiaro",
  CONFIRM: "Confermare",
  CONFIRMATION_REJECTED: "Conferma rifiutata.!",
  CONFIRM_SWAP: "Conferma lo scambio",
  CONNECT_WALLET: "Collegare il portafoglio",
  COPIED: "Copiato",
  CURRENT: "Attuale",
  CUSTOM: "Costume",
  DAYS: "Giorni",
  DEFAULT: "Predefinito",
  DETAILS: "Dettagli",
  DISCLAIMER: "Disclaimer",
  DISCONNECT_WALLET: "Scollet del portafoglio",
  DISMISS: "Congedare",
  DUPLICATE_ORDER_SUBMIT: "Ordine duplicato. Questo è già presentato",
  ENTER_AMT: "Inserire l'importo",
  EXCHANGE: "Scambio",
  EXPIRY: "SCADENZA",
  EXPIRY_SM: "Scadenza",
  FAILED_LOAD_CHART: "Impossibile caricare il grafico",
  FIND_BY_TOKEN_OR_STATUS: "Trova per token o status",
  INSUFFICIENT_BALANCE: "Equilibrio insufficiente",
  INSUFFICIENT: "Insufficiente",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "saldo per coprire gli ordini esistenti l'equilibrio di",
  RECEIVERS: "Ricevitore",
  WALETADDRESS: "Indirizzo del portafoglio",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Si prega di accettare i termini di servizio e l'informativa sulla privacy",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker non dovrebbe essere lo stesso indirizzo Taker",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "La tolleranza allo slittamento dovrebbe essere tra lo 0,1% e il 15%",
  SEARCH_A_TOKEN: "Cerca un token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "La tua transazione tornerà se il prezzo cambia sfavorevolmente di più di questa percentuale",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Regola il prezzo del gas per la tua transazione.GWEI più elevato = velocità più alta.",
  REVIEW_OTC_ORDER: "Rivedi l'ordine OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Periodo di scadenza stabilito dal produttore degli ordini.Passato questo periodo, l'ordine scadrà e verrà archiviato.",
  SEARCH_A_LIQUIDITY_SOURCES: "Cerca fonti di liquidità",

  FAST: "Veloce",
  FAST_AND_SECURE:
    "Il modo sicuro, veloce e più sicuro per portare risorse incrociate",
  FEATURE_TO_TRANSFER:
    "Si prega di non utilizzare questa funzione per trasferire i token su un indirizzo di uno scambio centralizzato.",
  FEES: "Commissioni",
  FILL_ORDER: "Riempire l'ordine",
  FILL_OTC_ORDER: "Riempire l'ordine OTC",
  FREE: "Gratuito",
  FROM: "Da",
  GAS_COST: "Costo del gas",
  GAS_DESTINATION: "Gas sulla catena di destinazione",
  GAS_ON_DESTINATION: "Gas sulla destinazione",
  GAS_PRICE: "Prezzo del gas",
  GOT_IT: "Fatto",
  GOT_PROBLEM_JUST: "Ho un problema? Mettiti in contatto.",
  HIGH: "Alto",
  HOURS: "Ore",
  IMPORT: "Importare",
  IMPOSSIBLE_TO_RETRIEVE:
    "Non è una funzionalità supportata. Inoltre, ricontrollare l'indirizzo che riempi, qualsiasi token inviato all'indirizzo sbagliato sarà impossibile da recuperare.",
  INSTANT: "Immediato",
  INSUFFICIENT_APPROVED_AMT: "Importo approvato insufficiente",
  INVALID_TOKEN_ADDRESS: "Indirizzo token non valido",
  KEEP_TRACK_NOTIFY:
    "Puoi tenere traccia su di esso nella notifica del pannello. Ti avviseremo una volta fatto.",
  KEEP_YOU_POSTED: ". Ti terremo aggiornato.",
  LEARN_MORE: "Saperne di più",
  LEGACY: "Eredità",
  LIMIT: "Limite",
  LIMIT_ORDER: "Ordine limite",
  LIMIT_PRICE: "Prezzo limite",
  LIMIT_PRICE_SM: "Prezzo limite",
  LIQUIDITY_SOURCES: "Fonti di liquidità",
  LOW: "Basso",
  MAKER: "Creatore",
  MARKET: "Mercato",
  MAX: "Max",
  MAX_BASE_FEE: "Tassa di base massima",
  MAX_S: "max",
  MAX_TO_PAY: "Max da pagare",
  MINIMUM_RECEIVED: "Ricevuto minimo",
  MINUTES: "Minuti",
  MONTHS: "Mesi",
  NEED_APPROVAL_MOVE: "Abbiamo bisogno della tua approvazione per muoversi",
  NEED_TO_WRAP: "Devi avvolgere",
  NETWORK_FEES: "Commissioni di rete",
  NETWORK_SM: "rete.",
  NEVER: "Mai",
  NONE: "Nessuno",
  NON_CUSTODIAL_LIMIT:
    "Nell'ordine limite non affidatario. È un requisito tecnico sul",
  NORMAL: "Normale",
  NO_ORDERS_YET: "Non hai ancora ordini",
  NO_RECORDS: "Nessuna registrazione",
  ON_UR_BEHALF: "a vostro nome.",
  OOPS_WRONG: "Oops! Qualcosa è andato storto.!",
  OPEN: "Aprire",
  ORDER_DETAILS: "Dettagli dell'ordine",
  ORDER_HISTORY: "Cronologia ordini",
  ORDER_PRICE: "Prezzo dell'ordine",
  ORDER_ROUTING: "Ordine routing",
  OTC: "OTC",
  PASTE: "Impasto",
  PAY: "Paga",
  PENDING_CONFIRM: "In attesa di conferma sul portafoglio.",
  PER: "per",
  PLEASE_CHOOSE_NETWORK: "Scegli la rete",
  PLEASE_LOGIN: "Si prega di accedere a",
  PLEASE_TRY_LATER: "Per favore riprova più tardi",
  POPULAR_TOKENS: "Token popolari",
  PRICE: "Prezzo",
  PRICE_IMPACT: "Impatto sui prezzi",
  PRIVACY_POLICY: "politica sulla riservatezza",
  RATE: "Valutare",
  READ_ACCEPT: "Leggo e accetto",
  RECEIVE: "Ricevere",
  RECEIVED_ORDERS: "Ordini ricevuti",
  REFUND: "RIMBORSO",
  REMOVE: "Rimuovere",
  REVERSE_RATE: "Tasso inverso",
  REVIEW_TRADE: "Revisione del commercio",
  SELECTED: "selezionato",
  SELECT_ALL: "Seleziona tutto",
  SELECT_FROM_INFO: "Si prega di selezionare tra le informazioni",
  SELECT_NETWORK: "Seleziona una rete",
  SELECT_TOKEN: "Seleziona un token",
  SELECT_TOKENN: "Seleziona token",
  SELECT_TO_INFO: "Seleziona le informazioni",
  SETTINGS: "Impostazioni",
  SIMPLE_MODE: "Modalità semplice",
  SLIPPAGE: "Slittamento",
  SLIPPAGE_TOLERANCE: "Tolleranza di slittamento",
  SRY_INSUFFICIENT: "Scusa, insufficiente",
  STATUS: "STATO",
  STATUS_SM: "Stato",
  STEP: "Fare un passo",
  SUBMIT: "Invia",
  SUBMITTED_ORDERS: "Ordini presentati",
  SUBMIT_DATE: "Data di invio",
  SUCCESS_FILLED: "Ordinare con successo pieno",
  SUCCESS_RECEIVED: "Ricevuto con successo",
  SUCCESS_SUBMITTED_ORDER: "Ha inviato correttamente il tuo ordine",
  SWAP: "Scambio",
  SWAP_TRANSFER: "Scambia e trasferimento",
  SWITCH_NETWORK: "Switch Network",
  TAKER: "Taker",
  TAKER_ADDRESS: "Indirizzo di Taker",
  TERMS_OF_SERVICE: "Termini di servizio",
  TO: "A",
  TOKEN_NOT_FOUND: "Token non trovato",
  TOKEN_RECEIVE_SENT: "Il token da ricevere verrà inviato a",
  TRADE: "Commercio",
  TRANSACTION_REJECTED_LATER: "Transazione rifiutata, riprova più tardi",
  TRANSFER: "Trasferimento",
  TRANSFER_ALERT: "Avviso di trasferimento",
  TRANSFER_TO: "Trasferire a",
  TX_COST: "Costo TX",
  UNLOCK: "Sbloccare",
  USER_GUIDE: "Guida utente",
  USE_ITAS: "e usalo come w",
  VERIFY: "Verificare",
  VIEW_EXPLORER: "Visualizza la transazione in Explorer",
  VIEW_ON: "Visualizza su",
  VIEW_TRANSACTION: "Visualizza la transazione",
  WRAP: "Avvolgere",
  YOUR_ADDRESS: "Il tuo indirizzo",
  YOUR_ORDER_CANCEL: "Il tuo ordine è cancellato",
  YOUR_WALLET: "Il tuo portafoglio",
  YOU_GET: "Ottieni",
  YOU_PAY: "Tu paghi",
  YOU_RECEIVE: "Ricevi",
  YOU_WILL_RECEIVE: "Riceverai",
  ANYONE_CAN_CREATE:
    "Chiunque può creare qualsiasi token, comprese le versioni false dei token esistenti. Prenditi cura. Alcuni token e i loro parametri tecnici possono essere incompatibili con i nostri servizi. Importando questo token personalizzato, riconosci e accetti i rischi.",
  BY_SIGNING_MESSAGE:
    "Firmando questo messaggio, confermi che non si accede a questa app o sei residente negli Stati Uniti o in qualsiasi altro paese limitato. Per ulteriori informazioni, leggi il nostro",
  CONSTITUTION: "Costituzione",
  CURRENTLY_CONNECTED: "Sei attualmente connesso a",
  IMPORT_TOKEN: "Importare un token",
  IN_YOUR_WALLET: "nel tuo portafoglio",
  MAKE_SURE_CHECKED:
    "Assicurati di aver controllato l'indirizzo token ed è corretto",
  ON: "SU",
  PEOPLE: "Persone",
  PLEASE_SWITCH: "per favore passa a",
  SELECTED_NOT_SUPPORTED:
    "Si prega di modificare la rete nel tuo portafoglio, la rete selezionata non è supportata",
  SIGN_FOR_ACCESS: "Firma per l'accesso",
  SIGN_MESSAGE: "Messaggio di firma",
  LAUNCH_APP: "AVPIA APP",
  GETTING_STARTED: "Iniziare con HODL: tutorial e spiegatori di prodotti",
  DEEP_DIVE: "Deepdive in Hodl: strategie e caratteristiche del prodotto",
  BUILD_HODL: "Basandosi su Hodl: documentazione tecnica",
  BLOG_HEADING:
    "Demistificante Defi: tutto ciò che devi sapere in un unico posto. Per i principianti agli utenti avanzati e tutti in mezzo.",
  BLOG_PLACEHOLDER: "Cerca il prodotto/argomento di cui vuoi imparare",
  CHOOSE_TOPIC: "Scegli la rete",
  TRY: "TENTATIVO",
  SEARCH_RESULTS: "risultati di ricerca",
  SEARCH_TOPIC: "Argomento di ricerca",
  LOAD_MORE: "Carica di più",
  SOCIAL_MEDIA: "socialMedia",
  NEW: "Prima i più nuovi",
  OLD: "Prima i più vecchi",
  Z_A: "Ordina il titolo Z-A",
  A_Z: "Ordina il titolo A-Z",
  RESULTS: "Risultati",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Una tassa di gas di base viene calcolata automaticamente dalla rete e varia da un blocco a blocco. Imposta l'importo massimo della commissione di base che si desidera pagare. Una differenza tra la tassa di base massima impostata e la tassa di base effettiva verrà rimborsata una volta che il blocco è stato estratto.",
  CANCELLED_TRANSACTION: "Annullata la tua transazione",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "La creazione di un ordine limite è un'operazione gratuita per il produttore degli ordini a meno che non si annulli. L'ordine sarà tenuto a pagare le commissioni di transazione mentre riempiono l'ordine.",
  Cancelled: "Annullato",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Differenza di valore tra il percorso migliore e",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Abilita questa opzione se si hanno problemi scambiando un token che ha commissioni integrate per il trasferimento.",
  Expired: "Scaduto",
  Filled: "Riempito",
  INSUFFICIENT_FOR_GASFEE: "Scusate, fondi insufficienti per la tassa di gas",
  Open: "Aprire",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Il riempimento parziale si applica solo se un percorso di swap è costituito da più parti principali di percorso. Consente l'esecuzione parziale, impedendo che la transazione venga invertita se il prezzo di mercato si sposta in fallimento.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Prezzo impostato dal produttore degli ordini per eseguire il loro ordine (token per ricevere = X token per pagare)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Prezzo impostato dal produttore degli ordini per eseguire il loro ordine (token per pagare = y token per ricevere",
  REVIEW_LIMIT_ORDER: "Ordine di limite di revisione",
  SEARCH_BY_TOKEN: "Cerca in token o indirizzo",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "L'impostazione di una tolleranza elevata di slittamento può aiutare le transazioni ad avere successo, ma potresti non ottenere un prezzo così buono. Usare con cautela.",
  STATUS_OF_THE_LIMIT_ORDER: "Stato dell'ordine limite",
  Suspended: "Sospeso",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Queste commissioni sono pagate ai minatori di rete. Hodl non controlla le commissioni.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "La differenza tra il prezzo di mercato e il prezzo est. A causa della dimensione del commercio",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "L'importo minimo ricevuto, altrimenti la transazione verrà ripristinata",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Questa commissione va direttamente ai minatori come incentivo a dare la priorità alla transazione.",
  TRANSACTION_REJECTED_TRY: "Transazione rifiutata, riprova",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Il trasferimento per il token selezionato non è disponibile su questa rete",
  YOU: "Voi",
};
