var router = {
    1: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    56: "0xb08Aa271d20D7CfB8bAb347A66d7fb14a2FD83b5",
    137: "0x40C73021b600B17a1a29ce3A4b1ee9eDAEc6642F",
    43114: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    250: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    42161: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    10: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    128: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57",
    8453: "0xDEF171Fe48CF0115B1d80b88dc8eAB59176FEe57"
}
export default router;