var coin = {
    1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    10: "0x4200000000000000000000000000000000000042",
    56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    128: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
    137: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    250: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    8453: "0x4200000000000000000000000000000000000006",
    43114: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    42161: "0x912CE59144191C1204E64559FE8253a0e49E6548"
}
export default coin;