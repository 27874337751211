import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { getblogbylink } from "../Api/BlogActions"
import moment from 'moment';
import config from "../config/config";
import { useSelector } from "react-redux";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function BlogDetail(props) {
  const params = useParams()
  const imageUrl = config.imageUrl;
  const [blogdata, setBlogdata] = useState({});
  const [similar, setsimilar] = useState({});
  const [recent, setrecent] = useState({});
  const [category, setcategory] = useState({})
  const langcode = useSelector((state) => state.lang);
  async function getBlogbylink() {
    var data = {
      link: params.link,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    var { result, similararticles, recentarticles, blogcategory } = await getblogbylink(data);
    console.log(recentarticles, 'result')
    setBlogdata(result)
    setsimilar(similararticles)
    setrecent(recentarticles)
    setcategory(blogcategory)
  }
  useEffect(() => {

    getBlogbylink()


  }, [langcode.lancode]);
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="blog_header blog_detail_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto text-center">
              <div className="blog_detail_typeflex">
                <div className={`${blogdata.leveltype == 'AdvancedUsers' ? "blog_type blog_type_advance" : blogdata.leveltype == 'TechnicalUsers' ? "blog_type blog_type_tech" : "blog_type blog_type_begin"}`}>
                  <i className="bi bi-circle-fill"></i>
                  <span>{"For " + blogdata.leveltype}</span>
                </div>
                <ul className="blog_topic_list">
                  <li>
                    <a href="#">{blogdata.categoryname}</a>
                  </li>
                </ul>
              </div>
              <h1>{blogdata.title}</h1>
              <h5>{blogdata.description}</h5>
              <small>{moment(blogdata.createdAt).format('D MMM,YYYY')}</small>
              <img
                src={`${imageUrl}/blog/${blogdata.bannerlogo}`}
                alt="Blog"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog_detail_content">
                  <div dangerouslySetInnerHTML={{ "__html": blogdata && blogdata.content ? blogdata.content : <p></p> }} />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog_detail_card p-0">
                  <h3 className="blog_detail_card_title">Recent Articles</h3>
                  <ul className="blog_detail_card_list">

                    {recent &&
                      recent.length > 0 &&
                      recent.map((item, key) => {


                        return (
                          <li>
                            <a href={'/blog-detail/' + item.link}>{item.title}</a>
                          </li>
                        );
                      })}
                    {/* <li>
                      <a href="#">
                        Basics Of Cryptocurrency Trading Strategies
                      </a>
                    </li>
                    <li>
                      <a href="#">What Is Dollar Cost Averaging?</a>
                    </li>
                    <li>
                      <a href="#">
                        Basics Of Cryptocurrency Trading Strategies
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        What Is Asset Allocation And Diversification?
                      </a>
                    </li>
                    <li>
                      <a href="#">How To Provide Liquidity For USDC On HODL?</a>
                    </li>
                    <li>
                      <a href="#">It's Here: MISO v2</a>
                    </li>
                    <li>
                      <a href="#">
                        Why Is The Borrowing Percentage Always Higher Than The
                        Supply Percentage?
                      </a>
                    </li> */}
                  </ul>
                </div>

                <div className="blog_detail_card p-0">
                  <h3 className="blog_detail_card_title">Topics</h3>
                  <ul className="blog_detail_card_list">

                    {category &&
                      category.length > 0 &&
                      category.map((item, key) => {

                        return (

                          <li>

                            <a href={'/blog-category/' + item.categoryname}>{item.categoryname}</a>
                          </li>
                        );
                      })}
                    {/* <li>
                      <a href="#">Trident</a>
                    </li>
                    <li>
                      <a href="#">Furo</a>
                    </li>
                    <li>
                      <a href="#">HODLSwap</a>
                    </li>
                    <li>
                      <a href="#">Onsen</a>
                    </li>
                    <li>
                      <a href="#">Kashi</a>
                    </li>
                    <li>
                      <a href="#">Miso</a>
                    </li>
                    <li>
                      <a href="#">BentoBox</a>
                    </li>
                    <li>
                      <a href="#">DeFi</a>
                    </li>
                    <li>
                      <a href="#">Vesting</a>
                    </li>                    
                    <li>
                      <a href="#">AMM</a>
                    </li>
                    <li>
                      <a href="#">Streaming</a>
                    </li>
                    <li>
                      <a href="#">Walkthrough</a>
                    </li>
                    <li>
                      <a href="#">Lending</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="main_title">Similar Articles</h2>
              </div>
            </div>
            <div className="blog_wrap">
              {similar &&
                similar.length > 0 &&
                similar.map((item, key) => {


                  return (
                    <a href={'/blog-detail/' + item.link}>
                      <div className="blog_single">
                        <div className="blog_img">
                          <img
                            src={`${imageUrl}/blog/${item.bannerlogo}`}
                            alt="Blog"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blog_content">
                          <div className={`${item.leveltype == 'AdvancedUsers' ? "blog_type blog_type_advance" : item.leveltype == 'TechnicalUsers' ? "blog_type blog_type_tech" : "blog_type blog_type_begin"}`}>
                            <i className="bi bi-circle-fill"></i>
                            <span>{"For " + item.leveltype}</span>
                          </div>
                          <ul className="blog_topic_list">
                            <li>
                              <a href="#">{item.categoryname}</a>
                            </li>
                          </ul>
                          <h2>{item.title}</h2>
                          <p>{item.description} </p>
                          <p className="blog_date">{moment(item.createdAt).format('D MMM,YYYY')}</p>
                        </div>
                      </div>
                    </a>
                  );
                })}
              {/* <a href="/blog-details">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_01.png")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_begin">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Beginners</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">Trident</a>
                      </li>
                    </ul>
                    <h2>Basics Of Risk Management</h2>
                    <p>Learn about risk management and how to mitigate </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_02.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_tech">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Technical Users</span>
                    </div>
                    <h2>Basics Of Cryptocurrency Trading Strategies</h2>
                    <p>
                      Learn about various trading strategies when trading
                      cryptocurrency{" "}
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_03.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">DeFi</a>
                      </li>
                    </ul>
                    <h2>What Does Yield Farming Mean In DeFi?</h2>
                    <p>Learn more about yield farming</p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_04.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">Trident</a>
                      </li>
                    </ul>
                    <h2>HODL's Concentrated Liquidity (v3) Pool Walkthrough</h2>
                    <p>
                      Learn how to open, manage & close a concentrated liquidity
                      position
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a> */}
            </div>

          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
