import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTokenList } from "../Api/TokenActions";
import config from "../config/config";
import { setSourceCount } from "../reducers/Actions";
import $ from "jquery";
import { useTranslation } from "react-i18next";

export default function DexListModal(props) {
  var { getSource } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setlist] = useState([]);
  const [alllist, setalllist] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [searchtxt, setsearchtxt] = useState("");

  useEffect(() => {
    dexData();
    //eslint-disable-next-line
  }, [props.dexList]);

  function dexData() {
    var isList = [];
    if (props.dexList && props.dexList.length > 0) {
      for (var d = 0; d < props.dexList.length; d++) {
        var getDisable = localStorage.getItem("disId");
        var checked = true;
        if (
          getDisable &&
          getDisable !== "" &&
          getDisable !== "null" &&
          getDisable !== null
        ) {
          var list = getDisable.split(",");
          if (list && list.length > 0) {
            let index = list.findIndex((val) => val == props.dexList[d].name);
            if (index !== -1) {
              checked = false;
            }
          }
        }
        isList.push({
          checked: checked,
          name: props.dexList[d].name,
          logoURI: props.dexList[d].logoURI,
          dexid: props.dexList[d].dexid,
        });
      }
      var cnt = getCount(isList);
      dispatch(setSourceCount({ count: cnt }));
    }
    setisLoad(true);
    setlist(isList);
    setalllist(isList);
  }

  function getCount(listVal) {
    var count = 0;
    for (let element of listVal) if (element.checked === true) count++;
    return count;
  }

  const filterValue = async (event) => {
    var value = event.target.value;
    if (value && value !== "") {
      var filteredData = alllist.filter((data) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setlist([]);
      } else {
        setTimeout(setlist.bind(this, filteredData), 200);
      }
      setsearchtxt(value);
    } else {
      setsearchtxt("");
      setTimeout(setlist.bind(this, alllist), 200);
    }
  };

  const LiqutitySource = async (item, i) => {
    var isStatus = item.checked ? false : true;
    var newList = alllist;
    if (searchtxt && searchtxt !== "") {
      var filteredData = newList.filter((data) =>
        data.name.toLowerCase().includes(searchtxt.toLowerCase())
      );
      newList = filteredData;
    }

    newList[i].checked = isStatus;
    alllist[i].checked = isStatus;
    setisLoad(false);
    setlist(newList);
    setalllist(alllist);
    var cnt = getCount(alllist);
    dispatch(setSourceCount({ count: cnt }));

    setisLoad(true);
    setTimeout(function () {
      $("#" + item.name).prop("checked", isStatus);
    }, 20);

    var getDisable = localStorage.getItem("disId");
    console.log(getDisable, "getDisable");
    if (
      getDisable &&
      getDisable !== "" &&
      getDisable !== "null" &&
      getDisable !== null
    ) {
      let pos = getDisable.search(item.name);
      if (pos >= 0) {
        getDisable = getDisable.replace("," + item.name, "");
      }
    }
    var allId = getDisable;
    if (!isStatus) {
      allId = getDisable + "," + item.name;
    }

    localStorage.setItem("disId", allId);
  };

  function clearSource() {
    var text = "";
    for (var d = 0; d < alllist.length; d++) {
      alllist[d].checked = false;
      if (d == 0) {
        text += alllist[d].name;
      } else {
        text += "," + alllist[d].name;
      }
      $("#" + alllist[d].name).prop("checked", false);
    }
    console.log(text, "texttext");
    localStorage.setItem("disId", text);
    setlist(alllist);
    setalllist(alllist);
    dispatch(setSourceCount({ count: 0 }));
  }

  function selectAll() {
    for (var d = 0; d < alllist.length; d++) {
      alllist[d].checked = true;
      $("#" + alllist[d].name).prop("checked", true);
    }
    localStorage.setItem("disId", "");
    setlist(alllist);
    setalllist(alllist);
    dispatch(setSourceCount({ count: alllist.length }));
  }

  function CloseLiq() {
    getSource();
  }

  return (
    <div
      className="modal fade primary_modal"
      id="select_dex_modal"
      tabIndex="-1"
      aria-labelledby="select_dex_modalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="select_dex_modalLabel">
              {t("LIQUIDITY_SOURCES")}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => CloseLiq()}
            ></button>
          </div>
          <div className="modal-body">
            <input
              className="token_search_inp"
              placeholder={t("SEARCH_A_LIQUIDITY_SOURCES")}
              onChange={filterValue}
            />
            <div className="h_flx">
              <span onClick={() => clearSource()}>{t("CLEAR")}</span>
              <span onClick={() => selectAll()}>{t("SELECT_ALL")}</span>
            </div>
            {isLoad && list && list.length > 0 && (
              <div className="token_search_list">
                {isLoad &&
                  list &&
                  list.length > 0 &&
                  list.map((item, i) => {
                    var logo = config.imageUrl + "/dexsite/" + item.logoURI;

                    return (
                      <div className="roundedimg">
                        <div className="token_search_list_row" key={i}>
                          <div>
                            <img
                              src={logo}
                              alt={item.name}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = config.defaultLogo;
                              }}
                              className="me-2 img-fluid liqImg"
                              style={{ widht: "unset", height: "30px" }}
                            />
                            <span className="token_search_list_cur_value">
                              {item.name}
                            </span>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={item.name}
                              onClick={() => LiqutitySource(item, i)}
                              checked={item.checked}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            {list && list.length === 0 && (
              <div className="token_search_list emptytokens">
                <div>
                  <span className="token_search_list_cur_name">
                    {t("NO_RECORDS")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
