import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { loadblog, getblog } from "../Api/BlogActions"
import { useSelector } from "react-redux";
import config from "../config/config";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
// Scroll to Top
function ScrollToTopOnMount() {

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);
  return null;
}




export default function Blog(props) {
  const { t, i18n } = useTranslation();

  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [blogdata, setBlogdata] = useState([]);
  const [count, setcount] = useState(0);
  const [skip, setskip] = useState(0);
  const [category, setcategory] = useState('');
  const [search, setSearch] = useState('');
  const imageUrl = config.imageUrl;
  const langcode = useSelector((state) => state.lang);
 
  useEffect(() => {
   
     
    
    getBlogdata()


  }, [langcode.lancode]);

  const handlechange = (event) => {
    console.log(event.target.value, 'event')
    setSearch(event.target.value)
  }
  async function getBlogdata() {
    try{
      var data = {
        skip: skip,
        lancode: langcode && langcode.lancode?langcode.lancode:'en',
      }
      var { result, totalrecords, category } = await getblog(data);
      console.log(totalrecords, 'totalrecords',result)
      setBlogdata(result)
      setData(category)
      setcategory(category[0]._id)
      setcount(totalrecords)
    }
    catch(err){
      console.log(err,'eeeeeeeeeeee')
    }
  }


  async function loadmore() {

    var skiporder = skip + 4

    var data = {
      skip: skiporder,
      category: category,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
      search: search,
    }
    setskip(skiporder)
    var { result } = await loadblog(data);
    var arr = blogdata.concat(result)
    setBlogdata(arr)
  }
  async function fetchbyCategory(category) {
    var skiporder = 0

    var data = {
      skip: skiporder,
      category: category,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    setskip(skiporder)
    setcategory(category)
    var { result, totalrecords } = await loadblog(data);
    setBlogdata(result)
    setcount(totalrecords)
  }

  async function fetchbysearch() {

    var skiporder = 0

    var data = {
      skip: skiporder,
      category: category,
      search: search,
      lancode: langcode && langcode.lancode?langcode.lancode:'en',
    }
    setskip(skiporder)
    setcategory(category)
    var { result, totalrecords } = await loadblog(data);
    setBlogdata(result)
    setcount(totalrecords)
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleSearch = async (event) => {
    
    debouncedSearch(event);
   
}

const debouncedSearch = debounce(async function (event) {
  setSearch(event.target.value)
  var skiporder = 0

  var data = {
    skip: skiporder,
    category: category,
    search: event.target.value,
    lancode: langcode && langcode.lancode?langcode.lancode:'en',
  }
  setskip(skiporder)
  setcategory(category)
  var { result, totalrecords } = await loadblog(data);
  setBlogdata(result)
  setcount(totalrecords)
  ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, 500);
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="blog_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto text-center">
              <h1>{t('BLOG')}</h1>
              <h5>
              {t('BLOG_HEADING')}
              </h5>
              <div className="blog_search">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("BLOG_PLACEHOLDER")}
                    aria-label="blog"
                    aria-describedby="basic-addon2"
                    id="search"
                    onChange={handleSearch}
                  />
                  <button className="input-group-text" id="basic-addon2" onClick={() => { fetchbysearch() }}>
                    <i className="bi bi-search"></i>
                  </button>
                </div>
                <div className="blog_search_keywords">
                  <p>{t('TRY')}:</p>
                  <ul>
                    {data &&
                      data.length > 0 &&
                      data.map((item, key) => {


                        return (
                          <li>
                            <a href={'/blog-category/' + item.categoryname} className="notranslate">{item.categoryname}</a>
                          </li>
                        );
                      })}
                    {/* <li>
                      <a href="#">HodlSwap</a>
                    </li>
                    <li>
                      <a href="#">Crosschain</a>
                    </li>
                    <li>
                      <a href="#">Swap</a>
                    </li>
                    <li>
                      <a href="#">Trident</a>
                    </li>
                    <li>
                      <a href="#">Furo</a>
                    </li>
                    <li>
                      <a href="#">Bentobox</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Blog Type */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="blog_type_box">
                  <div className="blog_type blog_type_begin">
                    <i className="bi bi-circle-fill"></i>
                    <span>For Beginners</span>
                  </div>
                  <div className="blog_type_box_img">
                    <img
                      src={require("../assets/images/beginners.png")}
                      alt="Beginners"
                      className="img-fluid"
                    />
                  </div>
                  <h4>
                    {t('GETTING_STARTED')}
                  </h4>
                  <button className="primary_btn">
                    <a href="/blog-search/Beginners">{t('LEARN_MORE')}</a>
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog_type_box">
                  <div className="blog_type blog_type_advance">
                    <i className="bi bi-circle-fill"></i>
                    <span>For Advanced Users</span>
                  </div>
                  <div className="blog_type_box_img">
                    <img
                      src={require("../assets/images/advanced.png")}
                      alt="Advanced"
                      className="img-fluid"
                    />
                  </div>
                  <h4>{t('DEEP_DIVE')}</h4>
                  <button className="primary_btn">
                    <a href="/blog-search/AdvancedUsers">{t('LEARN_MORE')}</a>
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="blog_type_box">
                  <div className="blog_type blog_type_tech">
                    <i className="bi bi-circle-fill"></i>
                    <span>For Technical Users</span>
                  </div>
                  <div className="blog_type_box_img">
                    <img
                      src={require("../assets/images/technical.png")}
                      alt="Technical"
                      className="img-fluid"
                    />
                  </div>
                  <h4>{t("BUILD_HODL")}</h4>
                  <button className="primary_btn">
                    <a href="/blog-search/TechnicalUsers">{t('LEARN_MORE')}</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={ref}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="main_title">{t('CHOOSE_TOPIC')}</h2>
                <ul className="blog_topic_list">


                  {data &&
                    data.length > 0 &&
                    data.map((item, key) => {


                      return (

                        <li>

                          <a className={item._id == category ? "addcolor" : ''} onClick={() => { fetchbyCategory(item._id) }} >{item.categoryname}</a>
                        </li>
                      );
                    })}
                  {/* <li>
                    <a href="#">Trident</a>
                  </li>
                  <li>
                    <a href="#">Furo</a>
                  </li>
                  <li>
                    <a href="#">HODLSwap</a>
                  </li>
                  <li>
                    <a href="#">Onsen</a>
                  </li>
                  <li>
                    <a href="#">Kashi</a>
                  </li>
                  <li>
                    <a href="#">Miso</a>
                  </li>
                  <li>
                    <a href="#">BentoBox</a>
                  </li>
                  <li>
                    <a href="#">DeFi</a>
                  </li>
                  <li>
                    <a href="#">Vesting</a>
                  </li>
                  <li>
                    <a href="#">HODL</a>
                  </li>
                  <li>
                    <a href="#">AMM</a>
                  </li>
                  <li>
                    <a href="#">Streaming</a>
                  </li>
                  <li>
                    <a href="#">Walkthrough</a>
                  </li>
                  <li>
                    <a href="#">Lending</a>
                  </li>
                  <li>
                    <a href="#">Margin Trading</a>
                  </li>
                  <li>
                    <a href="#">Crosschain</a>
                  </li>
                  <li>
                    <a href="#">Launchpad</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="blog_wrap">
{console.log(blogdata,'blogdata')}
              {blogdata &&
                blogdata.length > 0 ?
                blogdata.map((item, key) => {


                  return (
                    <a href={'/blog-detail/' + item.link} className="notranslate">
                      <div className="blog_single">
                        <div className="blog_img">
                          <img
                            src={`${imageUrl}/blog/${item.bannerlogo}`}
                            alt="Blog"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blog_content">
                          <div className={`${item.leveltype == 'AdvancedUsers' ? "blog_type blog_type_advance" : item.leveltype == 'TechnicalUsers' ? "blog_type blog_type_tech" : "blog_type blog_type_begin"}`}>
                            <i className="bi bi-circle-fill"></i>
                            <span>{"For " + item.leveltype}</span>
                          </div>
                          <ul className="blog_topic_list">
                            <li>
                              <a href="#">{item.categoryname}</a>
                            </li>
                          </ul>
                          <h2>{item.title}</h2>
                          <p>{item.description} </p>
                          <p className="blog_date">{moment(item.createdAt).format('D MMM,YYYY')}</p>
                        </div>
                      </div>
                    </a>
                  );
                }):<p>{t('NO_RECORDS')}</p>}

              {/* <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_02.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_tech">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Technical Users</span>
                    </div>
                    <h2>Basics Of Cryptocurrency Trading Strategies</h2>
                    <p>
                      Learn about various trading strategies when trading
                      cryptocurrency{" "}
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_03.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">DeFi</a>
                      </li>
                    </ul>
                    <h2>What Does Yield Farming Mean In DeFi?</h2>
                    <p>
                      Learn more about yield farming
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_04.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">Trident</a>
                      </li>
                    </ul>
                    <h2>HODL's Concentrated Liquidity (v3) Pool Walkthrough</h2>
                    <p>
                      Learn how to open, manage & close a concentrated liquidity position
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a> */}

              {/* <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_01.png")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_begin">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Beginners</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">Trident</a>
                      </li>
                    </ul>
                    <h2>Basics Of Risk Management</h2>
                    <p>Learn about risk management and how to mitigate </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_02.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_tech">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Technical Users</span>
                    </div>
                    <h2>Basics Of Cryptocurrency Trading Strategies</h2>
                    <p>
                      Learn about various trading strategies when trading
                      cryptocurrency{" "}
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_03.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">DeFi</a>
                      </li>
                    </ul>
                    <h2>What Does Yield Farming Mean In DeFi?</h2>
                    <p>
                      Learn more about yield farming
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>

              <a href="#">
                <div className="blog_single">
                  <div className="blog_img">
                    <img
                      src={require("../assets/images/blog_04.jpg")}
                      alt="Blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="blog_content">
                    <div className="blog_type blog_type_advance">
                      <i className="bi bi-circle-fill"></i>
                      <span>For Advanced Users</span>
                    </div>
                    <ul className="blog_topic_list">
                      <li>
                        <a href="#">Trident</a>
                      </li>
                    </ul>
                    <h2>HODL's Concentrated Liquidity (v3) Pool Walkthrough</h2>
                    <p>
                      Learn how to open, manage & close a concentrated liquidity position
                    </p>
                    <p className="blog_date">11 May, 2023</p>
                  </div>
                </div>
              </a>               */}
            </div>
            {count > blogdata.length &&
              <div className="text-center mt-5">
                <button className="primary_btn" onClick={() => loadmore()}>{t("LOAD_MORE")}</button>
              </div>
            }



          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
