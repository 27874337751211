import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { getCmsbylink } from "../Api/BlogActions"
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import config from "../config/config";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CMSDetail(props) {
  const params = useParams()
  const imageUrl = config.imageUrl;
  const [blogdata, setBlogdata] = useState('');
  const [similar, setsimilar] = useState({});
  const [recent, setrecent] = useState({});
  const [category, setcategory] = useState({})
  const langcode = useSelector((state) => state.lang);
  async function getcmsbylink() {
    var data = {
      link: params.link,
      lancode: langcode && langcode.lancode ? langcode.lancode : 'en',
    }
    var { result, status } = await getCmsbylink(data);
    console.log(status, 'status', result)
    if (status == true) {
      setBlogdata(result)
    }

    if (!status) {
      setBlogdata('No Data')
    }

  }
  useEffect(() => {

    getcmsbylink()


  }, [langcode.lancode]);
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      {blogdata && blogdata.title &&
        <div className="blog_header blog_detail_header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto text-center">

                <h1>{blogdata.title}</h1>

              </div>
            </div>
          </div>
        </div>
      }
      <div className="main_wrapper">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div dangerouslySetInnerHTML={{ "__html": blogdata && blogdata.content ? blogdata.content : "No Data" }} />
                </div>
              </div>

            </div>
          </div>
        </section>


      </div>
      <Footer />
    </div>
  );
}
