// import constant
import {
    PAIRTOKEN
} from '../constant';

const initialState = {
    address: "",
    decimal: 0,
    symbol: "",
    logoURI: "",
    name: ""
};

const pairToken = (state = initialState, action) => {

    switch (action.type) {
        case PAIRTOKEN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default pairToken;