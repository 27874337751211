export default {
  ABOUT_NEW_FEATURES: "关于新功能。",
  ACCEPT: "接受",
  ACTIVE_ORDERS: "主动订单",
  ADD_TOKEN: "添加一个令牌",
  AGGRESSIVE: "挑衅的",
  AND: "和",
  APPROVE: "批准",
  ASSETS_ON_WAY: "您的资产正在途中，应该到达",
  AUTO: "汽车",
  AVOID_TRANSFER:
    "请避免转移到集中的交换地址（Binance，Huobi ...）。这不是一个支持的功能",
  BALANCE: "平衡",
  BALANCE_ONE: "平衡：",
  BLOG: "OTC",
  BRIDGE: "桥",
  CANCEL: "取消",
  CANCELLATION_TRANSACTION_FEE: "取消限额订单涉及支付交易费用。",
  CANCEL_ALL: "取消全部",
  CANCEL_LIMIT_NOT_FILLED: "只要订单尚未填写，您就可以取消限额订单。",
  CANCEL_LIMIT_ORDER: "取消限制订单",
  CANCEL_ORDER: "取消订单",
  CANCEL_SELECTED: "取消选择",
  CHAINS: "博客",
  CHOOSE_NETWORK: "选择网络",
  CLASSIC_MODE: "经典模式",
  CLEAR: "清除",
  CONFIRM: "确认",
  CONFIRMATION_REJECTED: "确认拒绝了。",
  CONFIRM_SWAP: "确认交换",
  CONNECT_WALLET: "连接钱包",
  COPIED: "复制",
  CURRENT: "当前的",
  CUSTOM: "风俗",
  DAYS: "天",
  DEFAULT: "默认",
  DETAILS: "细节",
  DISCLAIMER: "免责声明",
  DISCONNECT_WALLET: "断开钱包",
  DISMISS: "解雇",
  DUPLICATE_ORDER_SUBMIT: "重复订单。这个已经提交了",
  ENTER_AMT: "输入金额",
  EXCHANGE: "交换",
  EXPIRY: "到期",
  EXPIRY_SM: "到期",
  FAILED_LOAD_CHART: "无法加载图表",
  FIND_BY_TOKEN_OR_STATUS: "通过令牌或状态查找",
  INSUFFICIENT_BALANCE: "平衡不足",
  INSUFFICIENT: "不足的",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF: "平衡以弥补现有订单的余额",
  RECEIVERS: "接收器的",
  WALETADDRESS: "钱包地址",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY: "请接受服务条款和隐私政策",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "制造商接受者不应是同一个接收者地址",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN: "滑板公差应在0.1％至15％之间",
  SEARCH_A_TOKEN: "搜索令牌",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "如果价格通过比这个百分比的更多，您的交易将恢复",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "调整交易的气价。较高的GWEI =更高的速度。",
  REVIEW_OTC_ORDER: "查看OTC订单",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "订单制造商设定的到期期。在此期间，该订单将过期并存档。",
  SEARCH_A_LIQUIDITY_SOURCES: "搜索流动性来源",

  FAST: "快速地",
  FAST_AND_SECURE: "安全，快速和最安全的方式将跨链资产带到",
  FEATURE_TO_TRANSFER: "请不要使用此功能将您的令牌转移到集中交易所的地址。",
  FEES: "费用",
  FILL_ORDER: "填写订单",
  FILL_OTC_ORDER: "填写OTC订单",
  FREE: "自由的",
  FROM: "从",
  GAS_COST: "汽油成本",
  GAS_DESTINATION: "目的地链上的气体",
  GAS_ON_DESTINATION: "目的地的汽油",
  GAS_PRICE: "汽油价格",
  GOT_IT: "知道了",
  GOT_PROBLEM_JUST: "有问题吗？只是取得联系。",
  HIGH: "高的",
  HOURS: "小时",
  IMPORT: "进口",
  IMPOSSIBLE_TO_RETRIEVE:
    "这不是支持的功能。另外，请仔细检查您填充的地址，任何发送到错误地址的令牌将无法检索。",
  INSTANT: "立即的",
  INSUFFICIENT_APPROVED_AMT: "批准金额不足",
  INVALID_TOKEN_ADDRESS: "无效的令牌地址",
  KEEP_TRACK_NOTIFY: "您可以在面板通知中跟踪它。完成后，我们将通知您。",
  KEEP_YOU_POSTED: "。我们会及时通知您。",
  LEARN_MORE: "了解更多",
  LEGACY: "遗产",
  LIMIT: "限制",
  LIMIT_ORDER: "限制顺序",
  LIMIT_PRICE: "限制价格",
  LIMIT_PRICE_SM: "限制价格",
  LIQUIDITY_SOURCES: "流动性来源",
  LOW: "低的",
  MAKER: "制作者",
  MARKET: "市场",
  MAX: "最大限度",
  MAX_BASE_FEE: "最大基本费用",
  MAX_S: "最大限度",
  MAX_TO_PAY: "最大付款",
  MINIMUM_RECEIVED: "最低收到",
  MINUTES: "分钟",
  MONTHS: "月份",
  NEED_APPROVAL_MOVE: "我们需要您的认可才能移动",
  NEED_TO_WRAP: "你需要包装",
  NETWORK_FEES: "网络费",
  NETWORK_SM: "网络。",
  NEVER: "绝不",
  NONE: "没有任何",
  NON_CUSTODIAL_LIMIT: "在非监测限制顺序中。这是关于",
  NORMAL: "普通的",
  NO_ORDERS_YET: "你还没有订单",
  NO_RECORDS: "没有记录",
  ON_UR_BEHALF: "代表你。",
  OOPS_WRONG: "哎呀！出事了。！",
  OPEN: "打开",
  ORDER_DETAILS: "订单详细信息",
  ORDER_HISTORY: "订单历史",
  ORDER_PRICE: "订单价格",
  ORDER_ROUTING: "订单路由",
  OTC: "OTC",
  PASTE: "粘贴",
  PAY: "支付",
  PENDING_CONFIRM: "等待钱包的确认。",
  PER: "每",
  PLEASE_CHOOSE_NETWORK: "请选择网络",
  PLEASE_LOGIN: "请登录",
  PLEASE_TRY_LATER: "请稍后再试",
  POPULAR_TOKENS: "受欢迎的令牌",
  PRICE: "价格",
  PRICE_IMPACT: "价格影响",
  PRIVACY_POLICY: "隐私政策",
  RATE: "速度",
  READ_ACCEPT: "我阅读并接受",
  RECEIVE: "收到",
  RECEIVED_ORDERS: "收到订单",
  REFUND: "退款",
  REMOVE: "消除",
  REVERSE_RATE: "反向速率",
  REVIEW_TRADE: "审查贸易",
  SELECTED: "选定",
  SELECT_ALL: "全选",
  SELECT_FROM_INFO: "请从信息中选择",
  SELECT_NETWORK: "选择一个网络",
  SELECT_TOKEN: "选择一个令牌",
  SELECT_TOKENN: "选择令牌",
  SELECT_TO_INFO: "请选择信息",
  SETTINGS: "设置",
  SIMPLE_MODE: "简单模式",
  SLIPPAGE: "滑移",
  SLIPPAGE_TOLERANCE: "滑板公差",
  SRY_INSUFFICIENT: "抱歉，不足",
  STATUS: "地位",
  STATUS_SM: "地位",
  STEP: "步",
  SUBMIT: "提交",
  SUBMITTED_ORDERS: "提交订单",
  SUBMIT_DATE: "提交日期",
  SUCCESS_FILLED: "成功订购",
  SUCCESS_RECEIVED: "成功收到",
  SUCCESS_SUBMITTED_ORDER: "成功提交了您的订单",
  SWAP: "交换",
  SWAP_TRANSFER: "交换和转移",
  SWITCH_NETWORK: "开关网络",
  TAKER: "接受者",
  TAKER_ADDRESS: "接管者地址",
  TERMS_OF_SERVICE: "服务条款",
  TO: "到",
  TOKEN_NOT_FOUND: "找不到令牌",
  TOKEN_RECEIVE_SENT: "要接收的令牌将发送到",
  TRADE: "贸易",
  TRANSACTION_REJECTED_LATER: "交易被拒绝，请稍后再试",
  TRANSFER: "转移",
  TRANSFER_ALERT: "传输警报",
  TRANSFER_TO: "传送到",
  TX_COST: "TX成本",
  UNLOCK: "开锁",
  USER_GUIDE: "用户指南",
  USE_ITAS: "并将其用作W",
  VERIFY: "核实",
  VIEW_EXPLORER: "在资源管理器中查看交易",
  VIEW_ON: "查看",
  VIEW_TRANSACTION: "查看交易",
  WRAP: "裹",
  YOUR_ADDRESS: "你的地址",
  YOUR_ORDER_CANCEL: "您的订单已取消",
  YOUR_WALLET: "你的钱包",
  YOU_GET: "你得到",
  YOU_PAY: "你付钱",
  YOU_RECEIVE: "你收到",
  YOU_WILL_RECEIVE: "您将收到",
  ANYONE_CAN_CREATE:
    "任何人都可以创建任何令牌，包括现有令牌的伪造版本。要注意。某些令牌及其技术参数可能与我们的服务不相容。通过导入此自定义令牌，您可以承认并接受风险。",
  BY_SIGNING_MESSAGE:
    "通过签署此消息，您确认您未从中访问此应用，或者是美国或任何其他受限制国家的居民。有关更多信息，请阅读我们的",
  CONSTITUTION: "宪法",
  CURRENTLY_CONNECTED: "您目前已连接到",
  IMPORT_TOKEN: "导入令牌",
  IN_YOUR_WALLET: "在你的钱包里",
  MAKE_SURE_CHECKED: "确保您已经检查了令牌地址，这是正确的",
  ON: "在",
  PEOPLE: "人们",
  PLEASE_SWITCH: "请切换",
  SELECTED_NOT_SUPPORTED: "请更改钱包中的网络，不支持选定的网络",
  SIGN_FOR_ACCESS: "登录访问权限",
  SIGN_MESSAGE: "标志消息",
  LAUNCH_APP: "启动应用程序",
  GETTING_STARTED: "HODL的开始：教程和产品解释器",
  DEEP_DIVE: "深入研究HODL：策略和产品功能",
  BUILD_HODL: "建立HODL：技术文档",
  BLOG_HEADING:
    "揭开defi的神秘面纱 - 您需要在一个地方知道的一切。对于初学者，高级用户以及介于两者之间的每个人。",
  BLOG_PLACEHOLDER: "搜索您要学习的产品/主题",
  CHOOSE_TOPIC: "选择主题",
  TRY: "尝试",
  SEARCH_RESULTS: "搜索结果",
  SEARCH_TOPIC: "搜索主题",
  LOAD_MORE: "装载更多",
  SOCIAL_MEDIA: "社交媒体",
  NEW: "新的先来",
  OLD: "最古老的第一",
  Z_A: "排序标题z-a",
  A_Z: "排序标题A-Z",
  RESULTS: "结果",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "基本气费自动由网络计算，并且因块而异。设置您要支付的最高基本费用。一旦块开采，您设定的最高基本费用与实际基本费用之间的差额将退还。",
  CANCELLED_TRANSACTION: "取消了您的交易",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "除非取消，否则创建限制订单是订单制造商的免费操作。接收订单的人在填写订单时必须支付交易费用。",
  Cancelled: "取消",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "最佳路线之间的价值差异和",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "如果您遇到交换具有内置费用的代币，请启用此选项。",
  Expired: "已到期",
  Filled: "填充",
  INSUFFICIENT_FOR_GASFEE: "抱歉，费用不足的资金",
  Open: "打开",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "部分填充仅当交换路线由多个主要路由零件组成时，才适用。它允许部分执行，如果市场价格不受欢迎，则可以防止交易逆转。",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "订单制造商设定的价格用于执行订单（代币接收= x代币以付款）",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "订单制造商设定的代价用于执行订单（代币付费= y代币以接收",
  REVIEW_LIMIT_ORDER: "审查限制顺序",
  SEARCH_BY_TOKEN: "通过令牌或地址搜索",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "设定高滑倒公差可以帮助交易成功，但您可能不会获得如此好的价格。谨慎使用。",
  STATUS_OF_THE_LIMIT_ORDER: "限制顺序的状态",
  Suspended: "暂停",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "这些费用是向网络矿工支付的。 Hodl不能控制费用。",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "由于贸易规模而导致的市场价格和估计价格之间的差额",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "收到的最低金额，否则交易将被恢复",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "这笔费用直接捐给矿工，以便将您的交易确定优先级。",
  TRANSACTION_REJECTED_TRY: "交易被拒绝，请重试",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE: "该网络上没有选定令牌的传输",
  YOU: "你",
};
