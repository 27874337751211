// import constant
import {
    TRANSFERSTATUS
} from '../constant';

const initialState = {
    showApprove: false,
    showTransfer: false
};

const transferStatus = (state = initialState, action) => {


    switch (action.type) {
        case TRANSFERSTATUS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default transferStatus;