import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Swapsection from "../../components/Swapsection";
import ChartPage from "../chartLimitClassic/chartPageClassic";

import { GetTokenList, GetDexSites } from "../../Api/TokenActions";
import config from "../../config/config";
import { setIsMarket, setIsRoute } from "../../reducers/Actions";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { getTokenLogo } from "../../helper/networkdata";
import { useTranslation } from "react-i18next";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function SwapClassic(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [marketsList, setmarketsList] = useState([]);
  const [swapInfo, setswapInfo] = useState({});
  const [bestRoute, setbestRoute] = useState([]);
  const [tokenList, settokenList] = useState([]);
  const [dexList, setdexList] = useState([]);
  const [multiSwaps, setmultiSwaps] = useState("");

  const swapToken = useSelector((state) => state.swapToken);
  const walletConnection = useSelector((state) => state.walletConnection);
  const isMarket = useSelector((state) => state.isMarket);
  const isRoute = useSelector((state) => state.isRoute);

  useEffect(() => {
    // eslint-disable-next-line
  }, [swapToken]);

  async function bestpath(routes) {
    let chainId =
      walletConnection && walletConnection.network
        ? walletConnection.network
        : 56;
    var data = {
      chainId: chainId,
      useraddress: walletConnection.address,
    };
    var tokens = tokenList;
    if (tokens && tokens.length == 0) {
      var { result } = await GetTokenList(data);
      tokens = JSON.parse(result);
      settokenList(tokens);
    }

    var list = [];
    var multiList = [];
    if (routes && routes.length > 0) {
      for (var p = 0; p < routes.length; p++) {
        list.push({ percentage: routes[p].percent, swaps: [] });
        if (routes && routes[p] && routes[p].swaps) {
          for (var s = 0; s < routes[p].swaps.length; s++) {
            var srcToken =
              routes &&
              routes[p] &&
              routes[p].swaps[s] &&
              routes[p].swaps[s].srcToken
                ? routes[p].swaps[s].srcToken.toLowerCase()
                : "";
            var destToken =
              routes &&
              routes[p] &&
              routes[p].swaps[s] &&
              routes[p].swaps[s].destToken
                ? routes[p].swaps[s].destToken.toLowerCase()
                : "";

            var destSymbol = "";
            var srcSymbol = "";
            var srcLogo = config.defaultLogo;
            var destLogo = config.defaultLogo;
            if (srcToken !== "") {
              var tokenPos = tokens.findIndex(
                (val) => val.address.toLowerCase() === srcToken
              );
              srcSymbol = tokenPos !== -1 ? tokens[tokenPos].symbol : "-";
              srcLogo =
                tokenPos !== -1 ? tokens[tokenPos].logoURI : config.defaultLogo;
            }
            if (destToken !== "") {
              var tokenPos1 = tokens.findIndex(
                (val) => val.address.toLowerCase() === destToken
              );
              destSymbol = tokenPos1 !== -1 ? tokens[tokenPos1].symbol : "-";
              destLogo =
                tokenPos1 !== -1
                  ? tokens[tokenPos1].logoURI
                  : config.defaultLogo;
            }
            list[p].swaps.push({
              srcToken: srcToken,
              srcSymbol: srcSymbol,
              srcLogo: srcLogo,
              destToken: destToken,
              destSymbol: destSymbol,
              destLogo: destLogo,
              text: "",
            });
            var text = "";

            if (
              routes[p].swaps &&
              routes[p].swaps[s] &&
              routes[p].swaps[s].swapExchanges
            ) {
              for (
                var e = 0;
                e < routes[p].swaps[s].swapExchanges.length;
                e++
              ) {
                var exchangeName = routes[p].swaps[s].swapExchanges[e].exchange;
                var percent = routes[p].swaps[s].swapExchanges[e].percent;
                var comma = e > 0 ? ", " : "";
                text += comma + exchangeName + ":" + percent + "%";

                let index = multiList.findIndex((val) => val == exchangeName);
                if (index == -1) {
                  multiList.push(exchangeName);
                }
              }
              list[p].swaps[s].text = text;
            }
          }
        }
      }
    }

    dispatch(setIsRoute({ status: true }));
    setbestRoute(list);

    return multiList;
  }

  async function getMarkets(list, info, routes) {
    if (info && info.srcAmount && parseFloat(info.srcAmount) > 0) {
      var multiList = await bestpath(routes);
      var newList = [];

      var fromdecimal = parseFloat(info.fromdecimal);
      var todecimal = parseFloat(info.todecimal);
      var srcAmount = parseFloat(info.srcAmount) / 10 ** fromdecimal;
      var destAmount = parseFloat(info.destAmount) / 10 ** todecimal;

      var exchangename =
        routes &&
        routes[0] &&
        routes[0].swaps &&
        routes[0].swaps[0].swapExchanges &&
        routes[0].swaps[0].swapExchanges[0] &&
        routes[0].swaps[0].swapExchanges[0].exchange
          ? routes[0].swaps[0].swapExchanges[0].exchange
          : "";

      if (
        info.contractMethod === "megaSwap" ||
        info.contractMethod === "multiSwap"
      ) {
        exchangename =
          info.contractMethod === "megaSwap"
            ? "MegaSwap"
            : info.contractMethod === "multiSwap"
            ? "MultiSwap"
            : exchangename;
        let p = destAmount / srcAmount;

        var exchageTxt = "";
        for (var e = 0; e < multiList.length; e++) {
          exchageTxt += multiList[e];
          if (multiList.length - 1 != e) {
            exchageTxt += "\n";
          }
        }
        setmultiSwaps(exchageTxt);

        var obj = {
          destAmount: parseFloat(destAmount),
          unit: p,
          data: { gasUSD: info.gasUSD },
          exchange: exchangename,
          gasUSD: info.gasUSD,
          price: p,
        };
        newList.push(obj);
      } else {
        let p = destAmount / srcAmount;
        let obj1 = {
          destAmount: parseFloat(destAmount),
          unit: p,
          data: { gasUSD: info.gasUSD },
          gasUSD: info.gasUSD,
          exchange: exchangename,
          price: p,
        };
        newList.push(obj1);
      }
      var v2 = destAmount;

      for (var m = 0; m < list.length; m++) {
        var sAmount =
          parseFloat(list[m].srcAmount) / 10 ** parseFloat(info.fromdecimal);
        var dAmount =
          parseFloat(list[m].destAmount) / 10 ** parseFloat(info.todecimal);

        var price = dAmount / sAmount;

        let gasUSD =
          list[m].data && list[m].data.gasUSD
            ? parseFloat(list[m].data.gasUSD)
            : 0;
        var percentage = 0;
        if (m > 0) {
          var v1 = dAmount;
          //percentage = 100 * Math.abs((v1 - v2) / ((v1 + v2) / 2));
          percentage = (v2 - v1) / v2;
          percentage = percentage * 100;
        }
        percentage = percentage <= 100 ? percentage.toFixed(2) : 0;
        var index = newList.findIndex(
          (val) => val.exchange == list[m].exchange
        );
        if (index == -1) {
          newList.push({
            destAmount: destAmount,
            price: price,
            gasUSD: gasUSD,
            exchange: list[m].exchange,
            percentage: percentage,
          });
        }
      }

      if (newList && newList.length > 0) {
        newList.sort((a, b) => (a.destAmount > b.destAmount ? -1 : 1));
        setmarketsList(newList);
      }

      setswapInfo(info);
      dispatch(setIsMarket({ isList: true }));

      var nw = parseInt(walletConnection.network);
      var { result } = await GetDexSites({ chainId: nw });
      result = JSON.parse(result);
      setdexList(result);
    } else {
      setmarketsList([]);
      setbestRoute([]);
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_page_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 order-lg-1 order-2">
              <div className="row">
                <div className="col-12">
                  <ChartPage />
                  <div className="mb-0">
                    <div className="routing_container_wrap">
                      <h2>{t("ORDER_ROUTING")}</h2>
                      {isRoute && !isRoute.status && (
                        <div className="text-center">
                          <i className="fas fa-spinner fa-spin"></i>
                        </div>
                      )}
                      <div className="routing_container">
                        {isRoute &&
                          isRoute.status &&
                          swapInfo.fromlogo &&
                          swapInfo.fromlogo !== "" && (
                            <div className="routing_head">
                              <div className="routing_head_inner">
                                <img
                                  src={getTokenLogo(
                                    swapInfo.fromlogo,
                                    swapInfo.fromchainId
                                  )}
                                  alt={swapInfo.fromsymbol}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = config.defaultLogo;
                                  }}
                                />
                                <span className="routing_head_inner_title">
                                  {swapInfo.fromsymbol}
                                </span>
                              </div>
                              <div className="routing_head_inner">
                                <span className="routing_head_inner_title">
                                  {swapInfo.tosymbol}
                                </span>
                                <img
                                  src={getTokenLogo(
                                    swapInfo.tologo,
                                    swapInfo.tochainId
                                  )}
                                  alt={swapInfo.tosymbol}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = config.defaultLogo;
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        {isRoute && isRoute.status && (
                          <div className="routing_arrow_container">
                            <div className="routing_arrow_div routing_arrow_left"></div>
                            <div className="routing_arrow_div routing_arrow_right"></div>
                          </div>
                        )}

                        <div className="routing_row">
                          {isRoute &&
                            isRoute.status &&
                            bestRoute &&
                            bestRoute.length > 0 &&
                            bestRoute.map((item, i) => {
                              return (
                                <div className="routing_row_single">
                                  <div className="routing_row_single_inner">
                                    <div className="routing_row_card">
                                      <span>{item.percentage}%</span>
                                    </div>
                                    {item.swaps &&
                                      item.swaps.length > 0 &&
                                      item.swaps.map((item1, i) => {
                                        return (
                                          <div className="routing_row_card routing_row_swap_card">
                                            <img
                                              src={getTokenLogo(
                                                item1.srcLogo,
                                                swapInfo.fromchainId
                                              )}
                                              alt={item1.srcSymbol}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  config.defaultLogo;
                                              }}
                                            />
                                            <span>
                                              <span className="routing_row_swap_count">
                                                {item1.srcSymbol}
                                              </span>
                                              <div className="routing_row_tooltip">
                                                {item1.text}{" "}
                                              </div>
                                            </span>
                                            <img
                                              src={getTokenLogo(
                                                item1.destLogo,
                                                swapInfo.fromchainId
                                              )}
                                              alt={item1.destSymbol}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  config.defaultLogo;
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    <div className="routing_row_card">
                                      <span>{item.percentage}%</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {isMarket && isMarket.isList ? (
                      <div className="table-responsive adjust_height">
                        <table className="table primary_table">
                          <thead>
                            <tr>
                              <th>{t("EXCHANGE")}</th>
                              <th>
                                {t("PRICE")}{" "}
                                <span>
                                  {swapInfo.fromsymbol}/{swapInfo.tosymbol}
                                </span>
                              </th>
                              <th>{t("YOU_GET")}</th>
                              <th>
                                {t("FEES")}
                                <i
                                  className="far fa-question-circle ms-1"
                                  id="fees"
                                ></i>
                              </th>
                              <ReactTooltip
                                className="custom_tooltip"
                                anchorId="fees"
                                place="left"
                                content={t(
                                  "THESE_FEES_ARE_PAID_TO_THE_NETWORK"
                                )}
                              />
                            </tr>
                          </thead>
                          <tbody>
                            {marketsList &&
                              marketsList.length > 0 &&
                              marketsList.map((item, i) => {
                                var index = dexList.findIndex(
                                  (val) =>
                                    val.name.toLowerCase() ===
                                    item.exchange.toLowerCase()
                                );
                                var logo = config.defaultLogo;
                                if (index !== -1) {
                                  logo =
                                    config.imageUrl +
                                    "/dexsite/" +
                                    dexList[index].logoURI;
                                }
                                if (
                                  item.exchange == "MegaSwap" ||
                                  item.exchange == "MultiSwap"
                                ) {
                                  logo =
                                    config.imageUrl + "/dexsite/megaswap.png";
                                } else if (item.exchange == "SimpleSwap") {
                                  logo =
                                    config.imageUrl + "/dexsite/simpleswap.png";
                                }

                                return (
                                  <tr>
                                    <td className="align">
                                      {" "}
                                      <img
                                        src={logo}
                                        alt={item.exchange}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = config.defaultLogo;
                                        }}
                                        className="me-2"
                                        style={{
                                          widht: "30px",
                                          height: "30px",
                                        }}
                                      />
                                      {item.exchange}
                                      <span
                                        id={"feepercentage-" + i}
                                        className={
                                          i === 0
                                            ? "badge rounded-pill text-bg-success"
                                            : "badge rounded-pill text-bg-danger"
                                        }
                                      >
                                        {i === 0
                                          ? "Best"
                                          : item.percentage + "%"}
                                        {i > 0 && (
                                          <ReactTooltip
                                            className="custom_tooltip"
                                            anchorId={"feepercentage-" + i}
                                            place="right"
                                            content={`${t(
                                              "DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST"
                                            )} ${item.exchange}`}
                                          />
                                        )}
                                      </span>
                                    </td>

                                    <td>
                                      {item && parseFloat(item.price) > 0
                                        ? parseFloat(item.price).toFixed(3)
                                        : 0}
                                    </td>
                                    <td>
                                      {item && parseFloat(item.destAmount) > 0
                                        ? parseFloat(item.destAmount).toFixed(3)
                                        : 0}{" "}
                                      <span>{swapInfo.tosymbol}</span>
                                    </td>
                                    <td>
                                      ~$
                                      {item && parseFloat(item.gasUSD) > 0
                                        ? parseFloat(item.gasUSD).toFixed(3)
                                        : 0}
                                    </td>
                                  </tr>
                                );
                              })}

                            {marketsList && marketsList.length == 0 && (
                              <tr>
                                <td colSpan={4}>
                                  <div className="noorder_div">
                                    <img
                                      src={require("../../assets/images/no_orders_icon.png")}
                                      alt="Search"
                                    />
                                    <p>{t("NO_RECORDS")}</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                          {/* {marketsList && marketsList.length == 0 && (
                            <p className="text-center">{t('NO_RECORDS')}</p>
                          )} */}
                        </table>
                      </div>
                    ) : (
                      <div className="text-center">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 order-lg-2 order-1">
              <Swapsection loadMarkets={getMarkets} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
