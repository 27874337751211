import moment from 'moment';

export function dateFormat(date, format) {

	if (date && date !== '' && format && format !== '') {
		var cDate = moment(date).format(format);
		return cDate;
	} else {
		return '-';
	}
}

export function SecondsToDay(value) {
	var seconds = Number(value);
	if (seconds && seconds > 0) {

		var d = Math.floor(seconds / (3600 * 24));
		var h = Math.floor(seconds % (3600 * 24) / 3600);
		var m = Math.floor(seconds % 3600 / 60);
		var s = Math.floor(seconds % 60);
		var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
		var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
		var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
		var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
		return dDisplay + hDisplay + mDisplay + sDisplay;

	} else {
		return '-';
	}
}

export function displayDays(value) {

	var seconds = Number(value);

	if (seconds && seconds > 0) {

		var countDownDate = new Date(value).getTime();
		var now = new Date().getTime();
		var timeToDate = countDownDate - now;
		var days = Math.floor(timeToDate / (1000 * 60 * 60 * 24));
		var hours = Math.floor((timeToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((timeToDate % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((timeToDate % (1000 * 60)) / 1000);

		var display = "";
		if (days > 0) {
			display += days + "d "
		}
		if (hours > 0) {
			display += hours + "h "
		}
		if (minutes > 0) {
			display += minutes + "m "
		} if (seconds > 0) {
			display += seconds + "s "
		}
		return display;

	} else {
		return '-';
	}
}