var rpc = {
    1: "https://mainnet.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
    56: "https://bsc-dataseed.binance.org/",
    137: "https://polygon-rpc.com/",
    43114: "https://api.avax.network/ext/bc/C/rpc",
    250: "https://rpcapi.fantom.network",
    42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
    10: "https://mainnet.optimism.io",
    128: "https://http-mainnet-node.huobichain.com",
    8453: "https://mainnet.base.org",
};
export default rpc;