import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTokens } from "../../Api/BridgeActions";
import config from "../../config/config";
import { useTranslation } from "react-i18next";
import { getAllTokenBalances, getAllCoinBalances } from "../../ContractActions/bep20Actions";
import { setbridgeToken, setbridgeNetwork } from "../../reducers/Actions";
import { getNetworkName } from "../../helper/networkdata";
import {
  getFromDetails,
  getFromToken,
  getToDetails,
  getToToken,
} from "../../ContractActions/bridgeActions";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";


var nwList = [
  {
    name: "Ethereum",
    chainId: 1,
    bridgeId: 101,
    Router: "0x7612aE2a34E5A363E137De748801FB4c86499152",
    bridge: "0xd43cbCC7642C1Df8e986255228174C2cca58d65b",
  },
  {
    name: "BNB Chain",
    chainId: 56,
    bridgeId: 102,
    Router: "0xbB0f1be1E9CE9cB27EA5b0c3a85B7cc3381d8176",
    bridge: "0xa1E105511416aEc3200CcE7069548cF332c6DCA2",
  },
  {
    name: "Avalanche",
    chainId: 43114,
    bridgeId: 106,
    Router: "0x13093E05Eb890dfA6DacecBdE51d24DabAb2Faa1",
    bridge: "0x29fBC4E4092Db862218c62a888a00F9521619230",
  },
  {
    name: "Polygon",
    chainId: 137,
    bridgeId: 109,
    Router: "0x817436a076060D158204d955E5403b6Ed0A5fac0",
    bridge: "0x629B57D89b1739eE1C0c0fD9eab426306e11cF42",
  },
  {
    name: "Arbitrum",
    chainId: 42161,
    bridgeId: 110,
    Router: "0xb850873f4c993Ac2405A1AdD71F6ca5D4d4d6b4f",
    bridge: "0xd43cbCC7642C1Df8e986255228174C2cca58d65b",
  },
  {
    name: "Optimism",
    chainId: 10,
    bridgeId: 111,
  },
  {
    name: "Fantom",
    chainId: 250,
    bridgeId: 112,
  },
  {
    name: "Base",
    chainId: 8453,
    bridgeId: 184,
  },
];

import { dropdownLogo } from "../../helper/networkdata";

export default function BridgeModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bridgeToken = useSelector((state) => state.bridgeToken);
  const bridgeNetwork = useSelector((state) => state.bridgeNetwork);
  const walletConnection = useSelector((state) => state.walletConnection);

  const [tokenList, settokenList] = useState([]);
  const [alltokenList, setalltokenList] = useState([]);
  const [currId, setcurrId] = useState("");
  const [networkList, setnetworkList] = useState(nwList);
  const [populartokens, setpopulartokens] = useState([]);
  const [status, setstatus] = useState(true);

  useEffect(() => {
    setInitial();
    //eslint-disable-next-line
  }, [walletConnection.network]);

  useEffect(() => {
    selectedToken();
    //eslint-disable-next-line
  }, [props.id]);

  function selectedToken() {
    setcurrId(props.id);
  }

  async function setInitial() {
    try {
      var ntId = localStorage.getItem("network");
      ntId = parseInt(ntId) > 0 ? parseInt(ntId) : 56;

      let { result } = await GetTokens();
      var list = JSON.parse(result);

      list = list.sort(function (a, b) {
        return parseFloat(a.chainId) - parseFloat(b.chainId);
      });

      var index = list.findIndex(
        (val) =>
          val.chainId === 56 &&
          val.address === "0x55d398326f99059fF775485246999027B3197955"
      );
      index = index === -1 ? 0 : index;

      var index1 = list.findIndex(
        (val) =>
          val.chainId === 137 &&
          val.address === "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
      );
      index1 = index1 === -1 ? 0 : index1;

      var fromaddress = list[index].address;
      var fromsymbol = list[index].symbol;
      var fromname = list[index].name;
      var fromlogo = config.imageUrl + "/bridge/" + list[index].logoURI;
      var frombridgeId = list[index].bridgeId;
      var fromchainId = list[index].chainId;
      var frompoolId = list[index].poolId;
      var fromdecimals = list[index].decimals;
      var frompool = list[index].pool;

      var toaddress = list[index1].address;
      var tosymbol = list[index1].symbol;
      var tologo = config.imageUrl + "/bridge/" + list[index1].logoURI;
      var toname = list[index1].name;
      var tobridgeId = list[index1].bridgeId;
      var tochainId = list[index1].chainId;
      var topoolId = list[index1].poolId;
      var todecimals = list[index1].decimals;
      var topool = list[index1].pool;

      if (
        bridgeToken &&
        bridgeToken.fromaddress &&
        bridgeToken.fromaddress !== "" &&
        bridgeToken.toaddress &&
        bridgeToken.toaddress !== ""
      ) {
        fromaddress = bridgeToken.fromaddress;
        fromsymbol = bridgeToken.fromsymbol;
        fromname = bridgeToken.fromname;
        fromlogo = bridgeToken.fromlogo;
        frombridgeId = bridgeNetwork.frombridgeId;
        fromchainId = bridgeNetwork.fromchainId;
        frompoolId = bridgeToken.frompoolId;
        fromdecimals = bridgeToken.fromdecimals;
        frompool = bridgeToken.frompool;

        toaddress = bridgeToken.toaddress;
        tosymbol = bridgeToken.tosymbol;
        tologo = bridgeToken.tologo;
        toname = bridgeToken.toname;
        tobridgeId = bridgeNetwork.tobridgeId;
        tochainId = bridgeNetwork.tochainId;
        topoolId = bridgeToken.topoolId;
        todecimals = bridgeToken.todecimals;
        topool = bridgeToken.topool;
      }

      var popList = getPoplularList(list);
      //popList = popList.filter((data) => data.chainId == fromchainId);
      setpopulartokens(popList);

      dispatch(
        setbridgeToken({
          fromaddress: fromaddress,
          fromsymbol: fromsymbol,
          fromname: fromname,
          fromlogo: fromlogo,
          frombridgeId: frombridgeId,
          fromchainId: fromchainId,
          frompoolId: frompoolId,
          fromdecimals: fromdecimals,
          frompool: frompool,
          toaddress: toaddress,
          tosymbol: tosymbol,
          tologo: tologo,
          toname: toname,
          tobridgeId: tobridgeId,
          tochainId: tochainId,
          topoolId: topoolId,
          todecimals: todecimals,
          topool: topool,
        })
      );
      settokenList(list);
      setalltokenList(list);

      var fChainId = fromchainId && fromchainId > 0 ? fromchainId : 56;
      var tChainId = tochainId && tochainId > 0 ? tochainId : 137;
      var index2 = networkList.findIndex((val) => val.chainId === fChainId);
      index2 = index2 === -1 ? 0 : index2;
      var index3 = networkList.findIndex((val) => val.chainId === tChainId);
      index3 = index3 === -1 ? 0 : index3;

      var fromnetwork = networkList[index2].name;
      var fromchainId = networkList[index2].chainId;
      var frombridgeId = networkList[index2].bridgeId;
      var tonetwork = networkList[index3].name;
      var tochainId = networkList[index3].chainId;
      var tobridgeId = networkList[index3].bridgeId;

      if (
        bridgeNetwork &&
        parseInt(bridgeNetwork.fromnetwork) > 0 &&
        parseInt(bridgeNetwork.tonetwork) > 0
      ) {
        fromnetwork = bridgeNetwork.fromnetwork;
        fromchainId = bridgeNetwork.fromchainId;
        frombridgeId = bridgeNetwork.frombridgeId;

        tonetwork = bridgeNetwork.tonetwork;
        tochainId = bridgeNetwork.tochainId;
        tobridgeId = bridgeNetwork.tobridgeId;
      }

      dispatch(
        setbridgeNetwork({
          fromnetwork: fromnetwork,
          fromchainId: fromchainId,
          frombridgeId: frombridgeId,
          tonetwork: tonetwork,
          tochainId: tochainId,
          tobridgeId: tobridgeId,
        })
      );

      loadUserBalances(list);
    } catch (err) {
      console.log(err, "rrrrrrrrr");
    }
  }

  function getPoplularList(list) {
    try {
      var getPopular = list.filter((data) => data.isPopular == "yes");
      var popularList = [];
      for (var l = 0; l < getPopular.length; l++) {
        popularList.push(getPopular[l]);
      }

      var isPopular = localStorage.getItem("isloadPopularBridge");

      if (isPopular !== "yes") {
        var pList = "";
        if (popularList && popularList.length > 0) {
          for (var p = 0; p < popularList.length; p++) {
            pList +=
              p == 0 ? popularList[p].address : "," + popularList[p].address;
          }

          localStorage.setItem("isloadPopularBridge", "yes");
          localStorage.setItem("bridgepopularlist", pList);
        }
      } else if (isPopular == "yes") {
        var getList = localStorage.getItem("bridgepopularlist");
        var splitAddr = [];
        if (getList) {
          var splitAddr = getList.split(",");
        }
        var gList = [];
        if (splitAddr && splitAddr.length > 0) {
          for (var k = 0; k < splitAddr.length; k++) {
            var chkIndex = list.findIndex(
              (val) => val.address.toLowerCase() == splitAddr[k].toLowerCase()
            );
            if (chkIndex !== -1) {
              gList.push(list[chkIndex]);
            }
          }
          if (gList && gList.length > 0) {
            popularList = gList;
          }
        }
      }
      return gList;
    } catch (err) {
      console.log(err, "ooooooooooo");
      return [];
    }
  }

  const sleep = m => new Promise(r => setTimeout(r, m));

  async function loadUserBalances(list) {

    var ethlist = list.filter((data) => data.chainId == 1 && data.symbol != "ETH");

    ethlist = await getAllTokenBalances(ethlist);
    await sleep(1000);

    var bsclist = list.filter((data) => data.chainId == 56 && data.symbol != "ETH");
    bsclist = await getAllTokenBalances(bsclist);
    await sleep(1000);

    var maticlist = list.filter((data) => data.chainId == 137 && data.symbol != "ETH");
    maticlist = await getAllTokenBalances(maticlist);
    await sleep(1000);

    var avaxlist = list.filter((data) => data.chainId == 43114 && data.symbol != "ETH");
    avaxlist = await getAllTokenBalances(avaxlist);
    await sleep(1000);

    var ftmlist = list.filter((data) => data.chainId == 250 && data.symbol != "ETH");
    ftmlist = await getAllTokenBalances(ftmlist);
    await sleep(1000);

    var arblist = list.filter((data) => data.chainId == 42161 && data.symbol != "ETH");
    arblist = await getAllTokenBalances(arblist);
    await sleep(2000);

    var optlist = list.filter((data) => data.chainId == 10 && data.symbol != "ETH");
    optlist = await getAllTokenBalances(optlist);
    await sleep(1000);

    var baselist = list.filter((data) => data.chainId == 8453 && data.symbol != "ETH");
    baselist = await getAllTokenBalances(baselist);
    await sleep(1000);

    var withouttoken = list.filter((data) => data.symbol == "ETH");
    withouttoken = await getAllCoinBalances(withouttoken);

  }

  const filterValue = async (event) => {
    var value = event.target.value;
    if (value && value !== "") {
      var filteredData = alltokenList.filter((data) =>
        data.symbol.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        settokenList([]);
      } else {
        setTimeout(settokenList.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(settokenList.bind(this, alltokenList), 200);
    }
  };

  const filterNetwork = async (event) => {
    var value = event.target.value;
    if (value && value !== "") {
      var filteredData = nwList.filter((data) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredData.length === 0) {
        setnetworkList([]);
      } else {
        setTimeout(setnetworkList.bind(this, filteredData), 200);
      }
    } else {
      setTimeout(setnetworkList.bind(this, nwList), 200);
    }
  };

  function chooseNetwork(item, isAvailable) {

    if (isAvailable == "yes") {
      var id = currId;

      var resp = bridgeToken;
      if (id === "from") {
        resp = getFromDetails(item, tokenList, bridgeToken);
        dispatch(
          setbridgeNetwork({
            fromnetwork: item.name,
            fromchainId: item.chainId,
            frombridgeId: item.bridgeId,
            tonetwork: bridgeNetwork.tonetwork,
            tochainId: bridgeNetwork.tochainId,
            tobridgeId: bridgeNetwork.tobridgeId,
          })
        );
      } else {

        resp = getToDetails(item, tokenList, bridgeToken);

        dispatch(
          setbridgeNetwork({
            fromnetwork: bridgeNetwork.fromnetwork,
            fromchainId: bridgeNetwork.fromchainId,
            frombridgeId: bridgeNetwork.frombridgeId,
            tonetwork: item.name,
            tochainId: item.chainId,
            tobridgeId: item.bridgeId,
          })
        );
      }
      dispatch(setbridgeToken(resp));
      window.$("#bridge_network_modal").modal("hide");
    }
  }

  function chooseToken(item) {

    var id = currId;
    var resp = bridgeToken;

    if (id === "from") {
      var Data = {
        fromaddress: item.address,
        fromsymbol: item.symbol,
        fromname: item.name,
        fromlogo: config.imageUrl + "/bridge/" + item.logoURI,
        frombridgeId: item.bridgeId,
        fromchainId: item.chainId,
        frompoolId: item.poolId,
        fromdecimals: item.decimals,
        frompool: item.pool,
        toaddress: bridgeToken.toaddress,
        tosymbol: bridgeToken.tosymbol,
        tologo: bridgeToken.tologo,
        toname: bridgeToken.toname,
        tobridgeId: bridgeToken.tobridgeId,
        tochainId: bridgeToken.tochainId,
        topoolId: bridgeToken.topoolId,
        todecimals: bridgeToken.todecimals,
        topool: bridgeToken.topool
      };

      var nw = {
        chainId: item.chainId,
        tochainId: bridgeNetwork.tochainId,
      };

      var index = nwList.findIndex(val => val.chainId == item.chainId);
      resp = getFromToken(nw, tokenList, Data);
      var index1 = nwList.findIndex(val => val.chainId == resp.tochainId);
      dispatch(
        setbridgeNetwork({
          fromnetwork: nwList[index].name,
          fromchainId: nwList[index].chainId,
          frombridgeId: nwList[index].bridgeId,
          tonetwork: nwList[index1].name,
          tochainId: nwList[index1].chainId,
          tobridgeId: nwList[index1].bridgeId,
        })
      );


    } else {
      var Data = {
        fromaddress: bridgeToken.fromaddress,
        fromdecimal: bridgeToken.fromdecimal,
        fromsymbol: bridgeToken.fromsymbol,
        fromname: bridgeToken.fromname,
        fromlogo: bridgeToken.fromlogo,
        frombridgeId: bridgeToken.frombridgeId,
        fromchainId: bridgeToken.fromchainId,
        frompoolId: bridgeToken.frompoolId,
        fromdecimals: bridgeToken.fromdecimals,
        frompool: bridgeToken.frompool,
        toaddress: item.address,
        tosymbol: item.symbol,
        tologo: config.imageUrl + "/bridge/" + item.logoURI,
        toname: item.name,
        tobridgeId: item.bridgeId,
        tochainId: item.chainId,
        topoolId: item.poolId,
        todecimals: item.decimals,
        topool: item.pool
      };
      var nw = {
        chainId: bridgeNetwork.tochainId,
        tochainId: bridgeNetwork.fromchainId,
      };

      resp = getToToken(nw, tokenList, Data);

    }

    dispatch(setbridgeToken(resp));
    window.$("#bridge_token_modal").modal("hide");
  }

  function setFavourite(addr) {

    if (walletConnection && walletConnection.address !== "") {
      setstatus(false)
      var isExits = populartokens.findIndex(
        (val) => val.address.toLowerCase() == addr.toLowerCase()
      );

      if (isExits == -1) {
        //if (populartokens && populartokens.length <= 12) {
        var getList = localStorage.getItem("bridgepopularlist");
        if (getList) {
          getList += "," + addr;
        } else {
          getList += addr;
        }
        var chkIndex = alltokenList.findIndex(
          (val) => val.address.toLowerCase() == addr.toLowerCase()
        );
        if (chkIndex !== -1) {
          populartokens.push(alltokenList[chkIndex]);
        }
        setpopulartokens([])
        setTimeout(function () {
          setpopulartokens(populartokens);
        }, 1)

        localStorage.setItem("bridgepopularlist", getList);
        // }
      } else {
        populartokens.splice(isExits, 1);
        setpopulartokens([])
        setTimeout(function () {
          setpopulartokens(populartokens);
        }, 1)
        var pList = "";
        for (var p = 0; p < populartokens.length; p++) {
          pList +=
            p == 0 ? populartokens[p].address : "," + populartokens[p].address;
        }
        localStorage.setItem("bridgepopularlist", pList);
      }
      setstatus(true)
    }

  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="bridge_token_modal"
        tabIndex="-1"
        aria-labelledby="bridge_token_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="bridge_token_modalLabel">
                {t("SELECT_TOKEN")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                className="token_search_inp"
                placeholder={t("SEARCH_A_TOKEN")}
                onChange={filterValue}
              />

              <p className="my-3">{t('POPULAR_TOKENS')}</p>

              <div className="whole_popularlist">
                {status && populartokens &&
                  populartokens.length > 0 &&
                  populartokens.map((item, i) => {
                    var Saddress =
                      currId == "from"
                        ? bridgeToken.toaddress
                        : bridgeToken.fromaddress;

                    var logoURI = config.imageUrl + "/bridge/" + item.logoURI;
                    var chainId = (currId == "from") ? bridgeNetwork.fromchainId : bridgeNetwork.tochainId
                    if (Saddress !== item.address && chainId == item.chainId) {
                      return (
                        <div
                          className="popularlist"
                          id={"fav-" + i}
                        >
                          <img
                            src={logoURI}
                            alt={item.symbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                            onClick={() => chooseToken(item)}
                          />
                          <div onClick={() => chooseToken(item)}>
                            <span className="token_search_list_cur_name">
                              {item.symbol}
                            </span>
                          </div>
                          <i
                            className="fa fa-times-circle popularlist_close"
                            aria-hidden="true"
                            onClick={() => setFavourite(item.address)}
                          ></i>
                        </div>
                      );
                    }
                  })}
              </div>

              <div className="token_search_list">

                <div>
                  <p>{bridgeNetwork.fromnetwork} Network</p>
                  <hr></hr>
                  {tokenList &&
                    tokenList.length > 0 &&
                    tokenList.map((item, i) => {

                      var logoURI = config.imageUrl + "/bridge/" + item.logoURI;
                      var currNwId =
                        currId == "from"
                          ? bridgeToken.fromchainId
                          : bridgeToken.tochainId;

                      var index = nwList.findIndex(val => val.chainId == item.chainId);
                      var nwName = "";
                      if (index !== -1) {
                        nwName = nwList[index].name
                      }
                      var tknAddr = item.address.toLowerCase();
                      var pIndex = -1;
                      if (populartokens && populartokens.length > 0) {
                        pIndex = populartokens.findIndex(
                          (val) =>
                            val.address.toLowerCase() ==
                            item.address.toLowerCase()
                        );
                      }

                      if (currNwId == item.chainId) {
                        return (
                          <div className="token_search_list_row" key={i}>
                            <div onClick={() => chooseToken(item)}>
                              <img
                                src={logoURI}
                                alt={item.symbol}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = config.defaultLogo;
                                }}
                              />
                              <div>
                                <span className="token_search_list_cur_name">
                                  {item.symbol}
                                </span>
                                <span className="token_search_list_cur_value">
                                  {getNetworkName(item.chainId)}
                                </span>
                              </div>
                            </div>
                            <div className="token_fav">
                              <span
                                id={tknAddr}
                                className="token_search_list_coin_dollar"
                              >
                                {(item.balance) ? item.balance : 0}{" "}
                              </span>
                              <i
                                onClick={() => setFavourite(item.address)}
                                className={
                                  (pIndex !== -1 && walletConnection && walletConnection.address != "")
                                    ? "fas fa-thumbtack ispopular"
                                    : "fas fa-thumbtack"
                                }
                              ></i>
                            </div>

                          </div>
                        );
                      }
                    })}
                </div>

                {currId == "from" &&
                  <div>
                    <p>All Networks</p>
                    <hr></hr>
                    {tokenList &&
                      tokenList.length > 0 &&
                      tokenList.map((item, i) => {

                        var logoURI = config.imageUrl + "/bridge/" + item.logoURI;
                        var currNwId =
                          currId == "from"
                            ? bridgeToken.fromchainId
                            : bridgeToken.tochainId;

                        var index = nwList.findIndex(val => val.chainId == item.chainId);
                        var nwName = "";
                        if (index !== -1) {
                          nwName = nwList[index].name
                        }
                        var tknAddr = item.address.toLowerCase();
                        var pIndex = -1;
                        if (populartokens && populartokens.length > 0) {
                          pIndex = populartokens.findIndex(
                            (val) =>
                              val.address.toLowerCase() ==
                              item.address.toLowerCase()
                          );
                        }

                        if (currNwId !== item.chainId) {
                          return (
                            <div className="token_search_list_row" key={i}>
                              <div onClick={() => chooseToken(item)}>
                                <img
                                  src={logoURI}
                                  alt={item.symbol}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = config.defaultLogo;
                                  }}
                                />
                                <div>
                                  <span className="token_search_list_cur_name">
                                    {item.symbol}
                                  </span>
                                  <span className="token_search_list_cur_value">
                                    {getNetworkName(item.chainId)}
                                  </span>
                                </div>
                              </div>
                              <div className="token_fav">
                                <span
                                  id={tknAddr}
                                  className="token_search_list_coin_dollar"
                                >
                                  {(item.balance) ? item.balance : 0}{" "}
                                </span>
                                <i
                                  onClick={() => setFavourite(item.address)}
                                  className={
                                    (pIndex !== -1 && walletConnection && walletConnection.address != "")
                                      ? "fas fa-thumbtack ispopular"
                                      : "fas fa-thumbtack"
                                  }
                                ></i>
                              </div>

                            </div>
                          );
                        }
                      })}
                  </div>
                }
              </div>


              {tokenList && tokenList.length === 0 && (
                <div className="token_search_list emptytokens">
                  <div>
                    <span className="token_search_list_cur_name">
                      {t("NO_RECORDS")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade primary_modal"
        id="bridge_network_modal"
        tabIndex="-1"
        aria-labelledby="bridge_network_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="bridge_network_modalLabel">
                {t("SELECT_NETWORK")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                className="token_search_inp"
                placeholder="Search a network"
                onChange={filterNetwork}
              />
              {networkList && networkList.length > 0 && (
                <div className="token_search_list">
                  {networkList &&
                    networkList.length > 0 &&
                    networkList.map((item, i) => {
                      var currAddr =
                        currId == "from"
                          ? bridgeToken.toaddress
                          : bridgeToken.fromaddress;
                      var currChainId =
                        currId == "from"
                          ? bridgeToken.tochainId
                          : bridgeToken.fromchainId;

                      var currIndex = tokenList.findIndex(
                        (val) =>
                          val.address.toLowerCase() == currAddr.toLowerCase()
                      );

                      var isAvailable = "no";
                      var errorMsg = t("TRANSFER_FOR_THE_SLECTED_TOKEN_ARE");
                      if (currIndex != -1) {
                        var currLocal = tokenList[currIndex].localpath;

                        var checkExitsToken = tokenList.filter(
                          (data) =>
                            data.chainId == item.chainId &&
                            data.remotepath.includes(currLocal)
                        );
                        if (item.chainId == 10) {
                        }

                        if (checkExitsToken && checkExitsToken.length > 0) {
                          isAvailable = "yes";
                          errorMsg = "";
                        }
                      }

                      if (currChainId == item.chainId) {
                        errorMsg =
                          "Transfer between the same network are not supported";
                        isAvailable = "samenetwork";
                      }

                      return (
                        <div
                          className={
                            isAvailable == "yes"
                              ? " token_search_list_row eligibletokens"
                              : " token_search_list_row eligibletokens_disabled"
                          }
                          key={i}
                          onClick={() => chooseNetwork(item, isAvailable)}
                          id={item.chainId + "_" + isAvailable}
                        >
                          <div>
                            <img
                              src={dropdownLogo(item.chainId)}
                              alt={item.symbol}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = config.defaultLogo;
                              }}
                            />
                            <div>
                              <span className="token_search_list_cur_name">
                                {item.name}
                              </span>
                            </div>
                          </div>
                          {isAvailable == "no" && (
                            <ReactTooltip
                              anchorId={item.chainId + "_" + isAvailable}
                              place="top"
                              content={errorMsg}
                            />
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
              {networkList && networkList.length === 0 && (
                <div className="token_search_list emptytokens">
                  <div>
                    <span className="token_search_list_cur_name">
                      {t("NO_RECORDS")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
