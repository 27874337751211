import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useConnect, useDisconnect } from "wagmi";
import { dropdownLogo, getCoinName } from "../../helper/networkdata";
import {
  getLayerZeroFee,
  swap,
  getProtocolAndLpFeeInfo,
  getCalculation,
  getTokenBalance,
  getBridgeFee,
} from "../../ContractActions/bridgeActions";
import { approve } from "../../ContractActions/bep20Actions";
import { toastAlert } from "../../helper/toastAlert";
import config from "../../config/config";
import {
  setTransferValue,
  updateTransferValue,
  setTransferStatus,
  setChainGasCost,
  setbridgeToken,
  setbridgeNetwork,
  selectNetwork,
  setBridgeSettings
} from "../../reducers/Actions";
import { useTranslation } from "react-i18next";

import BridgeModal from "./bridgeModal";
import {
  formatNumber,
  numberFloatOnly,
  CheckDecimal,
  multiplyValue,
  divideValue,
  subtractValue
} from "../../helper/custommath";

import BridgeConnectWallet from "../../components/bridgeConnectWallet";

import { getLoginInfo } from "../../Api/TokenActions";
import { SaveBridge } from "../../Api/BridgeActions";

import { getTokenRate } from "../../Api/SwapActions";
import { getbalance } from "../../ContractActions/bep20Actions";

var initial = {
  PROTOCOL_FEE_FOR_SAME_TOKEN: 0,
  PROTOCOL_FEE: 0,
  DENOMINATOR: 0,
  LP_FEE: 0,
  stakingAddr: "",
  allocPoint: 0,
};
var setintervaltime = new Date();
export default function Transfer(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  var { clickSettings1, confirmSwap1 } = props;
  const walletConnection = useSelector((state) => state.walletConnection);
  const bridgeToken = useSelector((state) => state.bridgeToken);
  const bridgeNetwork = useSelector((state) => state.bridgeNetwork);
  const transfer = useSelector((state) => state.transfer);
  const bsettings = useSelector((state) => state.bridgesettings);
  const transferstatus = useSelector((state) => state.transferstatus);
  const chainGasCost = useSelector((state) => state.chainGasCost);
  const selectednetwork = useSelector((state) => state.network);

  var CurrchainId =
    selectednetwork &&
      selectednetwork.network &&
      parseInt(selectednetwork.network)
      ? parseInt(selectednetwork.network)
      : 0;

  const [spendeValue, setspendeValue] = useState(0);
  const [selectedId, setselectedId] = useState("");
  const [calInfo, setcalInfo] = useState(initial);
  const [gasCost, setgasCost] = useState(0);
  const [balanceInfo, setbalanceInfo] = useState({
    allowance: 0,
    tokenbalanceOf: 0,
    balanceOf: 0,
    tokenbalance: 0,
    balance: 0,
    ethbalanceOf: 0,
  });

  const [error, seterror] = useState("");
  const [isApprove, setisApprove] = useState(false);
  const [isChange, setisChange] = useState(false);
  const [isSwap, setisSwap] = useState(false);
  const [adminfee, setadminfee] = useState(0);
  const [feeLoaed, setfeeLoaed] = useState(false);

  useEffect(() => {
    initialLoad();
  }, [walletConnection, bridgeToken]);

  useEffect(() => {
    GasonDestination();
  }, [bridgeToken.tochainId]);

  useEffect(() => {
    const btimer = window.setInterval(async () => {
      var t = new Date(setintervaltime);
      t.setSeconds(t.getSeconds() + 10);
      if (t < new Date() && setintervaltime) {
        reload();
      }
    }, 15000);
    return () => {
      window.clearInterval(btimer);
    };
    // eslint-disable-next-line
  }, [bridgeToken, walletConnection, transfer]);

  async function GasonDestination() {
    var chainId = bridgeToken.tochainId;
    var bvalue = localStorage.getItem("bvalue-" + chainId) &&
      (localStorage.getItem("bvalue-" + chainId) !== "") ? localStorage.getItem("bvalue-" + chainId) : 0;

    dispatch(setBridgeSettings({
      bslippage: bsettings.bslippage,
      bgasmode: bsettings.bgasmode,
      bvalue: bvalue,
    }));
    localStorage.setItem("bvalue-" + chainId, bvalue);

  }

  useEffect(() => {
    reload();
  }, [bsettings]);

  async function initialLoad() {
    if (
      bridgeToken &&
      bridgeToken.fromaddress &&
      bridgeToken.fromaddress != ""
    ) {
      var chainId = parseInt(bridgeToken.fromchainId);
      var feeLibrary = config.bridge[chainId].feeLibrary;
      var middlreware = config.middlreware[chainId];

      var bal = await getTokenBalance(
        bridgeToken.fromaddress,
        middlreware,
        bridgeToken.fromsymbol,
        chainId
      );

      setbalanceInfo(bal);
      var info = {
        chainId: chainId,
        _srcPoolId: bridgeToken.frompoolId.toString(),
        _dstPoolId: bridgeToken.topoolId.toString(),
        feeLibrary: feeLibrary,
      };

      var resp = await getProtocolAndLpFeeInfo(info);

      setcalInfo(resp);
      seterror("");
      var { fee } = await getBridgeFee(chainId);
      setadminfee(fee);
      setfeeLoaed(true);
      if (
        transfer &&
        transfer.amount &&
        parseFloat(transfer.amount) > 0 &&
        walletConnection &&
        walletConnection.address !== ""
      ) {
        var swapData = bridgeToken;
        var balnfo = bal;
        var cInfo = resp;
        setisSwap(true);
        calculateTransfer(transfer.amount, swapData, balnfo, cInfo, fee);
      }
    }
  }

  async function transferToken() {
    console.log("transferToken");
    confirmSwap1(true, false);

    if (!feeLoaed) {
      toastAlert("error", t("PLEASE_TRY_LATER"), "transfer");
      return false;
    }

    try {
      var chainId = parseInt(bridgeToken.fromchainId);
      var middlreware = config.middlreware[chainId];
      if (bridgeToken.fromsymbol === "ETH") {
        //Router = config.bridge[chainId].RouterETH;
      }

      let fromdecimals = bridgeToken.fromdecimals;
      let todecimals = bridgeToken.todecimals;

      var amount = parseFloat(transfer.amount) * 10 ** parseFloat(fromdecimals);
      var gasAmt = parseFloat(chainGasCost.gas) * 10 ** parseFloat(18);

      var minReceivedAmt =
        transfer && transfer.receiveAmount && parseFloat(transfer.receiveAmount)
          ? transfer.receiveAmount
          : 0;

      var slippage =
        bsettings && bsettings.bslippage && parseFloat(bsettings.bslippage)
          ? bsettings.bslippage
          : 0.5;
      var cal = (minReceivedAmt * slippage) / 100;

      minReceivedAmt = minReceivedAmt - cal;

      minReceivedAmt = minReceivedAmt * 10 ** parseFloat(todecimals);

      // let extra = 0.0002 * 10 ** parseFloat(todecimals);
      minReceivedAmt = parseFloat(minReceivedAmt) + parseFloat(0);

      minReceivedAmt = CheckDecimal(minReceivedAmt);
      amount = CheckDecimal(amount);
      gasAmt = CheckDecimal(gasAmt);

      console.log(bridgeToken.tobridgeId.toString(),
        bridgeToken.frompoolId.toString(),
        bridgeToken.topoolId.toString(),
        amount,
        middlreware,
        gasAmt,
        bridgeToken.tochainId,
        bridgeToken.fromsymbol,
        minReceivedAmt.toString(),
        bridgeToken.fromaddress)

      var { status, error, block, txHash, adminFee } = await swap(
        bridgeToken.tobridgeId.toString(),
        bridgeToken.frompoolId.toString(),
        bridgeToken.topoolId.toString(),
        amount,
        middlreware,
        gasAmt,
        bridgeToken.tochainId,
        bridgeToken.fromsymbol,
        minReceivedAmt.toString(),
        bridgeToken.fromaddress
      );

      if (error) {
        toastAlert("error", t(error), "transfer");
        confirmSwap1(false, false);
      } else if (status) {
        var { ip, country } = await getLoginInfo();
        var txData = {
          formAddress: bridgeToken.fromaddress,
          toAddress: bridgeToken.toaddress,
          fromSymbol: bridgeToken.fromsymbol,
          toSymbol: bridgeToken.tosymbol,
          fromAmount: parseFloat(transfer.amount),
          toAmount: transfer.receiveAmount,
          chainId: bridgeToken.fromchainId,
          tochainId: bridgeToken.tochainId,
          txId: txHash,
          userAddress: walletConnection.address,
          ip,
          country,
          adminFee:
            parseFloat(adminFee) / 10 ** parseFloat(bridgeToken.fromdecimals),
        };
        SaveBridge(txData);
        setspendeValue(0);
        // dispatch(setTransferValue({
        //     amount: "",
        //     receiveAmount: 0,
        //     GasonDestination: 0
        // }));
        dispatch(
          setChainGasCost({
            gas: 0,
          })
        );
        setChainGasCost(block);

        dispatch(
          setTransferStatus({
            showApprove: false,
            showTransfer: false,
          })
        );
        // toastAlert("success", "Successfully transfered", "transfer");
        confirmSwap1(false, true);
      }
    } catch (err) {
      confirmSwap1(false, false);
      var message = err && err.message ? err.message : "";
      var pos = message.search("User denied transaction signature");
      var pos1 = message.search("insufficient");
      if (pos !== -1) {
        message = "Cancelled your transaction";
      } else if (pos1 !== -1) {
        message = "insufficient balance your wallet";
      }

      toastAlert("error", t(message), "transfer");
    }
  }

  async function approveToken() {
    setisApprove(true);
    try {
      var chainId = parseInt(bridgeToken.fromchainId);
      var Router = config.middlreware[chainId];
      let { status, approvalAmt } = await approve(
        bridgeToken.fromaddress,
        Router
      );

      var amount =
        parseFloat(spendeValue) * 10 ** parseFloat(bridgeToken.fromdecimals);
      console.log(status, 'statusstatusstatus', parseFloat(approvalAmt), amount, spendeValue)
      if (parseFloat(amount) > parseFloat(approvalAmt)) {
        console.log('11111111111111111')
        dispatch(
          updateTransferValue({
            showApprove: false,
            showTransfer: true,
          })
        );
      } else if (status) {
        var chainId = parseInt(bridgeToken.fromchainId);
        var router = config.middlreware[chainId];
        var bal = await getTokenBalance(
          bridgeToken.fromaddress,
          router,
          bridgeToken.fromsymbol,
          chainId
        );
        setbalanceInfo(bal);
        setTimeout(function () {
          console.log("6363636363363663636")
          validateBalance(spendeValue, gasCost, bridgeToken, bal);
          setisApprove(false);
        }, 5000);
      }

    } catch (err) {
      setisApprove(false);
      toastAlert("error", t("TRANSACTION_REJECTED_LATER"), "transfer");
    }
  }

  const inputChange = async (event) => {
    var value = event.target.value;
    setspendeValue(value);
    var swapData = bridgeToken;

    var balnfo = balanceInfo;
    var cInfo = calInfo;

    var status = await numberFloatOnly(value);
    var str = value.toString();
    var res = str.charAt(str.length - 1);
    if (status) {
      setisSwap(true);
      calculateTransfer(value, swapData, balnfo, cInfo, adminfee);
    } else if (value === "" || !status) {
      resetSwap();
    }
  };

  function reload() {
    if (transfer && transfer.amount && parseFloat(transfer.amount) > 0) {
      var swapData = bridgeToken;
      var balnfo = balanceInfo;
      var cInfo = calInfo;
      setisSwap(true);
      calculateTransfer(transfer.amount, swapData, balnfo, cInfo, adminfee);
    }
  }

  function resetSwap() {
    setgasCost(0);
    dispatch(
      setTransferValue({
        amount: "",
        receiveAmount: 0,
        GasonDestination: 0,
      })
    );
    seterror("");
    dispatch(
      setTransferStatus({
        showApprove: false,
        showTransfer: false,
      })
    );
    setspendeValue("");
    dispatch(
      setChainGasCost({
        gas: 0,
      })
    );
  }

  async function calculateTransfer(value, swapData, balnfo, cInfo, aFee) {
    setintervaltime = new Date();
    var chainId = parseInt(swapData.fromchainId);
    var Router = config.bridge[chainId].router;

    if (aFee == 0 && !feeLoaed) {
      var { fee } = await getBridgeFee(chainId);
      setadminfee(fee);
      aFee = fee;
    }

    //value = parseFloat(value);
    //var cal = (parseFloat(value) * aFee) / 1000;
    var cal1 = multiplyValue(value, aFee);
    cal1 = divideValue(cal1, 100);
    // var cal = parseFloat(value) - cal;
    var cal = subtractValue(value, cal1);

    var amt = parseFloat(cal) * 10 ** parseFloat(swapData.fromdecimals);
    Object.assign(cInfo, {
      _amountSD: amt,
      _srcPoolId: swapData.frompoolId.toString(),
      _dstPoolId: swapData.topoolId.toString(),
      decimals: swapData.fromdecimals,
    });

    var { amount } = getCalculation(cInfo);
    dispatch(
      setTransferValue({
        amount: value,
        receiveAmount: amount,
        GasonDestination: 0,
      })
    );

    var { networkFee } = await getLayerZeroFee(
      Router,
      swapData.tobridgeId,
      swapData.fromdecimals,
      chainId
    );
    //console.log(networkFee, 'networkFeenetworkFeenetworkFee')
    setgasCost(networkFee);

    dispatch(
      setChainGasCost({
        gas: networkFee,
      })
    );
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address !== "" &&
      balnfo.isLoad
    ) {
      validateBalance(value, networkFee, swapData, balnfo);
    } else {
      var chainId = parseInt(bridgeToken.fromchainId);
      var router = config.middlreware[chainId];
      var bal = await getTokenBalance(
        bridgeToken.fromaddress,
        router,
        bridgeToken.fromsymbol,
        chainId
      );
      setbalanceInfo(bal);
      validateBalance(value, networkFee, swapData, bal);
    }
  }

  function validateBalance(value, netFee, swapData, balnfo) {

    var amt = parseFloat(value) * 10 ** parseFloat(swapData.fromdecimals);
    seterror("");
    dispatch(
      setTransferStatus({
        showApprove: false,
        showTransfer: false,
      })
    );

    if (value > 0) {
      var currBal = parseFloat(balnfo.tokenbalanceOf);
      if (swapData.fromsymbol === "ETH") {
        currBal = parseFloat(balnfo.ethbalanceOf);
      }

      if (parseFloat(currBal) < amt) {
        seterror("Sorry Insufficient balance " + swapData.fromsymbol);
      } else if (
        parseFloat(balnfo.ethbalanceOf) <
        parseFloat(netFee) * 10 ** 18
      ) {
        let name = getCoinName(swapData.fromchainId);
        netFee = formatNumber(netFee, 5);
        let err = `Not enough gas ${netFee} ${name} needed`;
        seterror(err);
      } else if (
        parseFloat(balnfo.allowance) < amt &&
        swapData.fromsymbol !== "ETH"
      ) {
        dispatch(
          setTransferStatus({
            showApprove: true,
            showTransfer: false,
          })
        );
      } else {
        dispatch(
          setTransferStatus({
            showApprove: false,
            showTransfer: true,
          })
        );
      }
    }
    setisSwap(false);
  }

  function setCurrentId(id) {
    setselectedId(id);
  }

  function showSettings() {
    clickSettings1();
  }

  async function interChange() {
    setfeeLoaed(false);
    if (
      bridgeToken &&
      bridgeToken.toaddress &&
      bridgeToken.toaddress !== "" &&
      bridgeToken.fromaddress &&
      bridgeToken.fromaddress !== ""
    ) {
      setisChange(true);
      setisSwap(true);
      var swapData = {
        fromaddress: bridgeToken.toaddress,
        fromsymbol: bridgeToken.tosymbol,
        fromname: bridgeToken.toname,
        fromlogo: bridgeToken.tologo,
        frombridgeId: bridgeToken.tobridgeId,
        fromchainId: bridgeToken.tochainId,
        frompoolId: bridgeToken.topoolId,
        fromdecimals: bridgeToken.todecimals,
        frompool: bridgeToken.toaddress,
        toaddress: bridgeToken.fromaddress,
        tosymbol: bridgeToken.fromsymbol,
        tologo: bridgeToken.fromlogo,
        toname: bridgeToken.fromname,
        tobridgeId: bridgeToken.frombridgeId,
        tochainId: bridgeToken.fromchainId,
        topoolId: bridgeToken.frompoolId,
        todecimals: bridgeToken.fromdecimals,
        topool: bridgeToken.frompool,
      };

      dispatch(setbridgeToken(swapData));

      dispatch(
        setbridgeNetwork({
          fromnetwork: bridgeNetwork.tonetwork,
          fromchainId: bridgeNetwork.tochainId,
          frombridgeId: bridgeNetwork.tobridgeId,
          tonetwork: bridgeNetwork.fromnetwork,
          tochainId: bridgeNetwork.fromchainId,
          tobridgeId: bridgeNetwork.frombridgeId,
        })
      );

      var chainId = parseInt(swapData.fromchainId);
      var feeLibrary = config.bridge[chainId].feeLibrary;
      var middlreware = config.middlreware[chainId];
      var bal = await getTokenBalance(
        swapData.fromaddress,
        middlreware,
        swapData.fromsymbol,
        chainId
      );

      setbalanceInfo(bal);
      var info = {
        chainId: chainId,
        _srcPoolId: swapData.frompoolId.toString(),
        _dstPoolId: swapData.topoolId.toString(),
        feeLibrary: feeLibrary,
      };

      var resp = await getProtocolAndLpFeeInfo(info);

      setcalInfo(resp);
      var { fee } = await getBridgeFee(chainId);
      setadminfee(fee);
      setfeeLoaed(true);
      if (spendeValue && parseFloat(spendeValue) > 0) {
        calculateTransfer(spendeValue, swapData, bal, resp, fee);
      } else {
        setisSwap(false);
      }
      setisChange(false);
    }
  }

  async function switchNetwork() {
    disconnect();
    dispatch(selectNetwork({ network: parseInt(bridgeNetwork.fromchainId) }));
  }

  async function setMax() {
    if (balanceInfo && balanceInfo && parseFloat(balanceInfo.balance) > 0) {

      var swapData = bridgeToken;
      var AvailBal = balanceInfo.balance;
      if (swapData.fromsymbol == "ETH") {
        var chainId = parseInt(swapData.fromchainId);
        var Router = config.bridge[chainId].router;
        var { networkFee } = await getLayerZeroFee(
          Router,
          swapData.tobridgeId,
          swapData.fromdecimals,
          chainId
        );
        console.log(swapData, 'swapDataswapDataswapData', networkFee);
        AvailBal = balanceInfo.balance - networkFee;
      }

      if (AvailBal > 0) {
        setspendeValue(AvailBal);
        var balnfo = balanceInfo;
        var cInfo = calInfo;
        setisSwap(true);
        calculateTransfer(AvailBal, swapData, balnfo, cInfo, adminfee);
      } else if (swapData.fromsymbol == "ETH") {
        toastAlert("error", t("INSUFFICIENT_FOR_GASFEE"), "transfer");
      }

    }
  }

  return (
    <div className="bridge_right">
      <div className="swap_box">
        <div className="heading_block">
          <span>{t("BRIDGE")}</span>
          <div>
            {/* <i>
              <img
                src={require("../../assets/images/refresh_icon.png")}
                alt="Refresh"
                onClick={() => reload()}
              />
            </i> */}
            <i onClick={() => showSettings()}>
              <img
                src={require("../../assets/images/settings_icon.png")}
                alt="Settings"
              />
            </i>
          </div>
        </div>
        <div className="swap_container swap_container_chg mb-3">
          <div className="swap_select_token_wrap">
            <div className="swap_select_token_inner">
              <label>{t("YOU_PAY")}</label>
              <div>
                <div
                  className="swap_select_token"
                  data-bs-toggle="modal"
                  data-bs-target="#bridge_token_modal"
                  onClick={() => setCurrentId("from")}
                >
                  <div className="swap_select_token_option">
                    <img
                      src={bridgeToken.fromlogo}
                      alt="Crypto Coin"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                    <span className="swap_token_title">
                      {bridgeToken.fromsymbol}
                    </span>
                  </div>
                </div>
                <div
                  className="swap_select_token"
                  data-bs-toggle="modal"
                  data-bs-target="#bridge_network_modal"
                  onClick={() => setCurrentId("from")}
                >
                  <div className="swap_select_token_option">
                    <img
                      src={dropdownLogo(bridgeNetwork.fromchainId)}
                      alt="Crypto Coin"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                    <span className="swap_token_title">
                      {bridgeNetwork.fromnetwork}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swap_arrow_container">
            <img
              src={require("../../assets/images/swap_icon.png")}
              alt="Crypto Coin"
              disabled={isChange}
              onClick={() => interChange()}
            />
          </div>

          <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
            <div className="swap_select_token_inner">
              <label>{t("YOU_RECEIVE")}</label>
              <div>
                <div
                  className="swap_select_token"
                  data-bs-toggle="modal"
                  data-bs-target="#bridge_token_modal"
                  onClick={() => setCurrentId("to")}
                >
                  <div className="swap_select_token_option">
                    <img
                      src={bridgeToken.tologo}
                      alt="Crypto Coin"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                    <span className="swap_token_title">
                      {bridgeToken.tosymbol}
                    </span>
                  </div>
                </div>
                <div
                  className="swap_select_token"
                  data-bs-toggle="modal"
                  data-bs-target="#bridge_network_modal"
                  onClick={() => setCurrentId("to")}
                >
                  <div className="swap_select_token_option">
                    <img
                      src={dropdownLogo(bridgeNetwork.tochainId)}
                      alt="Crypto Coin"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = config.defaultLogo;
                      }}
                    />
                    <span className="swap_token_title">
                      {bridgeNetwork.tonetwork}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bridge_inp mt-4">
            <div className="d-flex justify-content-between">
              <label>{t("ENTER_AMT")}</label>
              <label>
                {t("BALANCE_ONE")}{" "}
                <span>
                  {formatNumber(balanceInfo.balance, 6)}{" "}
                  {bridgeToken.fromsymbol}
                </span>
              </label>
            </div>
            <div className="input-group slipage_inp_grp mt-1">
              <input
                type="text"
                className="form-control"
                aria-label="slipage"
                aria-describedby="slipage"
                id="amount"
                onChange={inputChange}
                placeholder={t("ENTER_AMT")}
                value={transfer.amount}
                disabled={feeLoaed ? false : true}
              />
              <button
                className="input-group-text"
                id="bridge_max"
                onClick={() => setMax()}
              >
                <span>{t("MAX_S")}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="accordion bridge_info_accordian" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <span>{t("YOU_WILL_RECEIVE")}</span>
                <span>
                  {isSwap && <i className="fas fa-spinner fa-spin"></i>}
                  {transfer.receiveAmount} {bridgeToken.fromsymbol}
                </span>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="swap_info">
                  <div>
                    <span>{t("SLIPPAGE")}</span>

                    <span>{bsettings.bslippage}%</span>
                  </div>
                  <div>
                    <span>{t("GAS_ON_DESTINATION")}</span>
                    <span>
                      {bsettings.bvalue} {getCoinName(bridgeToken.tochainId)}
                    </span>
                  </div>
                  {/* <div>
                                <span>Fee</span>
                                <span>$0.00</span>
                              </div> */}
                  <div>
                    <span>{t("GAS_COST")}</span>
                    <span>
                      {isSwap && <i className="fas fa-spinner fa-spin"></i>}
                      {formatNumber(chainGasCost.gas, 5)}{" "}
                      {getCoinName(bridgeToken.fromchainId)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          {walletConnection.address == "" ? (
            <button
              className="connect_wallet_btn w-100"
              data-bs-toggle="modal"
              data-bs-target="#bconnect_wallet_modal"
              onClick={() => switchNetwork(parseInt(bridgeToken.fromchainId))}
            >
              {t("CONNECT_WALLET")}
            </button>
          ) : error && error !== "" ? (
            <button className="connect_wallet_btn w-100">{error}</button>
          ) : transfer.amount == "" ||
            !transfer.amount ||
            parseFloat(transfer.amount) == 0 ? (
            <button className="connect_wallet_btn w-100">
              {t("ENTER_AMT")}
            </button>
          ) : bridgeToken.fromaddress == "" ? (
            <button className="connect_wallet_btn w-100">
              {t("SELECT_FROM_INFO")}
            </button>
          ) : bridgeToken.toaddress == "" ? (
            <button className="connect_wallet_btn w-100">
              {t("SELECT_TO_INFO")}
            </button>
          ) : parseInt(bridgeToken.fromchainId) !== parseInt(CurrchainId) ? (
            <button
              className="connect_wallet_btn w-100"
              data-bs-toggle="modal"
              data-bs-target="#bconnect_wallet_modal"
              onClick={() => switchNetwork(parseInt(bridgeToken.fromchainId))}
            >
              {t("SWITCH_NETWORK")}
            </button>
          ) : transferstatus.showApprove ? (
            <button
              className="connect_wallet_btn w-100 loadingtext"
              onClick={() => approveToken()}
              disabled={isApprove}
            >
              {t("APPROVE")}
              {isApprove && <i className="fas fa-spinner fa-spin"></i>}
            </button>
          ) : transferstatus.showTransfer ? (
            <button
              className="connect_wallet_btn w-100"
              onClick={() => transferToken()}
            >
              {t("TRANSFER")}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <BridgeModal id={selectedId} />
      <BridgeConnectWallet fromchainId={bridgeToken.fromchainId} />
    </div>
  );
}
