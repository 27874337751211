export default {
  ABOUT_NEW_FEATURES: "o nowych funkcjach.",
  ACCEPT: "Zaakceptować",
  ACTIVE_ORDERS: "Aktywne zamówienia",
  ADD_TOKEN: "Dodaj token",
  AGGRESSIVE: "Agresywny",
  AND: "I",
  APPROVE: "Zatwierdzić",
  ASSETS_ON_WAY: "Twoje aktywa są w drodze i powinny dotrzeć",
  AUTO: "Automatyczny",
  AVOID_TRANSFER:
    "Unikaj przeniesienia na scentralizowany adres wymiany (binance, Huobi ...). To nie jest funkcja obsługiwana",
  BALANCE: "Balansować",
  BALANCE_ONE: "Balansować:",
  BLOG: "OTC",
  BRIDGE: "Most",
  CANCEL: "Anulować",
  CANCELLATION_TRANSACTION_FEE:
    "Anulowanie zamówienia limitowego polega na uiszczeniu opłat transakcyjnych.",
  CANCEL_ALL: "Anulować całość",
  CANCEL_LIMIT_NOT_FILLED:
    "Możesz anulować zamówienie limitu, o ile zamówienie nie zostało jeszcze wypełnione.",
  CANCEL_LIMIT_ORDER: "Anuluj zamówienie limitu",
  CANCEL_ORDER: "Anuluj zamówienie",
  CANCEL_SELECTED: "Anuluj wybrane",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Wybierz sieć",
  CLASSIC_MODE: "Tryb klasyczny",
  CLEAR: "Jasne",
  CONFIRM: "Potwierdzać",
  CONFIRMATION_REJECTED: "Potwierdzenie odrzucone.!",
  CONFIRM_SWAP: "Potwierdź zamianę",
  CONNECT_WALLET: "Połącz portfel",
  COPIED: "Skopiowane",
  CURRENT: "Aktualny",
  CUSTOM: "Zwyczaj",
  DAYS: "Dni",
  DEFAULT: "Domyślny",
  DETAILS: "Detale",
  DISCLAIMER: "Zastrzeżenie",
  DISCONNECT_WALLET: "Odłącz portfel",
  DISMISS: "Odrzucać",
  DUPLICATE_ORDER_SUBMIT: "Duplikat kolejności. Ten jest już przesłany",
  ENTER_AMT: "Wprowadź ilość",
  EXCHANGE: "Giełda",
  EXPIRY: "WYGAŚNIĘCIE",
  EXPIRY_SM: "Wygaśnięcie",
  FAILED_LOAD_CHART: "Nie udało się załadować wykresu",
  FIND_BY_TOKEN_OR_STATUS: "Znajdź według tokenu lub statusu",
  INSUFFICIENT_BALANCE: "Niewystarczająca ilość środków",
  INSUFFICIENT: "Niewystarczający",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "saldo pokrycia istniejących zamówień równowagi",
  RECEIVERS: "Odbiornik",
  WALETADDRESS: "Adres portfela",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Proszę przyjąć warunki zasad usług i prywatności",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker nie powinien być tym samym adresem",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Tolerancja poślizgu powinna wynosić od 0,1% do 15%",
  SEARCH_A_TOKEN: "Wyszukaj token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Twoja transakcja powróci, jeśli cena nie byłaby niekorzystna o więcej, że ten odsetek",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Dostosowuje cenę gazu dla Twojej transakcji.Wyższa GWEI = wyższa prędkość.",
  REVIEW_OTC_ORDER: "Przejrzyj zamówienie OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Okres ważności ustalony przez producent zamówień.W tym okresie zamówienie wygasa i zostanie zarchiwizowane.",
  SEARCH_A_LIQUIDITY_SOURCES: "Wyszukaj źródła płynności",

  FAST: "Szybko",
  FAST_AND_SECURE:
    "Bezpieczny, szybki i najbezpieczniejszy sposób na przyniesienie aktywa",
  FEATURE_TO_TRANSFER:
    "Nie używaj tej funkcji, aby przenieść tokeny na adres scentralizowanej wymiany.",
  FEES: "Opłaty",
  FILL_ORDER: "Wypełnij kolejność",
  FILL_OTC_ORDER: "Wypełnij zamówienie OTC",
  FREE: "Bezpłatny",
  FROM: "Z",
  GAS_COST: "Koszt gazu",
  GAS_DESTINATION: "Gaz na łańcuchu docelowym",
  GAS_ON_DESTINATION: "Gaz na miejscu docelowym",
  GAS_PRICE: "Cena gazu",
  GOT_IT: "Rozumiem",
  GOT_PROBLEM_JUST: "Mam problem? Po prostu skontaktuj się.",
  HIGH: "Wysoki",
  HOURS: "godziny",
  IMPORT: "Import",
  IMPOSSIBLE_TO_RETRIEVE:
    "To nie jest funkcja obsługiwana. Ponadto, należy podłączyć wypełniony adres, wszelkie tokeny wysłane na niewłaściwy adres będą niemożliwe do odzyskania.",
  INSTANT: "Natychmiastowy",
  INSUFFICIENT_APPROVED_AMT: "Niewystarczająca zatwierdzona kwota",
  INVALID_TOKEN_ADDRESS: "Nieprawidłowy adres tokena",
  KEEP_TRACK_NOTIFY:
    "Możesz śledzić to w powiadomieniu panelu. Po zakończeniu powiadomimy cię.",
  KEEP_YOU_POSTED: ". Będziemy informować na bieżąco.",
  LEARN_MORE: "Ucz się więcej",
  LEGACY: "Dziedzictwo",
  LIMIT: "Limit",
  LIMIT_ORDER: "Ograniczenie ograniczenia",
  LIMIT_PRICE: "Cena limitu",
  LIMIT_PRICE_SM: "Limit ceny",
  LIQUIDITY_SOURCES: "Źródła płynności",
  LOW: "Niski",
  MAKER: "Producent",
  MARKET: "Rynek",
  MAX: "Max",
  MAX_BASE_FEE: "MAX BASE ROPE",
  MAX_S: "Max",
  MAX_TO_PAY: "Max do zapłaty",
  MINIMUM_RECEIVED: "Minimum otrzymane",
  MINUTES: "Minuty",
  MONTHS: "Miesiące",
  NEED_APPROVAL_MOVE: "Potrzebujemy Twojej zgody, aby się poruszyć",
  NEED_TO_WRAP: "Musisz owinąć",
  NETWORK_FEES: "Opłaty sieciowe",
  NETWORK_SM: "sieć.",
  NEVER: "Nigdy",
  NONE: "Nic",
  NON_CUSTODIAL_LIMIT:
    "w kolejności granicznej nieobsługowej. To wymaganie techniczne",
  NORMAL: "Normalna",
  NO_ORDERS_YET: "Nie masz jeszcze zamówień",
  NO_RECORDS: "Brak nagrań",
  ON_UR_BEHALF: "w Twoim imieniu.",
  OOPS_WRONG: "Ups! Coś poszło nie tak.!",
  OPEN: "otwarty",
  ORDER_DETAILS: "Szczegóły zamówienia",
  ORDER_HISTORY: "Historia zamówień",
  ORDER_PRICE: "Cena zamówienia",
  ORDER_ROUTING: "Zamów routing",
  OTC: "OTC",
  PASTE: "Pasta",
  PAY: "Płacić",
  PENDING_CONFIRM: "Oczekujące potwierdzenie portfela.",
  PER: "za",
  PLEASE_CHOOSE_NETWORK: "Wybierz sieć",
  PLEASE_LOGIN: "Zaloguj się do",
  PLEASE_TRY_LATER: "Spróbuj ponownie później",
  POPULAR_TOKENS: "Popularne tokeny",
  PRICE: "Cena",
  PRICE_IMPACT: "Wpływ ceny",
  PRIVACY_POLICY: "Polityka prywatności",
  RATE: "Wskaźnik",
  READ_ACCEPT: "Czytam i akceptuję",
  RECEIVE: "Odbierać",
  RECEIVED_ORDERS: "Otrzymane zamówienia",
  REFUND: "REFUNDACJA",
  REMOVE: "Usunąć",
  REVERSE_RATE: "Odwrotna stawka",
  REVIEW_TRADE: "Przegląd handlu",
  SELECTED: "wybrany",
  SELECT_ALL: "Zaznacz wszystko",
  SELECT_FROM_INFO: "Wybierz z informacji",
  SELECT_NETWORK: "Wybierz sieć",
  SELECT_TOKEN: "Wybierz token",
  SELECT_TOKENN: "Wybierz token",
  SELECT_TO_INFO: "Wybierz informacje",
  SETTINGS: "Ustawienia",
  SIMPLE_MODE: "Prosty tryb",
  SLIPPAGE: "Poślizg",
  SLIPPAGE_TOLERANCE: "Tolerancja poślizgu",
  SRY_INSUFFICIENT: "Przepraszam, niewystarczający",
  STATUS: "STATUS",
  STATUS_SM: "Status",
  STEP: "Krok",
  SUBMIT: "Składać",
  SUBMITTED_ORDERS: "Zgłoszone zamówienia",
  SUBMIT_DATE: "Prześlij datę",
  SUCCESS_FILLED: "Pomyślnie wypełnione zamówienie",
  SUCCESS_RECEIVED: "Pomyślnie otrzymane",
  SUCCESS_SUBMITTED_ORDER: "Pomyślnie złożył zamówienie",
  SWAP: "Zamieniać",
  SWAP_TRANSFER: "Zamiana i transfer",
  SWITCH_NETWORK: "Sieć przełącznika",
  TAKER: "Cheak",
  TAKER_ADDRESS: "Adres Taker",
  TERMS_OF_SERVICE: "Warunki usługi",
  TO: "Do",
  TOKEN_NOT_FOUND: "Token nie znaleziono",
  TOKEN_RECEIVE_SENT: "Token do otrzymania zostanie wysłany do",
  TRADE: "Handel",
  TRANSACTION_REJECTED_LATER: "Transakcja odrzucona, spróbuj ponownie później",
  TRANSFER: "Przenosić",
  TRANSFER_ALERT: "Alert transfer",
  TRANSFER_TO: "Przenieść do",
  TX_COST: "TX Koszt",
  UNLOCK: "Odblokować",
  USER_GUIDE: "Podręcznik użytkownika",
  USE_ITAS: "i użyj go jako W",
  VERIFY: "Zweryfikować",
  VIEW_EXPLORER: "Zobacz transakcję w Explorer",
  VIEW_ON: "Widok na",
  VIEW_TRANSACTION: "Zobacz transakcję",
  WRAP: "Zawinąć",
  YOUR_ADDRESS: "Twój adres",
  YOUR_ORDER_CANCEL: "Twoje zamówienie jest anulowane",
  YOUR_WALLET: "Twój portfel",
  YOU_GET: "Dostajesz",
  YOU_PAY: "Ty płacisz",
  YOU_RECEIVE: "Otrzymujesz",
  YOU_WILL_RECEIVE: "Otrzymacie",
  ANYONE_CAN_CREATE:
    "Każdy może stworzyć dowolny token, w tym fałszywe wersje istniejących tokenów. Zachowaj należytą ostrożność. Niektóre tokeny i ich parametry techniczne mogą być niezgodne z naszymi usługami. Importując ten niestandardowy token, potwierdzasz i przyjmujesz ryzyko.",
  BY_SIGNING_MESSAGE:
    "Podpisując tę ​​wiadomość, potwierdzasz, że nie uzyskujesz dostępu do tej aplikacji lub jesteś rezydentem USA lub innego ograniczonego kraju. Aby uzyskać więcej informacji, przeczytaj nasze",
  CONSTITUTION: "Constitutiondao",
  CURRENTLY_CONNECTED: "Jesteś obecnie związany z",
  IMPORT_TOKEN: "Zaimportuj token",
  IN_YOUR_WALLET: "w twoim portfelu",
  MAKE_SURE_CHECKED:
    "Upewnij się, że sprawdziłeś adres tokena i jest to poprawne",
  ON: "NA",
  PEOPLE: "Ludzie",
  PLEASE_SWITCH: "Proszę przełączyć się na",
  SELECTED_NOT_SUPPORTED:
    "Zmień sieć w portfelu, wybrana sieć nie jest obsługiwana",
  SIGN_FOR_ACCESS: "Podpisz dostęp do dostępu",
  SIGN_MESSAGE: "Podpisuj wiadomość",
  LAUNCH_APP: "Uruchom aplikację",
  GETTING_STARTED: "Pierwsze kroki z hodl: samouczki i wytłumacze produktów",
  DEEP_DIVE: "DeepDive w Hodl: Strategie i funkcje produktu",
  BUILD_HODL: "Budowanie na Hodl: Dokumentacja techniczna",
  BLOG_HEADING:
    "Demystifing Defi - wszystko, co musisz wiedzieć w jednym miejscu. Dla początkujących dla zaawansowanych użytkowników i wszystkich pomiędzy.",
  BLOG_PLACEHOLDER: "Wyszukaj produkt/temat, o którym chcesz się dowiedzieć",
  CHOOSE_TOPIC: "Wybierz temat",
  TRY: "Próbować",
  SEARCH_RESULTS: "Wyniki wyszukiwania",
  SEARCH_TOPIC: "Wyszukaj temat",
  LOAD_MORE: "Załaduj więcej",
  SOCIAL_MEDIA: "Media społecznościowe",
  NEW: "Najnowszy pierwszy",
  OLD: "Najpierw najstarsi",
  Z_A: "Sort tytuł Z-A",
  A_Z: "Sort Title A-Z",
  RESULTS: "Wyniki",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Podstawowa opłata gazowa jest automatycznie obliczana przez sieć i zmienia się w zależności od bloku. Ustaw maksymalną kwotę opłaty podstawowej, którą chcesz zapłacić. Różnica między ustawioną opłatą podstawową maksymalną a faktyczną opłatą podstawową zostanie zwrócona po wydobyciu bloku.",
  CANCELLED_TRANSACTION: "Anulował twoją transakcję",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Utworzenie zamówienia limitu jest bezpłatną operacją dla producenta zamówień, chyba że anulują. Zamówienie będzie zobowiązane do zapłaty za opłaty transakcyjne podczas wypełnienia zamówienia.",
  Cancelled: "Odwołany",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Różnica wartości między najlepszą trasą a",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Włącz tę opcję, jeśli masz problemy z zamianą tokena, który ma wbudowane opłaty za przeniesienie.",
  Expired: "Wygasły",
  Filled: "Wypełniony",
  INSUFFICIENT_FOR_GASFEE:
    "Przepraszam, niewystarczające fundusze na opłatę gazową",
  Open: "otwarty",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Częściowe wypełnienie obowiązuje tylko wtedy, gdy trasa zamiany składa się z wielu głównych części trasy. Umożliwia częściowe wykonanie, uniemożliwiając odwrócenie transakcji, jeśli cena rynkowa wyniesie korzyść.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Cena ustalona przez producent zamówień za wykonanie zamówienia (token do otrzymania = x token do zapłaty)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Cena ustalona przez producent zamówień za wykonanie zamówienia (token do zapłaty = y token do otrzymania",
  REVIEW_LIMIT_ORDER: "Recenzja Zamówienie limitu",
  SEARCH_BY_TOKEN: "Wyszukaj token lub adres",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Ustawianie wysokiej tolerancji poślizgu może pomóc w transakcjach, ale możesz nie uzyskać tak dobrej ceny. Używaj ostrożnie.",
  STATUS_OF_THE_LIMIT_ORDER: "Status zamówienia limitu",
  Suspended: "Zawieszony",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Opłaty te są płacone górnikom sieci. Hodl nie kontroluje opłat.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE: "Różnica między ceną rynkową a ceną est.",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Minimalna otrzymana kwota, w przeciwnym razie transakcja zostanie powrócona",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Opłata ta trafia bezpośrednio do górników jako zachęty do ustalenia priorytetu Twojej transakcji.",
  TRANSACTION_REJECTED_TRY: "Transakcja odrzucona, spróbuj ponownie",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Przeniesienie dla wybranego tokena nie jest dostępne w tej sieci",
  YOU: "Ty",
};
