import axios from "axios";

import { CoinGeckoClient } from 'coingecko-api-v3';

import config from "../config/config";
import { convert } from "../helper/convert";
import { getChartId, getCurrentGas } from "../helper/networkdata";
const baseUrl = config.baseUrl;

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS,GET'
}

const client = new CoinGeckoClient({
    timeout: 10000,
    autoRetry: true,
    headers: headers,
});

export const getRoutes = async (data) => {
    var amt = await convert(data.amount);
    // var excludeDEXS="";
    //console.log(amt, 'amtamtamtamtamtamt')
    var getDisable = localStorage.getItem("disId");
    var disabledDexIds = "";
    if (getDisable && getDisable !== "" && getDisable !== "null" && getDisable !== null) {
        disabledDexIds = getDisable;
    }

    var url = `https://apiv5.paraswap.io/prices?srcToken=${data.fromaddress}&destToken=${data.toaddress}&amount=${amt}&srcDecimals=${data.fromdecimal}&destDecimals=${data.todecimal}&side=${data.side}&network=${parseFloat(data.network)}&otherExchangePrices=true&partner=hodl&excludeDEXS=${disabledDexIds}&includeContractMethods=simpleSwap,multiSwap,directUniV3Swap`

    try {
        let respData = await axios({
            'method': 'get',
            'url': url
        });

        return {
            loading: true,
            error: "",
            result: (respData && respData.data && respData.data.priceRoute) ? respData.data.priceRoute : {},
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: (err && err.response && err.response.data && err.response.data.error) ? err.response.data.error : "",
            result: (err && err.response && err.response.data && err.response.data.priceRoute) ? err.response.data.priceRoute : {}
        }
    }
}

export const getUsdRate = async (symbol) => {

    var url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin,ethereum,matic-network`

    try {
        let respData = await axios({
            'method': 'get',
            'url': url
        });
        return {
            loading: false,
            result: (respData && respData.data) ? respData.data : 0
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getUsdRate')
        return {
            loading: false,
            error: returnErr(err),
            result: null
        }
    }

}

export const getChart = async (data) => {

    var id = (parseInt(data.network) === 56) ?
        "binance-smart-chain" : parseInt(data.network) === 137 ? "polygon-pos" : parseInt(data.network) === 1 ?
            "ethereum" : parseInt(data.network) === 250 ? "fantom" : parseInt(data.network) === 43114 ? "avalanche" : "binance-smart-chain"

    var initial = {
        prices: [],
        market_caps: [],
        total_volumes: []

    }
    try {

        var data1 = {
            id,
            contract_address: data.contract,
            vs_currency: "usd",
            from: data.from,
            to: data.to,

        }
        const respData = await client.contractMarketChartRange(data1);

        // let respData = await axios({
        //     'method': 'get',
        //     'url': url
        // });



        console.log(respData, 'getChartgetChart')
        return {
            loading: true,
            result: (respData && respData.length > 0) ? respData : initial
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: initial
        }
    }
}

export const getChart1 = async (data) => {
    var id = getChartId(data.network);
    var data1 = {
        id,
        contract_address: data.contract,
        vs_currency: "usd",
        from: data.from,
        to: data.to,

    }
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-chart`,
            'data': data1,
            'timeout': 10000
        });
        let prices = (respData && respData.data && respData.data.list)
            ? respData.data.list : []
        return {
            loading: false,
            result: {
                prices: prices
            }
        }
    }
    catch (err) {

        return {
            loading: false,
            error: returnErr(err),
            result: {
                prices: []
            }
        }
    }

}
export const saveswap = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-swap`,
            data: data
        });

        return {
            loading: true,

        }
    }
    catch (err) {
        console.log(err, 'errerrerr getRoutes')
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getCoinRate = async () => {
    let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin,arbitrum,optimism,fantom,avalanche-2,ethereum,matic-network,huobi-token,coinbase-wrapped-staked-eth`
    try {
        let respData = await axios({
            'method': 'get',
            'url': url
        });

        var rateDetails = [];
        if (respData.data && respData.data.length > 0) {
            for (var r = 0; r < respData.data.length; r++) {
                var chainId = 0;

                if (respData.data[r].id == "ethereum") {
                    chainId = 1;
                } else if (respData.data[r].id == "binancecoin") {
                    chainId = 56;
                } else if (respData.data[r].id == "matic-network") {
                    chainId = 137;
                } else if (respData.data[r].id == "avalanche-2") {
                    chainId = 43114;
                } else if (respData.data[r].id == "optimism") {
                    chainId = 10;
                } else if (respData.data[r].id == "arbitrum") {
                    chainId = 42161;
                } else if (respData.data[r].id == "fantom") {
                    chainId = 250;
                } else if (respData.data[r].id == "coinbase-wrapped-staked-eth") {
                    chainId = 8453;
                }

                rateDetails.push({
                    id: respData.data[r].id,
                    symbol: respData.data[r].symbol,
                    current_price: respData.data[r].current_price,
                    chainId: chainId
                });
            }
        }

        return {
            loading: true,
            error: "",
            result: rateDetails,
        }
    }
    catch (err) {

        return {
            loading: true,
            error: (err && err.response && err.response.data && err.response.data.error) ? err.response.data.error : "",
            result: []
        }
    }
}

export const getTokenRate = async (id, address) => {
    try {
        let url = `https://api.coingecko.com/api/v3/simple/token_price/${id}?contract_addresses=${address}&vs_currencies=usd`;

        let respData = await axios({
            'method': 'get',
            'url': url
        });

        var data = (respData && respData.data) ? respData.data : {};
        address = address.toLowerCase();
        var price = (data && data[address] && data[address].usd) ? data[address].usd : 0;

        return price;
    } catch (err) {
        console.log(err, 'getTokenRate err')
        return 0;
    }

}


function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


