import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import Countdown from "react-countdown";
import axios from "axios";

import { dropdownLogo, getTxLink } from "../../helper/networkdata";
import config from "../../config/config";
import { useTranslation } from "react-i18next";

var ZeroLayABI = [
  {
    inputs: [
      { internalType: "uint16", name: "_srcChainId", type: "uint16" },
      { internalType: "address", name: "_dstAddress", type: "address" },
      { internalType: "uint256", name: "_gasLimit", type: "uint256" },
      { internalType: "bytes32", name: "_blockHash", type: "bytes32" },
      { internalType: "bytes32", name: "_data", type: "bytes32" },
      { internalType: "bytes", name: "_transactionProof", type: "bytes" },
    ],
    name: "validateTransactionProofV1",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const abiDecoder = require("abi-decoder");
abiDecoder.addABI(ZeroLayABI);

export default function WalletConfirm(props) {

  const { t } = useTranslation();
  const bridgeToken = useSelector((state) => state.bridgeToken);
  const transfer = useSelector((state) => state.transfer);
  const [startissues, setstartissues] = React.useState(0);
  const [timer, settimer] = React.useState(270000);
  const [loopCnt, setloopCnt] = React.useState(1);
  const [txId, settxId] = React.useState("");
  const currblock = useSelector((state) => state.block);
  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    var timer = window.setInterval(async () => {
      if (txId == "") {
        try {
          var cId = parseInt(bridgeToken.tochainId)
            ? bridgeToken.tochainId
            : 56;
          var apiKey = config.gastrakerkey[cId];

          var search = `module=account&action=txlist&address=${config.zerolayer}&page=1&offset=20&sort=desc&apikey=${apiKey}`;
          var url = config.apiurl[cId] + search;
          let respData = await axios({
            method: "get",
            url: `${url}`,
          });
          var address = walletConnection.address;
          address = address.substring(2);
          address = address.toLowerCase();
          var data =
            respData && respData.data && respData.data.result
              ? respData.data.result
              : [];

          for (var t = 0; t < data.length; t++) {
            // console.log(data[t], 'data[t]')
            var input = data && data[t] && data[t].input ? data[t].input : "0x";
            var hash = data && data[t] && data[t].hash ? data[t].hash : "";
            console.log(hash, "hashhashhash");
            var trans_detail = await abiDecoder.decodeMethod(input);
            // console.log(trans_detail, 'trans_detail')
            var _transactionProof =
              trans_detail &&
                trans_detail.params &&
                trans_detail.params[5] &&
                trans_detail.params[5].value
                ? trans_detail.params[5].value.toLowerCase()
                : "";
            var pos = _transactionProof.search(address);
            console.log(pos, "pospospos11");
            if (pos >= 0) {
              var tx = getTxLink(cId) + "tx/" + hash;
              settxId(tx);
              window.clearInterval(timer);
              break;
            }
            //console.log(_transactionProof, '_transactionProof', address)
          }
        } catch (err) { }
      }
    }, 6000);
    return () => {
      window.clearInterval(timer);
    };
  }, []);
  console.log(txId, "txIdtxIdtxIdtxId");
  function getNextAutoTime() {
    if (loopCnt <= 6) {
      settimer(0);
      var cnt = loopCnt;
      setloopCnt(cnt++);
      var currDate = new Date();
      setstartissues(currDate);
      settimer(270000);
    } else {
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    var final = minutes + ":" + seconds;
    return <span>{final}</span>;
  };

  return (
    <div className="bridge_right">
      {/* Bridge Confirmation */}
      <div className="swap_box">
        <div className="heading_block">
          <span>{t('BRIDGE')}</span>
          <div>
            <i>
              <a className="" href="/bridge">
                <img
                  src={require("../../assets/images/close_icon.png")}
                  alt="Close"
                />
              </a>
            </i>
          </div>
        </div>
        <div className="bridge_confirmation">
          <div className="bridge_confirmation_row">
            {txId === "" && <p className="bridge_confirmation_time">4m 30s</p>}
            <div>
              <div>
                <img
                  src={dropdownLogo(
                    bridgeToken.fromchainId)}
                  alt="Close"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = config.defaultLogo;
                  }}
                  className="bridge_crypto"
                />
                <a
                  href={
                    getTxLink(bridgeToken.fromchainId) + "address/" + bridgeToken.fromaddress
                  }
                  target="_blank"
                >
                  <i>
                    <img
                      src={require("../../assets/images/newwindow.png")}
                      alt="Window"
                    />
                  </i>
                </a>
              </div>
              <div>
                <img
                  src={dropdownLogo(
                    bridgeToken.tochainId
                  )}
                  alt="Close"
                  className="bridge_crypto"
                />
                <a
                  href={
                    getTxLink(bridgeToken.tochainId) + "address/" + bridgeToken.toaddress
                  }
                  target="_blank"
                >
                  <i>
                    <img
                      src={require("../../assets/images/newwindow.png")}
                      alt="Window"
                    />
                  </i>
                </a>
              </div>
            </div>
            <p className="bridge_confirmation_fees">
              {transfer.receiveAmount} {bridgeToken.tosymbol}
            </p>
          </div>

          {txId === "" ? (
            <div>
              <p className="bridge_note">
                {t('ASSETS_ON_WAY')}{" "}
                <div>
                  {" "}
                  <Countdown
                    date={Date.now() + parseInt(timer)}
                    onComplete={() => getNextAutoTime()}
                    autoStart={true}
                    key={startissues}
                    renderer={renderer}
                  />
                  {t('KEEP_YOU_POSTED')}
                </div>
              </p>
              <p className="bridge_note">
                {t('KEEP_TRACK_NOTIFY')}
              </p>
            </div>
          ) : (
            <div>
              <p className="bridge_note success">
                <i className="far fa-check-circle d-block text-success checkIcon"></i>
                {t('SUCCESS_RECEIVED')}
                <a href={txId} target="_blank" className="d-block mt-3">
                  {" "}
                  <i className="fa fa-eye" aria-hidden="true"></i> {t('VIEW_TRANSACTION')}{" "}
                </a>
              </p>
            </div>
          )}
          <div className="text-center">
            <a className="connect_wallet_btn w-100 d-block" href="/bridge">
              {t('GOT_IT')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}