import BEP20ABI from "../../ABI/BEP20.json";

import { Multicall } from "ethereum-multicall";

import { getFormatMulticall, formatNumber } from "../../helper/custommath";
import { connection, readWeb3 } from "../../helper/connection";
import { dateFormat } from "../../helper/dateFormat";

export async function validateAllowanceBalance(
  swapToken,
  walletConnection,
  router
) {
  var initial = {
    fromBalanceOf: 0,
    fromAllowance: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toAllowance: 0,
    toBalance: 0,
    coinBalance: 0,
    allowance,
  };

  var address = walletConnection.address;
  try {
    if (address && address !== "") {
      var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0)
        ? parseInt(localStorage.getItem("network")) : (walletConnection.network > 0) ? walletConnection.network : 56;
      var web3 = readWeb3(chainVal);
      const multicall = new Multicall({
        web3Instance: web3,
      });

      const contractCallContext = [
        {
          reference: "fromDetails",
          contractAddress: swapToken.fromaddress,
          abi: BEP20ABI,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [address],
            },
            {
              reference: "allowance",
              methodName: "allowance",
              methodParameters: [address, router],
            },
            {
              reference: "decimals",
              methodName: "decimals",
              methodParameters: [],
            },
          ],
        },
      ];

      const results = await multicall.call(contractCallContext);

      var fromBalanceOf = await getFormatMulticall(results, "fromDetails", 0);

      fromBalanceOf =
        fromBalanceOf && fromBalanceOf.hex ? parseInt(fromBalanceOf.hex) : 0;
      var fromAllowance = await getFormatMulticall(results, "fromDetails", 1);
      fromAllowance =
        fromAllowance && fromAllowance.hex ? parseInt(fromAllowance.hex) : 0;
      var fromDecimals = await getFormatMulticall(results, "fromDetails", 2);

      var getBalance = await web3.eth.getBalance(address);
      var bal = getBalance / 10 ** 18;

      var allowance = fromAllowance / 10 ** fromDecimals;

      return {
        fromBalanceOf: fromBalanceOf,
        fromAllowance: fromAllowance,
        fromBalance: fromBalanceOf / 10 ** fromDecimals,
        coinBalance: bal,
        allowance,
      };
    } else {
      return initial;
    }
  } catch (err) {
    console.log(err, "errerrerrerrerr11111");
    return initial;
  }
}

export async function approveToken(token, spender, amount) {
  var get = await connection();
  try {
    if (get && get.web3) {
      var web3 = get.web3;
      var address = get.address;
      var Contract = new web3.eth.Contract(BEP20ABI, token);
      var result1 = await Contract.methods
        .approve(spender, amount)
        .send({ from: address });

      return {
        value: result1,
        status: true,
      };
    } else {
      return {
        value: {},
        status: false,
      };
    }
  } catch (err) {
    return {
      value: {},
      status: false,
    };
  }
}

export async function getUserBalances(
  swapToken,
  walletConnection,
  setusertokenBal
) {
  var initial = {
    fromBalanceOf: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toBalance: 0,
  };
  var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0)
    ? parseInt(localStorage.getItem("network")) : (walletConnection.network > 0) ? walletConnection.network : 56;
  var web3 = readWeb3(chainVal);
  var address = walletConnection.address;
  try {
    if (address && address !== "") {
      const multicall = new Multicall({
        web3Instance: web3,
      });

      if (
        swapToken.fromaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "fromDetails",
            contractAddress: swapToken.fromaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
          {
            reference: "toDetails",
            contractAddress: swapToken.toaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var fromBalanceOf = await getFormatMulticall(results, "fromDetails", 0);
        fromBalanceOf =
          fromBalanceOf && fromBalanceOf.hex ? parseInt(fromBalanceOf.hex) : 0;
        var fromDecimals = await getFormatMulticall(results, "fromDetails", 1);

        var toBalanceOf = await getFormatMulticall(results, "toDetails", 0);
        toBalanceOf =
          toBalanceOf && toBalanceOf.hex ? parseInt(toBalanceOf.hex) : 0;
        var toDecimals = await getFormatMulticall(results, "toDetails", 1);

        let balData = {
          fromBalanceOf: fromBalanceOf,
          fromBalance:
            fromBalanceOf > 0
              ? (fromBalanceOf / 10 ** fromDecimals).toFixed(4)
              : 0.0,
          toBalanceOf: toBalanceOf,
          toBalance:
            toBalanceOf > 0 ? (toBalanceOf / 10 ** toDecimals).toFixed(4) : 0.0,
        };
        setusertokenBal(balData);
        return;
      } else if (
        swapToken.fromaddress.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "toDetails",
            contractAddress: swapToken.toaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var toBalanceOf1 = await getFormatMulticall(results, "toDetails", 0);
        toBalanceOf1 =
          toBalanceOf1 && toBalanceOf1.hex ? parseInt(toBalanceOf1.hex) : 0;
        var toDecimals1 = await getFormatMulticall(results, "toDetails", 1);

        var getBalance = await web3.eth.getBalance(walletConnection.address);

        var bal = getBalance / 10 ** 18;

        let balData = {
          fromBalanceOf: getBalance,
          fromBalance: bal > 0 ? bal.toFixed(4) : 0.0,
          toBalanceOf: toBalanceOf1,
          toBalance:
            toBalanceOf1 > 0
              ? (toBalanceOf1 / 10 ** toDecimals1).toFixed(4)
              : 0.0,
        };

        setusertokenBal(balData);
      } else if (
        swapToken.fromaddress.toLowerCase() !==
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" &&
        swapToken.toaddress.toLowerCase() ===
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        const contractCallContext = [
          {
            reference: "fromDetails",
            contractAddress: swapToken.fromaddress,
            abi: BEP20ABI,
            calls: [
              {
                reference: "balanceOf",
                methodName: "balanceOf",
                methodParameters: [address],
              },
              {
                reference: "decimals",
                methodName: "decimals",
                methodParameters: [],
              },
            ],
          },
        ];

        const results = await multicall.call(contractCallContext);

        var fromBalanceOf1 = await getFormatMulticall(
          results,
          "fromDetails",
          0
        );
        fromBalanceOf1 =
          fromBalanceOf1 && fromBalanceOf1.hex
            ? parseInt(fromBalanceOf1.hex)
            : 0;
        var fromDecimals1 = await getFormatMulticall(results, "fromDetails", 1);

        var getBalance = await web3.eth.getBalance(walletConnection.address);
        var bal = getBalance / 10 ** 18;

        let balData = {
          fromBalanceOf: fromBalanceOf1,
          fromBalance:
            fromBalanceOf1 > 0
              ? (fromBalanceOf1 / 10 ** fromDecimals1).toFixed(4)
              : 0.0,
          toBalanceOf: getBalance,
          toBalance: getBalance > 0 ? (getBalance / 10 ** 18).toFixed(4) : 0,
        };
        setusertokenBal(balData);
      }
    } else {
      setusertokenBal(initial);
      return initial;
    }
  } catch (err) {
    setusertokenBal(initial);
    console.log(err, "getUserBalances err");
    return initial;
  }
}

export function limitOrderExpireOptions() {
  let expire = [
    {
      value: "Never",
      label: "Never",
    },
    {
      value: "10",
      label: "Minutes",
    },
    {
      value: "10",
      label: "Hours",
    },
    {
      value: "1",
      label: "Days",
    },
    {
      value: "1",
      label: "Months",
    },
  ];
  return expire;
}

export function limitOrders(list) {
  try {
    if (list.length == 0) {
      return [];
    }
    var newList = [];
    for (let o = 0; o < list.length; o++) {
      var makerAmt =
        list && list[o] && list[o].data && list[o].data.makingAmount
          ? list[o].data.makingAmount
          : 0;
      let makerDeci =
        list && list[o] && list[o].data && list[o].data.makerAssetDecimals
          ? list[o].data.makerAssetDecimals
          : 0;
      makerAmt = makerAmt / 10 ** makerDeci;
      let makerAsset =
        list && list[o] && list[o].data && list[o].data.makerAsset
          ? list[o].data.makerAsset
          : "";
      let makerAssetSymbol =
        list && list[o] && list[o].data && list[o].data.makerAssetSymbol
          ? list[o].data.makerAssetSymbol
          : "";
      let makerAssetIcon =
        list && list[o] && list[o].data && list[o].data.makerAssetIcon
          ? list[o].data.makerAssetIcon
          : "";

      var takerAmt =
        list && list[o] && list[o].data && list[o].data.takingAmount
          ? list[o].data.takingAmount
          : 0;
      let takerDeci =
        list && list[o] && list[o].data && list[o].data.takerAssetDecimals
          ? list[o].data.takerAssetDecimals
          : 0;
      takerAmt = takerAmt / 10 ** takerDeci;

      let takerAsset =
        list && list[o] && list[o].data && list[o].data.takerAsset
          ? list[o].data.takerAsset
          : "";
      let takerAssetSymbol =
        list && list[o] && list[o].data && list[o].data.takerAssetSymbol
          ? list[o].data.takerAssetSymbol
          : "";
      let takerAssetIcon =
        list && list[o] && list[o].data && list[o].data.takerAssetIcon
          ? list[o].data.takerAssetIcon
          : "";

      var rate = takerAmt / makerAmt;

      var date =
        list && list[o] && list[o].expireTime
          ? dateFormat(list[o].expireTime, "DD-MM-YYYY")
          : null;

      var time =
        list && list[o] && list[o].expireTime
          ? dateFormat(list[o].expireTime, "hh:mm:i")
          : null;

      var status = list && list[o] && list[o].statuses ? list[o].statuses : 0;
      var statusTxt = "";
      if (status == 1) {
        statusTxt = "Active";
      } else if (status == 3) {
        statusTxt = "Cancelled";
      }

      newList.push({
        makerAmt: formatNumber(makerAmt, 6),
        makerAsset: makerAsset,
        makerAssetSymbol: makerAssetSymbol,
        makerAssetIcon: makerAssetIcon,
        takerAmt: formatNumber(takerAmt, 6),
        takerAsset: takerAsset,
        takerAssetSymbol: takerAssetSymbol,
        takerAssetIcon: takerAssetIcon,
        rate: formatNumber(rate, 6),
        date: date,
        time: time,
        statusTxt: statusTxt,
      });
    }

    return newList.reverse();
  } catch (err) {
    console.log(err, "errerrerrerr");
    return [];
  }
}

export function getOrderStatus(status) {
  try {
    // var status = items.state;
    var className = "order_open";
    if (status == "CANCELLED") {
      status = "Cancelled";
      className = "order_cancel";
    } else if (status == "FULFILLED") {
      status = "Filled";
      className = "order_filled";
    } else if (status == "EXPIRED") {
      status = "Expired";
      className = "order_expired";
    } else if (status == "SUSPENDED") {
      status = "Suspended";
      className = "order_cancel";
    } else if (status == "PENDING") {
      status = "Open";
      className = "order_open";
    }
    return {
      status,
      className,
    };
  } catch (err) {
    console.log(err, "getOrderStatusgetOrderStatus errr");
    return {
      status,
      className: "order_open",
    };
  }
}
