// import constant
import {
    BRIDGETOKEN
} from '../constant';

const initialState = {
    fromaddress: "",
    fromsymbol: "",
    fromlogo: "",
    fromname: "",
    frombridgeId: 0,
    fromchainId: 0,
    frompoolId: 0,
    fromdecimals: 0,
    frompool: "",
    toaddress: "",
    tosymbol: "",
    tologo: "",
    toname: "",
    tobridgeId: 0,
    tochainId: 0,
    topoolId: 0,
    todecimals: 0,
    topool: "",
};

const bridgeToken = (state = initialState, action) => {

    switch (action.type) {
        case BRIDGETOKEN:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default bridgeToken;