// import constant
import {
    TRANSFER
} from '../constant';

const initialState = {
    amount: "",
    receiveAmount: 0,
    GasonDestination: 0,
    GasonCost: 0
};

const transferValue = (state = initialState, action) => {


    switch (action.type) {
        case TRANSFER:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default transferValue;