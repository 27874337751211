import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactLoading from "react-loading";
import {
  dropdownLogo,
  getCoinName,
  getNetworkName,
} from "../../helper/networkdata";
import { convertWrapped } from "../../ContractActions/limitorderActions";
import { approve, increaseAllowance } from "../../ContractActions/bep20Actions";
import { toastAlert } from "../../helper/toastAlert"
import { useTranslation } from "react-i18next";
import { checkNetwork } from "../../helper/connection"
import config from "../../config/config";

import {
  formatNumber
} from "../../helper/custommath";

var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

var initial = {
  pay: 0,
  fromsymbol: "",
  fromaddress: "",
  fromdecimal: 0
}

export default function UnlockTokenModal(props) {
  let { OrderDetails, getLimitPriceData1, spender, alreadyApproved, totalApproved } = props;
  const { t } = useTranslation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loading, setloading] = useState(false);
  const [tokenName, settokenName] = useState("");
  const [orderInfos, setorderInfos] = useState(initial);

  useEffect(() => {
    getTokenName(OrderDetails);
  }, [OrderDetails]);

  function getTokenName(OrderDetails) {
    if (OrderDetails) {
      setorderInfos(OrderDetails)
      if (OrderDetails && OrderDetails.fromaddress) {
        var cName = getCoinName(walletConnection.network);
        var coinSym =
          OrderDetails.fromaddress.toLowerCase() === coinAddress
            ? "W" + cName
            : OrderDetails.fromsymbol;
        settokenName(coinSym);
      }
    }

  }

  async function triggerApprove() {
    setloading(true);

    //getLimitPriceData1(orderInfos.pay);
    try {

      var { isNetwork, networkname } = await checkNetwork();
      if (!isNetwork) {
        setloading(false);
        toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
        return;
      }

      var nwrk = walletConnection.network;
      //var spender = config.limitorder[nwrk];
      var coinAddr =
        orderInfos.fromaddress.toLowerCase() === coinAddress &&
          config &&
          config.coin &&
          config.coin[nwrk]
          ? config.coin[nwrk]
          : orderInfos.fromaddress;


      alreadyApproved = parseFloat(alreadyApproved);

      var totalApproved = parseFloat(orderInfos.pay) + parseFloat(alreadyApproved);

      // if (alreadyApproved > 0) {
      //   var { status, approvalAmt, error } = await increaseAllowance(coinAddr, spender);
      // } else {
      var { status, approvalAmt, error } = await approve(coinAddr, spender);
      // }


      var deci = parseFloat(orderInfos.fromdecimal)
      var chkAmt = parseFloat(approvalAmt) / 10 ** deci;

      if (error) {
        toastAlert("error", t(error), "approval");
        setloading(false);
        return;
      } else if (totalApproved > chkAmt) {
        toastAlert("error", t('INSUFFICIENT_APPROVED_AMT'), "approval");
        setloading(false);
        return;
      }

      if (status) {
        getLimitPriceData1(orderInfos.pay, orderInfos.rate);
        window.$("#confirm_approve_modal").modal("hide");
      }
      setloading(false);
    } catch (err) {
      var errMsg = (err && err.message) ? err.message : err;
      var pos = errMsg.search("User denied");
      var msg = "Please try again later";
      if (pos >= 0) {
        msg = "Confirmation rejected";
      }
      toastAlert("error", t(msg), "approval");
      console.log(err, "errerrerrerr");
      setloading(false);
    }
  }


  return (
    <>
      <div
        className="modal fade primary_modal"
        id="confirm_approve_modal"
        tabIndex="-1"
        aria-labelledby="confirm_approve_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <img
                src={require(`../../assets/images/unlocked.png`)}
                alt="Unlock" width="80" className="mb-3"
              />
              <h5>{t('UNLOCK')} {tokenName}</h5>
              <p>
                {t('NEED_APPROVAL_MOVE')} {(totalApproved && totalApproved) ? formatNumber(totalApproved, 8) : 0} {tokenName}{" "}
                {t('ON_UR_BEHALF')}
              </p>
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => triggerApprove()}
                  disabled={loading}
                >
                  {t('UNLOCK')} {tokenName}
                  {loading && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
