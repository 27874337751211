import React, { useEffect, useState } from "react";
import FooterLogo from "../assets/images/footer_logo.svg";
import { getfooterCms, getfooterLink } from "../Api/BlogActions"
import { useNavigate } from "react-router-dom";
import config from "../config/config"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHomepageText } from "../Api/HomeActions.js";
export default function Footer(props) {
  const { t, i18n } = useTranslation();
  const [footerdata, setFooterdata] = useState([]);
  const [cmsdata, setCmsdata] = useState([]);
  const [footerLink, setFooterlink] = useState([]);
  const langcode = useSelector((state) => state.lang);
  const [textData, setTextData] = useState();
  async function getfootercms() {
    var data = {
      lancode: langcode && langcode.lancode ? langcode.lancode : "en"
    }
    var { result, footerresult } = await getfooterCms(data);

    setFooterdata(footerresult)
    setCmsdata(result)


  }
  async function getfooterlink() {

    var { result } = await getfooterLink();
    setFooterlink(result)


  }
  useEffect(() => {

    getfooterlink()

  }, [])
  useEffect(() => {

    getfootercms()

  }, [langcode.lancode])
  useEffect(() => {
    const gettingValues = async () => {

      var data = {
        lancode: langcode && langcode.lancode ? langcode.lancode : "en"

      }
      const result = await getHomepageText(data);
      setTextData(result.result);

    };
    gettingValues();
  }, [langcode.lancode]);
  async function handleClick(subcategory) {
    console.log(cmsdata, 'cmsdata', subcategory)
    const datas = cmsdata && cmsdata.length > 0 && cmsdata.find((item) => item.subcategoryname == subcategory)
    console.log(datas, 'datas')
    if (datas && datas.linkstatus == true) {
      window.location.href = datas.link
    }
    if (datas && datas.linkstatus == false) {
      //history(datas.identifier)
      window.location.href = datas.identifier;
    }
  }
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">

          {footerdata && footerdata.length > 0 &&
            footerdata.map((items, i) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <h2>{items.categoryname}</h2>

                  <ul className="footer_list_links">
                    {items && items.category &&
                      items.category.map((item, i) => {

                        return (
                          <li>
                            <a onClick={() => handleClick(item.subcategory)}>{item.subcategory}</a>
                          </li>
                        )
                      })
                    }


                  </ul>
                </div>
              );
            })}

          {/* <div className="col-lg-3 col-md-6 col-sm-6">
            <h2>Investing & Trading</h2>
            <ul className="footer_list_links">
              <li>
                <a href="/#">Business accounts</a>
              </li>
              <li>
                <a href="/#">Token listing</a>
              </li>
              <li>
                <a href="/#">API</a>
              </li>
              <li>
                <a href="/#">Fee Schedule</a>
              </li>
              <li>
                <a href="/#">Security</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <h2>Support</h2>
            <ul className="footer_list_links">
              <li>
                <a href="/faq">FAQ</a>
              </li>
              <li>
                <a href="/#">Help Center</a>
              </li>
              <li>
                <a href="/#">Contact us</a>
              </li>
            </ul>
          </div> */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            {footerLink && footerLink.logoURI && <img src={`${config.imageUrl + '/logo/' + footerLink.logoURI
              }`} alt="Logo" width="190" />}
            <h2 className="mt-4">{t("SOCIAL_MEDIA")}</h2>
            <ul className="footer_social_links">
              {footerLink && footerLink.telegram && footerLink.telegram != "" &&
                <li>
                  <a href={`${footerLink.telegram}`} target="_blank">
                    <i className="fab fa-telegram-plane"></i>
                  </a>
                </li>
              }
              {footerLink && footerLink.twitter && footerLink.twitter != "" &&
                <li>
                  <a href={`${footerLink.twitter}`} target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              }
              {footerLink && footerLink.linkedin && footerLink.linkedin != "" &&
                <li>
                  <a href={`${footerLink.linkedin}`} target="_blank">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              }
              {footerLink && footerLink.facebook && footerLink.facebook != "" &&
                <li>
                  <a href={`${footerLink.facebook}`} target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              }
              {footerLink && footerLink.youtube && footerLink.youtube != "" &&
                <li>
                  <a href={`${footerLink.youtube}`} target="_blank">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              }
            </ul>
          </div>
          <div className="col-12 text-center mb-0">
            <p className="copyright_txt">
              {/* © Copyright {new Date().getFullYear()} by HODL INTERNATIONAL. All
              rights Reserved. */}
              {textData && textData.copyrighttext}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
