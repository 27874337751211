export default {
  ABOUT_NEW_FEATURES: "yeni özellikler hakkında.",
  ACCEPT: "Kabul etmek",
  ACTIVE_ORDERS: "Aktif Siparişler",
  ADD_TOKEN: "Bir jeton ekle",
  AGGRESSIVE: "Saldırgan",
  AND: "Ve",
  APPROVE: "Onaylamak",
  ASSETS_ON_WAY: "Varlıklarınız yolda ve gelmeli",
  AUTO: "Oto",
  AVOID_TRANSFER:
    "Lütfen merkezi bir değişim adresine (Binance, Huobi ...) transfer etmekten kaçının. Desteklenen bir özellik değil",
  BALANCE: "Denge",
  BALANCE_ONE: "Denge:",
  BLOG: "OTC",
  BRIDGE: "Köprü",
  CANCEL: "İptal etmek",
  CANCELLATION_TRANSACTION_FEE:
    "Sınır siparişinin iptali, işlem ücretlerinin ödenmesini içerir.",
  CANCEL_ALL: "Hepsini iptal et",
  CANCEL_LIMIT_NOT_FILLED:
    "Sipariş henüz doldurulmadığı sürece bir limit siparişini iptal edebilirsiniz.",
  CANCEL_LIMIT_ORDER: "Sınır Siparişini İptal Et",
  CANCEL_ORDER: "Siparişi iptal et",
  CANCEL_SELECTED: "Seçili iptal et",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Ağ'ı seçin",
  CLASSIC_MODE: "Klasik Mod",
  CLEAR: "Temizlemek",
  CONFIRM: "Onaylamak",
  CONFIRMATION_REJECTED: "Onay reddedildi.!",
  CONFIRM_SWAP: "Takas onaylayın",
  CONNECT_WALLET: "Cüzdan Bağlayın",
  COPIED: "Kopyalanmış",
  CURRENT: "Akım",
  CUSTOM: "Gelenek",
  DAYS: "Günler",
  DEFAULT: "Varsayılan",
  DETAILS: "Detaylar",
  DISCLAIMER: "Feragatname",
  DISCONNECT_WALLET: "Cüzdanı Bağlayın",
  DISMISS: "Azletmek",
  DUPLICATE_ORDER_SUBMIT: "Tekrarlanan sipariş. Bu zaten gönderildi",
  ENTER_AMT: "Miktarı girin",
  EXCHANGE: "Değişme",
  EXPIRY: "Sona erme",
  EXPIRY_SM: "Sona erme",
  FAILED_LOAD_CHART: "Grafik yüklenemedi",
  FIND_BY_TOKEN_OR_STATUS: "Jeton veya durumla bulun",
  INSUFFICIENT_BALANCE: "Yetersiz bakiye",
  INSUFFICIENT: "Yetersiz",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "mevcut sipariş dengesini kapsayacak bakiye",
  RECEIVERS: "Alıcı",
  WALETADDRESS: "Cüzdan adresi",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Lütfen Hizmet Şartları ve Gizlilik Politikası kabul edin",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker aynı alıcı adresi olmamalı",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Kayma toleransı% 0.1 ile% 15 arasında olmalıdır",
  SEARCH_A_TOKEN: "Bir jeton arayın",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Fiyat, bu yüzdeden daha fazla olumsuz değişiyorsa işleminiz geri dönecektir",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "İşleminiz için gaz fiyatını ayarlar.Daha yüksek GWEI = daha yüksek hız.",
  REVIEW_OTC_ORDER: "OTC siparişini inceleyin",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Sipariş Maker tarafından belirlenen son kullanma süresi.Bu dönemden sonra, sipariş süresi dolacak ve arşivlenecektir.",
  SEARCH_A_LIQUIDITY_SOURCES: "Bir likidite kaynaklarını arayın",

  FAST: "Hızlı",
  FAST_AND_SECURE:
    "Çapraz zincir varlıklarını getirmenin güvenli, hızlı ve en güvenli yolu",
  FEATURE_TO_TRANSFER:
    "Lütfen jetonlarınızı merkezi bir değişim adresine aktarmak için bu özelliği kullanmayın.",
  FEES: "Ücret",
  FILL_ORDER: "Doldurma Siparişi",
  FILL_OTC_ORDER: "OTC siparişini doldurun",
  FREE: "Özgür",
  FROM: "İtibaren",
  GAS_COST: "Gaz maliyeti",
  GAS_DESTINATION: "Hedef zincirde gaz",
  GAS_ON_DESTINATION: "Hedefte Gaz",
  GAS_PRICE: "Gaz fiyatı",
  GOT_IT: "Anladım",
  GOT_PROBLEM_JUST: "Sorun var? Sadece temasa geç.",
  HIGH: "Yüksek",
  HOURS: "Saat",
  IMPORT: "İçe aktarmak",
  IMPOSSIBLE_TO_RETRIEVE:
    "Desteklenen bir özellik değildir. Ayrıca, lütfen doldurduğunuz adresi iki kez kontrol edin, yanlış adrese gönderilen jetonların alınması imkansız olacaktır.",
  INSTANT: "Ani",
  INSUFFICIENT_APPROVED_AMT: "Yetersiz onaylanmış miktar",
  INVALID_TOKEN_ADDRESS: "Geçersiz jeton adresi",
  KEEP_TRACK_NOTIFY:
    "Panel bildiriminizde üzerinde takip edebilirsiniz. Bittikten sonra sizi bilgilendireceğiz.",
  KEEP_YOU_POSTED: ". Sana haber vereceğiz.",
  LEARN_MORE: "Daha fazla bilgi edin",
  LEGACY: "Miras",
  LIMIT: "Sınırlamak",
  LIMIT_ORDER: "Sınır Siparişi",
  LIMIT_PRICE: "Sınırlama fiyatı",
  LIMIT_PRICE_SM: "Sınırlama fiyatı",
  LIQUIDITY_SOURCES: "Likidite kaynakları",
  LOW: "Düşük",
  MAKER: "Yapımcı",
  MARKET: "Pazar",
  MAX: "Maksimum",
  MAX_BASE_FEE: "Maksimum Base Ücreti",
  MAX_S: "maksimum",
  MAX_TO_PAY: "Max Ödeyecek",
  MINIMUM_RECEIVED: "Minimum alındı",
  MINUTES: "Dakikalar",
  MONTHS: "Aylar",
  NEED_APPROVAL_MOVE: "Taşımak için onayınıza ihtiyacımız var",
  NEED_TO_WRAP: "Sarmalısın",
  NETWORK_FEES: "Ağ ücretleri",
  NETWORK_SM: "ağ.",
  NEVER: "Asla",
  NONE: "Hiçbiri",
  NON_CUSTODIAL_LIMIT: "gözlemsiz sınır sırasında. Bu teknik bir gereklilik",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Henüz siparişin yok",
  NO_RECORDS: "Kayıt yok",
  ON_UR_BEHALF: "sizin adınıza.",
  OOPS_WRONG: "Hoop! Birşeyler yanlış gitti.!",
  OPEN: "Açık",
  ORDER_DETAILS: "sipariş detayları",
  ORDER_HISTORY: "Sipariş Geçmişi",
  ORDER_PRICE: "Sipariş fiyatı",
  ORDER_ROUTING: "Sipariş Yönlendirme",
  OTC: "OTC",
  PASTE: "Yapıştırmak",
  PAY: "Ödemek",
  PENDING_CONFIRM: "Cüzdanda onay beklemek.",
  PER: "başına",
  PLEASE_CHOOSE_NETWORK: "Lütfen Ağ'ı seçin",
  PLEASE_LOGIN: "Lütfen giriş yapın",
  PLEASE_TRY_LATER: "Lütfen daha sonra tekrar deneyiniz",
  POPULAR_TOKENS: "Popüler jetonlar",
  PRICE: "Fiyat",
  PRICE_IMPACT: "Fiyat etkisi",
  PRIVACY_POLICY: "Gizlilik Politikası",
  RATE: "Oran",
  READ_ACCEPT: "Okudum ve kabul ediyorum",
  RECEIVE: "Almak",
  RECEIVED_ORDERS: "Sipariş Aldı",
  REFUND: "GERİ ÖDEMEK",
  REMOVE: "Kaldırmak",
  REVERSE_RATE: "Ters hız",
  REVIEW_TRADE: "Ticareti Gözden Geçirme",
  SELECTED: "seçilmiş",
  SELECT_ALL: "Hepsini seç",
  SELECT_FROM_INFO: "Lütfen bilgi arasından seçim yapın",
  SELECT_NETWORK: "Bir Ağ Seçin",
  SELECT_TOKEN: "Bir jeton seçin",
  SELECT_TOKENN: "Token'i seçin",
  SELECT_TO_INFO: "Lütfen bilgi seçin",
  SETTINGS: "Ayarlar",
  SIMPLE_MODE: "Basit mod",
  SLIPPAGE: "Kayma",
  SLIPPAGE_TOLERANCE: "Kayma toleransı",
  SRY_INSUFFICIENT: "Üzgünüm, yetersiz",
  STATUS: "DURUM",
  STATUS_SM: "Durum",
  STEP: "Adım",
  SUBMIT: "Göndermek",
  SUBMITTED_ORDERS: "Gönderilen siparişler",
  SUBMIT_DATE: "Gönder tarihi",
  SUCCESS_FILLED: "Başarılı bir şekilde sipariş dolu",
  SUCCESS_RECEIVED: "Başarıyla alındı",
  SUCCESS_SUBMITTED_ORDER: "Siparişinizi başarıyla gönderdi",
  SWAP: "Takas",
  SWAP_TRANSFER: "Takas ve Transfer",
  SWITCH_NETWORK: "Anahtar Ağı",
  TAKER: "Alıcı",
  TAKER_ADDRESS: "Taker adresi",
  TERMS_OF_SERVICE: "Kullanım Şartları",
  TO: "İle",
  TOKEN_NOT_FOUND: "Jeton bulunamadı",
  TOKEN_RECEIVE_SENT: "Alınacak jeton gönderilecek",
  TRADE: "Ticaret",
  TRANSACTION_REJECTED_LATER:
    "İşlem reddedildi lütfen daha sonra tekrar deneyin",
  TRANSFER: "Aktar",
  TRANSFER_ALERT: "Aktarma uyarısı",
  TRANSFER_TO: "Transfer",
  TX_COST: "TX Maliyeti",
  UNLOCK: "Kilidini aç",
  USER_GUIDE: "Kullanici rehberi",
  USE_ITAS: "ve w olarak kullan",
  VERIFY: "Doğrulamak",
  VIEW_EXPLORER: "Explorer'da İşlemi Görüntüle",
  VIEW_ON: "Görüşmek",
  VIEW_TRANSACTION: "İşlemi Görüntüle",
  WRAP: "Dürüm",
  YOUR_ADDRESS: "Senin adresin",
  YOUR_ORDER_CANCEL: "Siparişiniz iptal edildi",
  YOUR_WALLET: "Cüzdanın",
  YOU_GET: "Alırsın",
  YOU_PAY: "Öde",
  YOU_RECEIVE: "Aldığın",
  YOU_WILL_RECEIVE: "Alacaksın",
  ANYONE_CAN_CREATE:
    "Mevcut jetonların sahte versiyonları da dahil olmak üzere herkes jeton oluşturabilir. Dikkat edin. Bazı jetonlar ve bunların teknik parametreleri hizmetlerimizle uyumsuz olabilir. Bu özel jetonu içe aktararak riskleri kabul eder ve kabul edersiniz.",
  BY_SIGNING_MESSAGE:
    "Bu mesajı imzalayarak, bu uygulamaya erişmediğinizi veya ABD veya başka bir kısıtlı ülkenin sakini olduğunuzu onaylarsınız. Daha fazla bilgi için lütfen bizim",
  CONSTITUTION: "Anayasa",
  CURRENTLY_CONNECTED: "Şu anda bağlantısınız",
  IMPORT_TOKEN: "Bir jetonu içe aktarın",
  IN_YOUR_WALLET: "Cüzdanında",
  MAKE_SURE_CHECKED: "Jeton adresini kontrol ettiğinizden emin olun ve doğru",
  ON: "Açık",
  PEOPLE: "İnsanlar",
  PLEASE_SWITCH: "Lütfen geçiş yapın",
  SELECTED_NOT_SUPPORTED:
    "Lütfen cüzdanınızdaki ağı değiştirin, seçilen ağ desteklenmez",
  SIGN_FOR_ACCESS: "Erişim için İşaret",
  SIGN_MESSAGE: "İşaret mesajı",
  LAUNCH_APP: "Uygulamayı başlat",
  GETTING_STARTED: "HODL ile Başlamak: Öğreticiler ve Ürün Açıklayıcıları",
  DEEP_DIVE: "Hodl'a Deepdive: Stratejiler ve Ürün Özellikleri",
  BUILD_HODL: "HODL üzerine bina: Teknik Belgeler",
  BLOG_HEADING:
    "Demystricing Defi - Bilmeniz gereken her şey tek bir yerde. Yeni başlayanlar için gelişmiş kullanıcılar ve aradaki herkes için.",
  BLOG_PLACEHOLDER: "Öğrenmek istediğiniz ürünü/konuyu arayın",
  CHOOSE_TOPIC: "Konu seçin",
  TRY: "Denemek",
  SEARCH_RESULTS: "Arama Sonuçları",
  SEARCH_TOPIC: "Arama Konusu",
  LOAD_MORE: "Daha fazla yükle",
  SOCIAL_MEDIA: "Sosyal medya",
  NEW: "En yeni ilk",
  OLD: "En eski ilk",
  Z_A: "Başlık Z-A",
  A_Z: "A-Z başlığı sıralayın",
  RESULTS: "Sonuçlar",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Temel gaz ücreti ağ tarafından otomatik olarak hesaplanır ve bloktan bloğa değişir. Ödemek istediğiniz temel ücretin maksimum miktarını belirleyin. Belirttiğiniz maksimum taban ücreti ile gerçek taban ücreti arasında bir fark, blok çıkarıldıktan sonra iade edilecektir.",
  CANCELLED_TRANSACTION: "İşleminizi iptal etti",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Bir limit siparişi oluşturmak, iptal etmedikçe sipariş üreticisi için ücretsiz bir işlemdir. Sipariş alıcısının siparişi doldururken işlem ücretlerini ödemesi gerekecektir.",
  Cancelled: "İptal edildi",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "En iyi rota arasındaki değer farkı ve",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Aktarmak için yerleşik ücretleri olan bir jetonu takas etme sorunları varsa bu seçeneği etkinleştirin.",
  Expired: "Günü geçmiş",
  Filled: "Dolu",
  INSUFFICIENT_FOR_GASFEE: "Üzgünüm, gaz ücreti için yetersiz fon",
  Open: "Açık",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Kısmi dolgu yalnızca bir takas yolu birden fazla ana yol parçasından oluşuyorsa geçerlidir. Piyasa fiyatı lehine düşerse, işlemin tersine çevrilmesini önleyerek kısmi yürütmeye izin verir.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Sipariş Maker tarafından siparişlerini yürütmek için belirlenen fiyat (jeton almak için jeton = x jeton ödemek için)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Sipariş Maker tarafından siparişlerini yürütmek için belirlenen fiyat (Pays için jeton = y jetonu almak",
  REVIEW_LIMIT_ORDER: "Sınır Siparişini İnceleyin",
  SEARCH_BY_TOKEN: "Jeton veya adrese göre arama",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Yüksek bir kayma toleransı belirlemek, işlemlerin başarılı olmasına yardımcı olabilir, ancak bu kadar iyi bir fiyat alamayabilirsiniz. Dikkatle kullanın.",
  STATUS_OF_THE_LIMIT_ORDER: "Sınır Siparişinin Durumu",
  Suspended: "Askıya alınmış",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Bu ücretler ağ madencilerine ödenir. HODL ücretleri kontrol etmeyin.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Ticaret büyüklüğü nedeniyle piyasa fiyatı ve tahmini fiyat arasındaki fark",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Alınan minimum miktar, aksi takdirde işlem geri dönecek",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Bu ücret, işleminize öncelik vermek için bir teşvik olarak doğrudan madencilere gider.",
  TRANSACTION_REJECTED_TRY: "İşlem reddedildi, lütfen tekrar deneyin",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Seçilen jeton için aktarım bu ağda mevcut değil",
  YOU: "Sen",
};
