import React from "react";
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";

import i18n from "./components/i18next/i18n";
import { I18nextProvider } from "react-i18next";

import { Provider } from "react-redux";
import store from "./store";

import Home from "./pages/home.js";
//import SwapSimple from "./pages/swap-simple";
import SwapClassic from "./pages/swapClassic/swap-classic";
import SwapSimple from "./pages/swapSimple/swap-simple";
import Bridge from "./pages/bridge/bridge";
import Swaplimit from "./pages/swapLimit/swap-limit";
import Blog from "./pages/blog.js";
import BlogDetail from "./pages/blog-detail.js";
import BlogSearch from "./pages/blog-search.js";
import BlogCategory from "./pages/blog-category.js"
import { SnackbarProvider } from 'notistack';
import CMSDetail from "./pages/cms-detail";
import { WagmiConfig } from 'wagmi';

import { createConfig, configureChains } from 'wagmi'
import { infuraProvider } from "wagmi/providers/infura";
import {
  mainnet,
  bsc,
  polygon,
  avalanche,
  fantom,
  arbitrum,
  optimism,
  base
} from "wagmi/chains";

import { publicProvider } from 'wagmi/providers/public'

import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import config from './config/config';

const App = () => {

  const routes = useRoutes([
    //{ path: "/home", element: <Home /> },
    { path: "/", element: <Home /> },
    { path: "swap-simple", element: <SwapSimple /> },
    { path: "swap-classic", element: <SwapClassic /> },
    { path: "transfer", element: <SwapClassic /> },
    { path: "swap-limit", element: <Swaplimit /> },
    { path: "p2p", element: <Swaplimit /> },
    { path: "bridge", element: <Bridge /> },
    { path: "blog", element: <Blog /> },
    { path: "blog-detail/:link", element: <BlogDetail /> },
    { path: "blog-search/:leveltype", element: <BlogSearch /> },
    { path: "blog-category/:categorytype", element: <BlogCategory /> },
    { path: "/:link", element: <CMSDetail /> },

  ]);
  return routes;
};

var mainnetNetwork = [mainnet, bsc, polygon, avalanche, fantom, arbitrum, optimism, base];

var allNetwork = mainnetNetwork;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  allNetwork,
  [infuraProvider({
    apiKey: config.infuraId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        verson: 2
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Binance Wallet',
        getProvider: () =>
          typeof window !== 'undefined' ? window.BinanceChain : undefined,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const AppWrapper = () => {

  return (
    <React.Fragment>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider />
          <Router basename="/">
            <WagmiConfig config={wconfigInitial}>
              <App />
            </WagmiConfig>
          </Router>
        </I18nextProvider>
      </Provider>
    </React.Fragment>
  );
};

export default AppWrapper;
