// import constant
import {
    ISMARKET,
    ISROUTE
} from '../constant';

const initialState = {
    isList: false
};
const initialState1 = {
    status: false
};

export const isMarket = (state = initialState, action) => {
    switch (action.type) {
        case ISMARKET:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export const isRoute = (state = initialState1, action) => {
    switch (action.type) {
        case ISROUTE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}