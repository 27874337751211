export const SET_WEB3 = 'SET_WEB3';
export const WALLET_CONNECT = 'WALLET_CONNECT';
export const TOKEN_LIST = 'TOKEN_LIST';
export const ALLOWED_PAIRS = 'ALLOWED_PAIRS';
export const IS_ELIGIBLE = 'IS_ELIGIBLE';
export const SWAPTOKEN = 'SWAPTOKEN';
export const SETTINGS = 'SETTINGS';
export const GASFEE = 'GASFEE';
export const ISMARKET = 'ISMARKET';
export const ISROUTE = 'ISROUTE';
export const NETWORK = 'NETWORK';
export const NETWORKCONFIG = 'NETWORKCONFIG';
export const BRIDGETOKEN = 'BRIDGETOKEN';
export const BRIDGENETWORK = 'BRIDGENETWORK';
export const BRIDGESETTINGS = 'BRIDGESETTINGS';
export const TRANSFER = 'TRANSFER';
export const UPDATETRANSFER = 'UPDATETRANSFER';
export const TRANSFERSTATUS = 'TRANSFERSTATUS';
export const BLOCKNUMBER = 'BLOCKNUMBER';
export const GASCOST = 'GASCOST';
export const SOURCECOUNT = 'SOURCECOUNT';
export const LIMITORDERTOKEN = 'LIMITORDERTOKEN';
export const PAIRTOKEN = 'PAIRTOKEN';
export const MARKETPRICE = 'MARKETPRICE';
export const LOADHISTORY = 'LOADHISTORY';
export const SWAPDATA = 'SWAPDATA';
export const SIGNIN = 'SIGNIN';
export const LANG = 'LANG';
