import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import locales
import enTranslation from './en'//english
import arTranslation from './ar'
import bnTranslation from './bn'
import deTranslation from './de'
import elTranslation from './el'
import esTranslation from './es'
import fiTranslation from './fi'
import filTranslation from './fil'
import frTranslation from './fr'
import hiTranslation from './hi'
import huTranslation from './hu'
import idTranslation from './id'
import itTranslation from './it'
import jaTranslation from './ja'
import koTranslation from './ko'
import nlTranslation from './nl'
import plTranslation from './pl'
import ptbrTranslation from './pt-br'
import ptTranslation from './pt'
import roTranslation from './ro'
import ruTranslation from './ru'
import svTranslation from './sv'
import taTranslation from './ta'
import trTranslation from './tr'
import ukTranslation from './uk'
import viTranslation from './vi'
import zhTWTranslation from './zh-TW'
import zhTranslation from './zh'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        'translation': enTranslation
    },
    ar: {
        'translation': arTranslation
    },
    bn: {
        'translation': bnTranslation
    },
    de: {
        'translation': deTranslation
    },
    el: {
        'translation': elTranslation
    },
    es: {
        'translation': esTranslation
    },
    fi: {
        'translation': fiTranslation
    },
    fil: {
        'translation': filTranslation
    },
    fr: {
        'translation': frTranslation
    },
    hi: {
        'translation': hiTranslation
    },
    hu: {
        'translation': huTranslation
    },
    id: {
        'translation': idTranslation
    },
    it: {
        'translation': itTranslation
    },
    ja: {
        'translation': jaTranslation
    },
    ko: {
        'translation': koTranslation
    },
    nl: {
        'translation': nlTranslation
    },
    pl: {
        'translation': plTranslation
    },
    ptbr: {
        'translation': ptbrTranslation
    },
    pt: {
        'translation': ptTranslation
    },
    ro: {
        'translation': roTranslation
    },
    ru: {
        'translation': ruTranslation
    },
    sv: {
        'translation': svTranslation
    },
    ta: {
        'translation': taTranslation
    },
    tr: {
        'translation': trTranslation
    },
    uk: {
        'translation': ukTranslation
    },
    vi: {
        'translation': viTranslation
    },
    zhTW: {
        'translation': zhTWTranslation
    },
    zh: {
        'translation': zhTranslation
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;