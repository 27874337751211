import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import config from "../../config/config";

import { getTxName, getTxLink } from "../../helper/networkdata";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getTokenLogo } from "../../helper/networkdata";
import { shortText, formatNumber } from "../../helper/custommath";
import UnlockTokenModal1 from "./unlockTokenModal1";
import { submitFillOrder, estimateGasFillOrder } from "./limitParaswapSdk";
import { getCoinRate } from "../../Api/SwapActions";
import { setLoadHistory } from "../../reducers/Actions";

import { displayDays } from "../../helper/dateFormat";
import { toastAlert } from "../../helper/toastAlert";

import { validateAllowanceBalance } from "./swap-details";

import { getUserBalances } from "../../components/swap-details";
import { getTokenRate } from "../../Api/SwapActions";

import { fillP2POrders } from "../../Api/LimitOrderActions";
import { useTranslation } from "react-i18next";

var coinAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

var initialdata = {
  fromlogo: "",
  fromsymbol: "",
  pay: 0,
  tologo: "",
  tosymbol: "",
  receive: "",
  maker: "",
  fromaddress: "",
  toaddress: "",
};

export default function ReviewTrade(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [txId, settxId] = useState("");
  const [fillInfo, setfillInfo] = useState(initialdata);

  const [wrapped, setwrapped] = useState(false);
  const [unlock, setunlock] = useState(false);
  const [insufficientBal, setinsufficientBal] = useState(false);
  const [reviewtrade, setreviewtrade] = useState(false);
  const [swaperror, setswaperror] = useState(false);
  const [approveLoad, setapproveLoad] = useState(false);
  const [networkFee, setnetworkFee] = useState(0);
  const [loadFee, setloadFee] = useState(false);
  const [usertokenBal, setusertokenBal] = useState({
    fromBalanceOf: 0,
    fromBalance: 0,
    toBalanceOf: 0,
    toBalance: 0,
  });
  const [tokenusd, settokenusd] = useState({ rate1: 0, rate2: 0 });

  var walletConnection = props.walletConnection;

  function CloseModal() {
    setloading(false);
  }

  useEffect(() => {
    setfillInfo(props.fillOrderInfo);
    //eslint-disable-next-line
  }, [props.fillOrderInfo]);
  useEffect(() => {
    checkAllowance(props.fillOrderInfo);
    getFee(props.fillOrderInfo);
    //eslint-disable-next-line
  }, [fillInfo]);

  async function getFee(fillInfo) {
    if (fillInfo.toaddress && fillInfo.toaddress !== "") {
      setloadFee(true);
      var { estimatedGas } = await estimateGasFillOrder(fillInfo);
      var { result } = await getCoinRate();
      var index = result.findIndex((val) => val.chainId == fillInfo.chainId);
      var dollarVal = 0.06;
      if (index != -1) {
        var current_price = result[index].current_price;
        dollarVal = current_price * estimatedGas;
      }
      setnetworkFee(dollarVal);
      setloadFee(false);
      var details = {
        fromaddress: fillInfo.fromaddress,
        toaddress: fillInfo.toaddress,
      };
      getUserBalances(details, walletConnection, setusertokenBal);

      setTimeout(async function () {
        var id = config.coingeckoId[fillInfo.chainId];
        var rate1 = await getTokenRate(id, fillInfo.fromaddress);
        let pay =
          parseFloat(fillInfo.pay) / 10 ** parseFloat(fillInfo.fromdecimal);
        rate1 = rate1 * parseFloat(pay);
        var rate2 = await getTokenRate(id, fillInfo.toaddress);
        let receive = parseFloat(fillInfo.receive) / 10 ** fillInfo.todecimal;
        rate2 = rate2 * parseFloat(receive);
        settokenusd({ rate1, rate2 });
      }, 500);
    }
  }

  async function checkAllowance() {
    console.log(fillInfo, 'fillInfofillInfofillInfo')
    if (fillInfo.toaddress && fillInfo.toaddress !== "") {
      var nwrk = walletConnection.network;
      var coinAddr =
        fillInfo.toaddress.toLowerCase() === coinAddress &&
          config &&
          config.coin &&
          config.coin[nwrk]
          ? config.coin[nwrk]
          : fillInfo.toaddress;

      let allowanceAddr = {
        fromaddress: coinAddr,
      };
      var spender = fillInfo.spender;

      var { fromAllowance, fromBalance, coinBalance } =
        await validateAllowanceBalance(
          allowanceAddr,
          walletConnection,
          spender
        );

      var dec = parseFloat(fillInfo.todecimal);
      var youPay = parseFloat(fillInfo.receive);
      youPay = youPay / 10 ** dec;
      fromAllowance = fromAllowance / 10 ** dec;

      console.log(
        youPay,
        fromAllowance,
        fromBalance,
        coinBalance,
        "fromAllowance, fromBalance, coinBalance",
        spender
      );
      setunlock(false);
      setwrapped(false);
      setinsufficientBal(false);
      setswaperror("");
      if (youPay > fromBalance) {
        console.log("11111111111111A");
        setinsufficientBal(true);
        setswaperror("Insufficient balance");
      } else if (youPay <= coinBalance && youPay > fromBalance) {
        console.log("11111111111111B");
        setwrapped(true);
      } else if (youPay > fromAllowance) {
        console.log("11111111111111C");
        setunlock(true);
      } else {
        console.log("11111111111111D");
        setreviewtrade(true);
      }
    }
  }

  async function triggerOrder() {
    setloading(true);

    var { hash, error, status } = await submitFillOrder(fillInfo);
    setloading(false);
    settxId(hash);
    if (error) {
      toastAlert("error", t(error), "fillorder");
      return;
    }

    if (hash && hash !== "") {
      window.$("#fill_trade_modal").modal("hide");
      window.$("#success_fillorder_modal").modal("show");

      // var fillOrderIds = localStorage.getItem("fillOrderIds");
      // var ids = (fillOrderIds) ? fillOrderIds : "";
      // ids += "," + fillInfo.orderHash;
      // localStorage.setItem("fillOrderIds", ids);
    }

    fillP2POrders({
      address: walletConnection.address,
      orderHash: fillInfo.orderHash,
      hash: hash,
    });

    setTimeout(function () {
      dispatch(
        setLoadHistory({
          status: true,
        })
      );
    }, 2000);
    setTimeout(function () {
      dispatch(
        setLoadHistory({
          status: false,
        })
      );
    }, 3000);
  }

  async function ApproveToken() {
    window.$("#confirm_approve_modal1").modal("show");
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="fill_trade_modal"
        tabIndex="-1"
        aria-labelledby="fill_trade_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="fill_trade_modalLabel">
                {t("FILL_OTC_ORDER")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => CloseModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <div className="swap_select_token_wrap">
                  <div className="swap_select_token_inner">
                    <div className="d-flex align-items-center justify-content-between">
                      <label>{t("PAY")}</label>
                      <small>
                        {t("BALANCE_ONE")}
                        {usertokenBal.toBalance} {fillInfo.tosymbol}
                      </small>
                    </div>
                    <div>
                      <div className="swap_select_token swap_select_token_noarrow">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              fillInfo.tologo,
                              walletConnection.network
                            )}
                            alt={fillInfo.tosymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {fillInfo.tosymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={formatNumber(
                          parseFloat(fillInfo.receive) /
                          10 ** fillInfo.todecimal,
                          5
                        )}
                        readOnly
                      />
                      <div className="swap_token_bal">
                        ~${formatNumber(tokenusd.rate2, 4)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swap_arrow_container">
                  <img
                    src={require("../../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
                <div className="swap_select_token_wrap swap_select_token_wrap_nobackground">
                  <div className="swap_select_token_inner">
                    <div className="d-flex align-items-center justify-content-between">
                      <label>{t("RECEIVE")}</label>
                      <small>
                        {t("BALANCE_ONE")}
                        {usertokenBal.fromBalance} {fillInfo.fromsymbol}
                      </small>
                    </div>
                    <div>
                      <div className="swap_select_token swap_select_token_noarrow">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              fillInfo.fromlogo,
                              walletConnection.network
                            )}
                            alt={fillInfo.fromsymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {fillInfo.fromsymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={formatNumber(
                          parseFloat(fillInfo.pay) / 10 ** fillInfo.fromdecimal,
                          5
                        )}
                        readOnly
                      />
                      <div className="swap_token_bal">
                        ~${formatNumber(tokenusd.rate1, 4)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swap_info">
                <div>
                  <span>
                    {t("ORDER_PRICE")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="orderPrice"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="orderPrice"
                    place="right"
                    content={t("PRICE_SET_BY_THE_ORDER_MAKER_X")}
                  />
                  <span>
                    1 {fillInfo.fromsymbol} ={" "}
                    {formatNumber(
                      parseFloat(fillInfo.receive) / parseFloat(fillInfo.pay),
                      5
                    )}{" "}
                    {fillInfo.tosymbol}
                  </span>
                </div>

                <div>
                  <span>
                    {t("EXPIRY")}
                    <i className="far fa-question-circle ms-1" id="expiry"></i>
                  </span>
                  <ReactTooltip
                    anchorId="expiry"
                    place="right"
                    content={t("EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER")}
                  />
                  <span>
                    {fillInfo.expiry == 0
                      ? "Never"
                      : displayDays(fillInfo.expiry * 1000)}
                  </span>
                </div>

                <div>
                  <span>
                    {t("MAKER")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="takeraddr"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="takeraddr"
                    place="right"
                    content={fillInfo.maker}
                  />
                  <span>{shortText(fillInfo.maker)}</span>
                </div>

                <div>
                  <span>{t("NETWORK_FEES")}</span>
                  {loadFee ? (
                    <span>...</span>
                  ) : (
                    <span>${formatNumber(networkFee, 5)}</span>
                  )}
                </div>
              </div>
              <div className="flex_btn_grp">
                {/* <button
                                    className="connect_wallet_btn w-100"
                                    data-bs-dismiss="modal"
                                    disabled={loading}
                                >
                                    Cancel{" "}
                                </button> */}

                {/* <button
                                    className="connect_wallet_btn w-100"
                                    onClick={() => triggerOrder()}
                                    disabled={loading}
                                >
                                    Submit{" "}
                                    {loading && <i className="fas fa-spinner fa-spin"></i>}
                                </button> */}

                {swaperror && swaperror != "" ? (
                  <>
                    <button className="connect_wallet_btn w-100">
                      {swaperror}
                    </button>
                  </>
                ) : wrapped ? (
                  <>
                    <button
                      className="connect_wallet_btn w-100"
                      onClick={() => WrappedToken()}
                    >
                      {t("WRAP")} {fillInfo.tosymbol}
                    </button>
                    <button
                      className="connect_wallet_btn w-100"
                      disabled={true}
                    >
                      {t("SUBMIT")}
                    </button>
                  </>
                ) : unlock ? (
                  <>
                    <button
                      className="connect_wallet_btn w-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      {t("CANCEL")}
                    </button>
                    <button
                      className="connect_wallet_btn w-100"
                      onClick={() => ApproveToken()}
                    >
                      {t("UNLOCK")} {fillInfo.tosymbol}
                      {approveLoad && (
                        <i className="fas fa-spinner fa-spin"></i>
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="connect_wallet_btn w-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      {t("CANCEL")}
                    </button>
                    <button
                      className="connect_wallet_btn w-100"
                      disabled={loading ? true : false}
                      onClick={() => triggerOrder()}
                    >
                      {t("SUBMIT")}
                    </button>
                  </>
                )}
              </div>
              {loading && (
                <div className="text-center">
                  <ReactLoading
                    type={"bars"}
                    color="#fbd534"
                    className="loading"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <UnlockTokenModal1
        wrappedDetails={{
          fromaddress: fillInfo.toaddress,
          pay: parseFloat(fillInfo.pay) / 10 ** parseFloat(fillInfo.todecimal),
          fromdecimals: fillInfo.todecimal,
          fromsymbol: fillInfo.tosymbol,
        }}
        getLimitPriceData1={checkAllowance}
        spender={fillInfo.spender}
      />

      <div
        className="modal fade primary_modal"
        id="success_fillorder_modal"
        tabIndex="-1"
        aria-labelledby="success_fillorder_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="success_fillorder_modalLabel"
              >
                {t("SUCCESS_FILLED")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <svg
                  className="txcomplete"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                <a
                  href={getTxLink(walletConnection.network) + "tx/" + txId}
                  target="_blank"
                  className="d-block mt-3 text-center"
                >
                  {" "}
                  <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                  {t("VIEW_ON")} {getTxName(walletConnection.network)}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
