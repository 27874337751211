import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";

import { useDispatch } from "react-redux";

import { cancelLimitOrders } from "./limitParaswapSdk";
import { toastAlert } from "../../helper/toastAlert";
import { connection } from "../../helper/connection";
import { setLoadHistory } from "../../reducers/Actions";

import { cancelOrders } from "../../Api/LimitOrderActions"
import { useTranslation } from "react-i18next";

export default function CancelLimitModal(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    let { cancelHash } = props;

    const [loading, setloading] = useState(false);

    async function cancelOrder() {

        setloading(true);
        try {
            var { hash, err } = await cancelLimitOrders(cancelHash);

            if (hash) {
                var cancelIds = localStorage.getItem("cancelIds");
                var ids = (cancelIds) ? cancelIds : "";
                for (var h = 0; h < cancelHash.length; h++) {
                    ids += "," + cancelHash[h];
                }
                localStorage.setItem("cancelIds", ids);
                checkTransaction(hash)
            } else if (err) {
                var errMsg = err.toString();
                var pos = errMsg.search("user rejected transaction")
                console.log(err, 'errerrerrerrerr1')
                if (pos >= 0) {
                    toastAlert("error", t('CONFIRMATION_REJECTED'), "canel")
                } else {
                    toastAlert("error", t('PLEASE_TRY_LATER'), "canel")
                }
                setloading(false);
            }

        } catch (err) {
            console.log(err, 'user rejected transaction')
            setloading(false);
        }

    }

    async function checkTransaction(hash) {
        console.log("checkTransaction", hash)
        var get = await connection();
        var web3 = get.web3;
        var cnt = 0;
        var timer = window.setInterval(async () => {
            cnt++;
            if (cnt > 20) {
                window.clearInterval(timer);
                window.$("#cancel_limit_modal").modal("hide");
                toastAlert("success", t('YOUR_ORDER_CANCEL'));
                dispatch(setLoadHistory({
                    status: true
                }));
                setTimeout(function () {
                    dispatch(setLoadHistory({
                        status: false
                    }));
                    setloading(false);
                }, 2000);
                return;
            }
            var resp = await web3.eth.getTransactionReceipt(hash);
            console.log(resp, 'resprespresp')
            if (resp && resp.status) {
                cancelOrders({ ids: JSON.stringify(cancelHash), hash: hash })
                dispatch(setLoadHistory({
                    status: true
                }));
                window.clearInterval(timer);
                window.$("#cancel_limit_modal").modal("hide");
                toastAlert("success", t('YOUR_ORDER_CANCEL'));
                setTimeout(function () {
                    dispatch(setLoadHistory({
                        status: false
                    }));
                    setloading(false);
                }, 2000);
            }
        }, 3000);

    }

    return (
        <>
            <div
                className="modal fade primary_modal"
                id="cancel_limit_modal"
                tabIndex="-1"
                aria-labelledby="cancel_limit_modalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="cancel_limit_modalLabel">
                                {t('CANCEL_LIMIT_ORDER')}</h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>{t('CANCEL_LIMIT_NOT_FILLED')}</p>
                            <p>{t('CANCELLATION_TRANSACTION_FEE')}</p>
                            <div className="flex_btn_grp">
                                <button
                                    className="connect_wallet_btn w-100"
                                    data-bs-dismiss="modal"
                                >
                                    {t('DISMISS')}
                                </button>
                                <button
                                    className="connect_wallet_btn w-100"
                                    onClick={() => cancelOrder()}
                                    disabled={loading}
                                >
                                    {t('CANCEL_ORDER')}
                                    {loading && <i className="fas fa-spinner fa-spin"></i>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}