import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { setGasFee } from "../reducers/Actions";
import { getGasFee } from "../helper/networkdata";
import { formatNumber } from "../helper/custommath";
import { useTranslation } from "react-i18next";

export default function Gasfee(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const gasValue = useSelector((state) => state.gasValue);
  const settings = useSelector((state) => state.settings);
  const walletConnection = useSelector((state) => state.walletConnection);

  var mType =
    settings.gasmode === "Normal"
      ? "low"
      : settings.gasmode === "Fast"
      ? "market"
      : settings.gasmode === "Instant"
      ? "aggressive"
      : "market";

  const [gastype, setgastype] = useState(mType);
  const [hideedit, sethideedit] = useState("Hide");
  const [error, seterror] = useState("");
  const [error1, seterror1] = useState("");
  const [error2, seterror2] = useState("");
  const [txCost, settxCost] = useState(props.gasCostUSD);
  const [actualCost, setactualCost] = useState(props.gasCostUSD);

  function selectGas(f) {
    setgastype(f);

    var currCost = parseFloat(actualCost);
    var onegweiusd = currCost / gasValue.low; //approximate
    var gasVal = gasValue.low;
    if (f === "market") {
      currCost = onegweiusd * gasValue.market;
      gasVal = gasValue.market;
    } else if (f === "aggressive") {
      currCost = onegweiusd * gasValue.aggressive;
      gasVal = gasValue.aggressive;
    } else if (f === "custom") {
      var cVal =
        gasValue &&
        gasValue.custom &&
        gasValue.custom.base &&
        parseFloat(gasValue.custom.base) > 0
          ? parseFloat(gasValue.custom.base)
          : 1;
      currCost = cVal * onegweiusd;
      gasVal = cVal;
    }

    settxCost(currCost.toFixed(2));

    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: { base: gasValue.custom.base, miner: gasValue.custom.miner },
        base: gasValue.base,
        gastype: f,
        selected: gasVal,
      })
    );
  }

  function setDropDown(val) {
    sethideedit(hideedit === "Hide" ? "Edit" : "Hide");
  }

  useEffect(() => {
    loadFee();

    var timer = window.setInterval(async () => {
      var { suggestBaseFee, lowFee, marketFee, aggressiveFee } =
        await getGasFee(walletConnection.network);

      var customBase =
        gasValue && gasValue.custom && gasValue.custom.base
          ? gasValue.custom.base
          : suggestBaseFee;

      try {
        if (parseFloat(walletConnection.network) === 56) {
          customBase = document.getElementById("custom").value;
        } else {
          customBase = document.getElementById("base").value;
        }
      } catch (err) {}

      var customMine =
        gasValue && gasValue.custom && gasValue.custom.miner
          ? gasValue.custom.miner
          : 0;
      var totalF = parseFloat(customBase) + parseFloat(customMine);
      var selectedFee =
        gasValue.gastype === "low"
          ? marketFee
          : gasValue.gastype === "market"
          ? marketFee
          : gasValue.gastype === "aggressive"
          ? aggressiveFee
          : gasValue.gastype === "custom"
          ? totalF
          : 0;

      seterror("");
      var cBase = 0;
      try {
        cBase = document.getElementById("base").value;
      } catch (err) {}
      if (suggestBaseFee > cBase) {
        seterror("Max base fee can not be lower than current base fee");
      }

      lowFee = formatNumber(lowFee, 2);
      marketFee = formatNumber(marketFee, 2);
      aggressiveFee = formatNumber(aggressiveFee, 2);
      suggestBaseFee = formatNumber(suggestBaseFee, 2);
      selectedFee = formatNumber(selectedFee, 2);
      customBase = formatNumber(customBase, 2);
      customMine = formatNumber(customMine, 2);

      dispatch(
        setGasFee({
          low: lowFee,
          market: marketFee,
          aggressive: aggressiveFee,
          base: suggestBaseFee,
          selected: selectedFee,
          custom: { base: customBase, miner: customMine },
          gastype: gasValue.gastype,
          network: walletConnection.network,
        })
      );
    }, 44000);
    return () => {
      window.clearInterval(timer);
    };
  }, [walletConnection.network, gasValue.gastype]);

  async function getRate() {
    //var { result } = await getUsdRate();
    //setpriceRates(result);
  }

  useEffect(() => {
    var currCost = parseFloat(props.gasCostUSD);
    var feeVal =
      gasValue && gasValue.selected && parseFloat(gasValue.low) > 0
        ? parseFloat(gasValue.low)
        : 1;
    var value =
      gasValue && gasValue.selected && parseFloat(gasValue.selected) > 0
        ? parseFloat(gasValue.selected)
        : gasValue.low;

    var onegweiusd = currCost / feeVal;
    currCost = value * onegweiusd;
    settxCost(currCost.toFixed(2));
    setactualCost(props.gasCostUSD);

    getRate();
  }, [props.gasCostUSD]);

  async function loadFee() {
    var seleGastype =
      gasValue && gasValue.gastype !== "" ? gasValue.gastype : gastype;
    var { suggestBaseFee, marketFee, aggressiveFee } = await getGasFee(
      walletConnection.network
    );

    var cValue = aggressiveFee;
    if (
      parseInt(gasValue.network) > 0 &&
      parseInt(walletConnection.network) !== parseInt(gasValue.network)
    ) {
      cValue = aggressiveFee;
    } else {
      try {
        if (parseFloat(walletConnection.network) === 56) {
          cValue = document.getElementById("custom").value;
        } else {
          cValue = document.getElementById("base").value;
        }
      } catch (err) {}

      cValue =
        cValue && parseFloat(cValue) > 0
          ? cValue
          : gasValue &&
            gasValue.custom &&
            gasValue.custom.base &&
            parseFloat(gasValue.custom.base)
          ? parseFloat(gasValue.custom.base).toFixed(2)
          : parseFloat(aggressiveFee).toFixed(2);
    }

    //var cValue = aggressiveFee;
    var selectedFee =
      seleGastype === "low"
        ? marketFee
        : seleGastype === "market"
        ? marketFee
        : seleGastype === "aggressive"
        ? aggressiveFee
        : seleGastype === "custom"
        ? cValue
        : marketFee;

    suggestBaseFee = formatNumber(suggestBaseFee, 2);
    marketFee = formatNumber(marketFee, 2);
    aggressiveFee = formatNumber(aggressiveFee, 2);
    selectedFee = formatNumber(selectedFee, 2);
    cValue = formatNumber(cValue, 2);

    dispatch(
      setGasFee({
        low: suggestBaseFee,
        market: marketFee,
        aggressive: aggressiveFee,
        custom: { base: cValue, miner: 0.47 },
        base: suggestBaseFee,
        gastype: seleGastype,
        selected: selectedFee,
        network: walletConnection.network,
      })
    );
    setgastype(seleGastype);
    //setcustomtotal((parseFloat(total) > 0) ? total.toFixed(2) : 0);
  }

  const inputChange = async (event) => {
    var id = event.target.id;
    var value = event.target.value;

    if (id === "base") {
      seterror("");
      if (isNaN(value)) {
        seterror("Please enter valid fee");
        dispatch(
          setGasFee({
            low: gasValue.low,
            market: gasValue.market,
            aggressive: gasValue.aggressive,
            custom: { base: "", miner: 0.47 },
            gastype: "custom",
            selected: 0,
          })
        );
        return;
      } else if (value && parseFloat(gasValue.base) > parseFloat(value)) {
        seterror("Max base fee can not be lower than current base fee");
      }
    }

    if (id === "miner") {
      seterror1("");
      if (!value || parseFloat(value) === 0) {
        var text = parseFloat(value) === 0 ? "0." : "empty.";
        seterror1("Miner priority fee can’t be " + text);
      }
    }

    var currCost = parseFloat(actualCost);
    var onegweiusd = currCost / gasValue.low; //approximate
    var cVal = parseFloat(value);
    currCost = cVal * onegweiusd;
    settxCost(currCost.toFixed(2));

    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: { base: value, miner: 0.47 },
        gastype: "custom",
        selected: value,
      })
    );
  };

  const inputChange1 = async (event) => {
    var value = event.target.value;
    seterror2("");

    if (value === 0 || !value || parseFloat(value) <= 0) {
      dispatch(
        setGasFee({
          low: gasValue.low,
          market: gasValue.market,
          aggressive: gasValue.aggressive,
          custom: { base: 0, miner: 0 },
          gastype: "custom",
          selected: 0,
          network: walletConnection.network,
        })
      );
      seterror2("Please enter valid gas fee");
      return;
    }

    var currCost = parseFloat(actualCost);
    var onegweiusd = currCost / gasValue.low; //approximate
    var cVal = parseFloat(value);
    currCost = cVal * onegweiusd;
    settxCost(currCost.toFixed(2));

    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: { base: value, miner: 0 },
        gastype: "custom",
        selected: value,
        network: walletConnection.network,
      })
    );
  };

  async function selectGas1(f) {
    seterror2("");
    setgastype(f);

    var currCost = parseFloat(actualCost);
    var onegweiusd = currCost / gasValue.low; //approximate
    var gasVal = gasValue.low;
    if (f === "market") {
      currCost = onegweiusd * gasValue.market;
      gasVal = gasValue.market;
    } else if (f === "aggressive") {
      currCost = onegweiusd * gasValue.aggressive;
      gasVal = gasValue.aggressive;
    } else if (f === "custom") {
      var cVal =
        gasValue &&
        gasValue.custom &&
        gasValue.custom.base &&
        parseFloat(gasValue.custom.base) > 0
          ? parseFloat(gasValue.custom.base)
          : 1;
      currCost = cVal * onegweiusd;
      gasVal = cVal;
    }
    settxCost(currCost.toFixed(2));

    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: { base: gasValue.custom.base, miner: gasValue.custom.miner },
        base: gasValue.base,
        gastype: f,
        selected: gasVal,
        network: walletConnection.network,
      })
    );
  }

  function AutoGas(val) {
    var currCost = parseFloat(actualCost);
    settxCost(currCost.toFixed(2));

    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: {
          base:
            gasValue && gasValue.custom && gasValue.custom.base
              ? gasValue.custom.base
              : 0,
          miner:
            gasValue && gasValue.custom && gasValue.custom.miner
              ? gasValue.custom.miner
              : 0,
        },
        base: gasValue.base,
        gastype: val,
        selected: gasValue.market,
        network: walletConnection.network,
      })
    );
    setgastype(val);
  }

  function selectTab() {
    if (gastype === "market" || gastype === "aggressive") {
      document.getElementById("two-tab").click();
    } else {
      document.getElementById("one-tab").click();
    }
  }

  return (
    <div className="accordion swap_info_accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
            onClick={() => selectTab()}
          ></button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="swap_info_chg">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="one-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#one"
                    type="button"
                    role="tab"
                    aria-controls="one"
                    aria-selected="true"
                  >
                    <div className="box_flx">
                      <div className="box me-1" onClick={() => AutoGas("auto")}>
                        <img
                          src={require("../assets/images/fusion.png")}
                          alt="img"
                          className="img-fluid"
                        />
                        <p className="mt-2">
                          {/* Fusion{" "} */}
                          <span>
                            {" "}
                            <img
                              src={require("../assets/images/a_symbol.png")}
                              alt="img"
                              className="asymbol img-fluid"
                            />
                          </span>{" "}
                          {t("AUTO")}
                        </p>
                        <p>
                          {t("TX_COST")}
                          <span className="ms-2">
                            $
                            {actualCost &&
                            parseFloat(actualCost) > 0 &&
                            !isNaN(actualCost)
                              ? actualCost
                              : 0}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="two-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#two"
                    type="button"
                    role="tab"
                    aria-controls="two"
                    aria-selected="false"
                  >
                    <div className="box_flx">
                      <div
                        className="box ms-1"
                        onClick={() => AutoGas("market")}
                      >
                        <img
                          src={require("../assets/images/gas-station.png")}
                          alt="img"
                        />
                        <p className="mt-2">
                          {t("LEGACY")} <span className="dot">.</span>{" "}
                          {t("MARKET")}
                        </p>
                        <p>
                          {t("TX_COST")}
                          <span className="ms-2">
                            $
                            {txCost && parseFloat(txCost) > 0 && !isNaN(txCost)
                              ? txCost
                              : 0}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="one"
                  role="tabpanel"
                  aria-labelledby="one-tab"
                >
                  <div className="swap_info">
                    <div>
                      <span>
                        1 {props.destSymbol} {t("PRICE")}
                      </span>
                      <span>
                        <span>
                          {props.fromRate} {props.srcSymbol}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span>
                        1 {props.srcSymbol} {t("PRICE")}
                      </span>
                      <span>
                        <span>
                          {props.toRate} {props.destSymbol}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span>{t("TX_COST")}</span>
                      <span>
                        <span>
                          $
                          {actualCost &&
                          parseFloat(txCost) > 0 &&
                          !isNaN(txCost)
                            ? txCost
                            : 0}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="two"
                  role="tabpanel"
                  aria-labelledby="two-tab"
                >
                  <div className="accordion" id="accordionExample4">
                    <div className="swap_info swap_info_chg">
                      <div>
                        <span>
                          1 {props.destSymbol} {t("PRICE")}
                        </span>
                        <span>
                          <span>
                            {props.fromRate} {props.srcSymbol}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span>
                          1 {props.srcSymbol} {t("PRICE")}
                        </span>
                        <span>
                          <span>
                            {props.toRate} {props.destSymbol}
                          </span>
                        </span>
                      </div>
                      <div className="mb-2">
                        <span>{t("TX_COST")}</span>
                        <span className="d-flex align-items-center">
                          <button
                            className="accordion-button me-2 text_show"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            onClick={() => setDropDown()}
                          >
                            {hideedit}
                          </button>

                          {gastype == "low"
                            ? "Low"
                            : gastype == "market"
                            ? "Market"
                            : gastype == "aggressive"
                            ? "Aggressive"
                            : gastype}
                          <span>
                            ~$
                            {txCost && parseFloat(txCost) > 0 && !isNaN(txCost)
                              ? txCost
                              : 0}
                          </span>
                        </span>
                      </div>
                    </div>

                    {/* <div className="accordion" id="accordionExample4"> */}
                    <div className="accordion-item">
                      {/* <h2 className="accordion-header" id="headingOne"> */}
                      {/* <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
Text
</button> */}
                      {/* </h2> */}
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample4"
                      >
                        <div className="accordion-body">
                          {(walletConnection &&
                            parseInt(walletConnection.network)) !== 56 ? (
                            <div className="accordion_box">
                              <div className="box">
                                <div className="flx">
                                  <input
                                    type="radio"
                                    readOnly
                                    name="gas"
                                    onClick={() => selectGas("low")}
                                    checked={gastype === "low" ? true : false}
                                  />
                                  <div className="ms-2">
                                    <span>{t("LOW")}</span>
                                    {/* <span className="grey">~20 sec</span> */}
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    {" "}
                                    {gasValue.base} - {gasValue.low} Gwei
                                  </span>
                                </div>
                              </div>
                              <div className="box">
                                <div className="flx">
                                  <input
                                    type="radio"
                                    readOnly
                                    name="gas"
                                    onClick={() => selectGas("market")}
                                    checked={
                                      gastype === "market" ? true : false
                                    }
                                  />
                                  <div className="ms-2">
                                    <span>{t("MARKET")}</span>
                                    {/* <span className="grey">~12 sec</span> */}
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    {" "}
                                    {gasValue.base} - {gasValue.market} Gwei
                                  </span>
                                </div>
                              </div>
                              <div className="box">
                                <div className="flx">
                                  <input
                                    type="radio"
                                    readOnly
                                    name="gas"
                                    onClick={() => selectGas("aggressive")}
                                    checked={
                                      gastype === "aggressive" ? true : false
                                    }
                                  />
                                  <div className="ms-2">
                                    <span>{t("AGGRESSIVE")}</span>
                                    {/* <span className="grey"> {"<"} 10 sec</span> */}
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    {" "}
                                    {gasValue.market} - {gasValue.aggressive}{" "}
                                    Gwei
                                  </span>
                                </div>
                              </div>
                              <div className="box">
                                <div className="flx">
                                  <input
                                    readOnly
                                    type="radio"
                                    name="gas"
                                    onClick={() => selectGas("custom")}
                                    checked={
                                      gastype === "custom" ? true : false
                                    }
                                  />
                                  <div className="ms-2">
                                    <span>{t("CUSTOM")}</span>
                                    {/* <span className="grey"> ~12 sec ffff</span> */}
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    {" "}
                                    {gasValue.custom.base} -{" "}
                                    {gasValue.custom.base} Gwei
                                  </span>
                                </div>
                              </div>
                              {gastype === "custom" && (
                                <div className="custom_modal custom_modal_chg">
                                  <div className="flxdiv grey flx">
                                    <span>
                                      {t("MAX_BASE_FEE")}
                                      <img
                                        src={require("../assets/images/info_icon.png")}
                                        alt="img"
                                        id="maxbase"
                                        className="ms-2 info"
                                      />
                                      <ReactTooltip
                                        anchorId="maxbase"
                                        place="right"
                                        content={t(
                                          "A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK"
                                        )}
                                      />
                                    </span>
                                    {/* <span>
                                      Miner priority fee
                                      <img
                                        src={require("../assets/images/info_icon.png")}
                                        alt="img"
                                        id="miner"
                                        className="ms-2 info"
                                      />
                                      <ReactTooltip
                                        anchorId="miner"
                                        place="bottom"
                                        content="This fee goes directly to miners as an incentive to prioritize your transaction."
                                      />
                                    </span> */}
                                  </div>

                                  <div className="flxdiv my-2">
                                    <div className="input-group  me-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="base"
                                        onChange={inputChange}
                                        value={
                                          gasValue &&
                                          gasValue.custom &&
                                          gasValue.custom.base
                                            ? gasValue.custom.base
                                            : ""
                                        }
                                      />
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        Gwei
                                      </span>
                                    </div>

                                    {/* <div className="input-group  ms-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={inputChange}
                                        id="miner"
                                        value={
                                          gasValue.custom &&
                                            gasValue.custom.miner
                                            ? gasValue.custom.miner
                                            : ""
                                        }
                                      />
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        Gwei
                                      </span>
                                    </div> */}
                                  </div>
                                  {error && error !== "" && (
                                    <p className="errmsg">{error}</p>
                                  )}

                                  {error1 && error1 !== "" && (
                                    <p className="errmsg">{error1}</p>
                                  )}

                                  <div className="flxdiv grey mt-2">
                                    <span>
                                      {" "}
                                      <p>{t("CURRENT")}</p>
                                      {gasValue.base} Gwei
                                    </span>
                                    {/* <span>
                                      {" "}
                                      <p>Current</p>
                                      {gasValue.custom && gasValue.custom.miner
                                        ? gasValue.custom.miner
                                        : 0}{" "}
                                      Gwei
                                    </span> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <div className="gasfee_selector_wrap">
                                <button
                                  className={
                                    gastype === "low" ? "selected" : ""
                                  }
                                  onClick={() => selectGas1("low")}
                                >
                                  {t("LOW")}(
                                  {gasValue && parseFloat(gasValue.low) > 0
                                    ? parseFloat(gasValue.low).toFixed(0)
                                    : 0}
                                  )
                                </button>
                                <button
                                  className={
                                    gastype === "market" ? "selected" : ""
                                  }
                                  onClick={() => selectGas1("market")}
                                >
                                  {t("MARKET")}(
                                  {gasValue && parseFloat(gasValue.market) > 0
                                    ? parseFloat(gasValue.market).toFixed(0)
                                    : 0}
                                  )
                                </button>
                                <button
                                  className={
                                    gastype === "aggressive" ? "selected" : ""
                                  }
                                  onClick={() => selectGas1("aggressive")}
                                >
                                  {t("HIGH")}(
                                  {gasValue &&
                                  parseFloat(gasValue.aggressive) > 0
                                    ? parseFloat(gasValue.aggressive).toFixed(0)
                                    : 0}
                                  )
                                </button>
                                <div>
                                  <input
                                    type="number"
                                    min={0}
                                    name="gas"
                                    onChange={inputChange1}
                                    placeholder="Custom"
                                    id="custom"
                                    value={
                                      gasValue.gastype === "custom"
                                        ? gasValue.custom.base
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                              {error2 && error2 !== "" && (
                                <p className="errmsg mt-1">{error2}</p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
