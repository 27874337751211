export default {
  ABOUT_NEW_FEATURES: "về các tính năng mới.",
  ACCEPT: "Chấp nhận",
  ACTIVE_ORDERS: "Đơn đặt hàng hoạt động",
  ADD_TOKEN: "Thêm một mã thông báo",
  AGGRESSIVE: "Hung dữ",
  AND: "Và",
  APPROVE: "Chấp thuận",
  ASSETS_ON_WAY: "Tài sản của bạn đang trên đường và sẽ đến",
  AUTO: "Tự động",
  AVOID_TRANSFER:
    "Vui lòng tránh chuyển đến một địa chỉ trao đổi tập trung (Binance, Huobi ...). Nó không phải là một tính năng được hỗ trợ",
  BALANCE: "Sự cân bằng",
  BALANCE_ONE: "Sự cân bằng:",
  BLOG: "OTC",
  BRIDGE: "Cầu",
  CANCEL: "Hủy bỏ",
  CANCELLATION_TRANSACTION_FEE:
    "Hủy bỏ đơn đặt hàng giới hạn liên quan đến việc trả phí giao dịch.",
  CANCEL_ALL: "Hủy bỏ tất cả",
  CANCEL_LIMIT_NOT_FILLED:
    "Bạn có thể hủy đơn đặt hàng giới hạn miễn là đơn đặt hàng chưa được điền.",
  CANCEL_LIMIT_ORDER: "Hủy đặt hàng giới hạn",
  CANCEL_ORDER: "Hủy đơn hàng",
  CANCEL_SELECTED: "Hủy đã chọn",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Chọn mạng",
  CLASSIC_MODE: "Chế độ cổ điển",
  CLEAR: "Thông thoáng",
  CONFIRM: "Xác nhận",
  CONFIRMATION_REJECTED: "Xác nhận bị từ chối.!",
  CONFIRM_SWAP: "Xác nhận hoán đổi",
  CONNECT_WALLET: "Kết nối ví",
  COPIED: "Đã sao chép",
  CURRENT: "Hiện hành",
  CUSTOM: "Phong tục",
  DAYS: "Ngày",
  DEFAULT: "Mặc định",
  DETAILS: "Chi tiết",
  DISCLAIMER: "Tuyên bố miễn trừ trách nhiệm",
  DISCONNECT_WALLET: "Ngắt kết nối ví",
  DISMISS: "Miễn nhiệm",
  DUPLICATE_ORDER_SUBMIT: "Đơn hàng trùng lặp. Cái này đã được gửi",
  ENTER_AMT: "Nhập số tiền",
  EXCHANGE: "Trao đổi",
  EXPIRY: "Hết hạn",
  EXPIRY_SM: "Hết hạn",
  FAILED_LOAD_CHART: "Không tải biểu đồ",
  FIND_BY_TOKEN_OR_STATUS: "Tìm bởi mã thông báo hoặc trạng thái",
  INSUFFICIENT_BALANCE: "Thiếu cân bằng",
  INSUFFICIENT: "Không đủ",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "số dư để trang trải số dư đơn đặt hàng hiện có của",
  RECEIVERS: "Người nhận",
  WALETADDRESS: "Địa chỉ ví",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Vui lòng chấp nhận Điều khoản về Dịch vụ và Chính sách bảo mật",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Người làm nhà sản xuất không nên là địa chỉ của người nhận",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Dung dịch trượt phải nằm trong khoảng 0,1% đến 15%",
  SEARCH_A_TOKEN: "Tìm kiếm mã thông báo",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Giao dịch của bạn sẽ hoàn nguyên nếu giá thay đổi không thuận lợi hơn so với tỷ lệ phần trăm này",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Điều chỉnh giá khí cho giao dịch của bạn.Gwei cao hơn = tốc độ cao hơn.",
  REVIEW_OTC_ORDER: "Xem lại đơn đặt hàng OTC",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Thời gian hết hạn được thiết lập bởi nhà sản xuất đơn hàng.Trong giai đoạn này, đơn đặt hàng sẽ hết hạn và được lưu trữ.",
  SEARCH_A_LIQUIDITY_SOURCES: "Tìm kiếm một nguồn thanh khoản",

  FAST: "Nhanh",
  FAST_AND_SECURE:
    "Cách an toàn, nhanh chóng và an toàn nhất để đưa các tài sản chuỗi chéo đến",
  FEATURE_TO_TRANSFER:
    "Vui lòng không sử dụng tính năng này để chuyển mã thông báo của bạn sang địa chỉ trao đổi tập trung.",
  FEES: "Lệ phí",
  FILL_ORDER: "Điền đơn đặt hàng",
  FILL_OTC_ORDER: "Điền vào đơn đặt hàng OTC",
  FREE: "Miễn phí",
  FROM: "Từ",
  GAS_COST: "Chi phí gas",
  GAS_DESTINATION: "Khí trên chuỗi đích",
  GAS_ON_DESTINATION: "Khí trên đích",
  GAS_PRICE: "Giá xăng",
  GOT_IT: "Hiểu rồi",
  GOT_PROBLEM_JUST: "Có một vấn đề? Chỉ cần liên lạc.",
  HIGH: "Cao",
  HOURS: "Giờ",
  IMPORT: "Nhập khẩu",
  IMPOSSIBLE_TO_RETRIEVE:
    "Nó không phải là một tính năng được hỗ trợ. Ngoài ra, vui lòng kiểm tra lại địa chỉ bạn điền, bất kỳ mã thông báo nào được gửi đến địa chỉ sai sẽ không thể truy xuất.",
  INSTANT: "Lập tức",
  INSUFFICIENT_APPROVED_AMT: "Không đủ số tiền được phê duyệt",
  INVALID_TOKEN_ADDRESS: "Địa chỉ mã thông báo không hợp lệ",
  KEEP_TRACK_NOTIFY:
    "Bạn có thể theo dõi nó trong thông báo bảng điều khiển của bạn. Chúng tôi sẽ thông báo cho bạn một khi nó đã hoàn thành.",
  KEEP_YOU_POSTED: ". Chúng tôi sẽ giữu bài viết của bạn.",
  LEARN_MORE: "Tìm hiểu thêm",
  LEGACY: "Di sản",
  LIMIT: "Giới hạn",
  LIMIT_ORDER: "Hạn chế thứ tự",
  LIMIT_PRICE: "Giới hạn giá",
  LIMIT_PRICE_SM: "Giới hạn giá",
  LIQUIDITY_SOURCES: "Nguồn thanh khoản",
  LOW: "Thấp",
  MAKER: "Tạo ra",
  MARKET: "Chợ",
  MAX: "Tối đa",
  MAX_BASE_FEE: "Phí cơ bản tối đa",
  MAX_S: "Tối đa",
  MAX_TO_PAY: "Tối đa để trả tiền",
  MINIMUM_RECEIVED: "Tối thiểu nhận được",
  MINUTES: "Phút",
  MONTHS: "Tháng",
  NEED_APPROVAL_MOVE: "Chúng tôi cần sự chấp thuận của bạn để di chuyển",
  NEED_TO_WRAP: "Bạn cần phải bọc",
  NETWORK_FEES: "Phí mạng",
  NETWORK_SM: "mạng.",
  NEVER: "Không bao giờ",
  NONE: "Không có",
  NON_CUSTODIAL_LIMIT:
    "Trong thứ tự giới hạn không giam giữ. Đó là một yêu cầu kỹ thuật về",
  NORMAL: "Bình thường",
  NO_ORDERS_YET: "Bạn chưa có đơn đặt hàng",
  NO_RECORDS: "Không có hồ sơ",
  ON_UR_BEHALF: "thay mặt bạn.",
  OOPS_WRONG: "Rất tiếc, một cái gì đó đã sai.!",
  OPEN: "Mở",
  ORDER_DETAILS: "Chi tiết đặt hàng",
  ORDER_HISTORY: "Lịch sử đơn hàng",
  ORDER_PRICE: "Giá đặt hàng",
  ORDER_ROUTING: "Định tuyến định tuyến",
  OTC: "OTC",
  PASTE: "Dán",
  PAY: "Chi trả",
  PENDING_CONFIRM: "Trong khi chờ xác nhận trên ví.",
  PER: "mỗi",
  PLEASE_CHOOSE_NETWORK: "Vui lòng chọn mạng",
  PLEASE_LOGIN: "Vui lòng đăng nhập vào",
  PLEASE_TRY_LATER: "Vui lòng thử lại sau",
  POPULAR_TOKENS: "Mã thông báo phổ biến",
  PRICE: "Giá",
  PRICE_IMPACT: "Tác động về giá",
  PRIVACY_POLICY: "Chính sách bảo mật",
  RATE: "Tỷ lệ",
  READ_ACCEPT: "Tôi đọc và chấp nhận",
  RECEIVE: "Nhận được",
  RECEIVED_ORDERS: "Nhận được đơn đặt hàng",
  REFUND: "ĐỀN BÙ",
  REMOVE: "Di dời",
  REVERSE_RATE: "Tỷ lệ ngược",
  REVIEW_TRADE: "Đánh giá thương mại",
  SELECTED: "đã chọn",
  SELECT_ALL: "Chọn tất cả",
  SELECT_FROM_INFO: "Vui lòng chọn từ thông tin",
  SELECT_NETWORK: "Chọn một mạng",
  SELECT_TOKEN: "Chọn một mã thông báo",
  SELECT_TOKENN: "Chọn mã thông báo",
  SELECT_TO_INFO: "Vui lòng chọn thông tin",
  SETTINGS: "Cài đặt",
  SIMPLE_MODE: "Chế độ đơn giản",
  SLIPPAGE: "Trượt",
  SLIPPAGE_TOLERANCE: "Khả năng chịu trượt",
  SRY_INSUFFICIENT: "Xin lỗi, không đủ",
  STATUS: "TRẠNG THÁI",
  STATUS_SM: "Trạng thái",
  STEP: "Bước chân",
  SUBMIT: "Nộp",
  SUBMITTED_ORDERS: "Đã gửi đơn đặt hàng",
  SUBMIT_DATE: "Gửi ngày",
  SUCCESS_FILLED: "Đơn đặt hàng thành công",
  SUCCESS_RECEIVED: "Nhận được thành công",
  SUCCESS_SUBMITTED_ORDER: "Đã gửi thành công đơn đặt hàng của bạn",
  SWAP: "Tráo đổi",
  SWAP_TRANSFER: "Hoán đổi & chuyển nhượng",
  SWITCH_NETWORK: "Chuyển mạng",
  TAKER: "Người nhận",
  TAKER_ADDRESS: "Địa chỉ Taker",
  TERMS_OF_SERVICE: "Điều khoản dịch vụ",
  TO: "ĐẾN",
  TOKEN_NOT_FOUND: "Không tìm thấy mã thông báo",
  TOKEN_RECEIVE_SENT: "Mã thông báo để nhận sẽ được gửi đến",
  TRADE: "Buôn bán",
  TRANSACTION_REJECTED_LATER: "Giao dịch bị từ chối Vui lòng thử lại sau",
  TRANSFER: "Chuyển khoản",
  TRANSFER_ALERT: "Cảnh báo chuyển giao",
  TRANSFER_TO: "Chuyển tới",
  TX_COST: "Chi phí TX",
  UNLOCK: "Mở khóa",
  USER_GUIDE: "Hướng dẫn sử dụng",
  USE_ITAS: "và sử dụng nó như w",
  VERIFY: "Xác minh",
  VIEW_EXPLORER: "Xem giao dịch trong Explorer",
  VIEW_ON: "Xem trên",
  VIEW_TRANSACTION: "Xem giao dịch",
  WRAP: "Bọc",
  YOUR_ADDRESS: "Địa chỉ của bạn",
  YOUR_ORDER_CANCEL: "Đơn đặt hàng của bạn bị hủy",
  YOUR_WALLET: "Ví của bạn",
  YOU_GET: "Bạn lấy",
  YOU_PAY: "Bạn trả",
  YOU_RECEIVE: "Bạn nhận được",
  YOU_WILL_RECEIVE: "Bạn sẽ nhận",
  ANYONE_CAN_CREATE:
    "Bất cứ ai cũng có thể tạo bất kỳ mã thông báo nào, bao gồm các phiên bản giả của mã thông báo hiện có. Hãy cẩn thận. Một số mã thông báo và các thông số kỹ thuật của chúng có thể không tương thích với các dịch vụ của chúng tôi. Bằng cách nhập mã thông báo tùy chỉnh này, bạn thừa nhận và chấp nhận rủi ro.",
  BY_SIGNING_MESSAGE:
    "Bằng cách ký tin nhắn này, bạn xác nhận rằng bạn không truy cập ứng dụng này hoặc là cư dân của Hoa Kỳ hoặc bất kỳ quốc gia hạn chế nào khác. Để biết thêm thông tin, vui lòng đọc",
  CONSTITUTION: "Hiến pháp",
  CURRENTLY_CONNECTED: "Bạn hiện đang được kết nối với",
  IMPORT_TOKEN: "Nhập mã thông báo",
  IN_YOUR_WALLET: "trong ví của bạn",
  MAKE_SURE_CHECKED:
    "Đảm bảo rằng bạn đã kiểm tra địa chỉ mã thông báo và nó đúng",
  ON: "TRÊN",
  PEOPLE: "Mọi người",
  PLEASE_SWITCH: "Vui lòng chuyển sang",
  SELECTED_NOT_SUPPORTED:
    "Vui lòng thay đổi mạng trong ví của bạn, mạng được chọn không được hỗ trợ",
  SIGN_FOR_ACCESS: "Đăng nhập để truy cập",
  SIGN_MESSAGE: "ĐĂNG KÝ THÔNG ĐIỆP",
  LAUNCH_APP: "Chạy chương trình",
  GETTING_STARTED: "Bắt đầu với Hodl: Hướng dẫn & Người giải thích sản phẩm",
  DEEP_DIVE: "DeepDive vào Hodl: Chiến lược & tính năng sản phẩm",
  BUILD_HODL: "Xây dựng trên Hodl: Tài liệu kỹ thuật",
  BLOG_HEADING:
    "Demystifying Defi - Mọi thứ bạn cần biết ở một nơi. Cho người mới bắt đầu cho người dùng nâng cao và mọi người ở giữa.",
  BLOG_PLACEHOLDER: "Tìm kiếm sản phẩm/chủ đề bạn muốn tìm hiểu về",
  CHOOSE_TOPIC: "Chọn chủ đề",
  TRY: "Thử",
  SEARCH_RESULTS: "kết quả tìm kiếm",
  SEARCH_TOPIC: "Chủ đề tìm kiếm",
  LOAD_MORE: "Tải nhiều hơn",
  SOCIAL_MEDIA: "Truyền thông xã hội",
  NEW: "Mới nhất đầu tiên",
  OLD: "Lâu đời nhất đầu tiên",
  Z_A: "Sắp xếp tiêu đề z-a",
  A_Z: "Sắp xếp tiêu đề A-Z",
  RESULTS: "Kết quả",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Phí khí cơ sở được tự động tính theo mạng và thay đổi từ khối này sang khối khác. Đặt số tiền tối đa của phí cơ sở bạn muốn trả. Một sự khác biệt giữa phí cơ sở tối đa bạn đặt và phí cơ sở thực tế sẽ được hoàn trả sau khi khối đã được khai thác.",
  CANCELLED_TRANSACTION: "Đã hủy giao dịch của bạn",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Tạo một thứ tự giới hạn là một hoạt động miễn phí cho nhà sản xuất đặt hàng trừ khi họ hủy. Người nhận đơn đặt hàng sẽ được yêu cầu thanh toán cho phí giao dịch trong khi họ điền đơn đặt hàng.",
  Cancelled: "Bị hủy bỏ",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Sự khác biệt về giá trị giữa tuyến đường tốt nhất và",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Kích hoạt tùy chọn này nếu bạn gặp sự cố trao đổi mã thông báo có phí tích hợp để chuyển.",
  Expired: "Hết hạn",
  Filled: "Điền",
  INSUFFICIENT_FOR_GASFEE: "Xin lỗi, không đủ tiền cho phí gas",
  Open: "Mở",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Điền một phần chỉ áp dụng nếu một tuyến đường hoán đổi bao gồm nhiều bộ phận tuyến chính. Nó cho phép thực hiện một phần, ngăn chặn giao dịch bị đảo ngược nếu giá thị trường không được ủng hộ.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Giá đặt do nhà sản xuất đặt hàng để thực hiện đơn đặt hàng của họ (mã thông báo để nhận = x mã thông báo để thanh toán)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Giá đặt do nhà sản xuất đặt hàng để thực hiện đơn đặt hàng của họ (mã thông báo để trả = mã thông báo để nhận",
  REVIEW_LIMIT_ORDER: "Xem lại đơn đặt hàng giới hạn",
  SEARCH_BY_TOKEN: "Tìm kiếm bằng mã thông báo hoặc địa chỉ",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Thiết lập dung sai trượt cao có thể giúp các giao dịch thành công, nhưng bạn có thể không nhận được mức giá tốt như vậy. Sử dụng cẩn thận.",
  STATUS_OF_THE_LIMIT_ORDER: "Trạng thái của thứ tự giới hạn",
  Suspended: "Cấm",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Những khoản phí này được trả cho các công ty khai thác mạng. Hodl không kiểm soát các khoản phí.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Sự khác biệt giữa giá thị trường và giá est do quy mô thương mại",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Số tiền nhận được tối thiểu, nếu không thì giao dịch sẽ được hoàn nguyên",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Phí này được chuyển trực tiếp cho các công ty khai thác như một động lực để ưu tiên giao dịch của bạn.",
  TRANSACTION_REJECTED_TRY: "Giao dịch bị từ chối, vui lòng thử lại",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Chuyển cho mã thông báo đã chọn không có sẵn trên mạng này",
  YOU: "Bạn",
};
