import {
    SET_WEB3,
    WALLET_CONNECT,
    TOKEN_LIST,
    ALLOWED_PAIRS,
    SWAPTOKEN,
    SETTINGS,
    GASFEE,
    ISMARKET,
    ISROUTE,
    NETWORK,
    BRIDGETOKEN,
    BRIDGENETWORK,
    BRIDGESETTINGS,
    TRANSFER,
    UPDATETRANSFER,
    TRANSFERSTATUS,
    GASCOST,
    SOURCECOUNT,
    LIMITORDERTOKEN,
    PAIRTOKEN,
    MARKETPRICE,
    LOADHISTORY,
    SWAPDATA,
    SIGNIN,
    LANG
} from '../constant';

export const setWeb3 = details => {
    return {
        type: SET_WEB3,
        payload: details
    };
};

export const setWallet = details => {
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};

export const setTokens = details => {
    return {
        type: TOKEN_LIST,
        payload: details
    };
};

export const setPairs = details => {
    return {
        type: ALLOWED_PAIRS,
        payload: details
    };
};

export const setswapToken = details => {
    return {
        type: SWAPTOKEN,
        payload: details
    };
};

export const setSettings = details => {
    return {
        type: SETTINGS,
        payload: details
    };
};

export const setGasFee = details => {
    return {
        type: GASFEE,
        payload: details
    };
};

export const setIsMarket = details => {
    return {
        type: ISMARKET,
        payload: details
    };
};

export const setIsRoute = details => {
    return {
        type: ISROUTE,
        payload: details
    };
};


export const selectNetwork = details => {
    return {
        type: NETWORK,
        payload: details
    };
};

export const setbridgeToken = details => {
    return {
        type: BRIDGETOKEN,
        payload: details
    };
};

export const setbridgeNetwork = details => {
    return {
        type: BRIDGENETWORK,
        payload: details
    };
};


export const setBridgeSettings = details => {
    return {
        type: BRIDGESETTINGS,
        payload: details
    };
};

export const setTransferValue = details => {
    return {
        type: TRANSFER,
        payload: details
    };
};

export const updateTransferValue = details => {
    return {
        type: UPDATETRANSFER,
        payload: details
    };
};

export const setTransferStatus = details => {
    return {
        type: TRANSFERSTATUS,
        payload: details
    };
};

export const setChainGasCost = details => {
    return {
        type: GASCOST,
        payload: details
    };
};

export const setSourceCount = details => {
    return {
        type: SOURCECOUNT,
        payload: details
    };
};

export const setLimitOrderToken = details => {
    return {
        type: LIMITORDERTOKEN,
        payload: details
    };
};

export const setpairToken = details => {
    return {
        type: PAIRTOKEN,
        payload: details
    };
};

export const setMarketPrice = details => {
    return {
        type: MARKETPRICE,
        payload: details
    };
};

export const setLoadHistory = details => {

    return {
        type: LOADHISTORY,
        payload: details
    };
};

export const setSwapData = details => {

    return {
        type: SWAPDATA,
        payload: details
    };
};

export const setSignin = details => {

    return {
        type: SIGNIN,
        payload: details
    };
};
export const setLanguage = details => {
    return {
        type: LANG,
        payload: details
    };
};



