// import constant
import {
    NETWORK
} from '../constant';

const initialState = {
    network: 0
};

const network = (state = initialState, action) => {

    switch (action.type) {
        case NETWORK:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default network;