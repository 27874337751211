export default {
  ABOUT_NEW_FEATURES: "새로운 기능에 대해.",
  ACCEPT: "수용하다",
  ACTIVE_ORDERS: "적극적인 주문",
  ADD_TOKEN: "토큰을 추가하십시오",
  AGGRESSIVE: "공격적인",
  AND: "그리고",
  APPROVE: "승인하다",
  ASSETS_ON_WAY: "자산이가는 길에 도착해야합니다.",
  AUTO: "자동",
  AVOID_TRANSFER:
    "중앙 집중식 교환 주소 (Binance, Huobi ...)로 전송하지 마십시오. 지원되는 기능이 아닙니다",
  BALANCE: "균형",
  BALANCE_ONE: "균형:",
  BLOG: "OTC",
  BRIDGE: "다리",
  CANCEL: "취소",
  CANCELLATION_TRANSACTION_FEE:
    "한도 주문 취소는 거래 수수료를 지불하는 것입니다.",
  CANCEL_ALL: "모두 취소하십시오",
  CANCEL_LIMIT_NOT_FILLED:
    "주문이 아직 채워지지 않은 한 제한 주문을 취소 할 수 있습니다.",
  CANCEL_LIMIT_ORDER: "제한 순서를 취소하십시오",
  CANCEL_ORDER: "주문 취소",
  CANCEL_SELECTED: "선택한 취소",
  CHAINS: "블로그",
  CHOOSE_NETWORK: "네트워크를 선택하십시오",
  CLASSIC_MODE: "클래식 모드",
  CLEAR: "분명한",
  CONFIRM: "확인하다",
  CONFIRMATION_REJECTED: "확인이 거부되었습니다.!",
  CONFIRM_SWAP: "스왑 확인",
  CONNECT_WALLET: "지갑을 연결하십시오",
  COPIED: "복사",
  CURRENT: "현재의",
  CUSTOM: "관습",
  DAYS: "날",
  DEFAULT: "기본",
  DETAILS: "세부",
  DISCLAIMER: "부인 성명",
  DISCONNECT_WALLET: "지갑을 분리하십시오",
  DISMISS: "해고하다",
  DUPLICATE_ORDER_SUBMIT: "중복 순서. 이것은 이미 제출되었습니다",
  ENTER_AMT: "금액을 입력하세요",
  EXCHANGE: "교환",
  EXPIRY: "만료",
  EXPIRY_SM: "만료",
  FAILED_LOAD_CHART: "차트를로드하지 못했습니다",
  FIND_BY_TOKEN_OR_STATUS: "토큰 또는 상태로 찾으십시오",
  INSUFFICIENT_BALANCE: "잔액 불충분",
  INSUFFICIENT: "불충분합니다",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "기존 주문의 잔액을 충당하기위한 잔액",
  RECEIVERS: "수신기",
  WALETADDRESS: "지갑 주소",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "서비스 약관 및 개인 정보 보호 정책을 수락하십시오",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "메이커 테이커는 동일한 테이커 주소가되어서는 안됩니다",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "미끄러짐 내성은 0.1%에서 15% 사이 여야합니다.",
  SEARCH_A_TOKEN: "토큰을 검색하십시오",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "가격 이이 비율보다 더 이상 변하지 않으면 거래가 되돌아 갈 것입니다.",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "거래의 가스 가격을 조정합니다.높은 gwei = 더 높은 속도.",
  REVIEW_OTC_ORDER: "OTC 순서를 검토하십시오",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "주문 제조업체가 설정 한 만료 기간.이 기간을 지나서 주문은 만료되어 보관됩니다.",
  SEARCH_A_LIQUIDITY_SOURCES: "유동성 소스를 검색하십시오",

  FAST: "빠른",
  FAST_AND_SECURE: "크로스 체인 자산을",
  FEATURE_TO_TRANSFER:
    "이 기능을 사용하여 토큰을 중앙 집중식 교환 주소로 전송하지 마십시오.",
  FEES: "수수료",
  FILL_ORDER: "순서를 채우십시오",
  FILL_OTC_ORDER: "OTC 순서를 채우십시오",
  FREE: "무료",
  FROM: "에서",
  GAS_COST: "가스 비용",
  GAS_DESTINATION: "목적지 체인의 가스",
  GAS_ON_DESTINATION: "목적지에 가스",
  GAS_PRICE: "가스 가격",
  GOT_IT: "알았어요",
  GOT_PROBLEM_JUST: "문제가 있습니까? 연락을 취하십시오.",
  HIGH: "높은",
  HOURS: "시간",
  IMPORT: "수입",
  IMPOSSIBLE_TO_RETRIEVE:
    "지원되는 기능이 아닙니다. 또한 채우는 주소를 다시 확인하십시오. 잘못된 주소로 전송 된 토큰은 검색 할 수 없습니다.",
  INSTANT: "즉각적인",
  INSUFFICIENT_APPROVED_AMT: "승인 된 금액이 불충분합니다",
  INVALID_TOKEN_ADDRESS: "잘못된 토큰 주소",
  KEEP_TRACK_NOTIFY:
    "패널 알림에서 추적 할 수 있습니다. 일단 완료되면 알려 드리겠습니다.",
  KEEP_YOU_POSTED: ". 계속 게시하겠습니다.",
  LEARN_MORE: "더 알아보기",
  LEGACY: "유산",
  LIMIT: "한계",
  LIMIT_ORDER: "제한 순서",
  LIMIT_PRICE: "제한 가격",
  LIMIT_PRICE_SM: "제한 가격",
  LIQUIDITY_SOURCES: "유동성 소스",
  LOW: "낮은",
  MAKER: "만드는 사람",
  MARKET: "시장",
  MAX: "맥스",
  MAX_BASE_FEE: "최대 기본 요금",
  MAX_S: "맥스",
  MAX_TO_PAY: "지불 할 맥스",
  MINIMUM_RECEIVED: "받은 최소",
  MINUTES: "분",
  MONTHS: "달",
  NEED_APPROVAL_MOVE: "이사하려면 귀하의 승인이 필요합니다",
  NEED_TO_WRAP: "포장해야합니다",
  NETWORK_FEES: "네트워크 요금",
  NETWORK_SM: "회로망.",
  NEVER: "절대",
  NONE: "없음",
  NON_CUSTODIAL_LIMIT:
    "비 양육권 제한 순서에서. 그것은 기술적 인 요구 사항입니다",
  NORMAL: "정상",
  NO_ORDERS_YET: "당신은 아직 주문이 없습니다",
  NO_RECORDS: "기록이 없습니다",
  ON_UR_BEHALF: "사용자를 대신하여.",
  OOPS_WRONG: "아차! 문제가 발생했습니다.!",
  OPEN: "열려 있는",
  ORDER_DETAILS: "주문 세부 사항",
  ORDER_HISTORY: "주문 내역",
  ORDER_PRICE: "주문 가격",
  ORDER_ROUTING: "주문 라우팅",
  OTC: "OTC",
  PASTE: "반죽",
  PAY: "지불하다",
  PENDING_CONFIRM: "지갑에 대한 확인 중.",
  PER: "당",
  PLEASE_CHOOSE_NETWORK: "네트워크를 선택하십시오",
  PLEASE_LOGIN: "로그인하십시오",
  PLEASE_TRY_LATER: "나중에 다시 시도 해주십시오",
  POPULAR_TOKENS: "인기있는 토큰",
  PRICE: "가격",
  PRICE_IMPACT: "가격 영향",
  PRIVACY_POLICY: "개인 정보 정책",
  RATE: "비율",
  READ_ACCEPT: "나는 읽고 받아들입니다",
  RECEIVE: "받다",
  RECEIVED_ORDERS: "명령을 받았습니다",
  REFUND: "환불하다",
  REMOVE: "제거하다",
  REVERSE_RATE: "역률",
  REVIEW_TRADE: "무역을 검토하십시오",
  SELECTED: "선택된",
  SELECT_ALL: "모두를 선택하십시오",
  SELECT_FROM_INFO: "정보에서 선택하십시오",
  SELECT_NETWORK: "네트워크를 선택하십시오",
  SELECT_TOKEN: "토큰을 선택하십시오",
  SELECT_TOKENN: "토큰을 선택하십시오",
  SELECT_TO_INFO: "정보를 선택하십시오",
  SETTINGS: "설정",
  SIMPLE_MODE: "간단한 모드",
  SLIPPAGE: "미끄러짐",
  SLIPPAGE_TOLERANCE: "미끄러짐 허용 오차",
  SRY_INSUFFICIENT: "죄송합니다, 불충분합니다",
  STATUS: "상태",
  STATUS_SM: "상태",
  STEP: "단계",
  SUBMIT: "제출하다",
  SUBMITTED_ORDERS: "제출 된 명령",
  SUBMIT_DATE: "날짜를 제출하십시오",
  SUCCESS_FILLED: "성공적으로 주문",
  SUCCESS_RECEIVED: "성공적으로 받았습니다",
  SUCCESS_SUBMITTED_ORDER: "주문을 성공적으로 제출했습니다",
  SWAP: "교환",
  SWAP_TRANSFER: "교환 및 전송",
  SWITCH_NETWORK: "스위치 네트워크",
  TAKER: "마시는 사람",
  TAKER_ADDRESS: "테이커 주소",
  TERMS_OF_SERVICE: "서비스 약관",
  TO: "에게",
  TOKEN_NOT_FOUND: "토큰을 찾을 수 없습니다",
  TOKEN_RECEIVE_SENT: "받을 토큰이 보내집니다",
  TRADE: "거래",
  TRANSACTION_REJECTED_LATER: "거래 거부 나중에 다시 시도하십시오",
  TRANSFER: "옮기다",
  TRANSFER_ALERT: "전송 경보",
  TRANSFER_TO: "로 전송",
  TX_COST: "TX 비용",
  UNLOCK: "터놓다",
  USER_GUIDE: "사용자 설명서",
  USE_ITAS: "w로 사용하십시오",
  VERIFY: "확인하다",
  VIEW_EXPLORER: "탐색기에서 트랜잭션을 봅니다",
  VIEW_ON: "보기",
  VIEW_TRANSACTION: "거래를 봅니다",
  WRAP: "포장하다",
  YOUR_ADDRESS: "주소",
  YOUR_ORDER_CANCEL: "주문이 취소되었습니다",
  YOUR_WALLET: "당신의 지갑",
  YOU_GET: "당신은 얻습니다",
  YOU_PAY: "당신은 지불합니다",
  YOU_RECEIVE: "당신은받습니다",
  YOU_WILL_RECEIVE: "당신은받을 것입니다",
  ANYONE_CAN_CREATE:
    "누구나 기존 토큰의 가짜 버전을 포함하여 모든 토큰을 만들 수 있습니다. 적절한주의를 기울이십시오. 일부 토큰과 기술 매개 변수는 서비스와 호환되지 않을 수 있습니다. 이 맞춤형 토큰을 가져 오면 위험을 인정하고 수락합니다.",
  BY_SIGNING_MESSAGE:
    "이 메시지에 서명함으로써 귀하는 귀하 가이 앱에 액세스하지 않거나 미국의 거주자 또는 기타 제한된 국가임을 확인합니다. 자세한 내용은 우리의 것을 읽으십시오",
  CONSTITUTION: "헌법",
  CURRENTLY_CONNECTED: "당신은 현재 연결되어 있습니다",
  IMPORT_TOKEN: "토큰을 가져옵니다",
  IN_YOUR_WALLET: "지갑에",
  MAKE_SURE_CHECKED: "토큰 주소를 확인했는지 확인하십시오.",
  ON: "~에",
  PEOPLE: "사람들",
  PLEASE_SWITCH: "전환하십시오",
  SELECTED_NOT_SUPPORTED:
    "지갑에서 네트워크를 변경하고 선택된 네트워크가 지원되지 않습니다.",
  SIGN_FOR_ACCESS: "액세스 권한 서명",
  SIGN_MESSAGE: "서명 메시지",
  LAUNCH_APP: "앱을 시작합니다",
  GETTING_STARTED: "HODL : 튜토리얼 및 제품 설명자를 시작합니다",
  DEEP_DIVE: "HODL에 대한 깊이 : 전략 및 제품 기능",
  BUILD_HODL: "Building on HODL: Technical Documentation",
  BLOG_HEADING: "HODL에 대한 빌딩 : 기술 문서",
  BLOG_PLACEHOLDER: "배우고 싶은 제품/주제 검색",
  CHOOSE_TOPIC: "네트워크를 선택하십시오",
  TRY: "노력하다",
  SEARCH_RESULTS: "검색 결과",
  SEARCH_TOPIC: "검색 주제",
  LOAD_MORE: "더로드하십시오",
  SOCIAL_MEDIA: "소셜 미디어",
  NEW: "최신 첫 번째",
  OLD: "가장 오래된 첫 번째",
  Z_A: "제목 Z-A 정렬",
  A_Z: "제목 A-Z 정렬",
  RESULTS: "결과",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "기본 가스 수수료는 네트워크에 의해 자동으로 계산되며 블록마다 다릅니다. 지불하고자하는 기본 수수료의 최대 금액을 설정하십시오. 설정 한 최대 기본 수수료와 실제 기본 수수료의 차이는 블록이 채굴되면 환불됩니다.",
  CANCELLED_TRANSACTION: "거래를 취소했습니다",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "제한 순서를 작성하는 것은 주문 제조업체가 취소하지 않는 한 무료 작업입니다. 주문 테이커는 주문을 채우는 동안 거래 수수료를 지불해야합니다.",
  Cancelled: "취소 된",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "최상의 경로와 값의 차이",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "이 옵션을 활성화하여 전송 비용이 내장 된 토큰을 교환하는 데 문제가있는 경우이 옵션을 활성화하십시오.",
  Expired: "만료되었습니다",
  Filled: "채우는",
  INSUFFICIENT_FOR_GASFEE:
    "죄송합니다. 가스 수수료에 대한 자금이 충분하지 않습니다",
  Open: "열려 있는",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "부분 채우기는 스왑 경로가 여러 기본 경로 부품으로 구성된 경우에만 적용됩니다. 시장 가격이 유리하지 않으면 부분적으로 집행 할 수있어 거래가 역전되는 것을 방지합니다.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "주문 제작자가 주문을 실행하기위한 가격 (수신 할 토큰 = x 토큰 지불)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "주문 제작자가 주문을 실행하기 위해 설정 한 가격 (지불 할 토큰 = y 토큰 수신",
  REVIEW_LIMIT_ORDER: "제한 순서를 검토하십시오",
  SEARCH_BY_TOKEN: "토큰 또는 주소로 검색하십시오",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "높은 미끄러짐 허용 오차를 설정하면 거래의 성공에 도움이 될 수 있지만 그러한 가격이 좋은 가격을 얻지 못할 수도 있습니다. 주의해서 사용하십시오.",
  STATUS_OF_THE_LIMIT_ORDER: "한도 순서의 상태",
  Suspended: "정지된",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "이 수수료는 네트워크 광부에게 지불됩니다. Hodl은 수수료를 통제하지 않습니다.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "거래 규모로 인한 시장 가격과 est. 가격의 차이",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "수신 된 최소 금액, 그렇지 않으면 거래가 되돌아갑니다.",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "이 수수료는 거래의 우선 순위를 정하는 인센티브로 광부에게 직접 진행됩니다.",
  TRANSACTION_REJECTED_TRY: "거래 거부 된 거래, 다시 시도하십시오",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "이 네트워크에서 선택한 토큰을 사용할 수 없습니다.",
  YOU: "너",
};
