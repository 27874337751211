import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import DexListModal from "../components/DexListModal";
import { useDispatch, useSelector } from "react-redux";
import { setSettings, setGasFee } from "../reducers/Actions";
import { GetDexSites } from "../Api/TokenActions";

export default function Settings(props) {
  var { reCheck } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const gasValue = useSelector((state) => state.gasValue);
  const sourceCount = useSelector((state) => state.sourceCount);
  const walletConnection = useSelector((state) => state.walletConnection);
  const [dexList, setdexList] = useState([]);
  const [slippageerr, setslippageerr] = useState("");

  useEffect(() => {
    getDexList();
    // eslint-disable-next-line
  }, [walletConnection.network]);

  async function getDexList() {
    var nw = parseInt(walletConnection.network);
    if (nw && nw > 0) {
      var { result } = await GetDexSites({ chainId: nw });
      result = result && JSON.parse(result);
      setdexList(result);
    }
  }

  const inputChange = async (event) => {
    setslippageerr("");

    dispatch(
      setSettings({
        slippage: event.target.value,
        gasmode: settings.gasmode,
      })
    );

    if (event.target.value > 15 || event.target.value < 0.1) {
      setslippageerr(t("SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN"));
      return;
    }

    localStorage.setItem("slippage", event.target.value);
  };

  function setGasPrice(type) {
    localStorage.setItem("gasmode", type);
    dispatch(
      setSettings({
        slippage: settings.slippage,
        gasmode: type,
      })
    );

    var mType =
      type === "Normal"
        ? "low"
        : type === "Fast"
        ? "market"
        : type === "Instant"
        ? "aggressive"
        : "market";
    console.log(mType, "mType-mType");
    dispatch(
      setGasFee({
        low: gasValue.low,
        market: gasValue.market,
        aggressive: gasValue.aggressive,
        custom: {
          base:
            gasValue && gasValue.custom && gasValue.custom.base
              ? gasValue.custom.base
              : 0,
          miner:
            gasValue && gasValue.custom && gasValue.custom.base
              ? gasValue.custom.base
              : 0,
        },
        base: gasValue.base,
        gastype: mType,
        selected: gasValue.selected,
      })
    );
  }
  function setSlippage(value) {
    localStorage.setItem("slippage", value);
    dispatch(
      setSettings({
        slippage: value,
        gasmode: settings.gasmode,
      })
    );
  }

  function closeSettings() {
    setslippageerr("");
    if (
      settings.slippage === "" ||
      parseFloat(settings.slippage) < 0.1 ||
      parseFloat(settings.slippage) > 15
    ) {
      dispatch(
        setSettings({
          slippage: 0.5,
          gasmode: settings.gasmode,
        })
      );
    }
  }

  function getSource1() {
    reCheck();
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="settings_modal"
        tabIndex="-1"
        aria-labelledby="settings_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="settings_modalLabel">
                {t("SETTINGS")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => closeSettings()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal_inner_head">
                <h2>{t("SLIPPAGE_TOLERANCE")}</h2>
                <img
                  src={require("../assets/images/info_icon.png")}
                  alt="Info"
                  id="slippage_tooltip"
                />
                <ReactTooltip
                  anchorId="slippage_tooltip"
                  place="top"
                  content={t("YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE")}
                />
              </div>
              <div className="modal_grp_btns">
                <button
                  className={
                    parseFloat(settings.slippage) === 0.1
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setSlippage("0.1")}
                >
                  0.1%
                </button>
                <button
                  className={
                    parseFloat(settings.slippage) === 0.5
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setSlippage("0.5")}
                >
                  0.5%
                </button>
                <button
                  className={
                    parseFloat(settings.slippage) === 1
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setSlippage("1")}
                >
                  1%
                </button>
              </div>
              <div className="input-group slipage_inp_grp slipage_inp_grp_nobg">
                <input
                  type="number"
                  className="form-control"
                  aria-label="slipage"
                  aria-describedby="slipage"
                  onChange={inputChange}
                  value={settings.slippage}
                />
                <span className="input-group-text" id="slipage">
                  %
                </span>
              </div>
              {slippageerr && slippageerr !== "" && <span>{slippageerr}</span>}
              <hr className="custom_hr" />
              <div className="modal_inner_head">
                <h2>{t("GAS_PRICE")}</h2>
                <img
                  src={require("../assets/images/info_icon.png")}
                  alt="Info"
                  id="gasprice_tooltip"
                />
                <ReactTooltip
                  anchorId="gasprice_tooltip"
                  place="top"
                  content={t("ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION")}
                />
              </div>
              <div className="modal_grp_btns">
                <button
                  className={
                    settings.gasmode === "Normal"
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setGasPrice("Normal")}
                >
                  {t("NORMAL")}
                </button>
                <button
                  className={
                    settings.gasmode === "Fast"
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setGasPrice("Fast")}
                >
                  {t("FAST")}
                </button>
                <button
                  className={
                    settings.gasmode === "Instant"
                      ? "settings_btn settings_btn_selected"
                      : "settings_btn"
                  }
                  onClick={() => setGasPrice("Instant")}
                >
                  {t("INSTANT")}
                </button>
              </div>

              <div className="flx_line">
                <div className="flx_line_div">
                  <p>{t("LIQUIDITY_SOURCES")}</p>
                </div>
                <div
                  className="cursor"
                  data-bs-toggle="modal"
                  data-bs-target="#select_dex_modal"
                >
                  {sourceCount && sourceCount.count ? sourceCount.count : 0}{" "}
                  {t("SELECTED")}
                  <i
                    data-bs-toggle="modal"
                    data-bs-target="#select_dex_modal"
                    className="fas fa-angle-down ms-2"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DexListModal dexList={dexList} getSource={getSource1} />
    </>
  );
}
