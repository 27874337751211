var apiurl = {
    1: "https://api.etherscan.io/api?",
    10: "https://mainnet.optimism.io",
    56: "https://api.bscscan.com/api?",
    128: "https://api.hecoinfo.com/api?",
    250: "https://api.ftmscan.com/api?",
    137: "https://api.polygonscan.com/api?",
    8453: "https://api.basescan.org/api?",
    42161: "https://arb1.arbitrum.io/rpc",
    43114: "https://gavax.blockscan.com/gasapi.ashx?"
};
export default apiurl;