import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { getTokenDetail, isAddress } from "../ContractActions/bep20Actions";
import config from "../config/config";
import { setswapToken } from "../reducers/Actions";
import { AddCustomToken, GetTokenList } from "../Api/TokenActions";

const initialState = {
    address: "",
    decimals: 0,
    symbol: "",
    logo: "",
    name: "",
    network: 0,
    useraddress: "",
    new: "no"
};

export default function AddToken(props) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const swapToken = useSelector((state) => state.swapToken);
    const walletConnection = useSelector((state) => state.walletConnection);

    const [tokenInfo, settokenInfo] = useState(initialState);
    const [allTokenList, setallTokenList] = useState([]);
    const [error, seterror] = useState("");

    const inputChange = async (event) => {
        var list = allTokenList;
        if (allTokenList && allTokenList.length === 0) {
            var data = {
                chainId: walletConnection.network,
                useraddress: walletConnection.address
            }
            var { result } = await GetTokenList(data);

            list = JSON.parse(result);
            setallTokenList(list);
        }


        var value = event.target.value;
        settokenInfo({ ...tokenInfo, ...{ address: value } });
        var { status } = await isAddress(value)
        console.log(status, '>>>>>>>?')
        if (status) {
            seterror("");
            var filteredData = list.filter(data => data.address.toLowerCase() === value.toLowerCase());

            if (filteredData && filteredData.length === 0) {
                var {
                    tokenName,
                    symbol,
                    decimals,
                    network,
                    useraddress
                } = await getTokenDetail(value);
                settokenInfo({
                    address: value,
                    decimals: decimals,
                    symbol: symbol,
                    logo: config.defaultLogo,
                    name: tokenName,
                    network: network,
                    useraddress: useraddress,
                    new: "yes"
                });
            } else if (filteredData && filteredData.length > 0) {

                settokenInfo({
                    address: filteredData[0].address,
                    decimals: filteredData[0].decimals,
                    symbol: filteredData[0].symbol,
                    logo: config.imageUrl + "/tokens/" + filteredData[0].logoURI,
                    name: filteredData[0].name,
                    network: network,
                    useraddress: useraddress,
                    new: "no"
                });


            }
        } else if (value !== "") {
            seterror("Invalid token address");
        } else {
            settokenInfo(initialState);
            seterror("");
        }
    }

    function importToken() {
        dispatch(setswapToken({
            fromaddress: tokenInfo.address,
            fromdecimal: tokenInfo.decimals,
            fromsymbol: tokenInfo.symbol,
            fromname: tokenInfo.symbol,
            network: tokenInfo.network,
            fromlogo: config.defaultLogo,
            toaddress: swapToken.toaddress,
            todecimal: swapToken.todecimal,
            tosymbol: swapToken.tosymbol,
            tologo: swapToken.tologo,
            toname: swapToken.toname
        }));
        window.$('#Add_token_modal').modal('hide');
        if (tokenInfo && tokenInfo.new === "yes") {
            AddCustomToken(tokenInfo);
        }

    }

    async function CloseModal() {
        settokenInfo(initialState);
    }

    return (
        <div
            className="modal fade primary_modal"
            id="Add_token_modal"
            tabIndex="-1"
            aria-labelledby="Add_token_modalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="Add_token_modalLabel">
                            {t('ADD_TOKEN')}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => CloseModal()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <>
                                <input
                                    className="token_search_inp"
                                    placeholder={t("SEARCH_A_TOKEN")}
                                    onChange={inputChange}
                                    value={tokenInfo.address}
                                />

                                <div className="token_search_list">
                                    {tokenInfo && tokenInfo.symbol !== "" &&
                                        <div className="token_search_list_row">
                                            <div>
                                                <img
                                                    src={tokenInfo.logo}
                                                    alt={tokenInfo.symbol}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = config.defaultLogo
                                                    }}
                                                />
                                                <div>
                                                    <span className="token_search_list_cur_name">{tokenInfo.symbol}</span>
                                                    <span className="token_search_list_cur_value">{tokenInfo.symbol}</span>
                                                </div>
                                            </div>
                                            {tokenInfo.new === "yes" &&
                                                <div>
                                                    {/* <span className="token_search_list_coin_dollar">$ 2.997108 </span> */}
                                                    <button className="connect_wallet_btn w-100" onClick={() => importToken()}>
                                                        {t('IMPORT')}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {error && error !== "" &&
                                        <div className="no_token_search_list_row">
                                            <img
                                                src={require("../assets/images/not_found.png")}
                                                alt={"Search token"}
                                                className="img-fluid"
                                            />
                                            <h2>{t('TOKEN_NOT_FOUND')}</h2>
                                            <h3>{t('INVALID_TOKEN_ADDRESS')}</h3>
                                        </div>
                                    }
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}