export default {
  ABOUT_NEW_FEATURES: "uusista ominaisuuksista.",
  ACCEPT: "Hyväksyä",
  ACTIVE_ORDERS: "Aktiiviset tilaukset",
  ADD_TOKEN: "Lisää tunnusmerkki",
  AGGRESSIVE: "Aggressiivinen",
  AND: "ja",
  APPROVE: "Hyväksyä",
  ASSETS_ON_WAY: "Omaisuuseräsi ovat matkalla ja niiden pitäisi saapua",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Vältä siirtämistä keskitettyyn vaihto -osoitukseen (Binance, Huobi ...). Se ei ole tuettu ominaisuus",
  BALANCE: "Saldo",
  BALANCE_ONE: "Saldo:",
  BLOG: "Blogi",
  BRIDGE: "Silta",
  CANCEL: "Peruuttaa",
  CANCELLATION_TRANSACTION_FEE:
    "Rajamääräyksen peruuttaminen sisältää transaktiomaksujen maksamisen.",
  CANCEL_ALL: "Peruuttaa kaikki",
  CANCEL_LIMIT_NOT_FILLED:
    "Voit peruuttaa rajatilauksen niin kauan kuin tilausta ei ole vielä täytetty.",
  CANCEL_LIMIT_ORDER: "Peruuta rajatilaus",
  CANCEL_ORDER: "Peruuta tilaus",
  CANCEL_SELECTED: "Peruuta valittu",
  CHAINS: "ketjut",
  CHOOSE_NETWORK: "Valitse verkko",
  CLASSIC_MODE: "Klassinen tila",
  CLEAR: "Asia selvä",
  CONFIRM: "Vahvistaa",
  CONFIRMATION_REJECTED: "Vahvistus hylättiin.!",
  CONFIRM_SWAP: "Vahvista vaihto",
  CONNECT_WALLET: "Kytke lompakko",
  COPIED: "Kopioitu",
  CURRENT: "Nykyinen",
  CUSTOM: "Mukautettu",
  DAYS: "Päivä",
  DEFAULT: "Laiminlyönti",
  DETAILS: "Yksityiskohdat",
  DISCLAIMER: "Vastuuvapauslauseke",
  DISCONNECT_WALLET: "Irrota lompakko",
  DISMISS: "Hylätä",
  DUPLICATE_ORDER_SUBMIT: "Kaksoiskappale. Tämä on jo lähetetty",
  ENTER_AMT: "Syötä summa",
  EXCHANGE: "Vaihto",
  EXPIRY: "Voimassaolo",
  EXPIRY_SM: "Voimassaolo",
  FAILED_LOAD_CHART: "Kaavion lataaminen epäonnistui",
  FIND_BY_TOKEN_OR_STATUS: "Löydä tunnusmerkki tai tila",
  INSUFFICIENT_BALANCE: "Riittämätön tasapaino",
  INSUFFICIENT: "Riittämätön",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "tasapaino nykyisten tilausten tasapainon kattamiseksi",
  RECEIVERS: "Vastaanottimen",
  WALETADDRESS: "Lompakon osoite",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Hyväksy palvelu- ja tietosuojakäytäntö",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker ei saisi olla sama Taker -osoite",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Liukumistoleranssin tulisi olla välillä 0,1–15%",
  SEARCH_A_TOKEN: "Etsi tunnusmerkki",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Tapahtumasi palautuu, jos hinta muuttuu epäsuotuisasti enemmän, että tämä prosenttiosuus",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Säätää kaupan kaasun hinnan.Suurempi gwei = suurempi nopeus.",
  REVIEW_OTC_ORDER: "Tarkista OTC -tilaus",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Tilauksen valmistajan asettama käyttöaika.Tämän ajanjakson ajan tilaus vanhenee ja arkistoidaan.",
  SEARCH_A_LIQUIDITY_SOURCES: "Etsi likviditeettilähteitä",

  FAST: "Nopeasti",
  FAST_AND_SECURE:
    "Turvallinen, nopea ja turvallisin tapa tuoda ristiketjun omaisuus",
  FEATURE_TO_TRANSFER:
    "Älä käytä tätä ominaisuutta siirtääksesi rahakkeet keskitetyn vaihdon osoitteeseen.",
  FEES: "Palkkiot",
  FILL_ORDER: "Täyttötilaus",
  FILL_OTC_ORDER: "Täytä OTC -tilaus",
  FREE: "Vapaa",
  FROM: "-Sta",
  GAS_COST: "Kaasukustannus",
  GAS_DESTINATION: "Kela kaasu kohdeketjussa",
  GAS_ON_DESTINATION: "Määränpää",
  GAS_PRICE: "Kaasun hinta",
  GOT_IT: "Sain sen",
  GOT_PROBLEM_JUST: "On ongelma? Ota vain yhteyttä.",
  HIGH: "Korkea",
  HOURS: "Tuntia",
  IMPORT: "Tuonti",
  IMPOSSIBLE_TO_RETRIEVE:
    "Se ei ole tuettu ominaisuus. Tarkista myös täyttämäsi osoite, kaikki väärään osoitteeseen lähetetyt rahakkeet ovat mahdotonta noutaa.",
  INSTANT: "Välitön",
  INSUFFICIENT_APPROVED_AMT: "Riittämätön hyväksytty määrä",
  INVALID_TOKEN_ADDRESS: "Virheellinen merkki -osoite",
  KEEP_TRACK_NOTIFY:
    "Voit seurata sitä paneeli -ilmoituksessa. Ilmoitamme sinulle, kun se on valmis.",
  KEEP_YOU_POSTED: ". Pidämme sinut ajan tasalla.",
  LEARN_MORE: "Oppia lisää",
  LEGACY: "Perintö",
  LIMIT: "Raja",
  LIMIT_ORDER: "rajaus",
  LIMIT_PRICE: "Rajata",
  LIMIT_PRICE_SM: "Rajata",
  LIQUIDITY_SOURCES: "Likviditeettilähteet",
  LOW: "Matala",
  MAKER: "Luoja",
  MARKET: "Markkinoida",
  MAX: "Max",
  MAX_BASE_FEE: "Max perusmaksu",
  MAX_S: "max",
  MAX_TO_PAY: "Maksa maksamaan",
  MINIMUM_RECEIVED: "Vähimmäis vastaanotettu",
  MINUTES: "Pöytäkirja",
  MONTHS: "Kuukaudet",
  NEED_APPROVAL_MOVE: "Tarvitsemme suostumuksesi liikkua",
  NEED_TO_WRAP: "Sinun täytyy kääriä",
  NETWORK_FEES: "Verkkopalkkiot",
  NETWORK_SM: "verkko.",
  NEVER: "Ei koskaan",
  NONE: "Ei mitään",
  NON_CUSTODIAL_LIMIT:
    "ei-huolehtimattomassa rajajärjestyksessä. Se on tekninen vaatimus",
  NORMAL: "Normaali",
  NO_ORDERS_YET: "Sinulla ei ole vielä tilauksia",
  NO_RECORDS: "Ei tietueita",
  ON_UR_BEHALF: "puolestasi.",
  OOPS_WRONG: "Hups! Jotain meni pieleen.!",
  OPEN: "Avata",
  ORDER_DETAILS: "Tilauksen tiedot",
  ORDER_HISTORY: "Tilaushistoria",
  ORDER_PRICE: "Tilaushinta",
  ORDER_ROUTING: "Tilausreititys",
  OTC: "OTC",
  PASTE: "Liittää",
  PAY: "Maksaa",
  PENDING_CONFIRM: "Odotettavissa vahvistusta lompakossa.",
  PER: "-",
  PLEASE_CHOOSE_NETWORK: "Valitse verkko",
  PLEASE_LOGIN: "Kirjaudu sisään",
  PLEASE_TRY_LATER: "Yritä uudelleen myöhemmin",
  POPULAR_TOKENS: "Suosittuja merkkejä",
  PRICE: "Hinta",
  PRICE_IMPACT: "Hintavaikutus",
  PRIVACY_POLICY: "Tietosuojakäytäntö",
  RATE: "Aste",
  READ_ACCEPT: "Luin ja hyväksyn",
  RECEIVE: "Vastaanottaa",
  RECEIVED_ORDERS: "Sai tilauksia",
  REFUND: "MAKSUN PALAUTUS",
  REMOVE: "Poista",
  REVERSE_RATE: "Peruutuskorko",
  REVIEW_TRADE: "Tarkista kauppa",
  SELECTED: "valittu",
  SELECT_ALL: "Valitse kaikki",
  SELECT_FROM_INFO: "Valitse tiedot",
  SELECT_NETWORK: "Valitse verkko",
  SELECT_TOKEN: "Valitse tunnusmerkki",
  SELECT_TOKENN: "Valitse Token",
  SELECT_TO_INFO: "Valitse tiedot",
  SETTINGS: "asetukset",
  SIMPLE_MODE: "Yksinkertainen tila",
  SLIPPAGE: "Liukuminen",
  SLIPPAGE_TOLERANCE: "Liukumistoleranssi",
  SRY_INSUFFICIENT: "Anteeksi, riittämätön",
  STATUS: "TILA",
  STATUS_SM: "Tila",
  STEP: "Askel",
  SUBMIT: "Lähetä",
  SUBMITTED_ORDERS: "Toimitettu tilaukset",
  SUBMIT_DATE: "Lähetä päivämäärä",
  SUCCESS_FILLED: "Tilaa onnistuneesti täytetty",
  SUCCESS_RECEIVED: "Onnistuneesti vastaanotettu",
  SUCCESS_SUBMITTED_ORDER: "Lähettänyt tilauksesi onnistuneesti",
  SWAP: "Vaihtaa",
  SWAP_TRANSFER: "Vaihda ja siirto",
  SWITCH_NETWORK: "Kytkentäverkko",
  TAKER: "Ottaja",
  TAKER_ADDRESS: "Ottajaosoite",
  TERMS_OF_SERVICE: "Käyttöehdot",
  TO: "-Lla",
  TOKEN_NOT_FOUND: "Tokenia ei löydy",
  TOKEN_RECEIVE_SENT: "Vastaanotettava merkki lähetetään",
  TRADE: "Käydä kauppaa",
  TRANSACTION_REJECTED_LATER: "Tapahtuma hylätään, yritä myöhemmin uudelleen",
  TRANSFER: "Siirtää",
  TRANSFER_ALERT: "Siirtohälytys",
  TRANSFER_TO: "Siirrä",
  TX_COST: "TX -kustannukset",
  UNLOCK: "Avata",
  USER_GUIDE: "Käyttöohjeet",
  USE_ITAS: "ja käytä sitä w",
  VERIFY: "Vahvistaa",
  VIEW_EXPLORER: "Näytä tapahtuma Explorerissa",
  VIEW_ON: "Katsella",
  VIEW_TRANSACTION: "Katsella tapahtumaa",
  WRAP: "Kääriä",
  YOUR_ADDRESS: "Osoitteesi",
  YOUR_ORDER_CANCEL: "Tilauksesi peruutetaan",
  YOUR_WALLET: "Sinun lompakkosi",
  YOU_GET: "Saat",
  YOU_PAY: "Sinä maksat",
  YOU_RECEIVE: "Saat",
  YOU_WILL_RECEIVE: "Sinä tulet vastaanottamaan",
  ANYONE_CAN_CREATE:
    "Kuka tahansa voi luoda minkä tahansa merkin, mukaan lukien olemassa olevien rahakkeiden väärennettyjä versioita. Ole varovainen. Jotkut rahakkeet ja niiden tekniset parametrit voivat olla yhteensopimattomia palveluidemme kanssa. Tuottamalla tämän mukautetun tunnuksen tunnustat ja hyväksyt riskit.",
  BY_SIGNING_MESSAGE:
    "Allekirjoittamalla tämän viestin vahvistat, että et käytä tätä sovellusta Yhdysvalloissa tai muissa rajoitetussa maassa. Lisätietoja on lukemalla meidän",
  CONSTITUTION: "Perustuslaki",
  CURRENTLY_CONNECTED: "Olet tällä hetkellä yhteydessä",
  IMPORT_TOKEN: "Tuo merkki",
  IN_YOUR_WALLET: "lompakossa",
  MAKE_SURE_CHECKED:
    "Varmista, että olet tarkistanut tunnusosoitteen ja se on oikein",
  ON: "päällä",
  PEOPLE: "Ihmiset",
  PLEASE_SWITCH: "Ole hyvä ja vaihda",
  SELECTED_NOT_SUPPORTED:
    "Vaihda verkosto lompakosi, valittua verkkoa ei tueta",
  SIGN_FOR_ACCESS: "Kirjaudu sisäänkäynnille",
  SIGN_MESSAGE: "Allekirjoittaa viesti",
  LAUNCH_APP: "Käynnistyssovellus",
  GETTING_STARTED: "HODL: n aloittaminen: Opetusohjelmat ja tuote selittäjät",
  DEEP_DIVE: "Deepdive Hodl: lle: Strategiat ja tuoteominaisuudet",
  BUILD_HODL: "Rakennus HODL: lle: Tekninen dokumentaatio",
  BLOG_HEADING:
    "Demystifious defi - kaikki mitä sinun tarvitsee tietää yhdessä paikassa. Aloittelijoille edistyneille käyttäjille ja kaikille niiden välillä.",
  BLOG_PLACEHOLDER: "Etsi tuotetta/aihetta, josta haluat oppia",
  CHOOSE_TOPIC: "Valitse verkko",
  TRY: "YRITTÄÄ",
  SEARCH_RESULTS: "Hakutulokset",
  SEARCH_TOPIC: "Hakukuva",
  LOAD_MORE: "Lataa lisää",
  SOCIAL_MEDIA: "Sosiaalinen media",
  NEW: "Uusin ensin",
  OLD: "Vanhin ensin",
  Z_A: "Lajittele otsikko Z-A",
  A_Z: "Lajittele otsikko A-Z",
  RESULTS: "Tulokset",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Verkko laskee automaattisesti peruskaasumaksun ja vaihtelee lohkosta toiseen. Aseta maksumaksun enimmäismäärä, jonka haluat maksaa. Ero asettamasi enimmäismaksun ja todellinen perusmaksu palautetaan, kun lohko on louhittu.",
  CANCELLED_TRANSACTION: "Peruutti tapahtumasi",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Rajatilauksen luominen on ilmainen operaatio tilauksen valmistajalle, elleivät ne peruuta. Tilauksen tekijän on maksettava tapahtumapalkkiot, kun ne täyttävät tilauksen.",
  Cancelled: "Peruutettu",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "Parhaan reitin ja arvon ero arvossa",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Ota tämä vaihtoehto käyttöön, jos sinulla on ongelmia, jotka vaihtavat merkin, jolla on sisäänrakennetut maksut siirtämisestä.",
  Expired: "Vanhentunut",
  Filled: "Täytetty",
  INSUFFICIENT_FOR_GASFEE: "Valitettavasti kaasumaksun riittämättömät varat",
  Open: "Avata",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Osittainen täyttö koskee vain, jos vaihtoreitti koostuu useista pääreitin osista. Se mahdollistaa osittaisen toteutuksen, estäen kaupan kääntymisen, jos markkinahinta siirtyy suosiosta.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Tilauksen valmistajan asettama hinta heidän tilauksensa toteuttamiseksi (tunnus vastaanotettavaksi = x tunnus maksettavaksi)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Tilauksen valmistajan asettama hinta heidän tilauksensa toteuttamiseksi (tunnus maksaa = y token vastaanottaa",
  REVIEW_LIMIT_ORDER: "Arviointirajatilaus",
  SEARCH_BY_TOKEN: "Etsi tunnusmerkki tai osoite",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Korkean liukumistoleranssin asettaminen voi auttaa tapahtumia onnistumaan, mutta et ehkä saa niin hyvää hintaa. Käytä varoen.",
  STATUS_OF_THE_LIMIT_ORDER: "Rajatilauksen tila",
  Suspended: "Keskeytetty",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Nämä maksut maksetaan verkkokaivosmiehille. HODL ei hallitse maksuja.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Markkinahinnan ja est. Ero kaupan koon takia",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Vähimmäis vastaanotettu määrä, muuten kauppa palautetaan",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Tämä maksu menee suoraan kaivostyöläisille kannustimena priorisoida tapahtumasi.",
  TRANSACTION_REJECTED_TRY: "Tapahtuma hylätty, yritä uudelleen",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Valitun tunnuksen siirto ei ole saatavana tässä verkossa",
  YOU: "Sinä",
};
