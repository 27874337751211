export default {
  ABOUT_NEW_FEATURES: "sobre os novos recursos.",
  ACCEPT: "Aceitar",
  ACTIVE_ORDERS: "Ordens ativas",
  ADD_TOKEN: "Adicione um token",
  AGGRESSIVE: "Agressivo",
  AND: "e",
  APPROVE: "Aprovar",
  ASSETS_ON_WAY: "Seus ativos estão a caminho e devem chegar",
  AUTO: "Auto",
  AVOID_TRANSFER:
    "Evite transferir para um endereço de troca centralizado (Binance, Huobi ...). Não é um recurso suportado",
  BALANCE: "Equilíbrio",
  BALANCE_ONE: "Equilíbrio:",
  BLOG: "OTC",
  BRIDGE: "Ponte",
  CANCEL: "Cancelar",
  CANCELLATION_TRANSACTION_FEE:
    "O cancelamento de uma ordem limite envolve o pagamento das taxas de transação.",
  CANCEL_ALL: "Cancelar tudo",
  CANCEL_LIMIT_NOT_FILLED:
    "Você pode cancelar uma ordem limite, desde que o pedido ainda não tenha sido preenchido.",
  CANCEL_LIMIT_ORDER: "Cancelar o pedido limite",
  CANCEL_ORDER: "Cancelar pedido",
  CANCEL_SELECTED: "Cancelar selecionado",
  CHAINS: "Blog",
  CHOOSE_NETWORK: "Escolha a rede",
  CLASSIC_MODE: "Modo clássico",
  CLEAR: "Claro",
  CONFIRM: "confirme",
  CONFIRMATION_REJECTED: "Confirmação rejeitada.!",
  CONFIRM_SWAP: "Confirme a troca",
  CONNECT_WALLET: "Conecte a carteira",
  COPIED: "Copiado",
  CURRENT: "Atual",
  CUSTOM: "Personalizado",
  DAYS: "Dias",
  DEFAULT: "Padrão",
  DETAILS: "Detalhes",
  DISCLAIMER: "Isenção de responsabilidade",
  DISCONNECT_WALLET: "Desconecte a carteira",
  DISMISS: "Liberar",
  DUPLICATE_ORDER_SUBMIT: "Pedido duplicado. Este já foi enviado",
  ENTER_AMT: "Insira o valor",
  EXCHANGE: "Intercâmbio",
  EXPIRY: "TERMO",
  EXPIRY_SM: "Termo",
  FAILED_LOAD_CHART: "Falha ao carregar gráfico",
  FIND_BY_TOKEN_OR_STATUS: "Encontre por token ou status",
  INSUFFICIENT_BALANCE: "Saldo insuficiente",
  INSUFFICIENT: "Insuficiente",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "Balance para cobrir o saldo de ordens existentes de",
  RECEIVERS: "Receptor",
  WALETADDRESS: "Endereço da carteira",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Aceite a política de termos de serviço e privacidade",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Taker do fabricante não deve ser o mesmo endereço de tomador",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "A tolerância a derrapagem deve estar entre 0,1% e 15%",
  SEARCH_A_TOKEN: "Procure um token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Sua transação será revertida se o preço mudar desfavorável por mais do que essa porcentagem",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Ajusta o preço do gás para sua transação.GWEI mais alto = velocidade mais alta.",
  REVIEW_OTC_ORDER: "Revise o OTC Order",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Período de validade definido pelo fabricante de pedidos.Após esse período, a ordem expirará e será arquivada.",
  SEARCH_A_LIQUIDITY_SOURCES: "Pesquise as fontes de liquidez",

  FAST: "Rápido",
  FAST_AND_SECURE:
    "A maneira segura, rápida e mais segura de trazer ativos de cadeia cruzada para",
  FEATURE_TO_TRANSFER:
    "Por favor, não use esse recurso para transferir seus tokens para um endereço de uma troca centralizada.",
  FEES: "Tarifas",
  FILL_ORDER: "Encher o pedido",
  FILL_OTC_ORDER: "Preencha o pedido OTC",
  FREE: "Livre",
  FROM: "De",
  GAS_COST: "Custo do gás",
  GAS_DESTINATION: "Gás na cadeia de destino",
  GAS_ON_DESTINATION: "Gás no destino",
  GAS_PRICE: "Preço do gás",
  GOT_IT: "Entendi",
  GOT_PROBLEM_JUST: "Tenho um problema? Apenas entre em contato.",
  HIGH: "Alto",
  HOURS: "Horas",
  IMPORT: "Importar",
  IMPOSSIBLE_TO_RETRIEVE:
    "Não é um recurso suportado. Além disso, verifique novamente o endereço que você preenche, quaisquer tokens enviados para o endereço errado serão impossíveis de recuperar.",
  INSTANT: "Instante",
  INSUFFICIENT_APPROVED_AMT: "Valor aprovado insuficiente",
  INVALID_TOKEN_ADDRESS: "Endereço de token inválido",
  KEEP_TRACK_NOTIFY:
    "Você pode acompanhar na sua notificação do painel. Nós o notificaremos assim que estiver pronto.",
  KEEP_YOU_POSTED: ". Vamos mantê -lo informado.",
  LEARN_MORE: "Saber mais",
  LEGACY: "Legado",
  LIMIT: "Limite",
  LIMIT_ORDER: "ordem limite",
  LIMIT_PRICE: "Preço limite",
  LIMIT_PRICE_SM: "Preço limite",
  LIQUIDITY_SOURCES: "Fontes de liquidez",
  LOW: "Baixo",
  MAKER: "criador",
  MARKET: "Mercado",
  MAX: "Máx",
  MAX_BASE_FEE: "Taxa base máxima",
  MAX_S: "máx",
  MAX_TO_PAY: "Máximo para pagar",
  MINIMUM_RECEIVED: "Mínimo recebido",
  MINUTES: "Minutos",
  MONTHS: "Meses",
  NEED_APPROVAL_MOVE: "Precisamos da sua aprovação para se mover",
  NEED_TO_WRAP: "Você precisa embrulhar",
  NETWORK_FEES: "Taxas de rede",
  NETWORK_SM: "rede.",
  NEVER: "Nunca",
  NONE: "Nenhum",
  NON_CUSTODIAL_LIMIT:
    "na ordem limite não custodial. É um requisito técnico no",
  NORMAL: "Normal",
  NO_ORDERS_YET: "Você ainda não tem ordens",
  NO_RECORDS: "Não há registros",
  ON_UR_BEHALF: "em seu nome.",
  OOPS_WRONG: "Opa, algo deu errado.!",
  OPEN: "Abrir",
  ORDER_DETAILS: "detalhes do pedido",
  ORDER_HISTORY: "Histórico de pedidos",
  ORDER_PRICE: "Preço do pedido",
  ORDER_ROUTING: "Roteamento de pedidos",
  OTC: "OTC",
  PASTE: "Colar",
  PAY: "Pagar",
  PENDING_CONFIRM: "Confirmação pendente na carteira.",
  PER: "por",
  PLEASE_CHOOSE_NETWORK: "Escolha a rede",
  PLEASE_LOGIN: "Por favor, faça o login",
  PLEASE_TRY_LATER: "Por favor, tente novamente mais tarde",
  POPULAR_TOKENS: "Tokens populares",
  PRICE: "Preço",
  PRICE_IMPACT: "Impacto de preço",
  PRIVACY_POLICY: "política de Privacidade",
  RATE: "Avaliar",
  READ_ACCEPT: "Eu li e aceito",
  RECEIVE: "Receber",
  RECEIVED_ORDERS: "Ordens recebidas",
  REFUND: "REEMBOLSO",
  REMOVE: "Remover",
  REVERSE_RATE: "Taxa reversa",
  REVIEW_TRADE: "Revisão do comércio",
  SELECTED: "selecionado",
  SELECT_ALL: "Selecionar tudo",
  SELECT_FROM_INFO: "Por favor, selecione FROM INFO",
  SELECT_NETWORK: "Selecione uma rede",
  SELECT_TOKEN: "Selecione um token",
  SELECT_TOKENN: "Selecione token",
  SELECT_TO_INFO: "Selecione para informações",
  SETTINGS: "Configurações",
  SIMPLE_MODE: "Modo simples",
  SLIPPAGE: "Deslizamento",
  SLIPPAGE_TOLERANCE: "Tolerância a derrapagem",
  SRY_INSUFFICIENT: "Desculpe, insuficiente",
  STATUS: "STATUS",
  STATUS_SM: "Status",
  STEP: "Etapa",
  SUBMIT: "Enviar",
  SUBMITTED_ORDERS: "Pedidos enviados",
  SUBMIT_DATE: "Data de submissão",
  SUCCESS_FILLED: "Encomendar com sucesso preenchido",
  SUCCESS_RECEIVED: "Recebido com sucesso",
  SUCCESS_SUBMITTED_ORDER: "Enviou com sucesso seu pedido",
  SWAP: "Trocar",
  SWAP_TRANSFER: "Trocar e transferir",
  SWITCH_NETWORK: "Switch Network",
  TAKER: "Tomador",
  TAKER_ADDRESS: "Endereço do tomador",
  TERMS_OF_SERVICE: "Termos de serviço",
  TO: "Para",
  TOKEN_NOT_FOUND: "Token não encontrado",
  TOKEN_RECEIVE_SENT: "O token a receber será enviado para",
  TRADE: "Troca",
  TRANSACTION_REJECTED_LATER:
    "Transação rejeitada, por favor tente novamente mais tarde",
  TRANSFER: "Transferir",
  TRANSFER_ALERT: "Alerta de transferência",
  TRANSFER_TO: "Transferir para",
  TX_COST: "Custo tx",
  UNLOCK: "Desbloquear",
  USER_GUIDE: "Guia de usuario",
  USE_ITAS: "e use -o como W",
  VERIFY: "Verificar",
  VIEW_EXPLORER: "Veja a transação no Explorer",
  VIEW_ON: "Ver",
  VIEW_TRANSACTION: "Visualizar transação",
  WRAP: "Enrolar",
  YOUR_ADDRESS: "Seu endereço",
  YOUR_ORDER_CANCEL: "Seu pedido é cancelado",
  YOUR_WALLET: "Sua carteira",
  YOU_GET: "Você consegue",
  YOU_PAY: "Você paga",
  YOU_RECEIVE: "Tu recebes",
  YOU_WILL_RECEIVE: "Você receberá",
  ANYONE_CAN_CREATE:
    "Qualquer pessoa pode criar qualquer token, incluindo versões falsas dos tokens existentes. Tome o devido cuidado. Alguns tokens e seus parâmetros técnicos podem ser incompatíveis com nossos serviços. Ao importar esse token personalizado, você reconhece e aceita os riscos.",
  BY_SIGNING_MESSAGE:
    "Ao assinar esta mensagem, você confirma que não está acessando este aplicativo ou é um residente dos EUA ou de qualquer outro país restrito. Para mais informações, leia nosso",
  CONSTITUTION: "ConstitutionDao",
  CURRENTLY_CONNECTED: "Você está atualmente conectado a",
  IMPORT_TOKEN: "Importar um token",
  IN_YOUR_WALLET: "em sua carteira",
  MAKE_SURE_CHECKED:
    "Verifique se você verificou o endereço do token e está correto",
  ON: "sobre",
  PEOPLE: "Pessoas",
  PLEASE_SWITCH: "Por favor, mude para",
  SELECTED_NOT_SUPPORTED:
    "Altere a rede em sua carteira, a rede selecionada não é suportada",
  SIGN_FOR_ACCESS: "Sinal para acesso",
  SIGN_MESSAGE: "Mensagem de assinar",
  LAUNCH_APP: "Lançamento do Aplicativo",
  GETTING_STARTED: "Introdução ao HODL: Tutoriais e explicadores de produtos",
  DEEP_DIVE: "Deepdive em HODL: estratégias e recursos do produto",
  BUILD_HODL: "Construindo no HODL: documentação técnica",
  BLOG_HEADING:
    "Defi desmistificante - tudo o que você precisa saber em um só lugar. Para iniciantes para usuários avançados e todos.",
  BLOG_PLACEHOLDER: "Procure o produto/tópico que você deseja aprender",
  CHOOSE_TOPIC: "Escolha o tópico",
  TRY: "Tentar",
  SEARCH_RESULTS: "Procurar Resultados",
  SEARCH_TOPIC: "Tópico de pesquisa",
  LOAD_MORE: "Carregue mais",
  SOCIAL_MEDIA: "Mídia social",
  NEW: "Os mais novos primeiro",
  OLD: "Mais velhos primeiro",
  Z_A: "Classificar o título Z-A",
  A_Z: "Classificar o título A-Z",
  RESULTS: "Resultados",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Uma taxa de gás de base é calculada automaticamente pela rede e varia de bloco para bloco. Defina o valor máximo da taxa básica que você deseja pagar. Uma diferença entre a taxa base máxima que você definiu e a taxa básica real será reembolsada assim que o bloco for extraído.",
  CANCELLED_TRANSACTION: "Cancelou sua transação",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Criar uma ordem limite é uma operação gratuita para o criador de pedidos, a menos que eles cancelem. O Torker de Ordem deverá pagar pelas Taxas de Transação enquanto eles preencherão o pedido.",
  Cancelled: "Cancelado",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Diferença de valor entre a melhor rota e",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Habilite esta opção se estiver tendo problemas para trocar um token que possui taxas internas para transferência.",
  Expired: "Expirado",
  Filled: "Preenchido",
  INSUFFICIENT_FOR_GASFEE: "Desculpe, fundos insuficientes para taxa de gás",
  Open: "Abrir",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "O preenchimento parcial se aplica apenas se uma rota de troca consistir em várias peças da rota principal. Permite a execução parcial, impedindo que a transação seja revertida se o preço do mercado for desprovido de favor.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Preço definido pelo fabricante de pedidos para executar seu pedido (token para receber = x token a pagar)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Preço definido pelo fabricante de pedidos para executar seu pedido (token para pagar = y token para receber",
  REVIEW_LIMIT_ORDER: "Revise o pedido limite",
  SEARCH_BY_TOKEN: "Pesquise por token ou endereço",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Definir uma alta tolerância a derrapagem pode ajudar as transações a ter sucesso, mas você pode não obter um preço tão bom. Use com cuidado.",
  STATUS_OF_THE_LIMIT_ORDER: "Status da ordem limite",
  Suspended: "Suspenso",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Essas taxas são pagas aos mineradores de rede. Hodl não controla as taxas.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "A diferença entre o preço de mercado e o preço da EST devido ao tamanho do comércio",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "O valor mínimo recebido, caso contrário a transação será revertida",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Essa taxa vai diretamente para os mineiros como um incentivo para priorizar sua transação.",
  TRANSACTION_REJECTED_TRY: "Transação rejeitada, por favor tente novamente",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Transferência para o token selecionado não está disponível nesta rede",
  YOU: "Você",
};
