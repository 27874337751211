export default {
  ABOUT_NEW_FEATURES: "για τα νέα χαρακτηριστικά.",
  ACCEPT: "Αποδέχομαι",
  ACTIVE_ORDERS: "Ενεργές παραγγελίες",
  ADD_TOKEN: "Προσθέστε ένα διακριτικό",
  AGGRESSIVE: "Επιθετικός",
  AND: "και",
  APPROVE: "Εγκρίνω",
  ASSETS_ON_WAY:
    "Τα περιουσιακά σας στοιχεία είναι στο δρόμο και πρέπει να φτάσουν",
  AUTO: "Αυτο",
  AVOID_TRANSFER:
    "Αποφύγετε τη μεταφορά σε μια κεντρική διεύθυνση ανταλλαγής (Binance, Huobi ...). Δεν είναι υποστηριζόμενη λειτουργία",
  BALANCE: "Ισορροπία",
  BALANCE_ONE: "Ισορροπία:",
  BLOG: "Ιστολόγιο",
  BRIDGE: "Γέφυρα",
  CANCEL: "Ματαίωση",
  CANCELLATION_TRANSACTION_FEE:
    "Η ακύρωση μιας οριακής εντολής περιλαμβάνει την καταβολή των τελών συναλλαγής.",
  CANCEL_ALL: "Ακύρωση όλων",
  CANCEL_LIMIT_NOT_FILLED:
    "Μπορείτε να ακυρώσετε μια οριακή παραγγελία, εφόσον η παραγγελία δεν έχει πληρωθεί ακόμα.",
  CANCEL_LIMIT_ORDER: "Ακύρωση οριακής παραγγελίας",
  CANCEL_ORDER: "Ακύρωση παραγγελίας",
  CANCEL_SELECTED: "Ακύρωση επιλεγμένο",
  CHAINS: "αλυσίδες",
  CHOOSE_NETWORK: "Επιλέξτε το δίκτυο",
  CLASSIC_MODE: "Κλασική λειτουργία",
  CLEAR: "Σαφή",
  CONFIRM: "Επιβεβαιώνω",
  CONFIRMATION_REJECTED: "Η επιβεβαίωση απορρίφθηκε.!",
  CONFIRM_SWAP: "Επιβεβαιώστε την ανταλλαγή",
  CONNECT_WALLET: "Συνδέστε το πορτοφόλι",
  COPIED: "Αντιγραφεί",
  CURRENT: "Ρεύμα",
  CUSTOM: "Εθιμο",
  DAYS: "Ημέρες",
  DEFAULT: "Προκαθορισμένο",
  DETAILS: "Λεπτομέριες",
  DISCLAIMER: "Αποποίηση ευθυνών",
  DISCONNECT_WALLET: "Αποσυνδέστε το πορτοφόλι",
  DISMISS: "Απολύω",
  DUPLICATE_ORDER_SUBMIT: "Διπλή παραγγελία. Αυτό έχει ήδη υποβληθεί",
  ENTER_AMT: "Εισάγετε το ποσό",
  EXCHANGE: "Ανταλλαγή",
  EXPIRY: "ΛΗΞΗ",
  EXPIRY_SM: "Λήξη",
  FAILED_LOAD_CHART: "Αποτυχία φόρτωσης γραφήματος",
  FIND_BY_TOKEN_OR_STATUS: "Βρείτε με διακριτικό ή κατάσταση",
  INSUFFICIENT_BALANCE: "Ανεπαρκής ισορροπία",
  INSUFFICIENT: "Ανεπαρκής",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "ισορροπία για την κάλυψη των υφιστάμενων παραγγελιών ισορροπία του",
  RECEIVERS: "Δέκτης",
  WALETADDRESS: "Διεύθυνση πορτοφολιού",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "Bitte akzeptieren Sie Nutzungsbedingungen und Datenschutzbestimmungen",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "Maker Taker sollte nicht gleiche Taker -Adresse sein",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "Die Schlupftoleranz sollte zwischen 0,1% und 15% liegen",
  SEARCH_A_TOKEN: "Suche nach einem Token",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "Ihre Transaktion wird zurückkehren, wenn sich der Preis um mehr als diesen Prozentsatz ändert",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "Passt den Gaspreis für Ihre Transaktion an.Höhere Gwei = höhere Geschwindigkeit.",
  REVIEW_OTC_ORDER: "Überprüfen Sie die OTC -Bestellung",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "Ablaufzeit des Order Makers.In diesem Zeitraum läuft die Bestellung aus und wird archiviert.",
  SEARCH_A_LIQUIDITY_SOURCES: "Suchen Sie nach Liquiditätsquellen",

  FAST: "Γρήγορα",
  FAST_AND_SECURE:
    "Ο ασφαλής, γρήγορος και πιο ασφαλής τρόπος για να φέρετε περιουσιακά στοιχεία",
  FEATURE_TO_TRANSFER:
    "Μην χρησιμοποιείτε αυτήν τη λειτουργία για να μεταφέρετε τα μάρκες σας σε μια διεύθυνση κεντρικής ανταλλαγής.",
  FEES: "Αμοιβές",
  FILL_ORDER: "Συμπληρώστε την παραγγελία",
  FILL_OTC_ORDER: "Συμπληρώστε την παραγγελία OTC",
  FREE: "Ελεύθερος",
  FROM: "Από",
  GAS_COST: "Κόστος φυσικού αερίου",
  GAS_DESTINATION: "Φυσικό αέριο στην αλυσίδα προορισμού",
  GAS_ON_DESTINATION: "Φυσικό αέριο στον προορισμό",
  GAS_PRICE: "Τιμή φυσικού αερίου",
  GOT_IT: "Το έπιασα",
  GOT_PROBLEM_JUST: "Έχετε πρόβλημα; Απλά επικοινωνήστε.",
  HIGH: "Υψηλός",
  HOURS: "Ωρες",
  IMPORT: "Εισαγωγή",
  IMPOSSIBLE_TO_RETRIEVE:
    "Δεν είναι ένα υποστηριζόμενο χαρακτηριστικό. Επίσης, παρακαλούμε να ελέγξετε τη διεύθυνση που συμπληρώνετε, τυχόν μάρκες που αποστέλλονται σε λάθος διεύθυνση θα είναι αδύνατο να ανακτήσετε.",
  INSTANT: "Στιγμή",
  INSUFFICIENT_APPROVED_AMT: "Ανεπαρκές εγκεκριμένο ποσό",
  INVALID_TOKEN_ADDRESS: "Μη έγκυρη διεύθυνση διακόπτη",
  KEEP_TRACK_NOTIFY:
    "Μπορείτε να παρακολουθείτε την ειδοποίηση του πίνακα. Θα σας ειδοποιήσουμε μόλις τελειώσει.",
  KEEP_YOU_POSTED: ". Θα σας κρατήσουμε αναρτημένους.",
  LEARN_MORE: "Μάθε περισσότερα",
  LEGACY: "Κληρονομιά",
  LIMIT: "Οριο",
  LIMIT_ORDER: "περιορίζω την παραγγελία",
  LIMIT_PRICE: "Περιοριστική τιμή",
  LIMIT_PRICE_SM: "Περιοριστική τιμή",
  LIQUIDITY_SOURCES: "Πηγές ρευστότητας",
  LOW: "Χαμηλός",
  MAKER: "Κατασκευαστής",
  MARKET: "Αγορά",
  MAX: "Μέγιστη",
  MAX_BASE_FEE: "Μέγιστο τέλος βάσης",
  MAX_S: "Μέγιστη",
  MAX_TO_PAY: "Max για πληρωμή",
  MINIMUM_RECEIVED: "Ελάχιστο ληφθεί",
  MINUTES: "Λεπτά",
  MONTHS: "Μήνες",
  NEED_APPROVAL_MOVE: "Χρειαζόμαστε την έγκρισή σας για να μετακινηθείτε",
  NEED_TO_WRAP: "Πρέπει να τυλίξετε",
  NETWORK_FEES: "Αμοιβές δικτύου",
  NETWORK_SM: "δίκτυο.",
  NEVER: "Ποτέ",
  NONE: "Κανένας",
  NON_CUSTODIAL_LIMIT:
    "στη μη στερητική σειρά ορίων. Είναι μια τεχνική απαίτηση για το",
  NORMAL: "Κανονικός",
  NO_ORDERS_YET: "Δεν έχετε ακόμα παραγγελίες",
  NO_RECORDS: "Χωρίς αρχεία",
  ON_UR_BEHALF: "εκ μέρους σου.",
  OOPS_WRONG: "Ωχ! Κάτι πήγε στραβά.!",
  OPEN: "Ανοιξε",
  ORDER_DETAILS: "Λεπτομέρειες Παραγγελίας",
  ORDER_HISTORY: "Ιστορικό παραγγελιών",
  ORDER_PRICE: "Τιμή παραγγελίας",
  ORDER_ROUTING: "Δρομολόγηση παραγγελιών",
  OTC: "OTC",
  PASTE: "Επικόλληση",
  PAY: "Πληρωμή",
  PENDING_CONFIRM: "Εν αναμονή επιβεβαίωσης στο πορτοφόλι.",
  PER: "ανά",
  PLEASE_CHOOSE_NETWORK: "Επιλέξτε το δίκτυο",
  PLEASE_LOGIN: "Συνδεθείτε",
  PLEASE_TRY_LATER: "Παρακαλώ δοκιμάστε ξανά αργότερα",
  POPULAR_TOKENS: "Δημοφιλή μάρκες",
  PRICE: "Τιμή",
  PRICE_IMPACT: "Αντίκτυπο τιμών",
  PRIVACY_POLICY: "Πολιτική απορρήτου",
  RATE: "Τιμή",
  READ_ACCEPT: "Διάβασα και αποδέχομαι",
  RECEIVE: "Λαμβάνω",
  RECEIVED_ORDERS: "Έλαβε παραγγελίες",
  REFUND: "ΕΠΙΣΤΡΟΦΗ ΧΡΗΜΑΤΩΝ",
  REMOVE: "Αφαιρώ",
  REVERSE_RATE: "Αντίστροφος ρυθμός",
  REVIEW_TRADE: "Αναθεωρήστε το εμπόριο",
  SELECTED: "επιλεγμένος",
  SELECT_ALL: "Επιλογή όλων",
  SELECT_FROM_INFO: "Επιλέξτε από πληροφορίες",
  SELECT_NETWORK: "Επιλέξτε ένα δίκτυο",
  SELECT_TOKEN: "Επιλέξτε ένα διακριτικό",
  SELECT_TOKENN: "Επιλέξτε Token",
  SELECT_TO_INFO: "Επιλέξτε στις πληροφορίες",
  SETTINGS: "Ρυθμίσεις",
  SIMPLE_MODE: "Απλή λειτουργία",
  SLIPPAGE: "Ολίσθηση",
  SLIPPAGE_TOLERANCE: "Ανοχή ολίσθησης",
  SRY_INSUFFICIENT: "Συγγνώμη, ανεπαρκής",
  STATUS: "ΚΑΤΑΣΤΑΣΗ",
  STATUS_SM: "Κατάσταση",
  STEP: "Βήμα",
  SUBMIT: "υποβάλλουν",
  SUBMITTED_ORDERS: "Υποβληθείσες παραγγελίες",
  SUBMIT_DATE: "Ημερομηνία υποβολής",
  SUCCESS_FILLED: "Πλήρης παραγγελία με επιτυχία",
  SUCCESS_RECEIVED: "Επιτυχώς ελήφθη",
  SUCCESS_SUBMITTED_ORDER: "Υποβλήθηκε με επιτυχία την παραγγελία σας",
  SWAP: "Ανταλαγή",
  SWAP_TRANSFER: "Ανταλλαγή και μεταφορά",
  SWITCH_NETWORK: "Διακόπτης δικτύου",
  TAKER: "Λήπτης",
  TAKER_ADDRESS: "Διεύθυνση λήψης",
  TERMS_OF_SERVICE: "Όροι χρήσης",
  TO: "Προς την",
  TOKEN_NOT_FOUND: "Το Token δεν βρέθηκε",
  TOKEN_RECEIVE_SENT: "Το διακριτικό που θα λάβει θα σταλεί στο",
  TRADE: "Εμπορικές συναλλαγές",
  TRANSACTION_REJECTED_LATER:
    "Απορρίπτεται η συναλλαγή, δοκιμάστε ξανά αργότερα",
  TRANSFER: "ΜΕΤΑΦΟΡΑ",
  TRANSFER_ALERT: "Συναγερμός μεταφοράς",
  TRANSFER_TO: "Μεταφέρω σε",
  TX_COST: "Κόστος TX",
  UNLOCK: "Ξεκλείδωμα",
  USER_GUIDE: "ΟΔΗΓΟΣ ΧΡΗΣΤΗ",
  USE_ITAS: "και χρησιμοποιήστε το ως w",
  VERIFY: "Επαληθεύω",
  VIEW_EXPLORER: "Προβολή συναλλαγής στο Explorer",
  VIEW_ON: "Βλέπω",
  VIEW_TRANSACTION: "Προβολή συναλλαγής",
  WRAP: "Κάλυμμα",
  YOUR_ADDRESS: "Η διεύθυνση σου",
  YOUR_ORDER_CANCEL: "Η παραγγελία σας ακυρώνεται",
  YOUR_WALLET: "Το πορτοφόλι σου",
  YOU_GET: "Παίρνεις",
  YOU_PAY: "Εσύ πληρώνεις",
  YOU_RECEIVE: "Λαμβάνεις",
  YOU_WILL_RECEIVE: "Θα λάβετε",
  ANYONE_CAN_CREATE:
    "Ο καθένας μπορεί να δημιουργήσει οποιοδήποτε διακριτικό, συμπεριλαμβανομένων ψεύτικων εκδόσεων των υφιστάμενων μαρκών. Πάρτε τη δέουσα προσοχή. Ορισμένες μάρκες και οι τεχνικές τους παράμετροι μπορεί να είναι ασυμβίβαστες με τις υπηρεσίες μας. Με την εισαγωγή αυτού του προσαρμοσμένου διακριτικού αναγνωρίζετε και αποδέχεστε τους κινδύνους.",
  BY_SIGNING_MESSAGE:
    "Υπογράφοντας αυτό το μήνυμα, επιβεβαιώνετε ότι δεν έχετε πρόσβαση σε αυτήν την εφαρμογή από ή είστε κάτοικος των ΗΠΑ ή οποιαδήποτε άλλη περιορισμένη χώρα. Για περισσότερες πληροφορίες, διαβάστε το δικό μας",
  CONSTITUTION: "Σύνταγμα",
  CURRENTLY_CONNECTED: "Είστε συνδεδεμένοι επί του παρόντος",
  IMPORT_TOKEN: "Εισαγάγετε ένα διακριτικό",
  IN_YOUR_WALLET: "στο πορτοφόλι σας",
  MAKE_SURE_CHECKED:
    "Βεβαιωθείτε ότι έχετε ελέγξει τη διεύθυνση συμβολαίου και είναι σωστό",
  ON: "επί",
  PEOPLE: "Ανθρωποι",
  PLEASE_SWITCH: "παρακαλώ μεταβείτε στο",
  SELECTED_NOT_SUPPORTED:
    "Αλλάξτε το δίκτυο στο πορτοφόλι σας, το επιλεγμένο δίκτυο δεν υποστηρίζεται",
  SIGN_FOR_ACCESS: "Υπογραφή για πρόσβαση",
  SIGN_MESSAGE: "Υπογραφή μηνύματος",
  LAUNCH_APP: "Εκκίνηση",
  GETTING_STARTED: "Ξεκινώντας με το HODL: Tutorials & Exployers Products",
  DEEP_DIVE: "Deepdive στο HODL: Στρατηγικές και χαρακτηριστικά προϊόντων",
  BUILD_HODL: "Βασιζόμενη στο HODL: Τεχνική τεκμηρίωση",
  BLOG_HEADING:
    "Demystifying Defi - Όλα όσα πρέπει να γνωρίζετε σε ένα μέρος. Για αρχάριους σε προχωρημένους χρήστες και όλους τους ενδιάμεσους.",
  BLOG_PLACEHOLDER: "Αναζητήστε το προϊόν/θέμα για το οποίο θέλετε να μάθετε",
  CHOOSE_TOPIC: "Επιλέξτε το δίκτυο",
  TRY: "ΔΟΚΙΜΑΣΤΕ",
  SEARCH_RESULTS: "Αποτελέσματα αναζήτησης",
  SEARCH_TOPIC: "Θέμα αναζήτησης",
  LOAD_MORE: "Φόρτωσε περισσότερα",
  SOCIAL_MEDIA: "Μεσα ΚΟΙΝΩΝΙΚΗΣ ΔΙΚΤΥΩΣΗΣ",
  NEW: "Νεότερος πρώτος",
  OLD: "Πρώτος πρώτος",
  Z_A: "Ταξινόμηση τίτλου Z-A",
  A_Z: "Ταξινόμηση τίτλου A-Z",
  RESULTS: "Αποτελέσματα",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "Ένα τέλος βάσης αερίου υπολογίζεται αυτόματα από το δίκτυο και ποικίλλει από μπλοκ σε μπλοκ. Ρυθμίστε το μέγιστο ποσό της βασικής αμοιβής που θέλετε να πληρώσετε. Μια διαφορά μεταξύ του μέγιστου βάσης που ορίσατε και της πραγματικής αμοιβής βάσης θα επιστραφεί μόλις εξορύσσεται το μπλοκ.",
  CANCELLED_TRANSACTION: "Ακυρώθηκε η συναλλαγή σας",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "Η δημιουργία μιας οριοθετημένης παραγγελίας είναι μια δωρεάν λειτουργία για τον κατασκευαστή παραγγελιών εκτός εάν ακυρώσουν. Ο παραλήπτης θα πρέπει να πληρώσει για τα τέλη συναλλαγών ενώ συμπληρώνει την παραγγελία.",
  Cancelled: "Ακυρώθηκε",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "Διαφορά στην αξία μεταξύ της καλύτερης διαδρομής και",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "Ενεργοποιήστε αυτήν την επιλογή εάν αντιμετωπίζετε προβλήματα με την ανταλλαγή ενός διακριτικού που έχει ενσωματωμένα τέλη για μεταφορά.",
  Expired: "έχει λήξει",
  Filled: "γέματο",
  INSUFFICIENT_FOR_GASFEE:
    "Συγγνώμη, ανεπαρκή κεφάλαια για αμοιβή φυσικού αερίου",
  Open: "Ανοιξε",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "Η μερική πλήρωση ισχύει μόνο εάν μια διαδρομή ανταλλαγής αποτελείται από πολλαπλά τμήματα κύριων διαδρομών. Επιτρέπει τη μερική εκτέλεση, εμποδίζοντας την αντιστροφή της συναλλαγής εάν η τιμή αγοράς απομακρυνθεί.",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "Τιμή που ορίστηκε από τον κατασκευαστή παραγγελιών για την εκτέλεση της παραγγελίας τους (Token για λήψη = X Token για πληρωμή)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "Τιμή που ορίστηκε από τον κατασκευαστή παραγγελιών για την εκτέλεση της παραγγελίας τους (Token να πληρώσει = y Token για λήψη",
  REVIEW_LIMIT_ORDER: "Αναθεωρημένη σειρά ορίων",
  SEARCH_BY_TOKEN: "Αναζήτηση με διακριτικό ή διεύθυνση",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "Ο καθορισμός μιας υψηλής ανοχής ολίσθησης μπορεί να βοηθήσει τις συναλλαγές να επιτύχει, αλλά μπορεί να μην έχετε τόσο καλή τιμή. Χρησιμοποιήστε με προσοχή.",
  STATUS_OF_THE_LIMIT_ORDER: "Κατάσταση της σειράς ορίου",
  Suspended: "Ανασταλεί",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "Αυτά τα τέλη καταβάλλονται στους ανθρακωρύχους δικτύου. Το Hodl δεν ελέγχει τα τέλη.",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "Η διαφορά μεταξύ της τιμής της αγοράς και της τιμής λόγω του μεγέθους του εμπορίου",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "Το ελάχιστο ποσό που λαμβάνεται, διαφορετικά η συναλλαγή θα επανέλθει",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "Αυτό το τέλος πηγαίνει απευθείας στους ανθρακωρύχους ως κίνητρο για την προτεραιότητα της συναλλαγής σας.",
  TRANSACTION_REJECTED_TRY: "Η συναλλαγή απορρίφθηκε, δοκιμάστε ξανά",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "Η μεταφορά για το επιλεγμένο διακριτικό δεν είναι διαθέσιμη σε αυτό το δίκτυο",
  YOU: "Εσείς",
};
