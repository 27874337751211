export default {
  ABOUT_NEW_FEATURES: "नई सुविधाओं के बारे में।",
  ACCEPT: "स्वीकार करना",
  ACTIVE_ORDERS: "सक्रिय आदेश",
  ADD_TOKEN: "एक टोकन जोड़ें",
  AGGRESSIVE: "आक्रामक",
  AND: "और",
  APPROVE: "मंज़ूरी देना",
  ASSETS_ON_WAY: "आपकी संपत्ति रास्ते में है और अंदर पहुंचनी चाहिए",
  AUTO: "ऑटो",
  AVOID_TRANSFER:
    "कृपया एक केंद्रीकृत विनिमय पते (बिनेंस, हुबी ...) में स्थानांतरित करने से बचें। यह एक समर्थित सुविधा नहीं है",
  BALANCE: "संतुलन",
  BALANCE_ONE: "संतुलन:",
  BLOG: "ओटीसी",
  BRIDGE: "पुल",
  CANCEL: "रद्द करना",
  CANCELLATION_TRANSACTION_FEE:
    "एक सीमा आदेश को रद्द करने में लेनदेन शुल्क का भुगतान करना शामिल है।",
  CANCEL_ALL: "सब रद्द करो",
  CANCEL_LIMIT_NOT_FILLED:
    "आप एक सीमा आदेश को रद्द कर सकते हैं जब तक कि आदेश अभी तक नहीं भरा गया है।",
  CANCEL_LIMIT_ORDER: "सीमा आदेश रद्द करें",
  CANCEL_ORDER: "आदेश रद्द",
  CANCEL_SELECTED: "चयनित रद्द करें",
  CHAINS: "ब्लॉग",
  CHOOSE_NETWORK: "नेटवर्क चुनें",
  CLASSIC_MODE: "क्लासिक मोड",
  CLEAR: "स्पष्ट",
  CONFIRM: "पुष्टि करना",
  CONFIRMATION_REJECTED: "पुष्टिकरण अस्वीकार कर दिया!",
  CONFIRM_SWAP: "स्वैप की पुष्टि करें",
  CONNECT_WALLET: "कनेक्ट वॉलेट",
  COPIED: "कॉपी किया गया",
  CURRENT: "मौजूदा",
  CUSTOM: "रिवाज़",
  DAYS: "दिन",
  DEFAULT: "गलती करना",
  DETAILS: "विवरण",
  DISCLAIMER: "अस्वीकरण",
  DISCONNECT_WALLET: "बटुए को डिस्कनेक्ट करें",
  DISMISS: "नकार देना",
  DUPLICATE_ORDER_SUBMIT: "आदेश की प्रतिलिपि। यह पहले से ही सबमिट किया गया है",
  ENTER_AMT: "राशि डालें",
  EXCHANGE: "अदला-बदली",
  EXPIRY: "समाप्ति",
  EXPIRY_SM: "समाप्ति",
  FAILED_LOAD_CHART: "चार्ट लोड करने में विफल",
  FIND_BY_TOKEN_OR_STATUS: "टोकन या स्थिति द्वारा खोजें",
  INSUFFICIENT_BALANCE: "अपर्याप्त शेषराशि",
  INSUFFICIENT: "नाकाफी",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "मौजूदा आदेशों को कवर करने के लिए संतुलन",
  RECEIVERS: "रिसीवर का",
  WALETADDRESS: "बटुए का पता",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "कृपया सेवा और गोपनीयता नीति की शर्तें स्वीकार करें",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "मेकर लेने वाले को एक ही टेकर का पता नहीं होना चाहिए",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "स्लिपेज सहिष्णुता 0.1% और 15% के बीच होनी चाहिए",
  SEARCH_A_TOKEN: "एक टोकन खोजें",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "यदि इस प्रतिशत से अधिक मूल्य बदल जाएगा तो आपका लेनदेन वापस आ जाएगा",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "आपके लेनदेन के लिए गैस की कीमत को समायोजित करता है।उच्च GWEI = उच्च गति।",
  REVIEW_OTC_ORDER: "OTC आदेश की समीक्षा करें",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "ऑर्डर मेकर द्वारा निर्धारित अवधि की अवधि।इस अवधि के दौरान, आदेश समाप्त हो जाएगा और संग्रहीत किया जाएगा।",
  SEARCH_A_LIQUIDITY_SOURCES: "एक तरलता स्रोत खोजें",

  FAST: "तेज़",
  FAST_AND_SECURE:
    "क्रॉस-चेन परिसंपत्तियों को लाने के लिए सुरक्षित, तेज और सबसे सुरक्षित तरीका",
  FEATURE_TO_TRANSFER:
    "कृपया अपने टोकन को केंद्रीकृत एक्सचेंज के पते पर स्थानांतरित करने के लिए इस सुविधा का उपयोग न करें।",
  FEES: "फीस",
  FILL_ORDER: "भवन आदेश",
  FILL_OTC_ORDER: "OTC आदेश भरें",
  FREE: "मुक्त",
  FROM: "से",
  GAS_COST: "गैस लागत",
  GAS_DESTINATION: "गंतव्य श्रृंखला पर गैस",
  GAS_ON_DESTINATION: "गंतव्य पर गैस",
  GAS_PRICE: "गैस की कीमत",
  GOT_IT: "समझ गया",
  GOT_PROBLEM_JUST: "एक समस्या मिलना? बस संपर्क करें।",
  HIGH: "उच्च",
  HOURS: "घंटे",
  IMPORT: "आयात",
  IMPOSSIBLE_TO_RETRIEVE:
    "यह एक समर्थित सुविधा नहीं है। इसके अलावा, कृपया आपके द्वारा भरने वाले पते को दोबारा जांचें, गलत पते पर भेजे गए किसी भी टोकन को पुनः प्राप्त करना असंभव होगा।",
  INSTANT: "तुरंत",
  INSUFFICIENT_APPROVED_AMT: "अपर्याप्त स्वीकृत राशि",
  INVALID_TOKEN_ADDRESS: "अमान्य टोकन पता",
  KEEP_TRACK_NOTIFY:
    "आप अपने पैनल अधिसूचना में इस पर ट्रैक रख सकते हैं। एक बार होने के बाद हम आपको सूचित करेंगे।",
  KEEP_YOU_POSTED: "। हम आपको तैनात रखेंगे।",
  LEARN_MORE: "और अधिक जानें",
  LEGACY: "परंपरा",
  LIMIT: "आप LIMIT",
  LIMIT_ORDER: "सीमा आदेश",
  LIMIT_PRICE: "सीमा मूल्य",
  LIMIT_PRICE_SM: "सीमा मूल्य",
  LIQUIDITY_SOURCES: "तरलता स्रोत",
  LOW: "कम",
  MAKER: "निर्माता",
  MARKET: "बाज़ार",
  MAX: "अधिकतम",
  MAX_BASE_FEE: "अधिकतम आधार शुल्क",
  MAX_S: "अधिकतम",
  MAX_TO_PAY: "अधिकतम भुगतान करने के लिए",
  MINIMUM_RECEIVED: "न्यूनतम प्राप्त",
  MINUTES: "मिनट",
  MONTHS: "महीने",
  NEED_APPROVAL_MOVE:
    "हमें स्थानांतरित करने के लिए आपकी स्वीकृति की आवश्यकता है",
  NEED_TO_WRAP: "आपको लपेटने की जरूरत है",
  NETWORK_FEES: "नेटवर्क शुल्क",
  NETWORK_SM: "नेटवर्क।",
  NEVER: "कभी नहीं",
  NONE: "कोई नहीं",
  NON_CUSTODIAL_LIMIT: "गैर-कस्टोडियल सीमा क्रम में। यह एक तकनीकी आवश्यकता है",
  NORMAL: "सामान्य",
  NO_ORDERS_YET: "आपके पास अभी तक कोई आदेश नहीं है",
  NO_RECORDS: "कोई रिकॉर्ड नहीं",
  ON_UR_BEHALF: "आपकी जगह।",
  OOPS_WRONG: "ओह! कुछ गलत हो गया है।!",
  OPEN: "खुला",
  ORDER_DETAILS: "ऑर्डर का विवरण",
  ORDER_HISTORY: "आदेश इतिहास",
  ORDER_PRICE: "आदेश मूल्य",
  ORDER_ROUTING: "आदेश मार्ग",
  OTC: "ओटीसी",
  PASTE: "पेस्ट करें",
  PAY: "वेतन",
  PENDING_CONFIRM: "बटुए पर लंबित पुष्टि।",
  PER: "प्रति",
  PLEASE_CHOOSE_NETWORK: "कृपया नेटवर्क चुनें",
  PLEASE_LOGIN: "कृपया लॉगिन करें",
  PLEASE_TRY_LATER: "कृपया बाद में पुन: प्रयास करें",
  POPULAR_TOKENS: "लोकप्रिय टोकन",
  PRICE: "कीमत",
  PRICE_IMPACT: "मूल्य प्रभाव",
  PRIVACY_POLICY: "गोपनीयता नीति",
  RATE: "दर",
  READ_ACCEPT: "मैं पढ़ता हूं और स्वीकार करता हूं",
  RECEIVE: "प्राप्त करें",
  RECEIVED_ORDERS: "आदेश प्राप्त हुए",
  REFUND: "धनवापसी",
  REMOVE: "निकालना",
  REVERSE_RATE: "रिवर्स दर",
  REVIEW_TRADE: "समीक्षा व्यापार",
  SELECTED: "चयनित",
  SELECT_ALL: "सबका चयन करें",
  SELECT_FROM_INFO: "कृपया जानकारी से चुनें",
  SELECT_NETWORK: "एक नेटवर्क का चयन करें",
  SELECT_TOKEN: "एक टोकन का चयन करें",
  SELECT_TOKENN: "टोकन का चयन करें",
  SELECT_TO_INFO: "कृपया जानकारी का चयन करें",
  SETTINGS: "समायोजन",
  SIMPLE_MODE: "सरल प्रकार",
  SLIPPAGE: "फिसलन",
  SLIPPAGE_TOLERANCE: "फिसलन सहिष्णुता",
  SRY_INSUFFICIENT: "क्षमा करें, अपर्याप्त",
  STATUS: "स्थिति",
  STATUS_SM: "स्थिति",
  STEP: "कदम",
  SUBMIT: "जमा करना",
  SUBMITTED_ORDERS: "प्रस्तुत आदेश",
  SUBMIT_DATE: "जमा करने की तारीख",
  SUCCESS_FILLED: "सफलतापूर्वक ऑर्डर भरा हुआ",
  SUCCESS_RECEIVED: "सफलतापूर्वक प्राप्त हुआ",
  SUCCESS_SUBMITTED_ORDER: "सफलतापूर्वक अपना आदेश प्रस्तुत किया",
  SWAP: "बदलना",
  SWAP_TRANSFER: "स्वैप एंड ट्रांसफर",
  SWITCH_NETWORK: "स्विच नेटवर्क",
  TAKER: "लेने वाला",
  TAKER_ADDRESS: "टेकर पता",
  TERMS_OF_SERVICE: "सेवा की शर्तें",
  TO: "को",
  TOKEN_NOT_FOUND: "टोकन नहीं मिला",
  TOKEN_RECEIVE_SENT: "प्राप्त करने के लिए टोकन भेजा जाएगा",
  TRADE: "व्यापार",
  TRANSACTION_REJECTED_LATER:
    "लेनदेन को अस्वीकार कर दिया कृपया बाद में पुनः प्रयास करें",
  TRANSFER: "स्थानांतरण",
  TRANSFER_ALERT: "स्थानांतरण अलर्ट",
  TRANSFER_TO: "के लिए स्थानांतरण",
  TX_COST: "TX लागत",
  UNLOCK: "अनलॉक",
  USER_GUIDE: "उपयोगकर्ता गाइड",
  USE_ITAS: "और इसे w के रूप में उपयोग करें",
  VERIFY: "सत्यापित करें",
  VIEW_EXPLORER: "एक्सप्लोरर में लेनदेन देखें",
  VIEW_ON: "पर देखें",
  VIEW_TRANSACTION: "लेन -देन देखें",
  WRAP: "लपेटना",
  YOUR_ADDRESS: "तुम्हारा पता",
  YOUR_ORDER_CANCEL: "आपका आदेश रद्द कर दिया गया है",
  YOUR_WALLET: "आपका बटुआ",
  YOU_GET: "आपको मिला",
  YOU_PAY: "तुम भुगतान दो",
  YOU_RECEIVE: "आपको प्राप्त हुया",
  YOU_WILL_RECEIVE: "आप पाएंगे",
  ANYONE_CAN_CREATE:
    "कोई भी मौजूदा टोकन के नकली संस्करणों सहित कोई भी टोकन बना सकता है। उचित ध्यान रखें। कुछ टोकन और उनके तकनीकी पैरामीटर हमारी सेवाओं के साथ असंगत हो सकते हैं। इस कस्टम टोकन को आयात करके आप जोखिमों को स्वीकार करते हैं और स्वीकार करते हैं।",
  BY_SIGNING_MESSAGE:
    "इस संदेश पर हस्ताक्षर करने से, आप पुष्टि करते हैं कि आप इस ऐप को एक्सेस नहीं कर रहे हैं, या यूएसए या किसी अन्य प्रतिबंधित देश के निवासी हैं। अधिक जानकारी के लिए, कृपया हमारे पढ़ें",
  CONSTITUTION: "संविधान",
  CURRENTLY_CONNECTED: "आप वर्तमान में जुड़े हुए हैं",
  IMPORT_TOKEN: "एक टोकन आयात करें",
  IN_YOUR_WALLET: "अपने बटुए में",
  MAKE_SURE_CHECKED:
    "सुनिश्चित करें कि आपने टोकन पते की जाँच की है और यह सही है",
  ON: "पर",
  PEOPLE: "लोग",
  PLEASE_SWITCH: "कृपया स्विच करें",
  SELECTED_NOT_SUPPORTED:
    "कृपया अपने वॉलेट में नेटवर्क बदलें, चयनित नेटवर्क समर्थित नहीं है",
  SIGN_FOR_ACCESS: "पहुंच के लिए हस्ताक्षर करें",
  SIGN_MESSAGE: "हस्ताक्षर संदेश",
  LAUNCH_APP: "ऐप लांच करें",
  GETTING_STARTED: "HODL के साथ शुरुआत करना: ट्यूटोरियल और उत्पाद व्याख्याता",
  DEEP_DIVE: "HODL में DeepDive: रणनीतियाँ और उत्पाद सुविधाएँ",
  BUILD_HODL: "HODL पर निर्माण: तकनीकी प्रलेखन",
  BLOG_HEADING:
    "डेफि को डिमॉस्टाइज़िंग - एक जगह पर आपको जो कुछ भी जानना है। उन्नत उपयोगकर्ताओं के लिए शुरुआती के लिए, और सभी के बीच में।",
  BLOG_PLACEHOLDER:
    "उस उत्पाद/विषय की खोज करें जिसके बारे में आप सीखना चाहते हैं",
  CHOOSE_TOPIC: "नेटवर्क चुनें",
  TRY: "कोशिश",
  SEARCH_RESULTS: "खोज के परिणाम",
  SEARCH_TOPIC: "खोज विषय",
  LOAD_MORE: "और लोड करें",
  SOCIAL_MEDIA: "सामाजिक मीडिया",
  NEW: "नवीनतम पहले",
  OLD: "सबसे पुराना पहले",
  Z_A: "क्रम शीर्षक z-a",
  A_Z: "क्रम A-Z",
  RESULTS: "परिणाम",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "एक आधार गैस शुल्क स्वचालित रूप से नेटवर्क द्वारा गणना की जाती है और ब्लॉक से ब्लॉक तक भिन्न होती है। आधार शुल्क की अधिकतम राशि निर्धारित करें जिसे आप भुगतान करना चाहते हैं। आपके द्वारा निर्धारित अधिकतम आधार शुल्क और वास्तविक आधार शुल्क के बीच अंतर को ब्लॉक के खनन के बाद वापस कर दिया जाएगा।",
  CANCELLED_TRANSACTION: "अपना लेनदेन रद्द कर दिया",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "एक सीमा आदेश बनाना ऑर्डर निर्माता के लिए एक मुफ्त ऑपरेशन है जब तक कि वे रद्द नहीं करते। ऑर्डर लेने वाले को ऑर्डर भरते समय लेनदेन शुल्क के लिए भुगतान करना होगा।",
  Cancelled: "रद्द",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST:
    "सबसे अच्छे मार्ग और के बीच मूल्य में अंतर",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "इस विकल्प को सक्षम करें यदि आप एक टोकन की अदला-बदली कर रहे हैं, जिसमें स्थानांतरण के लिए अंतर्निहित फीस है।",
  Expired: "खत्म हो चुका",
  Filled: "भरा हुआ",
  INSUFFICIENT_FOR_GASFEE: "क्षमा करें, गैस शुल्क के लिए अपर्याप्त धनराशि",
  Open: "खुला",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "आंशिक भरण केवल तभी लागू होता है जब एक स्वैप मार्ग में कई मुख्य मार्ग भाग होते हैं। यह आंशिक निष्पादन के लिए अनुमति देता है, लेनदेन को उलट होने से रोकता है यदि बाजार मूल्य पक्ष से बाहर हो जाता है।",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "आदेश निर्माता द्वारा उनके आदेश को निष्पादित करने के लिए निर्धारित मूल्य (भुगतान करने के लिए = x टोकन प्राप्त करने के लिए टोकन)",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "आदेश निर्माता द्वारा उनके आदेश को निष्पादित करने के लिए निर्धारित मूल्य (टोकन को भुगतान करने के लिए = y टोकन प्राप्त करने के लिए",
  REVIEW_LIMIT_ORDER: "सीमा आदेश की समीक्षा करें",
  SEARCH_BY_TOKEN: "टोकन या पते से खोजें",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "एक उच्च स्लिपेज सहिष्णुता सेट करने से लेनदेन को सफल होने में मदद मिल सकती है, लेकिन आपको इतनी अच्छी कीमत नहीं मिल सकती है। सावधानी से प्रयोग करें।",
  STATUS_OF_THE_LIMIT_ORDER: "सीमा आदेश की स्थिति",
  Suspended: "निलंबित",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "इन शुल्कों का भुगतान नेटवर्क खनिकों को किया जाता है। HODL फीस को नियंत्रित नहीं करता है।",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE:
    "व्यापार आकार के कारण बाजार मूल्य और स्था के बीच का अंतर।",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "न्यूनतम प्राप्त राशि, अन्यथा लेन -देन वापस कर दिया जाएगा",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "यह शुल्क आपके लेनदेन को प्राथमिकता देने के लिए एक प्रोत्साहन के रूप में सीधे खनिकों के पास जाता है।",
  TRANSACTION_REJECTED_TRY: "लेनदेन अस्वीकार कर दिया, कृपया पुनः प्रयास करें",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "चयनित टोकन के लिए स्थानांतरण इस नेटवर्क पर उपलब्ध नहीं हैं",
  YOU: "आप",
};
