import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import config from "../config/config";
import { getSwapTransaction } from "../ParaswapSdk/paraswap_sdk.ts";
import { swapParaswapContract } from "../ContractActions/swapActions";
import { saveswap } from "../Api/SwapActions";
import { toastAlert } from "../helper/toastAlert";
import { getTxName, getTxLink } from "../helper/networkdata";
import { shortText, CheckDecimal, formatNumber } from "../helper/custommath";
import { getLoginInfo } from "../Api/TokenActions";
import { useTranslation } from "react-i18next";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getTokenLogo } from "../helper/networkdata";
import { checkNetwork } from "../helper/connection";
import RouterABI from "../ABI/SwapRouterABI.json";

import {
  getdirectUniV3Swap,
  getSwapSimple,
  getmultiSwap,
  getreceivedAmount
} from "./swap-details"

const InputDataDecoder = require("ethereum-input-data-decoder");

export default function ConfirmSwap(props) {
  var {
    TokenModalClick,
    reCheck,
    enterData,
    tokenTransferProxy,
    settings,
    tokenrate,
    swapDetails,
    walletConnection,
    takeraddress,
    adminfee,
    partner,
    adminCommision,
    minReceived,
    feeLoaed,
  } = props;
  const gasValue = useSelector((state) => state.gasValue);
  const { t } = useTranslation();
  const [swapLoad, setswapLoad] = useState(false);
  const [txId, settxId] = useState("");
  const [transferaddress, settransferaddress] = useState("");
  const [payAmount, setpayAmount] = useState(0);

  useEffect(() => {
    settransferaddress(props.takeraddress);
    // eslint-disable-next-line
  }, [props.takeraddress]);

  useEffect(() => {
    setpayAmount(enterData.amount);
    // eslint-disable-next-line
  }, [enterData.amount]);

  async function triggerSwap() {
    if (!feeLoaed) {
      toastAlert("error", t("PLEASE_TRY_LATER"), "swaperror");
      return;
    }

    var { isNetwork, networkname } = await checkNetwork();
    if (!isNetwork) {
      toastAlert("error", t("PLEASE_SWITCH") + " " + networkname, "switchnwk");
      return;
    }

    const decoder = new InputDataDecoder(RouterABI);
    try {
      await reCheck();
      setswapLoad(true);

      var slippage =
        settings && settings.slippage ? parseFloat(settings.slippage) : 0.5;

      var amt =
        parseFloat(swapDetails.fromamount) /
        10 ** parseFloat(swapDetails.fromdecimal);
      var receiverAddr =
        location.pathname === "/transfer"
          ? takeraddress
          : walletConnection.address;
      var data = {
        srcToken: swapDetails.fromaddress,
        srcSymbol: swapDetails.fromsymbol,
        srcDecimals: swapDetails.fromdecimal,
        destToken: swapDetails.toaddress,
        destSymbol: swapDetails.tosymbol,
        destDecimals: swapDetails.todecimal,
        srcAmount: amt.toString(),
        networkID: walletConnection.network,
        slippage: slippage,
        userAddress: walletConnection.address,
        receiver: receiverAddr,
        priceRoute: swapDetails.priceRoute,
        partner: partner,
        partnerAddress: partner, //not used
        partnerFeeBps: 2, //not used
      };

      minReceived =
        parseFloat(minReceived) * 10 ** parseFloat(swapDetails.todecimal);
      minReceived = CheckDecimal(minReceived);

      var resp = await getSwapTransaction(data);
      //var resp = null;
      var result = decoder.decodeData(resp.data);
      var method = result.method;
      result = result.inputs[0];

      var partnerFee = adminfee * 100;
      partnerFee = partnerFee.toString();

      if (result && result[0]) {
        var tupleData = [];
        if (method == "directUniV3Swap") {
          tupleData = getdirectUniV3Swap(
            result,
            partnerFee,
            partner,
            minReceived
          );
        } else if (method == "simpleSwap") {
          tupleData = getSwapSimple(result, partnerFee, partner, minReceived);
        } else if (method == "multiSwap") {
          tupleData = getmultiSwap(result, partnerFee, partner, minReceived);
        } else {
          toastAlert("error", t("PLEASE_TRY_LATER"), "swaperror");
          setswapLoad(false);
          return;
        }

        if (resp && resp.error) {
          var message = resp.error.message;
          toastAlert("error", t(message), "swaperror");
          window.$("#confirm_swap_modal").modal("hide");
          setswapLoad(false);
          TokenModalClick();
          return;
        }

        var { txdata, error1 } = await swapParaswapContract(tupleData, method);

        //var error1 = "";
        //var txdata = { transactionHash: "0xef1090738c85fc387b1dede87a187e976e5b58b861c80c221b24d7d22df2efb9" }
        if (error1) {
          toastAlert("error", t(error1), "swaperror");
          window.$("#confirm_swap_modal").modal("hide");
          setswapLoad(false);
          TokenModalClick();
        } else {
          var link =
            config && config.txLink[walletConnection.network]
              ? config.txLink[walletConnection.network]
              : "";
          settxId(
            txdata && txdata.transactionHash
              ? link + "tx/" + txdata.transactionHash
              : ""
          );
          window.$("#confirm_swap_modal").modal("hide");
          window.$("#success_swap_modal").modal("show");
          console.log(txdata, 'txdatatxdatatxdata1')
          var { ip, country } = await getLoginInfo();

          var adminfeeUSDT = parseFloat(swapDetails.destUSD) * parseFloat(adminCommision) / 100;
          //var { transferAmount } = await getreceivedAmount(txdata.events, swapDetails.toaddress);
          var { expectedAmount, receivedAmount, adminFee } = await getreceivedAmount(txdata.transactionHash);
          // var receivedAmt = (txdata && txdata.events && txdata.events[6]
          //   && txdata.events[6].raw && txdata.events[6].raw.data
          // ) ? parseInt(txdata.events[6].raw.data) : 0;
          console.log(expectedAmount, receivedAmount, adminFee, '**************123')
          var receivedAmt = (expectedAmount > 0) ? expectedAmount : parseFloat(swapDetails.toamount);
          adminCommision = (adminFee > 0) ? adminFee / (10 ** swapDetails.todecimal) : parseFloat(adminCommision);

          var savedetails = {
            fromAddress: swapDetails.fromaddress,
            toAddress: swapDetails.toaddress,
            fromSymbol: swapDetails.fromsymbol,
            toSymbol: swapDetails.tosymbol,
            fromAmount: parseFloat(swapDetails.fromamount) / (10 ** swapDetails.fromdecimal),
            toAmount: formatNumber(parseFloat(receivedAmt) / (10 ** swapDetails.todecimal), swapDetails.todecimal),
            chainId: walletConnection.network,
            txId:
              txdata && txdata.transactionHash ? txdata.transactionHash : "",
            userAddress: walletConnection.address,
            ip: ip,
            country: country,
            srcUSD: swapDetails.srcUSD,
            destUSD: swapDetails.destUSD,
            actionType: "swap",
            adminFee: formatNumber(adminCommision, swapDetails.todecimal),
            adminfeeUSDT: adminfeeUSDT
          }

          await saveswap(savedetails)

          setswapLoad(false);
          TokenModalClick();
        }
      } else {
        console.log("elseeeee errerr");
        setswapLoad(false);
        toastAlert("error", t("PLEASE_TRY_LATER"), "swaperror");
      }
    } catch (err) {
      console.log(err, "triggerSwap errerr");
      setswapLoad(false);
      toastAlert("error", t("PLEASE_TRY_LATER"), "swaperror");
    }
  }

  function CloseModal() {
    setswapLoad(false);
  }

  return (
    <>
      <div
        className="modal fade primary_modal"
        id="confirm_swap_modal"
        tabIndex="-1"
        aria-labelledby="confirm_swap_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="confirm_swap_modalLabel">
                {t("CONFIRM_SWAP")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => CloseModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <div className="swap_select_token_wrap">
                  <div className="swap_select_token_inner">
                    <label>{t("YOU_PAY")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.fromlogo,
                              swapDetails.fromchainId
                            )}
                            alt={swapDetails.fromsymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.fromsymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={payAmount}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="swap_token_bal">
                        {swapDetails.fromname}
                      </div>
                      <div className="swap_token_bal">~${tokenrate.srcUSD}</div>
                    </div>
                  </div>
                </div>
                <div className="swap_arrow_container">
                  <img
                    src={require("../assets/images/swap_icon.png")}
                    alt="Crypto Coin"
                  />
                </div>
                <div className="swap_select_token_wrap">
                  <div className="swap_select_token_inner">
                    <label>{t("YOU_RECEIVE")}</label>
                    <div>
                      <div className="swap_select_token">
                        <div className="swap_select_token_option">
                          <img
                            src={getTokenLogo(
                              swapDetails.tologo,
                              swapDetails.tochainId
                            )}
                            alt={swapDetails.tosymbol}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = config.defaultLogo;
                            }}
                          />
                          <span className="swap_token_title">
                            {swapDetails.tosymbol}
                          </span>
                        </div>
                      </div>
                      <input
                        className="swap_token_input"
                        type="text"
                        value={swapDetails.todisplay}
                        readOnly
                      />
                    </div>
                    <div>
                      <div className="swap_token_bal">{swapDetails.toname}</div>
                      <div className="swap_token_bal">
                        {" "}
                        ~${tokenrate.destUSD}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swap_info">
                <div>
                  <span>{t("GAS_PRICE")}</span>
                  <span>{gasValue.selected} GWEI</span>
                </div>
                <div>
                  <span>
                    {swapDetails.fromsymbol} {t("PRICE")}
                  </span>
                  <span>${tokenrate.srcUSD}</span>
                </div>
                <div>
                  <span>
                    {t("MINIMUM_RECEIVED")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="minimumreceived"
                    ></i>
                  </span>

                  <ReactTooltip
                    anchorId="minimumreceived"
                    place="right"
                    content={t(
                      "THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION"
                    )}
                  />
                  <span>
                    {swapDetails.minimumrecevied} {swapDetails.tosymbol}
                  </span>
                </div>
                <div>
                  <span>
                    {t("SLIPPAGE_TOLERANCE")}
                    <i
                      className="far fa-question-circle ms-1"
                      id="slippage"
                    ></i>
                  </span>
                  <ReactTooltip
                    anchorId="slippage"
                    place="right"
                    content={t(
                      "SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION"
                    )}
                  />
                  <span>~{settings.slippage}</span>
                </div>
                {location.pathname === "/transfer" && (
                  <div className="otc_disclaimer d-block">
                    <div>
                      <h5>{t("TRANSFER_ALERT")}</h5>
                      <img
                        src={require("../assets/images/info_icon.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </div>
                    <p className="yellow_txt">
                      {t("TOKEN_RECEIVE_SENT")}{" "}
                      <a
                        href={
                          getTxLink(swapDetails.fromchainId) +
                          "address/" +
                          transferaddress
                        }
                        target="_blank"
                      >
                        {shortText(transferaddress)}
                      </a>
                    </p>
                    <p>{t("AVOID_TRANSFER")}</p>
                  </div>
                )}
              </div>
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => triggerSwap()}
                  disabled={swapLoad}
                >
                  {t("CONFIRM")}{" "}
                  {location.pathname === "/transfer"
                    ? t("SWAP_TRANSFER")
                    : t("SWAP")}{" "}
                  {swapLoad && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
              {swapLoad && (
                <div className="text-center">
                  <ReactLoading
                    type={"bars"}
                    color="#fbd534"
                    className="loading"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade primary_modal"
        id="success_swap_modal"
        tabIndex="-1"
        aria-labelledby="success_swap_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="success_swap_modalLabel">
                {t("SUCCESS_RECEIVED")}{" "}
                <span style={{ color: "#fbd334" }}>{swapDetails.tosymbol}</span>
              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="swap_container swap_container_chg">
                <svg
                  className="txcomplete"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                <a
                  href={txId}
                  target="_blank"
                  className="d-block mt-3 text-center"
                >
                  {" "}
                  <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                  {t("VIEW_ON")} {getTxName(walletConnection.network)}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
