export default {
  ABOUT_NEW_FEATURES: "新機能について。",
  ACCEPT: "受け入れる",
  ACTIVE_ORDERS: "アクティブ注文",
  ADD_TOKEN: "トークンを追加します",
  AGGRESSIVE: "攻撃的",
  AND: "そして",
  APPROVE: "承認する",
  ASSETS_ON_WAY: "あなたの資産は途中であり、に到着するはずです",
  AUTO: "自動",
  AVOID_TRANSFER:
    "集中交換住所（Binance、Huobi ...）への転送を避けてください。サポートされている機能ではありません",
  BALANCE: "バランス",
  BALANCE_ONE: "バランス：",
  BLOG: "OTC",
  BRIDGE: "橋",
  CANCEL: "キャンセル",
  CANCELLATION_TRANSACTION_FEE:
    "制限注文のキャンセルには、取引手数料の支払いが含まれます。",
  CANCEL_ALL: "すべてをキャンセルします",
  CANCEL_LIMIT_NOT_FILLED:
    "注文がまだ入力されていない限り、制限順序をキャンセルできます。",
  CANCEL_LIMIT_ORDER: "キャンセル制限順序",
  CANCEL_ORDER: "注文をキャンセルする",
  CANCEL_SELECTED: "選択したキャンセル",
  CHAINS: "ブログ",
  CHOOSE_NETWORK: "ネットワークを選択します",
  CLASSIC_MODE: "クラシックモード",
  CLEAR: "クリア",
  CONFIRM: "確認する",
  CONFIRMATION_REJECTED: "確認が拒否されました。",
  CONFIRM_SWAP: "スワップを確認します",
  CONNECT_WALLET: "ウォレットを接続します",
  COPIED: "コピー",
  CURRENT: "現在",
  CUSTOM: "カスタム",
  DAYS: "日々",
  DEFAULT: "デフォルト",
  DETAILS: "詳細",
  DISCLAIMER: "免責事項",
  DISCONNECT_WALLET: "ウォレットを切断します",
  DISMISS: "却下する",
  DUPLICATE_ORDER_SUBMIT: "重複した注文。これはすでに提出されています",
  ENTER_AMT: "金額を入力します",
  EXCHANGE: "交換",
  EXPIRY: "有効期限",
  EXPIRY_SM: "有効期限",
  FAILED_LOAD_CHART: "チャートをロードできませんでした",
  FIND_BY_TOKEN_OR_STATUS: "トークンまたはステータスで検索します",
  INSUFFICIENT_BALANCE: "残高不足",
  INSUFFICIENT: "不十分",
  BALANCE_TO_COVER_EXISTING_ORDERS_BALANCE_OF:
    "既存の注文バランスをカバーするバランス",
  RECEIVERS: "受信機",
  WALETADDRESS: "ウォレットアドレス",

  PLEASE_ACCEPT_TERMSOF_SERVICEAND_PRIVACY_POLICY:
    "利用規約とプライバシーポリシーを受け入れてください",
  MAKER_TAKER_SHOULD_NOT_BE_SAME_TAKER_ADDRESS:
    "メーカーテイカーは、同じテイカーの住所であるべきではありません",
  SILIPAGE_TOLERANCE_SHOULD_BE_BETWEEN:
    "滑り耐性は0.1％から15％でなければなりません",
  SEARCH_A_TOKEN: "トークンを検索します",
  YOUR_TRANSACTION_WILL_REVERT_IF_THE_PRICE:
    "価格がこの割合よりも不利に変更された場合、トランザクションは再vertします",
  ADJUSTS_THE_GAS_PRICE_FOR_YOUR_TRANSACTION:
    "取引のガス価格を調整します。より高いGWEI =高速。",
  REVIEW_OTC_ORDER: "OTC注文を確認します",
  EXPIRY_PERIOD_SET_BY_THE_ORDER_MAKER:
    "注文メーカーによって設定された有効期限。この期間を過ぎて、注文は期限切れになり、アーカイブされます。",
  SEARCH_A_LIQUIDITY_SOURCES: "流動性ソースを検索します",

  FAST: "速い",
  FAST_AND_SECURE: "クロスチェーン資産をにもたらす安全で高速で最も安全な方法",
  FEATURE_TO_TRANSFER:
    "この機能を使用して、トークンを集中交換のアドレスに転送しないでください。",
  FEES: "料金",
  FILL_ORDER: "注文に記入します",
  FILL_OTC_ORDER: "OTC注文を入力します",
  FREE: "無料",
  FROM: "から",
  GAS_COST: "ガスコスト",
  GAS_DESTINATION: "目的地チェーンのガス",
  GAS_ON_DESTINATION: "目的地のガス",
  GAS_PRICE: "ガス価格",
  GOT_IT: "わかった",
  GOT_PROBLEM_JUST: "問題を抱えて？連絡してください。",
  HIGH: "高い",
  HOURS: "時間",
  IMPORT: "輸入",
  IMPOSSIBLE_TO_RETRIEVE:
    "サポートされている機能ではありません。また、充填したアドレスを再確認してください。間違ったアドレスに送信されるトークンは取得することは不可能です。",
  INSTANT: "インスタント",
  INSUFFICIENT_APPROVED_AMT: "承認額が不十分です",
  INVALID_TOKEN_ADDRESS: "無効なトークンアドレス",
  KEEP_TRACK_NOTIFY:
    "パネル通知で追跡することができます。完了したら通知します。",
  KEEP_YOU_POSTED: "。私たちはあなたを投稿し続けます。",
  LEARN_MORE: "もっと詳しく知る",
  LEGACY: "遺産",
  LIMIT: "制限",
  LIMIT_ORDER: "順序を制限します",
  LIMIT_PRICE: "価格を制限します",
  LIMIT_PRICE_SM: "価格を制限します",
  LIQUIDITY_SOURCES: "流動性ソース",
  LOW: "低い",
  MAKER: "メーカー",
  MARKET: "市場",
  MAX: "マックス",
  MAX_BASE_FEE: "最大基地料金",
  MAX_S: "マックス",
  MAX_TO_PAY: "支払う最大",
  MINIMUM_RECEIVED: "最低受信",
  MINUTES: "分",
  MONTHS: "数ヶ月",
  NEED_APPROVAL_MOVE: "移動するには承認が必要です",
  NEED_TO_WRAP: "包む必要があります",
  NETWORK_FEES: "ネットワーク料金",
  NETWORK_SM: "通信網。",
  NEVER: "一度もない",
  NONE: "なし",
  NON_CUSTODIAL_LIMIT: "非義理の制限順序で。それは技術的な要件です",
  NORMAL: "普通",
  NO_ORDERS_YET: "まだ注文がありません",
  NO_RECORDS: "記録なし",
  ON_UR_BEHALF: "あなたに代わって。",
  OOPS_WRONG: "おっと！何かが間違っていた。！",
  OPEN: "開ける",
  ORDER_DETAILS: "注文詳細",
  ORDER_HISTORY: "注文履歴",
  ORDER_PRICE: "注文価格",
  ORDER_ROUTING: "ルーティングを注文します",
  OTC: "OTC",
  PASTE: "ペースト",
  PAY: "支払う",
  PENDING_CONFIRM: "ウォレットの保留中の確認。",
  PER: "あたり",
  PLEASE_CHOOSE_NETWORK: "ネットワークを選択してください",
  PLEASE_LOGIN: "ログインしてください",
  PLEASE_TRY_LATER: "後でもう一度やり直してください",
  POPULAR_TOKENS: "人気のトークン",
  PRICE: "価格",
  PRICE_IMPACT: "価格の影響",
  PRIVACY_POLICY: "プライバシーポリシー",
  RATE: "レート",
  READ_ACCEPT: "私は読んで受け入れます",
  RECEIVE: "受け取る",
  RECEIVED_ORDERS: "注文を受け取りました",
  REFUND: "返金",
  REMOVE: "取り除く",
  REVERSE_RATE: "逆レート",
  REVIEW_TRADE: "取引を確認します",
  SELECTED: "選択",
  SELECT_ALL: "すべて選択",
  SELECT_FROM_INFO: "情報から選択してください",
  SELECT_NETWORK: "ネットワークを選択します",
  SELECT_TOKEN: "トークンを選択します",
  SELECT_TOKENN: "トークンを選択します",
  SELECT_TO_INFO: "情報を選択してください",
  SETTINGS: "設定",
  SIMPLE_MODE: "シンプルモード",
  SLIPPAGE: "スリッページ",
  SLIPPAGE_TOLERANCE: "滑り耐性",
  SRY_INSUFFICIENT: "申し訳ありませんが、不十分です",
  STATUS: "状態",
  STATUS_SM: "状態",
  STEP: "ステップ",
  SUBMIT: "提出する",
  SUBMITTED_ORDERS: "提出された注文",
  SUBMIT_DATE: "日付を送信します",
  SUCCESS_FILLED: "正常に注文した",
  SUCCESS_RECEIVED: "正常に受け取った",
  SUCCESS_SUBMITTED_ORDER: "あなたの注文を正常に送信しました",
  SWAP: "スワップ",
  SWAP_TRANSFER: "交換＆転送",
  SWITCH_NETWORK: "スイッチネットワーク",
  TAKER: "テイカー",
  TAKER_ADDRESS: "テイカーアドレス",
  TERMS_OF_SERVICE: "利用規約",
  TO: "に",
  TOKEN_NOT_FOUND: "トークンが見つかりません",
  TOKEN_RECEIVE_SENT: "受信するトークンはに送信されます",
  TRADE: "貿易",
  TRANSACTION_REJECTED_LATER:
    "トランザクションは拒否され、後でもう一度やり直してください",
  TRANSFER: "移行",
  TRANSFER_ALERT: "アラートを転送します",
  TRANSFER_TO: "に転送します",
  TX_COST: "TXコスト",
  UNLOCK: "ロックを解除します",
  USER_GUIDE: "ユーザーガイド",
  USE_ITAS: "そしてそれをwとして使用します",
  VERIFY: "確認する",
  VIEW_EXPLORER: "Explorerのトランザクションを表示します",
  VIEW_ON: "表示",
  VIEW_TRANSACTION: "トランザクションを表示します",
  WRAP: "包む",
  YOUR_ADDRESS: "あなたの住所",
  YOUR_ORDER_CANCEL: "ご注文はキャンセルされます",
  YOUR_WALLET: "あなたの財布",
  YOU_GET: "あなたは得ます",
  YOU_PAY: "あなたが支払う",
  YOU_RECEIVE: "あなたは受け取ります",
  YOU_WILL_RECEIVE: "あなたが受け取るでしょう",
  ANYONE_CAN_CREATE:
    "誰でも、既存のトークンの偽のバージョンを含むトークンを作成できます。注意してください。一部のトークンとその技術的パラメーターは、当社のサービスと互換性がない場合があります。このカスタムトークンをインポートすることにより、リスクを認め、受け入れます。",
  BY_SIGNING_MESSAGE:
    "このメッセージに署名することにより、このアプリにアクセスしていないこと、または米国または他の制限付き国の居住者であることを確認します。詳細については、お読みください",
  CONSTITUTION: "憲法ダオ",
  CURRENTLY_CONNECTED: "現在接続されています",
  IMPORT_TOKEN: "トークンをインポートします",
  IN_YOUR_WALLET: "あなたの財布で",
  MAKE_SURE_CHECKED:
    "トークンアドレスを確認してください、そしてそれは正しいことを確認してください",
  ON: "の上",
  PEOPLE: "人々",
  PLEASE_SWITCH: "に切り替えてください",
  SELECTED_NOT_SUPPORTED:
    "ウォレットのネットワークを変更してください、選択したネットワークはサポートされていません",
  SIGN_FOR_ACCESS: "アクセスのサイン",
  SIGN_MESSAGE: "署名メッセージ",
  LAUNCH_APP: "起動アプリ",
  GETTING_STARTED: "HODL：チュートリアルと製品の説明者の始めます",
  DEEP_DIVE: "HODLへのディープダイビング：戦略と製品機能",
  BUILD_HODL: "HODLの構築：技術文書",
  BLOG_HEADING:
    "defi -defi- 1か所で知る必要があるすべて。初心者向けの上級ユーザーとその間の全員。",
  BLOG_PLACEHOLDER: "学びたい製品/トピックを検索する",
  CHOOSE_TOPIC: "ネットワークを選択します",
  TRY: "試す",
  SEARCH_RESULTS: "の検索結果",
  SEARCH_TOPIC: "トピックを検索します",
  LOAD_MORE: "もっと読み込む",
  SOCIAL_MEDIA: "ソーシャルメディア",
  NEW: "最新の最初",
  OLD: "最も古い最初",
  Z_A: "タイトルZ-Aをソートします",
  A_Z: "タイトルA-Zをソートします",
  RESULTS: "結果",
  A_BASE_GAS_FEE_IS_AUTOMATICALLY_CALCULATED_BY_THE_NETWORK:
    "基本ガス料金はネットワークによって自動的に計算され、ブロックごとに異なります。支払うべき基本料金の最大額を設定します。設定した最大基本料金と実際の基本料金の違いは、ブロックが採掘されたら返金されます。",
  CANCELLED_TRANSACTION: "トランザクションをキャンセルしました",
  CREATING_A_LIMIT_ORDER_IS_A_FREE:
    "制限注文を作成することは、キャンセルを除き、注文メーカーの無料操作です。注文テイカーは、注文を埋めながら取引手数料を支払う必要があります。",
  Cancelled: "キャンセル",
  DIFFERENCE_IN_VALUE_BETWEEN_THE_BEST: "最適なルートとの価値の違いと",
  ENABLE_THIS_OPTION_IF_YOU_ARE_HAVING:
    "このオプションを有効にして、転送用の料金が組み込まれているトークンの交換に問題がある場合は、有効にします。",
  Expired: "期限切れ",
  Filled: "満たされた",
  INSUFFICIENT_FOR_GASFEE: "申し訳ありませんが、ガソリン料金の資金が不十分です",
  Open: "開ける",
  PARTIAL_FILL_ONLY_APPLIES_IF_A_SWAP:
    "パーツフィルは、スワップルートが複数のメインルートパーツで構成されている場合にのみ適用されます。これにより、部分的な実行が可能になり、市場価格が好まれなくなった場合、取引が逆転しないようになります。",
  PRICE_SET_BY_THE_ORDER_MAKER_X:
    "注文メーカーが注文を実行するために設定された価格（トークンを受信するために= xトークンを支払う）",
  PRICE_SET_BY_THE_ORDER_MAKER_Y:
    "注文を実行するために注文メーカーが設定した価格（トークンを支払うために= yトークンを受信する",
  REVIEW_LIMIT_ORDER: "制限順序を確認します",
  SEARCH_BY_TOKEN: "トークンまたは住所で検索します",
  SETTING_A_HIGH_SLIPAGE_TOLERANCE_CAN_HELP_TRANSACTION:
    "滑りの高い許容度を設定すると、取引が成功するのに役立ちますが、そのような良い価格を得ることができないかもしれません。注意して使用してください。",
  STATUS_OF_THE_LIMIT_ORDER: "制限順序のステータス",
  Suspended: "一時停止中",
  THESE_FEES_ARE_PAID_TO_THE_NETWORK:
    "これらの料金は、ネットワークマイナーに支払われます。 HODLは料金を管理しません。",
  THE_DIFFERENT_BETWEEN_MARKET_PRICE: "貿易規模による市場価格と東部価格の違い",
  THE_MINIMUM_RECEIVED_AMOUNT_OTHERWISE_THE_TRANSACTION:
    "最小受信額、それ以外の場合はトランザクションが戻ります",
  THIS_FEE_GOES_DIRECTLY_TO_MINERS_AS_AN:
    "この料金は、トランザクションに優先順位を付けるインセンティブとして、鉱夫に直接送られます。",
  TRANSACTION_REJECTED_TRY:
    "トランザクションが拒否されました、もう一度やり直してください",
  TRANSFER_FOR_THE_SLECTED_TOKEN_ARE:
    "選択したトークンの転送はこのネットワークでは利用できません",
  YOU: "あなた",
};
