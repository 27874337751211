import { connection } from "../helper/connection";
import { convert } from "../helper/convert";
import WrappedABI from "../ABI/Wrapped.json";

import { multiplyValue } from "../helper/custommath";

export const convertWrapped = async (resp) => {
    console.log(resp, 'resp.contract')
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var amount = parseFloat(resp.amount);
        amount = multiplyValue(amount, 1e18);
        amount = await convert(amount);

        var Contract = new web3.eth.Contract(WrappedABI, resp.contract);
        var BalanceOf = await Contract.methods.balanceOf(address).call();

        if (parseFloat(BalanceOf) < parseFloat(amount)) {
            // amount = parseFloat(amount) - parseFloat(BalanceOf);
            //amount = amount.toString()
        }

        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.deposit().estimateGas({ from: address, value: amount });
        console.log(estimateGas, 'estimateGas1')
        estimateGas = parseFloat(estimateGas) + parseFloat(30000);
        console.log(estimateGas, 'estimateGas2')
        var result1 = await Contract.methods.deposit().send({ from: address, gasPrice: gasPrice, gasLimit: estimateGas, value: amount });
        return {
            status: (result1 && result1.status) ? result1.status : false,
            error: null
        }

    } catch (err) {
        var errMsg = (err && err.message) ? err.message : err;
        var pos = errMsg.search("gas required exceeds");
        var pos1 = errMsg.search("insufficient");
        var pos2 = errMsg.search("User denied transaction signature")
        var message = errMsg;
        if (pos >= 0 || pos1 >= 0) {
            message = "INSUFFICIENT_FOR_GASFEE"
        } else if (pos2 >= 0) {
            message = "CONFIRMATION_REJECTED"
        }
        console.log(err, 'convertWrapped errerrerr')
        return {
            status: false,
            error: message
        }
    }
}

export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function isAddress(address) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var status = web3.utils.isAddress(address)
            return {
                value: status,
                status: true
            };
        } else {
            return {
                value: false,
                status: false
            };
        }
    } catch (err) {

        return {
            value: false,
            status: false
        };
    }
}

export async function getEstimateFee() {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var txFee = await web3.eth.getGasPrice() * 400000;
            return {
                txFee: txFee,
                status: true
            };
        } else {
            return {
                txFee: 0,
                status: false
            };
        }
    } catch (err) {

        return {
            txFee: 0,
            status: false
        };
    }
}
