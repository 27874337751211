// import constant
import {
    MARKETPRICE
} from '../constant';

const initialState = {
    price: 0
};

const marketPrice = (state = initialState, action) => {
    switch (action.type) {
        case MARKETPRICE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default marketPrice;