import React from "react";
import Web3 from "web3";
import { type WalletClient, useWalletClient } from 'wagmi'
import { BrowserProvider, JsonRpcSigner } from 'ethers'
import config from "../config/config";
import store from '../store';

import { toastAlert } from "../helper/toastAlert";
import { getNetworkName } from "../helper/networkdata"

export function EthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId })
  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient],
  )
}

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new BrowserProvider(transport, network)
  const signer = new JsonRpcSigner(provider, account.address)
  return {
    signer,
    transport
  }
}



//var isLoad = false

export async function connection(walletClient) {

  var currentProvider = store.getState();

  var connect = {
    web3: "",
    address: "",
    network: (currentProvider && currentProvider.walletConnection && currentProvider.walletConnection.network) ?
      currentProvider.walletConnection.network : (localStorage.getItem("network") ? parseInt(localStorage.getItem("network")) : 56),
    provider: "",
  };

  var transport = (currentProvider && currentProvider.walletConnection && currentProvider.walletConnection.provider) ?
    currentProvider.walletConnection.provider : null;
  var web3 = (currentProvider && currentProvider.walletConnection && currentProvider.walletConnection.web3) ?
    currentProvider.walletConnection.web3 : null;
  var network = (currentProvider && currentProvider.walletConnection && currentProvider.walletConnection.network) ?
    currentProvider.walletConnection.network : 56;
  var address = (currentProvider && currentProvider.walletConnection && currentProvider.walletConnection.address) ?
    currentProvider.walletConnection.address : "";

  try {
    //var { signer, transport } = walletClientToSigner(walletClient);

    var web3 = new Web3(transport);

    connect.network = network;
    connect.web3 = web3;
    connect.address = address;
    connect.provider = transport;
    connect.connected = "yes";
  } catch (err) {
    console.log(err, 'err60')
  }

  return connect;
}

//without metamask connection
export function readWeb3(id) {
  id = parseFloat(id);
  var rpcUrl = config.rpc[id];
  var web3 = new Web3(rpcUrl);
  return web3;
}


export async function getCurrentNetwork() {
  var web3 = new Web3(window.ethereum);
  var currnetwork = await web3.eth.net.getId();
  return currnetwork;
}

export async function checkNetwork() {
  var currNt = await getCurrentNetwork();
  var chainVal = (localStorage.getItem("network") && parseInt(localStorage.getItem("network")) > 0)
    ? parseInt(localStorage.getItem("network")) : 0;
  var isNetwork = true;
  if (currNt != chainVal) {
    isNetwork = false;
  }
  return {
    isNetwork,
    networkname: getNetworkName(chainVal)
  };
}
