import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import FooterLogo from "../assets/images/footer_logo.svg";
import { toastAlert } from "../helper/toastAlert";
import { createSignature } from "./swap-details";
import { setWallet } from "../reducers/Actions";
import { useTranslation } from "react-i18next";
import { getsignmessages } from "../Api/HomeActions";
import { getfooterLink } from "../Api/BlogActions"
import config from "../config/config"
import { getPrivacyterms } from "../Api/HomeActions"
export default function SignIn(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const langcode = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  var { checkSignature } = props;
  const { t, i18n } = useTranslation();
  const [isLoad, setisLoad] = useState(false);
  const [messages, setmessages] = useState([]);
  const [footerLink, setFooterlink] = useState([]);
  const [cmsdata, setCmsdata] = useState([]);

  async function Signmessage() {
    setisLoad(true);
    console.log('eeeeeeeeeefffff')
    var address = walletConnection.address;
    var { status } = await createSignature({ message4: messages?.message4, address });
    console.log("Signmessage");
    if (status) {
      checkSignature(status);
      window.$("#sign_approve_modal").modal("hide");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
        })
      );
    } else {
      console.log('eeeeeeeeee')
      toastAlert("error", t("PLEASE_TRY_LATER"), "signature");
    }
    setisLoad(false);
  }
  useEffect(() => {

    getFuc();
  }, [langcode.lancode]);

  async function getFuc() {
    var result = await getsignmessages({ lancode: langcode.lancode });
    setmessages(result?.result);
  }
  async function getfooterlink() {
    var { result } = await getfooterLink();
    setFooterlink(result);
  }
  useEffect(() => {

    getfooterlink()

  }, [])
  async function handleClick(subcategory) {
    console.log(cmsdata, 'cmsdata', subcategory)
    const datas = cmsdata && cmsdata.length > 0 && cmsdata.find((item) => item.subcategoryname == subcategory)
    console.log(datas, 'datas')
    if (datas && datas.linkstatus == true) {
      window.open(datas.link, '_blank');
    }
    if (datas && datas.linkstatus == false) {
      //history(datas.identifier)
      window.open('/' + datas.identifier, '_blank');
    }
  }
  async function getdata() {
    var data = {
      lancode: langcode && langcode.lancode ? langcode.lancode : "en"
    }
    var { result } = await getPrivacyterms(data);
    setCmsdata(result)


  }
  useEffect(() => {
    getdata()

  }, [langcode.lancode]);
  return (
    <>
      <div
        className="modal fade primary_modal"
        id="sign_approve_modal"
        tabIndex="-1"
        aria-labelledby="sign_approve_modalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              {/* <img
                src={FooterLogo}
                alt="Logo"
                width="180"
                className="signlogo"
              /> */}
              {footerLink && footerLink.logoURI && <img src={`${config.imageUrl + '/logo/' + footerLink.logoURI
                }`} alt="Logo" width="180"
                className="signlogo" />}
              <h1 className="modal-title fs-5 mb-4" id="sign_modalLabel">
                {messages?.message1}
              </h1>
              <p>{messages?.message2}</p>
              <a className="link-yellow" onClick={() => handleClick('Terms of service')}>{messages?.message3}</a>
              <div className="text-center">
                <button
                  className="connect_wallet_btn w-100"
                  onClick={() => Signmessage()}
                >
                  {t("SIGN_MESSAGE")}
                  {isLoad && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
